import { Grid, Typography } from '@mui/material'
import globalStyles from "../../assets/styles/global.module.scss";
import React from 'react'

export const GlobalCard = (props: any) => {
    const newData = props.bullet && props.bullet.map((item: any) => ({
        text: item
    }));

    return (
        <>
            <Grid container direction="column" className={globalStyles.globalCardBox} >
                <Grid item className={globalStyles.globalCardHeading}>
                    {props.heading}
                </Grid>
                <Grid item >
                    {props.description ?
                        <Grid item className={globalStyles.globalCardBoxDescription} style={{ height: props.height, maxHeight: props.maxHeight, overflow: "auto" }}>
                            {props.description}
                        </Grid>
                        :
                        props.bullet ?
                            <Grid item className={globalStyles.globalCardBullet}>
                                {newData != undefined && newData.map((ele: any) => (
                                    <ul>
                                        <li className={globalStyles.globalCardBulletListText}>
                                            {ele.text}
                                        </li>
                                    </ul>))}
                                {/* <ul>
                            {newData!= undefined  && newData.length && newData.map((item :any, index:any) => (
                            <li key={index}>{item}</li>
                            ))}
                        </ul> */}

                            </Grid>
                            : ""
                    }
                </Grid>
            </Grid>
        </>
    )
}