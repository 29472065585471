import { Button, Divider, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserContext, UserContextPayload } from '../../context/userContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TemplateContext, TemplateContextPayload } from '../../context/templateContext';
import { DocContext, DocContextPayload } from '../../context/docContext';
import DescriptionIcon from '@mui/icons-material/Description';
import globalStyles from "../../assets/styles/global.module.scss";
import GlobalSearchBar from '../utility/searchBar';
import styles from "../../assets/styles/componentStyles/document.module.scss";
import youtubeIcon from "../../assets/youtube.svg";
import facebookIcon from "../../assets/facebook.svg";
import googleIcon from "../../assets/google.svg";
import blogIcon from "../../assets/blog.svg";
import addIcon from "../../assets/add.svg";
import docIcon from "../../assets/document.svg";
import editIcon from "../../assets/edit.png";
import deleteIcon from "../../assets/delete.png";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GlobalTable } from '../utility/globalTable';
import { DocumentListDataModel } from '../../models/axiosModel';
import { GlobalChip } from '../utility/globalChip';
import moment from 'moment';
import GlobalPopUp from '../utility/globalPopUp';
import DeleteDocumentDataComponent from './deleteDocumentDataComponent';
import Template from './template';
import agent from '../../services/api';
import GlobalSearch from '../utility/GlobalSearch/GlobalSearch';
import AddIcon from '@mui/icons-material/Add';
import GlobalButton from '../utility/globalButton/button';
import createPersonaIcon from "../../assets/createPersona.png";
import GlobalPageHeaderText from '../utility/globalPageHeaderText';
import { debounce } from 'lodash';
import { useSearchParam } from 'react-use';

const Documents = () => {

    let headerList = [
        {
            name: "Documents",
            alignment: "left",
            flagIcon: true,
            valueKey: "document_name",
            iconKey: "documentIcon",
        },
        {
            name: "Last Modified",
            alignment: "left",
            flagIcon: false,
            valueKey: "formatted_timestamp",
            iconKey: ""

        },
        {
            name: "Category",
            alignment: "left",
            flagIcon: true,
            valueKey: "template_name",
            iconKey: "featureIcon"
        },
        {
            name: "",
            alignment: "right",
            flagIcon: true,
            valueKey: "",
            iconKey: "editIcon"
        },
    ]

    const navigate = useNavigate()
    const { userDetail, flagLoading, setFlagLoading, setError, error, setSuccess, success, } = useContext(UserContext) as UserContextPayload;
    const { GetDocList, docList, setDocList, UpdateDocInfo, setDocumentEditorData } = useContext(DocContext) as DocContextPayload;
    const [docDetailForTable, setDocDetailForTable] = useState<DocumentListDataModel[]>([])
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [adjustedHeaderList, setAdjustedHeaderList] = useState(headerList);
    const [adjustedDocDetailForTable, setAdjustedDocDetailForTable] = useState(docDetailForTable);
    const [flagDeleteDocumentDataPopup, setFlagDeleteDocumentDataPopup] = useState(false)
    const [flagCreateNewDocument, setFlagCreateNewDocument] = useState(false)
    const [documentSelected, setDocumentSelected] = useState<null | DocumentListDataModel>(null)
    const [rowAllowEdit, setRowAllowEdit] = useState(null);
    const [flagRenderData, setFlagRenderData] = useState<Boolean | null>(null);
    const [searchParams] = useSearchParams()
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const isMobile = screenWidth <= 1200;

    const handleSearch = (event: any) => {
        const query = event.target.value?.toLowerCase()
        const docTableData = docDetailForTable?.filter((header) => {
            return (
                (header?.document_name !== null && header?.document_name?.toLowerCase().includes(query)) ||
                (header?.formatted_timestamp !== null && header?.formatted_timestamp?.toLowerCase().includes(query)) ||
                (header?.template_name !== null && header?.template_name?.toLowerCase().includes(query))
            );
            // old code
            // const lowerCaseDocumentName = header.document_name.toLowerCase();
            // const lowerCaseResults = query.toLowerCase();
            // return lowerCaseDocumentName.includes(lowerCaseResults);
        });
        setAdjustedDocDetailForTable(docTableData)
    };

    const debouncedSearch = debounce(handleSearch, 300)
    const handleInputChange = (event: any) => {
        debouncedSearch(event);
    };

    const handleCancelSearch = (results: string) => {
        setAdjustedDocDetailForTable(docDetailForTable);
    };

    useEffect(() => {
        if (userDetail.flagUser) {
            (async () => {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true });
                setFlagRenderData(false)
                await GetDocList()
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                setFlagRenderData(true)
                let createContent = searchParams.get('createContent')
                if (createContent && createContent == "true") {
                    createNewDocument()
                }
            })()
        }
    }, [])

    useEffect(() => {
        let tempDocList = docList?.length ? [...docList] : []
        tempDocList?.map((docObj: DocumentListDataModel) => {
            docObj['id'] = docObj?.document_id
            docObj['value'] = docObj?.document_name
            docObj['alignment'] = "left"
            docObj['iconSrc'] = docObj?.template_icon_url != null ? docObj?.template_icon_url : docIcon
            docObj['formatted_timestamp'] = moment(docObj.updated_at_timestamp).format('DD-MM-YY HH:mm')
        })
        setDocDetailForTable(tempDocList)
    }, [docList])

    useEffect(() => {
        if (isMobile) {
            // For mobile view
            const filteredHeaderList = headerList.filter(header => header.name !== "Category");
            const modifiedHeaderList = filteredHeaderList.map(header => {
                if (header.name === "Documents") {
                    return { ...header, flagIcon: false };
                }
                if (header.name === "Date and time") {
                    return { ...header, name: "Last Modified" };
                }
                return header;
            });

            setAdjustedHeaderList(modifiedHeaderList);
            docDetailForTable.slice(0, 10).map((row: any) => {
                row[`formatted_timestamp`] = moment(row.updated_at_timestamp).format('DD-MM-YY HH:mm')
            })
            setAdjustedDocDetailForTable(docDetailForTable);
        } else {
            // For desktop view
            setAdjustedHeaderList(headerList);
            docDetailForTable.slice(0, 10).map((row: any) => {
                row[`formatted_timestamp`] = moment(row.updated_at_timestamp).format('DD-MM-YY HH:mm')
            })
            setAdjustedDocDetailForTable(docDetailForTable);
        }
    }, [isMobile, docDetailForTable]);

    const createNewDocument = async () => {

        setDocumentEditorData(null)
        setFlagCreateNewDocument(true)
    }

    const deleteDocumentDataPopupOpen = (data: any) => {
        setFlagDeleteDocumentDataPopup(true)
        setDocumentSelected(data)
    }
    const deleteCancelDocumentDataPopupOpen = () => {
        setFlagDeleteDocumentDataPopup(false)
    }

    const rowClicked = async (e: any) => {
        if (e.template_type_name == "Single-Page") {
            setDocumentEditorData(null)
            navigate(`/doc/${e.document_uuid}/3/${e.template_id}`)

        } else {
            navigate(`/doc/${e.document_uuid}/3/${e.template_id}`)
        }
    }

    const renameDocumentName = async (value: any, data: any) => {

        const payload = {
            document_uuid: data.document_uuid,
            document_name: value
        }
        let response = await UpdateDocInfo(payload)

        let tempDocData: any = adjustedDocDetailForTable.map((ele) => {
            if (response?.data?.length) {
                if (ele.document_uuid == response?.data[0].document_uuid) {
                    var obj = { ...ele }
                    obj.document_name = response?.data[0].document_name
                    return obj
                } else {
                    return ele
                }
            }
        })
        setAdjustedDocDetailForTable(tempDocData)
        setRowAllowEdit(null)
    };

    const handleDeleteDocument = async (data: any) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await agent.deleteDocument.delete(data)
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Document Deleted Successfully!" })
            await GetDocList()
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong, Please try again!" })
        }
        deleteCancelDocumentDataPopupOpen()
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    return (
        <>
            {flagDeleteDocumentDataPopup &&
                <GlobalPopUp
                    open={flagDeleteDocumentDataPopup}
                    onClose={() => { setFlagDeleteDocumentDataPopup(false) }}
                    title={""}
                    flagShowCloseIcon={true}
                    handleClose={() => { setFlagDeleteDocumentDataPopup(false) }}
                >
                    <DeleteDocumentDataComponent
                        documentSelected={documentSelected != null && documentSelected.document_uuid}
                        deleteCancelDocumentDataPopupOpen={deleteCancelDocumentDataPopupOpen}
                        handleDeleteDocument={handleDeleteDocument}
                        deleteDescription="Are you sure you want to delete this Document." />
                </GlobalPopUp>
            }
            {flagCreateNewDocument &&
                <GlobalPopUp
                    open={flagCreateNewDocument}
                    onClose={() => { setFlagCreateNewDocument(false) }}
                    title={""}
                    flagShowCloseIcon={false}
                    handleClose={() => { setFlagCreateNewDocument(false) }}
                    className={globalStyles.newDocumentPopup}
                >
                    <Grid item >
                        <Template />
                    </Grid>
                </GlobalPopUp>
            }
            <Grid item xl={12} xs={12} className={`${globalStyles.fullwidth} ${globalStyles.dashboardChildWrapper}`} sx={{ width: "100%" }}>
                <Grid container alignItems={"center"} justifyContent={"start"} >
                    {/* title header */}

                    {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={8} className={styles.templateSearchbarStyle}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            {window.screen.width > 600 &&
                                <Grid item xl={4} lg={4} md={4} sm={4} xs={4} className={styles.docTitleTextStyle} sx={{ marginRight: { xs: "20px", sm: "0px" } }}>Documents</Grid>
                            }
                            <Grid item xl={4} lg={5} md={6} sm={7} xs={12} className={`${styles.tempSearchbarItem}`}>
                                <GlobalSearch
                                    fullWidth
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSearch(e) }}
                                    size="small"
                                    placeholder="Search"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {window.screen.width > 600 &&
                        <Grid item xl={2} lg={2.5} md={3.5} sm={5.5} xs={12} className={styles.newDOcBtn} sx={{ margin: "20px 0px" }}>
                            <Grid container>
                                <Grid item sx={{ width: "200px" }} onClick={(e: any) => { createNewDocument() }}>
                                    <Grid container
                                        sx={{
                                            gap: "10px",
                                            boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                                            padding: "10px",
                                            borderRadius: "5px",
                                            alignItems: "start"
                                        }}>
                                        <Grid item >
                                            <img src={addIcon} alt="" />
                                        </Grid>
                                        <Grid item>
                                            <Typography component={'span'} sx={{ color: "#000", fontSize: "15px", fontWeight: "500" }}>
                                                New Document
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    } */}

                    <Grid item xs={12} >
                        <Grid container sx={{ alignItems: "center" }}>

                            <Grid item xl={6} lg={5} md={12} sm={12} xs={12} sx={{ marginBottom: "10px" }}>
                                <GlobalPageHeaderText
                                    mainHeadText={"Documents"}
                                    mainSubText={"Browse existing or create new documents"}
                                />
                            </Grid>

                            <Grid item xl={6} lg={7} md={12} sm={12} xs={12} sx={{ marginBottom: { xs: "10px", sm: "10px", md: "0px" } }}>
                                <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} sx={{ alignItems: "center" }}>
                                    <Grid item xl={5.5} lg={5.5} md={4.5} sm={5.5} xs={9} className={`${globalStyles.globalHeadLeftSubItem}`}>
                                        <Grid container>
                                            <Grid item className={`${globalStyles.globalSearchbarSubItem}`}>
                                                <GlobalSearch
                                                    fullWidth
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleInputChange(e) }}
                                                    size="small"
                                                    placeholder="Search"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xl={4} lg={5} md={4.5} sm={5.5} xs={2} className={`${globalStyles.globalHeadRightSubItem} `}>
                                        <Grid container sx={{ alignItems: "center" }}>
                                            {window.screen.width >= 900 &&
                                                <Grid item className={`${globalStyles.globalNewAddBtnItem}`}>
                                                    {/* OLD CODE BEFORE SAME HEADING */}
                                                    {/* <Grid item onClick={(e: any) => { createNewDocument() }}>
                                                    <Grid container
                                                        sx={{
                                                            gap: "10px",
                                                            boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
                                                            padding: "10px",
                                                            borderRadius: "5px",
                                                            alignItems: "start"
                                                        }}
                                                    >
                                                        <Grid item >
                                                            <img src={addIcon} alt="" />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography component={'span'} sx={{ color: "#000", fontSize: "15px", fontWeight: "500" }}>
                                                                New Document
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid> */}
                                                    <Button
                                                        variant='outlined'
                                                        size='large'
                                                        fullWidth
                                                        startIcon={<img src={createPersonaIcon} alt="" style={{ height: "18px", width: 'auto' }} />}
                                                        className={globalStyles.addDocumentBtn}
                                                        onClick={(e: any) => { createNewDocument() }}
                                                    >
                                                        Create New Document
                                                    </Button>
                                                </Grid>
                                            }
                                            {window.screen.width <= 899 &&
                                                <IconButton
                                                    className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                                    onClick={(e: any) => { createNewDocument() }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* {window.screen.width <= 600 &&
                        <Grid item sx={{ marginLeft: "15px" }}>
                            <IconButton
                                size='large'
                                className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                onClick={(e: any) => { createNewDocument() }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Grid>
                    } */}

                    <Grid item xl={12} lg={12} md={12} sm={11} xs={12} className={`${styles.docSectionTextStyle} ${globalStyles.fullwidth}`}>
                        Recent Documents
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${styles.documentsTableItem}`}>
                        {flagRenderData == true ?
                            (
                                <GlobalTable
                                    headerList={adjustedHeaderList}
                                    rowData={adjustedDocDetailForTable}
                                    tableHeight={"81vh"}
                                    tableContainerHeight={"calc(100vh - 180px)"}
                                    tableContainerMinHeight={"auto"}
                                    onRowClick={(e: any) => { console.log("Hello", e) }}
                                    deleteRowData={deleteDocumentDataPopupOpen}
                                    renameDocumentName={renameDocumentName}
                                    rowAllowEdit={rowAllowEdit}
                                    flagPagination
                                    rowClicked={rowClicked}
                                />
                            ) : (<></>)
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Documents;