import { Grid, InputLabel } from "@mui/material";
import GlobalButton from "../utility/globalButton/button";
import OtpInput from "react18-input-otp";
import { useContext, useEffect, useState } from "react";
import styles from "../../assets/styles/componentStyles/verifyAccount.module.scss";
import { UserContext, UserContextPayload } from "../../context/userContext";
import GlobalSnackbar from "../utility/globalSnackbar";
import { errorModel } from "../../models/axiosModel";
import GlobalTextField from "./globalTextfield";
import globalStyles from "../../assets/styles/global.module.scss"
interface SuccessObj {
  flagShowSuccess: boolean;
  message: string;
}
const VerificationComponent = (props: any) => {
  const [otp, setOtp] = useState("");
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const emptyErrorObj = {
    flagShowError: false,
    message: ""
  }
  const emptySuccessObj = {
    flagShowSuccess: false,
    message: "",
  };
  const [error, setError] = useState<errorModel>(emptyErrorObj)
  const [success, setSuccess] = useState<SuccessObj>(emptySuccessObj)
  const { SendAccountOTP, setFlagLoading, flagLoading } = useContext(
    UserContext
  ) as UserContextPayload;


  const sendAccountOtp = async () => {
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });
    const response = await SendAccountOTP();
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    if (response.status) {
      handleStopTimer();
      handleResetTimer();
      handleStartTimer();
      if (response.data) {
        setSuccess({ ...success, flagShowSuccess: true, message: response.data?.message })
      }
    } else {
      setError({ ...error, flagShowError: true, message: "Something went wrong. Please try again!" })
    }
  };
  // useEffect(()=>{
  //   (async()=>{
  //     const response = await SendAccountOTP();
  //     console.log("response",response)
  //   })()
  // });

  useEffect(() => {
    let timerId: any;
    if (props.flagTimerActive && props.seconds > 0) {
      timerId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      setIsTimerActive(false);
    }
    return () => clearInterval(timerId);
  }, [isTimerActive, seconds]);

  // useEffect(()=>{
  //   sendAccountOtp();
  // },[])

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const handleStartTimer = () => setIsTimerActive(true);

  const handleStopTimer = () => setIsTimerActive(false);

  const handleResetTimer = () => {
    setIsTimerActive(false);
    setSeconds(60);
  };

  const handleResendOTP = () => {
    sendAccountOtp();
  };

  useEffect(() => {
    props.handleStartTimer();
  }, [])

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.keyCode === 13 && otp.length === 6){
        await props.handleLogin(otp);
    }
  }
  useEffect(() => {
      const onKeyDown = (event: KeyboardEvent) => {
          handleKeyDown(event as any);
      };
      document.addEventListener("keydown", onKeyDown);
      return () => {
          document.removeEventListener("keydown", onKeyDown);
      };
  }, [otp]);

  return (
    <>
      {error.flagShowError &&
        <GlobalSnackbar
          type="error"
          message={
            error.message == undefined
              ? "Something went wrong, Please try again!"
              : error.message
          }
          open={error.flagShowError}
          handleClose={() => {
            setError({ ...error, flagShowError: false });
          }}
        />
      }
      {success.flagShowSuccess &&
        <GlobalSnackbar
          type="success"
          message={success.message
          }
          open={success.flagShowSuccess}
          handleClose={() => {
            setSuccess({ ...success, flagShowSuccess: false });
          }}
        />
      }
      <Grid
        container
        sx={{ justifyContent: "space-between" }}
        direction={"column"}
      >
        <Grid item>
          <InputLabel className={globalStyles.inputLabel} >Email</InputLabel>
        </Grid>
        <Grid item>
          <GlobalTextField
            fullWidth
            value={props.value}
            // onChange={(e) => {
            //   setUser({ ...user, email: e.target.value });
            //   setError(emptyErrorObj);
            //   setFlagEmailValid(true)
            // }}
            sx={{ '& .MuiInputBase-root': { paddingRight: "0px !important" } }}
            onChange={props.onChange}
            disabled={props.disabled}
            InputProps={props.InputProps}
          />
        </Grid>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <Grid
          container
          sx={{ flexWrap: "nowrap" }}
          justifyContent={"center"}
          alignContent={"center"}
          direction={"column"}
        >
          <Grid item className={globalStyles.fullwidth} sx={{ fontSize: "15px", fontWeight: "600", color: "#4C4A4D", margin: "25px 0px !important", display: "flex", flexDirection: "column" }}>
            Verification Code
            <span style={{ fontSize: "13px", color: "#7A7A7A", fontWeight: "500", marginTop: "3px" }}>Sent to {props.value}</span>
          </Grid>
          <Grid item sx={{ marginBottom: "40px" }}>
            <OtpInput
              // {...field}
              value={otp}
              // id="otp"
              onChange={(value: any) => {
                setOtp(value);
                setInvalidOtp(false);
              }}
              isInputNum={true}
              shouldAutoFocus={true}
              numInputs={6}
              inputStyle={
                invalidOtp ? styles.otpInputError : styles.inputOtpStyle
              }
            />
          </Grid>
          <Grid item className={globalStyles.fullwidth}>
            <GlobalButton
              flagFullWidth
              buttonText="Login"
              disabled={otp.length < 6}
              className="authButtonStyle"
              onClick={() => { props.onClick(otp) }}
            // onClick={()=>handleVerifyAccount()}
            />
          </Grid>
          <Grid item sx={{ marginTop: "8px !important" }}>
            {props.seconds > 0 || props.minutes > 0 ? (
              <>
                <a
                  style={{
                    fontSize: "15px",
                    color: "#4C4A4D",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Haven’t received code? Resend code in{" "}
                </a>
                <a
                  style={{
                    fontSize: "14px",
                    color: "rgba(30, 136, 229, 1)",
                    marginRight: "20px"
                  }}
                >
                  {`${props.minutes}:${props.remainingSeconds < 10 ? "0" : ""
                    }${props.remainingSeconds}`}
                </a>
              </>
            ) : (
              <>
                <a
                  style={{
                    fontSize: "15px",
                    color: "#4C4A4D",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Haven’t received code?<span style={{ color: "#6A097D" }} onClick={props.handleResendOTP} className={`${globalStyles.clickable} ${globalStyles.resendButtonStyle}`}>Resend</span>
                </a>
              </>
              // <>
              //   <Grid item>
              //     <GlobalButton
              //       flagFullWidth
              //       buttonText="Resend"
              //       className="authButtonStyle"
              //       disabled={props.seconds > 0 || props.minutes > 0}
              //       // onClick={handleResendOTP}
              //       onClick={props.handleResendOTP}
              //     // onClick={props.onClick}
              //     />
              //   </Grid>
              // </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default VerificationComponent;
