import * as React from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import styles from "../../assets/styles/componentStyles/colorGuide.module.scss";


export default function ColorGuide() {
    const data = [
        {
            color: "colorGuideInclusion",
            primaryText: "Inclusion/Exclusion Compliance",
            secondaryText: "Persona Alignment"
        },
        {
            color: "colorGuideSynonym",
            primaryText: "Synonyms for Diversity",
            secondaryText: "Clarity Enhancements"
        },
        {
            color: "colorGuideGrammar",
            primaryText: "Grammar Fixes",
            secondaryText: "Syntax Correction"
        }
    ]

    return (
        <>
            {/* <Grid container className={styles.mainContainer}>
                <Grid item>
                    {data.map((element) => (
                        <Box className={styles.subContainer}>
                            <Box className={`${styles[element.color]} ${styles.colorPickerBox}`}></Box>
                            <Box className={styles.primaryText}>{element.primaryText}</Box>
                            <Divider className={`${styles[element.color]} ${styles.primaryText} ${styles.dividerStyle}`} orientation="vertical" variant="middle" flexItem />
                            <Box className={styles.primaryText}>{element.secondaryText}</Box>

                        </Box>
                    ))
                    }
                </Grid>
            </Grid> */}

            <Grid container sx={{ padding: "15px" }}>
                <Grid item xs={12} sx={{ fontSize: "14px", fontWeight: "500" }}>
                    {data.map((element) => (
                        <Grid container sx={{ alignItems: "center", gap: "10px" }}>
                            <Grid item className={`${styles[element.color]} ${styles.colorPickerBox}`} sx={{ margin: "3px 0px" }}></Grid>
                            <Grid item className={styles.primaryText} sx={{ margin: "3px 0px" }}>{element.primaryText}</Grid>
                            <Divider className={`${styles[element.color]} ${styles.primaryText} ${styles.dividerStyle}`} orientation="vertical" flexItem />
                            <Grid item className={styles.primaryText} sx={{ margin: "3px 0px" }}>{element.secondaryText}</Grid>
                        </Grid>
                    ))
                    }
                </Grid>
            </Grid>

        </>
    )
}
