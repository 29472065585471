import React, { useContext, useEffect, useState } from 'react'
import { Button, CardMedia, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import '../assets/styles/pricing.css';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RecommendedLabel from '../assets/RecommendedLabel.png';
import star from '../assets/CreditStar.svg';
import { UserContext, UserContextPayload } from '../context/userContext';

const PricingCard = (props: any) => {

    const {
        subType,
        pricingDetails,
    } = props

    const { customerSubscriptionDetails } = useContext(UserContext) as UserContextPayload

    const [selectedPlan, setSelectedPlan] = useState('Unlimited');
    const [isRecommended, setIsRecommended] = useState(true);

    const [selectedStarterMonthlyCreditValue, setSelectedStarterMonthlyCreditValue] = useState(0);
    const [selectedStarterYearlyCreditValue, setSelectedStarterYearlyCreditValue] = useState(0);

    const [selectedFreeMontlyCreditValue, setSelectedFreeMonthlyCreditValue] = useState(0);
    const [selectedFreeYearlyCreditValue, setSelectedFreeYearlyCreditValue] = useState(0);

    const [selectedPremiumMonthlyCreditValue, setSelectedPremiumMonthlyCreditValue] = useState(0);
    const [selectedPremiumYearlyCreditValue, setSelectedPremiumYearlyCreditValue] = useState(0);

    const [pricingCardDetails, setPricingCardDetails] = useState(pricingDetails)


    useEffect(() => {
        setPricingCardDetails(pricingDetails)
    }, [pricingDetails])

    const handlePlanClick = (planName: any) => {
        setSelectedPlan(planName);
        if (planName == 'Plus') {
            setIsRecommended(true);
        }
        else {
            setIsRecommended(false);
        }
    };

    const handleAnnualSavingValue = (monthlyValue: any, yearlyValue: any) => {
        const savings = ((monthlyValue - yearlyValue) / monthlyValue) * 100;
        return savings.toFixed(0);

    }

    const getFormattedDate = (date: any) => {
        const inputDate = new Date(date);
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = inputDate.toLocaleDateString('en-US', options);
        return formattedDate;
    };

    return (
        <>
            <Grid container className='pp-plans-panel-main-container'>

                {/* free plan */}
                {pricingCardDetails?.filter((e: any) => (e?.plan_name == 'Free'))?.map((ele: any) => {
                    return (
                        <>
                            <Grid item xl={3.5} lg={5.5} md={8} sm={12} xs={12} className='pp-global-plan-item'>
                                {/* show validity date */}
                                <Grid container className='pp-global-plan-main-container-height'>
                                    {/* {customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_product_name == "Free" && customerSubscriptionDetails.stripe_customer_subscription_plan_valid_till !== null &&
                                        <Grid item sx={{ fontSize: "14px", fontWeight: "600" }}>
                                            Valid till {getFormattedDate(customerSubscriptionDetails.stripe_customer_subscription_plan_valid_till)}
                                        </Grid>
                                    } */}

                                    <Grid item xl={3.8} lg={3.8} md={5.7} sm={12} xs={12}
                                        onClick={() => handlePlanClick('Free')}
                                        className='pp-plans-panel-sub-item'
                                    // className={`pp-plans-panel-sub-item ${customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_product_name == "Free" ? "selected-item" : ""}`}
                                    >
                                        <Grid container>
                                            <Grid item xs={11} className='pp-plan-card-item1'>
                                                <Grid container>

                                                    <Grid item xs={12} >
                                                        <Typography
                                                            component={'span'}
                                                            className='pp-plan-package-label-title'
                                                        >
                                                            {ele?.plan_name}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} className='pp-plan-subtitle-item'>
                                                        <Typography
                                                            component={'span'}
                                                            className='pp-plan-package-label-sub-title'
                                                        >{ele?.plan_desc}</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} className='pp-plan-package-amount-label-item'>
                                                        <Grid container className='pp-plan-amount-discount-container'>
                                                            <Grid item xs={6.5}>
                                                                {subType === "monthly" &&
                                                                    <Typography
                                                                        component={'span'}
                                                                        className='pp-plan-package-amount-label'
                                                                    >
                                                                        ${ele.monthly_plan[selectedFreeMontlyCreditValue]?.value}<Typography component={'span'} className='pp-plan-package-amount-month-label'>/month</Typography>
                                                                    </Typography>
                                                                }
                                                                {subType == "yearly" &&
                                                                    <Typography
                                                                        component={'span'}
                                                                        className='pp-plan-package-amount-label'
                                                                    >
                                                                        ${ele?.yearly_plan ? ele?.yearly_plan[selectedFreeYearlyCreditValue]?.value : "0"}
                                                                        <Typography component={'span'} className='pp-plan-package-amount-month-label'>/month</Typography>
                                                                    </Typography>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={4} className='pp-free-plan-label-item'>
                                                                <Typography
                                                                    component={'span'}
                                                                    className='pp-free-plan-label'
                                                                >Free of charge</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} className='pp-plan-package-dis-amount-label-item'>
                                                        <Typography
                                                            component={"span"}
                                                            sx={{ color: "black" }}
                                                        >
                                                            <img src={star} alt="" /> Credit
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} className='pp-plan-slider-item' >
                                                        <Grid container sx={{ flexWrap: "nowrap", overflow: "auto", gap: { xs: "20px", sm: "10px" } }}>
                                                            {subType == "monthly" &&
                                                                ele?.monthly_plan?.map((opt: any, i: any) => {
                                                                    return (
                                                                        <Grid item xs={2} key={i} className='pp-plan-credit-btn-item' >
                                                                            <Button
                                                                                variant={opt.value == selectedFreeMontlyCreditValue ? 'contained' : 'outlined'}
                                                                                className={opt.value == selectedFreeMontlyCreditValue ? 'pp-plan-credit-btn-selected' : 'pp-plan-credit-btn'}
                                                                                size='medium'
                                                                                fullWidth
                                                                                onClick={() => {
                                                                                    setSelectedFreeMonthlyCreditValue(opt.value)
                                                                                }}
                                                                            >
                                                                                {opt.label}
                                                                            </Button>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                            {subType == "yearly" &&
                                                                <Grid item xs={2} sx={{ margin: "25px 0px" }} >
                                                                    <span style={{ margin: "25px 0px" }}></span>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    {customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_customer_subscription_status !== "canceled" &&
                                                        <Grid item xs={12} className={'pp-plan-btn-item'}>
                                                            <Button
                                                                variant={selectedPlan == 'Free' ? 'contained' : 'outlined'}
                                                                fullWidth={true}
                                                                className={selectedPlan == 'Free' ? 'pp-plan-btn-selected' : 'pp-plan-btn'}
                                                                sx={{ boxShadow: "none" }}
                                                                size='large'
                                                                onClick={props.cancelStripeSubscription}
                                                            >
                                                                {selectedPlan == 'Free' ? (customerSubscriptionDetails.gc_stripe_customer_subscription_plan_data.stripe_product_name === "Premium" ? "Downgrade Now" : "Upgrade Now") : "Get Started"}
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Divider sx={{ color: "black", width: "100%" }} />

                                            <Grid item xs={11} className='pp-plan-card-item2'>
                                                <List>
                                                    {ele?.feature_list?.map((ele: any) => {
                                                        return (
                                                            <>
                                                                <ListItem className='pp-plans-list-points'>
                                                                    <ListItemIcon className='pp-plan-check-icon'>
                                                                        <TaskAltIcon sx={{ color: "black" }} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={
                                                                            <span className='pp-plans-points'>{ele}</span>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            </>
                                                        )
                                                    })}
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </>
                    )
                })}

                {/* plus plan */}
                {pricingCardDetails?.filter((e: any) => (e?.plan_name == "Starter"))?.map((ele: any) => {
                    return (
                        <>
                            <Grid item xl={3.5} lg={5.5} md={8} sm={12} xs={12} className='pp-global-plan-item' >
                                <Grid container className='pp-global-plan-main-container-height'>
                                    {/* {customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_product_name == "Starter" && customerSubscriptionDetails.stripe_customer_subscription_plan_valid_till !== null &&
                                        <Grid item sx={{ fontSize: "14px", fontWeight: "600" }}>
                                            Valid till {getFormattedDate(customerSubscriptionDetails.stripe_customer_subscription_plan_valid_till)}
                                        </Grid>
                                    } */}

                                    <Grid item xl={3.8} lg={3.8} md={5.7} sm={12} xs={12}
                                        // className={`pp-plans-panel-sub-item ${customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_product_name == "Starter" ? "selected-item" : ""}`}
                                        className='pp-plans-panel-sub-item'
                                        onClick={() => handlePlanClick('Plus')}
                                    >
                                        <Grid container>
                                            <Grid item xs={11} className='pp-plan-card-item1' >
                                                <Grid container>
                                                    <Grid item xs={12} >
                                                        <Typography
                                                            component={'span'}
                                                            className='pp-plan-package-label-title'
                                                        >{ele?.plan_name}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} className='pp-plan-subtitle-item'>
                                                        <Typography
                                                            component={'span'}
                                                            className='pp-plan-package-label-sub-title'
                                                        >
                                                            {ele?.plan_desc}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} className='pp-plan-package-amount-label-item'>
                                                        <Grid container className='pp-plan-amount-discount-container'>
                                                            <Grid item xs={5.5}>
                                                                {subType === "monthly" &&
                                                                    <Typography
                                                                        component={'span'}
                                                                        className='pp-plan-package-amount-label'
                                                                    >
                                                                        ${ele.monthly_plan[selectedStarterMonthlyCreditValue]?.value}<Typography component={'span'} className='pp-plan-package-amount-month-label'>/month</Typography>
                                                                    </Typography>
                                                                }
                                                                {subType === "yearly" &&
                                                                    <Typography
                                                                        component={'span'}
                                                                        className='pp-plan-package-amount-label'
                                                                    >${ele.yearly_plan[selectedStarterYearlyCreditValue]?.value}<Typography component={'span'} className='pp-plan-package-amount-month-label'>/month</Typography></Typography>
                                                                }
                                                            </Grid>
                                                            {/* Dyanmic Percentage of yearly plan */}
                                                            <Grid item xs={5.5} className='pp-plan-amount-discount-item'>
                                                                <Typography
                                                                    component={'span'}
                                                                    className='pp-plan-package-dis-amount-label'
                                                                >Save {handleAnnualSavingValue(ele.monthly_plan[selectedStarterMonthlyCreditValue]?.value, ele.yearly_plan[selectedStarterYearlyCreditValue]?.value)}% on yearly plan</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} className='pp-plan-package-dis-amount-label-item'>
                                                        <Typography
                                                            component={"span"}
                                                            sx={{ color: "black" }}
                                                        >
                                                            <img src={star} alt="" /> Credit
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} className='pp-plan-slider-item' >
                                                        <Grid container sx={{ flexWrap: "nowrap", overflow: "auto", gap: { xs: "20px", sm: "10px" } }}>
                                                            {subType == "monthly" &&
                                                                ele?.monthly_plan?.map((opt: any, i: any) => {
                                                                    return (
                                                                        <Grid item xs={2} key={i} className='pp-plan-credit-btn-item' >
                                                                            <Button
                                                                                variant={i == selectedStarterMonthlyCreditValue ? 'contained' : 'outlined'}
                                                                                className={i == selectedStarterMonthlyCreditValue ? 'pp-plan-credit-btn-selected' : 'pp-plan-credit-btn'}
                                                                                size='medium'
                                                                                fullWidth
                                                                                onClick={() => {
                                                                                    setSelectedStarterMonthlyCreditValue(i)
                                                                                    setSelectedStarterYearlyCreditValue(i)
                                                                                }}
                                                                            >
                                                                                {opt.label}
                                                                            </Button>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                            {subType == "yearly" &&
                                                                ele?.yearly_plan?.map((opt: any, i: any) => {
                                                                    return (
                                                                        <Grid item xs={2} key={i} className='pp-plan-credit-btn-item' >
                                                                            <Button
                                                                                variant={i == selectedStarterYearlyCreditValue ? 'contained' : 'outlined'}
                                                                                className={i == selectedStarterYearlyCreditValue ? 'pp-plan-credit-btn-selected' : 'pp-plan-credit-btn'}
                                                                                size='medium'
                                                                                fullWidth
                                                                                onClick={() => {
                                                                                    setSelectedStarterYearlyCreditValue(i)
                                                                                    setSelectedStarterMonthlyCreditValue(i)
                                                                                }}
                                                                            >
                                                                                {opt.label}
                                                                            </Button>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    {customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_customer_subscription_status !== "canceled" && (customerSubscriptionDetails.stripe_customer_subscription_flag_free_trial || customerSubscriptionDetails.stripe_customer_subscription_flag_free_plan) &&
                                                        <Grid item xs={12} className='pp-plan-btn-item'>
                                                            <Button
                                                                variant={selectedPlan == 'Plus' ? 'contained' : 'outlined'}
                                                                fullWidth={true}
                                                                className={selectedPlan == 'Plus' ? 'pp-plan-btn-selected' : 'pp-plan-btn'}
                                                                sx={{ boxShadow: "none" }}
                                                                size='large'
                                                                onClick={() => { props.handleUpdateCustomerSubscription(ele) }}
                                                            >
                                                                Buy Plan
                                                                {/* {selectedPlan == 'Plus' ? "Upgrade Now" : "Get Started"} */}
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Divider sx={{ color: "black", width: "100%" }} />

                                            <Grid item xs={11} className='pp-plan-card-item2'>
                                                <List>
                                                    {ele?.feature_list?.map((ele: any) => {
                                                        return (
                                                            <>
                                                                <ListItem className='pp-plans-list-points'>
                                                                    <ListItemIcon className='pp-plan-check-icon'>
                                                                        <TaskAltIcon sx={{ color: "black" }} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={
                                                                            <span className='pp-plans-points'>{ele}</span>
                                                                        }
                                                                    />
                                                                </ListItem>

                                                            </>
                                                        )
                                                    })
                                                    }
                                                </List>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>

                    )
                })}

                {/* premium plan */}
                {pricingCardDetails?.filter((e: any) => (e?.plan_name == "Premium"))?.map((ele: any) => {
                    return (
                        <Grid item xl={3.5} lg={5.5} md={8} sm={12} xs={12} className='pp-premium-plan-main-item' >
                            <Grid container className='pp-global-plan-main-container-height'>
                                {/* {customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_product_name == "Premium" && customerSubscriptionDetails.stripe_customer_subscription_plan_valid_till !== null &&
                                    <Grid item sx={{ fontSize: "14px", fontWeight: "600" }}>
                                        Valid till {getFormattedDate(customerSubscriptionDetails.stripe_customer_subscription_plan_valid_till)}
                                    </Grid>
                                } */}

                                <Grid item xl={3.8} lg={3.8} md={5.5} sm={12} xs={12}
                                    // className={`pp-plans-panel-sub-item ${customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_product_name == "Premium" ? "selected-item" : ""}`}
                                    className='pp-premium-plans-panel-sub-item'
                                    onClick={() => handlePlanClick('Unlimited')}
                                >

                                    <Grid container>
                                        <Grid item xs={12} className='pp-plan-head-label-item'>
                                            <Grid container className='pp-plan-head-label-container'>
                                                <Grid item xs={5} className='pp-plan-head-label-sub-item'>
                                                    <Typography
                                                        component={'span'}
                                                        className='pp-plan-package-label-title'
                                                    >{ele?.plan_name}</Typography>
                                                </Grid>

                                                <Grid item xs={7} className='pp-plan-RecommendedLabel' >
                                                    <CardMedia
                                                        src={RecommendedLabel}
                                                        sx={{ width: "auto", height: 50 }}
                                                        component="img"
                                                        alt="Checked"
                                                        className='pp-plan-RecommendedLabel-img'
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={11} className='pp-plan-head-label-main-item1'>
                                            <Grid container>

                                                <Grid item xs={12} className='pp-plan-subtitle-item'>
                                                    <Typography
                                                        component={'span'}
                                                        className='pp-plan-package-label-sub-title'
                                                    >{ele?.plan_desc}</Typography>
                                                </Grid>

                                                <Grid item xs={12} className='pp-plan-package-amount-label-item'>
                                                    <Grid container className='pp-plan-amount-discount-container'>
                                                        <Grid item xs={5.5}>
                                                            {subType === "monthly" &&
                                                                <Typography
                                                                    component={'span'}
                                                                    className='pp-plan-package-amount-label'
                                                                >${ele?.monthly_plan[selectedPremiumMonthlyCreditValue]?.value}<Typography component={'span'} className='pp-plan-package-amount-month-label'>/month</Typography>
                                                                </Typography>
                                                            }
                                                            {subType === "yearly" &&
                                                                <Typography
                                                                    component={'span'}
                                                                    className='pp-plan-package-amount-label'
                                                                >${ele?.yearly_plan[selectedPremiumYearlyCreditValue]?.value == undefined ? "0" : ele?.yearly_plan[selectedPremiumYearlyCreditValue]?.value}<Typography component={'span'} className='pp-plan-package-amount-month-label'>/month
                                                                    </Typography></Typography>
                                                            }
                                                        </Grid>
                                                        {/* Dyanmic Percentage of yearly plan */}
                                                        <Grid item xs={5.5} className='pp-plan-amount-discount-item'>
                                                            <Typography
                                                                component={'span'}
                                                                className='pp-plan-package-dis-amount-label'
                                                            >
                                                                Save {handleAnnualSavingValue(ele.monthly_plan[selectedPremiumMonthlyCreditValue]?.value, ele.yearly_plan[selectedPremiumYearlyCreditValue]?.value)}% on yearly plan
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} className='pp-plan-package-dis-amount-label-item'>
                                                    <Typography
                                                        component={"span"}
                                                        sx={{ color: "black" }}
                                                    >
                                                        <img src={star} alt="" /> Credit
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} className='pp-plan-slider-item' >
                                                    <Grid container sx={{ flexWrap: "nowrap", overflow: 'auto', gap: { xs: "20px", sm: "10px" } }} >
                                                        {subType == "monthly" &&
                                                            ele?.monthly_plan?.map((ele: any, i: any) => {
                                                                return (
                                                                    <Grid item xs={2} key={i} className='pp-plan-credit-btn-item' >
                                                                        <Button
                                                                            variant={i == selectedPremiumMonthlyCreditValue ? 'contained' : 'outlined'}
                                                                            className={i == selectedPremiumMonthlyCreditValue ? 'pp-plan-credit-btn-selected' : 'pp-plan-premium-credit-btn'}
                                                                            size='medium'
                                                                            fullWidth
                                                                            onClick={() => {
                                                                                setSelectedPremiumMonthlyCreditValue(i)
                                                                                setSelectedPremiumYearlyCreditValue(i)
                                                                            }}
                                                                        >
                                                                            {ele.label}
                                                                        </Button>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                        {subType == "yearly" &&
                                                            ele?.yearly_plan?.map((ele: any, i: any) => {
                                                                return (
                                                                    <Grid item xs={2} key={i} className='pp-plan-credit-btn-item' >
                                                                        <Button
                                                                            variant={i == selectedPremiumYearlyCreditValue ? 'contained' : 'outlined'}
                                                                            className={i == selectedPremiumYearlyCreditValue ? 'pp-plan-credit-btn-selected' : 'pp-plan-premium-credit-btn'}
                                                                            size='medium'
                                                                            fullWidth
                                                                            onClick={() => {
                                                                                setSelectedPremiumYearlyCreditValue(i)
                                                                                setSelectedPremiumMonthlyCreditValue(i)
                                                                            }}
                                                                        >
                                                                            {ele?.label}
                                                                        </Button>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                </Grid>

                                                {customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_customer_subscription_status !== "canceled" && (customerSubscriptionDetails.stripe_customer_subscription_flag_free_trial || customerSubscriptionDetails.stripe_customer_subscription_flag_free_plan) &&
                                                    <Grid item xs={12} className='pp-plan-btn-item'>
                                                        <Button
                                                            variant={selectedPlan == 'Unlimited' ? 'contained' : 'outlined'}
                                                            fullWidth={true}
                                                            className={selectedPlan == 'Unlimited' ? 'pp-plan-btn-selected' : 'pp-plan-btn'}
                                                            sx={{ boxShadow: "none" }}
                                                            size='large'
                                                            onClick={() => { props.handleUpdateCustomerSubscription(ele) }}
                                                        >
                                                            Buy Plan
                                                            {/* {selectedPlan == 'Unlimited' ? "Upgrade Now" : "Get Started"} */}
                                                        </Button>
                                                    </Grid>
                                                }

                                            </Grid>
                                        </Grid>

                                        <Divider sx={{ color: "black", width: "100%" }} />

                                        <Grid item xs={11} className='pp-plan-card-item2'>
                                            <List>
                                                {ele?.feature_list?.map((ele: any) => {
                                                    return (
                                                        <>
                                                            <ListItem className='pp-plans-list-points'>
                                                                <ListItemIcon className='pp-plan-check-icon'>
                                                                    <TaskAltIcon sx={{ color: "black" }} />
                                                                </ListItemIcon>
                                                                <ListItemText primary={<span className='pp-plans-points'>{ele}</span>} />
                                                            </ListItem>
                                                        </>
                                                    )
                                                })}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    )
                })}
            </Grid>
        </>
    )
}

export default PricingCard