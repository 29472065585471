import DraggableList from "react-draggable-list";
import { useContext, useEffect, useRef, useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import deleteIcon from "../../../assets/deleteDoc.svg";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import styles from "../../../assets/styles/componentStyles/customTemplateDraggableList.module.scss";
import addFieldIcon from "../../../assets/AddInputfield.svg";
import dragIndicatorIcon from "../../../assets/dragIndicatorIcon.svg";
import globalStyles from "../../../assets/styles/global.module.scss";
import { TemplateContext } from "../../../context/templateContext";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const Item = ({ item, itemSelected, dragHandleProps, commonProps }) => {
    const { onMouseDown, onTouchStart } = dragHandleProps;
    const handleFormFieldNameChange = (value, id, template_form_field_id) => {
        let temp = { type: "add", value: value, id: id, template_form_field_id: template_form_field_id }
        commonProps(temp)
    }
    const deleteFormField = (id, template_form_field_id) => {
        let temp = { type: "delete", id: id, template_form_field_id: template_form_field_id };
        commonProps(temp)
    }
    return (
        <>
            <Grid container sx={{ flexWrap: "nowrap" }} justifyContent={"space-between"} alignItems={"center"} className={styles.fieldItemStyle} >
                <Grid item>
                    <Grid container sx={{ flexWrap: "nowrap" }} alignItems={"center"} >
                        <Box
                            sx={{ width: "unset !important" }}
                            className="disable-select dragHandle"
                            onTouchStart={(e) => {
                                e.preventDefault();
                                document.body.style.overflow = "hidden";
                                onTouchStart(e);
                            }}
                            onMouseDown={(e) => {
                                document.body.style.overflow = "hidden";
                                onMouseDown(e);
                            }}
                            onTouchEnd={(e) => {
                                document.body.style.overflow = "visible";
                            }}
                            onMouseUp={() => {
                                document.body.style.overflow = "visible";
                            }}
                        ><DragIndicatorIcon
                                className={styles.draggableIconStyle} /></Box>
                        <Grid item sx={{ width: "100% !important" }}>
                            <TextField value={item.template_form_field_property[0].template_form_field_property_value} onChange={(event) => { handleFormFieldNameChange(event.target.value, item.id, item.template_form_field_id) }} className={styles.fieldTextfieldstyle}
                            // value={item.template_form_field_property[0].template_form_field_property_value} 
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <img alt="" src={deleteIcon} onClick={() => (deleteFormField(item.id, item.template_form_field_id))} className={styles.deleteIconStyle} />
                </Grid>
            </Grid>
        </>
    );
};

export default function DraggableListComponent() {
    // const [customTemplateTemplateFormFieldList, setCustomTemplateTemplateFormFieldList] = useState(data);
    const { templateFormFieldsGenericData, customTemplateTemplateFormFieldList, setCustomTemplateTemplateFormFieldList, customTemplatePromptActionData, setCustomTemplatePromptActionData } = useContext(TemplateContext);

    const containerRef = useRef();

    const _onListChange = (newList) => {
        newList.forEach((item, index) => {
            item.template_form_field_seq_num = index + 1;
        });
        setCustomTemplateTemplateFormFieldList(newList);
    };
    const addFormField = () => {
        const longTextFieldType = templateFormFieldsGenericData.find(
            (fieldType) => fieldType.form_field_input_type_name === "Long Text"
        );


        if (longTextFieldType) {
            // Create a new form field object
            const newFormField = {
                id: customTemplateTemplateFormFieldList.length, // Index of the new item
                template_form_field_id: `ud-${Math.floor(Math.random() * 10000)}`, // Generate a random 4-digit number attached with "ud-"
                form_field_input_type_name: longTextFieldType.form_field_input_type_name,
                form_field_input_type_id: longTextFieldType.form_field_input_type_id,
                template_form_field_seq_num: customTemplateTemplateFormFieldList.length + 1, // Index of the new item
                template_form_field_property: [
                    {
                        form_field_input_type_property_id: longTextFieldType.form_field_input_type_property.find(
                            (property) => property.form_field_input_type_property_name === "Title"
                        ).form_field_input_type_property_id,
                        template_form_field_property_value: "Input",
                        form_field_input_type_property_name: longTextFieldType.form_field_input_type_property.find(
                            (property) => property.form_field_input_type_property_name === "Title"
                        ).form_field_input_type_property_name
                    },
                    {
                        form_field_input_type_property_id: longTextFieldType.form_field_input_type_property.find(
                            (property) => property.form_field_input_type_property_name === "Placeholder"
                        ).form_field_input_type_property_id,
                        template_form_field_property_value: `Start typing here`,
                        form_field_input_type_property_name: longTextFieldType.form_field_input_type_property.find(
                            (property) => property.form_field_input_type_property_name === "Placeholder"
                        ).form_field_input_type_property_name
                    },
                    {
                        form_field_input_type_property_id: longTextFieldType.form_field_input_type_property.find(
                            (property) => property.form_field_input_type_property_name === "Is Required?"
                        ).form_field_input_type_property_id,
                        template_form_field_property_value: false, // Set to false by default
                        form_field_input_type_property_name: longTextFieldType.form_field_input_type_property.find(
                            (property) => property.form_field_input_type_property_name === "Is Required?"
                        ).form_field_input_type_property_name
                    },
                ],
            };

            // Push the new form field object into the customTemplateTemplateFormFieldList
            setCustomTemplateTemplateFormFieldList([...customTemplateTemplateFormFieldList, newFormField]);
        }
    }
    const listDataUpdate = (data) => {
        if (data.type == "add") {
            let temp = [...customTemplateTemplateFormFieldList];
            temp.map((item, id) => {
                if (item.template_form_field_id === data.template_form_field_id) {
                    return item.template_form_field_property[0].template_form_field_property_value = data.value
                }
            })

            // const regex = new RegExp(`\\[\\[{"template_form_field_id":"${data.template_form_field_id}","value":".*?"}\\]\\]`, 'g');
            // let match;
            // const matches = [];

            // // Extract matches
            // while ((match = regex.exec(customTemplatePromptActionData)) !== null) {
            //     matches.push({
            //         fullMatch: match[0],
            //         id: data.template_form_field_id,
            //         value: match[0].match(/"value":"(.*?)"/)[1]
            //     });
            // }

            let tempStr = customTemplatePromptActionData.trim()
            if (tempStr.indexOf(data.template_form_field_id) > -1) {
                let replaceValueIndex = tempStr.indexOf('"value":')
                let str1 = tempStr.substr(0, replaceValueIndex)


                let str2 = tempStr.substr(replaceValueIndex)
                let replaceValueEndIndex = str2.indexOf('"}]]') + replaceValueIndex
                tempStr = tempStr.slice(0, replaceValueIndex) + `"value":"${data.value}` + tempStr.slice(replaceValueEndIndex)
            }

            // Update the string based on the matches
            let customTemplatePromptActionDataTemp
            // matches.forEach(item => {
            //     if (item.id === data.template_form_field_id) {
            //         const updatedValue = `[[{"template_form_field_id":"${item.id}","value":"${data.value}"}]]`;
            //         customTemplatePromptActionDataTemp = customTemplatePromptActionData.replace(item.fullMatch, updatedValue);
            //     } else {
            //         customTemplatePromptActionDataTemp = customTemplatePromptActionData.replace(item.fullMatch, '');
            //     }
            // });

            // Clean up extra spaces
            // customTemplatePromptActionDataTemp = customTemplatePromptActionDataTemp.replace(/\s{2,}/g, ' ').trim();
            setCustomTemplatePromptActionData(tempStr)
            setCustomTemplateTemplateFormFieldList(temp)
        } else {
            let temp = [...customTemplateTemplateFormFieldList];
            let filteredList = temp.filter((item) => (item.template_form_field_id !== data.template_form_field_id))
            let tempStr = customTemplatePromptActionData.trim()
            if (tempStr.indexOf(data.template_form_field_id) > -1) {
                let replaceValueIndex = tempStr.indexOf(`"template_form_field_id":"${(data.template_form_field_id)}"`) - 3
                // let str1 = tempStr.substr(0, replaceValueIndex)


                let str2 = tempStr.substr(replaceValueIndex)
                let replaceValueEndIndex = str2.indexOf('"}]]') + replaceValueIndex + 4
                tempStr = tempStr.slice(0, replaceValueIndex) + '' + tempStr.slice(replaceValueEndIndex)
            }
            setCustomTemplatePromptActionData(tempStr.trim())
            setCustomTemplateTemplateFormFieldList(filteredList)

        }

    }

    return (
        <Grid container
            ref={containerRef}
            className={styles.dragDropContainer}
            sx={{ touchAction: "pan-y", width: "100%" }}
        >
            <Grid item xs={11} className={styles.upperBlock}>
                <Grid container sx={{ justifyContent: "end", alignItems: "center", }}>
                    {/* <Grid item sx={{
                        width: "100%",
                        overflow: "auto",
                        position: "sticky",
                        top: "0px",
                        backgroundColor: "#FFF",
                        zIndex: "10",

                    }}>Hello</Grid> */}
                    {/* <Grid item className={styles.fieldListTextStyle}>
                        <Grid container sx={{ alignItems: "Center" }}>
                            <Typography variant="span" sx={{ marginRight: '20px' }}>
                                Field list
                            </Typography>
                            <InfoOutlinedIcon sx={{ fontSize: "19px", color: "#6A097D" }} />
                        </Grid>
                    </Grid> */}

                    {customTemplateTemplateFormFieldList.length > 0 &&
                        <Grid item>
                            <Grid container sx={{ alignItems: "center" }}>
                                <Grid item sx={{ marginRight: "5px" }} >
                                    <Grid container >
                                        <img alt="" src={addFieldIcon} onClick={addFormField} className={globalStyles.clickable} />
                                    </Grid>
                                </Grid>
                                <Grid item className={styles.AddFieldTextStyle} onClick={addFormField}>
                                    <Typography variant="span" sx={{ fontSize: "15px", fontWeight: "400", color: "#7A7A7A", cursor: "pointer" }}>
                                        Add Input Field
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>}
                </Grid>
            </Grid>
            <Grid item xs={11} className={styles.draggableListContainer}>
                <Grid container sx={{ height: "100%" }} className={styles.draggableListContainerBox}>
                    {customTemplateTemplateFormFieldList.length > 0 ?
                        <DraggableList
                            itemKey="id"
                            template={Item}
                            style={{ width: "100%" }}
                            list={customTemplateTemplateFormFieldList}
                            commonProps={listDataUpdate}
                            onMoveEnd={(newList) => _onListChange(newList)}
                            container={() => containerRef.current}
                        />
                        :
                        <Grid item sx={{ height: "100%" }} className={globalStyles.fullwidth}>
                            <Grid container sx={{ height: "100%" }} direction={"column"} justifyContent={"center"} alignItems={"center"}>
                                <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                                    <img alt="" src={addFieldIcon} className={globalStyles.clickable} onClick={addFormField} />
                                </Grid>
                                <Grid item className={styles.AddFieldTextStyle}>
                                    Add Input Field
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                </Grid>
            </Grid>
        </Grid>
    );
}
