import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import { useLocation } from 'react-router-dom';
import CampaignTabView from './CampaignTabView';
import CreateCampaign from './CreateCampaign';
import { UserContext, UserContextPayload } from '../../../context/userContext';


const CampaignCreateUpdateView = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const getCampaignId = queryParams.get('campaign_id');
    const { userDetail } = useContext(UserContext) as UserContextPayload;

    if (getCampaignId && userDetail.flagUser) {
        return (
            <CampaignTabView />
        );
    }
    else {
        return (
            <CreateCampaign />
        );
    }
}

export default CampaignCreateUpdateView