import { Grid, LinearProgress, Slider, Typography } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { UserContext, UserContextPayload } from '../../context/userContext';
import moveForward from "../../assets/rightArrow.png";
import styles from "../../assets/styles/componentStyles/creditBanner.module.scss";
import GlobalButton from './globalButton/button';
import upgrade from "../../assets/upgrade.svg";
import { useNavigate } from 'react-router-dom';
import globalStyles from "../../assets/styles/global.module.scss";


export const CreditBanner = (props: any) => {

    const { creditUsed, setCreditUsed, customerSubscriptionDetails } = useContext(UserContext) as UserContextPayload;
    const getFormattedDate = (date: any) => {
        const inputDate = new Date(date);
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = inputDate.toLocaleDateString('en-US', options);
        return formattedDate;
    };

    return (
        <>
            <Grid item className={`${styles.creditUsedContainerBanner} ${globalStyles.fullwidth}`}>
                <Grid container direction={"column"}>
                    <Grid item className={styles.firstBlock}>
                        <Grid container justifyContent={"space-between"}>
                            <Grid item className={styles.planTextStyle}>Your Plan : {customerSubscriptionDetails?.gc_stripe_customer_subscription_plan_data.stripe_product_name} {customerSubscriptionDetails?.gc_stripe_customer_subscription_transaction_data[0].subscription_transaction_payment_status ? (customerSubscriptionDetails.gc_stripe_customer_subscription_transaction_data[0].subscription_transaction_payment_status) : ""}</Grid>
                            <Grid item>
                                <Grid container>
                                    <Grid item className={styles.cancelSubscriptionButtonWrapper}>
                                        <GlobalButton
                                            buttonText='Cancel Subscription'
                                            onClick={props.cancelStripeSubscription}
                                            disabled={customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_customer_subscription_status == "canceled" ? true : false}
                                            className='primaryButtonStyle'
                                        />
                                    </Grid>
                                    <Grid item className={styles.billingHistoryButtonWrapper}>
                                        <GlobalButton buttonText='Billing History & Invoices' onClick={props.getCustomerPortalLink} className='primaryButtonStyle' />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container sx={{ alignItems: "center", justifyContent: 'space-between' }}>
                            <Grid item xs={9} className={styles.bannerDetailMainItem}>
                                <Grid container>
                                    <Grid item xs={12} className={styles.bannerTrialLabelItem}>
                                        <Grid container>
                                            <Grid item className={styles.freeTrialStyle} >
                                                <Grid container>
                                                    <Grid item>
                                                        {customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_customer_subscription_flag_free_trial ? `Free Trial` : customerSubscriptionDetails?.gc_stripe_customer_subscription_plan_data.stripe_product_name}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item className={styles.dayCountTrialStyle} sx={{ marginLeft: { sm: "8px", xs: "5px" } }}>
                                                {customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_customer_subscription_flag_free_trial ?
                                                    // @ts-ignore
                                                    `${Math.floor((new Date(customerSubscriptionDetails.stripe_customer_subscription_plan_valid_till) - new Date(customerSubscriptionDetails.stripe_customer_subscription_plan_start_date)) / (1000 * 60 * 60 * 24))} days`
                                                    : customerSubscriptionDetails !== null && customerSubscriptionDetails.stripe_customer_subscription_plan_valid_till !== null && ` Valid till : ${new Date(`${customerSubscriptionDetails?.stripe_customer_subscription_plan_valid_till}`)}`
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} className={styles.bannerTrialLabelItem}>
                                        <Grid container>
                                            {customerSubscriptionDetails !== null && (customerSubscriptionDetails.stripe_customer_subscription_plan_valid_till !== null || customerSubscriptionDetails.gc_stripe_customer_subscription_transaction_data[0]?.subscription_transaction_plan_end_date !== null) &&
                                                <Grid item >
                                                    <Typography component={'span'} sx={{ fontSize: "14px", fontWeight: "600" }}>
                                                        Valid till
                                                        <Typography component={'span'} sx={{ marginLeft: { sm: "15px", xs: "5px" }, fontSize: "14px", fontWeight: "600" }}>
                                                            {getFormattedDate(customerSubscriptionDetails?.stripe_customer_subscription_plan_valid_till ?? customerSubscriptionDetails.gc_stripe_customer_subscription_transaction_data[0]?.subscription_transaction_plan_end_date)}
                                                        </Typography>
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={2} className={styles.bannerCreditItem}>
                                <span className={styles.amountStyle}>
                                    ${customerSubscriptionDetails?.stripe_customer_subscription_amount_total}/</span><span className={styles.monthlyStyle}>{customerSubscriptionDetails?.stripe_customer_subscription_interval}
                                </span>
                            </Grid>

                        </Grid>
                    </Grid>

                    {creditUsed &&
                        <Grid item>
                            <Grid container direction="column">

                                <Grid item>
                                    <Grid container>
                                        <Grid item><img src={upgrade} /></Grid>
                                        <Grid item className={styles.creditStatusText}>{creditUsed.remaining_credit_usage}/{creditUsed.total_credit_usage}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <LinearProgress
                                        className={styles.CreditUsedRail}
                                        variant="determinate"
                                        value={(creditUsed.remaining_credit_usage / creditUsed.total_credit_usage) * 100}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )
}
