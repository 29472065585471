import { Box, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography, styled } from '@mui/material';
import React, { useState } from 'react'
import { DocContext, DocContextPayload } from '../../../context/docContext';
import CloseIcon from '@mui/icons-material/Close';
import './rewritePopup.css'
import wIcon from '../../../assets/wIcon.png'
import sendIcon from '../../../assets/sendIcon.png'
import copyIcon from '../../../assets/copyIcon.png'
import styles from "../../../assets/styles/componentStyles/outputOptionCard.module.scss";

import casualIcon from '../../../assets/casualIcon.png'
import empatheticIcon from '../../../assets/empatheticIcon.png'
import compellingIcon from '../../../assets/compellingIcon.png'
import engagingIcon from '../../../assets/engagingIcon.png'
import rewriteLoader from '../../../assets/rewriteLoader.gif'
import Button from '@mui/material/Button';

import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import globalStyles from "../../../assets/styles/global.module.scss";
import Popover from '@mui/material/Popover';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { TemplateContext, TemplateContextPayload } from '../../../context/templateContext';
import CopyToClipboard from 'react-copy-to-clipboard';
import GlobalButton from '../../utility/globalButton/button';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { outputFormatObj, rewriteToneGenericListObj } from '../../../models/axiosModel';
import { emptyUserDetail, flagCookieExpired } from '../../../utils/constants';
import { useNavigate } from 'react-router';
import { checkForErrorCode, flagCreditsExhausted, getErrorMessage, isDev } from '../../../utils/constants';

interface RewritePopupInterface {
    flagParaphrase?: boolean
}
interface executePromptPayload {
    prompt_id: number,
    tone_of_voice: string,
    phrase: string,
    num_of_output: number,
    route_source_id: number,
    output_format_id: number
}
export const RewritePopup = (props: RewritePopupInterface) => {
    const navigate = useNavigate()
    const { flagRewritePopup, setFlagRewritePopup, editorSelectedText, documentEditorData, setEditorSelectedText, setDocumentEditorData, currentActiveDocDetails, ExecutePrompt, executePromptResponseParaphrase, setExecutePromptResponseParaphrase, selectedParaphraseText, setSelectedParaphraseText } = React.useContext(DocContext) as DocContextPayload;
    const { promptList, rewriteToneGenericList, routeSources, outputFormatGenericList } = React.useContext(TemplateContext) as TemplateContextPayload
    const { setError, error, setFlagShowCreditsExhausted, userDetail, setUserDetail } = React.useContext(UserContext) as UserContextPayload;
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [standardPrompts, setStandardPrompts] = useState<any>([])
    const [morePrompts, setMorePrompts] = useState<any>([])
    const [aiGeneratedData, setAiGeneratedData] = useState<any>({})
    const [flagPopupLoader, setFlagPopupLoader] = useState<boolean>(true)
    const [selectedTabName, setSelectedTabName] = useState<string>("Improve")
    const [customRewriteText, setCustomRewriteText] = useState<string>("")
    const [copyText, setCopyText] = React.useState("Copy")
    const [hoveredOption, setHoveredOption] = React.useState("")

    React.useEffect(() => {
        let toneOfVoicePrompts = rewriteToneGenericList?.filter((prompt: any) => (prompt.flag_custom == false && prompt.flag_narration == false))
        setStandardPrompts(toneOfVoicePrompts?.slice(0, 3))
        setMorePrompts(toneOfVoicePrompts?.slice(4, toneOfVoicePrompts.length - 1))
        GetAIGeneratedData(toneOfVoicePrompts[0])
    }, [])

    const handleClose = () => {
        setFlagRewritePopup(false);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [value, setValue] = React.useState(0);

    const GetPromptId = () => {
        let promptId = promptList?.filter((prompt: any) => prompt.prompt_name == "Paraphrase")[0].prompt_id
        return promptId

    }
    const GetAIGeneratedData = async (promptDetail: rewriteToneGenericListObj) => {
        setFlagPopupLoader(true)
        let payload: executePromptPayload = {
            prompt_id: GetPromptId(),
            tone_of_voice: promptDetail?.rewrite_tone_name,
            num_of_output: 5,
            phrase: editorSelectedText ? editorSelectedText : "",
            route_source_id: routeSources.find((route) => route.route_source_name == "Editor Rewrite Popup")?.route_source_id ?? 3,
            output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,

        }
        let tempAidata = { ...aiGeneratedData }
        let response = await ExecutePrompt(payload)
        if (response.status) {
            let tempArray: any = []
            response.data?.map((obj: any) => {
                tempArray.push(obj[response.prompt_output_key_name])
            })
            tempAidata[promptDetail?.rewrite_tone_name] = tempArray
            setAiGeneratedData(tempAidata)
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else if (flagCreditsExhausted(errorCode)) {
                setFlagShowCreditsExhausted(true)
            } else {
                setError({
                    ...error,
                    flagShowError: true,
                    message: getErrorMessage(errorCode, isDev(userDetail))
                });
            }
        }
        setFlagPopupLoader(false)
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        if (aiGeneratedData[standardPrompts[newValue]['prompt_name']]?.length) {
            return;
        } else {
            GetAIGeneratedData(standardPrompts[newValue])
        }
    };

    function contentLexicalObjFun(contentValue: any) {
        return {
            "root": {
                "children": contentValue,
                "direction": "ltr",
                "format": "",
                "indent": 0,
                "type": "root",
                "version": 1
            }
        }
    }

    const handleClickMore = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // const recursiveReplace = () =>{

    // }

    const replaceText = (data: any, newText: string) => {
        for (const item of data) {
            if (item?.text?.indexOf(editorSelectedText) > -1) {
                // item?.text?.indexOf(editorSelectedText)
                let updatedText = item?.text?.replace(editorSelectedText, newText)
                item['text'] = updatedText
            }
            if (item?.children?.length) {
                replaceText(item.children, newText);
            }

        }
        return data
    }

    React.useEffect(() => {
        if (standardPrompts?.length) {
            let selectedName = standardPrompts[value]['rewrite_tone_name']
            setSelectedTabName(selectedName)
        }
    }, [value, standardPrompts])

    const handleCopyToClipboard = (textToCopy: any) => {
        try {
            navigator.clipboard.writeText(textToCopy);
            setCopyText("Copied!"); // Update the button text
        } catch (error) {
            console.error("Clipboard write error:", error);
            setCopyText("Copy Failed"); // Handle copy failure
        }
    };

    const createCustomOption = async () => {
        setFlagPopupLoader(true)
        let tempStandardList = [...standardPrompts]
        tempStandardList.unshift(rewriteToneGenericList[rewriteToneGenericList?.length - 1])
        await setStandardPrompts(tempStandardList)
        setValue(0)
        let payload = {
            prompt_id: promptList?.filter((prompt: any) => prompt.prompt_name == "Custom Paraphrase JSON")[0].prompt_id,
            tone_of_voice: rewriteToneGenericList[rewriteToneGenericList?.length - 1].rewrite_tone_name,
            // num_of_output: 1,
            phrase: editorSelectedText ? editorSelectedText : "",
            user_custom_prompt: customRewriteText,
            route_source_id: routeSources.find((route) => route.route_source_name == "Editor Rewrite Popup")?.route_source_id ?? 3,
            output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
        }

        let response = await ExecutePrompt(payload)
        if (response.status) {
            let tempAidata = { ...aiGeneratedData }
            let tempArray: any = []
            response.data?.map((obj: any) => {
                tempArray.push(obj[response.prompt_output_key_name])
            })
            tempAidata["Custom Rewrite"] = tempArray
            setAiGeneratedData(tempAidata)
            setCustomRewriteText("")
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        }
        setFlagPopupLoader(false)
    }

    const addMorePrompt = async (option: any) => {
        let tempStandardList = [...standardPrompts]
        let isExist = false
        tempStandardList?.map((singlePrompt) => {
            if (singlePrompt.rewrite_tone_name == option.option) {
                isExist = true
            }
        })
        if (isExist) {
            setFlagPopupLoader(false)
            return;
        }
        tempStandardList.unshift(option)
        await setStandardPrompts(tempStandardList)
        setValue(0)
        handleClosePopover()
        setFlagPopupLoader(true)
        let payload = {
            prompt_id: GetPromptId(),
            tone_of_voice: option?.rewrite_tone_name,
            num_of_output: 5,
            phrase: editorSelectedText ? editorSelectedText : "",
            route_source_id: routeSources.find((route) => route.route_source_name == "Editor Rewrite Popup")?.route_source_id ?? 3,
            output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
        }

        let response = await ExecutePrompt(payload)
        if (response.status) {
            let tempAidata = { ...aiGeneratedData }
            let tempArray: any = []
            response.data?.map((obj: any) => {
                tempArray.push(obj[response.prompt_output_key_name])
            })
            tempAidata[option.rewrite_tone_name] = tempArray
            setAiGeneratedData(tempAidata)
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    if (flagCreditsExhausted(errorCode)) {
                        setFlagShowCreditsExhausted(true)
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                }
            }
        }
        setFlagPopupLoader(false)
    }

    const generateMoreOptionsFunction = async () => {
        setFlagPopupLoader(true)
        let tempList: any = [...standardPrompts]
        let selectedPrompt: any = tempList.filter((obj: any) => obj.rewrite_tone_name == selectedTabName)[0]
        let oldData: any = []
        let tempOld = { ...aiGeneratedData }
        tempOld[selectedPrompt['rewrite_tone_name']].map((prompt: string) => {
            let obj: any = {}
            var selectedPromptName: string = selectedPrompt['rewrite_tone_name']
            obj[selectedPromptName] = prompt
            oldData.push(obj)
        })
        let payload = {
            prompt_id: GetPromptId(),
            tone_of_voice: selectedPrompt?.rewrite_tone_name,
            phrase: editorSelectedText ? editorSelectedText : "",
            // num_of_output: 1,
            previous_response: oldData,
            route_source_id: routeSources.find((route) => route.route_source_name == "Editor Rewrite Popup")?.route_source_id ?? 3,
            output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
        }
        let response = await ExecutePrompt(payload)
        if (response.status) {
            let tempArray: any = []
            response.data?.map((obj: any) => {
                tempArray.push(obj[response.prompt_output_key_name])
            })
            let newTemp = tempOld[selectedPrompt.rewrite_tone_name].concat(tempArray)
            tempOld[selectedPrompt.rewrite_tone_name] = newTemp
            setAiGeneratedData(tempOld)
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    if (flagCreditsExhausted(errorCode)) {
                        setFlagShowCreditsExhausted(true)
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                }
            }

        }
        setFlagPopupLoader(false)
    }

    return (
        <>
            <Dialog
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={true}
                className='rewriteDialog'
            >
                <DialogContent sx={{ padding: "15px 15px" }}>
                    <Grid container alignItems={"center"} justifyContent={"start"}>
                        <Grid item md={1.5} lg={1} xl={1}>
                            <img src={wIcon} />
                            {/* <Divider orientation="vertical" flexItem /> */}
                        </Grid>
                        <Grid item md={6.5} lg={7} xl={7}>
                            <Grid container>
                                <Grid item xs={12}><p style={{ fontSize: "15px" }}>Input Text</p></Grid>
                                <Grid item xs={12}><p style={{ fontSize: "15px" }}>{editorSelectedText && editorSelectedText?.length > 40 ? editorSelectedText?.substring(0, 38) + "..." : editorSelectedText}</p></Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={4} lg={4} xl={4}>
                            <Grid container justifyContent={"flex-end"} alignItems={"center"}>
                                {/* <Grid item xl={4} textAlign={"center"}>
                                    <Chip
                                        avatar={<img src={startIcon} />}
                                        label="95"
                                        variant="outlined"
                                        sx={{ border: "1px solid #6A097D", color: "#6A097D" }}
                                        size='small'
                                        className={globalStyles.clickable}
                                    />
                                </Grid>
                                <Grid item xl={3} textAlign={"center"}>
                                    <img src={diamondIcon} className={globalStyles.clickable} />
                                </Grid> */}
                                {/* <Grid item xl={2} textAlign={"end"}>
                                    <MoreVertOutlinedIcon className={globalStyles.clickable} />
                                </Grid> */}
                                <Grid item md={3} lg={3} xl={3} textAlign={"end"}>
                                    <CloseOutlinedIcon className={globalStyles.clickable} onClick={() => { handleClose() }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <hr />

                    <Grid container>
                        <Grid item xs={12} mb={2} >
                            <Grid container>
                                <Grid item xs={10.5}>
                                    <Box sx={{ maxWidth: { xs: 320, sm: 500 } }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="icon position tabs example"
                                            indicatorColor="secondary"
                                            textColor="secondary"
                                            variant="scrollable"
                                            className='tabGroupStyle'
                                        >
                                            {standardPrompts?.map((promptData: any) => (
                                                <Tab
                                                    className='tabStyle'
                                                    icon={<img src={promptData.rewrite_tone_icon_url} style={{ height: "15px" }} />}
                                                    iconPosition="start"
                                                    label={promptData.rewrite_tone_name}
                                                />
                                            ))}
                                        </Tabs>
                                    </Box>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <Button
                                        className='rewriteMoreBtn'
                                        endIcon={<KeyboardArrowDownOutlinedIcon />}
                                        onClick={handleClickMore}
                                    >
                                        More
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className='rewriteContent' xs={12}>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                className='moreOptionPopover'
                            >
                                <Grid container>
                                    <Grid item xs={12} sx={{}}>
                                        <Grid container justifyContent={"space-between"} padding={"10px 10px 5px 10px"}>
                                            <Grid item xs={10}>{""}</Grid>
                                            <Grid item xs={2} textAlign={"end"}>
                                                <CloseOutlinedIcon
                                                    fontSize='small'
                                                    className={globalStyles.clickable} onClick={() => { handleClosePopover() }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <hr style={{ margin: 0 }} />
                                    <Grid item xs={12} className='moreOptionMainItem'>
                                        <Grid container>
                                            {morePrompts?.map((obj: any) => (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className='moreOptionItem'
                                                        onClick={() => { addMorePrompt(obj) }}
                                                    // onMouseEnter={() => { setHoveredOption(obj.prompt_name) }}
                                                    // onMouseLeave={() => { setHoveredOption("") }}
                                                    >
                                                        <img src={obj?.rewrite_tone_icon_url} style={{ verticalAlign: "middle", marginRight: "10px", height: "17px" }} />
                                                        <span style={{ fontSize: "15px" }}>{obj?.rewrite_tone_name}</span>
                                                    </Grid>
                                                </>
                                            ))}
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Popover>

                            {!flagPopupLoader ?
                                <Grid container>
                                    {aiGeneratedData[selectedTabName]?.map((obj: string) => (
                                        <Grid item xs={12} sx={{ borderBottom: "1px solid #EDEDED" }}>
                                            <Grid container justifyContent={"space-between"} sx={{ padding: "10px 0px" }} alignItems={"center"}>
                                                <Grid item md={9} lg={9.5} xl={9.5}>{obj}</Grid>
                                                <Grid item md={1} lg={1} xl={1} textAlign={"end"}>
                                                    <CopyToClipboard text={obj}>
                                                        <Tooltip title={copyText} onMouseLeave={() => setCopyText("Copy!")} onClick={() => {
                                                            setCopyText("Copied!");
                                                        }}>
                                                            <img
                                                                src={copyIcon}
                                                                className={`${styles.copyTextStyle} ${globalStyles.clickable}`}
                                                                onClick={() => handleCopyToClipboard(obj)}
                                                                onMouseLeave={() => setCopyText("Copy!")}
                                                            />
                                                        </Tooltip>
                                                    </CopyToClipboard>
                                                </Grid>
                                                <Grid item md={2} lg={1.5} xl={1.5} textAlign={"center"}>
                                                    <Typography
                                                        sx={{ color: "#6a097d", fontWeight: 600, cursor: "pointer" }}
                                                        onClick={() => {
                                                            // if (props.flagParaphrase) {
                                                            //     handleTest(obj)
                                                            // } else {
                                                            if (documentEditorData) {
                                                                let updatedData = replaceText(documentEditorData['root']['children'], obj)
                                                                updatedData = contentLexicalObjFun(updatedData)
                                                                setDocumentEditorData(updatedData)
                                                                setEditorSelectedText(obj)
                                                            }
                                                            // }
                                                        }}
                                                    >
                                                        Use
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                                :
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={rewriteLoader} style={{ height: "15px" }} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Grid container>
                        <Grid item xs={12}>
                            <GlobalButton
                                className='secondaryButtonStyle'
                                buttonText='Generate more options'
                                flagGenerate
                                disabled={flagPopupLoader}
                                onClick={() => { generateMoreOptionsFunction() }}
                            ></GlobalButton>
                            <hr style={{ margin: "0.5em 0" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                variant="outlined"
                                placeholder='Couldn’t find the right option for the input text? Tell us what..'
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <img
                                                src={sendIcon}
                                                className={globalStyles.clickable}
                                                onClick={() => { createCustomOption() }}
                                            />
                                        </InputAdornment>,
                                }}
                                value={customRewriteText}
                                disabled={flagPopupLoader}
                                className='rewriteTextField'
                                onChange={(e: any) => { setCustomRewriteText(e.target.value) }}
                            />
                        </Grid>

                    </Grid>

                </DialogActions>
            </Dialog>
        </>
    );
}
