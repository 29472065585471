import { Autocomplete, Avatar, Button, Grid, IconButton, Menu, MenuItem, Popper, Switch, TextField, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/template.module.scss";
import { PersonaContext, PersonaContextPayload } from '../../../context/personaContext';
import viewPersonaStyles from "../../../assets/styles/componentStyles/viewPersona.module.scss";
import personaAvatar from "../../../assets/personaAvatar.svg";
import GlobalButton from '../../utility/globalButton/button';
import agent from '../../../services/api';
import { GlobalSlider } from '../../utility/globalSlider';
import { GlobalCard } from '../../utility/globalCard';
import DeleteDocumentDataComponent from '../deleteDocumentDataComponent';
import createPersonaIcon from "../../../assets/createPersona.png";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GlobalPopUp from '../../utility/globalPopUp';
import premiumIcon from "../../../assets/personaCrown.svg";
import { Tooltip } from '@mui/joy';
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import AddIcon from '@mui/icons-material/Add';
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, invalidQueryParameter, isDev } from '../../../utils/constants';

const ViewPersonaDetails = () => {

    const StyledPopper = styled(Popper)(({ theme }) => ({
        '& .MuiListSubheader-root': {
            paddingLeft: "12px"
        },

        '& .MuiAutocomplete-option': {
            paddingLeft: "4px !important",
            paddingRight: "4px !important"
        },
    }))

    const {
        setFlagLoading,
        setError,
        error,
        flagLoading,
        setSuccess,
        success,
        setUserDetail,

    } = useContext(UserContext) as UserContextPayload;

    const { GetPersonaDetails,
        personaList,
        GetPersonaList,
        currentActivePersonaDetails,
        personaWritingSkills,
        GetPersonaWritingSkills,
        personaSentenceLength,
        GetPersonaSentenceLength,
        personaNarrativeStyle,
        GetPersonaNarrativeStyle,
        personaCommunicationType,
        GetPersonaCommunicationType,
        getDefaultPersonaTypeList,
        defaultPersonaTypeList,
        setCurrentActivePersonaDetails
    } = useContext(PersonaContext) as PersonaContextPayload;



    // STATE VARIABLE
    const [flagDeletePersonaDataPopup, setFlagDeletePersonaDataPopup] = useState(false)
    const [personaNameMenu, setPersonaNameMenu] = React.useState<null | HTMLElement>(null);
    const [personaOptionsMenu, setPersonaOptionsMenu] = React.useState<null | HTMLElement>(null);
    const { userDetail, setFlagShowCreditsExhausted } = useContext(UserContext) as UserContextPayload;
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const [selectedPersona, setSelectedPersona] = React.useState<any>(null);


    // CONST
    const theme = useTheme();
    const navigate = useNavigate()

    const isMobileScreen = useMediaQuery("only screen and (min-width : 0px) and (max-width : 600px)")
    const isTabScreen = useMediaQuery("only screen and (min-width : 601px) and (max-width : 1200px)")
    const isDesktopAndLaptopScreen = useMediaQuery("only screen and (min-width : 1201px)")
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const open = Boolean(personaNameMenu);

    const toneOfVoiceData = currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_writing_traits !== null &&
        JSON.parse(currentActivePersonaDetails.persona_writing_traits.persona_tone_of_voice).map((item: any) => ({
            text: item
        }));

    const personaJargons = currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_writing_traits !== null &&
        JSON.parse(currentActivePersonaDetails.persona_writing_traits.persona_jargons).map((item: any) => ({
            text: item
        }));

    const personaPersonalTraits = currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_writing_traits !== null &&
        currentActivePersonaDetails.persona_personal_traits && currentActivePersonaDetails.persona_personal_traits.persona_interests &&
        JSON.parse(currentActivePersonaDetails.persona_personal_traits.persona_interests).map((item: any) => ({
            text: item
        }));


    // USEEFFECTS:
    useEffect(() => {
        (async () => {
            if (searchParams) {
                let persona_id = Number(searchParams.get("persona_id"));
                if (persona_id) {
                    setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
                    if (personaList == null) {
                        let response = await GetPersonaList()
                    }
                    const response = await GetPersonaDetails(persona_id)
                    if (!response.status) {
                        if (invalidQueryParameter(response)) {
                            navigate("/dashboard/persona")
                        } else {
                            setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                        }
                    } else {
                        if (response.data) {

                            setCurrentActivePersonaDetails(response.data[0])

                        }

                    }
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                } else {
                    navigate("/dashboard")
                }
            } else {
                navigate("/dashboard")
            }
        })()

    }, [searchParams.get('persona_id')])

    useEffect(() => {
        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
            await getDefaultPersonaTypeList()
            let res = await GetPersonaWritingSkills()
            let res1 = await GetPersonaSentenceLength()
            let res2 = await GetPersonaNarrativeStyle()
            let res3 = await GetPersonaCommunicationType()
            if (res.status && res1.status && res2.status && res3.status) {
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
            } else {
                setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
            }

        })()
    }, [])

    useEffect(() => {
        getPersonaInitialVal()
    }, [currentActivePersonaDetails])


    // FUNCTIONS:
    const handleClickPersonaMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPersonaNameMenu(event.currentTarget);
    };

    const handleClosePersonaMenu = () => {
        setPersonaNameMenu(null);
    };

    const getPersonaInitialVal = () => {
        if (currentActivePersonaDetails) {
            if (currentActivePersonaDetails.persona_id) {
                if (personaList) {
                    setSelectedPersona(personaList.filter((persona) => persona.persona_id == currentActivePersonaDetails.persona_id)[0])
                } else {
                    setSelectedPersona(null)
                    return ""
                }
            }
            else {
                const defaultPersona = personaList?.find((persona) => persona.user_default_persona_data.find((personaData) => personaData.default_persona_type_name == "General"));
                const personaId = defaultPersona?.persona_id ?? null;

                if (personaId) {
                    if (personaList) {
                        setSelectedPersona(personaList.filter((persona) => persona.persona_id == personaId)[0])

                    } else {
                        setSelectedPersona(null)
                        return ""
                    }
                } else {
                    setSelectedPersona(null)
                    return ""
                }
            }
        } else {
            return ""
        }
    }

    const handleChangeDefaultPersona = async (event: React.ChangeEvent<HTMLInputElement>, persona_type_id: number) => {
        setFlagLoading((prevState) => {
            return { ...prevState, flagRoundLoader: true }
        });
        if (event.target.checked) {
            if (currentActivePersonaDetails) {
                const payload = {
                    persona_id: currentActivePersonaDetails?.persona_id,
                    default_persona_type_id: persona_type_id
                }
                const response = await agent.markDefaultPersona.put(payload)
                if (response.status) {
                    let persona_id = Number(searchParams.get("persona_id"));
                    if (persona_id) {
                        const response = await GetPersonaDetails(persona_id)
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                        if (!response.status) {
                            setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                            setCurrentActivePersonaDetails(null)
                        } else {
                            if (response.data) {
                                setCurrentActivePersonaDetails(response.data[0])
                            }
                        }
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                    }
                } else {
                    let errorCode = checkForErrorCode(response)
                    if (flagCookieExpired(userDetail, errorCode)) {
                        setUserDetail(emptyUserDetail)
                        if (userDetail.flag_user_anonymous) {
                            let url = window.location.pathname
                            navigate(url, { replace: true });
                        } else {
                            setError({
                                ...error,
                                flagShowError: true,
                                message: "Session expired! Please login to continue."
                            });
                            setTimeout(() => {
                                let url = encodeURIComponent(window.location.href)
                                navigate("/login?redirect=" + url, { replace: true });
                            }, 1500);
                        }
                    } else {
                        if (flagCreditsExhausted(errorCode)) {
                            setFlagShowCreditsExhausted(true)
                        } else {
                            setError({
                                ...error,
                                flagShowError: true,
                                message: getErrorMessage(errorCode, isDev(userDetail))
                            });
                        }
                    }
                }
            }
        } else {
            if (currentActivePersonaDetails) {
                const response = await agent.removeDefaultPersona.delete(persona_type_id.toString(), currentActivePersonaDetails.persona_id.toString())
                if (response.status) {
                    let persona_id = Number(searchParams.get("persona_id"));
                    if (persona_id) {
                        setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
                        const response = await GetPersonaDetails(persona_id)
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                        if (!response.status) {
                            setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                            setCurrentActivePersonaDetails(null)
                        } else {
                            if (response.data) {
                                setCurrentActivePersonaDetails(response.data[0])
                            }
                        }
                    }
                } else {
                    let errorCode = checkForErrorCode(response)
                    if (flagCookieExpired(userDetail, errorCode)) {
                        setUserDetail(emptyUserDetail)
                        if (userDetail.flag_user_anonymous) {
                            let url = window.location.pathname
                            navigate(url, { replace: true });
                        } else {
                            setError({
                                ...error,
                                flagShowError: true,
                                message: "Session expired! Please login to continue."
                            });
                            setTimeout(() => {
                                let url = encodeURIComponent(window.location.href)
                                navigate("/login?redirect=" + url, { replace: true });
                            }, 1500);
                        }
                    } else {
                        if (flagCreditsExhausted(errorCode)) {
                            setFlagShowCreditsExhausted(true)
                        } else {
                            setError({
                                ...error,
                                flagShowError: true,
                                message: getErrorMessage(errorCode, isDev(userDetail))
                            });
                        }
                    }
                }
            }
        }
        setFlagLoading((prevState) => {
            return { ...prevState, flagRoundLoader: false }
        });

    };

    const handleChangePersona = async (newValue: any) => {
        if (newValue !== null) {
            navigate(`/dashboard/viewPersona?persona_id=${newValue.persona_id}`);
            setSelectedPersona(newValue)
        }
    }

    const deleteDocumentDataPopupOpen = () => {
        setFlagDeletePersonaDataPopup(true)
    }

    const deleteCancelPersonaDataPopupOpen = () => {
        setFlagDeletePersonaDataPopup(false)
    }

    const handleDeletePersona = async (data: any) => {
        setFlagLoading((prevState) => {
            return { ...prevState, flagRoundLoader: true }
        });
        const response = await agent.deletePersona.delete(data)
        if (response.status) {
            deleteCancelPersonaDataPopupOpen()
            setSuccess({ ...success, flagShowSuccess: true, message: "Persona Deleted Successfully!" })
            await GetPersonaList()
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            navigate("/dashboard/persona")
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        }
        setFlagLoading((prevState) => {
            return { ...prevState, flagRoundLoader: false }
        });
    }

    return (
        <>
            {currentActivePersonaDetails != null &&
                <>
                    {flagDeletePersonaDataPopup &&
                        <GlobalPopUp
                            open={flagDeletePersonaDataPopup}
                            onClose={() => { setFlagDeletePersonaDataPopup(false) }}
                            title={""}
                            flagShowCloseIcon={true}
                            handleClose={() => { setFlagDeletePersonaDataPopup(false) }}
                        >
                            <DeleteDocumentDataComponent
                                documentSelected={currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_id}
                                deleteCancelDocumentDataPopupOpen={deleteCancelPersonaDataPopupOpen}
                                handleDeleteDocument={handleDeletePersona}
                                deleteDescription="Are you sure you want to delete this persona." />
                        </GlobalPopUp>
                    }

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateWrapper}`}>
                        <Grid container direction={"column"} alignItems={"center"} justifyContent={"start"}>

                            {/* title header */}
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateSearchbarStyle} ${styles.templateItem}`}>
                                <Grid container direction="column" className={viewPersonaStyles.personaViewChildWrapper}>
                                    <Grid item xs={12} sx={{ mb: "15px" }}>
                                        <Grid container className={viewPersonaStyles.personaViewheaderContainer}>
                                            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} >
                                                <GlobalPageHeaderText
                                                    mainHeadText={"Persona"}
                                                    mainSubText={"Customize your AI experience to your persona and writing style"}
                                                />
                                            </Grid>

                                            {isDesktopAndLaptopScreen ? (
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                    <Grid container className={viewPersonaStyles.personaViewNewPersonaBtnMainContainer}>
                                                        {(!userDetail.flag_user_anonymous && userDetail.flag_email_verified) ?
                                                            (<Grid item className={`${globalStyles.globalNewAddBtnItem}`}>
                                                                <Button
                                                                    variant='outlined'
                                                                    size='large'
                                                                    fullWidth
                                                                    startIcon={<img src={createPersonaIcon} alt="" style={{ height: "18px", width: 'auto' }} />}
                                                                    className={globalStyles.addDocumentBtn}
                                                                    onClick={() => { navigate("/dashboard/createPersona") }}
                                                                >
                                                                    Create New Persona
                                                                </Button>
                                                            </Grid>) : (<></>)
                                                        }
                                                    </Grid>
                                                </Grid>
                                            ) : (isTabScreen ? (
                                                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                    <Grid container className={viewPersonaStyles.personaViewNewPersonaBtnMainContainer}>
                                                        {(!userDetail.flag_user_anonymous && userDetail.flag_email_verified) ?
                                                            (<Grid item className={`${globalStyles.globalNewAddBtnItem}`}>
                                                                <Button
                                                                    variant='outlined'
                                                                    size='large'
                                                                    fullWidth
                                                                    startIcon={<img src={createPersonaIcon} alt="" style={{ height: "18px", width: 'auto' }} />}
                                                                    className={globalStyles.addDocumentBtn}
                                                                    onClick={() => { navigate("/dashboard/createPersona") }}
                                                                >
                                                                    Create New Persona
                                                                </Button>
                                                            </Grid>) : (<></>)
                                                        }
                                                    </Grid>
                                                </Grid>
                                            ) : (isMobileScreen &&
                                                <Grid item xs={12}>
                                                    <IconButton
                                                        className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                                        onClick={() => { navigate("/dashboard/createPersona") }}
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Grid>
                                            )
                                            )}
                                        </Grid>
                                    </Grid>

                                    <Grid item className={viewPersonaStyles.personadetailsGrid}>
                                        <Grid container className={viewPersonaStyles.viewPersonaFullContainer} direction={"column"}>
                                            <Grid item>
                                                <Grid container justifyContent={"space-between"}>
                                                    <Grid item xl={6} lg={6} md={11} sm={10.5} xs={10.5}>
                                                        <Grid container className={viewPersonaStyles.personaViewHeaderStyle}>
                                                            <Grid item xl={6} lg={6} md={6} sm={8} xs={8} className={viewPersonaStyles.personaViewdropdownstyle}>
                                                                <Grid container className={viewPersonaStyles.personaViewHeader}>
                                                                    <Grid item style={{ width: "100%" }}>


                                                                        <Autocomplete
                                                                            PopperComponent={StyledPopper}
                                                                            fullWidth
                                                                            className={viewPersonaStyles.personaDropdown}
                                                                            options={personaList || []}
                                                                            value={selectedPersona}
                                                                            groupBy={(option) => option.persona_sub_category__name}
                                                                            onChange={(_, newValue) => handleChangePersona(newValue)}
                                                                            getOptionLabel={(option) =>
                                                                                option.persona_name
                                                                                    ? option.persona_display_name ? `${option.persona_name}, ${option.persona_display_name}` : option.persona_name
                                                                                    : option.persona_sub_category__name
                                                                            }
                                                                            filterOptions={(options, { inputValue }) => {
                                                                                const filteredOptions = options.filter((option) => {
                                                                                    const personaName = option.persona_name || '';
                                                                                    const subCategoryName = option.persona_sub_category__name || '';

                                                                                    return (
                                                                                        personaName.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                                                        subCategoryName.toLowerCase().includes(inputValue.toLowerCase())
                                                                                    );
                                                                                });

                                                                                return filteredOptions;
                                                                            }}
                                                                            getOptionDisabled={(option) =>
                                                                                option.persona_premium_flag && ((userDetail.plan_detail && userDetail.plan_detail.credit_usage_data !== null && userDetail?.plan_detail?.credit_usage_data.stripe_product_name == "Free") || userDetail.flag_user_anonymous)
                                                                            }
                                                                            renderOption={(props, option) => (
                                                                                <li {...props}>
                                                                                    <Tooltip
                                                                                        sx={{ padding: "0 !important" }}
                                                                                        className={viewPersonaStyles.PersonaDescpTooltip}
                                                                                        title={<>
                                                                                            <Grid container className={viewPersonaStyles.personaMenuContainer} alignItems={"start"}>
                                                                                                <Grid item>
                                                                                                    <img
                                                                                                        src={option.persona_avatar_full_size_url ?? option.persona_avatar_url ?? personaAvatar}
                                                                                                        className={viewPersonaStyles.personaAvatarStyle}
                                                                                                    />
                                                                                                </Grid>
                                                                                                <Grid item className={viewPersonaStyles.leftBlock}>
                                                                                                    <Grid container direction={"column"}>
                                                                                                        <Grid item className={viewPersonaStyles.personaNameStyle}>{option.persona_name}</Grid>
                                                                                                        <Grid item className={viewPersonaStyles.PersonaDisplayNameStyle}>{option.persona_display_name}</Grid>
                                                                                                        <Grid item className={viewPersonaStyles.personaSummaryStyle}>{option.about_persona} </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </>}
                                                                                        placement="right">
                                                                                        <li {...props}
                                                                                            style={{
                                                                                                paddingLeft: "4px !important",
                                                                                                paddingRight: "4px !important",
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                fontSize: "15px",
                                                                                                fontWeight: "500",
                                                                                                width: "100%",

                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={{ pointerEvents: "none", display: "flex" }}>
                                                                                                <img
                                                                                                    style={{ width: "37px", height: "37px", borderRadius: "50%", marginRight: "10px" }}
                                                                                                    src={option.persona_avatar_full_size_url ?? option.persona_avatar_url ?? personaAvatar}
                                                                                                />
                                                                                            </div>

                                                                                            <div style={{ display: "flex", gap: "15px", width: "100%" }}>

                                                                                                <div style={{ width: "100%", whiteSpace: "pre-wrap", pointerEvents: "none", display: "flex", }}>
                                                                                                    {option.persona_display_name ? `${option.persona_name}, ${option.persona_display_name}` : option.persona_name}
                                                                                                </div>

                                                                                                {option.persona_premium_flag ?
                                                                                                    (<div style={{ width: "100%", display: "flex", justifyContent: "end", pointerEvents: "none", maxWidth: "25px" }}>
                                                                                                        <img src={premiumIcon} style={{ width: "25px", height: "auto" }} />
                                                                                                    </div>) : (
                                                                                                        <div style={{ width: "100%", display: "flex", justifyContent: "end", pointerEvents: "none", maxWidth: "25px" }}>

                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        </li>
                                                                                    </Tooltip>
                                                                                </li>
                                                                            )}
                                                                            renderInput={(params) => {
                                                                                return (
                                                                                    <>
                                                                                        <TextField
                                                                                            placeholder={"Select Persona"}
                                                                                            {...params}
                                                                                            variant="standard"
                                                                                            className={viewPersonaStyles.personaDropdown}
                                                                                            InputProps={{
                                                                                                ...params.InputProps,
                                                                                                startAdornment: (
                                                                                                    <>
                                                                                                        {selectedPersona && (
                                                                                                            <Avatar
                                                                                                                src={selectedPersona.persona_avatar_full_size_url ?? selectedPersona.persona_avatar_url ?? personaAvatar}
                                                                                                                alt="Selected Persona"
                                                                                                                style={{ width: "30px", height: "30px", marginRight: "10px" }}
                                                                                                            />
                                                                                                        )}
                                                                                                    </>
                                                                                                ),
                                                                                            }}
                                                                                        />
                                                                                    </>
                                                                                )
                                                                            }}

                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item className={`${viewPersonaStyles.individualPersonaButton}`} >
                                                                {currentActivePersonaDetails?.persona_type_name || ''}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item className={viewPersonaStyles.updatePersonaButtonPhone}>
                                                        <Button
                                                            id="basic-button"
                                                            aria-controls={open ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                            onClick={handleClickPersonaMenu}
                                                            className={viewPersonaStyles.personaOccupationButton}
                                                        >
                                                            <Grid display="flex" alignItems="end"><MoreVertIcon /></Grid>
                                                        </Button>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={personaNameMenu}
                                                            open={open}
                                                            className={viewPersonaStyles.updatePersonaMenuPhone}
                                                            onClose={handleClosePersonaMenu}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'basic-button',
                                                            }}
                                                        >
                                                            <MenuItem className={viewPersonaStyles.personaNameInMenu}>
                                                                <Grid container direction="column">
                                                                    <Grid item>
                                                                        Set as Default
                                                                    </Grid>
                                                                    {defaultPersonaTypeList !== null && defaultPersonaTypeList.map((personaType) => {

                                                                        return (
                                                                            <Grid item className={viewPersonaStyles.personaDefaultNamesInMenu}>
                                                                                <Grid container justifyContent="space-between" alignItems="center">
                                                                                    <Grid item>
                                                                                        {personaType.default_persona_type_name}
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Switch {...label} className={viewPersonaStyles.personaDefaultSwitch}
                                                                                            checked={currentActivePersonaDetails.user_default_persona_data.find((currentActivePersonaType) => currentActivePersonaType.default_persona_type_name == personaType.default_persona_type_name) ? true : false}
                                                                                            disabled={(userDetail.flag_user_anonymous || (userDetail.plan_detail ? userDetail.plan_detail.credit_usage_data !== null && userDetail.plan_detail.credit_usage_data.stripe_product_name == "Free" : true))}
                                                                                            color="secondary"
                                                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeDefaultPersona(event, personaType.default_persona_type_id)} />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )
                                                                    })}
                                                                </Grid>
                                                            </MenuItem>
                                                            {currentActivePersonaDetails.persona_flag_public !== true ?
                                                                (<>
                                                                    <MenuItem
                                                                        onClick={() => {
                                                                            navigate(`/dashboard/updatePersona?persona_id=${Number(searchParams.get("persona_id"))}`)
                                                                        }}
                                                                        className={viewPersonaStyles.personaNameInMenu}
                                                                    >
                                                                        Update Persona
                                                                    </MenuItem>
                                                                    <MenuItem
                                                                        onClick={deleteDocumentDataPopupOpen}
                                                                        className={viewPersonaStyles.personaNameInMenu}
                                                                    >
                                                                        Delete Persona
                                                                    </MenuItem>
                                                                </>) : (<></>)
                                                            }
                                                        </Menu>
                                                    </Grid>

                                                    <Grid item className={viewPersonaStyles.personaStyleMobile}>
                                                        <Grid container>
                                                            <Grid item className={viewPersonaStyles.personaDefaultStyle}>

                                                                {defaultPersonaTypeList !== null && defaultPersonaTypeList.map((personaType) => (
                                                                    <>
                                                                        {currentActivePersonaDetails.persona_premium_flag &&
                                                                            <span>
                                                                                <img src={premiumIcon}
                                                                                    style={{ transform: "translate(-4px,4px)" }} />
                                                                            </span>
                                                                        }

                                                                        <span className={viewPersonaStyles.personaDefaultText}>
                                                                            {`Set as Default (${(personaType.default_persona_type_name)})`}
                                                                        </span>

                                                                        <Switch
                                                                            {...label}
                                                                            className={viewPersonaStyles.personaDefaultSwitch}
                                                                            checked={currentActivePersonaDetails.user_default_persona_data.find((currentActivePersonaType) => currentActivePersonaType.default_persona_type_name == personaType.default_persona_type_name) ? true : false}
                                                                            disabled={(userDetail.flag_user_anonymous || (userDetail.plan_detail ? userDetail.plan_detail.credit_usage_data !== null && userDetail.plan_detail.credit_usage_data.stripe_product_name == "Free" : true))}
                                                                            color="secondary"
                                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeDefaultPersona(event, personaType.default_persona_type_id)}
                                                                        />
                                                                    </>
                                                                ))
                                                                }

                                                            </Grid>
                                                            <Grid item className={viewPersonaStyles.updatePersonaButton} >

                                                                {currentActivePersonaDetails.persona_flag_public !== true ?
                                                                    (
                                                                        <>
                                                                            <GlobalButton
                                                                                buttonText=''
                                                                                flagUpdatePersona
                                                                                flagFullWidth
                                                                                className='primaryButtonStylePersona'
                                                                                onClick={() => {
                                                                                    navigate(`/dashboard/updatePersona?persona_id=${Number(searchParams.get("persona_id"))}`)
                                                                                }}
                                                                            />
                                                                            <GlobalButton
                                                                                buttonText=''
                                                                                flagDeletePersona
                                                                                flagFullWidth
                                                                                className='primaryButtonStylePersona'
                                                                                onClick={deleteDocumentDataPopupOpen}
                                                                            />
                                                                        </>
                                                                    ) : ("")
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item>
                                                <Grid container className={viewPersonaStyles.fullDetailsContainer}>
                                                    <Grid item xl={3} lg={4.2} md={6.8} sm={12} xs={12}>
                                                        <Grid container direction="column" >
                                                            {currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_avatar_url ?
                                                                (
                                                                    <Grid item>
                                                                        <Grid container sx={{ justifyContent: "center" }}>
                                                                            <Grid item className={viewPersonaStyles.personaAvatarItem}>
                                                                                <img src={currentActivePersonaDetails.persona_avatar_url} className={viewPersonaStyles.personaAvatarBackground} />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                ) : (
                                                                    <Grid item>
                                                                        <Grid container sx={{ justifyContent: "center" }}>
                                                                            <Grid item className={viewPersonaStyles.personaAvatarItem}>
                                                                                <img src={personaAvatar} className={viewPersonaStyles.greyBackground} />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            }
                                                            <Grid item>
                                                                <Grid container>
                                                                    {
                                                                        currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_writing_traits !== null && currentActivePersonaDetails.persona_writing_traits.persona_tone_of_voice && toneOfVoiceData.length ?
                                                                            (toneOfVoiceData.length && toneOfVoiceData.map((ele: any) => (
                                                                                <Grid item className={viewPersonaStyles.chipsStylePersonaHighlight} key={ele.text}>
                                                                                    {ele.text}
                                                                                </Grid>
                                                                            ))
                                                                            ) : ("")
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            {currentActivePersonaDetails !== null && currentActivePersonaDetails.about_persona && currentActivePersonaDetails.about_persona != null ?
                                                                (
                                                                    <Grid item className={viewPersonaStyles.fullItemDetails}>
                                                                        <GlobalCard
                                                                            heading="About Persona"
                                                                            description={currentActivePersonaDetails !== null && currentActivePersonaDetails.about_persona}
                                                                            height="auto"
                                                                            maxHeight="146px"
                                                                        />
                                                                    </Grid>
                                                                ) : ("")
                                                            }
                                                            <Grid item className={viewPersonaStyles.fullItemDetailsList}>
                                                                <Grid container>
                                                                    <Grid item xl={5} lg={5} md={5} sm={5} xs={5} className={viewPersonaStyles.heightFitStyle}>
                                                                        <Grid container direction={"column"}>
                                                                            <Grid item className={viewPersonaStyles.userData}>Age</Grid>
                                                                            <Grid item className={viewPersonaStyles.userData}>Gender</Grid>
                                                                            <Grid item className={viewPersonaStyles.userData}>Location</Grid>
                                                                            <Grid item className={viewPersonaStyles.userData}>Occupation</Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xl={7} lg={7} md={7} sm={7} xs={7} className={viewPersonaStyles.heightFitStyle}>
                                                                        <Grid container direction={"column"} className={viewPersonaStyles.fullItemDetailsListAnswer}>
                                                                            <Grid item className={viewPersonaStyles.userDataAnswer}>{currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_age != null ? currentActivePersonaDetails.persona_age : "-"}</Grid>
                                                                            <Grid item className={viewPersonaStyles.userDataAnswer}>{currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_gender != null ? currentActivePersonaDetails.persona_gender : "-"}</Grid>
                                                                            <Grid item className={viewPersonaStyles.userDataAnswer}>{currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_location != null ? currentActivePersonaDetails.persona_location : "-"}</Grid>
                                                                            <Grid item className={viewPersonaStyles.userDataAnswer}>{currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_occupation != null ? currentActivePersonaDetails.persona_occupation : "-"}</Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xl={5.5} lg={7.3} md={4.8} sm={12} xs={12} sx={{ marginTop: { xl: "0px", lg: "0px", md: "0px", sm: "15px", xs: "15px" } }}>
                                                        <Grid container >
                                                            {currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_background !== null ?
                                                                (
                                                                    <Grid item className={viewPersonaStyles.fullItemDetails}>
                                                                        <GlobalCard heading="Persona Background" description={currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_background} height="auto" />
                                                                    </Grid>
                                                                ) : ("")
                                                            }

                                                            {currentActivePersonaDetails !== null && currentActivePersonaDetails?.persona_goals_objectives && JSON.parse(currentActivePersonaDetails.persona_goals_objectives).length ?
                                                                (
                                                                    <Grid item className={viewPersonaStyles.fullItemDetails}>
                                                                        <GlobalCard
                                                                            heading="Goals/Objectives"
                                                                            height="auto"
                                                                            bullet={currentActivePersonaDetails !== null ? currentActivePersonaDetails?.persona_goals_objectives ? JSON.parse(currentActivePersonaDetails.persona_goals_objectives) : [] : []}
                                                                        />
                                                                    </Grid>
                                                                ) : ("")
                                                            }

                                                            {currentActivePersonaDetails !== null && currentActivePersonaDetails?.persona_targeted_audience && JSON.parse(currentActivePersonaDetails.persona_targeted_audience).length ?
                                                                <Grid item className={viewPersonaStyles.fullItemDetails}>
                                                                    <GlobalCard
                                                                        heading="Targeted Audience"
                                                                        height="auto"
                                                                        bullet={currentActivePersonaDetails !== null ? currentActivePersonaDetails?.persona_targeted_audience ? JSON.parse(currentActivePersonaDetails.persona_targeted_audience) : [] : []}
                                                                    />
                                                                </Grid> : ""
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xl={3} lg={7} md={7} sm={12} xs={12} sx={{ marginTop: { xl: "0px", lg: "15px", md: "15px", sm: "15px", xs: "15px" } }}>
                                                        <Grid container>
                                                            <Grid item className={viewPersonaStyles.fullItem}>
                                                                <Grid container direction="column">
                                                                    {personaWritingSkills != null &&
                                                                        currentActivePersonaDetails.persona_writing_traits !== null &&
                                                                        currentActivePersonaDetails.persona_writing_traits &&
                                                                        currentActivePersonaDetails.persona_writing_traits.persona_reading_level_name != null &&
                                                                        (<>
                                                                            <Grid item className={viewPersonaStyles.personaBackgroundText}>
                                                                                Reading Ease
                                                                            </Grid>
                                                                            <Grid item>

                                                                                <GlobalSlider
                                                                                    markedValue={currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_writing_traits}
                                                                                    values={personaWritingSkills != null && personaWritingSkills}
                                                                                />
                                                                            </Grid>
                                                                        </>)
                                                                    }
                                                                    {personaSentenceLength != null &&
                                                                        currentActivePersonaDetails.persona_writing_traits !== null &&
                                                                        currentActivePersonaDetails.persona_writing_traits &&
                                                                        currentActivePersonaDetails.persona_writing_traits.persona_sentence_length_type != null &&
                                                                        (<>
                                                                            <Grid item className={viewPersonaStyles.personaBackgroundText}>
                                                                                Sentence length
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <GlobalSlider
                                                                                    markedValue={currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_writing_traits}
                                                                                    values={personaSentenceLength != null && personaSentenceLength}
                                                                                />
                                                                            </Grid>
                                                                        </>)
                                                                    }
                                                                    {personaNarrativeStyle != null &&
                                                                        currentActivePersonaDetails.persona_writing_traits !== null &&
                                                                        currentActivePersonaDetails.persona_writing_traits &&
                                                                        currentActivePersonaDetails.persona_personal_traits.persona_narrative_style_type != null &&
                                                                        (<>
                                                                            <Grid item className={viewPersonaStyles.personaBackgroundText}>
                                                                                Narrative Style
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <GlobalSlider
                                                                                    markedValue={currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_personal_traits}
                                                                                    values={personaNarrativeStyle != null && personaNarrativeStyle}
                                                                                />
                                                                            </Grid>
                                                                        </>)
                                                                    }
                                                                    {personaCommunicationType != null &&
                                                                        currentActivePersonaDetails.persona_writing_traits !== null &&
                                                                        currentActivePersonaDetails.persona_writing_traits &&
                                                                        currentActivePersonaDetails.persona_personal_traits.persona_communication_style_type &&
                                                                        (<Grid item>
                                                                            <Grid item className={viewPersonaStyles.personaBackgroundText}>
                                                                                Communication Style
                                                                            </Grid>
                                                                            <GlobalSlider
                                                                                markedValue={currentActivePersonaDetails !== null && currentActivePersonaDetails.persona_personal_traits}
                                                                                values={personaCommunicationType != null && personaCommunicationType}
                                                                            />
                                                                        </Grid>)
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item className={viewPersonaStyles.fullItemBottom}>
                                                                <Grid container direction="column">
                                                                    {currentActivePersonaDetails !== null &&
                                                                        currentActivePersonaDetails.persona_writing_traits !== null &&
                                                                        currentActivePersonaDetails.persona_writing_traits &&
                                                                        currentActivePersonaDetails.persona_writing_traits !== null && currentActivePersonaDetails.persona_writing_traits.persona_tone_of_voice ?
                                                                        (<>
                                                                            <Grid item className={viewPersonaStyles.personaBackgroundText}>
                                                                                Tone of Voice
                                                                            </Grid>
                                                                            <Grid container>
                                                                                {toneOfVoiceData.length && toneOfVoiceData.map((ele: any) => (
                                                                                    <Grid item className={viewPersonaStyles.chipsStyle} key={ele.text}>
                                                                                        {ele.text}
                                                                                    </Grid>
                                                                                ))
                                                                                }
                                                                            </Grid>
                                                                        </>) : (null)
                                                                    }
                                                                    {currentActivePersonaDetails !== null &&
                                                                        currentActivePersonaDetails.persona_writing_traits !== null &&
                                                                        currentActivePersonaDetails.persona_writing_traits &&
                                                                        currentActivePersonaDetails.persona_writing_traits !== null && currentActivePersonaDetails.persona_writing_traits.persona_tone_of_voice ?
                                                                        (<>
                                                                            <Grid item className={viewPersonaStyles.personaBackgroundText}>
                                                                                Jargons
                                                                            </Grid>
                                                                            <Grid container>

                                                                                {personaJargons.length && personaJargons.map((ele: any) => (
                                                                                    <Grid item className={viewPersonaStyles.chipsStyle} key={ele.text}>
                                                                                        {ele.text}
                                                                                    </Grid>
                                                                                ))
                                                                                }
                                                                            </Grid>
                                                                        </>) : (null)
                                                                    }
                                                                    {currentActivePersonaDetails !== null &&
                                                                        currentActivePersonaDetails.persona_writing_traits !== null &&
                                                                        currentActivePersonaDetails.persona_writing_traits &&
                                                                        currentActivePersonaDetails.persona_writing_traits !== null && currentActivePersonaDetails.persona_writing_traits.persona_tone_of_voice && personaPersonalTraits != null ?
                                                                        (<>
                                                                            <Grid item className={viewPersonaStyles.personaBackgroundText}>
                                                                                Interests
                                                                            </Grid>
                                                                            <Grid container>
                                                                                {personaPersonalTraits.length && personaPersonalTraits.map((ele: any) => (
                                                                                    <Grid item className={viewPersonaStyles.chipsStyle} key={ele.text}>
                                                                                        {ele.text}
                                                                                    </Grid>
                                                                                ))}

                                                                            </Grid>
                                                                        </>) : (null)
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid >
                </>
            }
        </>
    )
}

export default ViewPersonaDetails;