import { Autocomplete, Button, Chip, Divider, Grid, Paper, Tab, Tabs, TextField, Tooltip, Checkbox, Skeleton, useMediaQuery, Box, IconButton, styled, Popper } from '@mui/material';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/paraphrasing.module.scss";
import { Textarea, Typography } from '@mui/joy';
import GlobalTextField from '../../utility/globalTextfield';
import { TemplateContext, TemplateContextPayload } from '../../../context/templateContext';
import GlobalButton from '../../utility/globalButton/button';
import agent from '../../../services/api';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { ExecutePromptResponseDataModel, GetPersonaWritingSkillsModel, executePromptResponseParaphraseObj, executePromptResponseParaphraseValueObj, outputFormatObj, rewriteToneGenericListObj, workDomainObj } from '../../../models/axiosModel';
import CopyToClipboard from "react-copy-to-clipboard";
import wIcon from '../../../assets/images/icons/wwIcon.png';
import { RewritePopup } from '../../Editor/customComponent/rewritePopup';
import { DocContext, DocContextPayload } from '../../../context/docContext';
import shine from "../../../assets/shine.png"
import { PersonaContext, PersonaContextPayload } from '../../../context/personaContext';
import personaPremiumIcon from "../../../assets/personaCrown.svg";
import personaAvatar from "../../../assets/personaAvatar.svg";
import { GlobalTooltip } from '../../utility/globalTooltip';
import Required from '../../utility/required';
import PhoneIcon from '@mui/icons-material/Phone';
import IconsColor from '../../utility/iconsColor';
import closeArrow from "../../../assets/closeArrow.png";
import CloseIcon from '@mui/icons-material/Close';
import checkedIcon from "../../../assets/checkedCircularIcon.svg";
import unCheckedIcon from "../../../assets/uncheckedCircularIcon.svg";
import deleteIcon from "../../../assets/deleteChatbotDomain.svg";
import { Category, Height } from '@mui/icons-material';
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import SplitPane from "react-split-pane";
import reWriteIcon from '../../../assets/RewriteMobileIcon.svg'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './paraphrasingCarousel.css'
import Carousel from "react-elastic-carousel";
import ReactMarkdown from "react-markdown";
import { useRef } from 'react';
import { ParaphraseContext, ParaphraseContextPayload } from '../../../context/paraphraseContext';
import BasicParaphrasingTab from './basicParaphrasing';
import AdvanceParaphrasingTab from './advanceParaphrasing';
import CustomParaphrasingTab from './customParaphrasingTab';
import PersonaParaphrasingTab from './personaParaphrasingTab';
import CustomDrawer from './mobileDropdownDrawer';
import { CopyButton } from '../../Editor/plugins/CodeActionMenuPlugin/components/CopyButton';
import RoundLoader from '../../utility/globalLoader';
interface ParaphrasingMobileProps {
    hasParaphras: boolean;
    handleClearText: () => void;
    handleTrySampleText: () => void;
    currentTab: string,
    handleChangeSubTab: (event: React.SyntheticEvent, newValue: string) => void,
    SubTabList: any,
    morePrompts: any,
    length: any,
    CustomPaper: any,
    mobileScreen: any
    handleToggleInputEdit: (flag: boolean) => void;
    handleChangeParaphraseInput: (e: any) => void
    handleParaphraseOutputChange: (e: any, flag: any) => void
    handleToggleOutputEditable: (option: any, flag: any, index?: number) => void
    checkForValues: () => boolean
    handleDecOutputOptionVersion: (option: executePromptResponseParaphraseObj) => void
    handleRewriteOutputOption: (optionToRewrite: executePromptResponseParaphraseObj) => void,
    copyText: any,
    setCopyText: any,
    handleCopyToClipboard: (textToCopy: any) => void,
    handleChangeCheckOutputOptionCard: (event: React.ChangeEvent<HTMLInputElement>, option: executePromptResponseParaphraseObj) => void,
    handleUseAsInput: (valueToReplace: string, option: any) => void,
    deleteOutputOptionCard: (optionToDelete: executePromptResponseParaphraseObj) => void,
    handleSaveDocument: () => void,
    handleMultipleUseAsInput: () => void,
    handleIncOutputOptionVersion: (option: executePromptResponseParaphraseObj) => void,
    handleMultipleRewrite: () => void,
    multipleCopyText: () => string,
    handleMultipleDelete: () => void,
    getParaphraseDisableFlag: any,
    handleParaphrase: () => void
    handleOpenMenu: (event: React.ChangeEvent<HTMLInputElement>, option: executePromptResponseParaphraseObj) => void,
    handleCloseMenu: (event: React.ChangeEvent<HTMLInputElement>, option: executePromptResponseParaphraseObj) => void
    textAreaRefs: any,
    editIndex: any;
}

const ParaphrasingMobileComponent = (props: ParaphrasingMobileProps) => {
    const {
        hasParaphras,
        handleClearText,
        handleTrySampleText,
        currentTab,
        handleChangeSubTab,
        SubTabList,
        morePrompts,
        length,
        CustomPaper,
        mobileScreen,
        handleToggleInputEdit,
        handleChangeParaphraseInput,
        handleParaphraseOutputChange,
        handleToggleOutputEditable,
        checkForValues,
        handleDecOutputOptionVersion,
        handleRewriteOutputOption,
        copyText,
        setCopyText,
        handleCopyToClipboard,
        handleChangeCheckOutputOptionCard,
        handleUseAsInput,
        deleteOutputOptionCard,
        handleSaveDocument,
        handleMultipleUseAsInput,
        handleIncOutputOptionVersion,
        handleMultipleRewrite,
        multipleCopyText,
        handleMultipleDelete,
        getParaphraseDisableFlag,
        handleParaphrase,
        handleOpenMenu,
        handleCloseMenu,
        textAreaRefs,
        editIndex
    } = props;
    const { paraphrasingVersioningArr, paraphrasingVersionIndex, setParaphraseVersioningarr, currentIndex, drawerProps, setDrawerProps, setCurrentIndex } = useContext(ParaphraseContext) as ParaphraseContextPayload;
    const { languageList, flagLoading } = useContext(UserContext) as UserContextPayload;
    // @ts-ignore
    const [maxHeightBottomContainer, setMaxHeightBottomContainer] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [flagResizing, setFlagResizing] = useState(false)
    const [collapsed, setCollapsed] = useState(true)
    const [openMobileDropdownDrawer, setOpenMobileDropdownDrawer] = useState(false)
    const [customPane1Style, setCustomPane1Style] = useState<any>({
        display: "block",
        height: `calc(100% - 95px)`
        // `${window.innerHeight - 90}px`
        , flex: "unset"
    })
    const getTabSectionHeight = (tabName: string) => {
        switch (tabName) {
            case "Basic":
                return 325;
                break;
            case "Advanced":
                return 535;
                break;
            case "Custom Prompt":
                return 570;
                break;
            case "Persona":
                return 420;
                break;

        }
    }
    const [isInputFocused, setIsInputFocused] = useState(false)
    const [carouselCurrentIndex, setCarouselCurrentIndex] = useState(0)
    useEffect(() => {
        handleClearText()
    }, [])
    const [customPane2Style, setCustomPane2Style] = useState<any>({
        background: "#f9f9f9",
        opacity: "1",
        height: `95px`, flex: "unset", overflow: "auto"
    })

    const preventDefault = (event: any) => {
        if (flagResizing) {
            event.preventDefault();
            event.stopPropagation();
        }
        // Prevent the default touchmove behavior during resizing
    };
    const handleResize = (event: number) => {
        getContainerHeight();
        setFlagResizing(true)
        setCustomPane1Style({ display: "block" })
        setCustomPane2Style({
            background: "#f9f9f9",
            opacity: "1",
            overflow: "auto",
        });
        if (event + 200 > window.innerHeight) {
            setCollapsed(true)
            setCustomPane2Style({
                ...customPane2Style, overflow: "hidden",
            });

        } else {
            setCollapsed(false)
        }
        if (event)
            (document.getElementsByClassName('Pane')[0] as HTMLElement).style.flex = 'unset';
        (document.getElementsByClassName('Pane')[0] as HTMLElement).style.minWidth = 'unset';
    }
    const handleDragStarted = () => {
        setFlagResizing(true)
        document.addEventListener('touchmove', preventDefault, { passive: false });
    };

    const handleDragFinished = () => {
        setFlagResizing(false)
        document.removeEventListener('touchmove', preventDefault);
    };

    useEffect(() => {
        // Attach the touchmove event listener after the initial render
        document.addEventListener('touchmove', preventDefault, { passive: false });

        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener('touchmove', preventDefault);
        };
    }, [flagResizing]);
    useEffect(() => {
        if (editIndex !== null && textAreaRefs.current[editIndex]) {
            const textarea = textAreaRefs.current[editIndex]!;
            textarea.focus();
            const length = textarea.value.length;
            textarea.setSelectionRange(length, length);
        }
    }, [editIndex]);
    const handleCollapse = (flagCollapsed: boolean, flagCalledthroughParaphrase: boolean) => {
        let height = 0;
        if (paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length > 0 || flagCalledthroughParaphrase) {
            var elements = document.getElementsByClassName('outputCardContainer');

            for (var i = 0; i < elements.length; i++) {
                // @ts-ignore
                height += elements[i].offsetHeight;
            }
            if (elements.length == 0) {
                height = 450
            } else {
                height = height + 70
            }
            setMaxHeightBottomContainer(height)
        } else {
            // @ts-ignore
            height = document.getElementById('test')?.offsetHeight + 100;
            setMaxHeightBottomContainer(height)
        }
        const container = document.getElementsByClassName('Pane horizontal Pane2')[0] as HTMLElement
        const outputContainer = document.getElementById('ppOutputField') as HTMLElement
        if (container) {
            container.scrollTop = 0;
        }
        if (outputContainer) {
            outputContainer.scrollTop = 0;
        }
        if (paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length > 0) {
            if (flagCollapsed) {
                // this is opening drawer
                setCustomPane1Style({ ...customPane1Style, height: `${window.innerHeight - height}px`, flex: "unset" })
                setCustomPane2Style({ ...customPane2Style, height: `${height}px`, flex: "unset", overflow: "auto", })
            } else {
                // closing drawer
                setCustomPane1Style({ ...customPane1Style, height: `${window.innerHeight - 40}px`, flex: "unset" })
                setCustomPane2Style({ ...customPane2Style, height: `40px`, flex: "unset", overflow: "hidden" })
            }
        } else {
            if (flagCollapsed) {
                // this is opening drawer
                setCustomPane1Style({ ...customPane1Style, height: `${window.innerHeight - height}px`, flex: "unset" })
                setCustomPane2Style({ ...customPane2Style, height: `${height}px`, flex: "unset", overflow: "auto", })
            } else {
                // closing drawer
                setCustomPane1Style({ ...customPane1Style, height: `${window.innerHeight - 95}px`, flex: "unset" })
                setCustomPane2Style({ ...customPane2Style, height: `95px`, flex: "unset", overflow: "hidden" })
            }
        }
        setCollapsed(!flagCollapsed)
    }
    const changeHeightOfResizer = (tabName: string) => {
        let height = getTabSectionHeight(tabName);
        if (height) {
            setCustomPane1Style({ ...customPane1Style, height: `${window.innerHeight - height}px`, flex: "unset" })
            setCustomPane2Style({ ...customPane2Style, height: `${height}px`, flex: "unset", overflow: "auto", })
        }
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [openSection1Anchor, setOpenSection1Anchor] = useState<HTMLButtonElement | null>(null)
    const [openSection1, setOpenSection1] = useState<boolean>(false)
    // Boolean(openSection1Anchor);

    const handleOpenSection1Menu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenSection1(true)
        setOpenSection1Anchor(event.currentTarget)
    }

    const handleCloseSection1Menu = () => {
        setOpenSection1(false)
        setOpenSection1Anchor(null)
    }

    const carouselRef = useRef<any>(null);

    const goToSlide = (slideIndex: number) => {
        // Assuming that 'goTo' is a method provided by the carousel library
        if (carouselRef.current) {
            carouselRef.current?.goTo(slideIndex);
        }
    };
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [searchValue, setSearchValue] = useState<any>('');
    const handleSetMobileDrawer = (dropdownOptions: any, paraphrasingPayloadKeyValue: string, optionKeyName: string, paraphrasingTabKey: string | null, isValMultiple: boolean, optionImage: string | null, dropdownName: string) => {
        setDrawerProps({
            ...drawerProps,
            openDrawer: true,
            dropdownOptions: dropdownOptions,
            paraphrasingPayloadKeyValue: paraphrasingPayloadKeyValue,
            optionKeyName: optionKeyName,
            paraphrasingTabKey: paraphrasingTabKey,
            isValMultiple: isValMultiple,
            optionImage: optionImage,
            dropdownName: dropdownName
        })
    }
    const staticNumList = [
        {
            no_of_output: "1"
        },
        {
            no_of_output: "2"
        },
        {
            no_of_output: "3"
        },
        {
            no_of_output: "4"
        }, {
            no_of_output: "5"
        }]
    const flagShowTooltip = (option: any, keyname: string) => {
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    {
                        ...item, executePromptResponseParaphrase: paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.map((item: any) =>
                            item.id === option.id
                                ? {
                                    ...item,
                                    value: [
                                        ...item.value.map((val: any, index: any) =>
                                            index === option.index ? { ...val, [keyname]: true } : val
                                        ),
                                    ],
                                }
                                : item
                        )
                    } : item
            ));
        handleCopyToClipboard(option.value[option.index].paraphrase);
        setCopyText("Copied!");
        setTimeout(() => {
            setParaphraseVersioningarr((prevState: any) =>
                prevState.map((item: any, index: any) =>
                    index == paraphrasingVersionIndex ?
                        {
                            ...item, executePromptResponseParaphrase: paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.map((item: any) =>
                                item.id === option.id
                                    ? {
                                        ...item,
                                        value: [
                                            ...item.value.map((val: any, index: any) =>
                                                index === option.index ? { ...val, [keyname]: false } : val
                                            ),
                                        ],
                                    }
                                    : item
                            )
                        } : item
                ))
        }, 1000)
    }

    const handleDecVersioning = () => {
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == (paraphrasingVersioningArr.length - 1) ?
                    prevState[(currentIndex - 2)] : item
            ))
        setCurrentIndex((prevState: any) => prevState - 1)
    }

    const handleIncVersion = () => {
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == (paraphrasingVersioningArr.length - 1) ?
                    prevState[(currentIndex)] : item
            ))
        setCurrentIndex((prevState: any) => prevState + 1)
    }

    const handleOnChangeCarouselSlide = (currentItemObject: any, currentIndex: number) => {
        setCarouselCurrentIndex(currentIndex)
    };
    const getContainerHeight = () => {
        let height = 0;
        if (paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length > 0) {
            var elements = document.getElementsByClassName('outputCardContainer');

            for (var i = 0; i < elements.length; i++) {
                // @ts-ignore
                height += elements[i].offsetHeight;
            }
            setMaxHeightBottomContainer(height)
        } else {
            // @ts-ignore
            height = document.getElementById('test')?.offsetHeight;
            setMaxHeightBottomContainer(height)
            // setCustomPane1Style({ ...customPane1Style, height: `${window.innerHeight - height}px`, flex: "unset" })
            // setCustomPane2Style({ ...customPane2Style, height: `${height}px`, flex: "unset", overflow: "auto", })
        }
    }


    return (
        <>
            <div style={{ maxHeight: window.innerHeight - 56, height: window.innerHeight - 56 }}>
                <>
                    <CustomDrawer
                        drawerOpen={drawerProps.openDrawer}
                        handleDrawerClose={() => {
                            setDrawerProps({ ...drawerProps, openDrawer: false }); setSearchValue("")
                            setTimeout(() => {
                                document.getElementById('paraphraseInput')?.blur();
                                document.getElementById('toneOfVoice')?.blur()
                                document.getElementById('languageList')?.blur()
                                document.getElementById('noOfOutput')?.blur();
                                document.getElementById('length')?.blur()
                            }, 200)
                        }}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        dropdownOptions={drawerProps.dropdownOptions}
                        paraphrasingVersioningArr={paraphrasingVersioningArr}
                        paraphrasingVersionIndex={paraphrasingVersionIndex}
                        optionKeyName={drawerProps.optionKeyName}
                        paraphrasingPayloadKeyValue={drawerProps.paraphrasingPayloadKeyValue}
                        isValMultiple={drawerProps.isValMultiple}
                        paraphrasingTabKey={drawerProps.paraphrasingTabKey}
                        optionImage={drawerProps.optionImage}
                        dropdownName={drawerProps.dropdownName}
                    />
                </>
                {/* @ts-ignore */}
                <SplitPane
                    className={styles.splitpaneStyle}
                    split="horizontal"
                    minSize={150}
                    onChange={handleResize}
                    maxSize={window.innerHeight - 190}
                    resizerClassName={styles.paraphrasingResizer}
                    pane2Style={customPane2Style}
                    pane1Style={customPane1Style}
                    paneStyle={customPane1Style}
                    onDragStarted={handleDragStarted}
                    onDragFinished={handleDragFinished}
                    onTouchStart={handleDragStarted}
                    onPointerDown={handleDragStarted}
                    onPointerUp={handleDragFinished}
                    onMouseDown={handleDragFinished}
                // onChange={handleResize}
                >

                    {/******* PANE 1 *******/}
                    <div style={{ height: "100%" }}>
                        {/* Input */}
                        <Box
                            // item
                            // xl={6} lg={6} md={12} sm={12} xs={12}
                            className={styles.ppInputContainer} >
                            <Grid container
                                // for mobile
                                sx={{ height: "100%" }}
                            >
                                <Grid item xs={12}
                                // sx={{ marginTop: "60px" }}
                                >
                                    <Box
                                        // for mobile
                                        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                                    >
                                        <Box >
                                            <Grid container >
                                                <Grid item xs={12} className={styles.mbppTopPartMainItem}>
                                                    <Grid container justifyContent={"space-between"}>
                                                        {paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length > 0 ?
                                                            (
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <Grid container sx={{ justifyContent: "space-between" }}>
                                                                            <Grid item className={styles.inputTextStyle} sx={{ fontWeight: "600", display: "flex", alignItems: "center" }}>
                                                                                {carouselCurrentIndex === 0 ? (
                                                                                    <>
                                                                                        Input
                                                                                        <span style={{ fontSize: "11px", fontWeight: "500", color: "#7A7A7A", paddingLeft: "5px" }}>
                                                                                            {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st !== '').length} W {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.length} C
                                                                                        </span>
                                                                                    </>
                                                                                ) : (
                                                                                    <>Output Preferences</>
                                                                                )}
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Grid container sx={{ gap: "6px" }}>
                                                                                    <Grid item>
                                                                                        <GlobalButton
                                                                                            buttonText="Paraphrase"
                                                                                            flagFullWidth
                                                                                            height='35px !important'
                                                                                            className="primaryButtonStyle"
                                                                                            disabled={getParaphraseDisableFlag()}
                                                                                            onClick={async () => { handleCollapse(true, true); await handleParaphrase(); getContainerHeight(); }}
                                                                                        // disabled={paraphrasingInput.length < 5}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item sx={{ display: "flex" }}>
                                                                                        <IconButton
                                                                                            aria-label="more"
                                                                                            id="long-button"
                                                                                            aria-controls={open ? 'long-menu' : undefined}
                                                                                            aria-expanded={open ? 'true' : undefined}
                                                                                            aria-haspopup="true"
                                                                                            // onClick={handleClick}
                                                                                            sx={{ padding: "0px" }}
                                                                                            onClick={(e) => {
                                                                                                // setSelectedAgent(row)
                                                                                                handleOpenSection1Menu(e)

                                                                                            }}
                                                                                        >
                                                                                            <MoreVertIcon />
                                                                                        </IconButton>
                                                                                        <Menu
                                                                                            id="long-menu"
                                                                                            MenuListProps={{
                                                                                                'aria-labelledby': 'long-button',
                                                                                            }}
                                                                                            anchorEl={openSection1Anchor}
                                                                                            open={openSection1}
                                                                                            onClose={handleCloseSection1Menu}
                                                                                            PaperProps={{
                                                                                                style: {
                                                                                                    // maxHeight: ITEM_HEIGHT * 2,
                                                                                                    // width: '130px',
                                                                                                    // minWidth: "160px",
                                                                                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                                                                    padding: "0px 10px",

                                                                                                },
                                                                                            }}
                                                                                        >
                                                                                            {carouselCurrentIndex == 0 ?
                                                                                                <MenuItem
                                                                                                    onClick={() => { goToSlide(2); handleCloseSection1Menu(); }}
                                                                                                    style={{
                                                                                                        minHeight: '20px',
                                                                                                        padding: "5px 10px",
                                                                                                        textAlign: "center",
                                                                                                        justifyContent: "start",
                                                                                                        alignItems: "center",
                                                                                                        borderBottom: "1px solid #E9E9E9"
                                                                                                    }}
                                                                                                >
                                                                                                    <Typography component={'span'}>Output Preference</Typography>
                                                                                                </MenuItem>
                                                                                                :
                                                                                                <MenuItem
                                                                                                    onClick={() => { goToSlide(0); handleCloseSection1Menu(); }}
                                                                                                    style={{
                                                                                                        minHeight: '20px',
                                                                                                        padding: "5px 10px",
                                                                                                        textAlign: "center",
                                                                                                        justifyContent: "start",
                                                                                                        alignItems: "center",
                                                                                                        borderBottom: "1px solid #E9E9E9"
                                                                                                    }}
                                                                                                >
                                                                                                    <Typography component={'span'}>Input</Typography>
                                                                                                </MenuItem>
                                                                                            }
                                                                                            <MenuItem
                                                                                                onClick={() => {
                                                                                                    (paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.length > 0 ? handleClearText() : handleTrySampleText()); goToSlide(0); handleCloseSection1Menu();
                                                                                                }}
                                                                                                style={{
                                                                                                    minHeight: '20px',
                                                                                                    padding: "5px 10px",
                                                                                                    textAlign: "center",
                                                                                                    justifyContent: "start",
                                                                                                    alignItems: "center"
                                                                                                }}
                                                                                            >
                                                                                                <Typography component={'span'}>{paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 0 ? "Clear Input" : "Try Sample Text"}</Typography>
                                                                                            </MenuItem>

                                                                                        </Menu>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Grid item className={styles.inputTextStyle}>
                                                                        {carouselCurrentIndex === 0 ? (
                                                                            <>
                                                                                Input
                                                                                <span style={{ fontSize: "11px", fontWeight: "500", color: "#7A7A7A", paddingLeft: "5px" }}>
                                                                                    {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st !== '').length} W {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.length} C
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>Output Preferences</>
                                                                        )}

                                                                    </Grid>
                                                                    {/* <Grid item className={styles.characterCountText}>Words: {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length} Characters: {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.length}</Grid> */}
                                                                    <Grid item>
                                                                        <Grid container gap="7px">
                                                                            {isInputFocused && paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 0 &&
                                                                                <Grid item>
                                                                                    <GlobalButton
                                                                                        buttonText="Paraphrase"
                                                                                        flagFullWidth
                                                                                        height='35px !important'
                                                                                        className="primaryButtonStyle"
                                                                                        disabled={getParaphraseDisableFlag()}
                                                                                        onClick={async () => { handleCollapse(true, true); await handleParaphrase(); getContainerHeight(); }}
                                                                                    // disabled={paraphrasingInput.length < 5}
                                                                                    />
                                                                                </Grid>}
                                                                            {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 0 && !isInputFocused &&
                                                                                <Grid item
                                                                                    onClick={() => {
                                                                                        const currentVersion = paraphrasingVersioningArr[paraphrasingVersionIndex];

                                                                                        if (currentVersion && currentVersion.paraphrasingInput.length > 0) {
                                                                                            setIsInputFocused(true);
                                                                                            handleClearText();

                                                                                        } else {
                                                                                            handleTrySampleText();
                                                                                        }
                                                                                    }}
                                                                                    className={`${styles.clearInputText} ${globalStyles.clickable}`}>
                                                                                    {"Clear Input"}
                                                                                </Grid>}
                                                                            {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length == 0 &&
                                                                                <Grid item
                                                                                    onClick={() => {
                                                                                        const currentVersion = paraphrasingVersioningArr[paraphrasingVersionIndex];

                                                                                        if (currentVersion && currentVersion.paraphrasingInput.length > 0) {
                                                                                            setIsInputFocused(true);
                                                                                            handleClearText();

                                                                                        } else {
                                                                                            handleTrySampleText();
                                                                                        }
                                                                                    }}
                                                                                    className={`${styles.clearInputText} ${globalStyles.clickable}`}>
                                                                                    {"Try Sample Text"}
                                                                                </Grid>}
                                                                        </Grid>
                                                                    </Grid>

                                                                </>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sx={{ padding: "0px 15px" }}>
                                                    <hr className={styles.mobileDividerStyle} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box
                                            // for mobile
                                            sx={{ height: "calc(100% - 50px)" }}
                                            className={styles.ppInputAndOptions}>
                                            <Box
                                                // for mobile
                                                sx={{ height: "100%" }}
                                            >
                                                {paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length > 0 ? (
                                                    <>
                                                        {/* @ts-ignore */}
                                                        <Carousel
                                                            ref={carouselRef}
                                                            selectedItem={0}
                                                            showArrows={false} className='paraphrasingCarousel'
                                                            onChange={handleOnChangeCarouselSlide}
                                                        >
                                                            {/********  SECTION - 2 *********/}
                                                            <div className='styleDiv'>
                                                                <Box
                                                                    // for mobile
                                                                    sx={{ height: "100%", position: "relative" }}
                                                                    className={styles.ppInputField}
                                                                //  sx={{ position: "relative" }} 
                                                                >
                                                                    {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 900 &&
                                                                        <span
                                                                            style={{
                                                                                position: "absolute",
                                                                                bottom: "13px",
                                                                                color: "red",
                                                                                right: "17px",
                                                                                fontSize: "15px",
                                                                                zIndex: "1",
                                                                                background: "#fff"
                                                                            }}>
                                                                            {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length} / 1000 words
                                                                        </span>
                                                                    }
                                                                    {/* {paraphrasingVersioningArr[paraphrasingVersionIndex].flagInputEditable ? */}
                                                                    <GlobalTextField
                                                                        id="paraphraseInput"
                                                                        onFocus={(e) => { setIsInputFocused(true) }}
                                                                        value={paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput}
                                                                        onBlur={() => { handleToggleInputEdit(false); setTimeout(() => { setIsInputFocused(false) }, 100) }}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeParaphraseInput(e)}
                                                                        placeholder="To paraphrase text, enter or paste your content in the language of your choice, then press the 'Paraphrase' button."
                                                                        // rows={6}
                                                                        multiline
                                                                        autoFocus={isInputFocused}
                                                                        className={`${styles.ppGlobalMultilineTextField}`}
                                                                    />
                                                                    {/* :
                                                                            <div
                                                                                onClick={() => { handleToggleInputEdit(true) }}
                                                                                style={{ padding: "14px" }}
                                                                                className={`${styles.ppGlobalMultilineTextField} ${globalStyles.clickable}`}>
                                                                                <ReactMarkdown
                                                                                    className={styles.reactMarkdownStyle}
                                                                                    // children={option.value[option.index].paraphrase} 
                                                                                    children={paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput}
                                                                                />
                                                                            </div>
                                                                        } */}
                                                                </Box>
                                                                {/* <Grid item className={styles.wordCounterMobileContainer}>
                                                                    <Grid container className={styles.ppWbWordAndCharMainContainer}>
                                                                        <Grid item className={styles.wordCounterMobile}>
                                                                            {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length} Words
                                                                        </Grid>
                                                                        <Grid item className={styles.wordCounterMobile}>
                                                                            {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.length} Characters
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid> */}
                                                            </div>

                                                            {/********  SECTION - 2 *********/}
                                                            <div className='styleDiv'>
                                                                <Grid item xs={12} sx={{ height: "100%", overflowY: "scroll", }}>
                                                                    <Grid container>
                                                                        {/* lower tabs */}
                                                                        <Grid item xs={12} className={`${styles.ppMbInputFieldOptions}`} >
                                                                            <Grid container direction="column" >
                                                                                <Grid item>
                                                                                    <Tabs
                                                                                        // className={styles.ppTabs}
                                                                                        className={styles.ppTabsSecond}
                                                                                        // value={currentSubTab}
                                                                                        value={paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab}
                                                                                        onChange={(event: SyntheticEvent, newValue: string) => { handleChangeSubTab(event, newValue) }}
                                                                                        textColor="secondary"
                                                                                        indicatorColor="secondary"
                                                                                        aria-label="secondary tabs example"
                                                                                    >
                                                                                        {SubTabList.map((tabDetail: any, i: number) => (
                                                                                            <Tab className={`${styles.tabStyle}`} key={tabDetail.id} value={tabDetail.tabName} label={tabDetail.tabName} />
                                                                                        ))}
                                                                                    </Tabs>
                                                                                </Grid>
                                                                                <Grid item xs={12} className={styles.ppMbinputOptions}>
                                                                                    <Grid container gap={"0.2rem"} sx={{ padding: "0px 15px 0px 0px" }}>
                                                                                        {
                                                                                            paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab == 'Basic' ? (
                                                                                                <BasicParaphrasingTab
                                                                                                    morePrompts={morePrompts}
                                                                                                    length={length}
                                                                                                    CustomPaper={CustomPaper}
                                                                                                    mobileScreen={mobileScreen}
                                                                                                />
                                                                                            ) :
                                                                                                paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab == 'Advanced' ?
                                                                                                    <AdvanceParaphrasingTab length={length} morePrompts={morePrompts} CustomPaper={CustomPaper} />
                                                                                                    // AdvancedTabs()
                                                                                                    :
                                                                                                    <>
                                                                                                        {paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab == "Custom Prompt" ?
                                                                                                            (<>
                                                                                                                <CustomParaphrasingTab />
                                                                                                            </>)
                                                                                                            :
                                                                                                            <PersonaParaphrasingTab
                                                                                                                length={length}
                                                                                                            />
                                                                                                        }

                                                                                                    </>
                                                                                        }
                                                                                    </Grid>

                                                                                </Grid>
                                                                            </Grid>
                                                                            {/* <Divider sx={{ borderStyle: "unset !important" }} /> */}
                                                                        </Grid>

                                                                        {/* language, no of output */}
                                                                        <Grid item xs={12} className={styles.optionMbSection1Static}>
                                                                            <Grid container className={styles.paraphraseLangButtonParent} justifyContent={'space-between'} alignItems={"center"}>
                                                                                {/* language and no of output */}
                                                                                <Grid item className={styles.languageOutputCountContainer}>
                                                                                    <Grid container justifyContent={"space-between"} alignItems={"end"} className={globalStyles.containerflexWrapStyle} gap={'5px'}>
                                                                                        {/* language */}
                                                                                        <Grid item xs={5.85} className={styles.languageContainer} style={{ textAlign: "center" }}>
                                                                                            <Grid container className={`${globalStyles.containerflexWrapStyle} ${styles.languageContainerChild}`} justifyContent={"center"} alignItems="center">
                                                                                                <Grid item className={styles.bottomTextStyle}>Output Language</Grid>
                                                                                                <Grid item className={styles.languageParent}>
                                                                                                    <Autocomplete
                                                                                                        className={`${styles.languageAutocomplete} ${styles.autocompleteParaphrasing}`}
                                                                                                        disablePortal
                                                                                                        disableClearable
                                                                                                        id="languageList"
                                                                                                        blurOnSelect={true}
                                                                                                        onOpen={() => { handleSetMobileDrawer([{ name: "Same as Input" }, ...languageList], "selectedLanguage", "name", null, false, null, "Language") }}
                                                                                                        options={languageList.map((language) => language.name)}
                                                                                                        value={paraphrasingVersioningArr[paraphrasingVersionIndex].selectedLanguage}
                                                                                                        onChange={(e, value: any) => {
                                                                                                            setParaphraseVersioningarr((prevState: any) =>
                                                                                                                prevState.map((item: any, index: any) =>
                                                                                                                    index == paraphrasingVersionIndex ?
                                                                                                                        { ...item, selectedLanguage: value } : item
                                                                                                                ));
                                                                                                        }}

                                                                                                        renderInput={(params) => <TextField {...params} placeholder='Search' />}
                                                                                                        PaperComponent={({ children }) => (
                                                                                                            <Paper className={styles.languagePaperStyle}>{children}</Paper>
                                                                                                        )}
                                                                                                    />

                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        {/* number of output */}
                                                                                        <Grid item xs={5.85} className={styles.outputCountContainer}>
                                                                                            <Grid container className={`${globalStyles.containerflexWrapStyle} ${styles.noOfOutputContainerChild}`} alignItems={"center"}>
                                                                                                <Grid item>
                                                                                                    <Typography className={styles.bottomTextStyle}>#Output</Typography>
                                                                                                </Grid>
                                                                                                <Grid item className={styles.noOutputParent}>
                                                                                                    <Autocomplete
                                                                                                        disablePortal
                                                                                                        // className={" multiple_chips__autocomplete formWiz-input-field"}
                                                                                                        onOpen={() => { handleSetMobileDrawer(staticNumList, "selectedOutputCount", "no_of_output", null, false, null, "No of output") }}
                                                                                                        className={styles.autocompleteParaphrasing}
                                                                                                        id="noOfOutput"
                                                                                                        options={staticNumList.map((output) => output.no_of_output)}
                                                                                                        value={paraphrasingVersioningArr[paraphrasingVersionIndex].selectedOutputCount}
                                                                                                        onChange={(e, value: any) => {
                                                                                                            setParaphraseVersioningarr((prevState: any) =>
                                                                                                                prevState.map((item: any, index: any) =>
                                                                                                                    index == paraphrasingVersionIndex ?
                                                                                                                        { ...item, selectedOutputCount: value } : item
                                                                                                                ));
                                                                                                        }}

                                                                                                        // value={selectedOutputCount}
                                                                                                        // onChange={(e, value: any) => { setSelectedOutputCount(value) }}
                                                                                                        renderInput={(params) => <TextField {...params} type={"number"} />}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                {/* paraphrase button */}
                                                                                {/* <Grid item className={styles.paraphraseButtonContainer} sx={{ width: "170px" }}>
                                                                                                                <GlobalButton
                                                                                                                    buttonText="Paraphrase"
                                                                                                                    flagFullWidth
                                                                                                                    height='40px !important'
                                                                                                                    className="primaryButtonStyle"
                                                                                                                    disabled={getParaphraseDisableFlag()}
                                                                                                                    // disabled={paraphrasingInput.length < 5}
                                                                                                                    onClick={handleParaphrase}
                                                                                                                />
                                                                                                            </Grid> */}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Carousel>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* paraphrase input */}
                                                        <Box
                                                            // for mobile
                                                            sx={{ height: "100%" }}

                                                            className={styles.ppInputField}>
                                                            {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 900 &&
                                                                <span
                                                                    style={{
                                                                        position: "absolute",
                                                                        bottom: "13px",
                                                                        color: "red",
                                                                        right: "17px",
                                                                        fontSize: "15px",
                                                                        zIndex: "1",
                                                                        background: "#fff"
                                                                    }}>
                                                                    {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length} / 1000 words
                                                                </span>
                                                            }
                                                            {/* {paraphrasingVersioningArr[paraphrasingVersionIndex].flagInputEditable ? */}
                                                            <GlobalTextField
                                                                id="paraphraseInput"
                                                                onFocus={(e) => { setIsInputFocused(true) }}
                                                                value={paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput}
                                                                onBlur={() => { handleToggleInputEdit(false); setTimeout(() => { setIsInputFocused(false) }, 100) }}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeParaphraseInput(e)}
                                                                placeholder="To paraphrase text, enter or paste your content in the language of your choice, then press the 'Paraphrase' button."
                                                                // rows={6}
                                                                multiline
                                                                autoFocus={isInputFocused}
                                                                className={`${styles.ppGlobalMultilineTextField}`}
                                                            />
                                                            {/* :
                                                                    <div
                                                                        onClick={() => { handleToggleInputEdit(true) }}
                                                                        style={{ padding: "14px" }}
                                                                        className={`${styles.ppGlobalMultilineTextField} ${globalStyles.clickable}`}>
                                                                        <ReactMarkdown
                                                                            className={styles.reactMarkdownStyle}
                                                                            // children={option.value[option.index].paraphrase} 
                                                                            children={paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput}
                                                                        />
                                                                    </div>
                                                                } */}
                                                        </Box>

                                                        {/* <Grid item className={styles.wordCounterMobileContainer}>
                                                            <Grid container className={styles.ppWbWordAndCharMainContainer}>
                                                                <Grid item className={styles.wordCounterMobile}>
                                                                    {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length} Words
                                                                </Grid>
                                                                <Grid item className={styles.wordCounterMobile}>
                                                                    {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.length} Characters
                                                                </Grid>
                                                            </Grid>
                                                        </Grid> */}
                                                    </>
                                                )
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    {/******* PANE 2 *******/}
                    <div style={{ height: "100%" }}>
                        <div
                            style={{
                                // transform: `rotate(${flagOutlineCollapsed ? "0deg" : "180deg"})`,
                                position: "absolute",
                                transform: "rotate(90deg)",
                                zIndex: "1",
                                right: "11px",
                                top: "-2px",
                                cursor: "pointer",
                            }}
                        >
                            <img
                                alt="drawerArrow"
                                // className={styles.child}
                                src={closeArrow} style={{ width: "25px", height: "25px", transform: collapsed ? "rotate(180deg)" : "" }}
                                onClick={() => {
                                    handleCollapse(collapsed, false)
                                }}
                            />
                        </div>

                        <div style={{ height: "100%" }}>
                            {paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length ? (
                                <>
                                    {/* Output */}
                                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} className={`${styles.ppOutputContainer} ${styles.mbOutputItem}`}>
                                        <Grid container sx={{ height: "100%", flexDirection: "row", justifyContent: "start", alignItems: "center" }} >
                                            <Grid item xs={12} className={styles.mbOutputHeadItem}>
                                                <Grid container justifyContent={"space-between"}>
                                                    <Grid item>

                                                        <Typography component={'span'} className={styles.mbOutputHead}>Output ({paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length})</Typography>
                                                    </Grid>
                                                    <Grid item sx={{ paddingRight: "30px" }}>
                                                        {paraphrasingVersioningArr.length > 2 &&
                                                            <Grid container alignItems={"center"} justifyContent={"end"} >
                                                                <IconsColor iconName="decIndex" className={currentIndex !== 1 ? globalStyles.clickable : ""}
                                                                    width="8"
                                                                    height="13px"
                                                                    fillColor={currentIndex !== 1 ? "#000" : "#7A7A7A"}
                                                                    onClick={() => {
                                                                        currentIndex !== 1 &&
                                                                            handleDecVersioning()
                                                                    }}
                                                                />

                                                                <Grid item sx={{ margin: "0px 4px", }}>
                                                                    {/* {paraphrasingVersionIndex + 1 > paraphrasingVersioningArr.length - 1 ? paraphrasingVersionIndex : paraphrasingVersionIndex + 1}/{paraphrasingVersioningArr.length - 1} */}
                                                                    <Typography component={'span'} sx={{ color: "#7A7A7A", fontSize: "16px", fontWeight: "500" }}>
                                                                        {currentIndex}/{paraphrasingVersioningArr.length - 1}
                                                                    </Typography>
                                                                </Grid>

                                                                <IconsColor iconName="IncIndex" className={(currentIndex) !== (paraphrasingVersioningArr.length - 1) ? globalStyles.clickable : ""}
                                                                    width="8"
                                                                    height="13px"
                                                                    fillColor={(currentIndex) !== (paraphrasingVersioningArr.length - 1) ? "#000" : "#7A7A7A"}
                                                                    onClick={() => {
                                                                        (currentIndex) !== (paraphrasingVersioningArr.length - 1) &&
                                                                            // setParaphrasingVersionIndex(paraphrasingVersionIndex + 1)
                                                                            handleIncVersion()
                                                                    }}
                                                                />
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {flagLoading.flagContentGenerating ?
                                                <RoundLoader />
                                                :
                                                <Grid item xs={12} className={styles.ppOutputTextFields}>
                                                    {paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length ?
                                                        <Grid container direction={"column"} className={`${globalStyles.fullHeight} ${styles.outputMainItem}`} >
                                                            <Grid item id="ppOutputField" xs={12} className={styles.ppOutputField}>
                                                                {
                                                                    paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase?.map((option: executePromptResponseParaphraseObj, index: number) => {

                                                                        return (
                                                                            <>
                                                                                {option.value.length > 0 &&
                                                                                    <div className={`${styles.outputCardContainer} outputCardContainer`} >
                                                                                        {/* option number */}
                                                                                        <div className={styles.firstBlock} >
                                                                                            <div className={styles.optionNumWrapper}>
                                                                                                <div className={styles.indexStyle}> Option {index + 1}</div>
                                                                                                {!(option.value.length > 1) &&
                                                                                                    <div className={styles.mbOptionCharText}>{option.value[option.index].paraphrase.trim().split(/\s+/).filter(st => st != '').length} W {option.value[option.index].paraphrase.length} C</div>}
                                                                                            </div>
                                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                        {/* pagination */}
                                                                                                        {option.value.length > 1 &&
                                                                                                            <>
                                                                                                                <div>
                                                                                                                    <IconsColor
                                                                                                                        iconName="decIndex"
                                                                                                                        className={option.index !== 0 ? globalStyles.clickable : ""}
                                                                                                                        width="7"
                                                                                                                        height="11px"
                                                                                                                        fillColor={currentIndex !== 1 ? "#000" : "#7A7A7A"}
                                                                                                                        onClick={() => {
                                                                                                                            option.index !== 0 &&
                                                                                                                                handleDecOutputOptionVersion(option)
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div style={{ color: "#7A7A7A", fontSize: "14px", fontWeight: "500", margin: "0px 3px" }}>{`${option.index + 1}/${option.value.length}`}</div>

                                                                                                                <div style={{ paddingRight: "15px" }}>
                                                                                                                    <IconsColor
                                                                                                                        iconName="IncIndex"
                                                                                                                        className={option.index !== option.value.length - 1 ? globalStyles.clickable : ""}
                                                                                                                        width="7"
                                                                                                                        height="11px"
                                                                                                                        fillColor={option.index !== option.value.length - 1 ? "#000" : "#7A7A7A"}
                                                                                                                        onClick={() => { option.index !== option.value.length - 1 && handleIncOutputOptionVersion(option) }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                    {/* buttons */}
                                                                                                    {
                                                                                                        <div style={{
                                                                                                            display: 'flex', alignItems: "center",
                                                                                                            gap: "12px"
                                                                                                        }}>
                                                                                                            {!option.value[option.index].checked &&
                                                                                                                <>
                                                                                                                    <div style={{ display: "flex" }}>
                                                                                                                        {!option.value[option.index].flagEditable &&
                                                                                                                            <>

                                                                                                                                <Tooltip title={"Edit"}>
                                                                                                                                    <div
                                                                                                                                        className={option.value[option.index].flagLoading ? globalStyles.disabledClass : ""}
                                                                                                                                        onClick={() => {
                                                                                                                                            if (option.value[option.index].flagLoading) {
                                                                                                                                            } else {
                                                                                                                                                handleToggleOutputEditable(option, true, index)
                                                                                                                                            }
                                                                                                                                        }} style={{ display: "flex", cursor: "pointer" }}>
                                                                                                                                        <IconsColor
                                                                                                                                            iconName="edit"
                                                                                                                                            fillColor="#6A097D"
                                                                                                                                            width="16px"
                                                                                                                                            height="16px"
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                </Tooltip>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        onClick={() => {
                                                                                                                            if (option.value[option.index].flagLoading) {
                                                                                                                            } else {
                                                                                                                                handleRewriteOutputOption(option)
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        className={option.value[option.index].flagLoading ? `${globalStyles.disabledClass} ${styles.useasInputBtnStyle}` : `${styles.useasInputBtnStyle} ${globalStyles.clickable}`}>
                                                                                                                        <Tooltip title={"Rewrite"}>
                                                                                                                            <Grid item>
                                                                                                                                <IconButton
                                                                                                                                    aria-label="rewrite"
                                                                                                                                    size="small"
                                                                                                                                // onClick={() => { handleRewriteOutputOption(option) }}
                                                                                                                                >
                                                                                                                                    <img src={reWriteIcon} alt="Rewrite" />
                                                                                                                                </IconButton>
                                                                                                                            </Grid>
                                                                                                                        </Tooltip>

                                                                                                                    </div>
                                                                                                                    {/* <div className={`${globalStyles.clickable}`}><IconsColor iconName={"download"} fillColor={"#6A097D"} /></div> */}
                                                                                                                    <CopyToClipboard
                                                                                                                        text={option.value[option.index].paraphrase}>
                                                                                                                        <Grid item>
                                                                                                                            <Tooltip
                                                                                                                                disableFocusListener
                                                                                                                                disableHoverListener
                                                                                                                                disableTouchListener
                                                                                                                                open={option.value[option.index].showTooltip}
                                                                                                                                title={copyText} onClick={() => {
                                                                                                                                    setCopyText("Copied!");
                                                                                                                                }}>
                                                                                                                                <div
                                                                                                                                    className={option.value[option.index].flagLoading ? `${globalStyles.disabledClass}` : `${globalStyles.clickable}`}
                                                                                                                                    onClick={() => {
                                                                                                                                        if (option.value[option.index].flagLoading) {
                                                                                                                                        } else {
                                                                                                                                            flagShowTooltip(option, "showTooltip")
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                    style={{ display: "flex", alignItems: "center" }}
                                                                                                                                >
                                                                                                                                    <IconsColor iconName={"Copy"} fillColor={"#6A097D"} width={"16"} height={"16"} />
                                                                                                                                </div>
                                                                                                                            </Tooltip>

                                                                                                                        </Grid>

                                                                                                                    </CopyToClipboard>



                                                                                                                    <div key={option.id} style={{ display: "flex" }}>
                                                                                                                        <IconButton
                                                                                                                            aria-label="more"
                                                                                                                            id={option.id.toString()}
                                                                                                                            key={option.id}
                                                                                                                            aria-haspopup="true"
                                                                                                                            // onClick={handleClick}
                                                                                                                            sx={{ padding: "0px" }}
                                                                                                                            className={option.value[option.index].flagLoading ? `${globalStyles.disabledClass}` : `${globalStyles.clickable}`}
                                                                                                                            onClick={(e: any) => {
                                                                                                                                if (option.value[option.index].flagLoading) {
                                                                                                                                } else {
                                                                                                                                    handleOpenMenu(e, option)
                                                                                                                                }
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <MoreVertIcon sx={{ color: "#6A097D", fontSize: "large" }} />
                                                                                                                        </IconButton>
                                                                                                                        <Menu
                                                                                                                            id={option.id.toString()}
                                                                                                                            MenuListProps={{
                                                                                                                                'aria-labelledby': 'long-button',
                                                                                                                            }}
                                                                                                                            anchorEl={option.value[option.index].anchorEl}
                                                                                                                            key={option.value[option.index].id}
                                                                                                                            open={option.value[option.index].flagOpenMenu}
                                                                                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeCheckOutputOptionCard(event, option) }}

                                                                                                                            onClose={(e: any) => handleCloseMenu(e, option)}
                                                                                                                            PaperProps={{
                                                                                                                                style: {
                                                                                                                                    // maxHeight: ITEM_HEIGHT * 4.5,
                                                                                                                                    // width: '130px',
                                                                                                                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                                                                                                    padding: "0 10px"
                                                                                                                                },
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <MenuItem onClick={() => { handleUseAsInput(option.value[option.index].paraphrase, option) }}

                                                                                                                                style={{
                                                                                                                                    minHeight: '20px',
                                                                                                                                    padding: "5px 10px",
                                                                                                                                    textAlign: "center",
                                                                                                                                    justifyContent: "start",
                                                                                                                                    alignItems: "center",
                                                                                                                                    borderBottom: "1px solid #E9E9E9"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <Typography component={'span'}>Use as Input</Typography>
                                                                                                                            </MenuItem>
                                                                                                                            <MenuItem
                                                                                                                                onClick={() => { deleteOutputOptionCard(option) }}
                                                                                                                                style={{
                                                                                                                                    minHeight: '20px',
                                                                                                                                    padding: "5px 10px",
                                                                                                                                    textAlign: "center",
                                                                                                                                    justifyContent: "start",
                                                                                                                                    alignItems: "center"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <Typography component={'span'}>Delete</Typography>
                                                                                                                            </MenuItem>
                                                                                                                        </Menu>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            }
                                                                                                            <div style={{ display: "flex" }}>
                                                                                                                <Checkbox
                                                                                                                    key={option.value[option.index].id}
                                                                                                                    value={option.value[option.index].checked}
                                                                                                                    checked={option.value[option.index].checked}
                                                                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeCheckOutputOptionCard(event, option) }}
                                                                                                                    className={`${styles.checkboxStyle} ${globalStyles.clickable}`}
                                                                                                                    checkedIcon={<img src={checkedIcon} />}
                                                                                                                    icon={<img src={unCheckedIcon} />}
                                                                                                                    disabled={option.value[option.index].flagLoading ? true : false}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>}
                                                                                                </div>


                                                                                            </div>
                                                                                        </div>
                                                                                        {/* textarea */}
                                                                                        {<div>
                                                                                            {
                                                                                                option.value[option.index].flagLoading ?
                                                                                                    <>
                                                                                                        <div style={{ marginBottom: "16px" }}>
                                                                                                            <Skeleton />
                                                                                                            <Skeleton />
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {option.value[option.index].flagEditable ?
                                                                                                            <>
                                                                                                                <textarea style={{ position: "relative" }}
                                                                                                                    ref={(el) => (textAreaRefs.current[index] = el)}
                                                                                                                    className={`${globalStyles.clickable} resizableTextArea`}
                                                                                                                    value={option.value[option.index].paraphrase}
                                                                                                                    onChange={(e: any) => handleParaphraseOutputChange(e, option)}
                                                                                                                    onBlur={() => { handleToggleOutputEditable(option, false) }}
                                                                                                                // onClick={() => { setSelectedOutputResponse(option) }}  ${selectedOutputResponse && selectedOutputResponse.id == option.id ? styles.selectedOutput : ""}
                                                                                                                // onSelect={(e: any) => handleTextSelect(e, option)}
                                                                                                                >
                                                                                                                </textarea>
                                                                                                            </>
                                                                                                            :
                                                                                                            <>



                                                                                                                <CopyToClipboard
                                                                                                                    text={option.value[option.index].paraphrase}>
                                                                                                                    <Grid item>
                                                                                                                        <Tooltip
                                                                                                                            disableFocusListener
                                                                                                                            disableHoverListener
                                                                                                                            disableTouchListener
                                                                                                                            open={option.value[option.index].showCardTooltip}
                                                                                                                            title={copyText} onClick={() => {
                                                                                                                                setCopyText("Copied!");
                                                                                                                            }}>
                                                                                                                            <div
                                                                                                                                onClick={() => {
                                                                                                                                    flagShowTooltip(option, "showCardTooltip")
                                                                                                                                    // handleCopyToClipboard(option.value[option.index].paraphrase);
                                                                                                                                    // setCopyText("Copied!")
                                                                                                                                }}
                                                                                                                                style={{ display: "flex", alignItems: "center" }}
                                                                                                                                className={`${globalStyles.clickable}`}
                                                                                                                            >
                                                                                                                                <div style={{ minHeight: "22px", whiteSpace: "pre-wrap" }}
                                                                                                                                // onClick={() => { handleToggleOutputEditable(option, true, index) }}
                                                                                                                                >
                                                                                                                                    <ReactMarkdown
                                                                                                                                        // children={option.value[option.index].paraphrase} 
                                                                                                                                        children={option.value[option.index].paraphrase}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </Tooltip>

                                                                                                                    </Grid>

                                                                                                                </CopyToClipboard>

                                                                                                                {/* <hr style={{ width: "100%" }} /> */}
                                                                                                            </>
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                        </div>}
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                            {paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length !== 0 &&
                                                                <Box className={`${styles.ppOutputButtons} ${styles.mbppStickyFooter}`} >
                                                                    <Grid container justifyContent={'space-between'}>
                                                                        <Grid item className={styles.ppOutputButtonsContainer}>
                                                                            {!mobileScreen &&
                                                                                <GlobalButton
                                                                                    disabled={
                                                                                        paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                    }
                                                                                    // disabled={paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.filter((outputCard) => outputCard.checked == true).length == 0 ? true : false}
                                                                                    onClick={handleSaveDocument} buttonText={"Save"} className="borderedButtonStyle" />
                                                                            }
                                                                            <GlobalButton
                                                                                // disabled={
                                                                                //     paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.some((outputCard) =>
                                                                                //         outputCard.value.some((item) => !item.checked)
                                                                                //     )
                                                                                // }
                                                                                height='32px'
                                                                                disabled={
                                                                                    paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                }
                                                                                // disabled={paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.filter((outputCard) => outputCard.checked == true).length == 0 ? true : false}
                                                                                onClick={handleMultipleUseAsInput} buttonText={"Use as Input"} className='borderedButtonStyle' />
                                                                            <GlobalButton
                                                                                height='32px'
                                                                                disabled={
                                                                                    paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                }
                                                                                // disabled={paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.filter((outputCard) => outputCard.checked == true).length == 0 ? true : false}
                                                                                onClick={handleMultipleRewrite} buttonText={"Rewrite"} className='borderedButtonStyle' />

                                                                            {/* <Button disabled={selectedOutputResponse == null ? true : false} sx={{ opacity: selectedOutputResponse == null ? "0.5" : "" }} onClick={handleAddToInput}>Add to Input</Button>
                                                                <Button >Save New Document</Button> */}
                                                                        </Grid>

                                                                        <Grid item sx={{ display: "flex" }}>
                                                                            <Grid container alignItems={"center"} sx={{ gap: "8px", justifyContent: "space-between" }} >
                                                                                <Grid item>
                                                                                    <CopyToClipboard
                                                                                        text={multipleCopyText()}
                                                                                    >
                                                                                        <Grid item>
                                                                                            <Tooltip title={copyText} onClick={() => {
                                                                                                setCopyText("Copied!");

                                                                                            }}>
                                                                                                <GlobalButton
                                                                                                    onClick={() => {
                                                                                                        handleCopyToClipboard(multipleCopyText());
                                                                                                        setCopyText("Copied!")
                                                                                                    }}
                                                                                                    disabled={
                                                                                                        paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                                    }
                                                                                                    buttonText={<IconsColor iconName="Copy" fillColor="#6A097D" width={"18"} height={"18"} />}
                                                                                                    className='borderedButtonStyle'></GlobalButton>
                                                                                            </Tooltip>

                                                                                        </Grid>

                                                                                    </CopyToClipboard>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <GlobalButton
                                                                                        disabled={
                                                                                            paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                        }
                                                                                        onClick={handleMultipleDelete} buttonText={<IconsColor iconName="Delete" fillColor="#6A097D" width={"18"} height={"18"} />} className='borderedButtonStyle' />
                                                                                </Grid>
                                                                            </Grid>
                                                                            {/* <GlobalButton buttonText={<IconsColor iconName="download" fillColor="red" />} className='borderedButtonStyle' /> */}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>}
                                                        </Grid>
                                                        :
                                                        <>
                                                            <Box sx={{ height: "100%", padding: "10px", alignItems: "center", display: "flex" }}>
                                                                <Box className={globalStyles.globalTextStyleGrey} alignItems={"center"} sx={{ maxWidth: "355px" }}>
                                                                    AI paraphrased content will show up here
                                                                    <img src={shine} style={{ width: "20px", height: "20px" }} />
                                                                </Box>

                                                            </Box>
                                                        </>
                                                    }

                                                </Grid>}
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12} className={styles.mbOpSection2MainItem}>
                                        <Grid container className={styles.mbOpSection2MainContainer}>

                                            <Grid item xs={12}>
                                                <Grid container id="test">
                                                    <Grid item xs={12} className={`${styles.mbTabSectionMainItem}`}>
                                                        <Grid container direction={"column"} sx={{ flexWrap: "nowrap", paddingLeft: "15px" }}>
                                                            <Grid item className={styles.preferencesTextStyle}>Set your output preferences</Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {flagLoading.flagContentGenerating ?

                                                        <RoundLoader />
                                                        :
                                                        <>
                                                            {/* lower tabs */}
                                                            <Grid item xs={12} className={`${styles.ppInputFieldOptions}`} >
                                                                <Grid container direction="column"
                                                                >

                                                                    {<>
                                                                        <Grid item>
                                                                            <Tabs
                                                                                // className={styles.ppTabs}
                                                                                className={styles.ppTabsSecond}
                                                                                // value={currentSubTab}
                                                                                value={paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab}
                                                                                // onChange={handleChangeSubTab}
                                                                                onChange={(event: SyntheticEvent, newValue: string) => { handleChangeSubTab(event, newValue); changeHeightOfResizer(newValue); }}
                                                                                textColor="secondary"
                                                                                indicatorColor="secondary"
                                                                                aria-label="secondary tabs example"
                                                                            >
                                                                                {SubTabList.map((tabDetail: any, i: any) => (
                                                                                    <Tab className={`${styles.tabStyle}`} key={tabDetail.id} value={tabDetail.tabName} label={tabDetail.tabName} />
                                                                                ))}
                                                                            </Tabs>
                                                                        </Grid>

                                                                        <Grid item xs={12} className={styles.ppMbinputOptions}>
                                                                            <Grid container gap={"0.2rem"} sx={{ padding: "0px 15px 0px 0px" }}>
                                                                                {
                                                                                    paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab == 'Basic' ? (
                                                                                        <BasicParaphrasingTab
                                                                                            morePrompts={morePrompts}
                                                                                            length={length}
                                                                                            CustomPaper={CustomPaper}
                                                                                            mobileScreen={mobileScreen}
                                                                                        />
                                                                                        // BasicParaphrasing(morePrompts, length, setParaphrasingLength, selectedParaphrasingLength, basicParaphrasingVal, setBasicParaphrasingVal, CustomPaper, paraphrasingVersioningArr, setParaphraseVersioningarr, paraphrasingVersionIndex, mobileScreen)
                                                                                    ) :
                                                                                        paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab == 'Advanced' ?
                                                                                            <AdvanceParaphrasingTab length={length} morePrompts={morePrompts} CustomPaper={CustomPaper} />
                                                                                            // AdvancedTabs(length, morePrompts, setAdvanceParaphrasingVal, advanceParaphrasingVal, personaWritingSkills, formatList, depthList, rewriteToneGenericList, workDomainList, CustomPaper, paraphrasingVersioningArr, setParaphraseVersioningarr, paraphrasingVersionIndex, mobileScreen)
                                                                                            :
                                                                                            <>
                                                                                                {paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab == "Custom Prompt" ?
                                                                                                    (<>
                                                                                                        <CustomParaphrasingTab />
                                                                                                    </>)
                                                                                                    :
                                                                                                    <>
                                                                                                        <PersonaParaphrasingTab length={length} />
                                                                                                    </>
                                                                                                }

                                                                                            </>
                                                                                }
                                                                            </Grid>

                                                                        </Grid>
                                                                    </>}
                                                                </Grid>
                                                                {/* <Divider sx={{ borderStyle: "unset !important" }} /> */}
                                                            </Grid>

                                                            {/* language, no of output */}
                                                            <Grid item xs={12} className={styles.mBoptionStatic}>
                                                                <Grid container className={styles.paraphraseLangButtonParent} justifyContent={'space-between'} alignItems={"center"}>
                                                                    {/* language and no of output */}
                                                                    <Grid item className={styles.languageOutputCountContainer}>
                                                                        <Grid container justifyContent={"space-between"} alignItems={"end"} className={globalStyles.containerflexWrapStyle} sx={{ gap: "5px", alignItems: "flex-end" }}>
                                                                            {/* language */}
                                                                            <Grid item xs={5.85} className={styles.languageContainer} style={{ textAlign: "center" }}>
                                                                                <Grid container className={`${globalStyles.containerflexWrapStyle} ${styles.languageContainerChild}`} justifyContent={"center"} alignItems="center">
                                                                                    <Grid item className={styles.bottomTextStyle}>Output Language</Grid>
                                                                                    <Grid item className={styles.languageParent}>
                                                                                        <Autocomplete
                                                                                            className={`${styles.languageAutocomplete} ${styles.autocompleteParaphrasing}`}
                                                                                            disablePortal
                                                                                            disableClearable
                                                                                            id="languageList"
                                                                                            blurOnSelect={true}
                                                                                            onOpen={() => { handleSetMobileDrawer([{ name: "Same as Input" }, ...languageList], "selectedLanguage", "name", null, false, null, "Language") }}
                                                                                            // options={languages[0].optionsList.map((option) => option)}
                                                                                            options={languageList.map((language) => language.name)}
                                                                                            value={paraphrasingVersioningArr[paraphrasingVersionIndex].selectedLanguage}
                                                                                            onChange={(e, value: any) => {
                                                                                                setParaphraseVersioningarr((prevState: any) =>
                                                                                                    prevState.map((item: any, index: any) =>
                                                                                                        index == paraphrasingVersionIndex ?
                                                                                                            { ...item, selectedLanguage: value } : item
                                                                                                    ));
                                                                                            }}

                                                                                            renderInput={(params) => <TextField {...params} placeholder='Search' />}
                                                                                            PaperComponent={({ children }) => (
                                                                                                <Paper className={styles.languagePaperStyle}>{children}</Paper>
                                                                                            )}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                            {/* number of output */}
                                                                            <Grid item xs={5.85} className={styles.outputCountContainer}>
                                                                                <Grid container className={`${globalStyles.containerflexWrapStyle} ${styles.noOfOutputContainerChild}`} alignItems={"center"}>
                                                                                    <Grid item>
                                                                                        <Typography className={styles.bottomTextStyle}>#Output</Typography>
                                                                                    </Grid>
                                                                                    <Grid item className={styles.noOutputParent}>
                                                                                        <Autocomplete
                                                                                            disablePortal
                                                                                            // className={" multiple_chips__autocomplete formWiz-input-field"}
                                                                                            className={styles.autocompleteParaphrasing}
                                                                                            id="noOfOutput"
                                                                                            onOpen={() => { handleSetMobileDrawer(staticNumList, "selectedOutputCount", "no_of_output", null, false, null, "No of output") }}

                                                                                            options={staticNumList.map((output) => output.no_of_output)}
                                                                                            value={paraphrasingVersioningArr[paraphrasingVersionIndex].selectedOutputCount}
                                                                                            onChange={(e, value: any) => {
                                                                                                setParaphraseVersioningarr((prevState: any) =>
                                                                                                    prevState.map((item: any, index: any) =>
                                                                                                        index == paraphrasingVersionIndex ?
                                                                                                            { ...item, selectedOutputCount: value } : item
                                                                                                    ));
                                                                                            }}

                                                                                            // value={selectedOutputCount}
                                                                                            // onChange={(e, value: any) => { setSelectedOutputCount(value) }}
                                                                                            renderInput={(params) => <TextField {...params} />}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </>}
                                                </Grid>
                                            </Grid>

                                            {/* paraphrase button */}
                                            {!flagLoading.flagContentGenerating &&
                                                <Grid item className={`${styles.paraphraseButtonContainer} ${styles.mbOpSection2ParaphrserBtnMainItem}`} sx={{ width: "170px" }}>
                                                    <Grid container className={`${styles.mbOpSection2ParaphrserBtnMainContainer}`}>
                                                        <GlobalButton
                                                            buttonText="Paraphrase"
                                                            flagFullWidth
                                                            height='40px !important'
                                                            className="primaryButtonStyle"
                                                            disabled={getParaphraseDisableFlag()}
                                                            // disabled={paraphrasingInput.length < 5}
                                                            onClick={async () => { handleCollapse(true, true); await handleParaphrase(); getContainerHeight(); }}
                                                        />
                                                    </Grid>
                                                </Grid>}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </div>
                    </div>
                </SplitPane >
            </div >
        </>
    )
}

export default ParaphrasingMobileComponent

