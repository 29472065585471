import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses
} from "@mui/material/StepConnector";
import { StepContent, StepIconProps } from "@mui/material";
import styles from "../../assets/styles/componentStyles/stepper.module.scss"
import { Grid, Radio } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { stepperComponentModel } from "../../models/componentModel";
import { DocContext, DocContextPayload } from "../../context/docContext";
import globalStyles from "../../assets/styles/global.module.scss";
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.lineVertical}`]: {
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.lineVertical}`]: {
    }
  },
  [`& .${stepConnectorClasses.lineVertical}`]: {
    height: 70,
    border: 0,
    width: "3px",
    marginLeft: "2px",
    flexDirection: "column",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1
  }
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  background: "#fff",
  zIndex: 1,
  color: "#fff",
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  border: "2px solid #CFCFCF",
  width: "30px",
  height: "30px",
  ...(ownerState.active && {
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {active ?
        (<Radio
          checked={true}
          sx={{
            fontSize: "smaller",
            "&.Mui-checked": {
              color: "#9831AD"
            }
          }}
        />)
        : completed ? (<CheckCircleOutlineIcon
          sx={{
            color: "#5dc729"
          }}
        />) : null
      }
    </ColorlibStepIconRoot>
  );
}


export default function CustomizedSteppers(props: stepperComponentModel) {
  const { currentActiveStep, steps, currentActiveDocDetails } = React.useContext(DocContext) as DocContextPayload;

  return (
    <Grid container direction="column" alignItems={"center"}>
      <Grid item className={`${styles.stepperTitleGrid} ${globalStyles.fullwidth}`}>{currentActiveDocDetails?.template_name}</Grid>
      <Grid item sx={{ width: "100%" }}>
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            className={styles.stepperStyle}
            orientation="vertical"
            activeStep={currentActiveStep - 1}
            connector={<ColorlibConnector />}
          >
            {steps !== null && steps.map((step: any) => (
              <Step key={step.label}>
                <StepLabel sx={{ position: "relative" }} StepIconComponent={ColorlibStepIcon} className={styles.stepLabelStyle}>
                  <Grid container sx={{ position: "absolute", paddingLeft: "7px" }} direction="column">
                    <Grid item className={styles.stepperLabelStyle}>{step.label}</Grid>
                    <Grid item className={styles.stepperDescriptionStyle}>{step.description}</Grid>
                  </Grid>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Grid>
    </Grid>

  );
}
