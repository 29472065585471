import React, { useContext, useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import { CommonResponseModel, TemplateListDataModel, TemplateDetailModel, templateFormFieldDataModel, rewriteToneGenericListObj, workDomainObj, routeSourceObj, outputFormatObj } from "../models/axiosModel";
import agent from "../services/api";
import { UserContext, UserContextPayload } from "./userContext";

const TemplateContext = React.createContext<TemplateContextPayload | undefined>(
    undefined
);

export interface TemplateContextPayload {
    templateList: TemplateListDataModel[] | null;
    promptList: any;
    setTemplateList: React.Dispatch<React.SetStateAction<TemplateListDataModel[] | null>>;
    GetTemplateList: () => void;
    GetPromptList: () => any;
    customTemplatePromptActionData: string,
    setCustomTemplatePromptActionData: React.Dispatch<React.SetStateAction<string>>;
    customTemplateTemplateFormFieldList: templateFormFieldDataModel | [];
    setCustomTemplateTemplateFormFieldList: React.Dispatch<React.SetStateAction<templateFormFieldDataModel | []>>;
    templateFormFieldsGenericData: templateFormFieldDataModel;
    setTemplateFormFieldsGenericData: React.Dispatch<React.SetStateAction<templateFormFieldDataModel>>;
    rewriteToneGenericList: rewriteToneGenericListObj[]
    setRewriteToneGenericList: React.Dispatch<React.SetStateAction<rewriteToneGenericListObj[] | []>>
    GetRewriteToneGenericList: () => void,
    workDomainList: workDomainObj[];
    setWorkDomainList: React.Dispatch<React.SetStateAction<workDomainObj[]>>
    GetWorkDomainList: () => void;
    routeSources: routeSourceObj[];
    setRouteSources: React.Dispatch<React.SetStateAction<routeSourceObj[] | []>>;
    GetRouteSourceList: () => void;
    outputFormatGenericList: outputFormatObj[];
    setOutputFormatGenericList: React.Dispatch<React.SetStateAction<outputFormatObj[] | []>>;
    GetOutputFormatGenericList: () => void;
}

const TemplateProvider = ({ children }: ChildrenProps) => {

    const [templateList, setTemplateList] = useState<TemplateListDataModel[] | null>(
        null
    );
    const [promptList, setPromptList] = useState<any>(null);
    const [customTemplateTemplateFormFieldList, setCustomTemplateTemplateFormFieldList] = useState<templateFormFieldDataModel | []>([])
    const [customTemplatePromptActionData, setCustomTemplatePromptActionData] = useState<string>("");
    const [templateFormFieldsGenericData, setTemplateFormFieldsGenericData] = useState<any>([{
        "form_field_input_type_id": 2,
        "form_field_input_type_name": "Long Text",
        "form_field_input_type_description": "",
        "form_field_input_type_abbr": "longtext",
        "form_field_input_type_regex": "",
        "form_field_input_type_formwiz_name": "longtext",
        "form_field_input_type_property": [
            {
                "form_field_input_type_property_id": 320,
                "form_field_input_type_property_name": "Helper Text",
                "form_field_input_type_property_description": "helper_text",
                "form_field_data_type_id": 1,
                "form_field_data_type_name": "Text",
                "form_field_data_type_unit": null,
                "form_field_input_type_property_seq_num": 9,
                "form_field_input_type_property_flag_title": false,
                "form_field_input_type_property_default_value": null,
                "form_field_input_type_additional_property": []
            },
            {
                "form_field_input_type_property_id": 203,
                "form_field_input_type_property_name": "Title",
                "form_field_input_type_property_description": "label",
                "form_field_data_type_id": 1,
                "form_field_data_type_name": "Text",
                "form_field_data_type_unit": null,
                "form_field_input_type_property_seq_num": 1,
                "form_field_input_type_property_flag_title": true,
                "form_field_input_type_property_default_value": null,
                "form_field_input_type_additional_property": []
            },
            {
                "form_field_input_type_property_id": 204,
                "form_field_input_type_property_name": "Placeholder",
                "form_field_input_type_property_description": "placeholder",
                "form_field_data_type_id": 1,
                "form_field_data_type_name": "Text",
                "form_field_data_type_unit": null,
                "form_field_input_type_property_seq_num": 2,
                "form_field_input_type_property_flag_title": false,
                "form_field_input_type_property_default_value": null,
                "form_field_input_type_additional_property": []
            },
            {
                "form_field_input_type_property_id": 205,
                "form_field_input_type_property_name": "Is Required?",
                "form_field_input_type_property_description": "required",
                "form_field_data_type_id": 5,
                "form_field_data_type_name": "Boolean",
                "form_field_data_type_unit": null,
                "form_field_input_type_property_seq_num": 3,
                "form_field_input_type_property_flag_title": false,
                "form_field_input_type_property_default_value": null,
                "form_field_input_type_additional_property": []
            },
            {
                "form_field_input_type_property_id": 206,
                "form_field_input_type_property_name": "Hidden",
                "form_field_input_type_property_description": "hidden",
                "form_field_data_type_id": 5,
                "form_field_data_type_name": "Boolean",
                "form_field_data_type_unit": null,
                "form_field_input_type_property_seq_num": 4,
                "form_field_input_type_property_flag_title": false,
                "form_field_input_type_property_default_value": null,
                "form_field_input_type_additional_property": []
            },
            {
                "form_field_input_type_property_id": 207,
                "form_field_input_type_property_name": "Tooltip",
                "form_field_input_type_property_description": "tooltip",
                "form_field_data_type_id": 1,
                "form_field_data_type_name": "Text",
                "form_field_data_type_unit": null,
                "form_field_input_type_property_seq_num": 5,
                "form_field_input_type_property_flag_title": false,
                "form_field_input_type_property_default_value": null,
                "form_field_input_type_additional_property": []
            },
            {
                "form_field_input_type_property_id": 208,
                "form_field_input_type_property_name": "Minimum Limits",
                "form_field_input_type_property_description": "min",
                "form_field_data_type_id": 1,
                "form_field_data_type_name": "Text",
                "form_field_data_type_unit": null,
                "form_field_input_type_property_seq_num": 7,
                "form_field_input_type_property_flag_title": false,
                "form_field_input_type_property_default_value": null,
                "form_field_input_type_additional_property": []
            },
            {
                "form_field_input_type_property_id": 209,
                "form_field_input_type_property_name": "Maximum Limits",
                "form_field_input_type_property_description": "max",
                "form_field_data_type_id": 1,
                "form_field_data_type_name": "Text",
                "form_field_data_type_unit": null,
                "form_field_input_type_property_seq_num": 8,
                "form_field_input_type_property_flag_title": false,
                "form_field_input_type_property_default_value": null,
                "form_field_input_type_additional_property": []
            }
        ]
    }])
    const [rewriteToneGenericList, setRewriteToneGenericList] = useState<rewriteToneGenericListObj[] | []>([])
    const [workDomainList, setWorkDomainList] = useState<workDomainObj[] | []>([])
    const [routeSources, setRouteSources] = useState<routeSourceObj[] | []>([])
    const [outputFormatGenericList, setOutputFormatGenericList] = useState<outputFormatObj[] | []>([]);

    const GetTemplateList = async () => {
        const response = await agent.getTemplateList.get();
        if (response.status) {
            setTemplateList(response.data);
        } else {
            console.log("err---")
            setTemplateList(null)
        }
    }
    const GetPromptList = async () => {
        const response = await agent.getPrompList.get();
        if (response?.status) {
            setPromptList(response.data);
        }
    }
    const GetRewriteToneGenericList = async () => {
        const response = await agent.getRewriteToneGenericList.get();
        if (response.status) {
            setRewriteToneGenericList(response.data)
        } else {
            setRewriteToneGenericList([])
        }
    }
    const GetWorkDomainList = async () => {
        const response = await agent.getWorkDomainList.get();
        if (response.status) {
            setWorkDomainList(response.data)
        } else {
            setWorkDomainList([])
        }
    }
    const GetRouteSourceList = async () => {
        const response = await agent.getRouteSourceIds.get();
        if (response.status) {
            setRouteSources(response.data)
        } else {
            setRouteSources([])
        }
    }
    const GetOutputFormatGenericList = async () => {
        // setTimeout(() => {
        //     let test = agent.apiBuffer.filter((api: any) => api.name == agent.getOutputFormatGenericList.getURL)
        //     if (test.length > 0) {
        //         agent.apiBuffer = agent.apiBuffer.filter((api: any) => api.name != agent.getOutputFormatGenericList.getURL)
        //         console.log("abort func triggered")
        //         test[0].controller.abort();
        //     }

        // }, 100)
        const response = await agent.getOutputFormatGenericList.get();
        if (response.status) {
            setOutputFormatGenericList(response.data)
        } else {
            setOutputFormatGenericList([])
        }
    }
    const payload = {
        templateList,
        promptList,
        setTemplateList,
        GetTemplateList,
        GetPromptList,
        customTemplatePromptActionData,
        setCustomTemplatePromptActionData,
        customTemplateTemplateFormFieldList,
        setCustomTemplateTemplateFormFieldList,
        templateFormFieldsGenericData,
        setTemplateFormFieldsGenericData,
        rewriteToneGenericList,
        setRewriteToneGenericList,
        GetRewriteToneGenericList,
        GetWorkDomainList,
        workDomainList,
        setWorkDomainList,
        routeSources,
        setRouteSources,
        GetRouteSourceList,
        outputFormatGenericList,
        setOutputFormatGenericList,
        GetOutputFormatGenericList
    };

    return (
        <TemplateContext.Provider value={payload}>
            {children}
        </TemplateContext.Provider>
    );
};

export { TemplateContext, TemplateProvider };
