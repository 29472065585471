import * as React from 'react';
import Button from '@mui/material/Button';
import styles from "../../../assets/styles/componentStyles/button.module.scss"
import thunderbolt from "../../../assets/thunderBolt.png";
import flagMoveForwardImg from "../../../assets/moveForwardArrow.png";
import { GlobalStyles } from '@mui/material';
import globalStyles from "../../../assets/styles/global.module.scss";
import upgradeIcon from "../../../assets/upgradeIcon.svg";
import downloadChromeExtensionIcon from "../../../assets/downloadExtensionIcon.svg";
import createPersonaIcon from "../../../assets/createPersona.png";
import updatePersonaIcon from "../../../assets/updatePersona.svg";
import addPersona from "../../../assets/addPersona.svg";
import updatePersona from "../../../assets/update.svg";
import deletePersona from "../../../assets/deletePersona.svg";
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import upgrade from "../../../assets/upgrade.svg";
import IconsColor from '../iconsColor';

interface buttonProps {
  buttonText: string | ReactJSXElement;
  onClick?: () => void;
  onMouseLeave?: () => void;
  className: string;
  disabled?: boolean;
  flagGenerate?: boolean;
  flagMoveForward?: boolean
  flagFullWidth?: boolean;
  height?: string;
  flagUpgrade?: boolean;
  flagDownloadExtension?: boolean;
  flagCreatePersona?: boolean;
  flagUpdatePersona?: boolean;
  flagDeletePersona?: boolean;
  flagAddPersona?: boolean;
  flagMobile?: boolean;
  id?: string;
  sx?: any;
  variant?: "text" | "contained" | "outlined"
  startIcon?: any;
  endIcon?: any;
}
export default function GlobalButton(props: buttonProps) {


  return (
    <Button
      id={props.id ? props.id : ""}
      disabled={props.disabled}
      sx={{ opacity: props.disabled ? "0.5" : "1", textTransform: "unset", height: props.height, minWidth: "unset", ...props.sx }}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClick} className={props.flagFullWidth ? `${globalStyles.fullwidth} ${styles[props.className]}` : styles[props.className]}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      variant={props.variant ? props.variant : "contained"}>
      {props.flagGenerate && <img src={upgrade} style={{ width: "18px", height: "auto", marginRight: "5px" }} />}
      {props.flagUpgrade && <IconsColor iconName="diamond" width="17px" height="16px" fillColor="#fff" style={{ marginRight: props.flagMobile ? "" : "7px" }} />}
      {props.flagDownloadExtension && <img src={downloadChromeExtensionIcon} style={{ marginRight: props.flagMobile ? "" : "9px" }} />}
      {props.flagCreatePersona && <img src={createPersonaIcon} style={{ marginRight: "9px", height: "15px" }} />}
      {props.flagUpdatePersona && <img src={updatePersona} />}
      {props.flagAddPersona && <img src={addPersona} />}
      {props.flagDeletePersona && <img src={deletePersona} />}
      {props.buttonText}
      {props.flagMoveForward && <img src={flagMoveForwardImg} style={{ width: "24px", height: "auto", display: "flex", paddingLeft: "5px" }} />}
    </Button>
  );
}
