import React, { useState, useEffect } from 'react';
import { Slider } from '@mui/material'
import globalStyles from "../../assets/styles/global.module.scss";

export const GlobalSlider = (props: any) => {

    const [sliderValue, setSliderValue] = useState<number | number[]>();


    const getDefaultValue = () => {

        let tempDefault = props?.values?.length && props.values?.filter((value: any) => {

            return value.persona_reading_level_name && props.markedValue.persona_reading_level_name ?
                value.persona_reading_level_name == props.markedValue.persona_reading_level_name :
                value.persona_sentence_length_type && props.markedValue.persona_sentence_length_type ?
                    value.persona_sentence_length_type == props.markedValue.persona_sentence_length_type :
                    value.persona_narrative_style_type && props.markedValue.persona_narrative_style_type ?
                        value.persona_narrative_style_type == props.markedValue.persona_narrative_style_type :
                        value.persona_communication_style_type && props.markedValue.persona_communication_style_type ?
                            value.persona_communication_style_type == props.markedValue.persona_communication_style_type :
                            null
        })[0]

        let tempArray = makeBreakPointsArray(props?.values)
        let finalDefault = tempArray?.filter((obj) => {

            return obj.label == tempDefault.persona_reading_level_name ?
                tempDefault.persona_reading_level_name :
                obj.label == tempDefault.persona_sentence_length_type ?
                    tempDefault.persona_sentence_length_type :
                    obj.label == tempDefault.persona_narrative_style_type ?
                        tempDefault.persona_narrative_style_type :
                        obj.label == tempDefault.persona_communication_style_type ?
                            tempDefault.persona_communication_style_type :
                            null
        })[0]

        if (finalDefault) {
            return finalDefault?.value
        } else {
            return finalDefault?.value
        }
    }

    const makeBreakPointsArray = (arr: any) => {
        let FinalArray = []
        let partLength = 100 / (arr.length - 1)
        let currentValue = 0;
        for (let i = 0; i < arr?.length; i++) {
            let innerObj: any = {}

            innerObj['value'] = currentValue
            innerObj['label'] = arr[i].persona_reading_level_name ? arr[i]['persona_reading_level_name'] :
                arr[i].persona_sentence_length_type ? arr[i]['persona_sentence_length_type'] :
                    arr[i].persona_narrative_style_type ? arr[i]['persona_narrative_style_type'] :
                        arr[i].persona_communication_style_type ? arr[i]['persona_communication_style_type'] :
                            null

            FinalArray.push(innerObj)
            if ((100 - currentValue) < partLength) {
                currentValue = 100
            } else {
                currentValue = currentValue + partLength
            }

            if (innerObj.value < 5) {
                innerObj.value = 5
            }
            else if (innerObj.value > 94) {
                innerObj.value = 94
            }
        }
        return FinalArray
    }

    useEffect(() => {
        (async () => {
            await setSliderValue(() => getDefaultValue())
        })()

    }, [])

    useEffect(() => {
        if (sliderValue === undefined || sliderValue === null) {
            setSliderValue(() => getDefaultValue())
        }
    }, [sliderValue])



    return (
        <>
            <Slider
                value={sliderValue != undefined ? sliderValue : 0}
                className={globalStyles.ppPlanSlider}
                aria-label="pretto slider Custom marks"
                valueLabelDisplay="off"
                track={false}
                defaultValue={sliderValue != undefined ? sliderValue : 0}
                step={5}
                marks={makeBreakPointsArray(props.values)}
                disabled={true}
            />
        </>
    )
}
