import React, { useContext, useEffect } from 'react'
import styles from '../../../../assets/styles/componentStyles/paraphraserLoader.module.scss'
import { Button, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import GlobalButton from '../../../utility/globalButton/button'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Loader from '../../../../assets/Customize-Loader.gif';
import { StyledList } from '@mui/joy/List/List';
import { UserContext, UserContextPayload } from '../../../../context/userContext';
import { CommonResponseModel, CommonResponseSecondaryModel, RegisterDataResponseModel, SendOTPResponseDataModel } from '../../../../models/axiosModel';
import { DocContext, DocContextPayload } from '../../../../context/docContext';
import { PersonaContext, PersonaContextPayload } from '../../../../context/personaContext';
import { useNavigate } from 'react-router-dom';
import { TemplateContext, TemplateContextPayload } from '../../../../context/templateContext';
import stopIcon from "../../../../assets/stopIcon.png";
import ideaIcon from "../../../../assets/idea.png";
import { checkForErrorCode, flagCreditsExhausted, getErrorMessage, isDev, takeUserToPricingPlan } from '../../../../utils/constants';
interface GenerateContentLoaderProps {
    stopGenerating: () => void;
}
const ParaphraserLoader = (props: GenerateContentLoaderProps) => {
    const { userDetail, setUserDetail, Register, setError, flagLoading, setFlagLoading, error, setShowLoginPopup, loaderDetails, setLoaderDetails, setLoaderFilterDataList, loaderFilterDataList, loaderFilterDetails, setFlagShowCreditsExhausted } = useContext(UserContext) as UserContextPayload;
    const { CreateDoc } = useContext(DocContext) as DocContextPayload
    const { personaList } = useContext(PersonaContext) as PersonaContextPayload
    const { templateList } = useContext(TemplateContext) as TemplateContextPayload
    const { stopGenerating, } = props
    const navigate = useNavigate();

    const executeOnClickFunction = (functionName: string) => {
        switch (functionName) {
            case "handleCreateLongPost":
                return createTemplateDoc("Long Social Post")

            case "handleSignup":
                return handleSignup();

            case "handleCreateChatbot":
                return handleCreateChatbot()

            case "handleUpgradeToPremium":
                return handleUpgradeToPremium();

            case "handleGenerateHashtagsCaption":
                return createTemplateDoc("Social Media Photo/Video Post Captions & Hashtags");

            case "handleCreateVideoIdeas":
                return createTemplateDoc("YouTube Video Ideas")

            case "handleCreateVideoHooks":
                return createTemplateDoc("Video Hook & Introduction")
            case "handleCreateCampaign":
                return handleCreateCampaign()
            case "handleCreatePersona":
                return handleCreatePersona()
            case "handleCreateBlog":
                return createTemplateDoc("Blog Post Creation");
            case "handleCreateShortSocialPost":
                return createTemplateDoc("Short Social Post")
            default:
                return () => { console.log("no function found err") }

        }
    };
    const handleCreatePersona = () => {
        window.open("/dashboard/createPersona", "_blank")
    }
    const getCurrentButtonDetails = () => {
        const currentDetail = loaderFilterDetails?.loaderDetails?.[loaderFilterDetails.index];
        // by default if nothing found
        if (!currentDetail) {
            return {
                functionName: '',
                className: 'primaryButtonStyle'
            };
        }
        try {
            const [className, functionName] = JSON.parse(currentDetail.cta_button_related_field);
            return { className, functionName };
        } catch (error) {
            return { functionName: '', className: 'primaryButtonStyle' };
        }
    };
    const handleSignup = () => {
        setShowLoginPopup(true)
    }
    const handleCreateChatbot = () => {
        window.open(window.location.origin + "/dashboard/createChatbot", "_blank")
    }
    const handleUpgradeToPremium = () => {
        let urlToNavigateTo = takeUserToPricingPlan(userDetail);
        if (urlToNavigateTo.toNavigate) {
            navigate(urlToNavigateTo.url)
        } else {
            window.open(urlToNavigateTo.url, "_blank")
        }
    }
    const handleCreateCampaign = () => {
        window.open(window.location.origin + "/dashboard/campaigns/create", "_blank")
    }
    const handleCreateUser = async () => {
        const payload = {
            google_recaptcha_token: null,
        };
        const response: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> =
            await Register(payload);
        if (response.status) {
            if (Array.isArray(response.data)) {
                setUserDetail({ ...response.data[0], flagUser: response.status, flagUserFromParaphrase: true })
            }
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
            setFlagLoading({ ...flagLoading, flagRoundLoader: false, flagContentGenerating: false });
        }
        return response;
    };
    const getTemplateIDENVName = (templateName: string) => {
        switch (templateName) {
            case "Long Social Post":
                return "REACT_APP_LONG_POST_TEMPLATE_ID";
            case "Social Media Photo/Video Post Captions & Hashtags":
                return "REACT_APP_HASHTAGS_AND_CAPTIONS_TEMPLATE_ID";
            case "YouTube Video Ideas":
                return "REACT_APP_VIDEO_IDEAS_TEMPLATE_ID";
            case "Video Hook & Introduction":
                return "REACT_APP_VIDEO_HOOKS_TEMPLATE_ID";
            case "Blog Post Ideas":
                return "REACT_APP_BLOG_POST_IDEAS_TEMPLATE_ID";
            case "Short Social Post":
                return "REACT_APP_SHORT_SOCIAL_POST_TEMPLATE_ID";
            case "Blog Post Creation":
                return "REACT_APP_BLOG_POST_CREATION_TEMPLATE_ID";
            default:
                return ""
        }
    }
    const createTemplateDoc = async (templateName: string) => {
        let envName: string = getTemplateIDENVName(templateName)
        let templateId = Number(process.env[envName])
        setFlagLoading({ ...flagLoading, flagRoundLoader: true })
        let responseOfCreateUser: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> = {}
        if (templateList) {
            templateId = templateList[0].category_list.flatMap((category) => category.template_data_list).find((template) => template.template_name === templateName)?.template_id ?? Number(process.env[envName])
        }
        if (userDetail.flagUser) {
            responseOfCreateUser = { status: true }
        } else {
            responseOfCreateUser = await handleCreateUser();
        }
        if (responseOfCreateUser.status) {
            const defaultPersona = personaList?.find((persona) => persona.user_default_persona_data.find((personaData) => personaData.default_persona_type_name == "General"));
            const personaId = defaultPersona?.persona_id ?? null;
            const payload = {
                template_id: templateId,
                persona_id: personaId
            }
            const response = await CreateDoc(payload)
            if (response.status) {
                if (response.data) {
                    if (response.data[0]) {
                        if (response.data[0].template_type_name == "Single-Page") {
                            window.open(`${window.location.origin}/doc/${response.data[0].document_uuid}/3/${response.data[0].template_id}`, "_blank")
                        } else {
                            response.data[0] !== null && window.open(`/doc/${response.data[0].document_uuid}/1/${response.data[0].template_id}`, "_blank")
                        }
                    }
                }
            } else {
                let errorCode = checkForErrorCode(response)
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        }
        setFlagLoading((prevState) => ({ ...prevState, flagRoundLoader: false }));
    }

    return (
        <>
            <Grid container justifyContent={"center"}>
                <Grid item
                    className={styles.loaderGrid}
                >
                    <Grid container>
                        {/* upper block which contains the stop btn */}
                        <Grid item xs={12} className={styles.prLoaderItem1}>
                            <Grid container className={styles.prLoaderContainer1} sx={{ flexWrap: "nowrap" }}>
                                <Grid item>
                                    <Grid container gap="7px" alignItems={"center"}>
                                        <Grid item>
                                            <img src={Loader} className={styles.customizeLoaderGif} alt="" />
                                        </Grid>
                                        <Grid item>
                                            <Typography component='span' className={styles.prLoaderBufferText}>
                                                Generating your content...
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item>
                                            <Tooltip title="Stop Generating">
                                                <IconButton>
                                                    <img src={stopIcon} onClick={stopGenerating} style={{ width: "18px", height: "18px" }} alt="Stop" />
                                                </IconButton>
                                            </Tooltip>
                                            {/* <Button
                                                onClick={stopGenerating}
                                                variant='contained'
                                                fullWidth
                                                className={styles.prRecordingBtn}
                                                startIcon={<FiberManualRecordIcon style={{ color: "#E33629", fontSize: "15px", fontWeight: '500' }} />}
                                            >
                                                Stop Generating
                                            </Button> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* bottom dynamic content */}
                        <Grid item xs={12}
                            // className={styles.prLoaderItem2}
                            sx={{
                                backgroundImage: loaderFilterDetails?.loaderDetails?.[loaderFilterDetails.index]?.background_image_url
                                    ? `url(${loaderFilterDetails.loaderDetails[loaderFilterDetails.index].background_image_url})`
                                    : "url('../../Loader-bg.png')",
                                backgroundColor: "white",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                padding: "20px",
                                margin: "20px 0px",
                                borderRadius: "10px",
                                zIndex: "1",
                            }}
                        >
                            <Grid container direction={"column"} alignItems={"start"}>
                                {/* main heading */}
                                <Grid item xs={12}>
                                    <Typography component={'span'} className={styles.prLoaderHeadText}>
                                        <img
                                            src={loaderFilterDetails?.loaderDetails?.[loaderFilterDetails.index]?.icon_image_url ? loaderFilterDetails?.loaderDetails?.[loaderFilterDetails.index]?.icon_image_url : ideaIcon}
                                            style={{ width: "18px", height: "18px", marginRight: "5px" }}
                                            alt="idea"
                                        />


                                        <span dangerouslySetInnerHTML={{ __html: loaderFilterDetails?.loaderDetails?.[loaderFilterDetails.index]?.image_heading }}
                                        >
                                        </span>
                                    </Typography>
                                </Grid>
                                {/* description */}
                                <Grid item xs={12} className={styles.prDescTextItem}>
                                    <Typography component={'span'} className={styles.prDescText}>

                                        <span dangerouslySetInnerHTML={{ __html: loaderFilterDetails?.loaderDetails?.[loaderFilterDetails.index]?.image_description }}
                                        >
                                        </span>
                                    </Typography>
                                </Grid>
                                {/* cta btn */}
                                <Grid item xs={12} className={styles.prBloggingBtnItem}>
                                    <Grid container>
                                        <Grid item>
                                            <Button
                                                variant='contained'
                                                size='medium'
                                                fullWidth
                                                startIcon={
                                                    loaderFilterDetails?.loaderDetails?.[loaderFilterDetails.index]?.
                                                        cta_button_icon_url &&
                                                    <img
                                                        alt=""
                                                        src={loaderFilterDetails?.loaderDetails?.[loaderFilterDetails.index]?.
                                                            cta_button_icon_url
                                                            ? loaderFilterDetails?.loaderDetails?.[loaderFilterDetails.index]?.
                                                                cta_button_icon_url
                                                            : ''}
                                                        style={{ width: "20px", height: "auto" }}
                                                    />
                                                }
                                                className={styles[getCurrentButtonDetails().className] || styles.prBloggingBtn}
                                                onClick={() => {
                                                    executeOnClickFunction(getCurrentButtonDetails().functionName)
                                                }}
                                            >
                                                {(!userDetail.flagUser || userDetail.flag_user_anonymous) ? loaderFilterDetails?.loaderDetails?.[loaderFilterDetails.index]?.cta_button_text : loaderFilterDetails?.loaderDetails?.[loaderFilterDetails.index]?.cta_button_text}
                                                {/* {(!userDetail.flagUser || userDetail.flag_user_anonymous) ? "Sign Up" : "Start Blogging Now"} */}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ParaphraserLoader