import React, { useContext, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import styles from "../../../assets/styles/componentStyles/paraphrasing.module.scss";
import { ParaphraseContext, ParaphraseContextPayload } from '../../../context/paraphraseContext';
import globalStyles from "../../../assets/styles/global.module.scss"
import GlobalSearch from '../../utility/GlobalSearch/GlobalSearch';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
interface CustomDrawerProps {
    drawerOpen: boolean;
    handleDrawerClose: () => void;
    searchValue: string;
    setSearchValue: (value: string) => void;
    dropdownOptions: any[]; // Adjust the type as needed
    paraphrasingVersioningArr: any[]; // Adjust the type as needed
    paraphrasingVersionIndex: number;
    optionKeyName: string,
    paraphrasingTabKey: string | null,
    optionImage: string | null,
    paraphrasingPayloadKeyValue: string,
    isValMultiple: boolean,
    dropdownName: string
}

const CustomDrawer: React.FC<CustomDrawerProps> = ({
    drawerOpen,
    handleDrawerClose,
    searchValue,
    setSearchValue,
    dropdownOptions,
    paraphrasingVersioningArr,
    paraphrasingVersionIndex,
    optionKeyName,
    paraphrasingTabKey,
    optionImage,
    paraphrasingPayloadKeyValue,
    isValMultiple,
    dropdownName
}) => {
    const { setParaphraseVersioningarr } = useContext(ParaphraseContext) as ParaphraseContextPayload
    const handleCheckboxChange = (optionName: string, isChecked: boolean) => {
        const currentValue = paraphrasingTabKey !== null
            ? paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue]
            : paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue];

        const updatedValue = isChecked
            ? isValMultiple
                ? [...currentValue, optionName]
                : optionName
            : isValMultiple
                ? currentValue.filter((option: any) => option !== optionName)
                : '';

        if (paraphrasingTabKey !== null) {
            if (paraphrasingPayloadKeyValue == "tone_of_voice") {
                setParaphraseVersioningarr((prevState: any) =>
                    prevState.map((item: any, index: number) =>
                        index === paraphrasingVersionIndex
                            ? { ...item, basicParaphrasingVal: { ...item.basicParaphrasingVal, tone_of_voice: updatedValue }, advanceParaphrasingVal: { ...item.advanceParaphrasingVal, tone_of_voice: updatedValue } }
                            : item
                    )
                );
            } else {
                setParaphraseVersioningarr((prevState: any) =>
                    prevState.map((item: any, index: number) =>
                        index === paraphrasingVersionIndex
                            ? { ...item, [paraphrasingTabKey]: { ...item[paraphrasingTabKey], [paraphrasingPayloadKeyValue]: updatedValue } }
                            : item
                    )
                );
            }
        } else {
            setParaphraseVersioningarr((prevState: any) =>
                prevState.map((item: any, index: any) =>
                    index === paraphrasingVersionIndex
                        ? { ...item, [paraphrasingPayloadKeyValue]: updatedValue }
                        : item
                )
            );
        }
    };
    const handleSelectOption = (optionName: string) => {

        if (paraphrasingTabKey !== null) {
            setParaphraseVersioningarr((prevState: any) =>
                prevState.map((item: any, index: number) =>
                    index === paraphrasingVersionIndex
                        ? { ...item, [paraphrasingTabKey]: { ...item[paraphrasingTabKey], [paraphrasingPayloadKeyValue]: optionName } }
                        : item
                )
            );
        } else {
            setParaphraseVersioningarr((prevState: any) =>
                prevState.map((item: any, index: any) =>
                    index === paraphrasingVersionIndex
                        ? { ...item, [paraphrasingPayloadKeyValue]: optionName }
                        : item
                )
            );
        }
        handleDrawerClose();
    }
    return (
        <Drawer
            variant='temporary'
            anchor="bottom"
            open={drawerOpen}
            onClose={handleDrawerClose}
            sx={{ height: '50%', display: 'flex', flexDirection: 'column', ' & .MuiPaper-root': { maxHeight: `${dropdownOptions.length * 50 + 50}px` } }}
        >
            <div style={{ padding: '16px', position: 'sticky', top: 0, zIndex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                    {dropdownOptions.length > 6 ?
                        <>
                            <GlobalSearch
                                fullWidth
                                onChange={(e: any) => setSearchValue(e.target.value)}
                                size="small"
                                placeholder={dropdownName}
                            />
                        </>
                        :
                        <>
                            <IconButton className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}  ${globalStyles.primaryColor} ${styles.dropdownTitleStyle}`} onClick={handleDrawerClose}>
                                {dropdownName}
                            </IconButton>

                        </>
                    }
                    <IconButton className={`${globalStyles.primaryFontSize} ${globalStyles.primaryFontWeight}  ${globalStyles.primaryColor} ${styles.dropdownTitleStyle}`} onClick={handleDrawerClose}>
                        Done
                    </IconButton>
                </div>
            </div>
            <div style={{
                overflowY: 'auto', flex: 1,
                maxHeight: `${window.innerHeight - 200}px`,
                // minHeight: drawerOpen ? `${window.innerHeight - 200}px` : ""
            }}
            >
                {dropdownOptions.filter((option: any) => {
                    const optionKeyValue = option[optionKeyName];

                    // Check if option[optionKeyName] is a string before applying toLowerCase()
                    if (typeof optionKeyValue === 'string') {
                        return optionKeyValue.toLowerCase().includes(searchValue.toLowerCase());
                    }

                    // Handle non-string types differently here (if needed)
                    // For example, you can return true to include all non-string values

                    return true;
                })
                    .map((option: any, index: number) => (
                        isValMultiple ?
                            <>
                                <div style={{ display: "flex", padding: "10px", gap: "15px" }} key={index} >
                                    {
                                        <Checkbox
                                            className={styles.checkboxStyle}
                                            key={index}
                                            checked={
                                                paraphrasingTabKey !== null
                                                    ? (
                                                        (Array.isArray(paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue])
                                                            && paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue].includes(option[optionKeyName]))
                                                        ||
                                                        (typeof paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue] === 'number'
                                                            && paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue] === option[optionKeyName])
                                                        ||
                                                        (typeof paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue] === 'string'
                                                            && paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue] === option[optionKeyName])
                                                    )
                                                    : (
                                                        (Array.isArray(paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue])
                                                            && paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue].includes(option[optionKeyName]))
                                                        ||
                                                        (typeof paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue] === 'number'
                                                            && paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue] === option[optionKeyName])
                                                        ||
                                                        (typeof paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue] === 'string'
                                                            && paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue] === option[optionKeyName])
                                                    )
                                            }

                                            onChange={(e) => handleCheckboxChange(option[optionKeyName], e.target.checked)}
                                        />}
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            {optionImage !== null &&
                                                <img
                                                    src={option[optionImage]}
                                                    alt="icon"
                                                    style={{ width: '17px', height: '17px', marginRight: '8px' }}
                                                />}
                                        </div>
                                        <div style={{ fontSize: "15px", fontWeight: "500" }}> {option[optionKeyName]}</div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <ListItemButton
                                    onClick={() => handleSelectOption(option[optionKeyName])}
                                    key={index}
                                    selected={
                                        paraphrasingTabKey !== null
                                            ? (
                                                (Array.isArray(paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue])
                                                    && paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue].includes(option[optionKeyName]))
                                                ||
                                                (typeof paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue] === 'number'
                                                    && paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue] === option[optionKeyName])
                                                ||
                                                (typeof paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue] === 'string'
                                                    && paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingTabKey][paraphrasingPayloadKeyValue] === option[optionKeyName])
                                            )
                                            : (
                                                (Array.isArray(paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue])
                                                    && paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue].includes(option[optionKeyName]))
                                                ||
                                                (typeof paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue] === 'number'
                                                    && paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue] === option[optionKeyName])
                                                ||
                                                (typeof paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue] === 'string'
                                                    && paraphrasingVersioningArr[paraphrasingVersionIndex][paraphrasingPayloadKeyValue] === option[optionKeyName])
                                            )}
                                >
                                    {optionImage !== null &&
                                        <ListItemIcon sx={{ minWidth: "30px !important" }}>
                                            <img src={option[optionImage]} style={{ width: '17px', height: '17px' }} alt="icon" />
                                        </ListItemIcon>
                                    }
                                    <ListItemText className={styles.listItemStyle} primary={
                                        option[optionKeyName]} />
                                </ListItemButton>
                            </>
                    ))}
            </div>
        </Drawer>
    );
};

export default CustomDrawer;

;
