import React from 'react'
import styles from './commonErrorPage.module.scss'
import { Button, Grid, Typography } from '@mui/material'
import { Link, Navigate } from 'react-router-dom'
import errorIcon from './../assets/errorPageIllustration.png'

const CommonErrorPage = () => {
  return (
    <Grid xs={12} container className={styles.errorPageGridContainer}>
        <Grid item xs={11} lg={10} xl={9} className={styles.errorPageGridBox}>
          <img src={errorIcon} className={styles.errorImage} alt='Error'/> 
          <Typography component={"span"} className={styles.somethingWentText}>Looks like there was a problem</Typography>
          <Typography component={"span"} className={styles.errorText}>Apologies for the inconvenience, we are currently experiencing technical difficulties. We're working to fix it.</Typography>
          <Typography component={"span"} className={styles.errorText}>Please refresh the page or try again later. For more information contact our team <Link to="https://writewiz.ai/contact-support">Here</Link></Typography>
          <Button variant='contained' onClick={() => <Navigate to={"/dashboard"} />} className={styles.primaryButtonStyle}>Return to Homepage</Button>
        </Grid>
    </Grid>
  )
}

export default CommonErrorPage
