import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Grid, Snackbar, Alert, } from "@mui/material";
// import { FormSubmissionAPI, GetWidgetDetailsAPI } from '../app.service';
import { useNavigate, useParams } from "react-router-dom";
import GlobalForm from "../utility/formWiz/globalForm";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import RoundLoader from "../utility/globalLoader";
import GlobalButton from "../utility/globalButton/button";
import { DocContext } from "../../context/docContext";
import GlobalSnackbar from "../utility/globalSnackbar";
import { emptyUserDetail, flagCookieExpired } from "../../utils/constants";
import { checkForErrorCode, flagCreditsExhausted, formWizPageThemeStyle, getErrorMessage, isDev, prompts } from "../../utils/constants";
import { UserContext } from "../../context/userContext";
import globalStyles from "../../assets/styles/global.module.scss";
import { TemplateContext } from "../../context/templateContext";
import { useSearchParam } from "react-use";
import GlobalRoundLoader from "../utility/globalRoundLoader";
const FormPreview = (props) => {
    const navigate = useNavigate();
    const { error, setError, setFlagLoading, setFlagShowCreditsExhausted, userDetail, flagLoading, promptLogFieldGenericList, setUserDetail } = useContext(UserContext)
    const { currentActiveStep, setCurrentActiveStep, setFlagShowGenerateTitlesButton, flagShowGenerateTitlesButton, createdDoc, currentActiveDocDetails, setCurrentActiveDocDetails, UpdateDocForm, ExecutePrompt, setAISuggestedTitles, aiSuggestedTitles, flagTextLoader,
        setFlagTextLoader, flagGenerateButtonDisabled, setFlagGenerateButtonDisabled, setFlagShowAIOutput, blogTitle, setFlagGenerateTitleButtonClicked, setFlagDebouncePending, flagDebouncePending, executePromptResponse,
        setExecutePromptResponse, setCurrentActiveDocFieldVal, currentActiveDocFieldVal, flagValUpdated, setFlagValUpdated, GetDocDetails, flagShowAIOutput, setTabValuePanel, setFlagFormDetailsChanged, documentOutlineData } = useContext(DocContext);
    const { routeSources, outputFormatGenericList } = useContext(TemplateContext)
    const { ActiveStep } = useParams();
    const userToken = useParams();
    const [widget_favicon_url, set_Widget_favicon_url] = useState("");
    const [widget_logo_url, set_Widget_logo_url] = useState("");
    const [submissionID, setsubmissionID] = useState(null);
    const [showForm, setShowForm] = useState(true);
    const [loaderFlag, setLoaderFlag] = useState(false);
    const [flagFirstRenderDone, setFlagFirstRenderDone] = useState(false);
    const [initialCurrentActiveDocDetailsData, setInitialCurrentActiveDocDetailsData] = useState([]);
    const [renderValue, setRenderValue] = useState({
        status: null,
        message: "",
        submission_id: null
    })
    const [docData, setDocData] = useState({
        data: {
            widget_form_full_width: true,
            global_form_field: [
            ],
            doc_page_theme: formWizPageThemeStyle
            // [
            //     {
            //         "page_id": "682",
            //         "page_theme_font_type": "Open Sans",
            //         "page_theme_font_style": "normal",
            //         "page_theme_font_align": "left",
            //         "page_theme_font_weight": "bold",
            //         "page_theme_font_size": "14",
            //         "page_theme_background_color": "#000 !important",
            //         "page_theme_heading_color": "#EFE4F1",
            //         "page_theme_text_decoration": "unset",
            //         "page_hide_button": false,
            //         "page_auto_save": false,
            //         "page_theme_row_spacing": "10px 0px 1px 0px",
            //         "page_theme_column_spacing": 0,
            //         "margin": "0px 0px 8px 0px"
            //     }
            // ]
        }
    });
    const [flagPending, setFlagPending] = useState(false)
    const [googleIdFinal, setGoogleIdFinal] = useState([]);

    // const [debouncedInputValue] = useDebounce(docData, 2000);
    // setting the value in docData in 500 instead of 2000
    const [debouncedInputValue] = useDebounce(docData, 500);


    useEffect(() => {
        if (blogTitle == "") {
            setFlagShowGenerateTitlesButton(false)
        } else {
            setFlagGenerateTitleButtonClicked(true)
        }
        if (currentActiveDocDetails !== null) {
            const formFieldSubmissionData = currentActiveDocDetails.document_step_data[0].document_form_submission_data[0].document_form_field_submission_data;
            for (const fieldData of formFieldSubmissionData) {
                const isRequired = fieldData.form_field_input_type_property_data['Is Required?'];
                const submissionValue = fieldData.document_form_field_submission_data_value;
                const maxLimit = fieldData.form_field_input_type_property_data['Minimum Limits'];
                // loaded initially
                if (docData.data.global_form_field.length == 0) {
                    if (isRequired === true && (submissionValue === null || submissionValue === "" || typeof submissionValue == "string" && submissionValue.length < Number(maxLimit))) {
                        // setFlagShowGenerateTitlesButton(true)
                        setFlagGenerateButtonDisabled(true)
                        break;
                    } else {
                        // setFlagShowGenerateTitlesButton(false)
                        setFlagGenerateButtonDisabled(false)
                    }
                } else {
                    const globalFormFields = docData.data.global_form_field;
                    let isAnyRequiredFieldEmpty = false;
                    for (const field of globalFormFields) {
                        // added condition for checking min length too
                        if (field.required == true && (field.value === "" || field.value === null || (typeof field.value == "string" && field.value.length < Number(field.min)) || (Array.isArray(field.value) && field.value.length == 0))) {
                            isAnyRequiredFieldEmpty = true; // Found at least one empty required field
                            break;
                        }
                    }
                    setFlagGenerateButtonDisabled(isAnyRequiredFieldEmpty);
                }
            }
            if (currentActiveDocDetails) {
                convertDataFormwiz(currentActiveDocDetails);
            }

            // condition for hiding generate title button when blog context has value

        }
    }, [currentActiveDocDetails])

    useEffect(() => {

        let widget_favicon_url = "https://api.filemaster.io/api/storage/file?file_id=" + docData.data.widget_favicon_id +
            "&flag_info=false";
        set_Widget_favicon_url(widget_favicon_url)
        let widget_logo_url = "https://api.filemaster.io/api/storage/file?file_id=" + docData.data.widget_logo_id + "&flag_info=false";
        set_Widget_logo_url(widget_logo_url)
        if (flagFirstRenderDone)
            debounced()

    }, [docData])

    const debounced = useDebouncedCallback(
        () => {
            if (docData.data.widget_flag_auto_save)
                // here changing the 3000 timespan to 1500
                formSubmitFunction(docData)
        }, 1500);

    const handleCloseSnackBar = () => {
        setRenderValue({
            status: null,
            message: "",
            submission_id: null
        })
    }

    const setFieldValue = (data) => {
        // check here for disable condition of button
        setFlagValUpdated(false)
        var tempdata = { ...docData }
        tempdata.data.global_form_field.map((newvalue) => {

            if (newvalue.options) {
                newvalue.options.map((option_value) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
            if (newvalue.type == "file") {
                setGoogleIdFinal(newvalue.value)
            }
        })
        if (currentActiveDocDetails.template_type_name !== "Blog-Generation") {
            setCurrentActiveDocFieldVal(tempdata.data.global_form_field)
        } else {
            setCurrentActiveDocFieldVal(null)
        }
        // checking for generatebuttonDisableCondition
        const globalFormFields = tempdata.data.global_form_field;
        let isAnyRequiredFieldEmpty = false;
        for (const field of globalFormFields) {
            // added condition for checking min length too
            if (field.required == true && (field.value === "" || field.value === null || (typeof field.value == "string" && field.value.length < Number(field.min)) || (Array.isArray(field.value) && field.value.length == 0))) {
                isAnyRequiredFieldEmpty = true; // Found at least one empty required field
                break;
            }
        }
        setFlagGenerateButtonDisabled(isAnyRequiredFieldEmpty);
        if (documentOutlineData && documentOutlineData.length > 0) {
            if (JSON.stringify(tempdata) == JSON.stringify(initialCurrentActiveDocDetailsData)) {
                setFlagFormDetailsChanged(false)
            } else {
                setFlagFormDetailsChanged(true)
            }
        }
        setDocData(tempdata)
        setFlagFirstRenderDone(true)
    }

    const formSubmitFunction = async (data) => {
        // converting data back to writewiz form
        const submissionDataArray = data.data.global_form_field;

        const documentFormFieldSubmissionData = submissionDataArray.map((field) => {
            // debugger;
            const formFieldInputTypePropertyData = {
                "Title": field.label,
                "Placeholder": field.placeholder,
                "Is Required?": field.required,
                "Options": field.options.map(option => option.field_option_value),
            };

            return {
                "document_form_field_submission_data_id": field.page_field_id,
                "form_field_input_type_abbr": field.type,
                "form_field_input_type_value_flag": field.value == null ? null : field.value == "" ? false : true,
                "template_form_field_generic_name": field.template_form_field_generic_name,
                // if the value is emptyArray or emptyString i need to convert this back to null for backend validation
                // "document_form_field_submission_data_value": field.value,
                "document_form_field_submission_data_value": (field.value == [] || field.value == "") ? null :
                    field.form_field_input_type_value_flag == null ? //checking value flag is null then i need to send whatever value it is
                        field.value
                        : field.form_field_input_type_value_flag ?   //checking value flag is true or not, if true i need to send the value in array otherwise string
                            (Array.isArray(field.value) ? field.value : [field.value])  //need to check if it is already in array format or not if yes no need to convert array
                            : field.value,
                "form_field_input_type_property_data": formFieldInputTypePropertyData,
            };
        });

        // const temp = { ...currentActiveDocDetails } //not doing this bec it was allocating the same memory and hence we would not get some values in fields
        const temp = JSON.parse(JSON.stringify(currentActiveDocDetails))
        temp.document_step_data[0].document_form_submission_data[0].document_form_field_submission_data = documentFormFieldSubmissionData

        const payload = {
            document_uuid: temp.document_uuid,
            document_form_submission_id: temp.document_step_data[0].document_form_submission_data[0].document_form_submission_id,
            document_form_field_submission_data: temp.document_step_data[0].document_form_submission_data[0].document_form_field_submission_data.map((formField) => {
                return {
                    document_form_field_submission_data_id: formField.document_form_field_submission_data_id,
                    // converting data from array to comma separated string
                    // document_form_field_submission_data_value: formField.document_form_field_submission_data_value.isArray ? formField.document_form_field_submission_data_value.join(',') : formField.document_form_field_submission_data_value
                    document_form_field_submission_data_value: formField.document_form_field_submission_data_value
                }
            })
        }
        var finalData = { ...debouncedInputValue.data };

        if (data.submit_button || (docData.data.widget_flag_auto_save && !data.submit_button)) {

            delete finalData.timestamp
            delete finalData.token
            delete finalData.widget_domain
            delete finalData.widget_font_color
            delete finalData.widget_favicon_id
            delete finalData.widget_flag_auto_save
            delete finalData.widget_flag_hide_button
            delete finalData.widget_logo_align
            delete finalData.widget_logo_id
            delete finalData.widget_name
            delete finalData.widget_page
            delete finalData.doc_page_theme
            delete finalData.widget_logo_size
            delete finalData.widget_preview_color
            delete finalData.widget_sub_heading
            delete finalData.widget_type
            delete finalData.widget_title
        }
        if (data.submit_button) {
            finalData['widget_flag_submit'] = true
        }
        if (docData.data.widget_flag_auto_save && !data.submit_button) {
            finalData['widget_flag_submit'] = false
        }
        const response = await UpdateDocForm(payload);
        setFlagValUpdated(true)

        if (response.status) {
            console.log("-----updateCallDone", new Date())
        } else {
            setError({
                ...error,
                flagShowError: true,
                message: "Something Went wrong! Please try again later.",
            });
        }
    }

    var faviconId = docData.data.widget_favicon_id ? docData.data.widget_favicon_id : null;
    var logoId = docData.data.widget_logo_id ? docData.data.widget_logo_id : null;

    // for converting writewiz data structure to formwiz data structure
    const convertDataFormwiz = async (currentActiveDocDetails) => {
        if (currentActiveDocDetails.template_type_name == "Blog-Generation") {
            setCurrentActiveDocFieldVal(null)
        }
        const documentFormFields = currentActiveDocDetails.document_step_data[0].document_form_submission_data[0].document_form_field_submission_data;

        const globalFormField = documentFormFields.sort((a, b) => a.template_form_field_seq_num - b.template_form_field_seq_num).map((field, index) => {
            const fieldName = `${field.document_form_field_submission_data_id}_${field.form_field_input_type_property_data.Title.replace(/\s+/g, '_')}`;

            const optionsArray = field.form_field_input_type_property_data.Options || [];
            const options = Array.isArray(optionsArray)
                ? optionsArray.map((option, optionIndex) => {
                    return {
                        "field_option_data_id": optionIndex,
                        "field_option_value": option.trim(),
                        "field_option_flag": false
                    };
                })
                : [];
            // console.log("currentActiveDocFieldVal-----", currentActiveDocFieldVal)
            // console.log("value condition---", currentActiveDocFieldVal == null ?
            //     (
            //         field.document_form_field_submission_data_value == null ?
            //             (field.form_field_input_type_property_data['Default Value'] ?
            //                 field.form_field_input_type_property_data['Default Value']
            //                 :
            //                 field.form_field_input_type_value_flag == null ? "" : field.form_field_input_type_value_flag == true ? [] : "")
            //             :
            //             (field.document_form_field_submission_data_value)
            //     )
            //     :
            //     (currentActiveDocFieldVal.filter((val) => val.page_field_id == field.document_form_field_submission_data_id)[0].value),)
            // const formField = {
            //     "page_field_id": field.document_form_field_submission_data_id,
            //     "label": field.form_field_input_type_property_data.Title,
            //     "placeholder": field.form_field_input_type_property_data.Placeholder,
            //     "required": field.form_field_input_type_property_data["Is Required?"],
            //     "name": fieldName,
            //     "type": field.form_field_input_type_abbr,
            //     "value": field.document_form_field_submission_data_value,
            //     "hidden": field.form_field_input_type_property_data.hidden,
            //     // "options": options,
            //     "flag_ai_suggestion": field.template_form_field_flag_ai_generation
            // };

            // if (field.form_field_input_type_property_data.Tooltip) {
            //     formField.tooltip = field.form_field_input_type_property_data.Tooltip;
            // }
            // if(field.form_field_input_type_property_data.Options){
            //     formField.options = options
            // }
            // return formField

            return {
                "page_field_id": field.document_form_field_submission_data_id,
                "label": field.form_field_input_type_property_data.Title,
                "placeholder": field.form_field_input_type_property_data.Placeholder,
                "required": field.form_field_input_type_property_data["Is Required?"],
                "name": fieldName,
                // npm start"type":field.form_field_input_type_abbr,
                // "type": (field.form_field_input_type_abbr == "multiple chip text field" || field.form_field_input_type_abbr == "multiple chip text and dropdown") ? "chip" : field.form_field_input_type_abbr,
                "type": field.form_field_input_type_abbr,
                // need to take value from context

                // initial value
                // "value": currentActiveDocFieldVal == null ? (field.form_field_input_type_property_data['Default Value'] ? field.form_field_input_type_property_data['Default Value'] : field.document_form_field_submission_data_value == null ?
                //     (field.form_field_input_type_value_flag == null ? "" : field.form_field_input_type_value_flag == true ? [] : "") :
                //     (field.document_form_field_submission_data_value)) : (currentActiveDocFieldVal.filter((val) => val.page_field_id == field.document_form_field_submission_data_id)[0].value),
                "value": currentActiveDocFieldVal == null ?
                    (
                        field.document_form_field_submission_data_value == null ?
                            (field.form_field_input_type_property_data['Default Value'] ?
                                field.form_field_input_type_property_data['Default Value']
                                :
                                field.form_field_input_type_value_flag == null ? "" : field.form_field_input_type_value_flag == true ? [] : "")
                            :
                            (field.document_form_field_submission_data_value)
                    )
                    :
                    (currentActiveDocFieldVal.filter((val) => val.page_field_id == field.document_form_field_submission_data_id)[0].value),
                // "value": field.document_form_field_submission_data_value == null ?
                //     (field.form_field_input_type_value_flag == null ? "" : field.form_field_input_type_value_flag == true ? [] : "") :
                //     (field.document_form_field_submission_data_value),
                // value: (field.form_field_input_type_value_flag == null ? field.document_form_field_submission_data_value : field.form_field_input_type_value_flag == true ? [] : ""),
                "hidden": field.form_field_input_type_property_data.Hidden,
                "options": options,
                "tooltip": field.form_field_input_type_property_data.Tooltip,
                "flag_ai_suggestion": field.template_form_field_flag_ai_generation,
                "template_form_field_generic_name": field.template_form_field_generic_name,
                "is_disabled": props.isDisabled,
                "form_field_input_type_value_flag": field.form_field_input_type_value_flag,
                "min": field.form_field_input_type_property_data["Minimum Limits"],
                "max": field.form_field_input_type_property_data["Maximum Limits"],
                "is_multiple": field.form_field_input_type_property_data["Is Multiple?"],
                "is_searchable": field.form_field_input_type_property_data["Is Searchable?"],
                "is_valid_new_option": field.form_field_input_type_property_data["Add Custom Options"]
            };
        });

        const docDataTemp = {
            data: {
                widget_form_full_width: true,
                global_form_field: globalFormField,
                doc_page_theme: formWizPageThemeStyle,
                // [
                //     {
                //         "page_id": "682",
                //         "page_theme_font_type": "Open Sans",
                //         "page_theme_font_style": "normal",
                //         "page_theme_font_align": "left",
                //         "page_theme_font_weight": "bold",
                //         "page_theme_font_size": "14",
                //         "page_theme_background_color": "#000 !important",
                //         "page_theme_heading_color": "#EFE4F1",
                //         "page_theme_text_decoration": "unset",
                //         "page_hide_button": false,
                //         "page_auto_save": false,
                //         "page_theme_row_spacing": "10px 0px 1px 0px",
                //         "page_theme_column_spacing": 0,
                //         "margin": "0px 0px 8px 0px"
                //     }
                // ],
                widget_flag_auto_save: true
            }
        };
        await setInitialCurrentActiveDocDetailsData(docDataTemp)
        await setDocData(docDataTemp)
    }

    // const getAIsuggestionList = async(file_field_id) =>{
    //     console.log("file_field_id",file_field_id)
    //     // const payload = {
    //     //     document_uuid:currentActiveDocDetails.document_uuid,
    //     //     prompt_id: 
    //     // }

    //     // const response = await ExecutePrompt;
    //     if(docData.data.global_form_field){
    //         setTimeout(function(){
    //             var ff = JSON.parse(JSON.stringify(docData))
    //             ff.data.global_form_field.map(rr=>{
    //                     if(rr.page_field_id===file_field_id){
    //                         rr['ai_suggestions_loader'] = false
    //                         rr['ai_suggestions_list'] = [
    //                             { id: "1", value: "Nikunj" },
    //                             { id: "2", value: "Test "+parseInt(Math.random()*100) },
    //                             { id: "3", value: "Test "+parseInt(Math.random()*100) },
    //                             { id: "4", value: "Test "+parseInt(Math.random()*100) },
    //                             { id: "5", value: "Test "+parseInt(Math.random()*100) }
    //                         ]
    //                         const templateName = rr.template_form_field_generic_name;
    //                         const matchingPrompt = prompts.AISuggestions.find(
    //                             (prompt) => prompt.template_form_field_generic_name === templateName
    //                         );

    //                         if (matchingPrompt) {
    //                             // Return the found prompt_id
    //                             console.log("Matching prompt_id:", matchingPrompt.prompt_id);
    //                             // You can also return this value or handle it as needed
    //                         }
    //                         const payload = {
    //                             document_uuid: currentActiveDocDetails.document_uuid,
    //                             prompt_id: matchingPrompt.prompt_id
    //                         }
    //                         const response =  ExecutePrompt(payload);
    //                         console.log("response of prompt",response)
    //                     }
    //             })
    //             setDocData(ff)
    //         },2000)
    //     }
    //     return true
    // }
    const getAIsuggestionList = async (file_field_id) => {

        if (docData.data.global_form_field) {
            const ff = JSON.parse(JSON.stringify(docData));

            for (const rr of ff.data.global_form_field) {
                if (rr.page_field_id === file_field_id) {
                    rr['ai_suggestions_loader'] = false;

                    const templateName = rr.template_form_field_generic_name;
                    const matchingPrompt = prompts.AISuggestions.find(
                        (prompt) => prompt.template_form_field_generic_name === templateName
                    );

                    if (matchingPrompt) {
                        try {
                            const payload = {
                                document_uuid: currentActiveDocDetails.document_uuid,
                                prompt_id: matchingPrompt.prompt_id,
                                route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id,
                                output_format_id: outputFormatGenericList.find((outputFormat) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
                            };

                            const response = await ExecutePrompt(payload);
                            if (response.status) {
                                rr['ai_suggestions_list'] = response.data.map((item, index) => ({
                                    id: (index + 1).toString(),
                                    // value: item.content
                                    value: item[matchingPrompt.prompt_key]
                                }));

                            } else {
                                let errorCode = checkForErrorCode(response)
                                if (flagCookieExpired(userDetail, errorCode)) {
                                    setUserDetail(emptyUserDetail)
                                    if (userDetail.flag_user_anonymous) {
                                        let url = window.location.pathname
                                        navigate(url, { replace: true });
                                    } else {
                                        setError({
                                            ...error,
                                            flagShowError: true,
                                            message: "Session expired! Please login to continue."
                                        });
                                        setTimeout(() => {
                                            let url = encodeURIComponent(window.location.href)
                                            navigate("/login?redirect=" + url, { replace: true });
                                        }, 1500);
                                    }
                                } else {
                                    if (flagCreditsExhausted(errorCode)) {
                                        setFlagShowCreditsExhausted(true)
                                    } else {
                                        if (flagCreditsExhausted(errorCode)) {
                                            setFlagShowCreditsExhausted(true)
                                        } else {
                                            setError({
                                                ...error,
                                                flagShowError: true,
                                                message: getErrorMessage(errorCode, isDev(userDetail))
                                            });
                                        }
                                    }
                                }

                            }

                        } catch (error) {
                            console.error("Error executing prompt:", error);
                        }
                    }

                    break; // No need to continue the loop if we found a match
                }
            }

            setDocData(ff);
        }

        return true;
    };
    function generatePromptLogFieldData(currentActiveDocDetails, genericList) {
        const prompt_log_field_data = [];

        for (const field of docData.data.global_form_field) {
            const fieldName = field.label.toLowerCase();
            const value = field.value;

            for (const item of genericList) {
                const promptLogFieldName = item.prompt_log_field_name.toLowerCase();

                if (fieldName.includes(promptLogFieldName) && (value !== "" && value !== null)) {
                    prompt_log_field_data.push({
                        "field_id": item.prompt_log_field_id,
                        "value": Array.isArray(value) ? JSON.stringify(value) : value,
                        "template_id": currentActiveDocDetails.template_id
                    });
                    break;
                }
            }
        }

        return prompt_log_field_data;
    }
    const handleGenerateTitle = async () => {
        // custom template 
        if (currentActiveDocDetails.prompt_id) {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            setFlagGenerateTitleButtonClicked(true)
            setFlagTextLoader(true)
            if (debounced.isPending()) {
                setFlagDebouncePending(true)
                setTimeout(async () => {
                    const payload = {
                        document_uuid: currentActiveDocDetails !== null ? currentActiveDocDetails.document_uuid : "",
                        prompt_id: currentActiveDocDetails.prompt_id,
                        route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id,
                        output_format_id: outputFormatGenericList.find((outputFormat) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
                        prompt_log_field_data: generatePromptLogFieldData(currentActiveDocDetails, promptLogFieldGenericList)

                    }
                    const response = await ExecutePrompt(payload)
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                    setFlagTextLoader(false)
                    if (response.status) {
                        if (response.data) {
                            if (currentActiveDocDetails.document_name == "Untitled Document") {
                                setTimeout(() => {
                                    GetDocDetails(currentActiveDocDetails.document_uuid)
                                }, 3000)
                            }
                            setTabValuePanel(1)
                            setExecutePromptResponse(response)
                            setFlagShowAIOutput(true) //this is for outline component testing
                            setFlagShowGenerateTitlesButton(false)
                            setAISuggestedTitles(response.data)
                        }
                    } else {
                        let temp = [...aiSuggestedTitles]
                        temp[0].title = null
                        setAISuggestedTitles(temp)
                        let errorCode = checkForErrorCode(response)
                        if (flagCookieExpired(userDetail, errorCode)) {
                            setUserDetail(emptyUserDetail)
                            if (userDetail.flag_user_anonymous) {
                                let url = window.location.pathname
                                navigate(url, { replace: true });
                            } else {
                                setError({
                                    ...error,
                                    flagShowError: true,
                                    message: "Session expired! Please login to continue."
                                });
                                setTimeout(() => {
                                    let url = encodeURIComponent(window.location.href)
                                    navigate("/login?redirect=" + url, { replace: true });
                                }, 1500);
                            }
                        } else {
                            if (flagCreditsExhausted(errorCode)) {
                                setFlagShowCreditsExhausted(true)
                            } else {
                                if (flagCreditsExhausted(errorCode)) {
                                    setFlagShowCreditsExhausted(true)
                                } else {
                                    setError({
                                        ...error,
                                        flagShowError: true,
                                        message: getErrorMessage(errorCode, isDev(userDetail))
                                    });
                                }
                            }
                        }
                    }

                }, 1500)
            } else {
                setFlagDebouncePending(false)
                const payload = {
                    document_uuid: currentActiveDocDetails !== null ? currentActiveDocDetails.document_uuid : "",
                    prompt_id: currentActiveDocDetails.prompt_id,
                    route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id,
                    output_format_id: outputFormatGenericList.find((outputFormat) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
                    prompt_log_field_data: generatePromptLogFieldData(currentActiveDocDetails, promptLogFieldGenericList)

                }
                const response = await ExecutePrompt(payload)
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                setFlagTextLoader(false)
                if (response.status) {
                    if (response.data) {
                        if (currentActiveDocDetails.document_name == "Untitled Document") {
                            await GetDocDetails(currentActiveDocDetails.document_uuid)
                        }
                        setExecutePromptResponse(response)
                        setFlagShowAIOutput(true) //this is for outline component testing
                        setTabValuePanel(1)
                        setFlagShowGenerateTitlesButton(false)
                        setAISuggestedTitles(response.data)
                    } else {
                        // setAISuggestedTitles(aiSuggestedTitles)
                        let temp = [...aiSuggestedTitles]
                        temp[0].title = null
                        setAISuggestedTitles(temp)
                        setError({ ...error, flagShowError: true, message: "Something went Wrong! Please try again later." })
                    }

                } else {
                    let errorCode = checkForErrorCode(response)
                    if (flagCookieExpired(userDetail, errorCode)) {
                        setUserDetail(emptyUserDetail)
                        if (userDetail.flag_user_anonymous) {
                            let url = window.location.pathname
                            navigate(url, { replace: true });
                        } else {
                            setError({
                                ...error,
                                flagShowError: true,
                                message: "Session expired! Please login to continue."
                            });
                            setTimeout(() => {
                                let url = encodeURIComponent(window.location.href)
                                navigate("/login?redirect=" + url, { replace: true });
                            }, 1500);
                        }
                    } else {

                        if (flagCreditsExhausted(errorCode)) {
                            setFlagShowCreditsExhausted(true)
                        } else {
                            if (flagCreditsExhausted(errorCode)) {
                                setFlagShowCreditsExhausted(true)
                            } else {
                                setError({
                                    ...error,
                                    flagShowError: true,
                                    message: getErrorMessage(errorCode, isDev(userDetail))
                                });
                            }
                        }
                    }
                }
            }
            const payload = {
                document_uuid: currentActiveDocDetails !== null ? currentActiveDocDetails.document_uuid : "",
                prompt_id: currentActiveDocDetails.prompt_id
            }

        } else {
            //our defined templates
            const templateName = currentActiveDocDetails.template_name;
            const matchingPrompt = prompts.AISuggestions.find(
                (prompt) => prompt.template_name === templateName
            );
            if (matchingPrompt) {
                try {
                    setFlagGenerateTitleButtonClicked(true)
                    setFlagTextLoader(true)
                    if (debounced.isPending()) {
                        setFlagDebouncePending(true)
                        setTimeout(async () => {
                            const payload = {
                                document_uuid: currentActiveDocDetails !== null ? currentActiveDocDetails.document_uuid : "",
                                prompt_id: matchingPrompt.prompt_id,
                                route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id,
                                output_format_id: outputFormatGenericList.find((outputFormat) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
                                prompt_log_field_data: generatePromptLogFieldData(currentActiveDocDetails, promptLogFieldGenericList)
                            }
                            const response = await ExecutePrompt(payload)
                            setFlagTextLoader(false)
                            if (response.status) {
                                if (response.data) {
                                    setFlagShowGenerateTitlesButton(false)
                                    setAISuggestedTitles(response.data)
                                }
                            } else {
                                let temp = [...aiSuggestedTitles]
                                temp[0].title = null
                                setAISuggestedTitles(temp)
                                setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
                            }

                        }, 1500)
                    } else {
                        setFlagDebouncePending(false)
                        const payload = {
                            document_uuid: currentActiveDocDetails !== null ? currentActiveDocDetails.document_uuid : "",
                            prompt_id: matchingPrompt.prompt_id,
                            route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id,
                            output_format_id: outputFormatGenericList.find((outputFormat) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
                            prompt_log_field_data: generatePromptLogFieldData(currentActiveDocDetails, promptLogFieldGenericList)
                        }
                        const response = await ExecutePrompt(payload)
                        setFlagTextLoader(false)
                        if (response.status) {
                            if (response.data) {
                                setFlagShowGenerateTitlesButton(false)
                                setAISuggestedTitles(response.data)
                            }
                        }
                        else {
                            let errorCode = checkForErrorCode(response)
                            if (flagCookieExpired(userDetail, errorCode)) {
                                setUserDetail(emptyUserDetail)
                                if (userDetail.flag_user_anonymous) {
                                    let url = window.location.pathname
                                    navigate(url, { replace: true });
                                } else {
                                    setError({
                                        ...error,
                                        flagShowError: true,
                                        message: "Session expired! Please login to continue."
                                    });
                                    setTimeout(() => {
                                        let url = encodeURIComponent(window.location.href)
                                        navigate("/login?redirect=" + url, { replace: true });
                                    }, 1500);
                                }
                            } else {
                                if (flagCreditsExhausted(errorCode)) {
                                    setFlagShowCreditsExhausted(true)
                                } else {
                                    if (flagCreditsExhausted(errorCode)) {
                                        setFlagShowCreditsExhausted(true)
                                    } else {
                                        setError({
                                            ...error,
                                            flagShowError: true,
                                            message: getErrorMessage(errorCode, isDev(userDetail))
                                        });
                                    }
                                }
                            }

                        }
                    }

                } catch (error) {
                    console.error("Error executing prompt:", error);
                }
            }


        }
    }
    return (
        <>
            {error.flagShowError && (
                <GlobalSnackbar
                    type="error"
                    message={
                        error.message == undefined
                            ? "Something went wrong, Please try again!"
                            : error.message
                    }
                    open={error.flagShowError}
                    handleClose={() => {
                        setError({ ...error, flagShowError: false });
                    }}
                />
            )}
            {loaderFlag ? <GlobalRoundLoader /> : ""}
            {showForm ?
                <>
                    <Grid item className={`${globalStyles.fullHeight} + ${currentActiveDocDetails.template_type_name == "Blog-Generation" ? globalStyles.blogGenerationFormStyles : ""}`} sx={{ paddingBottom: props.flagMobile ? "55px" : "" }}>
                        <Grid container className={`${globalStyles.fullHeight} ${globalStyles.containerflexWrapStyle}`} direction="column" justifyContent={currentActiveDocDetails !== null && currentActiveDocDetails.template_type_name !== "Single-Page" ? "start" : "space-between"} alignItems={"start"}>
                            <Grid item className={currentActiveDocDetails !== null && currentActiveDocDetails.template_type_name !== "Single-Page" ?
                                (ActiveStep == "1" ? globalStyles.formWrapper : globalStyles.formWrapperSingleTemplate)
                                :
                                globalStyles.formWrapperSingleTemplate} style={{ width: "100%", background: docData.data.widget_preview_color, borderRadius: "0px 0px 18px 18px" }}>
                                <GlobalForm
                                    // fields={docData.data.global_form_field}
                                    // fields={convertData(createdDoc).data.global_form_field}
                                    fields={docData.data.global_form_field}
                                    // fields ={testData}
                                    submitFunction={formSubmitFunction}
                                    checkFormSubmit={renderValue}
                                    handleCloseSnackBar={handleCloseSnackBar}
                                    pageThemeData={docData.data.doc_page_theme[0]}
                                    // pageThemeData={pageTestData}
                                    // formData={convertData(createdDoc).data}
                                    formData={docData.data}
                                    submissionID={submissionID}
                                    setFieldValue={setFieldValue}
                                    getAIsuggestionList={(e) => { getAIsuggestionList(e) }}
                                    formEditable={true}
                                />
                            </Grid>
                            {props.flagShowGenerateTitlesButton &&
                                // <Grid sx={{ position: props.flagMobile ? "fixed" : "", bottom: props.flagMobile ? "1.5%" : "", right: props.flagMobile ? "9px" : "", zIndex: "999" }} className={currentActiveDocDetails !== null && currentActiveDocDetails.template_type_name !== "Single-Page" ? globalStyles.generateTitleButtonWrapperBlogGeneration : globalStyles.generateTitleButtonWrapper} item
                                // // sx={{ marginTop: "40px" }}
                                // >
                                <Grid className={currentActiveDocDetails !== null && currentActiveDocDetails.template_type_name !== "Single-Page" ? globalStyles.generateTitleButtonWrapperBlogGeneration2 : globalStyles.generateTitleButtonWrapper} item
                                //  sx={{ marginTop: "40px" }}
                                >
                                    <GlobalButton
                                        buttonText={props.buttonText}
                                        disabled={flagGenerateButtonDisabled}
                                        className="secondaryButtonStyle"
                                        flagGenerate
                                        // onClick={()=>{setFlagGenerateTitles(true)}}
                                        onClick={() => { handleGenerateTitle() }}
                                    />
                                </Grid>}
                        </Grid>
                    </Grid>
                </>
                : ""}
        </>
    );
}
export default FormPreview;