import * as React from "react";
import { Button, Typography, Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { Carousel } from "react-responsive-carousel";
import CloseIcon from "@mui/icons-material/Close";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styless from "../../assets/styles/componentStyles/carousel.module.scss";
import EastIcon from "@mui/icons-material/East";
import idea from "../../assets/idea.png";
import rightArrow from "../../assets/rightArrow.png";
import { carouselComponentPropsModel } from "../../models/componentModel";

export default function CarouselComponent(props: carouselComponentPropsModel) {
  const [open, setOpen] = React.useState(false);


  return (
    <div>
      <Carousel
        className={styless.CarouselStyles}
        // autoPlay={true}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        infiniteLoop={true}
        autoFocus={true}
      >
        {props.tips.map((tip, index) => (
          <Grid item key={index}>
            <Grid container direction="column" justifyContent="center">
              {/* <Grid item sx={{ marginBottom: "45px" }}> */}
              {/* <img style={{width:"250px",height:"250px"}} src={parsingRules}/> */}
              {/* </Grid> */}
              <Grid item>
                <Grid container direction="column" sx={{ marginBottom: "95px" }}>
                  <Grid item sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography className={styless.Heading}>
                      <img src={idea} className={styless.ideaStyle} /> {tip.heading}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{ whiteSpace: "pre-wrap" }}
                      className={styless.descriptionStyle}
                    >
                      {tip.content}
                    </Typography>
                    {/* <Typography className={styless.learnMoreStyle}>
                    <a
                      target="_blank"
                      className={styless.learnMoreStyle}
                      href={tip.buttonHref}
                    >
                      {tip.buttonText}{" "}
                      <img
                        src={rightArrow}
                        className={styless.rightArrowStyle}
                      />
                    </a>
                  </Typography> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Carousel>

    </div>
  );
}
