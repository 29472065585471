import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { Box, Tooltip } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styles from "../../../assets/styles/componentStyles/sideNavigationPanel.module.scss";
import { Grid } from "@mui/material";
import writewizLogo from "../../../assets/writewizLogo.svg";
import { useNavigate } from "react-router";
import { CreditUsedComponent } from "../../utility/creditUsed";
import { SidepanelAccountDetails } from "../../utility/sidePanelAccountDetails";
import GlobalButton from "../../utility/globalButton/button";
import { UserContext, UserContextPayload } from "../../../context/userContext";
import shortWritewizLogo from "../../../assets/shortWritewizLogo.svg";
import globalStyles from "../../../assets/styles/global.module.scss";
import premiumIcon from "../../../assets/personaCrown.svg";
import { withStyles } from '@mui/styles';

const drawerWidth = 233;
const mobileDrawerWidth = "70px !important";
const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    zIndex: 1,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    // width: `calc(${theme.spacing(7)} + 1px)`,
    width: mobileDrawerWidth,
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme)
    })
}));

export default function MiniDrawer(props: any) {
    const navigate = useNavigate()
    const theme = useTheme();
    // const [open, setOpen] = React.useState(false);
    const [currentActiveMenu, setCurrentActiveMenu] = React.useState("home");
    const [hoveredMenu, setHoveredMenu] = React.useState("");
    const { userDetail, flagChromeDesktop, setFlagChromeDesktop, setShowLoginPopup } = React.useContext(UserContext) as UserContextPayload;

    React.useEffect(() => {
        if (window.location.pathname.toLowerCase() == "/dashboard/viewpersona" || window.location.pathname.toLowerCase() == "/dashboard/createpersona" || window.location.pathname.toLowerCase() == "/dashboard/updatepersona") {
            setCurrentActiveMenu("/dashboard/persona")
        } else if (window.location.pathname.toLowerCase() == "/dashboard/createchatbot" || window.location.pathname.toLowerCase() == "/dashboard/chatbot/viewchatbot") {
            setCurrentActiveMenu("/dashboard/chatbot")
        } else if (window.location.pathname.toLowerCase() == "/dashboard/campaigns/create") {
            setCurrentActiveMenu("/dashboard/campaigns")
        } else if (window.location.pathname.toLowerCase() == "/dashboard/customtemplate") {
            setCurrentActiveMenu("/dashboard/templates")
        } else if (window.location.pathname.toLowerCase() == "/dashboard/notes/create") {
            setCurrentActiveMenu("/dashboard/notes")
        } else if (window.location.pathname.toLowerCase() == "/dashboard/settings-plansandbilling") {
            setCurrentActiveMenu("/dashboard/settings-accountDetails")
        }
        else if (window.location.pathname.toLowerCase() == "/dashboard/settings-accountdetails") {
            setCurrentActiveMenu("/dashboard/settings-accountDetails")
        }
        else {
            setCurrentActiveMenu(window.location.pathname.toLowerCase())
        }
    }, [window.location.pathname])

    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };

    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };
    const drawer = (
        <>
            <Grid container direction="column" justifyContent={"space-between"} className={userDetail.flagUser && !userDetail.flag_user_anonymous ? `${styles.navigationPanelContentWrapper}` : `${styles.navigationPanelContentWrapperAnonymous}`}>
                <Grid item>
                    {/* <Toolbar>
                        <img src={writewizLogo} onClick={() => { navigate('/dashboard') }} className={styles.writewizLogoStyle} />
                    </Toolbar> */}
                    <List sx={{ padding: '10px' }} >
                        {props.menuItems.map((menuItem: any, index: any) => {

                            return <ListItem
                                sx={{ opacity: menuItem.flagDisabled ? "0.5" : "1" }}
                                className={currentActiveMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() ? styles.selectedListItemStyle : styles.listItemStyle} onMouseEnter={() => { setHoveredMenu(menuItem.menuKey) }} onMouseLeave={() => { setHoveredMenu("") }}
                                onClick={() => {
                                    if (menuItem.flagDisabled) {
                                        setShowLoginPopup(true)
                                    } else {
                                        if (menuItem.menuName == "AI Chatbot") {
                                            setCurrentActiveMenu(menuItem.navigationLink);
                                            // window.open(process.env.REACT_APP_CHATBOT_LINK, '_self')
                                            navigate('/dashboard/chatbot')
                                        } else {
                                            setCurrentActiveMenu(menuItem.navigationLink);
                                            navigate(menuItem.navigationLink)
                                        }
                                    }
                                }}
                                key={menuItem.menuKey} disablePadding>
                                <ListItemIcon sx={{ transform: "scale(1.2)" }} className={styles.imageNavigationMenu}>
                                    {currentActiveMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() ? menuItem.whiteImgSrc : hoveredMenu.toLowerCase() == menuItem.menuKey.toLowerCase() ? menuItem.coloredImgSrc : menuItem.imgSrc}
                                </ListItemIcon>
                                <ListItemText primary={menuItem.menuName} />
                                {/* {menuItem.flagDisabled &&
                                    <ListItemIcon><img src={premiumIcon} /></ListItemIcon>} */}
                            </ListItem>
                        })}
                    </List>
                </Grid>
                <Grid item>
                    <Grid container direction={"column"}>
                        {!userDetail.flag_user_anonymous &&
                            userDetail.flagUser &&
                            <Grid item className={`${globalStyles.fullwidth} ${styles.creditUsedComponentWrapper}`}>
                                <CreditUsedComponent />
                            </Grid>
                        }
                        {flagChromeDesktop &&
                            <Grid item className={styles.downloadExtensionWrapper}>
                                <GlobalButton onClick={() => { window.open("https://chrome.google.com/webstore/detail/writewiz-ai-content-creat/nikfnkpdalalkollmjgjkgmfblfmlpbd/related?hl=en", '_blank'); }} flagDownloadExtension flagFullWidth buttonText='Download Extension' className='borderedButtonStyle' />
                            </Grid>}
                        <Grid item className={styles.profileWrapper}>
                            <SidepanelAccountDetails />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>

    );
    const CustomTooltip = withStyles((theme: any) => ({
        tooltip: {
            backgroundColor: '#EFD7FE',
            color: '#6A097D',
            fontSize: '13px',
            padding: '8px 15px',
            borderRadius: 5,
        },
        arrow: {
            color: '#EFD7FE',
        },
    }))(Tooltip);
    const mobileDrawer = (
        <>
            <Grid container direction="column" justifyContent={"space-between"} className={styles.navigationPanelContentWrapper}>
                <Grid item>
                    {/* <Toolbar>
                        <img src={shortWritewizLogo} onClick={() => { navigate('/dashboard') }} />
                    </Toolbar> */}
                    <List sx={{ padding: '15px' }} >
                        {props.menuItems.map((menuItem: any, index: any) => {
                            return <CustomTooltip title={menuItem.menuName} arrow placement="right">
                                <ListItem sx={{ display: "flex", justifyContent: "center", alignItems: "center", opacity: menuItem.flagDisabled ? "0.5" : "1", transform: "scale(1.2)" }} className={currentActiveMenu == menuItem.navigationLink ? styles.selectedListItemStyle : styles.listItemStyle} onMouseEnter={() => { setHoveredMenu(menuItem.menuKey) }} onMouseLeave={() => { setHoveredMenu("") }}
                                    onClick={() => {
                                        navigate('/dashboard/chatbot')
                                        // if (menuItem.flagDisabled) {
                                        //     setShowLoginPopup(true)
                                        // } else {
                                        //     if (menuItem.menuName == "AI Chatbot") {
                                        //         setCurrentActiveMenu(menuItem.navigationLink);
                                        //         window.open(process.env.REACT_APP_CHATBOT_LINK, '_self')
                                        //     } else {
                                        //         setCurrentActiveMenu(menuItem.navigationLink);
                                        //         navigate(menuItem.navigationLink)
                                        //     }

                                        // }
                                    }}
                                    key={menuItem.menuKey} disablePadding>
                                    <ListItemIcon className={styles.imageNavigationMenu}>
                                        {currentActiveMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() ? menuItem.whiteImgSrc : hoveredMenu.toLowerCase() == menuItem.menuKey.toLowerCase() ? menuItem.coloredImgSrc : menuItem.imgSrc}
                                    </ListItemIcon>
                                </ListItem>
                            </CustomTooltip>
                        })}
                    </List>
                </Grid>
                <Grid item>
                    <Grid container direction={"column"} className={globalStyles.containerflexWrapStyle}>
                        {!userDetail.flag_user_anonymous &&
                            userDetail.flagUser &&
                            <Grid item className={styles.creditUsedComponentWrapper}>
                                <CreditUsedComponent flagMobile />
                            </Grid>
                        }
                        <Grid item className={styles.downloadExtensionWrapper}>
                            <GlobalButton onClick={() => { window.open("https://chrome.google.com/webstore/detail/writewiz-ai-content-creat/nikfnkpdalalkollmjgjkgmfblfmlpbd/related?hl=en", '_blank'); }} flagMobile flagDownloadExtension flagFullWidth buttonText='' className='borderedButtonStyle' />
                        </Grid>
                        <Grid item className={styles.profileWrapper}>
                            <SidepanelAccountDetails flagMobile />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>

    );
    return (
        <Box sx={{
            display: { xs: 'none', sm: 'block' },
        }}>
            {/* <Toolbar>
                <IconButton
                    aria-label="open drawer"
                    onClick={props.handleDrawerOpen}
                    edge="start"
                    sx={{
                        position: "fixed",
                        right: 0,
                        marginRight: 5,
                        ...(props.open && { display: "none" })
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar> */}
            <Drawer className={styles.sideNavigationPanelStyle} variant="permanent" open={props.open}>
                <DrawerHeader>
                    <Grid container justifyContent={"center"}>
                        <Grid item>
                            {props.open ? <img src={writewizLogo} style={{ cursor: "pointer" }} onClick={() => { navigate('/dashboard') }} className={styles.writewizLogoStyle} />
                                :
                                <img src={shortWritewizLogo} style={{ width: "38px", height: "auto", cursor: "pointer" }} onClick={() => { navigate('/dashboard') }} />
                            }
                        </Grid>

                    </Grid>

                </DrawerHeader>
                {props.open ? drawer : mobileDrawer}
            </Drawer>
        </Box>
    );
}
