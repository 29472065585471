import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserContext, UserContextPayload } from '../../context/userContext';
import { useNavigate } from 'react-router-dom';
import { TemplateContext, TemplateContextPayload } from '../../context/templateContext';
import { DocContext, DocContextPayload } from '../../context/docContext';
import DescriptionIcon from '@mui/icons-material/Description';
import globalStyles from "../../assets/styles/global.module.scss";
import GlobalSearchBar from '../utility/searchBar';
import styles from "../../assets/styles/componentStyles/template.module.scss";
import TabComponent from '../templateComponents/tabComponent';
import BasicAccordion from '../templateComponents/accordionComponent';
import AccountDetails from '../accountDetails';
import Pricing from '../pricing';

const Settings = () => {
    const tabs = [
        { label: 'Account Details', content: <AccountDetails />, tabVisibility: true, flagInitiallyActive: false },
        { label: 'Plans & Billings', content: <Pricing />, tabVisibility: true, flagInitiallyActive: false },
    ];
    return (
        <>
            <Grid container>
                <Grid item xs={12} className={globalStyles.settingsPageMainItem}>
                    <TabComponent tabHeaderClassname="settingsTabStyle" className="settingsTabStyle" tabs={tabs} />
                </Grid>
            </Grid>
        </>
    )
}

export default Settings;