import React, { useContext, useEffect, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/campaigns.module.scss'
import globalStyles from '../../../assets/styles/global.module.scss'
import { useNavigate } from 'react-router-dom'
import { Button, Divider, Grid, Typography } from '@mui/material'
import { CommonResponseModel, CommonResponseSecondaryModel, RegisterDataResponseModel, SendOTPResponseDataModel, createCampaignPayloadModel } from '../../../models/axiosModel'
import { CampaignContext, CampaignContextPayload } from '../../../context/campaignContext'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import GlobalForm from '../../utility/formWiz/globalForm'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText'
import { checkForErrorCode, flagCreditsExhausted, getErrorMessage, isDev, emptyUserDetail, flagCookieExpired } from '../../../utils/constants'


const CreateCampaign = () => {

    const navigate = useNavigate()
    const {
        setFlagLoading,
        success,
        setSuccess,
        error,
        setError,
        setFlagShowCreditsExhausted,
        flagLoading
    } = useContext(UserContext) as UserContextPayload;
    const { createCampaign } = useContext(CampaignContext) as CampaignContextPayload;
    const { userDetail, Register, setUserDetail } = useContext(UserContext) as UserContextPayload;
    const [submissionID, setsubmissionID] = React.useState(null);
    const [renderValue, setRenderValue] = React.useState({
        status: null,
        message: "",
        submission_id: null
    })
    const [flagCreateBtnDisabled, setFlagCreateBtnDisabled] = useState(true)

    const campaignsDetailsForm = [
        {
            "page_field_id": 1666,
            "label": "Campaign Name",
            "placeholder": "Write Name",
            "required": true,
            "name": "1666_Campaign_Name",
            "type": "text",
            "value": "",
            "size": "small",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "class2": "globalTextfieldStyle"
        },
        {
            "page_field_id": 1668,
            "label": "Provide the context for your campaign",
            "placeholder": "Enter Context",
            "required": true,
            "name": "1668_Context_Compign",
            "type": "longtext",
            "value": "",
            "hidden": false,
            "size": "small",
            "position": null,
            "font_color": null,
            "col": 12,
            "rows": 5,
            "class2": "globalTextfieldStyle"
        },


    ]

    const [createCampaignFormData, setCreateCampaignFormData] = useState<any>({
        data: {
            global_form_field: campaignsDetailsForm,
            widget_flag_auto_save: false,
            widget_flag_hide_button: false,
            widget_form_full_width: true,
            doc_page_theme: [
                {
                    "page_id": "682",
                    "page_theme_font_type": "Open Sans",
                    "page_theme_font_style": "normal",
                    "page_theme_font_align": "left",
                    "page_theme_font_weight": "bold",
                    "page_theme_font_size": "15",
                    "page_theme_background_color": "#FFFFFF",
                    "page_theme_heading_color": "#EFE4F1",
                    "page_theme_text_decoration": "unset",
                    "page_hide_button": false,
                    "page_auto_save": false,
                    "page_font_spacing": null,
                    "page_theme_row_spacing": "10px 0px 10px 0px",
                    "page_theme_column_spacing": 0,
                    "margin": "0px 0px 8px 0px"
                }
            ]
        }
    })

    useEffect(() => {
        if (createCampaignFormData.data.global_form_field[0].value.trim() && createCampaignFormData.data.global_form_field[1].value.trim()) {
            setFlagCreateBtnDisabled(false)
        } else {
            setFlagCreateBtnDisabled(true)
        }

    }, [createCampaignFormData])


    const formSubmitFunction = (data: any) => {
        // console.log("devounce value in preview--------------", data);
        var finalData = data;
        // RegisterUser(data)
    }

    const handleCloseSnackBar = () => {
        setRenderValue({
            status: null,
            message: "",
            submission_id: null
        })
    }

    const setFieldValue = (data: any) => {
        var tempdata = { ...createCampaignFormData }
        tempdata.data.global_form_field.map((newvalue: any) => {

            if (newvalue.options) {
                newvalue.options.map((option_value: any) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
        })
        setCreateCampaignFormData(tempdata)
        // setFlagFirstRenderDone(true)
    }
    const handleCreateUser = async () => {
        const payload = {
            google_recaptcha_token: null,
        };
        const response: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> =
            await Register(payload);
        if (response.status) {
            if (Array.isArray(response.data)) {
                setUserDetail({ ...response.data[0], flagUser: response.status, flagUserFromParaphrase: true })
            }
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    };
    const handleSubmitCreatedCampaign = async () => {
        if (userDetail.flagUser) {
        } else {
            await handleCreateUser();
        }
        let payload: createCampaignPayloadModel = {
            campaign_name: createCampaignFormData.data.global_form_field[0].value,
            campaign_context: createCampaignFormData.data.global_form_field[1].value
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let response = await createCampaign(payload)
        if (response.status) {
            if (response.data) {
                let responseCampaignId = response.data[0].campaign_id
                navigate(`/dashboard/campaigns/create?campaign_id=${responseCampaignId}`)
                setCreateCampaignFormData(response.data[0])
                setSuccess({ ...success, flagShowSuccess: true, message: "Campaign Created Successfully!" })
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }
        }
        else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    }
    return (
        <>
            <Grid container className={`${styles.cmpGlobalHeadSpacing}`}>
                <Grid item xs={12} className={styles.cmpGlobalHeader}>
                    <GlobalPageHeaderText
                        mainHeadText={"Campaign"}
                        mainSubText={"Create a campaign that transforms a simple brief into a full suite of essential documents seamlessly"}
                    />
                </Grid>
                <Grid item xs={12} className={`${styles.cmpCreateFormHeight} ${styles.cmpGlobalComponentBorderItem}`}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        component='span'
                                        className={styles.cmpCreateCampaignText}
                                    >
                                        Create your campaign
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <hr className={styles.cmpCreateDivider} />
                        </Grid>

                        <Grid item xl={5} lg={7} md={10} sm={12} xs={12}>
                            <Grid container>
                                <GlobalForm
                                    fields={createCampaignFormData.data.global_form_field}
                                    submitFunction={formSubmitFunction}
                                    checkFormSubmit={renderValue}
                                    handleCloseSnackBar={handleCloseSnackBar}
                                    pageThemeData={createCampaignFormData.data.doc_page_theme[0]}
                                    formData={createCampaignFormData.data}
                                    submissionID={submissionID}
                                    setFieldValue={setFieldValue}
                                    recaptchaFlag="true"
                                    defaultLoader={false}
                                    formEditable={true}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={styles.cmpCreateButton}>
                            <Button
                                variant='contained'
                                size='medium'
                                className={flagCreateBtnDisabled ? `${styles.cmpGlobalContainedDisabledBtnStyles}` : `${styles.cmpGlobalContainedBtnStyles}`}
                                disabled={flagCreateBtnDisabled}
                                onClick={() => {

                                    handleSubmitCreatedCampaign()
                                }}
                            >
                                Create Campaign
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CreateCampaign