import React, { useCallback, useEffect, useState } from "react";
import { MixedTags } from "@yaireo/tagify/dist/react.tagify";
import { useContext } from "react";
import { TemplateContext } from "../../../context/templateContext";
import styles from "../../../assets/styles/componentStyles/customTemplateDraggableList.module.scss";
const MixedModeTagify = (props) => {
    const [settings, setSettings] = useState({
        pattern: /@/,
        dropdown: {
            enabled: 0,
            position: "text"
        },
        whitelist: props.whiteListOptions
    });
    const [inputValue, setInputValue] = useState(""); // Track user's input
    const { customTemplatePromptActionData,
        setCustomTemplatePromptActionData } = useContext(TemplateContext)
    const [key, setKey] = useState(1); // Key to force re-render of MixedTagsff
    useEffect(() => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            whitelist: props.whiteListOptions
        }));
        setKey(key + 1); // Increment the key to force re-render
    }, [props.whiteListOptions]);

    useEffect(() => {
        if (customTemplatePromptActionData) {
            let temp = customTemplatePromptActionData;
            temp = temp.replace(/</gi, '&lt;').replace(/>/gi, '&gt;');
            if (temp !== customTemplatePromptActionData) {
                setCustomTemplatePromptActionData(temp)
            }
        }
    }, [customTemplatePromptActionData])
    const onChange = useCallback((e) => {
        // if (e && e.preventDefault) {
        e.preventDefault && e.preventDefault();
        // }
        console.log("CHANGED:", e.detail.value);
        const inputString = "This is a test string with @fir\n and some other text.";
        const regex = /@[^[\]{}]*\n/
        if (regex.test(e.detail.value)) {
            // console.log("String contains '@' followed by something up to '\\n'");
        } else {
            // console.log("else block", e.detail.value)

            // console.log("String does not contain '@' followed by something up to '\\n'");
        }

    }, []);


    const handlingOnBlur = (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        let val = e.detail.tagify.state.lastOriginalValueReported
        if (e.detail.tagify.state.lastOriginalValueReported) {
            setCustomTemplatePromptActionData(val.replace(/\n+$/, ''))
        }

    }
    return (
        <>
            <MixedTags
                placeholder="Write a compelling caption about Topic @Topic for @Target Audience and make sure to include relevant hashtags in a @Tone tone"
                key={key}
                showDropdown
                settings={settings}
                className={`${styles.templatePromp}`}
                readOnly={false}
                onChange={onChange}
                value={customTemplatePromptActionData}
                onBlur={handlingOnBlur}
                onKeyDown={(e) => {
                    alert("hkjh")
                    e && e.preventDefault && e.preventDefault();
                }}
            // value={props.value}
            />
        </>
    );
};
export default MixedModeTagify;