import React, { useEffect } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { DialogTitle, IconButton, Backdrop } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { FocusTrap } from "@mui/base";
interface GlobalDialogProps extends DialogProps {
  title?: string;
  flagShowCloseIcon?: boolean;
  handleClose: () => void;
  flagBackdrop?: boolean;
  borderRadius?: string; // New prop for borderRadius
  overflow?: string;
  paperMaxwidth?: string;
  paperMinWidth?: string
}
const BootstrapDialog = styled(Dialog)<{ borderRadius?: string, overflow?: string, paperMaxwidth?: string, paperMinWidth?: string }>(({ theme, borderRadius, overflow, paperMaxwidth, paperMinWidth }) => ({

  "& .MuiPaper-root": {
    borderRadius: borderRadius ?? "4px", // Apply borderRadius
    overflow: overflow ?? "",
    maxWidth: paperMaxwidth ?? "",
    minWidth: paperMinWidth ?? ""
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: 0,
  },
  // "@media (max-width: 550px)": {
  //   "& .MuiPaper-root": {
  //     minWidth: "450px",
  //   }
  // },
  // "@media (max-width: 450px)": {
  //   "& .MuiPaper-root": {
  //     minWidth: "365px",
  //   }
  // },
  // "@media (max-width: 400px)": {
  //   "& .MuiPaper-root": {
  //     minWidth: "330px",
  //   }
  // },
}));



function BootstrapDialogTitle(props: GlobalDialogProps) {
  const { title, flagShowCloseIcon, handleClose, flagBackdrop } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center', padding: title && title.length ? "16px" : "0px" }}>
      {title}
      {flagShowCloseIcon && handleClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute !important',
            right: title && title.length ? 8 : 0,
            top: title && title.length ? 8 : 0,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const GlobalPopUp: React.FC<GlobalDialogProps> = ({
  title,
  open,
  flagShowCloseIcon,
  handleClose,
  children,
  flagBackdrop,
  borderRadius,
  ...dialogProps
}) => {
  const handleBackdropClick: React.MouseEventHandler<HTMLElement> = (event) => {
    // Prevent closing when clicking on the backdrop
    if (event.target === event.currentTarget) {
      return;
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    if (open) {
      document.addEventListener('keydown', handleKeyDown);
    }

    // Remove the event listener when the component is unmounted or the dialog is closed
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, handleClose]);

  return (
    <FocusTrap open={true} disableEnforceFocus={true}>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        {...dialogProps}
        disableEscapeKeyDown
        borderRadius={borderRadius}
        disableEnforceFocus={true}
      >
        {flagBackdrop &&
          <Backdrop
            open={open}
            onClick={handleBackdropClick}
            sx={{ opacity: "-1 !important" }}
          >
            {/* Backdrop content here */}
          </Backdrop>}
        <BootstrapDialogTitle
          open={open}
          title={title}
          flagShowCloseIcon={flagShowCloseIcon}
          handleClose={handleClose}
          disableEscapeKeyDown={true}
        />
        {children}
      </BootstrapDialog>
    </FocusTrap>
  );
};

export default GlobalPopUp;
