import { Autocomplete, Chip, Divider, Grid, Paper, TextField, Tooltip, Skeleton, useMediaQuery, Box, MenuItem, Menu, IconButton, Popper, Avatar } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/paraphrasing.module.scss";
import { Typography } from '@mui/joy';
import GlobalTextField from '../../utility/globalTextfield';
import { TemplateContext, TemplateContextPayload } from '../../../context/templateContext';
import GlobalButton from '../../utility/globalButton/button';
import agent from '../../../services/api';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { CommonResponseModel, CommonResponseSecondaryModel, ExecutePromptResponseDataModel, RegisterDataResponseModel, SendOTPResponseDataModel, executePromptResponseParaphraseObj, outputFormatObj } from '../../../models/axiosModel';
import CopyToClipboard from "react-copy-to-clipboard";
import { DocContext, DocContextPayload } from '../../../context/docContext';
import shine from "../../../assets/shine.png"
import { PersonaContext, PersonaContextPayload } from '../../../context/personaContext';
import personaPremiumIcon from "../../../assets/personaCrown.svg";
import { GlobalTooltip } from '../../utility/globalTooltip';
import IconsColor from '../../utility/iconsColor';
import CloseIcon from '@mui/icons-material/Close';
import './paraphrasingCarousel.css'
import ReactMarkdown from "react-markdown";
import { ParaphraseContext, ParaphraseContextPayload } from '../../../context/paraphraseContext';
import personaAvatar from "../../../assets/personaAvatar.svg";
import GlobalPopover from '../../utility/globalPopover';
import ColorGuide from '../../utility/colorGuide';
import WordChangerMobileComponent from './wordChangerMobileComponent';
import ConvertTextToHTML from './wordChanger/utils/convertTextToHtml';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import colorGuideIcon from "../../../assets/colorGuide.svg";
import SplitPane from 'react-split-pane';
import closeArrow from "../../../assets/closeArrow.png";
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, isDev } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';

let test = {
    output_text: `Paraphraser takes your sentences and makes alterations ||*{"replace":"changes","replacement":"alterations","additional_suggestion":["modifications","adjustments"],"suggestion_type_id":3}*||, aiding ||*{"replace":"helping","replacement":"aiding","additional_suggestion":["assisting","facilitating"],"suggestion_type_id":3}*|| you to rework and restate ||*{"replace":"rephrase","replacement":"restate","additional_suggestion":["reword","rewrite"],"suggestion_type_id":3}*|| your content swiftly ||*{"replace":"quickly","replacement":"swiftly","additional_suggestion":["promptly","speedily"],"suggestion_type_id":3}*|| and effectively ||*{"replace":"efficiently","replacement":"effectively","additional_suggestion":["productively","competently"],"suggestion_type_id":3}*||!`
}
const WordChanger = () => {
    const navigate = useNavigate()
    const { personaWritingSkills, personaList } = useContext(PersonaContext) as PersonaContextPayload;
    const { UpdateDocumentEditorDetail, setDocumentEditorData, CreateDoc } = useContext(DocContext) as DocContextPayload
    const { rewordVersioningArr, setRewordVersioningarr, rewordCurrentIndex, setrewordCurrentIndex, rewordVersionIndex, setrewordVersionIndex } = useContext(ParaphraseContext) as ParaphraseContextPayload;
    const { userDetail, flagLoading, Register, setUserDetail, promptLogFieldGenericList, setFlagShowCreditsExhausted } = useContext(UserContext) as UserContextPayload;
    const { promptList, workDomainList, templateList, routeSources, outputFormatGenericList } = React.useContext(TemplateContext) as TemplateContextPayload
    const { setFlagLoading, setError, error } = useContext(UserContext) as UserContextPayload;

    const mobileScreen = useMediaQuery('(min-width: 320px) and (max-width: 600px)')

    const resizeFunction = () => {
        document.querySelectorAll(".resizableTextArea").forEach((element) => {
            const textarea = element as HTMLTextAreaElement;
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 10 + 'px';
        });
    }
    useEffect(() => {
        resizeFunction()
    }, [rewordVersioningArr]); // Empty
    function generatePromptLogFieldData(commonKeys: any, genericList: any) {
        const prompt_log_field_data = [];

        for (const key in commonKeys) {
            const commonKeyWithoutUnderscore = key.replaceAll("_", " ").toLowerCase();
            for (const item of genericList) {
                const promptLogFieldNameWithoutUnderscore = item.prompt_log_field_name.toLowerCase();

                if (commonKeyWithoutUnderscore === promptLogFieldNameWithoutUnderscore) {
                    prompt_log_field_data.push({
                        "field_id": item.prompt_log_field_id,
                        "value": commonKeys[key]
                    });
                    break;
                }
            }
        }

        return prompt_log_field_data;
    }
    const getParaphrasePayload = () => {

        const commonKeys = {
            route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id ?? 2,
            output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
            phrase: rewordVersioningArr[rewordVersionIndex].rewordingInput,
            prompt_id: promptList !== null ? promptList.find((prompt: any) => prompt.prompt_name == "Word Changer")?.prompt_id : Number(process.env.REACT_APP_REWORD_ID)
        };

        let additionalKeys;
        additionalKeys = rewordVersioningArr[rewordVersionIndex].wordChangerPayload;
        // for filtering keys that are not empty
        const filteredAdditionalKeys = Object.fromEntries(
            Object.entries(additionalKeys).filter(([key, value]) => {
                return value !== "" && (value !== null ? (Array.isArray(value) ? value.length > 0 : true) : true);
            })
        );
        let allKeys = { ...commonKeys, ...filteredAdditionalKeys }
        const prompt_log_field_data = generatePromptLogFieldData(allKeys, promptLogFieldGenericList)
        return { ...commonKeys, ...filteredAdditionalKeys, prompt_log_field_data };
    }
    const handleCreateUser = async () => {
        const payload = {
            google_recaptcha_token: null,
        };
        const response: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> =
            await Register(payload);
        if (response.status) {
            if (Array.isArray(response.data)) {
                setUserDetail({ ...response.data[0], flagUser: response.status, flagUserFromParaphrase: true })
            }
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
            setFlagLoading({ ...flagLoading, flagRoundLoader: false, flagContentGenerating: false });
        }
        return response
    };
    const handleReword = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let responseOfCreateUser: CommonResponseModel<RegisterDataResponseModel> = { status: true }
        if (userDetail.flagUser) {
        } else {
            await handleCreateUser();
        }
        if (responseOfCreateUser.status) {
            const response = await agent.executePrompt.post(getParaphrasePayload())
            if (response.status) {
                if (Array.isArray(response.data)) {
                    let temp = response.data.map((responseObj: any, index: any) => {
                        let uniqueIdAddedText = addUniqueId(responseObj[response.prompt_output_key_name])
                        return {
                            id: index + 1,
                            index: 0,
                            value: [
                                {
                                    id: index + 1,
                                    paraphrase: uniqueIdAddedText.replace(/\|\|\*[^*]*\*\|\|/g, ''),
                                    convertedToHTML: <ConvertTextToHTML output_text={uniqueIdAddedText} id={index + 1} indexID={index} updatedOriginalText={uniqueIdAddedText} changeWord={changeWord} />,
                                    originalText: uniqueIdAddedText,
                                    updatedOriginalText: uniqueIdAddedText,
                                    checked: false,
                                    flagEditable: false,
                                    flagLoading: false,
                                    flagOpenMenu: false,
                                    anchorEl: null,
                                    showTooltip: false,
                                    showCardTooltip: false
                                }
                            ]
                        }
                    })
                    resizeFunction()

                    setRewordVersioningarr((prevState: any) => {
                        const updatedArray = prevState.map((item: any, index: any) =>
                            index === rewordVersionIndex
                                ? { ...item, executePromptResponseReword: temp }
                                : item
                        );
                        const lastObject = { ...prevState[prevState.length - 1], executePromptResponseReword: temp, id: prevState[prevState.length - 1].id + 1 };
                        return [...updatedArray, lastObject];
                    });
                    setrewordCurrentIndex(rewordVersioningArr.length)
                    setrewordVersionIndex(rewordVersioningArr.length)
                } else {
                    let errorCode = 1110;
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            } else {
                let errorCode = checkForErrorCode(response)
                if (flagCookieExpired(userDetail, errorCode)) {
                    setUserDetail(emptyUserDetail)
                    if (userDetail.flag_user_anonymous) {
                        let url = window.location.pathname
                        navigate(url, { replace: true });
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: "Session expired! Please login to continue."
                        });
                        setTimeout(() => {
                            let url = encodeURIComponent(window.location.href)
                            navigate("/login?redirect=" + url, { replace: true });
                        }, 1500);
                    }
                } else {
                    if (flagCreditsExhausted(errorCode)) {
                        setFlagShowCreditsExhausted(true)
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                }
            }
        }
        setFlagLoading((prevState) => {
            return { ...prevState, flagRoundLoader: false }
        })
    }

    const handleChangeParaphraseInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    { ...item, rewordingInput: event.target.value } : item
            ))
    }
    const handleCovertInnerHTMLToOriginalText = (innerHTML: string) => {

        // Define the regular expression pattern
        var pattern = /<span[^>]*class="[^"]*"[^>]*>(.*?)<\/span>(?:<span style="display: none;">(.*?)<\/span>)?/g;

        // Find all matches in the input string
        var matches: any = innerHTML.matchAll(pattern);

        // Initialize the starting index
        var lastIndex = 0;

        // Iterate through matches and build the output string
        var outputString = "";
        for (var match of matches) {
            var visibleText = match[1].trim();
            var hiddenText = match[2] ? match[2].trim() : null;

            // Add the text between the current match and the previous one
            outputString += innerHTML.substring(lastIndex, match.index);

            if (hiddenText) {
                outputString += `${visibleText} ||* ${hiddenText} *|| `;
            } else {
                outputString += `${visibleText} `;
            }

            // Update the last index
            lastIndex = match.index + match[0].length;
        }

        // Add the remaining text after the last match
        outputString += innerHTML.substring(lastIndex);

        return outputString.trim();

    }
    const handleParaphraseOutputChange = (event: any, option: any) => {
        const { value } = event.target;
        const updatedResponse = rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.map((item: any) =>
            item.id === option.id ?
                {
                    ...item,
                    value: [
                        ...item.value.map((val: any, index: any) =>
                            index === option.index ? { ...val, paraphrase: event.target.innerText, updatedOriginalText: handleCovertInnerHTMLToOriginalText(event.target.innerHTML) } : val)
                    ]
                }
                : item
        );
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    { ...item, executePromptResponseReword: updatedResponse } : item
            ));
    }
    const [copyText, setCopyText] = React.useState("Copy")

    const handleCopyToClipboard = (textToCopy: any) => {
        if (textToCopy) {
            try {
                navigator.clipboard.writeText(textToCopy);
                setCopyText("Copied!"); // Update the button text
            } catch (error) {
                console.error("Clipboard write error:", error);
                setCopyText("Copy Failed"); // Handle copy failure
            }
        }
    };
    const handleClearText = () => {

        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    { ...item, rewordingInput: "" } : item
            ))
        // handleCloseSection1Menu()
    }
    const handleTrySampleText = () => {
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    { ...item, rewordingInput: "Paraphraser takes your sentences and makes changes, helping you to rework and rephrase your content quickly and efficiently!" } : item
            ))
    }



    const getRewordDisableFlag = () => {
        if (
            rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length == 0 ||
            rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().length < 4 ||
            rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 1000
        ) {
            return true
        } else {
            return false
        }
    }

    const CustomPaper = (props: any) => (
        <Paper {...props} className={styles.autocompletePaper}
        />
    );

    const handleUseAsInput = (valueToReplace: string, option: any) => {
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    {
                        ...item, rewordingInput: valueToReplace, executePromptResponseReword: item.executePromptResponseReword.map((output: any) =>
                            output.id === option.id
                                ? {
                                    ...output,
                                    value: output.value.map((val: any, index: any) =>
                                        index === option.index ? { ...val, flagOpenMenu: false } : val
                                    )
                                }
                                : output
                        )
                    } : item
            ));
    }
    const handleRewriteOutputOption = async (optionToRewrite: executePromptResponseParaphraseObj) => {

        // Update the UI to indicate loading
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    {
                        ...item, executePromptResponseReword: item.executePromptResponseReword.map((item: any) =>
                            item.id === optionToRewrite.id
                                ? {
                                    ...item,
                                    value: item.value.map((val: any, index: any) =>
                                        index === optionToRewrite.index ? { ...val, flagLoading: true } : val
                                    )
                                }
                                : item
                        )
                    } : item
            ))
        const payload = {
            phrase: optionToRewrite.value[optionToRewrite.index].paraphrase,
            num_of_output: 1,
            prompt_id: promptList !== null ? promptList.find((prompt: any) => prompt.prompt_name == "Word Changer")?.prompt_id : 0,
            output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
            route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id ?? 2,
        };

        try {
            const response = await agent.executePrompt.post(payload);
            let uniqueIdAddedText = addUniqueId(response.data[0][response.prompt_output_key_name])
            if (response.status) {
                // Update the UI with the rewritten content
                const newVersion = {
                    id: optionToRewrite.value[(optionToRewrite.value.length - 1)].id + 1,
                    paraphrase: uniqueIdAddedText.replace(/\|\|\*[^*]*\*\|\|/g, ''),
                    convertedToHTML: <ConvertTextToHTML output_text={uniqueIdAddedText} id={optionToRewrite.id} indexID={optionToRewrite.index + 1} updatedOriginalText={uniqueIdAddedText} changeWord={changeWord} />,
                    originalText: uniqueIdAddedText,
                    updatedOriginalText: uniqueIdAddedText,
                    checked: false,
                    flagEditable: false,
                    flagLoading: false,
                    flagOpenMenu: false,
                    anchorEl: null,
                    showTooltip: false,
                    showCardTooltip: false
                }

                // conditions are to also change the executepromptResponse response in  replica and as well as the original arr
                setRewordVersioningarr((prevState: any) =>
                    prevState.map((item: any, index: any) => {
                        if (rewordVersioningArr.length - 1 == rewordCurrentIndex) {
                            // Update executePromptResponse for rewordVersionIndex and rewordVersionIndex - 1
                            if (index === rewordVersionIndex || index === (rewordVersionIndex - 1)) {
                                return {
                                    ...item,
                                    executePromptResponseReword: item.executePromptResponseReword.map((innerItem: any) =>
                                        innerItem.id === optionToRewrite.id ? {
                                            ...innerItem,
                                            value: [
                                                ...innerItem.value.map((val: any, innerIndex: any) =>
                                                    innerIndex === optionToRewrite.index ? { ...val, flagLoading: false } : val
                                                ),
                                                newVersion
                                            ],
                                            index: innerItem.value.length
                                        } : innerItem
                                    )
                                };
                            }
                        } else if (index === (rewordCurrentIndex - 2)) {
                            // Update for rewordCurrentIndex - 2
                            return {
                                ...item,
                                executePromptResponseReword: item.executePromptResponseReword.map((innerItem: any) =>
                                    innerItem.id === optionToRewrite.id ? {
                                        ...innerItem,
                                        value: [
                                            ...innerItem.value.map((val: any, innerIndex: any) =>
                                                innerIndex === optionToRewrite.index ? { ...val, flagLoading: false } : val
                                            ),
                                            newVersion
                                        ],
                                        index: innerItem.value.length
                                    } : innerItem
                                )
                            };
                        }
                        return item;
                    })
                );

            } else {
                setError({ ...error, flagShowError: true, message: "Something went wrong, Please try again later!" });
            }
        } catch (errorr) {
            setError({ ...error, flagShowError: true, message: "An error occurred. Please try again later!" });
        }
    };
    const deleteOutputOptionCard = (optionToDelete: executePromptResponseParaphraseObj) => {
        const updated = rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.map((item: any) =>
            item.id === optionToDelete.id
                ?
                optionToDelete.value.length == 1 ? null :
                    {
                        ...item,
                        index: item.value.filter((val: any, index: any) => index !== optionToDelete.index).length - 1,
                        value: item.value.filter((val: any, index: any) => index !== optionToDelete.index),
                    }
                : item
        );

        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    {
                        ...item, executePromptResponseReword: updated.filter((item: any) => item !== null)
                    } : item
            ))
    };

    const handleChangeCheckOutputOptionCard = (event: React.ChangeEvent<HTMLInputElement>, option: executePromptResponseParaphraseObj) => {
        const { checked } = event.target;
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    {
                        ...item, executePromptResponseParaphrase: rewordVersioningArr[rewordVersionIndex].executePromptResponseParaphrase.map((item: any) =>
                            item.id === option.id
                                ? {
                                    ...item,
                                    value: [
                                        ...item.value.map((val: any, index: any) =>
                                            index === option.index ? { ...val, checked: checked } : val
                                        ),
                                    ],
                                }
                                : item
                        )
                    } : item
            ))
    }
    function contentLexicalObjFun(contentValue: string, indent: number = 0) {
        return {
            "children": [
                {
                    "detail": 0,
                    "format": 0,
                    "mode": "normal",
                    "style": "",
                    "text": contentValue,
                    "type": "text",
                    "version": 1
                }
            ],
            "direction": "ltr",
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
        }
    }
    const handleCreateDoc = async (templateId: number) => {
        // setCurrentActiveDocFieldVal(null)
        // setAISuggestedTitles([
        //     { title: "" },
        //     { title: "" },
        //     { title: "" },
        //     { title: "" },
        //     { title: "" }
        // ])
        // setCurrentActiveStep(0)
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const defaultPersona = personaList?.find((persona) => persona.user_default_persona_data.find((personaData) => personaData.default_persona_type_name == "General"));
        const personaId = defaultPersona?.persona_id ?? null;
        const payload = {
            template_id: templateId,
            persona_id: personaId
        }
        const response = await CreateDoc(payload)
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        if (response.status) {
            if (response.data) {
                let newChild: any = [];
                if (response.data[0]) {
                    let checkedOptions = rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.map((option: any) => option.value[option.index])
                    checkedOptions.map((option: any) =>
                        newChild.push(contentLexicalObjFun(option.paraphrase))
                    )
                    let lexicalDataArray: any = {
                        root: {
                            "children": [],
                            "direction": "ltr",
                            "format": "",
                            "indent": 0,
                            "type": "root",
                            "version": 1
                        }

                    }
                    lexicalDataArray['root']['children']?.push(...newChild)
                    setDocumentEditorData(lexicalDataArray)

                    let finalObj: any = {}
                    finalObj['document_uuid'] = response.data[0].document_uuid
                    finalObj['document_step_id'] = response.data[0]?.document_step_data?.filter((stepDetail) => stepDetail.step_type_name == "Editor")[0]?.document_step_id
                    finalObj['document_lexical_editor_data'] = [lexicalDataArray?.root]
                    // finalObj['document_lexical_editor_data'] = [documentEditorData?.root]
                    let response1 = await UpdateDocumentEditorDetail(finalObj)
                    if (!response1.status) {
                        setError({ ...error, flagShowError: true, message: "Something went wrong. Please try again!" })
                    } else {
                        window.open(`${window.location.origin}/doc/${response.data[0].document_uuid}/3/${response.data[0].template_id}`, "_blank")
                    }


                }
            }
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }

    }

    const handleSaveDocument = async () => {
        let blankTemplateId = templateList !== null ? templateList[0].category_list.find((category) => category.category_name == "General")?.template_data_list.find((template) => template.template_type_name == "Blank")?.template_id : 0;
        handleCreateDoc(blankTemplateId ?? 0)
    }

    const handleIncOutputOptionVersion = (option: executePromptResponseParaphraseObj) => {
        const updatedResponse = rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.map((item: any) =>
            item.id === option.id ?
                {
                    ...item,
                    index: item.index + 1
                }
                : item
        );
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    { ...item, executePromptResponseReword: updatedResponse } : item
            ));
    }
    const handleDecOutputOptionVersion = (option: executePromptResponseParaphraseObj) => {
        const updatedResponse = rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.map((item: any) =>
            item.id === option.id ?
                {
                    ...item,
                    index: item.index - 1
                }
                : item
        );
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    { ...item, executePromptResponseReword: updatedResponse } : item
            ));
    }

    const changeWord = (wordToReplaceWith: string, id: number, indexID: number, uniqueIdAfterReplacement: any, textToChange: string, wordToReplace: string, rewordVersioningArr: any, rewordVersionIndex: number) => {

        const regex = /\s*\|\|\*\s*(\{"replace":[^}]*\})\s*\*\|\|\s*/g;

        // Split the textToChange into segments based on the regex pattern
        const segments = textToChange.split(regex);

        // Find the index of the segment with the matching unique_id and containing the wordToReplace
        const matchedIndex = segments.findIndex((segment, index) => {
            // Only consider odd-indexed segments
            if (index % 2 !== 0) {
                const matchSeg = JSON.parse(segment);
                const containsWordToReplace = segment.includes(`\"replacement\":\"${wordToReplace}\"`);
                return matchSeg.unique_id === uniqueIdAfterReplacement && containsWordToReplace;
            }
            return false;
        });

        if (matchedIndex !== -1) {
            // Replace the "replacement" key in the matched segment
            segments[matchedIndex] = segments[matchedIndex].replace(`\"replacement\":\"${wordToReplace}\"`, `\"replacement\":\"${wordToReplaceWith}\"`);

            // Update the last occurrence of "wordToReplace" in the previous segment
            if (matchedIndex > 0) {
                const previousSegment = segments[matchedIndex - 1];
                const lastIndexOfWordToReplace = previousSegment.lastIndexOf(wordToReplace);
                if (lastIndexOfWordToReplace !== -1) {
                    // Replace the last occurrence of "wordToReplace" with "wordToReplaceWith"
                    segments[matchedIndex - 1] = previousSegment.substring(0, lastIndexOfWordToReplace) +
                        wordToReplaceWith +
                        previousSegment.substring(lastIndexOfWordToReplace + wordToReplace.length);
                }
            }
        }

        // Join the modified segments back into a single string
        const modifiedText = segments.map((segment, index) => {
            if (index % 2 === 0) {
                return segment;
            } else {
                return `||*${segment}*||`;
            }
        }).join('');
        const updatedResponse = [...rewordVersioningArr][rewordVersionIndex].executePromptResponseReword.map((item: any) =>
            item.id === id ?
                {
                    ...item,
                    value: [
                        ...item.value.map((val: any, index: any) =>
                            index === indexID ? { ...val, paraphrase: modifiedText.replace(/\|\|\*[^*]*\*\|\|/g, ''), updatedOriginalText: modifiedText, convertedToHTML: <ConvertTextToHTML output_text={modifiedText} id={id} indexID={indexID} updatedOriginalText={modifiedText} changeWord={changeWord} /> } : val)
                    ]
                }
                : item
        );
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    { ...item, executePromptResponseReword: updatedResponse } : item
            ));


    }
    function addUniqueId(sampleText: string) {
        const regex = /\s*\|\|\*\s*(\{"replace":[^}]*\})\s*\*\|\|\s*/g;
        const segments = sampleText.split(regex);

        return segments.map((segment, index) => {
            const isPlaceholder = /\{.*\}/.test(segment);

            if (isPlaceholder) {
                const replaceInfo = JSON.parse(segment.replace(/(\w+)(?=:)/g, '"$1"').trim());
                replaceInfo.additional_suggestion.unshift(replaceInfo.replacement);
                replaceInfo.unique_id = index;
                return `||*${JSON.stringify(replaceInfo)}*||`;
            } else {
                return segment;
            }
        }).join('');
    }
    const handleOpenMenu = (event: React.ChangeEvent<HTMLInputElement>, option: executePromptResponseParaphraseObj) => {

        const { checked } = event.target;
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    {
                        ...item, executePromptResponseReword: rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.map((item: any) =>
                            item.id === option.id
                                ? {
                                    ...item,
                                    value: [
                                        ...item.value.map((val: any, index: any) =>
                                            index === option.index ? { ...val, flagOpenMenu: !val.flagOpenMenu, anchorEl: event.currentTarget } : val
                                        ),
                                    ],
                                }
                                : item
                        )
                    } : item
            ))
    }
    const handleCloseMenu = (event: React.ChangeEvent<HTMLInputElement>, option: executePromptResponseParaphraseObj) => {
        const { checked } = event.target;
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    {
                        ...item, executePromptResponseReword: rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.map((item: any) =>
                            item.id === option.id
                                ? {
                                    ...item,
                                    value: [
                                        ...item.value.map((val: any, index: any) =>
                                            index === option.index ? { ...val, flagOpenMenu: !val.flagOpenMenu, anchorEl: event.currentTarget } : val
                                        ),
                                    ],
                                }
                                : item
                        )
                    } : item
            ))
    }
    const [collapsed, setCollapsed] = useState(false);
    const miniLaptop = useMediaQuery('(min-width: 1200px) and (max-width: 1620px)')
    const tabScreen = useMediaQuery('(min-width: 599px) and (max-width: 1200px)')
    const getTabSectionHeight = () => {
        if (miniLaptop) {
            return 340;
        } else if (tabScreen) {
            return 370;
        } else {
            return 370;
        }
    }
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
        function handleResize() {
            setViewportHeight(window.innerHeight);
        }

        // Add event listener when component mounts
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const [customPane1Style, setCustomPane1Style] = useState<React.CSSProperties>({
        display: "block",
        maxHeight: "calc(100% - 100px)", minHeight: `calc(100% - ${getTabSectionHeight()}px)`
    })
    const [customPane2Style, setCustomPane2Style] = useState<React.CSSProperties>({
        background: "#f9f9f9",
        opacity: "1",
        // overflow: "hidden",
    })
    const handleCollapse = () => {
        if (collapsed) {
            setCustomPane1Style({ ...customPane1Style, height: "36%", transition: "height 0.5s ease" });
            setCustomPane2Style({
                ...customPane2Style, height: "84%", transition: "height 0.5s ease"
            })
        } else {
            setCustomPane1Style({ ...customPane1Style, height: "86%", maxHeight: "calc(100% - 100px)", transition: "height 0.5s ease, max-height 0.5s ease" })
            setCustomPane2Style({ ...customPane2Style, height: "36%", transition: "height 0.5s ease" })
        }
        setCollapsed(!collapsed)
    }
    useEffect(() => {
        // Select the parent element with class "Pane horizontal Pane2"
        const parentElement = document.querySelector('.Pane.horizontal.Pane2');
        const paneOne = document.querySelector('.Pane.horizontal.Pane1')
        // Check if the parent element exists
        if (parentElement) {
            // Get the immediate child div
            parentElement.classList.add(styles.responsivePane2)
            const childDiv = parentElement.querySelector('div');

            // Add a class to the child div
            if (childDiv) {
                childDiv.classList.add(globalStyles.fullHeight);
            }
        }
        if (paneOne) {
            // Get the immediate child div
            paneOne.classList.add(styles.responsivePane1)

            const childDiv = paneOne.querySelector('div');

            // Add a class to the child div
            if (childDiv) {
                childDiv.classList.add(styles.pane1Child);
            }
        }
    }, []);

    const handleResize = () => {
        setCustomPane1Style({ display: "block", maxHeight: "calc(100% - 100px)", minHeight: `calc(100% - ${getTabSectionHeight()}px)` })
        setCustomPane2Style({
            // background: "#f9f9f9",
            // opacity: "1",
            // overflow: "hidden",
            minHeight: "99px"
        });
    }
    return (
        <>
            {mobileScreen ?
                <WordChangerMobileComponent
                    workDomainList={workDomainList}
                    handleClearText={handleClearText}
                    handleTrySampleText={handleTrySampleText}
                    CustomPaper={CustomPaper}
                    mobileScreen={mobileScreen}
                    handleChangeParaphraseInput={handleChangeParaphraseInput}
                    handleParaphraseOutputChange={handleParaphraseOutputChange}
                    handleDecOutputOptionVersion={handleDecOutputOptionVersion}
                    handleRewriteOutputOption={handleRewriteOutputOption}
                    copyText={copyText}
                    setCopyText={setCopyText}
                    handleCopyToClipboard={handleCopyToClipboard}
                    handleChangeCheckOutputOptionCard={handleChangeCheckOutputOptionCard}
                    handleUseAsInput={handleUseAsInput}
                    deleteOutputOptionCard={deleteOutputOptionCard}
                    handleSaveDocument={handleSaveDocument}
                    handleIncOutputOptionVersion={handleIncOutputOptionVersion}
                    handleParaphrase={handleReword}
                    handleOpenMenu={handleOpenMenu}
                    handleCloseMenu={handleCloseMenu}
                    getParaphraseDisableFlag={getRewordDisableFlag}
                />
                :
                <>
                    <Grid item xs={12} className={`${styles.ppInputOutput}  ${styles.ppIpOpMainItemWrapper}`} >
                        <Grid container
                            // gap={'1rem'}
                            justifyContent={'space-between'}
                            // sx={{ boxShadow: " 0 0 3px 0 rgba(0, 0, 0, 0.25)" }}
                            className={`${styles.mainContainer} ${styles.ppIpOpMainContainerWrapper}`}>

                            {/* Input */}
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12} className={`${styles.ppInputContainer} ${styles.ppInMainItemWrapper}`}>
                                <Grid container className={styles.ppInMainContainerWrapper} sx={{ boxShadow: " 0 0 3px 0 rgba(0, 0, 0, 0.25)" }}>
                                    <Grid item xs={12} className={styles.ppInputContainerHeader}>
                                        <Grid container justifyContent={"space-between"}>
                                            <Grid item className={styles.inputTextStyle}>Input</Grid>

                                            <Grid item className={styles.characterCountText}>Words: {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length} Characters: {rewordVersioningArr[rewordVersionIndex].rewordingInput.length}</Grid>

                                            <Grid item onClick={() => { rewordVersioningArr[rewordVersionIndex].rewordingInput.length > 0 ? handleClearText() : handleTrySampleText() }} className={`${styles.clearInputText} ${globalStyles.clickable}`}>{rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 0 ? "Clear Input" : "Try Sample Text"}</Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} className={styles.ppInputAndOptions}>
                                        <Grid container
                                            // className={styles.bottomLeftContainerCompact}
                                            sx={{ minHeight: `calc(${viewportHeight}px - 200px)`, maxHeight: `calc(${viewportHeight}px - 200px)`, position: "relative" }}
                                        >
                                            <>
                                                {/* @ts-ignore */}
                                                <SplitPane
                                                    className={`${styles.desktopSplitPaneStyle}`}
                                                    split="horizontal"
                                                    pane2Style={customPane2Style}
                                                    pane1Style={customPane1Style}
                                                    onChange={handleResize}
                                                >
                                                    <div>
                                                        {/* paraphrase input */}
                                                        <Grid item xs={12} className={`${styles.ppInputFieldItem} ${globalStyles.fullHeight}`} sx={{ position: "relative" }} >
                                                            {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 900 &&
                                                                <span style={{ position: "absolute", bottom: "13px", color: "red", right: "17px", fontSize: "15px", zIndex: "1", background: "#fff" }}> {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length} / 1000 words</span>}
                                                            <GlobalTextField
                                                                value={rewordVersioningArr[rewordVersionIndex].rewordingInput}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeParaphraseInput(e)}
                                                                placeholder="For rewording your text, just type or paste the content here and press the 'Reword' button."
                                                                rows={6}
                                                                multiline
                                                                className={`${styles.ppGlobalMultilineTextField}`}
                                                            />

                                                        </Grid>
                                                        <div className={collapsed ? styles.upArrowStyles : styles.downArrowStyles} style={{ transform: collapsed ? "rotate(180deg)" : "" }}>
                                                            <img
                                                                alt="drawerArrow"
                                                                style={{ transform: "rotate(90deg)", width: "25px", height: "25px" }}
                                                                src={closeArrow}
                                                                onClick={() => {
                                                                    handleCollapse()
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <Grid item xs={12} className={globalStyles.fullHeight}>
                                                            <Grid container sx={{ height: "100%", flexDirection: "column", flexWrap: "nowrap" }}>
                                                                {/* lower tabs */}
                                                                <Grid item xs={12}
                                                                    className={`${styles.ppInputFieldOptionsWordChanger} ${styles.ppInputFieldOptionsHeightCompactWordChanger}`}
                                                                //  className={`${styles.ppInputFieldOptions} ${styles.ppInputFieldOptionsHeightCompactWordChanger}`}
                                                                >
                                                                    <Grid container direction="column"
                                                                        sx={{
                                                                            height: "100%",
                                                                        }}
                                                                    // sx={{ flexWrap: "nowrap", maxHeight: "100%" }}
                                                                    >
                                                                        <Grid item xs={12} sx={{ height: "100%" }}>
                                                                            <Grid container direction={"column"} sx={{ flexWrap: "nowrap", maxHeight: "100%" }}>
                                                                                <Grid item xs={12} className={styles.preferencesTextStyle} >Set your output preferences</Grid>
                                                                                <Grid item className={globalStyles.fullHeight} sx={{ position: "absolute", top: "30px", width: "100%" }}>
                                                                                    <Grid container className={`${globalStyles.fullHeight} ${globalStyles.containerFlexWrap}`} direction={"column"}>
                                                                                        <Grid item xs={12} className={styles.inputOptions}
                                                                                            sx={{ maxHeight: "calc(100% - 35px)", overflow: "scroll" }}
                                                                                        >
                                                                                            <Grid container
                                                                                                // gap={"0.2rem"} 
                                                                                                className={styles.inputOptionsChild} >
                                                                                                <>
                                                                                                    {/* Advance tab */}

                                                                                                    {/* tone of voice */}
                                                                                                    <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "10px" }}>
                                                                                                        <Grid container direction="column">
                                                                                                            <Grid item className={styles.ppOptionsHeader}>Persona</Grid>
                                                                                                            <Grid item>
                                                                                                                <Autocomplete
                                                                                                                    options={personaList || []}
                                                                                                                    getOptionLabel={(option: any) => option.persona_name ? `${option.persona_name}, ${option.persona_display_name}` : ""}
                                                                                                                    filterOptions={(options, { inputValue }) => {
                                                                                                                        const filteredOptions = options.filter((option) => {
                                                                                                                            const personaName = option.persona_name || '';
                                                                                                                            const subCategoryName = option.persona_sub_category__name || '';

                                                                                                                            return (
                                                                                                                                personaName.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                                                                                                subCategoryName.toLowerCase().includes(inputValue.toLowerCase())
                                                                                                                            );
                                                                                                                        });

                                                                                                                        return filteredOptions;
                                                                                                                    }}
                                                                                                                    className={styles.autocompleteParaphrasing}
                                                                                                                    getOptionDisabled={(option) =>
                                                                                                                        option.persona_premium_flag && ((userDetail.plan_detail && userDetail.plan_detail.credit_usage_data !== null && userDetail?.plan_detail?.credit_usage_data.stripe_product_name == "Free") || userDetail.flag_user_anonymous)
                                                                                                                    }
                                                                                                                    ChipProps={{ deleteIcon: <CloseIcon />, style: { maxWidth: '100%' }, }}
                                                                                                                    groupBy={(option) => option.persona_sub_category__name}
                                                                                                                    renderOption={(props, option) => (
                                                                                                                        <li {...props}>
                                                                                                                            <GlobalTooltip
                                                                                                                                option={option}
                                                                                                                            >
                                                                                                                                <li {...props}
                                                                                                                                    style={{
                                                                                                                                        paddingLeft: "0px",
                                                                                                                                        paddingRight: "0px",
                                                                                                                                        display: "flex",
                                                                                                                                        alignItems: "center",
                                                                                                                                        fontSize: "15px",
                                                                                                                                        fontWeight: "500",
                                                                                                                                        width: "100%"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <div
                                                                                                                                        style={{ pointerEvents: "none", display: "flex" }}>
                                                                                                                                        <img style={{ width: "37px", height: "37px", borderRadius: "50%", marginRight: "10px" }} src={option.persona_avatar_url ? option.persona_avatar_url : personaAvatar} alt="personaAvatar" />
                                                                                                                                    </div>

                                                                                                                                    <div style={{ width: "100%", pointerEvents: "none", padding: "0px 5px" }}>
                                                                                                                                        {option.persona_display_name ? `${option.persona_name}, ${option.persona_display_name}` : option.persona_name}
                                                                                                                                    </div>

                                                                                                                                    {option.persona_premium_flag &&
                                                                                                                                        <div style={{ width: "100%", display: "flex", justifyContent: "end", pointerEvents: "none", maxWidth: "25px" }}>
                                                                                                                                            <img src={personaPremiumIcon} style={{ width: "25px", height: "auto" }} alt="personaPremiumIcon" />
                                                                                                                                        </div>
                                                                                                                                    }
                                                                                                                                </li>
                                                                                                                            </GlobalTooltip>
                                                                                                                        </li>
                                                                                                                    )}
                                                                                                                    value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.personaObj}
                                                                                                                    onChange={(_, newValue) => {
                                                                                                                        setRewordVersioningarr((prevState: any) =>
                                                                                                                            prevState.map((item: any, index: any) =>
                                                                                                                                index == rewordVersionIndex ?
                                                                                                                                    { ...item, wordChangerPayload: { ...item.wordChangerPayload, personaObj: newValue == null ? "" : newValue, persona_id: newValue == null ? "" : newValue.persona_id } } : item
                                                                                                                            ))
                                                                                                                    }}
                                                                                                                    renderInput={(params) => (
                                                                                                                        <>
                                                                                                                            <TextField
                                                                                                                                placeholder='Select Persona'
                                                                                                                                {...params}
                                                                                                                                InputProps={{
                                                                                                                                    ...params.InputProps,
                                                                                                                                    startAdornment: (
                                                                                                                                        <>
                                                                                                                                            {rewordVersioningArr[rewordVersionIndex].wordChangerPayload.personaObj && (
                                                                                                                                                <Avatar
                                                                                                                                                    src={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.personaObj.persona_avatar_url ? rewordVersioningArr[rewordVersionIndex].wordChangerPayload.personaObj.persona_avatar_url : personaAvatar}
                                                                                                                                                    alt="Selected Persona"
                                                                                                                                                    style={{ width: "30px", height: "30px", marginLeft: "10px" }}
                                                                                                                                                />
                                                                                                                                            )}
                                                                                                                                        </>
                                                                                                                                    ),
                                                                                                                                }}

                                                                                                                            />
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                />
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    {/* writing style and work domain */}
                                                                                                    <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "10px" }}>
                                                                                                        <Grid container sx={{ gap: "5px", justifyContent: "space-between" }}>
                                                                                                            <Grid item xs={5.85}>
                                                                                                                <Grid container direction="column">
                                                                                                                    <Grid item className={styles.ppOptionsHeader}>Reading Ease</Grid>
                                                                                                                    <Grid item>
                                                                                                                        <Autocomplete
                                                                                                                            className={styles.autocompleteParaphrasing}
                                                                                                                            disablePortal
                                                                                                                            id="combo-box-demo"
                                                                                                                            options={personaWritingSkills !== null ? personaWritingSkills.map((writingSkill: any) => writingSkill.persona_reading_level_name) : []}
                                                                                                                            value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.reading_ease}
                                                                                                                            onChange={(event, values: any) => {
                                                                                                                                setRewordVersioningarr((prevState: any) =>
                                                                                                                                    prevState.map((item: any, index: any) =>
                                                                                                                                        index == rewordVersionIndex ?
                                                                                                                                            { ...item, wordChangerPayload: { ...item.wordChangerPayload, reading_ease: values } } : item
                                                                                                                                    ))
                                                                                                                            }}
                                                                                                                            PopperComponent={(props) => (
                                                                                                                                <Popper {...props} placement="top" className={styles.singleStructureDropdown} />
                                                                                                                            )}
                                                                                                                            renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                            <Grid item xs={5.85}>
                                                                                                                <Grid container direction="column">
                                                                                                                    <Grid item className={styles.ppOptionsHeader}>Work Domain</Grid>
                                                                                                                    <Grid item>
                                                                                                                        <Autocomplete
                                                                                                                            className={styles.autocompleteParaphrasing}
                                                                                                                            disablePortal
                                                                                                                            id="combo-box-demo"
                                                                                                                            options={workDomainList.map((workDomain: any) => workDomain.work_domain_name)}
                                                                                                                            value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.work_domain}
                                                                                                                            onChange={(event, values: any) => {
                                                                                                                                setRewordVersioningarr((prevState: any) =>
                                                                                                                                    prevState.map((item: any, index: any) =>
                                                                                                                                        index == rewordVersionIndex ?
                                                                                                                                            { ...item, wordChangerPayload: { ...item.wordChangerPayload, work_domain: values } } : item
                                                                                                                                    ))
                                                                                                                            }}
                                                                                                                            renderOption={(props, option, state) => (
                                                                                                                                <li {...props}>
                                                                                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                                                        <img src={workDomainList.find((workDomain: any) => workDomain.work_domain_name == option)?.work_domain_icon_url ?? ""} alt="icon" style={{ width: '17px', height: '17px', marginRight: '8px' }} />
                                                                                                                                        {option}
                                                                                                                                    </div>
                                                                                                                                </li>
                                                                                                                            )}
                                                                                                                            PopperComponent={(props) => (
                                                                                                                                <Popper {...props} placement="top" />
                                                                                                                            )}
                                                                                                                            PaperComponent={CustomPaper}
                                                                                                                            renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>

                                                                                                    {/* reading ease and format */}
                                                                                                    <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "10px" }}>
                                                                                                        <Grid container sx={{ gap: "5px", justifyContent: "space-between" }}>
                                                                                                            <Grid xs={5.85} className={styles.inputOptionsCont} sx={{ mb: "10px", display: "flex" }}>
                                                                                                                <Grid container justifyContent={"space-between"} direction="column">
                                                                                                                    <Grid item className={styles.ppOptionsHeader}>
                                                                                                                        Words/ Phrases to Include
                                                                                                                        {/* {tonOfVoice[0].name} */}
                                                                                                                    </Grid>
                                                                                                                    <Grid item>
                                                                                                                        <Autocomplete
                                                                                                                            multiple
                                                                                                                            // className={" multiple_chips__autocomplete formWiz-input-field"}
                                                                                                                            className={styles.autocompleteParaphrasing}
                                                                                                                            disablePortal
                                                                                                                            id="combo-box-demo"
                                                                                                                            freeSolo
                                                                                                                            options={[]}
                                                                                                                            value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.words_to_include}
                                                                                                                            onChange={(event, values: any) => {
                                                                                                                                setRewordVersioningarr((prevState: any) =>
                                                                                                                                    prevState.map((item: any, index: any) =>
                                                                                                                                        index == rewordVersionIndex ?
                                                                                                                                            { ...item, wordChangerPayload: { ...item.wordChangerPayload, words_to_include: values } } : item
                                                                                                                                    ))
                                                                                                                            }}
                                                                                                                            renderTags={(value, getTagProps) =>
                                                                                                                                value.map((option, index) => (
                                                                                                                                    <Chip
                                                                                                                                        label={option}
                                                                                                                                        deleteIcon={<CloseIcon />}
                                                                                                                                        {...getTagProps({ index })}
                                                                                                                                    />
                                                                                                                                ))
                                                                                                                            }
                                                                                                                            PaperComponent={CustomPaper}
                                                                                                                            renderInput={(params: any) => <TextField {...params} placeholder='Enter Words/Phrases' />} />
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                            <Grid xs={5.85} className={styles.inputOptionsCont} sx={{ mb: "10px", display: "flex" }}>
                                                                                                                <Grid container justifyContent={"space-between"} direction="column">
                                                                                                                    <Grid item className={styles.ppOptionsHeader}>
                                                                                                                        Words/ Phrases to Exclude
                                                                                                                        {/* {tonOfVoice[0].name} */}
                                                                                                                    </Grid>
                                                                                                                    <Grid item>
                                                                                                                        <Autocomplete
                                                                                                                            multiple
                                                                                                                            // className={" multiple_chips__autocomplete formWiz-input-field"}
                                                                                                                            className={styles.autocompleteParaphrasing}
                                                                                                                            disablePortal
                                                                                                                            id="combo-box-demo"
                                                                                                                            freeSolo
                                                                                                                            options={[]}
                                                                                                                            value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.words_to_exclude}
                                                                                                                            onChange={(event, values: any) => {
                                                                                                                                setRewordVersioningarr((prevState: any) =>
                                                                                                                                    prevState.map((item: any, index: any) =>
                                                                                                                                        index == rewordVersionIndex ?
                                                                                                                                            { ...item, wordChangerPayload: { ...item.wordChangerPayload, words_to_exclude: values } } : item
                                                                                                                                    ))
                                                                                                                            }}
                                                                                                                            renderTags={(value, getTagProps) =>
                                                                                                                                value.map((option, index) => (
                                                                                                                                    <Chip
                                                                                                                                        label={option}
                                                                                                                                        deleteIcon={<CloseIcon />}
                                                                                                                                        {...getTagProps({ index })}
                                                                                                                                    />
                                                                                                                                ))
                                                                                                                            }
                                                                                                                            PaperComponent={CustomPaper}
                                                                                                                            renderInput={(params: any) => <TextField {...params} placeholder='Enter Words/Phrases' />} />
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </>                                                                                  </Grid>

                                                                                        </Grid>
                                                                                    </Grid>

                                                                                </Grid>

                                                                            </Grid>
                                                                        </Grid>

                                                                    </Grid>
                                                                    <Divider />
                                                                </Grid>

                                                                {/* language, no of output */}
                                                                <Grid item xs={12} className={styles.optionStaticWordChanger}>
                                                                    <Grid container
                                                                        className={styles.paraphraseLangButtonParent}
                                                                        justifyContent={'end'} alignItems={"center"}>

                                                                        {/* paraphrase button */}
                                                                        <Grid item className={styles.paraphraseButtonContainer} sx={{ width: "170px" }}>
                                                                            <GlobalButton
                                                                                buttonText="Reword"
                                                                                flagFullWidth
                                                                                height='40px !important'
                                                                                className="primaryButtonStyle"
                                                                                disabled={getRewordDisableFlag()}
                                                                                // disabled={rewordingInput.length < 5}
                                                                                onClick={() => { handleReword() }}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </SplitPane>
                                            </>


                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>


                            {/* Output */}
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12} className={`${styles.ppOutputContainer}`}
                                sx={{ maxHeight: "calc(100vh - 150px)" }}
                            >
                                <Grid container sx={{ height: "100%", boxShadow: " 0 0 3px 0 rgba(0, 0, 0, 0.25)" }} direction={"row"} justifyContent={"start"} alignItems={"start"} >
                                    <Grid item xs={12} className={styles.ppOutputContainerHeader}>
                                        <Grid container justifyContent={"space-between"}>
                                            <Grid item>
                                                <Typography>Output</Typography>
                                            </Grid>
                                            <Grid item>  <GlobalPopover
                                                trigger={
                                                    <div style={{ display: "flex" }}>
                                                        <img src={colorGuideIcon} alt="colorGuide" />
                                                        <div style={{ display: "flex", alignItems: "center", marginLeft: "5px !important" }} className={`${globalStyles.tertiaryFontSize} ${globalStyles.primaryFontWeight} ${globalStyles.primaryColor}`}> Color Guide</div>
                                                    </div>
                                                }
                                                content={<ColorGuide />}
                                            /></Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ height: "calc(100% - 44px)" }} className={styles.mbOutputOutsideItem}>
                                        <Grid container sx={{ height: "100%" }}>

                                            <Grid item xs={12} sx={{ height: "100%" }} className={styles.ppOutputTextFields}>
                                                {rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.length ?
                                                    <Grid container direction={"column"} className={`${globalStyles.fullHeight} ${styles.outputMainItem}`} >
                                                        <Grid item xs={12} className={styles.ppOutputField} sx={{ overflowY: "hidden !important", margin: "0px !important" }}>
                                                            {
                                                                rewordVersioningArr[rewordVersionIndex].executePromptResponseReword?.map((option: executePromptResponseParaphraseObj, index: number) => {
                                                                    return (
                                                                        <>
                                                                            {option.value.length > 0 &&
                                                                                <div className={styles.outputCardContainerWordChanger} >
                                                                                    {/* textarea */}
                                                                                    {
                                                                                        <div style={{ maxHeight: "calc(100vh - 75px)", overflowY: "auto", paddingBottom: "10px" }}>
                                                                                            {
                                                                                                option.value[option.index].flagLoading ?
                                                                                                    <>
                                                                                                        <div style={{ marginBottom: "16px" }}>
                                                                                                            <Skeleton />
                                                                                                            <Skeleton />
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div
                                                                                                            onInput={(e: React.FormEvent<HTMLDivElement>) =>
                                                                                                                handleParaphraseOutputChange(e, option)
                                                                                                            }
                                                                                                            // onChange={(e: any) => handleParaphraseOutputChange(e, option)}
                                                                                                            className="ua-chrome ProseMirror pm-table-resizing-plugin"
                                                                                                            aria-label="Main content area, start typing to enter text."
                                                                                                            aria-multiline="true" role="textbox" id="ak-editor-textarea"
                                                                                                            contentEditable="true"
                                                                                                            data-gramm="false"
                                                                                                            translate="no">
                                                                                                            {/* @ts-ignore */}
                                                                                                            {option.value[option.index].convertedToHTML}
                                                                                                            {/* {convertTextToHTML(option.value[option.index].paraphrase)} */}
                                                                                                        </div>

                                                                                                    </>
                                                                                            }
                                                                                        </div>
                                                                                    }

                                                                                    <hr style={{ width: "100%", margin: "0px 0px 10px", opacity: "0.2" }} />

                                                                                    {/* char no */}
                                                                                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                                                        {/* words and charac */}
                                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                                            {/* pagination */}
                                                                                            {option.value.length > 1 &&
                                                                                                <>
                                                                                                    <div>
                                                                                                        <IconsColor
                                                                                                            iconName="decIndex"
                                                                                                            className={option.index !== 0 ? globalStyles.clickable : ""}
                                                                                                            width="7"
                                                                                                            height="11px"
                                                                                                            fillColor={rewordCurrentIndex !== 1 ? "#000" : "#7A7A7A"}
                                                                                                            onClick={() => {
                                                                                                                option.index !== 0 &&
                                                                                                                    handleDecOutputOptionVersion(option)
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div style={{ color: "#7A7A7A", fontSize: "14px", fontWeight: "500", margin: "0px 3px" }}>{`${option.index + 1}/${option.value.length}`}</div>
                                                                                                    <div style={{ paddingRight: "15px" }}>
                                                                                                        <IconsColor
                                                                                                            iconName="IncIndex"
                                                                                                            className={option.index !== option.value.length - 1 ? globalStyles.clickable : ""}
                                                                                                            width="7"
                                                                                                            height="11px"
                                                                                                            fillColor={option.index !== option.value.length - 1 ? "#000" : "#7A7A7A"}
                                                                                                            onClick={() => { option.index !== option.value.length - 1 && handleIncOutputOptionVersion(option) }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                            <div className={styles.outputLength}>{option.value[option.index].paraphrase.trim().split(/\s+/).filter(st => st != '').length} Words</div>
                                                                                            <div className={styles.outputLength}> {option.value[option.index].paraphrase.length} Characters</div>
                                                                                        </div>
                                                                                        {/* buttons */}
                                                                                        {!option.value[option.index].checked &&
                                                                                            <div style={{ display: 'flex', alignItems: "center", gap: "14px" }}>
                                                                                                <div
                                                                                                    onClick={() => { handleSaveDocument() }}
                                                                                                    className={`${styles.useasInputBtnStyle} ${globalStyles.clickable}`}>Save</div>
                                                                                                {/* <div
                                                                                                    onClick={() => { handleUseAsInput(option.value[option.index].paraphrase, option) }}
                                                                                                    className={`${styles.useasInputBtnStyle} ${globalStyles.clickable}`}>Use as Input</div> */}
                                                                                                <Tooltip title={"Rewrite"}>
                                                                                                    <div
                                                                                                        onClick={() => { handleRewriteOutputOption(option) }}
                                                                                                        className={`${styles.useasInputBtnStyle} ${globalStyles.clickable}`}>
                                                                                                        <IconsColor iconName="rewrite" width="16px" height="16px" fillColor={"#6A097D"} />
                                                                                                    </div>
                                                                                                </Tooltip>
                                                                                                <CopyToClipboard
                                                                                                    text={option.value[option.index].paraphrase}>
                                                                                                    <Grid item>
                                                                                                        <Tooltip title={copyText} onMouseLeave={() => setCopyText("Copy!")} onClick={() => {
                                                                                                            setCopyText("Copied!");

                                                                                                        }}>
                                                                                                            <div
                                                                                                                onMouseLeave={() => setCopyText("Copy!")}
                                                                                                                onClick={() => {
                                                                                                                    handleCopyToClipboard(option.value[option.index].paraphrase);
                                                                                                                    setCopyText("Copied!")
                                                                                                                }}
                                                                                                                style={{ display: "flex", alignItems: "center" }}
                                                                                                                className={`${globalStyles.clickable}`}
                                                                                                            >
                                                                                                                <IconsColor iconName={"Copy"} fillColor={"#6A097D"} width={"16"} height={"16"} />
                                                                                                            </div>
                                                                                                        </Tooltip>

                                                                                                    </Grid>

                                                                                                </CopyToClipboard>
                                                                                                {/* <div
                                                                                                    onClick={() => { deleteOutputOptionCard(option) }}
                                                                                                    className={`${globalStyles.clickable}`}
                                                                                                    style={{ display: "flex", alignItems: "center" }}
                                                                                                >
                                                                                                    <IconsColor iconName={"Delete"} fillColor={"#6A097D"} width={"16"} height={"16"} style={{ display: "flex" }} />
                                                                                                </div> */}
                                                                                                <div key={option.id} style={{ display: "flex" }}>
                                                                                                    <IconButton
                                                                                                        aria-label="more"
                                                                                                        id={option.id.toString()}
                                                                                                        key={option.id}
                                                                                                        aria-haspopup="true"
                                                                                                        // onClick={handleClick}
                                                                                                        sx={{ padding: "0px" }}
                                                                                                        className={option.value[option.index].flagLoading ? `${globalStyles.disabledClass}` : `${globalStyles.clickable}`}
                                                                                                        onClick={(e: any) => {
                                                                                                            if (option.value[option.index].flagLoading) {
                                                                                                            } else {
                                                                                                                handleOpenMenu(e, option)
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <MoreVertIcon sx={{ color: "#6A097D", fontSize: "large" }} />
                                                                                                    </IconButton>
                                                                                                    <Menu
                                                                                                        id={option.id.toString()}
                                                                                                        MenuListProps={{
                                                                                                            'aria-labelledby': 'long-button',
                                                                                                        }}
                                                                                                        anchorEl={option.value[option.index].anchorEl}
                                                                                                        key={option.value[option.index].id}
                                                                                                        open={option.value[option.index].flagOpenMenu}
                                                                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeCheckOutputOptionCard(event, option) }}

                                                                                                        onClose={(e: any) => handleCloseMenu(e, option)}
                                                                                                        PaperProps={{
                                                                                                            style: {
                                                                                                                // maxHeight: ITEM_HEIGHT * 4.5,
                                                                                                                // width: '130px',
                                                                                                                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                                                                                padding: "0 10px"
                                                                                                            },
                                                                                                        }}
                                                                                                    >
                                                                                                        <MenuItem onClick={() => { handleUseAsInput(option.value[option.index].paraphrase, option) }}

                                                                                                            style={{
                                                                                                                minHeight: '20px',
                                                                                                                padding: "5px 10px",
                                                                                                                textAlign: "center",
                                                                                                                justifyContent: "start",
                                                                                                                alignItems: "center",
                                                                                                                borderBottom: "1px solid #E9E9E9"
                                                                                                            }}
                                                                                                        >
                                                                                                            <Typography component={'span'}>Use as Input</Typography>
                                                                                                        </MenuItem>
                                                                                                        <MenuItem
                                                                                                            onClick={() => { deleteOutputOptionCard(option) }}
                                                                                                            style={{
                                                                                                                minHeight: '20px',
                                                                                                                padding: "5px 10px",
                                                                                                                textAlign: "center",
                                                                                                                justifyContent: "start",
                                                                                                                alignItems: "center"
                                                                                                            }}
                                                                                                        >
                                                                                                            <Typography component={'span'}>Delete</Typography>
                                                                                                        </MenuItem>
                                                                                                    </Menu>
                                                                                                </div>
                                                                                            </div>}
                                                                                    </div>

                                                                                </div>}
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                        {/* <Box className={styles.ppOutputButtons}>
                                                            <Grid container justifyContent={'space-between'}>
                                                                <Grid item className={styles.ppOutputButtonsContainer}>
                                                                    <GlobalButton
                                                                        disabled={
                                                                            rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                        }
                                                                        onClick={handleSaveDocument} buttonText={"Save"} className="borderedButtonStyle" />
                                                                    <GlobalButton
                                                                        disabled={
                                                                            rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                        }

                                                                        buttonText={"Use as Input"} className='borderedButtonStyle' />
                                                                    <GlobalButton
                                                                        disabled={
                                                                            rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                        }
                                                                        buttonText={"Rewrite"} className='borderedButtonStyle' />
                                                                </Grid>

                                                                <Grid item>
                                                                    <Grid container className={styles.inputOptionsContainer} >
                                                                        <Grid item>
                                                                            <CopyToClipboard
                                                                                text=""
                                                                            // text={multipleCopyText()}
                                                                            >
                                                                                <Grid item>
                                                                                    <Tooltip title={copyText} onMouseLeave={() => setCopyText("Copy!")} onClick={() => {
                                                                                        setCopyText("Copied!");

                                                                                    }}>
                                                                                        <GlobalButton
                                                                                            onClick={() => {
                                                                                                // handleCopyToClipboard(multipleCopyText());
                                                                                                setCopyText("Copied!")
                                                                                            }}
                                                                                            disabled={
                                                                                                rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                            }
                                                                                            onMouseLeave={() => setCopyText("Copy!")}
                                                                                            buttonText={<IconsColor iconName="Copy" fillColor="#6A097D" width={"16"} height={"16"} />}
                                                                                            className='borderedButtonStyle'></GlobalButton>
                                                                                    </Tooltip>

                                                                                </Grid>

                                                                            </CopyToClipboard>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <GlobalButton
                                                                                disabled={
                                                                                    rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                }
                                                                                // onClick={handleMultipleDelete} 
                                                                                buttonText={<IconsColor iconName="Delete" fillColor="#6A097D" width={"18"} height={"18"} />} className='borderedButtonStyle' />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Box> */}
                                                    </Grid>
                                                    :
                                                    <>
                                                        <Box sx={{ height: "100%", padding: "10px", alignItems: "center", display: "flex" }}>
                                                            <Box className={globalStyles.globalTextStyleGrey} alignItems={"center"} sx={{ maxWidth: "355px" }}>
                                                                AI reworded text will show up here.
                                                                <img src={shine} style={{ width: "20px", height: "20px" }} alt="shine" />
                                                            </Box>

                                                        </Box>
                                                    </>
                                                }

                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }

        </>
    )
}

export default WordChanger

