import * as React from "react";
import {
    Grid,
    Divider
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext, UserContextPayload } from "../../../context/userContext";
import GlobalForm from "../../utility/formWiz/globalForm";
import globalStyles from "../../../assets/styles/global.module.scss";
import GlobalButton from "../../utility/globalButton/button";
import styles from "../../../assets/styles/componentStyles/createPersona.module.scss";
import { PersonaContext, PersonaContextPayload } from "../../../context/personaContext";
import agent from "../../../services/api";
import GlobalPageHeaderText from "../../utility/globalPageHeaderText";
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, isDev } from "../../../utils/constants";

export default function CreatePersona() {
    const navigate = useNavigate()

    const { setUserDetail, userDetail, setFlagLoading, setError, error, flagLoading, setSuccess, success, setFlagShowCreditsExhausted } = useContext(
        UserContext
    ) as UserContextPayload;
    const { CreatePersona } = useContext(PersonaContext) as PersonaContextPayload
    interface WritingExample {
        example: string;
    }
    interface TagLine {
        tag_line: any;
    }
    const IndividualTypeForm = [
        {
            "page_field_id": 1666,
            "label": "Persona Type",
            "placeholder": null,
            "required": false,
            "name": "1666_Persona_Type",
            "type": "text",
            "value": 1,
            "hidden": true,
            "position": null,
            "font_color": null,
            "col": 6,
        },
        {
            "page_field_id": 2815,
            "name": "2815_TEST ",
            "type": "file",
            "value": [],
            "col": 12,
            "label": "Avatar",
            "placeholder": "Upload your persona picture",
            "multiple_file": "1",
            "file_size": "2",
            "is_avatar": true,
            "required": false,
            "hidden": false,
            "flag_ai_suggestion": false
        },
        {
            "page_field_id": 1668,
            "label": "About Persona",
            "placeholder": "What is this persona about?",
            "required": true,
            "name": "1668_About_Persona",
            "type": "longtext",
            max: "3000",
            min: "5",
            "value": "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12
        },
        {
            "page_field_id": 1669,
            "label": "Writing Example 1",
            "placeholder": "Paste or type your writing example here and keep it around 2000-3000 words",
            "required": true,
            "name": "1669_Writing_Example_1 ",
            "type": "longtext",
            max: "3000",
            min: "5",
            "value": "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12
        },
        {
            "page_field_id": 1670,
            "label": "Writing Example 2",
            "placeholder": "Paste or type your writing example here and keep it around 2000-3000 words",
            "required": false,
            "name": "1670_Writing_Example_2",
            max: "3000",
            min: "5",
            "type": "longtext",
            "value": "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12
        },
        {
            "page_field_id": 1671,
            "label": "Writing Example 3",
            "placeholder": "Paste or type your writing example here and keep it around 2000-3000 words",
            "required": false,
            "name": "1671_Writing_Example_3 ",
            "type": "longtext",
            max: "3000",
            min: "5",
            "value": "",
            "hidden": false,
            "position": "center",
            "font_color": "#FFFFFF",
            "col": 12,
        }
    ]
    const BrandTypeForm = [
        {
            "page_field_id": 1666,
            "label": "Persona Type",
            "placeholder": null,
            "required": false,
            "name": "1666_Persona_Type",
            "type": "text",
            "value": 2,
            "hidden": true,
            "position": null,
            "font_color": null,
            "col": 6,
        },
        {
            "page_field_id": 2815,
            "name": "2815_TEST ",
            "type": "file",
            "value": [],
            "col": 12,
            "label": "Avatar",
            "placeholder": "Upload your persona picture",
            "multiple_file": "1",
            "file_size": "2",
            "is_avatar": true,
            "required": false,
            "hidden": false,
            "flag_ai_suggestion": false
        },
        {
            "page_field_id": 1668,
            "label": "About Persona",
            "placeholder": "What is this persona about?",
            "required": true,
            "name": "1668_About_Persona",
            "type": "longtext",
            "value": "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12
        },
        {
            "page_field_id": 1669,
            "label": "Tagline 1",
            "placeholder": "Enter your brand tagline",
            "required": true,
            "name": "1668_Enter_Email_1 ",
            "type": "longtext",
            "value": "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12
        },
        {
            "page_field_id": 1673,
            "label": "Tagline 2",
            "placeholder": "Enter your brand tagline",
            "required": false,
            "name": "1668_Enter_Emai_2 ",
            "type": "longtext",
            "value": "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12
        },
        {
            "page_field_id": 1674,
            "label": "Tagline 3",
            "placeholder": "Enter your brand tagline",
            "required": false,
            "name": "1668_Enter_Email_3 ",
            "type": "longtext",
            "value": "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12
        },
        {
            "page_field_id": 1670,
            "label": "Writing Example 1",
            "placeholder": "Paste or type your writing example here and keep it around 2000-3000 words",
            "required": true,
            "name": "1669_Writing_Example_1 ",
            "type": "longtext",
            "value": "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12
        },
        {
            "page_field_id": 1671,
            "label": "Writing Example 2",
            "placeholder": "Paste or type your writing example here and keep it around 2000-3000 words",
            "required": false,
            "name": "1670_Writing_Example_2",
            "type": "longtext",
            "value": "",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12
        },
        {
            "page_field_id": 1672,
            "label": "Writing Example 3",
            "placeholder": "Paste or type your writing example here and keep it around 2000-3000 words",
            "required": false,
            "name": "1671_Writing_Example_3 ",
            "type": "longtext",
            "value": "",
            "hidden": false,
            "position": "center",
            "font_color": "#FFFFFF",
            "col": 12,
        }
    ]
    const [createPersonaFormData, setCreatePersonaFormData] = useState<any>({
        data: {
            global_form_field: IndividualTypeForm,
            widget_flag_auto_save: false,
            widget_flag_hide_button: false,
            widget_form_full_width: true,
            doc_page_theme: [
                {
                    "page_id": "682",
                    "page_theme_font_type": "Open Sans",
                    "page_theme_font_style": "normal",
                    "page_theme_font_align": "left",
                    "page_theme_font_weight": "bold",
                    "page_theme_font_size": "15",
                    "page_theme_background_color": "#FFFFFF",
                    "page_theme_heading_color": "#EFE4F1",
                    "page_theme_text_decoration": "unset",
                    "page_hide_button": false,
                    "page_auto_save": false,
                    "page_font_spacing": null,
                    "page_theme_row_spacing": 2,
                    "page_theme_column_spacing": 2
                }
            ]
        }
    })
    const [submissionID, setsubmissionID] = useState(null);
    const [subType, setSubType] = useState<any>("Individual");
    const [filePath, setfilePath] = useState<any>("");
    const [flagFirstRenderDone, setFlagFirstRenderDone] = useState(false)
    const [renderValue, setRenderValue] = useState({
        status: null,
        message: "",
        submission_id: null
    })
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState({ filename: "", percent: 0 });

    const handleCloseSnackBar = () => {
        setRenderValue({
            status: null,
            message: "",
            submission_id: null
        })
    }

    const setFieldValue = (data: any) => {
        var tempdata = { ...createPersonaFormData }
        tempdata.data.global_form_field.map((newvalue: any) => {

            if (newvalue.options) {
                newvalue.options.map((option_value: any) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
        })
        setCreatePersonaFormData(tempdata)
        setFlagFirstRenderDone(true)
    }

    const formSubmitFunction = (data: any) => {
        var finalData = data;
    }

    const getCreatePersonaButtonDisableCondition = () => {
        let fields = createPersonaFormData["data"]["global_form_field"]
        for (const field of fields) {
            if (field.required && field.value.trim().length < 3) {
                return true
            }
        }
        return false
    }

    const handleSubmitPersona = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let fields = createPersonaFormData["data"]["global_form_field"]

        const payload = {
            about_persona: '',
            persona_type_id: subType == "Brand" ? 2 : 1,
            persona_avatar_url: '',
            persona_writing_example: [] as WritingExample[],
            persona_tag_line: [] as TagLine[],
        }
        fields.forEach((field: any) => {
            const label = field.label;
            const value = field.value;

            if (label === 'About Persona') {
                payload.about_persona = value;
            }
            else if (label === 'Avatar') {
                payload.persona_avatar_url = filePath;
            }
            else if (label.startsWith('Writing Example')) {
                const exampleValue = value;
                let tagLine = null;

                // Look for a corresponding tag line field
                fields.forEach((tagLineField: any) => {
                    if (tagLineField.label === `Tag Line ${label.slice(-1)}`) {
                        tagLine = tagLineField.value;
                    }
                });

                if (exampleValue) {
                    payload.persona_writing_example.push({ example: exampleValue });
                }

            }
            else if (label.startsWith('Tagline')) {
                const exampleValue = value;
                let tagLine = null;

                fields.forEach((tagLineField: any) => {
                    if (tagLineField.label === `Tag Line ${label.slice(-1)}`) {
                        tagLine = tagLineField.value;
                    }
                });

                payload.persona_tag_line.push({ tag_line: exampleValue });

            }
        });

        const response: any = await CreatePersona(payload)

        if (response.status) {
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
            setSuccess({ ...success, flagShowSuccess: true, message: "Persona Created Successfully!" })
            navigate(`/dashboard/viewPersona?persona_id=${response.data[0].persona_id}`)

        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }


    useEffect(() => {
        if (subType == "Individual") {
            let temp = { ...createPersonaFormData }
            temp.data.global_form_field = IndividualTypeForm
            setCreatePersonaFormData(temp)
        } else {
            let temp = { ...createPersonaFormData }
            temp.data.global_form_field = BrandTypeForm
            setCreatePersonaFormData(temp)
        }
    }, [subType])

    const uploadFileHandler = async (event: any, id: any, removeFile: any) => {

        if (removeFile == "RemoveFile") {
            var tempdata = { ...createPersonaFormData }
            tempdata.data.global_form_field.map((newvalue: any) => {
                if (newvalue.page_field_id === id) {
                    newvalue.avatar_Image = ""
                }
            })
            setCreatePersonaFormData(tempdata)
        }
        else {
            const file = event[0];

            if (!file) return;
            file.isUploading = true;

            const formData: any = new FormData()
            formData.append(
                "file",
                file,
                "persona_flag",
                true
            )
            const data: any = {}
            data[`persona_flag`] = true;
            data[`file`] = file;

            const response = await agent.fileUpload.post(data)
            if (response.status) {
                await setfilePath(response.data.file_path)
                var tempdata = { ...createPersonaFormData }
                tempdata.data.global_form_field.map((newvalue: any) => {

                    if (newvalue.page_field_id === id) {
                        newvalue.avatar_Image = response.data.file_path
                    }
                })
                setCreatePersonaFormData(tempdata)
            }
        }
    }

    const removeFile = (google_id: any) => {
        var google_s_id: any = {};
        google_s_id['file_id'] = google_id
        const fileSet: any = files.filter((file: any) => file.google_storage_id !== google_id)
        var finalFiles: any = [...fileSet];
        setFiles(finalFiles)
    }


    return (
        <Grid container className={globalStyles.dashboardChildWrapper} direction="column">

            <Grid item xs={12} sx={{ marginBottom: "15px" }}>
                <GlobalPageHeaderText
                    mainHeadText={"Persona"}
                    mainSubText={"Customize your AI experience to your persona and writing style"}
                />
            </Grid>

            <Grid item className={globalStyles.updatePersonaFullContainerForm}>
                <Grid container className={globalStyles.updatePersonaFullContainer} direction={"column"}>
                    <Grid item>
                        <Grid container justifyContent="space-between" sx={{ alignItems: "center", marginBottom: "10px" }}>
                            <Grid item>
                                <Grid container sx={{ alignItems: "center" }}>
                                    <Grid item className={globalStyles.dashboardChildTitleTextStylePersona}>Create your persona</Grid>
                                    <Grid item sx={{ fontSize: "15px", fontWeight: "500", color: "#7A7A7A" }}>
                                        Add your details & writing examples, and we'll craft your personalized persona
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item style={{ marginTop: "7px" }} className={styles.personaNameCreatePage}>
                                <GlobalButton buttonText="Create Persona" className="primaryButtonStyleCreatePersona"
                                    disabled={getCreatePersonaButtonDisableCondition()}
                                    onClick={handleSubmitPersona}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.personaDivider}>
                        <Divider />
                    </Grid>
                    <Grid item
                        className={styles.createPersonaFormMobileHeight}
                    >

                        <Grid container direction="column" alignItems={"start"} justifyContent={"center"}>

                            <Grid item>
                                <GlobalForm
                                    fields={createPersonaFormData.data.global_form_field}
                                    submitFunction={formSubmitFunction}
                                    checkFormSubmit={renderValue}
                                    handleCloseSnackBar={handleCloseSnackBar}
                                    pageThemeData={createPersonaFormData.data.doc_page_theme[0]}
                                    formData={createPersonaFormData.data}
                                    submissionID={submissionID}
                                    setFieldValue={setFieldValue}
                                    defaultLoader={false}
                                    formEditable={true}
                                    uploadFileHandler={(e: any, id: any, removeFile: any) => { uploadFileHandler(e, id, removeFile) }}
                                    filesInParent={files}
                                    progressInParent={progress}
                                    removeFile={(e: any) => { removeFile(e) }}
                                />
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item style={{ marginTop: "7px", padding: "0px 20px" }} className={styles.personaNameCreatePageMobile}>
                        <GlobalButton buttonText="Create Persona" className="primaryButtonStyleCreatePersona"
                            disabled={getCreatePersonaButtonDisableCondition()}
                            onClick={handleSubmitPersona}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
