import * as React from "react";
import {
    Grid,
    Typography,
    Divider,
    useMediaQuery
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext, UserContextPayload } from "../../../context/userContext";
import GlobalForm from "../../utility/formWiz/globalForm";
import GlobalButton from "../../utility/globalButton/button";
import styles from "../../../assets/styles/componentStyles/updatePersona.module.scss";
import { PersonaContext, PersonaContextPayload } from "../../../context/personaContext";
import agent from "../../../services/api";
import GlobalPageHeaderText from "../../utility/globalPageHeaderText";
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, isDev } from "../../../utils/constants";

export default function UpdatePersona() {

    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const { currentActivePersonaDetails, GetPersonaDetails, UpdatePersona, setCurrentActivePersonaDetails, personaWritingSkills, personaNarrativeStyle, personaCommunicationType, personaSentenceLength } = useContext(PersonaContext) as PersonaContextPayload;
    const { setUserDetail, userDetail, setFlagLoading, setError, error, flagLoading, setSuccess, success, setFlagShowCreditsExhausted } = useContext(
        UserContext
    ) as UserContextPayload;
    const mobileScreen = useMediaQuery('(min-width: 320px) and (max-width: 600px)')

    useEffect(() => {
        (async () => {
            if (searchParams) {
                let persona_id = Number(searchParams.get("persona_id"));
                if (persona_id) {
                    setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
                    let response = await GetPersonaDetails(persona_id)
                    if (!response.status) {
                        setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                        setCurrentActivePersonaDetails(null)
                    } else {
                        if (response.data) {
                            setCurrentActivePersonaDetails(response.data[0])
                        }
                    }
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                } else {
                    navigate("/dashboard")
                }
            } else {
                navigate("/dashboard")
            }
        })()

    }, [])

    const IndividualTypeForm = [
        {
            "page_field_id": 5000,
            "name": "2815_Avatar",
            "type": "file",
            "value": [],
            "avatar_Image": currentActivePersonaDetails == null ? "" : currentActivePersonaDetails.persona_avatar_url,
            "col": 12,
            "label": "Avatar",
            "placeholder": "Upload your persona picture",
            "multiple_file": "1",
            "file_size": "2",
            "is_avatar": true,
            "required": false,
            "hidden": false,
            "flag_ai_suggestion": false,
            "payload_name": "persona_avatar_url"
        },
        {
            "page_field_id": 5001,
            "label": "Persona Name",
            "placeholder": null,
            "required": true,
            "name": "5001_Persona_Name",
            "type": "text",
            "value": currentActivePersonaDetails == null ? "" : currentActivePersonaDetails.persona_name,
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "payload_name": "persona_name"
        },
        {
            "page_field_id": 5002,
            "label": "About Persona",
            "placeholder": "What is this persona about?",
            "required": true,
            "name": "1668_About_Persona",
            "type": "longtext",
            "value": currentActivePersonaDetails == null ? "" : currentActivePersonaDetails.about_persona,
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "payload_name": "about_persona"
        },

        {
            "page_field_id": 5004,
            "label": "Age",
            "placeholder": null,
            "required": false,
            "name": "5004_Age ",
            "type": "number",
            "value": currentActivePersonaDetails == null ? "" : currentActivePersonaDetails.persona_age,
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": mobileScreen ? 12 : 6.5,
            "payload_name": "persona_age"
        },
        {
            "page_field_id": 5005,
            "label": "Gender",
            "placeholder": null,
            "required": false,
            "name": "5005_Gender ",
            "type": "text",
            "value": currentActivePersonaDetails == null ? "" : currentActivePersonaDetails.persona_gender,
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": mobileScreen ? 12 : 6.5,
            "payload_name": "persona_gender"
        },
        {
            "page_field_id": 5006,
            "label": "Occupation",
            "placeholder": null,
            "required": false,
            "name": "5006_Gender ",
            "type": "text",
            "value": currentActivePersonaDetails == null ? "" : currentActivePersonaDetails.persona_occupation,
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "payload_name": "persona_occupation"
        },
        {
            "page_field_id": 5007,
            "label": "Location",
            "placeholder": null,
            "required": false,
            "name": "5007_Location",
            "type": "text",
            "value": currentActivePersonaDetails == null ? "" : currentActivePersonaDetails.persona_location,
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "payload_name": "persona_location"
        },
        {
            "page_field_id": 5008,
            "label": "Writer's Intention",
            "placeholder": null,
            "required": false,
            "name": "5008_Writer's_Intention",
            "type": "text",
            "value": currentActivePersonaDetails == null ? "" : currentActivePersonaDetails.persona_writer_intention,
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "payload_name": "persona_writer_intention"
        },
        {
            "page_field_id": 5009,
            "label": "Writing Example 1",
            "placeholder": null,
            "required": true,
            "name": "5009_Writing_Example_1 ",
            "type": "longtext",
            "value": currentActivePersonaDetails == null ? "" : currentActivePersonaDetails?.persona_writing_example[0] && currentActivePersonaDetails?.persona_writing_example[0]?.persona_writing_example_value,
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "payload_name": "persona_writing_example",
            "persona_set_name": "persona_writing_example_1",
        },
        {
            "page_field_id": 5010,
            "label": "Writing Example 2",
            "placeholder": null,
            "required": false,
            "name": "5009_Writing_Example_2 ",
            "type": "longtext",
            "value": currentActivePersonaDetails == null ? "" : currentActivePersonaDetails.persona_writing_example[1] && currentActivePersonaDetails.persona_writing_example[1].persona_writing_example_value,
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "payload_name": "persona_writing_example",
            "persona_set_name": "persona_writing_example_2",
        },
        {
            "page_field_id": 5011,
            "label": "Writing Example 3",
            "placeholder": null,
            "required": false,
            "name": "5009_Writing_Example_3 ",
            "type": "longtext",
            "value": currentActivePersonaDetails == null ? "" : currentActivePersonaDetails.persona_writing_example[2] && currentActivePersonaDetails.persona_writing_example[2].persona_writing_example_value,
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "payload_name": "persona_writing_example",
            "persona_set_name": "persona_writing_example_3",
        },
        {
            "page_field_id": 2006,
            "label": "Targeted Audience",
            "placeholder": null,
            "required": false,
            "name": "2006_Targeted_Audience",
            "type": "chip",
            "value": currentActivePersonaDetails !== null
                ? JSON.parse(currentActivePersonaDetails.persona_targeted_audience || '[]')
                : [],
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "payload_name": "persona_targeted_audience"
        },
        {
            "page_field_id": 2821,
            "name": "2821_Select ",
            "type": "select button",
            "value": currentActivePersonaDetails !== null
                ? currentActivePersonaDetails.persona_writing_traits.persona_reading_level_name ? currentActivePersonaDetails.persona_writing_traits.persona_reading_level_name : ""
                : "",
            "col": 12,
            "label": "Reading Ease",
            "options": [
                {
                    "field_option_data_id": 13090,
                    "field_option_value": "Novice",
                    "field_option_flag": false
                },
                {
                    "field_option_data_id": 13091,
                    "field_option_value": "Intermediate",
                    "field_option_flag": false
                },

                {
                    "field_option_data_id": 13093,
                    "field_option_value": "Expert",
                    "field_option_flag": false
                }
            ],
            "tooltip": "",
            "helper_text": "",
            "hidden": false,
            "required": false,
            "payload_name": "persona_writing_traits",
            "persona_set_name": "persona_reading_level_name",
        },
        {
            "page_field_id": 2822,
            "name": "2822_Select ",
            "type": "select button",
            "value": currentActivePersonaDetails !== null
                ? currentActivePersonaDetails.persona_writing_traits.persona_sentence_length_type ? currentActivePersonaDetails.persona_writing_traits.persona_sentence_length_type : ""
                : "",
            "col": 12,
            "label": "Sentence length",
            "options": [
                {
                    "field_option_data_id": 13090,
                    "field_option_value": "Short",
                    "field_option_flag": false
                },
                {
                    "field_option_data_id": 13091,
                    "field_option_value": "Medium",
                    "field_option_flag": false
                },
                {
                    "field_option_data_id": 13092,
                    "field_option_value": "Long",
                    "field_option_flag": false
                },
            ],
            "tooltip": "",
            "helper_text": "",
            "hidden": false,
            "required": false,
            "payload_name": "persona_writing_traits",
            "persona_set_name": "persona_sentence_length_type",
        },
        {
            "page_field_id": 2823,
            "name": "2822_Jargons",
            "type": "chip",
            "value": currentActivePersonaDetails !== null ?
                currentActivePersonaDetails.persona_writing_traits.persona_jargons ?
                    JSON.parse(currentActivePersonaDetails.persona_writing_traits.persona_jargons) : []
                : [],
            "col": 12,
            "label": "Jargons",
            "placeholder": "",
            "required": false,
            "flag_ai_suggestion": false,
            "tooltip": "",
            "helper_text": "",
            "payload_name": "persona_writing_traits",
            "persona_set_name": "persona_jargons",
        },
        {
            "page_field_id": 2824,
            "name": "2824_Select ",
            "type": "select button",
            "value": currentActivePersonaDetails !== null
                ? currentActivePersonaDetails?.persona_personal_traits?.persona_narrative_style_type ? currentActivePersonaDetails.persona_personal_traits?.persona_narrative_style_type : ""
                : "",
            "col": 12,
            "label": "Narrative Style",
            "options": [
                {
                    "field_option_data_id": 13090,
                    "field_option_value": "Active",
                    "field_option_flag": false
                },
                {
                    "field_option_data_id": 13091,
                    "field_option_value": "Passive",
                    "field_option_flag": false
                },
            ],
            "tooltip": "",
            "helper_text": "",
            "hidden": false,
            "required": false,
            "payload_name": "persona_personal_traits",
            "persona_set_name": "persona_narrative_style_type",
        },
        {
            "page_field_id": 2825,
            "name": "2825_Select ",
            "type": "select button",
            "value": currentActivePersonaDetails !== null
                ? currentActivePersonaDetails?.persona_personal_traits?.persona_communication_style_type ? currentActivePersonaDetails.persona_personal_traits?.persona_communication_style_type : ""
                : "",
            "col": 12,
            "label": "Communication Style",
            "options": [
                {
                    "field_option_data_id": 13090,
                    "field_option_value": "Introvert",
                    "field_option_flag": false
                },
                {
                    "field_option_data_id": 13091,
                    "field_option_value": "Balanced",
                    "field_option_flag": false
                },
                {
                    "field_option_data_id": 13092,
                    "field_option_value": "Extrovert",
                    "field_option_flag": false
                },
            ],
            "tooltip": "",
            "helper_text": "",
            "hidden": false,
            "required": false,
            "payload_name": "persona_personal_traits",
            "persona_set_name": "persona_communication_style_type",
        },
        {
            "page_field_id": 2826,
            "name": "2826_Interests",
            "type": "chip",
            "value": currentActivePersonaDetails !== null ?
                currentActivePersonaDetails.persona_personal_traits.persona_interests ?
                    JSON.parse(currentActivePersonaDetails.persona_personal_traits.persona_interests) : []
                : [],
            "col": 12,
            "label": "Interests",
            "placeholder": "",
            "required": false,
            "flag_ai_suggestion": false,
            "tooltip": "",
            "helper_text": "",
            "payload_name": "persona_personal_traits",
            "payload_set_name": "persona_interests"
        },
        {
            "page_field_id": 2827,
            "label": "Persona Background",
            "placeholder": null,
            "required": false,
            "name": "2827_Persona_Background ",
            "type": "longtext",
            "value": currentActivePersonaDetails == null ? "" : currentActivePersonaDetails.persona_background,
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "payload_name": "persona_background"
        },
        {
            "page_field_id": 2828,
            "label": "Goals/Objectives",
            "placeholder": null,
            "required": true,
            "name": "2828_Goal/Objectives ",
            "type": "chip",
            "value": currentActivePersonaDetails !== null
                ? JSON.parse(currentActivePersonaDetails.persona_goals_objectives || '[]')
                : [],
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "payload_name": "persona_goals_objectives"
        },

    ]

    const [createPersonaFormData, setCreatePersonaFormData] = useState<any>({
        data: {
            global_form_field: IndividualTypeForm,
            widget_flag_auto_save: false,
            widget_flag_hide_button: false,
            widget_form_full_width: true,
            widget_theme_color: "#6A097D33",
            doc_page_theme: [
                {
                    "page_id": "682",
                    "page_theme_font_type": "Open Sans",
                    "page_theme_font_style": "normal",
                    "page_theme_font_align": "left",
                    "page_theme_font_weight": "bold",
                    "page_theme_font_size": "15",
                    "page_theme_background_color": "#FFFFFF",
                    "page_theme_heading_color": "grey",
                    "page_theme_text_decoration": "unset",
                    "page_hide_button": false,
                    "page_auto_save": false,
                    "page_font_spacing": null,
                    "page_theme_row_spacing": 0,
                    "page_theme_column_spacing": 0,
                    "margin": "10px "
                }
            ]
        }
    })


    const [submissionID, setsubmissionID] = useState(null);

    const [subType, setSubType] = useState<any>("Individual");
    const [filePath, setfilePath] = useState<any>("");
    const [flagFirstRenderDone, setFlagFirstRenderDone] = useState(false)
    const [renderValue, setRenderValue] = useState({
        status: null,
        message: "",
        submission_id: null
    })


    const handleCloseSnackBar = () => {
        setRenderValue({
            status: null,
            message: "",
            submission_id: null
        })
    }

    const setFieldValue = (data: any) => {
        var tempdata = { ...createPersonaFormData }
        tempdata.data.global_form_field.map((newvalue: any) => {

            if (newvalue.options) {
                newvalue.options.map((option_value: any) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
        })
        setCreatePersonaFormData(tempdata)
        setFlagFirstRenderDone(true)
    }
    useEffect(() => {
        let temp = { ...createPersonaFormData }
        temp.data.global_form_field = IndividualTypeForm
        setCreatePersonaFormData(temp)
    }, [currentActivePersonaDetails])

    const formSubmitFunction = (data: any) => {
        var finalData = data;

    }

    const getCreatePersonaButtonDisableCondition = () => {
        let fields = createPersonaFormData["data"]["global_form_field"]
        for (const field of fields) {
            if (field.required && (
                (Array.isArray(field.value) && field.value.length === 0) ||
                (!Array.isArray(field.value) && field.value.trim().length === 0) ||
                (JSON.stringify(fields) === JSON.stringify(IndividualTypeForm))
            )) {
                return true;
            }
        }
        return false;

    }

    const handleSubmitPersona = async () => {
        let fields = createPersonaFormData["data"]["global_form_field"]
        const updatedPayload: any = { ...currentActivePersonaDetails }
        fields.forEach((field: any) => {

            updatedPayload.persona_id = currentActivePersonaDetails != null && currentActivePersonaDetails.persona_id;
            updatedPayload.persona_created_by_user_name = currentActivePersonaDetails != null && currentActivePersonaDetails.persona_created_by_user_name;
            updatedPayload.persona_created_at_timestamp = currentActivePersonaDetails != null && currentActivePersonaDetails.persona_created_at_timestamp;
            updatedPayload.persona_flag_public = currentActivePersonaDetails != null && currentActivePersonaDetails.persona_flag_public;
            updatedPayload.persona_premium_flag = currentActivePersonaDetails != null && currentActivePersonaDetails.persona_premium_flag;
            updatedPayload.persona_type_id = currentActivePersonaDetails != null && currentActivePersonaDetails.persona_type_id;
            updatedPayload.persona_type_name = currentActivePersonaDetails != null && currentActivePersonaDetails.persona_type_name;
            if (field.payload_name == "persona_avatar_url") {

                updatedPayload.persona_avatar_url = field.avatar_Image;
            }
            else if (field.payload_name == "persona_name") {
                updatedPayload.persona_name = field.value;
            }
            else if (field.payload_name == "about_persona") {
                updatedPayload.about_persona = field.value;
            }
            else if (field.payload_name == "persona_age") {
                updatedPayload.persona_age = field.value;
            }
            else if (field.payload_name == "persona_gender") {
                updatedPayload.persona_gender = field.value;
            }
            else if (field.payload_name == "persona_location") {
                updatedPayload.persona_location = field.value;
            }
            else if (field.payload_name == "persona_background") {
                updatedPayload.persona_background = field.value;
            }
            else if (field.payload_name == "persona_occupation") {
                updatedPayload.persona_occupation = field.value;
            }
            else if (field.payload_name == "persona_brand_details") {
                updatedPayload.persona_brand_details = field.value;
            }
            else if (field.payload_name == "persona_goals_objectives") {
                updatedPayload.persona_goals_objectives = JSON.stringify(field.value);
            }
            else if (field.payload_name == "persona_writer_intention") {
                updatedPayload.persona_writer_intention = field.value;
            }
            else if (field.payload_name == "persona_targeted_audience") {
                updatedPayload.persona_targeted_audience = JSON.stringify(field.value);
            }
            else if (field.payload_name == "persona_summary") {
                updatedPayload.persona_summary = field.value;
            }
            else if (field.payload_name == "persona_writing_example") {
                if (field.persona_set_name == "persona_writing_example_1") {
                    updatedPayload.persona_writing_example[0].persona_writing_example_value = field.value;
                }
                else if (field.persona_set_name == "persona_writing_example_2") {
                    if (updatedPayload?.persona_writing_example?.length > 1) {
                        updatedPayload['persona_writing_example'][1].persona_writing_example_value = field.value;
                    } else {
                        updatedPayload.persona_writing_example.push({
                            persona_writing_example_value: field.value
                        })
                    }
                }
                else if (field.persona_set_name == "persona_writing_example_3") {
                    if (updatedPayload?.persona_writing_example?.length > 2) {
                        updatedPayload['persona_writing_example'][2].persona_writing_example_value = field.value;
                    } else {
                        updatedPayload.persona_writing_example.push({
                            persona_writing_example_value: field.value
                        })
                    }
                }
            }
            else if (field.payload_name == "persona_writing_traits") {
                if (field.persona_set_name == "persona_reading_level_name") {
                    const level = personaWritingSkills?.find(level => level.persona_reading_level_name === field.value);
                    updatedPayload.persona_writing_traits['persona_reading_level_id'] = level ? level.persona_reading_level_id : null;
                    updatedPayload.persona_writing_traits['persona_reading_level_name'] = field.value;
                }
                else if (field.persona_set_name == "persona_sentence_length_type") {
                    const level = personaSentenceLength?.find(level => level.persona_sentence_length_type === field.value);
                    updatedPayload.persona_writing_traits['persona_sentence_length_id'] = level ? level.persona_sentence_length_id : null;
                    updatedPayload.persona_writing_traits['persona_sentence_length_type'] = field.value;
                }
                else if (field.persona_set_name == "persona_jargons") {
                    updatedPayload.persona_writing_traits['persona_jargons'] = JSON.stringify(field.value);
                }
            }
            else if (field.payload_name == "persona_personal_traits") {
                if (field.persona_set_name == "persona_narrative_style_type") {
                    const level = personaNarrativeStyle?.find(level => level.persona_narrative_style_type === field.value);
                    updatedPayload.persona_personal_traits['persona_narrative_style_id'] = level ? level.persona_narrative_style_id : null;
                    updatedPayload.persona_personal_traits['persona_narrative_style_type'] = field.value;
                }
                else if (field.persona_set_name == "persona_communication_style_type") {
                    const level = personaCommunicationType?.find(level => level.persona_communication_style_type === field.value);
                    updatedPayload.persona_personal_traits['persona_communication_style_id'] = level ? level.persona_communication_style_id : null;
                    updatedPayload.persona_personal_traits['persona_communication_style_type'] = field.value;
                }
                else if (field.persona_set_name == "persona_interests") {
                    updatedPayload.persona_personal_traits['persona_interests'] = JSON.stringify(field.value);
                }
            }

            let a: any = []
            updatedPayload.persona_writing_example.map((ele: any) => {
                if (!!ele.persona_writing_example_value) {
                    a.push(ele)
                }
            })
            updatedPayload.persona_writing_example = a


        })

        setFlagLoading({ ...flagLoading, flagRoundLoader: true });

        const response = await UpdatePersona(updatedPayload)
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Persona Updated Successfully!" })
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });




    }

    const uploadFileHandler = async (event: any, id: any, removeFile: any) => {

        if (removeFile == "RemoveFile") {

            var tempdata = { ...createPersonaFormData }
            tempdata.data.global_form_field.map((newvalue: any) => {

                if (newvalue.page_field_id === id) {
                    newvalue.avatar_Image = ""
                }
            })
            setCreatePersonaFormData(tempdata)
        }
        else {
            const file = event[0];

            if (!file) return;
            file.isUploading = true;

            const formData: any = new FormData()
            formData.append(
                "file",
                file,
                // file.name,
                "persona_flag",
                true
            )
            const payload: any = {}
            payload[`persona_flag`] = true;
            payload[`file`] = file;
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            const response = await agent.fileUpload.post(payload)
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });

            if (response.status) {
                await setfilePath(response.data.file_path)

                var tempdata = { ...createPersonaFormData }
                tempdata.data.global_form_field.map((newvalue: any) => {

                    if (newvalue.page_field_id === id) {
                        newvalue.avatar_Image = response.data.file_path
                    }
                })
                setCreatePersonaFormData(tempdata)
            }
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} className={styles.prEditMainItem}>
                <Grid container>
                    <Grid item xs={12} sx={{ marginBottom: "15px" }}>
                        <GlobalPageHeaderText
                            mainHeadText={"Persona"}
                            mainSubText={"Customize your AI experience to your persona and writing style"}
                        />
                    </Grid>



                    <Grid item xs={12} className={styles.prEditFormMainItem}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container className={styles.prEditHeaderMainContainer}>
                                    <Grid item>
                                        <Typography component={'span'} className={styles.prEditHeaderText}>
                                            Edit Persona - <span className={styles.prTypeNameText}>Individual</span>
                                        </Typography>
                                    </Grid>
                                    {!mobileScreen ? (
                                        <>
                                            <Grid item>
                                                <Grid container>
                                                    <Grid item>
                                                        <GlobalButton
                                                            buttonText="Cancel"
                                                            className="cancelButtonPrimaryBorder"
                                                            onClick={() => {
                                                                navigate(`/dashboard/viewPersona?persona_id=${currentActivePersonaDetails != null && currentActivePersonaDetails.persona_id}`)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <GlobalButton
                                                            buttonText="Save"
                                                            className="primaryButtonStylePersonaEdit"
                                                            disabled={getCreatePersonaButtonDisableCondition()}
                                                            onClick={handleSubmitPersona}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (<></>)
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} className={styles.prEditGlobalformMainItem}>
                                <GlobalForm
                                    fields={createPersonaFormData.data.global_form_field}
                                    submitFunction={formSubmitFunction}
                                    checkFormSubmit={renderValue}
                                    handleCloseSnackBar={handleCloseSnackBar}
                                    pageThemeData={createPersonaFormData.data.doc_page_theme[0]}
                                    formData={createPersonaFormData.data}
                                    submissionID={submissionID}
                                    setFieldValue={setFieldValue}
                                    recaptchaFlag="true"
                                    defaultLoader={false}
                                    formEditable={true}
                                    uploadFileHandler={(e: any, id: any, removeFile: any) => { uploadFileHandler(e, id, removeFile) }}
                                />
                            </Grid>

                            {mobileScreen ? (
                                <>
                                    <Grid item xs={12}>
                                        <Grid container sx={{ justifyContent: "center", gap: "10px", margin: "10px 0px 0px" }}>
                                            <Grid item xs={5}>
                                                <GlobalButton
                                                    flagFullWidth
                                                    buttonText="Cancel"
                                                    className="cancelButtonPrimaryBorder"
                                                    onClick={() => {
                                                        navigate(`/dashboard/viewPersona?persona_id=${currentActivePersonaDetails != null && currentActivePersonaDetails.persona_id}`)
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <GlobalButton
                                                    flagFullWidth
                                                    buttonText="Save"
                                                    className="primaryButtonStylePersonaEdit"
                                                    disabled={getCreatePersonaButtonDisableCondition()}
                                                    onClick={handleSubmitPersona}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (<></>)}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
