import { $createLinkNode } from '@lexical/link';
import { $createListItemNode, $createListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import * as React from 'react';

import { isDevPlayground } from './appSettings';
import { SettingsContext, useSettings } from '../Editor/context/SettingsContext';
import { SharedAutocompleteContext } from '../Editor/context/SharedAutocompleteContext';
import { SharedHistoryContext } from '../Editor/context/SharedHistoryContext';
import Editor from '../Editor/Editor';
// import logo from './images/logo.svg';
import PlaygroundNodes from '../Editor/nodes/PlaygroundNodes';
import DocsPlugin from '../Editor/plugins/DocsPlugin';
import PasteLogPlugin from '../Editor/plugins/PasteLogPlugin';
import { TableContext } from '../Editor/plugins/TablePlugin';
// import TestRecorderPlugin from '../Editor/plugins/TestRecorderPlugin';
import TypingPerfPlugin from '../Editor/plugins/TypingPerfPlugin';
// import Settings from './Settings';
import PlaygroundEditorTheme from '../Editor/themes/PlaygroundEditorTheme';
import './App.css'
import editorOption from '../../assets/images/icons/editorOption.png'

console.warn(
  'If you are profiling the playground app, please ensure you turn off the debug view. You can disable it by pressing on the settings control in the bottom-left of your screen and toggling the debug view setting.',
);

function prepopulatedRichText() {
  const root = $getRoot();
  if (root.getFirstChild() === null) {
    const heading = $createHeadingNode('h1');
    heading.append($createTextNode('Welcome to the playground'));
    root.append(heading);
    const quote = $createQuoteNode();
    quote.append(
      $createTextNode(
        `In case you were wondering what the black box at the bottom is – it's the debug view, showing the current state of the editor. ` +
        `You can disable it by pressing on the settings control in the bottom-left of your screen and toggling the debug view setting.`,
      ),
    );
    root.append(quote);
    const paragraph = $createParagraphNode();
    paragraph.append(
      $createTextNode('The playground is a demo environment built with '),
      $createTextNode('@lexical/react').toggleFormat('code'),
      $createTextNode('.'),
      $createTextNode(' Try typing in '),
      $createTextNode('some text').toggleFormat('bold'),
      $createTextNode(' with '),
      $createTextNode('different').toggleFormat('italic'),
      $createTextNode(' formats.'),
    );
    root.append(paragraph);
    const paragraph2 = $createParagraphNode();
    paragraph2.append(
      $createTextNode(
        'Make sure to check out the various plugins in the toolbar. You can also use #hashtags or @-mentions too!',
      ),
    );
    root.append(paragraph2);
    const paragraph3 = $createParagraphNode();
    paragraph3.append(
      $createTextNode(`If you'd like to find out more about Lexical, you can:`),
    );
    root.append(paragraph3);
    const list = $createListNode('bullet');
    list.append(
      $createListItemNode().append(
        $createTextNode(`Visit the `),
        $createLinkNode('https://lexical.dev/').append(
          $createTextNode('Lexical website'),
        ),
        $createTextNode(` for documentation and more information.`),
      ),
      $createListItemNode().append(
        $createTextNode(`Check out the code on our `),
        $createLinkNode('https://github.com/facebook/lexical').append(
          $createTextNode('GitHub repository'),
        ),
        $createTextNode(`.`),
      ),
      $createListItemNode().append(
        $createTextNode(`Playground code can be found `),
        $createLinkNode(
          'https://github.com/facebook/lexical/tree/main/packages/lexical-playground',
        ).append($createTextNode('here')),
        $createTextNode(`.`),
      ),
      $createListItemNode().append(
        $createTextNode(`Join our `),
        $createLinkNode('https://discord.com/invite/KmG4wQnnD9').append(
          $createTextNode('Discord Server'),
        ),
        $createTextNode(` and chat with the team.`),
      ),
    );
    root.append(list);
    const paragraph4 = $createParagraphNode();
    paragraph4.append(
      $createTextNode(
        `Lastly, we're constantly adding cool new features to this playground. So make sure you check back here when you next get a chance :).`,
      ),
    );
    root.append(paragraph4);
  }
}

function App(): JSX.Element {
  const {
    settings: { isCollab, emptyEditor, measureTypingPerf },
  } = useSettings();

  const initialConfig = {
    editorState: isCollab
      ? null
      : emptyEditor
        ? undefined
        : prepopulatedRichText,
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  const [position, setPosition] = React.useState({ x: 0, y: 0 });

  // console.log("position", position)
  React.useEffect(() => {
    const handleMouseMove = (e: any) => {
      if (e.clientY > 76 && e.clientY < 700) {
        setPosition({ x: e.clientX, y: e.clientY });
      }
    };

    const handleKeyDown = (e: any) => {
      if (e.key == 'ArrowUp' || e.key == 'ArrowDown') {
        const { x, y } = getCursorPosition();
        if (y > 76) {
          setPosition({ x: x, y: y - 17 });
          // setPosition({ x: x, y: y-15 });
        } else {
        }
      }
    };

    // window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const getCursorPosition = () => {
    const selection: any = window.getSelection();
    if (selection.rangeCount === 0) return { x: 0, y: 0 };
    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    return { x: rect.left, y: rect.top };
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <TableContext>
          <SharedAutocompleteContext>
            <header>
              {/* <a href="https://lexical.dev" target="_blank" rel="noreferrer">
                <img src={logo} alt="Lexical Logo" />
              </a> */}
            </header>
            <div className="editor-shell">
              <Editor />
            </div>
            {/* <div style={{ position: "absolute", top: position.y, right: "70px", transitionDuration: "0.5s" }}>
              <img src={editorOption} style={{ height: "30px", width: "30px", cursor: "pointer" }} />
            </div> */}
            {/* <Settings /> */}
            {/* {isDevPlayground ? <DocsPlugin /> : null} */}
            {/* {isDevPlayground ? <PasteLogPlugin /> : null} */}
            {/* {isDevPlayground ? <TestRecorderPlugin /> : null} */}

            {measureTypingPerf ? <TypingPerfPlugin /> : null}
          </SharedAutocompleteContext>
        </TableContext>
      </SharedHistoryContext>
    </LexicalComposer>
  );
}

export default function PlaygroundApp(): JSX.Element {
  return (
    <SettingsContext>
      <App />
      <a
        href="https://github.com/facebook/lexical/tree/main/packages/lexical-playground"
        className="github-corner"
        aria-label="View source on GitHub">
      </a>
    </SettingsContext>
  );
}

