import React, { useContext } from 'react'
import CircularProgress from '@mui/joy/CircularProgress';
import { Grid, Box, Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button, Select, MenuItem, InputLabel, FormControl, TextField } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { color, motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { questionModel } from '../../models/axiosModel';
import { answerModel } from '../../models/axiosModel';
import styles from "../../assets/styles/componentStyles/welcome.module.scss";
import WriteWizLogo from "../../assets/WriteWizLogo.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserContext, UserContextPayload } from '../../context/userContext';
import Autocomplete from '@mui/material/Autocomplete';
import WzStarIcon from '../../assets/writewiz-star-white.png';

const Welcome = () => {
    const [questions, setQuestions] = React.useState<questionModel[]>([]);
    const [currentQuestion, setCurrentQuestion] = React.useState(0);
    const [selectedAnswer, setSelectedAnswer] = React.useState<string | null>(null);
    const [answers, setAnswers] = React.useState<answerModel[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [disabledStartWriting, setDisabledStartWriting] = React.useState<boolean>(true);
    const [alreadyAnswered, setAlreadyAnswered] = React.useState<answerModel[]>([]);
    const { userDetail, flagOnboardingSkip,
        setFlagOnboardingSkip } = useContext(UserContext) as UserContextPayload;

    const navigate = useNavigate();

    const fetchUserAdditionalInfo = async () => {
        try {
            // REACT_APP_USER_ADDITIONAL_INFO_URL
            const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get-user-additional-information-data`);
            const userAdditionalInfo = data.data.data;

            let index;
            const ans: answerModel[] = [];
            for (index = 0; index < userAdditionalInfo.length - 1; index++) {
                if (userAdditionalInfo[index].user_additional_information_data_value === "") {
                    setCurrentQuestion(index);
                    break;
                }
                else {
                    const answer = {
                        user_additional_information_id: userAdditionalInfo[index].user_additional_information_id,
                        user_additional_information_data_value: userAdditionalInfo[index].user_additional_information_data_value
                    }
                    ans.push(answer);
                }
            }

            if (index >= 4) {
                setCurrentQuestion(3);
            }
            setAlreadyAnswered(ans);
            setAnswers(ans);
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchQuestions = async () => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get-user-additional-information-generic-data`);
            setQuestions(data.data.data);
            setIsLoading(false);
        }
        catch (error) {
            console.log("error");
        }
    }


    React.useEffect(() => {
        fetchUserAdditionalInfo();
        fetchQuestions();
    }, []);

    React.useEffect(() => {
        storeUserAdditionalData();
        setAlreadyAnswered(answers);
    }, [answers]);


    const storeUserAdditionalData = async (data?: answerModel[]) => {
        // debugger;
        if (data) {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/user/add-user-additional-information-data`, { user_additional_information_data: data });
            if (response.status) {
                return true
            } else {
                return false
            }
        }
        else {
            try {
                const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/user/add-user-additional-information-data`, { user_additional_information_data: answers });
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const handleListItemClick = async (event: any, value: string) => {

        const answer = {
            user_additional_information_id: questions[currentQuestion].user_additional_information_id,
            user_additional_information_data_value: value
        }

        if (answers.length - 1 >= currentQuestion) {
            const ans = answers;
            ans[currentQuestion] = answer;
            setAnswers([...ans]);
        }
        else {
            setAnswers([...answers, answer]);
        }

        if (currentQuestion === questions.length - 2) {
            setDisabledStartWriting(false);
        }
        else {
            setTimeout(() => {
                setCurrentQuestion(currentQuestion + 1);
            })

            setDisabledStartWriting(true)
        }

        if (currentQuestion !== questions.length - 2) {
            setSelectedAnswer(alreadyAnswered[currentQuestion + 1] ? alreadyAnswered[currentQuestion + 1].user_additional_information_data_value : '');
        }
    };

    const handleSkip = async () => {
        const skip = {
            user_additional_information_id: 6,
            user_additional_information_data_value: "true"
        }

        const data = [...answers, skip];
        const response = await storeUserAdditionalData(data);


        if (response) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get-user-additional-information-data`);
                if (response.status) {
                    const userAdditionalInfo = response?.data?.data;
                    if (!userAdditionalInfo.length || userAdditionalInfo[userAdditionalInfo.length - 1].user_additional_information_data_value === "") {
                        setFlagOnboardingSkip(false);
                    }
                    else {
                        setFlagOnboardingSkip(true);
                    }
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    }

    const goToPreviousQuestion = () => {
        setCurrentQuestion(currentQuestion - 1);

        setSelectedAnswer(alreadyAnswered[currentQuestion - 1].user_additional_information_data_value);
    }

    const goToNextQuestion = () => {
        setCurrentQuestion(currentQuestion + 1);
        setSelectedAnswer(alreadyAnswered[currentQuestion + 1] ? alreadyAnswered[currentQuestion + 1].user_additional_information_data_value : '');
    }

    return (
        <>
            {!isLoading && (
                <Box>
                    <Grid container className={styles.navContainer}>
                        <Grid item>
                            <img src={WriteWizLogo} alt="Logo" className={styles.welcome_pg_wz_logo} />
                        </Grid>
                        <Grid item>
                            <CircularProgress size="lg" determinate value={(currentQuestion) * 100 / (questions.length - 1)} color='primary' className={styles.welcomePageProgress} sx={{ color: "#6A097D", fontWeight: "600", fontSize: '15px', stroke: "#6A097D !important" }}>
                                <span style={{ color: "black", paddingRight: "2px" }}>{currentQuestion}</span> / <span style={{ paddingLeft: "2px" }}>{questions.length - 1}</span>
                            </CircularProgress>
                        </Grid>
                    </Grid>

                    <Grid container className={styles.questionsContainer}>
                        <Box className={styles.staticDataWrapper}>
                            <Typography variant='h3' fontSize={"26px"} fontWeight={"600"} pb={1}>
                                Welcome to <span style={{ color: "#6A097D" }}>Writewiz</span>, {userDetail.user_first_name}
                            </Typography>
                            <Typography variant='body1' fontSize={"15px"} fontWeight={"600"} color={"#888888"}>
                                Tell us more about yourself, and we'll ensure a tailored experience just for you.
                            </Typography>
                        </Box>
                        <motion.div
                            key={currentQuestion}
                            initial={{ opacity: 0, y: "45vh" }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ type: 'tween', ease: 'easeInOut', duration: 0.4 }}
                            className={styles.questionsWrapper}
                        >
                            <Grid item>
                                <Typography variant='h6' fontWeight={"500px"} fontSize={"16px"}>{questions[currentQuestion]?.user_additional_information_question}</Typography>
                                {
                                    currentQuestion === 0 ?
                                        (<List className={styles.optionsList}>
                                            {questions[currentQuestion]?.user_additional_information_option.map((option: string, index: number) =>
                                                <ListItem disablePadding key={index}>
                                                    <ListItemButton sx={{ background: alreadyAnswered[currentQuestion] ? alreadyAnswered[currentQuestion].user_additional_information_data_value === option ? '#f7e9ff' : '#f1f1f1' : '#f1f1f1' }} onClick={(e) => handleListItemClick(e, option)}>
                                                        <ListItemText primary={option} />
                                                    </ListItemButton>
                                                </ListItem>
                                            )}
                                        </List>
                                        ) :
                                        <Autocomplete
                                            options={questions[currentQuestion]?.user_additional_information_option}
                                            className={styles.dropdownForOptions}
                                            disablePortal
                                            disableClearable
                                            value={selectedAnswer}
                                            renderOption={(props: object, option: any, state: object) => {
                                                return (
                                                    <li className={styles.optionValue} {...props} style={{ fontSize: "13px", fontWeight: "600" }}>
                                                        {option}
                                                    </li>
                                                )
                                            }}
                                            sx={{
                                                '& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover': {
                                                    backgroundColor: '#F7E9FF',
                                                },
                                                "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']:hover": {
                                                    backgroundColor: '#F7E9FF',
                                                },
                                            }}
                                            onChange={(event, newValue) => {
                                                setSelectedAnswer(newValue);
                                                handleListItemClick(event, newValue as string);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant='outlined'
                                                    placeholder='Search Something'
                                                    className={styles.textField}
                                                    sx={{
                                                        '.MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'rgba(0, 0, 0, 0.1)',
                                                        },
                                                        borderRadius: 2,
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'rgba(106, 9, 125, 0.5)',
                                                        },
                                                        '&:hover': {
                                                            '&& fieldset': {
                                                                border: '1px solid rgba(106, 9, 125, 0.5)',
                                                            },
                                                        },
                                                    }}
                                                />
                                            )}
                                            filterOptions={(options: any, state: any) =>
                                                options.filter((option: any) =>
                                                    option.toLowerCase().includes(state.inputValue.toLowerCase())
                                                )
                                            }
                                        />
                                }

                                {currentQuestion === questions.length - 2 &&
                                    <Button variant='contained' startIcon={<img src={WzStarIcon} />} className={styles.startWritingBtn} disabled={disabledStartWriting} onClick={handleSkip}>
                                        Start Writing
                                    </Button>
                                }
                            </Grid>
                        </motion.div>
                    </Grid>

                    <Box className={styles.backSkipContainer} display={"flex"} justifyContent={"space-between"} width={"90%"} margin={"auto"}>
                        <div style={{ width: "97px" }}>
                            {currentQuestion !== 0 && <Button variant='outlined' startIcon={<ArrowBackIcon />} onClick={goToPreviousQuestion} className={styles.skipBackBtn} sx={{ color: "black !important" }}>
                                Back
                            </Button>
                            }
                        </div>

                        <div>
                            {(currentQuestion !== questions.length - 2 && alreadyAnswered[currentQuestion]) && <Button variant='outlined' endIcon={<ArrowBackIcon sx={{ transform: 'rotate(180deg)' }} />} onClick={goToNextQuestion} className={styles.skipBackBtn} sx={{ color: "black !important" }}>
                                Next
                            </Button>
                            }
                        </div>

                        {currentQuestion !== questions.length - 2 && <Button variant='outlined' endIcon={<ArrowForwardIosIcon fontSize='small' sx={{ width: "80%" }} />} onClick={handleSkip} className={styles.skipBackBtn}>
                            Skip
                        </Button>}
                    </Box>
                </Box>
            )}
        </>
    )
}

export default Welcome;