import { Grid } from '@mui/material';
import { NestedListComponent } from './templateComponents/dragDropComponent';
import globalStyles from "../assets/styles/global.module.scss";
import GlobalButton from './utility/globalButton/button';
import { prompts } from '../utils/constants';
import { DocContext, DocContextPayload } from '../context/docContext';
import React from 'react';
import styles from "../assets/styles/componentStyles/outlineSectionHeading.module.scss";


const OutlineSectionHeadingComponent = () => {
    const { webSocketObj, documentOutlineData, currentActiveDocDetails, setFlagOutlineLoader, flagOutlineLoader } = React.useContext(DocContext) as DocContextPayload;
    function updateResponseAsRequired(data: any) {
        for (const item of data) {
            item['unique_id'] = item.document_outline_id
            delete item.document_outline_level_num
            delete item.document_outline_seq_num

            if (item?.children && item.children.length > 0) {
                updateResponseAsRequired(item.children);
            }
        }
        return data
    }

    const generateSectionHeaders = async () => {
        if (currentActiveDocDetails) {
            const matchingPrompt = prompts.AISuggestions.find(
                (prompt) => prompt.prompt_key === "1::blog_outline_section_header"
            );
            if (matchingPrompt?.prompt_id) {
                setFlagOutlineLoader(true)
                const payload = {
                    document_uuid: currentActiveDocDetails.document_uuid,
                    prompt_id: matchingPrompt.prompt_id
                }
                webSocketObj.send(JSON.stringify({
                    "url": "ai/ask-ai",
                    "operation_id": `prompt-outline-${new Date().getTime()}`,
                    "payload": {
                        "document_uuid": payload.document_uuid,
                        "prompt_id": payload.prompt_id
                    }
                }))
            }
        }
    }

    const generateSubSectionOutline = async () => {
        if (currentActiveDocDetails) {
            const matchingPrompt = prompts.AISuggestions.find(
                (prompt) => prompt.prompt_key === "1::blog_outline_sub_section"
            );
            if (matchingPrompt?.prompt_id) {
                setFlagOutlineLoader(true)
                const payload = {
                    document_uuid: currentActiveDocDetails.document_uuid,
                    prompt_id: matchingPrompt.prompt_id
                }
                webSocketObj.send(JSON.stringify({
                    "url": "ai/ask-ai",
                    "operation_id": `prompt-outline-${new Date().getTime()}`,
                    "payload": {
                        "document_uuid": payload.document_uuid,
                        "prompt_id": payload.prompt_id
                    }
                }))
            }
        }
    }

    const subSectionBtnVisibility = () => {
        let childrenLines = documentOutlineData?.filter((outline) => outline?.children?.length > 0)
        if (childrenLines && childrenLines?.length > 0) {
            return false
        } else {
            return true
        }
    }
    return (
        <>
            <Grid container direction="column" className={styles.sectionOutlineGrid}>
                <Grid item className={`${globalStyles.globalTextStyleGrey} ${styles.sectionOutlineItem}`}>
                    <Grid container justifyContent={"space-between"} alignItems={"center"} className={styles.sectonOutlineContainer}>
                        <Grid item>
                            Section Outline
                        </Grid>
                        <Grid item>
                            <Grid container>
                                {/* <Grid item sx={{ display: "flex", alignItems: "center", opacity: "0.5" }}>
                                    <img src={undo} style={{ width: "20px", height: "20px" }} />
                                </Grid>
                                <Grid item sx={{ display: "flex", alignItems: "center", margin: "0px 30px 0px 10px", opacity: "0.5" }}>
                                    <img src={redo} style={{ width: "20px", height: "20px" }} />
                                </Grid> */}
                                <Grid item className={styles.sectionHeaderButtonWrapper}>
                                    {subSectionBtnVisibility() ?
                                        <GlobalButton
                                            buttonText='Generate sub-section outline'
                                            className='borderedButtonStyle'
                                            // className='linearGredientAnimatedButton'
                                            flagGenerate={true}
                                            onClick={() => { generateSubSectionOutline() }}
                                            disabled={flagOutlineLoader}
                                        />
                                        : ""
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item marginTop={2} className={styles.nestedListComponent}>
                    {/* draggable component */}
                    <NestedListComponent />
                </Grid>
                {subSectionBtnVisibility() ?
                    <Grid item marginTop={3} className={styles.sectionHeaderButtonWrapper}>
                        <GlobalButton
                            buttonText='Generate more section headers'
                            className='borderedButtonStyle'
                            flagGenerate={true}
                            onClick={() => { generateSectionHeaders() }}
                            disabled={(documentOutlineData == undefined || flagOutlineLoader) ? true : documentOutlineData?.length > 5}
                        />
                        {/* draggable component */}
                    </Grid> : ""
                }
            </Grid>
        </>
    )
}

export default OutlineSectionHeadingComponent;