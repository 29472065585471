import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import { CommonResponseModel, addChatbotDomainPayloadModel, createCampaignPayloadModel, createCampaignResponseModel, createChatbotPayloadModel, generateCampaignDocumentPayloadModel, getCampaignDocumentsResponseModel, getCampaignExistingDetailsResponse, getCampaignList, getChatbotResponseModel, getTemplateCampaignListResponseModel, responseModel, updateCampaignDetailsModel, updateChatbotDomainPayloadModel, updateChatbotPayloadModel } from "../models/axiosModel";
import agent from "../services/api";

const CampaignContext = React.createContext<CampaignContextPayload | undefined>(undefined);
export interface CampaignContextPayload {

    GetCampaignListApi: () => Promise<CommonResponseModel<getCampaignList>>;
    campaignListDetails: getCampaignList[] | null;
    setCampaignListDetails: React.Dispatch<React.SetStateAction<null | getCampaignList[]>>;

    createCampaign: (payload: createCampaignPayloadModel) => Promise<CommonResponseModel<createCampaignResponseModel>>;
    getCampaignDetails: (campaign_id: string) => Promise<CommonResponseModel<getCampaignExistingDetailsResponse | null>>

    campaignExistingDetails: getCampaignExistingDetailsResponse | null;
    setCampaignExistingDetails: React.Dispatch<React.SetStateAction<getCampaignExistingDetailsResponse | null>>;
    updateCampaignDetails: (payload: updateCampaignDetailsModel) => Promise<CommonResponseModel<updateCampaignDetailsModel>>

    GetTemplateCampaignList: () => Promise<CommonResponseModel<getTemplateCampaignListResponseModel>>;
    templateCampaignList: getTemplateCampaignListResponseModel[] | null
    setTemplateCampaignList: React.Dispatch<React.SetStateAction<null | getTemplateCampaignListResponseModel[]>>;

    generateCampaignDocument: (payload: generateCampaignDocumentPayloadModel) => Promise<CommonResponseModel<responseModel>>

    getCampaignDocument: (campaign_id: string) => Promise<CommonResponseModel<getCampaignDocumentsResponseModel>>
    campaignDocList: getCampaignDocumentsResponseModel[] | null
    setCampaignDocList: React.Dispatch<React.SetStateAction<null | getCampaignDocumentsResponseModel[]>>;

    campaignRender: any;
    // setCampaignRender: boolean;


}

const CampaignProvider = ({ children }: ChildrenProps) => {

    const [campaignListDetails, setCampaignListDetails] = useState<getCampaignList[] | null>(null)
    const [campaignExistingDetails, setCampaignExistingDetails] = useState<getCampaignExistingDetailsResponse | null>(null)
    const [templateCampaignList, setTemplateCampaignList] = useState<getTemplateCampaignListResponseModel[] | null>(null)
    const [campaignDocList, setCampaignDocList] = useState<getCampaignDocumentsResponseModel[] | null>(null)
    const [campaignRender, setCampaignRender] = useState<any>(null)

    const GetCampaignListApi = async (): Promise<CommonResponseModel<getCampaignList>> => {
        // setCampaignRender(false)
        const response = await agent.getCampaignList.get();
        return response
        // if (response.status) {
        //     console.log("response inside context", response);
        //     if (response.data) {
        //         setCampaignListDetails(response.data)
        //         setCampaignRender(true)
        //         return response;
        //     } else {
        //         setCampaignListDetails([])
        //         setCampaignRender(true)
        //         return response;
        //     }
        // } else {
        //     setCampaignListDetails(null)
        //     setCampaignRender(true)
        //     return response;
        // }
    }

    const createCampaign = async (payload: createCampaignPayloadModel): Promise<any> => {
        const response = await agent.createCampaignList.post(payload)
        return response
    }

    const getCampaignDetails = async (params: string): Promise<CommonResponseModel<getCampaignExistingDetailsResponse | null>> => {
        const response = await agent.getCampaignDetails.get(params)
        if (response.status) {
            if (response.data) {
                setCampaignExistingDetails(response.data[0]);
                return response;
            }
        } else {
            setCampaignExistingDetails(null);
            return response;
        }
        return response
    }

    const updateCampaignDetails = async (payload: updateCampaignDetailsModel): Promise<CommonResponseModel<updateCampaignDetailsModel>> => {
        const response = await agent.updateCampaignDetails.put(payload)
        if (response.status) {
            if (response.data) {
                setCampaignExistingDetails(response.data[0]);
                return response;
            }
        } else {
            setCampaignExistingDetails(null);
            return response;
        }
        return response
    }

    const GetTemplateCampaignList = async (): Promise<CommonResponseModel<getTemplateCampaignListResponseModel>> => {
        const response = await agent.getTemplateCampaignList.get();
        if (response.status) {
            if (response.data) {
                response.data.map((ele: any) => {
                    ele.flagChecked = false
                })
                setTemplateCampaignList(response.data)
            }
        }
        return response;
    }

    const generateCampaignDocument = async (payload: generateCampaignDocumentPayloadModel): Promise<CommonResponseModel<responseModel>> => {
        const response = await agent.generateCampaignDocument.post(payload);
        return response;
    }

    const getCampaignDocument = async (params: string): Promise<CommonResponseModel<getCampaignDocumentsResponseModel>> => {
        const response = await agent.getCampaignDocuemnts.get(params);
        if (response.status) {
            setCampaignDocList(response?.data);
            return response;
        }
        else {
            setCampaignDocList(null);
            return response;
        }
    }

    const payload = {
        GetCampaignListApi,
        campaignListDetails,
        setCampaignListDetails,
        createCampaign,
        getCampaignDetails,
        campaignExistingDetails,
        setCampaignExistingDetails,
        updateCampaignDetails,
        GetTemplateCampaignList,
        templateCampaignList,
        setTemplateCampaignList,
        generateCampaignDocument,
        getCampaignDocument,
        campaignDocList,
        setCampaignDocList,
        campaignRender,
        setCampaignRender

    };

    return (
        <CampaignContext.Provider value={payload}>
            {children}
        </CampaignContext.Provider>
    );
};

export { CampaignContext, CampaignProvider };
