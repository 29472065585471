import React from 'react'
import { Grid, Typography } from '@mui/material';
import styles from "../../assets/styles/componentStyles/wordChangerPopver.module.scss";
import IconsColor from './iconsColor';
import GlobalButton from './globalButton/button';
import globalStyles from "../../assets/styles/global.module.scss";

const WordChangerPopover = (props: any) => {

    return (
        <>
            <Grid container
                direction={"column"}
            >
                <Grid item className={styles.wcPvrMainItem}>
                    <Grid container>
                        <Grid item xs={12} className={styles.wcPvrHeadTextItem}>
                            <Typography component={'span'} className={styles.wcPvrHeadText}>
                                Suggestions
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container gap="10px">
                                {/* <Grid item
                                >
                                    <GlobalButton className="primaryButtonStyle" buttonText={<Grid container justifyContent={"center"} onClick={() => { props.changeWord(props.replacement) }}>
                                        <Grid item sx={{ marginRight: "5px" }}>
                                            <IconsColor iconName="star" width="10px" height="10px" fillColor="#FAC917" />
                                        </Grid>
                                        <Grid item>
                                            <Typography component={'span'} className={styles.wcPvrSuggestionTextPreferred}>
                                                {props.replacement}
                                            </Typography>
                                        </Grid>
                                    </Grid>} />
                                </Grid> */}
                                {props.wordSuggestions.map((ele: string, index: number) => {
                                    return (
                                        <>
                                            <Grid item
                                            >
                                                <GlobalButton className={props.replacement === ele ? "primaryButtonStyle" : "secondaryButtonStyle"}
                                                    buttonText={
                                                        <Grid container justifyContent={"center"} className={globalStyles.containerFlexWrap}
                                                        >
                                                            {index === 0 &&
                                                                <Grid item sx={{ marginRight: "5px" }}>
                                                                    <IconsColor iconName="star" width="10px" height="10px" fillColor="#FAC917" />
                                                                </Grid>}{ele}

                                                        </Grid >} onClick={() => { props.changeWord(ele) }}/>
                                            </Grid>
                                        </>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: "5px 0px" }} >
                            <hr />
                        </Grid>
                        <Grid item xs={12} className={styles.wcPvrOriginalTextItem}>
                            <Typography component={'span'} className={styles.wcPvrOriginalText}>
                                Original Text:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component={'span'} className={styles.wcPvrHeadTextValue}>
                                {props.originalText}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ margin: "0px 0px" }} >
                    <hr />
                </Grid>

                <Grid item xs={12} className={styles.wcPvrReasonChangerTextMainItem}>
                    <Typography component={'span'} className={styles.wcPvrReasonChangerText}>
                        Reason for change : <Typography sx={{ color: `${props.color} !important` }} component={'span'} className={styles.wcPvrReasonChangerTextValue}>{props.suggestion_type}</Typography>
                    </Typography>
                </Grid>
            </Grid >
        </>
    );
};



export default WordChangerPopover