import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import CarouselComponent from './carouselComponent';
import StepperComponent from './stepper';
import styles from './../../assets/styles/componentStyles/sidebarComponent.module.scss'

const Sidebar = () => {

  const tips = [
    {
      heading: "Craft an Engaging Blog Title",
      content: `Your blog title is your blog's first impression. Don't hesitate to experiment with different options to find the perfect fit. \n\nWritewiz is your creative partner, here to help you craft attention-grabbing headlines that seamlessly complement your blog's keynotes, leading you through the entire journey.`,
      buttonText: "Learn More",
      buttonHref: "www.writeWiz/Blog.com",
    },
    {
      heading: "Designing a Stellar Blog Structure",
      content: `Writewiz assists you in building a reader-friendly structure, guiding you at every step.\n\nour blog's structure is like the blueprint of a well-designed building. Organize your ideas coherently, use engaging subheadings, and sprinkle visuals for reader delight.`,
      buttonText: "Learn More",
      buttonHref: "www.writeWiz/Blog.com",
    },
    {
      heading: "Mastering SEO for Triumph",
      content: ` SEO is the gateway to online success. Employ strategic keywords, craft captivating meta descriptions, and enhance user experience. \n\nWith Writewiz, you'll become an SEO expert, guaranteeing your blog's top position in search results, every step of the way.`,
      buttonText: "Learn More",
      buttonHref: "www.writeWiz/Blog.com",
    },
  ];
  return (
    <>
      <Grid container className={styles.sidebarcomponentGridContainer} direction="column" justifyContent="space-between">
        <Grid item>
          <StepperComponent stepperTitle={"Blog Post Creation"} />
        </Grid>
        <Grid item >
          <CarouselComponent tips={tips} />
        </Grid>
      </Grid>
    </>
  )
}

export default Sidebar;