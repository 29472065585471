import React, { useContext, useEffect, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/campaigns.module.scss'
import { Button, CardMedia, Grid, InputBase, Typography } from '@mui/material'
import GlobalSearchBar from '../../utility/globalSearchBar/globalSearchBar'
import AddIcon from '@mui/icons-material/Add';
import logo from '../../../assets/newCampaignLogo.svg'
import IconButton from '@mui/material/IconButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CampaignContext, CampaignContextPayload } from '../../../context/campaignContext';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import agent from '../../../services/api';
import deleteIcon from '../../../assets/deleteIcon.svg'
import { Search } from '@mui/icons-material';
import globalStyles from '../../../assets/styles/global.module.scss'
import SearchBar from "material-ui-search-bar";
import createPersonaIcon from "../../../assets/createPersona.png";
import { getCampaignList } from '../../../models/axiosModel';
import GlobalSearch from '../../utility/GlobalSearch/GlobalSearch';
import noDataFound from '../../../assets/CampaignNoDataFound.png'
import noCampaignFound from '../../../assets/CamapignNotFound.png'
import GlobalPopUp from '../../utility/globalPopUp';
import DeleteDocumentDataComponent from '../deleteDocumentDataComponent';
import GlobalDeletePopup from '../../utility/globalDeletePopup/globalDeletePopup';
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, isDev } from '../../../utils/constants';
// import AddIcon from '@mui/icons-material/Add';

const Campaigns = () => {


    // context
    const {
        GetCampaignListApi,
        campaignListDetails,
        setCampaignListDetails,
        // campaignRender
    } = useContext(CampaignContext) as CampaignContextPayload;

    const {
        flagLoading,
        userDetail,
        setFlagLoading,
        setSuccess,
        setError,
        success,
        error,
        loaderDetails,
        setFlagShowCreditsExhausted,
        setUserDetail
    } = useContext(UserContext) as UserContextPayload;

    // Const variables
    const navigate = useNavigate()

    // UseStates 
    const [hoveredCard, setHoveredCard] = useState(null);
    const [campaignOriginalDetails, setCampaignOriginalDetails] = useState<getCampaignList[] | null>();
    const [campaignDetails, setCampaignDetails] = useState<getCampaignList[] | null>();

    const [flagDeleteCampaign, setFlagDeleteCampaign] = React.useState(false);
    const [deleteCampaignDetails, setDeleteCampaignDetails] = useState({
        campaign_id: null,
        campaign_name: null,
    })
    const [campaignRender, setCampaignRender] = useState<any>(null)
    // useEffect(() => {
    //     (async () => {
    //         setFlagLoading({ ...flagLoading, flagRoundLoader: true});;
    //         try {
    //             await GetCampaignListApi();
    //         }
    //         catch (err) {
    //             console.error("An error occurred:", error);
    //             setError({ ...error, flagShowError: true, message: "Something went wrong, Please try again later!" })
    //             setFlagLoading({ ...flagLoading, flagRoundLoader: false});
    //         }
    //         setFlagLoading({ ...flagLoading, flagRoundLoader: false});;
    //     })();
    // }, []);

    // useEffect(() => {
    //     setFlagLoading({ ...flagLoading, flagRoundLoader: true});
    //     setCampaignOriginalDetails(campaignListDetails)
    //     setFlagLoading({ ...flagLoading, flagRoundLoader: false});
    // }, [campaignListDetails])

    useEffect(() => {
        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            setCampaignRender(false)
            let response = await GetCampaignListApi()
            if (response?.status) {
                if (response?.data?.length) {
                    setCampaignDetails(response.data)
                    setCampaignOriginalDetails(response.data)
                    setCampaignRender(true)
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                } else {
                    setCampaignDetails(null)
                    setCampaignOriginalDetails(null)
                    setCampaignRender(true)
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                }
            } else {
                setCampaignDetails(null)
                setCampaignOriginalDetails(null)
                setCampaignRender(true)
                let errorCode = checkForErrorCode(response)
                if (flagCookieExpired(userDetail, errorCode)) {
                    setUserDetail(emptyUserDetail)
                    if (userDetail.flag_user_anonymous) {
                        let url = window.location.pathname
                        navigate(url, { replace: true });
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: "Session expired! Please login to continue."
                        });
                        setTimeout(() => {
                            let url = encodeURIComponent(window.location.href)
                            navigate("/login?redirect=" + url, { replace: true });
                        }, 1500);
                    }
                } else {
                    if (flagCreditsExhausted(errorCode)) {
                        setFlagShowCreditsExhausted(true)
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                }
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }
        })()
    }, [])

    // Functions
    const handleCardHover = (campaignId: any) => {
        setHoveredCard(campaignId);
    };

    const handleCardLeave = () => {
        setHoveredCard(null);
    };

    const handleGetCampaignDetails = async (campaign_id: number) => {
        navigate(`/dashboard/campaigns/create?campaign_id=${campaign_id}`)
    }

    const handleDeleteCampaignTemplate = async (event: any, campaign_id: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await agent.removeCampaign.delete(campaign_id)
        if (response.status) {
            let campaignResponse = await GetCampaignListApi()
            if (campaignResponse?.status) {
                if (campaignResponse?.data?.length) {
                    setCampaignDetails(campaignResponse.data)
                    setCampaignOriginalDetails(campaignResponse.data)
                    setCampaignRender(true)
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                } else {
                    setCampaignDetails(null)
                    setCampaignOriginalDetails(null)
                    setCampaignRender(true)
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                }
            } else {
                setCampaignDetails(null)
                setCampaignOriginalDetails(null)
                setCampaignRender(true)
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }
            setFlagDeleteCampaign(false)
            setSuccess({ ...success, flagShowSuccess: true, message: "Campaign Deleted Successfully!" })
        }
        else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
            setFlagDeleteCampaign(false)
        }
        setFlagLoading((prevState) => {
            return { ...prevState, flagRoundLoader: false }
        });
    }

    const handleSearch = (event: any) => {
        const query = event?.target?.value?.toLowerCase()
        if (campaignDetails !== null && campaignDetails !== undefined) {
            const campaignFilterData = campaignDetails.filter((campaignData) => {
                return (
                    campaignData.campaign_name !== null && campaignData?.campaign_name?.toLowerCase().includes(query)
                )
            })
            setCampaignOriginalDetails(campaignFilterData)
        }
    }

    return (
        <>
            {flagDeleteCampaign &&
                <GlobalDeletePopup
                    deleteDescription={`Are you sure want to delete ${deleteCampaignDetails.campaign_name} campaign`}
                    open={flagDeleteCampaign}
                    onClose={() => { setFlagDeleteCampaign(false) }}
                    onDelete={(e: any) => {
                        handleDeleteCampaignTemplate(e, Number(deleteCampaignDetails?.campaign_id))
                        setFlagDeleteCampaign(false)
                    }}
                />
            }
            <Grid container>
                <Grid item xs={12} className={`${styles.cmpMainItem}`}>
                    <Grid container>
                        <Grid item xs={12} className={`${styles.cmpMainHeadItem}`}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xl={6} lg={5} md={12} sm={12} xs={12} className={styles.cmpHeaderGrid}>
                                            <GlobalPageHeaderText
                                                mainHeadText={"Campaign"}
                                                mainSubText={"Create a campaign that transforms a simple brief into a full suite of essential documents seamlessly"}
                                            />
                                        </Grid>

                                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <Grid container className={`${globalStyles.globalHeadMainItemRightContainer} ${styles.globalHeadMainItemRightContainer}`} >
                                                {(campaignOriginalDetails == null || campaignOriginalDetails == undefined) ?
                                                    (<> </>) :
                                                    (
                                                        <>
                                                            <Grid item xl={4} lg={5.5} md={4} sm={8} xs={9} className={`${globalStyles.globalHeadLeftSubItem}`}>
                                                                <Grid container>
                                                                    <Grid item className={`${globalStyles.globalSearchbarSubItem}`}>
                                                                        <GlobalSearch
                                                                            fullWidth
                                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSearch(e) }}
                                                                            size="small"
                                                                            placeholder="Search"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xl={4} lg={5.5} md={4.5} sm={3} xs={2} className={`${globalStyles.globalHeadRightSubItem} `}>
                                                                <Grid container alignItems={"center"}>

                                                                    {window.screen.width >= 900 &&
                                                                        <Grid item className={`${globalStyles.globalNewAddBtnItem}`}>
                                                                            <Button
                                                                                variant='outlined'
                                                                                size='large'
                                                                                fullWidth
                                                                                startIcon={<img src={createPersonaIcon} alt="" className={styles.createDocumentIconImg} />}
                                                                                className={globalStyles.addDocumentBtn}
                                                                                onClick={() => { navigate('/dashboard/campaigns/create') }}
                                                                            >
                                                                                Create New Campaign
                                                                            </Button>
                                                                        </Grid>

                                                                    }
                                                                    {window.screen.width <= 899 &&
                                                                        <IconButton
                                                                            className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                                                            onClick={() => {
                                                                                navigate('/dashboard/campaigns/create')
                                                                            }}
                                                                        >
                                                                            <AddIcon />
                                                                        </IconButton>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={`${styles.campaignsCardsMainItem}`}  >
                            <Grid container>
                                {campaignRender == true ?
                                    <>
                                        {(campaignOriginalDetails && campaignOriginalDetails?.length > 0) ? (
                                            campaignOriginalDetails?.map((campaign: any) => {
                                                return (
                                                    <>
                                                        <Grid item

                                                            className={`${styles.campaignsCards}`}
                                                            onClick={() => handleGetCampaignDetails(campaign.campaign_id)}
                                                            onMouseEnter={() => handleCardHover(campaign.campaign_id)}
                                                            onMouseLeave={handleCardLeave}
                                                        >
                                                            <Grid container>
                                                                <Grid item className={`${styles.cmpCardNameItem}`}>
                                                                    <Grid container justifyContent={"space-between"} alignItems={"flex-start"}>
                                                                        <Grid item className={`${styles.cmpCardIconItem}`}>
                                                                            <CardMedia
                                                                                component="img"
                                                                                className={styles.cmpCardIconMedia}
                                                                                image={logo}
                                                                                alt="icons"
                                                                            />
                                                                        </Grid>

                                                                        <Grid item>
                                                                            <IconButton
                                                                                size="small"
                                                                                className={styles.cmpDeleteIconButton}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setDeleteCampaignDetails(campaign)
                                                                                    setFlagDeleteCampaign(true)
                                                                                }}
                                                                            >
                                                                                <img src={deleteIcon} alt="delete" />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} className={`${styles.cmpCardIconName}`}>
                                                                    <Typography
                                                                        component={'span'}
                                                                        className={styles.cmpCardIconCampaignName}
                                                                    >
                                                                        {campaign?.campaign_name.length > 90 ? campaign.campaign_name.substring(0, 90) + "..." : campaign.campaign_name}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )
                                            })
                                        ) : (campaignOriginalDetails == null || campaignOriginalDetails == undefined && campaignRender == true ? (
                                            <>
                                                <Grid item xs={12} className={styles.noDataFoundItem}>
                                                    <Grid container className={styles.noDataFoundContainer}>
                                                        <Grid item>
                                                            <img src={noCampaignFound} alt="" />
                                                        </Grid>
                                                        <Grid item className={styles.noCampaignCreatedItem}>
                                                            <Typography component={'span'} className={styles.noCampaignCreatedText}>
                                                                No Campaign Created Yet! Start With a New Campaign
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} className={styles.creareNewCampaignItem}>
                                                            <Button
                                                                variant='outlined'
                                                                size='large'
                                                                className={globalStyles.globalRedirecetionBtn}
                                                                onClick={() => { navigate('/dashboard/campaigns/create') }}
                                                            >
                                                                Create New Campaign
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ) : (campaignOriginalDetails.length == 0 && campaignRender == true &&
                                            <>
                                                <Grid item xs={12} className={styles.noDataFoundItem}>
                                                    <Grid container className={styles.noDataFoundContainer}>
                                                        <Grid item>
                                                            <img src={noDataFound} alt="" />
                                                        </Grid>
                                                        <Grid item >
                                                            <Typography component={'span'} className={styles.noDataFoundText}>
                                                                No Data Found!
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                        )}
                                    </>
                                    :
                                    <></>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default Campaigns