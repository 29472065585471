import { Backdrop, Box, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import globalStyles from "../../assets/styles/global.module.scss";
import { useParams } from 'react-router-dom';
import { DocContext, DocContextPayload } from '../../context/docContext';
import GlobalButton from './globalButton/button';
import { UserContext, UserContextPayload } from '../../context/userContext';
import agent from '../../services/api';
import ParaphraserLoader from '../dashboard/paraphraser/ParaphraserLoader/ParaphraserLoader';
import { ParaphraseContext } from '../../context/paraphraseContext';

const GlobalRoundLoader = () => {
    const [currentActiveMenu, setCurrentActiveMenu] = React.useState("");
    const { currentActiveStep, currentActiveDocDetails, } = useContext(DocContext) as DocContextPayload;
    const { loadingURL, flagLoading } = useContext(UserContext) as UserContextPayload;
    React.useEffect(() => {
        (async () => {
            await setCurrentActiveMenu(window.location.pathname)
        })()

    }, [window.location.pathname])

    return (
        <>
            {flagLoading.flagRoundLoader &&
                <React.Fragment>
                    <Backdrop
                        className={globalStyles.backDropStyle}
                        sx={{
                            zIndex: 1500,
                            opacity: "0.2 !important"
                        }}
                        open={true}
                    >
                        <Box sx={{
                            display: 'flex',
                            justifyContent: "center",
                            alignItems: 'center',
                            flexDirection: 'column',
                            textAlign: 'center'
                        }}>
                            <CircularProgress color="inherit"
                                sx={{
                                    width: '50px !important',
                                    height: '50px !important'
                                }}
                            />
                            {(currentActiveMenu == "/dashboard/createPersona" || (currentActiveStep == 2 && currentActiveDocDetails?.template_type_name == "Blog-Generation")) &&
                                <>
                                    <Grid style={{ padding: "15px", fontSize: "20px" }}>
                                        Hang in there!
                                    </Grid>
                                    <Grid style={{ fontSize: "22px" }}>
                                        This may take a while
                                    </Grid>
                                </>}
                        </Box>
                    </Backdrop>
                </React.Fragment>
            }
        </>

    )
};

export default GlobalRoundLoader;