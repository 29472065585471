import { Divider, Grid, Paper, Tab, Tabs, Checkbox, useMediaQuery, FormControlLabel } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/paraphrasing.module.scss";
import { Typography } from '@mui/joy';
import { TemplateContext, TemplateContextPayload } from '../../../context/templateContext';
import GlobalButton from '../../utility/globalButton/button';
import agent from '../../../services/api';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { popUpActionDataModel } from '../../../models/axiosModel';
import { PersonaContext, PersonaContextPayload } from '../../../context/personaContext';
import IconsColor from '../../utility/iconsColor';
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import './paraphrasingCarousel.css'
import { ParaphraseContext, ParaphraseContextPayload } from '../../../context/paraphraseContext';
import Paraphrasing from './paraphrasing';
import WordChanger from './wordChangerTab';
import GlobalPopUp from '../../utility/globalPopUp';
import { getErrorMessage, isDev } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';

const Paraphraser = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const { GetPersonaWritingSkills, GetPersonaList } = useContext(PersonaContext) as PersonaContextPayload;
    const { paraphrasingVersioningArr, setParaphraseVersioningarr, currentIndex, setCurrentIndex, paraphrasingVersionIndex, setParaphrasingVersionIndex, showConfirmationPopup,
        setShowConfirmationPopup,
        popUpActionData,
        setPopupActionData,
        handleGetResetBtnDisableFlag,
        handleGetResetBtnRewordDisableFlag,
        handleResetWordChanger,
        currentTab, setCurrentTab,
        rewordVersioningArr,
        setRewordVersioningarr,
        rewordCurrentIndex,
        setrewordCurrentIndex,
    } = useContext(ParaphraseContext) as ParaphraseContextPayload;
    const { userDetail, flagLoading } = useContext(UserContext) as UserContextPayload;

    const { GetWorkDomainList, GetTemplateList } = React.useContext(TemplateContext) as TemplateContextPayload
    const { setError, error } = useContext(UserContext) as UserContextPayload;
    const mobileScreen = useMediaQuery('(min-width: 320px) and (max-width: 600px)');
    const laptopScreen = useMediaQuery('(min-width:1200px)')
    const [temp, setTemp] = useState(popUpActionData)
    useEffect(() => {
        setTemp(popUpActionData)
    }, [popUpActionData])
    useEffect(() => {
        let resetBtnDisable = handleGetResetBtnDisableFlag()
        if (!resetBtnDisable) {
            const handleBeforeUnload = (event: any) => {
                event.preventDefault();
                // Custom logic to handle the refresh
                // Display a confirmation message or perform necessary actions
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        } else {
            const handleBeforeUnload = (event: any) => {
                event.preventDefault();
            };
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, [paraphrasingVersioningArr]);
    let TabList = [

        {
            id: 1,
            tabName: "Paraphrase",
            icon: <div ><IconsColor fillColor={currentTab === "Paraphrase" ? "#6A097D" : "#676767"} iconName="paraphrasing" /></div>
        },
        {
            id: 2,
            tabName: "Word Changer",
            icon: <div ><IconsColor fillColor={currentTab === "Word Changer" ? "#6A097D" : "#676767"} iconName="word changer" width={"18px"} height={"18px"} /></div>
        },
    ]


    const resizeFunction = () => {
        document.querySelectorAll(".resizableTextArea").forEach((element) => {
            const textarea = element as HTMLTextAreaElement;
            textarea.style.height = 'auto';
            // textarea.style.height = textarea.scrollHeight + 10 + 'px';
            textarea.style.height = textarea.scrollHeight + 'px';

        });
    }
    useEffect(() => {
        resizeFunction()
    }, [paraphrasingVersioningArr]); // Empty

    React.useEffect(() => {
        setCurrentTab(urlParams.get('currentTab') || "Paraphrase");
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    { ...item, currentSubTab: urlParams.get('subTab') || 'Basic', } : item
            ));
        (async () => {
            await GetPersonaWritingSkills();
            await GetPersonaList()
            await GetWorkDomainList();
            await GetTemplateList()
        })()
    }, [])

    // Handlers
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        const searchParams = new URLSearchParams();
        searchParams.set('currentTab', newValue);
        window.history.replaceState({}, '', '?' + searchParams.toString());
        setCurrentTab(newValue);
    };


    const handleReset = () => {
        setParaphrasingVersionIndex(0)
        setCurrentIndex(0)
        setParaphraseVersioningarr(
            [
                {
                    id: 0,
                    index: 0,
                    currentSubTab: 'Basic',
                    selectedLanguage: "Same as Input",
                    selectedOutputCount: "3",
                    paraphrasingInput: "",
                    basicParaphrasingVal: {
                        tone_of_voice: [],
                        sentence_length: "Medium",
                    },
                    advanceParaphrasingVal: {
                        tone_of_voice: [],
                        narrative_style: "",
                        work_domain: "",
                        reading_ease: "",
                        layout: "",
                        sentence_length: "Medium",
                        dialect: ""
                    },
                    personaPayload: {
                        personaObj: "",
                        persona_id: "",
                        layout: "",
                        sentence_length: "Medium",
                    },
                    customPromptPayload: {
                        user_custom_prompt: "",
                    },
                    executePromptResponseParaphrase: []
                }
            ]
        )
    }

    const handleDecVersioning = (setState: any, state: any, currentIndex: number, setCurrentIndex: any) => {
        setState((prevState: any) =>
            prevState.map((item: any, index: number) =>
                index === (state.length - 1) ?
                    prevState[(currentIndex - 2)] : item
            ))
        setCurrentIndex((prevState: number) => prevState - 1)
    }

    const handleIncVersion = (setState: any, state: any, currentIndex: number, setCurrentIndex: any) => {
        setState((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index === (state.length - 1) ?
                    prevState[(currentIndex)] : item
            ))
        setCurrentIndex((prevState: any) => prevState + 1)
    }

    const handleCreateUserAction = async (currentPopupActionData: popUpActionDataModel) => {
        let payload = {
            module_type_id: currentPopupActionData.module_type_id,
            do_not_show_flag: true
        }
        const response = await agent.createUserPopupAction.post(payload);
        if (response.status) {
            setPopupActionData((prevState: popUpActionDataModel[]) => prevState.map((popupActionData: popUpActionDataModel) => popupActionData.module_type_id == currentPopupActionData.module_type_id ? { ...popupActionData, do_not_show_flag: true } : popupActionData))
            if (currentTab == "Paraphrase") {
                handleReset();
            } else {
                handleResetWordChanger();
            }
            setShowConfirmationPopup(false)
        } else {
            setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(response?.error?.code, isDev(userDetail)),
            });
        }
    }

    return (
        <>
            {showConfirmationPopup &&
                <GlobalPopUp
                    borderRadius='20px'
                    open={showConfirmationPopup}
                    onClose={() => { setShowConfirmationPopup(false) }}
                    title={"Delete Text"}
                    flagShowCloseIcon={true}
                    handleClose={() => { setShowConfirmationPopup(false) }}
                >
                    <Grid container sx={{ padding: "0px 20px 20px" }} direction="column" justifyContent={"space-between"} alignItems={"start"}>
                        <Grid item sx={{ marginBottom: "30px", minWidth: "150px", fontSize: "15px", fontWeight: "500", textAlign: "center" }}>
                            All your progress will be lost, do you want to continue?
                        </Grid>
                        <Grid item sx={{ width: "100%" }}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item sx={{ display: "flex" }}>
                                    <FormControlLabel
                                        label="Don't show again"
                                        sx={{
                                            marginRight: "0px !important",
                                            marginLeft: "0px !important",
                                            "& .MuiTypography-root ": {

                                                color: " #6A097D !important",
                                                fontSize: "15px !important",
                                                fontWeight: "600 !important",
                                                textTransform: "none !important",
                                                paddingLeft: "5px"
                                            },
                                            '& .MuiCheckbox-root': {
                                                paddingRight: "5px !important", padding: "0px !important"
                                            },

                                        }}
                                        control={
                                            <Checkbox
                                                value={temp}
                                                onChange={(e) => {
                                                    setTemp((prevState: popUpActionDataModel[]) =>
                                                        prevState.map((popupData: popUpActionDataModel) =>
                                                            popupData.module_type_name.includes(currentTab) ? { ...popupData, do_not_show_flag: e.target.checked } :
                                                                popupData))
                                                }}
                                            />
                                        }
                                    />
                                </Grid>

                                <Grid item className={globalStyles.displayFlex}>
                                    <GlobalButton
                                        variant="text"
                                        buttonText={"Continue"}
                                        className="textVariantStyle"
                                        onClick={() => {
                                            let currentPopupActionData = temp.find((popupActionData) => popupActionData.module_type_name.includes(currentTab));
                                            if (currentPopupActionData) {
                                                if (currentPopupActionData.do_not_show_flag) {
                                                    handleCreateUserAction(currentPopupActionData)
                                                } else {
                                                    if (currentTab == "Paraphrase") {
                                                        handleReset();
                                                    } else {
                                                        handleResetWordChanger()
                                                    }
                                                    setShowConfirmationPopup(false)
                                                }
                                            }
                                        }
                                        }
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </GlobalPopUp>
            }
            <Grid item xs={12} className={`${styles.paraphrasingMainItem}`}>
                <Grid container className={globalStyles.containerFlexWrap} direction={"column"}>
                    {/* HEADER */}
                    {mobileScreen ? (<></>) : (
                        <>
                            <Grid item xs={12} sx={{ mb: "15px" }}>
                                <Grid container justifyContent={"space-between"} sx={{ alignItems: "center" }}>
                                    <Grid item
                                        sx={{ padding: { xs: "0px 20px", sm: "0px" }, margin: { xs: "10px 0px 0px", sm: "0px" } }}>
                                        <GlobalPageHeaderText
                                            mainHeadText={"Paraphraser"}
                                        // mainSubText={"Effortlessly paraphrase your content with versatile output preferences"}
                                        />
                                    </Grid >
                                    <Grid item
                                        // xl={6} lg={4} md={3} sm={12} xs={12} 
                                        sx={{ marginTop: { xs: "0px", sm: "10px", md: "0px" } }}>
                                        <Grid gap="15px" container sx={{ justifyContent: { xs: "start", sm: "start", md: "end" }, padding: { xs: "0px 20px", sm: "0px" }, alignItems: "center" }}>
                                            {currentTab == "Paraphrase" ?
                                                !handleGetResetBtnDisableFlag() &&
                                                <Grid item>
                                                    <GlobalButton
                                                        variant="text"
                                                        buttonText={"Reset"}
                                                        className="textVariantStyle"
                                                        onClick={() =>
                                                        // handleReset()
                                                        {
                                                            if (userDetail.flagUser) {
                                                                let currentPopupActionData = temp.find((popupActionData) => popupActionData.module_type_name.includes(currentTab));
                                                                if (currentPopupActionData?.do_not_show_flag) {
                                                                    handleReset()
                                                                } else {
                                                                    setShowConfirmationPopup(true)
                                                                }
                                                            } else {
                                                                handleReset()
                                                            }
                                                        }
                                                        } disabled={handleGetResetBtnDisableFlag() || flagLoading.flagContentGenerating}
                                                    />
                                                </Grid>
                                                :
                                                !handleGetResetBtnRewordDisableFlag() &&
                                                <Grid item>
                                                    <GlobalButton
                                                        variant="text"
                                                        buttonText={"Reset"}
                                                        className="textVariantStyle"
                                                        onClick={() => {
                                                            if (userDetail.flagUser) {
                                                                let currentPopupActionData = temp.find((popupActionData) => popupActionData.module_type_name.includes(currentTab));
                                                                if (currentPopupActionData?.do_not_show_flag) {
                                                                    handleResetWordChanger()
                                                                } else {
                                                                    setShowConfirmationPopup(true)
                                                                }
                                                            } else {
                                                                handleResetWordChanger()
                                                            }
                                                        }
                                                        }
                                                        disabled={handleGetResetBtnRewordDisableFlag()}
                                                    />
                                                </Grid>

                                            }
                                            {
                                                (userDetail.flagUser && !userDetail.flag_user_anonymous && userDetail.flag_email_verified && userDetail.plan_detail?.credit_usage_data?.stripe_product_name === "Free") &&
                                                <Grid item>
                                                    <GlobalButton buttonText={"Upgrade to Premium"} className="headerUpgradeButton" flagUpgrade
                                                        onClick={() => navigate('/dashboard/settings-plansAndBilling')} />
                                                </Grid>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}
                            // sx={{ mb: "15px" }}
                            >
                                <Divider sx={{ color: "#DFDFDF", opacity: "0.20", padding: "0px" }} />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} className={styles.ppMainComponentWrapper}>
                        <Grid container direction="column" className={`${styles.ppMainComponentWrapperContainer} ${globalStyles.containerFlexWrap}`}>
                            <Grid item >
                                <Grid container className={globalStyles.containerflexWrapStyle} justifyContent={'end'} alignItems={'center'}>

                                    {mobileScreen ?
                                        (<></>)
                                        :
                                        (
                                            <Grid item sx={{ width: "100%", }}>
                                                <Grid container gap={"1rem"} justifyContent={"space-between"} alignItems={"center"} sx={{ flexWrap: "nowrap" }}>
                                                    <Grid item xs={6} sx={{ width: "100%", minWidth: { sm: "100%", md: "unset" } }}>
                                                        <Tabs
                                                            className={styles.ppTabs}
                                                            value={currentTab}
                                                            onChange={handleChangeTab}
                                                            textColor="secondary"
                                                            indicatorColor="secondary"
                                                            aria-label="secondary tabs example"
                                                        >
                                                            {TabList.map((tabDetail, i) => (
                                                                <Tab icon={tabDetail.icon} iconPosition="start" className={`${styles.tabStyle}`} key={tabDetail.id} value={tabDetail.tabName} label={tabDetail.tabName} />
                                                            ))}
                                                        </Tabs>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{ textAlign: "center" }}>

                                                        {currentTab == "Paraphrase" && (paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length == 0 && laptopScreen && !flagLoading.flagContentGenerating) &&
                                                            <div style={{ fontSize: "16px", fontWeight: "700", color: "#000", fontStyle: "italic", textAlign: "center", }}>
                                                                Why are we the Best Paraphrasing Tool?
                                                            </div>}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}

                                    <Grid item className={styles.ppClearButton} >
                                        <Grid container gap={"15px"} alignItems={"center"} justifyContent={"end"}>
                                            {/* pagination */}
                                            {!mobileScreen ?
                                                (
                                                    <>
                                                        {currentTab == "Paraphrase" ?
                                                            <>
                                                                {paraphrasingVersioningArr.length > 2 &&
                                                                    <Grid item
                                                                    //  sx={{ margin: "0px 0px 10px" }}
                                                                    >
                                                                        <Grid className={globalStyles.containerflexWrapStyle} container alignItems={"center"} justifyContent={"end"} >
                                                                            <IconsColor iconName="decIndex" className={currentIndex !== 1 && (!flagLoading.flagContentGenerating) ? globalStyles.clickable : ""}
                                                                                width="8"
                                                                                height="13px"
                                                                                fillColor={currentIndex !== 1 && (!flagLoading.flagContentGenerating) ? "#000" : "#7A7A7A"}
                                                                                onClick={() => {
                                                                                    currentIndex !== 1 && (!flagLoading.flagContentGenerating) &&
                                                                                        handleDecVersioning(setParaphraseVersioningarr, paraphrasingVersioningArr, currentIndex, setCurrentIndex)
                                                                                }}
                                                                            />

                                                                            <Grid item sx={{ margin: "0px 4px", }}>
                                                                                <Typography component={'span'} sx={{ color: "#7A7A7A", fontSize: "16px", fontWeight: "500" }}>
                                                                                    {currentIndex}/{paraphrasingVersioningArr.length - 1}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <IconsColor iconName="IncIndex" className={(currentIndex) !== (paraphrasingVersioningArr.length - 1) && (!flagLoading.flagContentGenerating) ? globalStyles.clickable : ""}
                                                                                width="8"
                                                                                height="13px"
                                                                                fillColor={(currentIndex) !== (paraphrasingVersioningArr.length - 1) && (!flagLoading.flagContentGenerating) ? "#000" : "#7A7A7A"}
                                                                                onClick={() => {
                                                                                    (currentIndex) !== (paraphrasingVersioningArr.length - 1) && (!flagLoading.flagContentGenerating) &&
                                                                                        handleIncVersion(setParaphraseVersioningarr, paraphrasingVersioningArr, currentIndex, setCurrentIndex)
                                                                                }}
                                                                            />

                                                                        </Grid>
                                                                    </Grid>}
                                                            </>
                                                            :
                                                            <>
                                                                {rewordVersioningArr.length > 2 &&
                                                                    <Grid item
                                                                    //  sx={{ margin: "0px 0px 10px" }}
                                                                    >
                                                                        <Grid className={globalStyles.containerflexWrapStyle} container alignItems={"center"} justifyContent={"end"} >
                                                                            <IconsColor iconName="decIndex" className={rewordCurrentIndex !== 1 ? globalStyles.clickable : ""}
                                                                                width="8"
                                                                                height="13px"
                                                                                fillColor={rewordCurrentIndex !== 1 ? "#000" : "#7A7A7A"}
                                                                                onClick={() => {
                                                                                    rewordCurrentIndex !== 1 &&
                                                                                        handleDecVersioning(setRewordVersioningarr, rewordVersioningArr, rewordCurrentIndex, setrewordCurrentIndex)
                                                                                }}
                                                                            />

                                                                            <Grid item sx={{ margin: "0px 4px", }}>
                                                                                <Typography component={'span'} sx={{ color: "#7A7A7A", fontSize: "16px", fontWeight: "500" }}>
                                                                                    {rewordCurrentIndex}/{rewordVersioningArr.length - 1}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <IconsColor iconName="IncIndex" className={(rewordCurrentIndex) !== (rewordVersioningArr.length - 1) ? globalStyles.clickable : ""}
                                                                                width="8"
                                                                                height="13px"
                                                                                fillColor={(rewordCurrentIndex) !== (rewordVersioningArr.length - 1) ? "#000" : "#7A7A7A"}
                                                                                onClick={() => {
                                                                                    (rewordCurrentIndex) !== (rewordVersioningArr.length - 1) &&
                                                                                        handleIncVersion(setRewordVersioningarr, rewordVersioningArr, rewordCurrentIndex, setrewordCurrentIndex)
                                                                                }}
                                                                            />

                                                                        </Grid>
                                                                    </Grid>}
                                                            </>
                                                        }
                                                    </>
                                                ) : (<></>)
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <>
                                {currentTab == "Paraphrase" ?
                                    <Paraphrasing />
                                    :
                                    <>
                                        <WordChanger />
                                    </>
                                }
                            </>
                        </Grid>
                    </Grid >
                </Grid >
            </Grid >

        </>
    )
}

export default Paraphraser
