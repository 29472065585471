import * as React from 'react';
import { useEffect } from 'react';
import { Autocomplete, Avatar, Box, Divider, Grid, MenuItem, Popover, Popper, Select, TextField, styled } from '@mui/material';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Sidebar from '../../templateComponents/sidebarComponent';
import TemplateHeader from '../../templateComponents/templateHeaderComponent';
import FormPreview from '../../templateComponents/generateTitleGlobalFormPreview';
import shine from "../../../assets/shine.png"
import globalStyles from "../../../assets/styles/global.module.scss"
import styles from "../../../assets/styles/componentStyles/formLayout.module.scss"
import { DocContext, DocContextPayload } from '../../../context/docContext';
import OptionListComponent from '../../templateComponents/optionListComponent';
import OutlineSectionHeadingComponent from '../../outlineSectionHeading';
import OptionListMobileComponent from '../../templateComponents/optionListMobileComponent';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { PersonaContext, PersonaContextPayload } from '../../../context/personaContext';
import ClearIcon from '@mui/icons-material/Clear';
import personaPremiumIcon from "../../../assets/personaCrown.svg";
import personaAvatar from "../../../assets/personaAvatar.svg";
import { Tooltip, Typography } from '@mui/joy';
const FormLayout = () => {


    const StyledPopper = styled(Popper)(({ theme }) => ({
        '& .MuiListSubheader-root': {
            paddingLeft: "12px"
        },

        '& .MuiAutocomplete-option': {
            paddingLeft: "4px !important",
            paddingRight: "4px !important"
        },
    }))

    const navigate = useNavigate()
    const { currentActiveStep, setCurrentActiveStep, flagShowGenerateTitlesButton, flagGenerateTitleButtonClicked, setFlagGenerateTitleButtonClicked, setFlagShowGenerateTitlesButton, currentActiveDocDetails, aiSuggestedTitles, UpdateDocInfo } = React.useContext(DocContext) as DocContextPayload;
    const { personaList, GetPersonaList } = React.useContext(PersonaContext) as PersonaContextPayload;
    const { userDetail } = React.useContext(UserContext) as UserContextPayload

    const [selectedPersona, setSelectedPersona] = React.useState<any>(null);



    // React.useEffect(() => {
    //     if (currentActiveDocDetails?.document_title?.length) {
    //         setFlagShowGenerateTitlesButton(false)
    //         setFlagGenerateTitleButtonClicked(true)
    //     } else {
    //         setFlagShowGenerateTitlesButton(true)
    //         setFlagGenerateTitleButtonClicked(false)
    //     }
    // }, [currentActiveDocDetails])

    // React.useEffect(() => {
    //     console.log("33333flagShowGenerateTitlesButton", flagShowGenerateTitlesButton)
    //     console.log("33333flagGenerateTitleButtonClicked", flagGenerateTitleButtonClicked)

    // }, [flagGenerateTitleButtonClicked, flagShowGenerateTitlesButton])


    React.useEffect(() => {
        if (personaList == null) {
            GetPersonaList()
        }
    }, [])

    useEffect(() => {
        getPersonaInitialVal()
    }, [currentActiveDocDetails])

    React.useEffect(() => {
        if (currentActiveDocDetails?.document_title) {
            // if document title is there we dont need to show the initial   Start generating now and let your words make a
            // lasting impact!
            setFlagGenerateTitleButtonClicked(true)
        } else {
            setFlagGenerateTitleButtonClicked(false)
        }
    }, [currentActiveDocDetails])

    // const getPersonaInitialVal = () => {
    //     if (currentActiveDocDetails) {
    //         if (currentActiveDocDetails.document_persona_id) {
    //             if (personaList) {
    //                 setSelectedPersona(personaList.filter((persona) => persona.persona_id == currentActiveDocDetails.document_persona_id)[0])
    //                 // return personaList.filter((persona) => persona.persona_id == currentActiveDocDetails.document_persona_id)
    //             } else {
    //                 setSelectedPersona(null)
    //                 return ""
    //             }
    //         } else {
    //             const defaultPersona = personaList?.find((persona) => persona.user_default_persona_data.find((personaData) => personaData.default_persona_type_name == "General"));
    //             const personaId = defaultPersona?.persona_id ?? null;
    //             if (personaId) {
    //                 if (personaList) {
    //                     setSelectedPersona(personaList.filter((persona) => persona.persona_id == personaId)[0])
    //                     // return personaList.filter((persona) => persona.persona_id == userDetail.default_persona_id)
    //                 } else {
    //                     setSelectedPersona(null)
    //                     return ""
    //                 }
    //             } else {
    //                 setSelectedPersona(null)
    //                 return ""
    //             }
    //         }
    //     } else {
    //         return ""
    //     }
    // }

    const getPersonaInitialVal = () => {
        if (currentActiveDocDetails) {
            if (currentActiveDocDetails.document_persona_id) {
                if (personaList) {
                    let tt = personaList.filter((persona) => persona.persona_id == currentActiveDocDetails.document_persona_id)[0]
                    setSelectedPersona(tt)
                } else {
                    setSelectedPersona(null)
                    return ""
                }
            }
            else {
                const defaultPersona = personaList?.find((persona) => persona.user_default_persona_data.find((personaData) => personaData.default_persona_type_name == "General"));
                const personaId = defaultPersona?.persona_id ?? null;

                if (personaId) {
                    if (personaList) {
                        setSelectedPersona(personaList.filter((persona) => persona.persona_id == personaId)[0])
                        // return personaList.filter((persona) => persona.persona_id == userDetail.default_persona_id)
                    } else {
                        setSelectedPersona(null)
                        return ""
                    }
                } else {
                    setSelectedPersona(null)
                    return ""
                }
            }
        } else {
            return ""
        }
    }



    const handleChange = async (event: any) => {
        setSelectedPersona(event);
        // console.log("evemt", event)
        // if (event == null) {
        //     setSelectedPersona(event);
        // } else {
        //     setSelectedPersona(event.persona_name);
        // }
        const payload = {
            document_uuid: currentActiveDocDetails?.document_uuid ? currentActiveDocDetails?.document_uuid : "",
            document_persona_id: event == null ? null : event.persona_id
        }
        const response = await UpdateDocInfo(payload)
    };


    return (
        <>

            <Grid container sx={{ width: "100% !important", margin: "0px !important", flexWrap: "nowrap", height: "auto", maxHeight: "100%" }} >
                <Grid item sx={{
                    display: { xs: "none", md: "block" },
                    // height:"calc(100vh - 64px)", 
                    height: "auto",
                    minHeight: "calc(100vh - 64px)"
                }}>
                    <Sidebar />
                </Grid>

                <Grid item className={`${globalStyles.fullwidth} ${styles.setUpContextWrapper}`}>
                    <Grid container sx={{ height: "100%", flexWrap: "nowrap" }} direction={"column"}>
                        <Grid item sx={{ display: { xs: "none", md: "block" } }} >
                            <TemplateHeader nextStepButtonClassname={"secondaryButtonStyle"} nextStepText={"Next: Sub headings & Paragraphs"} />
                        </Grid>
                        {/* <Outlet /> */}
                        {currentActiveStep == 1 ?
                            <Grid item className={globalStyles.fullHeight}>
                                <Grid container justifyContent={"space-between"} sx={{ height: "100%", flexWrap: "nowrap" }}>
                                    <Grid item className={globalStyles.fullHeight} xs={12} sm={12} md={6} sx={{ paddingRight: { xs: "0px", md: "35px" }, display: { xs: "none", md: "block" } }}>
                                        <Box>
                                            <Grid item className={styles.personaContainer}>
                                                <Grid container className={globalStyles.containerflexWrapStyle} alignItems={"center"}>
                                                    <Grid item className={styles.personaTextStyle}>Persona:</Grid>
                                                    <Grid item className={globalStyles.fullwidth}>
                                                        <Autocomplete
                                                            // getOptionLabel={(option: any) => option.persona_name ? option.persona_name : "Select Persona"}
                                                            options={personaList || []}
                                                            value={selectedPersona}
                                                            getOptionLabel={(option) =>
                                                                option.persona_name
                                                                    ? `${option.persona_name}, ${option.persona_display_name}`
                                                                    : "Select Persona"
                                                            }
                                                            filterOptions={(options, { inputValue }) => {

                                                                const filteredOptions = options.filter((option) => {
                                                                    const personaName = option.persona_name || '';
                                                                    const subCategoryName = option.persona_sub_category__name || '';

                                                                    return (
                                                                        personaName.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                                        subCategoryName.toLowerCase().includes(inputValue.toLowerCase())
                                                                    );
                                                                });

                                                                return filteredOptions;
                                                            }}
                                                            className={styles.personaDropdown}
                                                            getOptionDisabled={(option) =>
                                                                option.persona_premium_flag && ((userDetail.plan_detail && userDetail.plan_detail.credit_usage_data !== null && userDetail?.plan_detail?.credit_usage_data.stripe_product_name == "Free") || userDetail.flag_user_anonymous)
                                                            }
                                                            groupBy={(option) => option.persona_sub_category__name}
                                                            renderOption={(props, option) => (
                                                                <li {...props} >
                                                                    <Tooltip
                                                                        sx={{ backgroundColor: "unset !important", padding: "0 !important" }}
                                                                        className={styles.PersonaDescpTooltip}
                                                                        title={<>
                                                                            <Grid container className={styles.personaMenuContainer} alignItems={"start"}>
                                                                                <Grid item>
                                                                                    {/* <img src={option.persona_avatar_url ? option.persona_avatar_url : personaAvatar} className={styles.personaAvatarStyle} /> */}
                                                                                    <img
                                                                                        src={option.persona_avatar_full_size_url ?? option.persona_avatar_url ?? personaAvatar}
                                                                                        // src={option.persona_avatar_full_size_url !== null ? option.persona_avatar_full_size_url : option.persona_avatar_url ? option.persona_avatar_url : personaAvatar}
                                                                                        className={styles.personaAvatarStyle}
                                                                                    />

                                                                                </Grid>
                                                                                <Grid item className={styles.leftBlock}>
                                                                                    <Grid container direction={"column"} >
                                                                                        <Grid item className={styles.personaNameStyle}>{option.persona_name}</Grid>
                                                                                        <Grid item className={styles.PersonaDisplayNameStyle}>{option.persona_display_name}</Grid>
                                                                                        <Grid item className={styles.personaSummaryStyle}>{option.about_persona} </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>}
                                                                        placement="right"
                                                                    >
                                                                        <li {...props}
                                                                            style={{
                                                                                paddingLeft: "0px",
                                                                                paddingRight: "0px",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                fontSize: "15px",
                                                                                fontWeight: "500",
                                                                                width: "100%"
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{ pointerEvents: "none", display: "flex" }}>
                                                                                <img
                                                                                    style={{ width: "37px", height: "37px", borderRadius: "50%", marginRight: "10px" }}
                                                                                    src={option.persona_avatar_full_size_url ?? option.persona_avatar_url ?? personaAvatar}

                                                                                // src={option.persona_avatar_full_size_url !== null ? option.persona_avatar_full_size_url : option.persona_avatar_url ? option.persona_avatar_url : personaAvatar}
                                                                                // src={option.persona_avatar_url ? option.persona_avatar_url : personaAvatar}
                                                                                />
                                                                            </div>

                                                                            <div style={{ width: "100%", whiteSpace: "pre-wrap", pointerEvents: "none" }}>
                                                                                {option.persona_display_name ? `${option.persona_name}, ${option.persona_display_name}` : option.persona_name}
                                                                            </div>

                                                                            {option.persona_premium_flag &&
                                                                                <div style={{ width: "100%", display: "flex", justifyContent: "end", pointerEvents: "none", maxWidth: "25px" }}>
                                                                                    <img src={personaPremiumIcon} style={{ width: "25px", height: "auto" }} />
                                                                                </div>
                                                                            }
                                                                        </li>
                                                                    </Tooltip>
                                                                </li>
                                                            )}
                                                            // clearIcon={selectedPersona ? <ClearIcon /> : <span />}
                                                            onChange={(_, newValue) => handleChange(newValue)}
                                                            renderInput={(params) => (
                                                                <>
                                                                    <TextField
                                                                        placeholder='Select Persona'
                                                                        {...params}
                                                                        variant="standard"
                                                                        className={styles.personaDropdown}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            startAdornment: (
                                                                                <>
                                                                                    {selectedPersona && (
                                                                                        <Avatar
                                                                                            // src={selectedPersona.persona_avatar_url && selectedPersona.persona_avatar_url}
                                                                                            src={selectedPersona.persona_avatar_full_size_url ?? selectedPersona.persona_avatar_url ?? personaAvatar}
                                                                                            alt="Selected Persona"
                                                                                            style={{ width: "30px", height: "30px", marginRight: "10px" }}
                                                                                        />
                                                                                    )}
                                                                                </>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </>
                                                            )}
                                                        />

                                                        {/* <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            variant="standard"
                                                            className={styles.personaDropdown}
                                                            value={selectedPersona}
                                                            onChange={handleChange}
                                                        // onBlur={handleUpdateDocPersona}
                                                        >
                                                            <MenuItem className={styles.personaDropdownMenuItem} value={0}>
                                                                None
                                                            </MenuItem>
                                                            {personaList?.map((persona: any) => (
                                                                <MenuItem className={styles.personaDropdownMenuItem} value={persona.persona_id}>{persona.persona_name}</MenuItem>
                                                            ))}
                                                        </Select> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <FormPreview
                                            buttonText={currentActiveDocDetails?.document_title ? "Generate More Titles" : "Generate Titles"}
                                            // flagShowGenerateTitlesButton={true}
                                            flagShowGenerateTitlesButton={aiSuggestedTitles[0].title == "" ? true : false} // if there are options of titles we will only show regenerate titles instead of generate
                                        />
                                    </Grid>
                                    <Grid item sx={{ display: { xs: "none", md: "block" } }}>
                                        <Divider className={globalStyles.dividerStyle} orientation='vertical' />
                                    </Grid>
                                    <Grid item xs={6} className={styles.leftBlock} sx={{ paddingLeft: "35px", display: { xs: "none", md: "block" } }}>
                                        {(flagGenerateTitleButtonClicked == false) ?
                                            <Grid container justifyContent={"center"} alignItems={"center"} sx={{ height: "100%" }}>
                                                <Grid item className={globalStyles.globalTextStyleGrey} sx={{ maxWidth: "355px" }}>
                                                    Start generating now and let your words make a
                                                    lasting impact!
                                                    <img src={shine} style={{ width: "20px", height: "20px" }} />
                                                </Grid>

                                            </Grid>
                                            :
                                            <OptionListComponent />
                                        }
                                    </Grid>
                                    {flagGenerateTitleButtonClicked == true
                                        ?
                                        <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
                                            <OptionListMobileComponent />
                                        </Grid>
                                        :
                                        <Grid item xs={12} sm={12} sx={{ display: { xs: "block", md: "none" } }}>
                                            <Box>
                                                <Grid item className={styles.personaContainer}>
                                                    <Grid container className={globalStyles.containerflexWrapStyle} alignItems={"center"}>
                                                        <Grid item className={styles.personaTextStyle}>Persona:</Grid>
                                                        <Grid item className={globalStyles.fullwidth}>
                                                            <Autocomplete
                                                                PopperComponent={StyledPopper}
                                                                options={personaList || []}
                                                                // getOptionLabel={(option: any) => option.persona_name ? option.persona_name : "Select Persona"}
                                                                value={selectedPersona}
                                                                className={styles.personaDropdown}
                                                                getOptionDisabled={(option) =>
                                                                    option.persona_premium_flag && ((userDetail.plan_detail && userDetail.plan_detail.credit_usage_data !== null && userDetail?.plan_detail?.credit_usage_data.stripe_product_name == "Free") || userDetail.flag_user_anonymous)
                                                                }
                                                                getOptionLabel={(option) =>
                                                                    option.persona_name
                                                                        ? `${option.persona_name}, ${option.persona_display_name}`
                                                                        : "Select Persona"
                                                                }
                                                                filterOptions={(options, { inputValue }) => {
                                                                    const filteredOptions = options.filter((option) => {
                                                                        const personaName = option.persona_name || '';
                                                                        const subCategoryName = option.persona_sub_category__name || '';

                                                                        return (
                                                                            personaName.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                                            subCategoryName.toLowerCase().includes(inputValue.toLowerCase())
                                                                        );
                                                                    });

                                                                    return filteredOptions;
                                                                }}
                                                                groupBy={(option) => option.persona_sub_category__name}
                                                                renderOption={(props, option) => (
                                                                    <li {...props} style={{ width: "100%" }}>
                                                                        <Tooltip
                                                                            sx={{ backgroundColor: "unset !important", padding: "0 !important" }}
                                                                            className={styles.PersonaDescpTooltip}
                                                                            title={<>
                                                                                <Grid container className={styles.personaMenuContainer} alignItems={"start"}>
                                                                                    <Grid item>
                                                                                        {/* <img src={option.persona_avatar_url ? option.persona_avatar_url : personaAvatar} className={styles.personaAvatarStyle} /> */}
                                                                                        src={option.persona_avatar_full_size_url ?? option.persona_avatar_url ?? personaAvatar}

                                                                                        {/* <img src={option.persona_avatar_full_size_url !== null ? option.persona_avatar_full_size_url : option.persona_avatar_url ? option.persona_avatar_url : personaAvatar} /> */}
                                                                                    </Grid>
                                                                                    <Grid item className={styles.leftBlock}>
                                                                                        <Grid container direction={"column"}>
                                                                                            <Grid item className={styles.personaNameStyle}>{option.persona_name}</Grid>
                                                                                            <Grid item className={styles.PersonaDisplayNameStyle}>{option.persona_display_name}</Grid>
                                                                                            <Grid item className={styles.personaSummaryStyle}>{option.about_persona} </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>}
                                                                            placement="right"
                                                                        >

                                                                            <li {...props}
                                                                                style={{
                                                                                    paddingLeft: "4px !important",
                                                                                    paddingRight: "4px !important",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    fontSize: "15px",
                                                                                    fontWeight: "500",
                                                                                    width: "100%",
                                                                                    // justifyContent: "space-between",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{ pointerEvents: "none", display: "flex" }}>
                                                                                    <img
                                                                                        style={{ width: "37px", height: "37px", borderRadius: "50%", marginRight: "10px" }}
                                                                                        //  src={option.persona_avatar_url ? option.persona_avatar_url : personaAvatar} 
                                                                                        src={option.persona_avatar_full_size_url ?? option.persona_avatar_url ?? personaAvatar}

                                                                                    // src={option.persona_avatar_full_size_url !== null ? option.persona_avatar_full_size_url : option.persona_avatar_url ? option.persona_avatar_url : personaAvatar}
                                                                                    />
                                                                                </div>

                                                                                <div style={{ display: "flex", gap: "15px", width: "100%" }}>

                                                                                    <div style={{ width: "100%", whiteSpace: "pre-wrap", pointerEvents: "none", display: "flex", }}>
                                                                                        {option.persona_display_name ? `${option.persona_name}, ${option.persona_display_name}` : option.persona_name}
                                                                                    </div>

                                                                                    {option.persona_premium_flag ?
                                                                                        (<div style={{ width: "100%", display: "flex", justifyContent: "end", pointerEvents: "none", maxWidth: "25px" }}>
                                                                                            <img src={personaPremiumIcon} style={{ width: "25px", height: "auto" }} />
                                                                                        </div>) : (
                                                                                            <div style={{ width: "100%", display: "flex", justifyContent: "end", pointerEvents: "none", maxWidth: "25px" }}>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </li>
                                                                        </Tooltip>
                                                                    </li>
                                                                )}
                                                                // clearIcon={selectedPersona ? <ClearIcon /> : <span />}
                                                                onChange={(_, newValue) => handleChange(newValue)}
                                                                renderInput={(params) => (
                                                                    <>
                                                                        <TextField
                                                                            placeholder='Select Persona'
                                                                            {...params}
                                                                            variant="standard"
                                                                            className={styles.personaDropdown}
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                startAdornment: (
                                                                                    <>
                                                                                        {selectedPersona && (
                                                                                            <Avatar
                                                                                                // src={selectedPersona.persona_avatar_url && selectedPersona.persona_avatar_url}
                                                                                                src={selectedPersona.persona_avatar_full_size_url ?? selectedPersona.persona_avatar_url ?? personaAvatar}
                                                                                                alt="Selected Persona"
                                                                                                style={{ width: "30px", height: "30px", marginRight: "10px" }}
                                                                                            />
                                                                                        )}
                                                                                    </>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <FormPreview
                                                buttonText="Generate Titles"
                                                flagShowGenerateTitlesButton={true}
                                                flagMobile
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            :
                            currentActiveStep == 2 ?
                                <OutlineSectionHeadingComponent />
                                : ""
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default FormLayout;