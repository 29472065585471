import * as React from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../layout/header/header';
import { UserContext, UserContextPayload } from '../../context/userContext';
import FormLayout from '../layout/header/formLayout';
import { DocContext, DocContextPayload } from '../../context/docContext';
import PlaygroundApp from '../Editor/App';
import BlogEditor from './Editor';
import { MobileFooter } from '../layout/mobileFooter';
import globalStyles from "../../assets/styles/global.module.scss"
import { PersonaContext, PersonaContextPayload } from '../../context/personaContext';
import SideNavigationPanel from '../layout/header/sideNavigationPanel';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import IconsColor from '../utility/iconsColor';
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, isDev } from '../../utils/constants';

export const GetComponentToRender = (props: any) => {
    if (props.currentActiveDocDetails) {
        if (props.currentActiveDocDetails.template_type_name == "Blank" || props.currentActiveDocDetails.document_html_body_flag || props.currentActiveDocDetails.document_step_data[0].template_step_seq_num == 2
        ) {
            return <PlaygroundApp />
        } else if (props.currentActiveDocDetails.template_type_name == "Single-Page") {
            return <BlogEditor />
        } else {
            return (props.ActiveStep == "3" ?
                <BlogEditor />
                :
                <FormLayout />)
        }
    } else {
        return <></>
    }
}
interface MenuItem {
    menuName: string;
    menuKey: string;
    navigationLink: string;
    imgSrc: React.ReactElement;
    whiteImgSrc: React.ReactElement;
    coloredImgSrc: React.ReactElement;
    flagDisabled?: boolean
}

const DocComponent = () => {
    const navigate = useNavigate()
    const { userDetail, setFlagLoading, flagLoading, setFlagShowCreditsExhausted, setUserDetail, setError, error } = React.useContext(UserContext) as UserContextPayload;
    const { GetDocDetails, currentActiveDocDetails, currentActiveStep, GetDocumentContentTypeData, documentEditorData, setCurrentActiveStep, setFlagShowGenerateTitlesButton, setBlogTitle, documentOutlineData } = React.useContext(DocContext) as DocContextPayload;
    const { GetPersonaList } = React.useContext(PersonaContext) as PersonaContextPayload
    const { docId, ActiveStep } = useParams();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const publicMenuItems = [
        {
            menuName: "Home",
            menuKey: "home",
            navigationLink: "/dashboard",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="home" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="home" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="home" />
        },
        {
            menuName: "Paraphraser",
            menuKey: "paraphraser",
            navigationLink: "/dashboard/paraphraser",
            flagDisabled: false,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="paraphrasing" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="paraphrasing" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="paraphrasing" />,
        },
        {
            menuName: "Create Content",
            menuKey: "templates",
            navigationLink: "/dashboard/templates",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="templates" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="templates" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="templates" />
        },

        // {
        //     menuName: "Documents",
        //     menuKey: "documents",
        //     navigationLink: "/dashboard/documents",
        //     flagDisabled: userDetail.flagUser ? false : true,
        //     imgSrc: <IconsColor fillColor="#4C4A4D" iconName="document" />,
        //     whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="document" />,
        //     coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="document" />
        // },
        {
            menuName: "Persona",
            menuKey: "persona",
            navigationLink: "/dashboard/persona",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="persona" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="persona" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="persona" />
        },
        {
            menuName: "Chatbot",
            menuKey: "chatbot",
            navigationLink: "/dashboard/chatbot",
            // flagDisabled: !userDetail.flagUser ? true : (
            //     userDetail.user_role && (userDetail.user_role.user_role_name === "Developer" || userDetail.user_role.user_role_name === "Admin") ? false : (
            //         userDetail.plan_detail ?
            //             userDetail.plan_detail.credit_usage_data !== null &&
            //             (userDetail.plan_detail?.credit_usage_data.stripe_product_name === null ||
            //                 userDetail.plan_detail?.credit_usage_data.stripe_product_name === "Free")
            //             : true
            //     )
            // ),
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chatbot" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="chatbot" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="chatbot" />,
        },
        {
            menuName: "Campaign",
            menuKey: "campaign",
            // flagDisabled: !userDetail.flagUser ? true : userDetail.user_role && userDetail.user_role.user_role_name == "Developer" ? false : (userDetail.plan_detail ? userDetail.plan_detail.credit_usage_data !== null && (userDetail.plan_detail?.credit_usage_data.stripe_product_name == null || userDetail.plan_detail?.credit_usage_data.stripe_product_name == "Free") : true),
            navigationLink: "/dashboard/campaigns",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="campaign" />,
            whiteImgSrc: <IconsColor fillColor="#FFFFFF" iconName="campaign" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="campaign" />
        },
        {
            menuName: "Notes",
            menuKey: "notes",
            // flagDisabled: !userDetail.flagUser ? true : userDetail.user_role && userDetail.user_role.user_role_name == "Developer" ? false : (userDetail.plan_detail ? userDetail.plan_detail.credit_usage_data !== null && (userDetail.plan_detail?.credit_usage_data.stripe_product_name == null || userDetail.plan_detail?.credit_usage_data.stripe_product_name == "Free") : true),
            navigationLink: "/dashboard/notes",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="campaign" />,
            whiteImgSrc: <IconsColor fillColor="#FFFFFF" iconName="campaign" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="campaign" />
        }
    ]
    const [sidePanelMenuItems, setSidePanelMenuItems] = React.useState(publicMenuItems);

    const [flagFirstRender, setFlagFirstRender] = React.useState(true);

    React.useEffect(() => {
        let temp: MenuItem[] = [...publicMenuItems];
        // debugger;
        if (userDetail.flagUser) {
            const documentObj: MenuItem = {
                menuName: "Documents",
                menuKey: "documents",
                navigationLink: "/dashboard/documents",
                imgSrc: <IconsColor fillColor="#4C4A4D" iconName="document" />,
                whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="document" />,
                coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="document" />
            }
            if (!userDetail.flag_user_anonymous && userDetail.flag_email_verified) {
                if (
                    userDetail.user_role.user_role_name === "Developer" ||
                    userDetail.user_role.user_role_name === "Super Admin" ||
                    userDetail.user_role.user_role_name === "Admin"
                ) {
                    const campaignObj: MenuItem = {
                        menuName: "Campaign",
                        menuKey: "campaign",
                        flagDisabled: userDetail.plan_detail ? userDetail.plan_detail.credit_usage_data !== null && userDetail.plan_detail?.credit_usage_data.stripe_product_name == "Free" : true,
                        navigationLink: "/dashboard/campaigns",
                        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="campaign" />,
                        whiteImgSrc: <IconsColor fillColor="#FFFFFF" iconName="campaign" />,
                        coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="campaign" />
                    }
                    // const chatbotObj: MenuItem = {
                    //     menuName: "Chatbot",
                    //     menuKey: "chatbot",
                    //     navigationLink: "/dashboard/chatbot",
                    //     imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chatbot" />,
                    //     whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="chatbot" />,
                    //     coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="chatbot" />,
                    // };
                    temp.splice(3, 0, documentObj);
                    setSidePanelMenuItems(temp);
                } else {
                    // const chatbotObj: MenuItem = {
                    //     menuName: "Chatbot",
                    //     menuKey: "chatbot",
                    //     navigationLink: "/dashboard/chatbot",
                    //     imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chatbot" />,
                    //     whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="chatbot" />,
                    //     coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="chatbot" />,
                    // };
                    const settingsObj: MenuItem = {
                        menuName: "Settings",
                        menuKey: "settings",
                        navigationLink: "/dashboard/settings-accountDetails",
                        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="settings" />,
                        whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="settings" />,
                        coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="settings" />,
                    };
                    const campaignObj: MenuItem = {
                        menuName: "Campaign",
                        menuKey: "campaign",
                        navigationLink: "/dashboard/campaigns",
                        flagDisabled: userDetail.plan_detail ? userDetail.plan_detail.credit_usage_data !== null && userDetail.plan_detail?.credit_usage_data.stripe_product_name == "Free" : true,
                        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="campaign" />,
                        whiteImgSrc: <IconsColor fillColor="#FFFFFF" iconName="campaign" />,
                        coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="campaign" />
                    }
                    // temp.push(chatbotObj, settingsObj);
                    // temp.push(documentObj, settingsObj, campaignObj)
                    temp.splice(3, 0, documentObj);
                    temp.splice(7, 0, settingsObj);
                    setSidePanelMenuItems(temp);
                }
            } else {
                // const campaignObj: MenuItem = {
                //     menuName: "Campaign",
                //     menuKey: "campaign",
                //     flagDisabled: true,
                //     navigationLink: "/dashboard/campaigns",
                //     imgSrc: <IconsColor fillColor="#4C4A4D" iconName="campaign" />,
                //     whiteImgSrc: <IconsColor fillColor="#FFFFFF" iconName="campaign" />,
                //     coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="campaign" />
                // }
                temp.splice(3, 0, documentObj);
                setSidePanelMenuItems(temp);
                // setSidePanelMenuItems(publicMenuItems);
            }
        } else {

        }
    }, [userDetail]);

    React.useLayoutEffect(() => {
        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            if (userDetail.flagUser) {
                if (docId) {
                    await GetPersonaList();
                    await GetDocDetails(docId).then((response) => {

                        let responseOfDocDetail = response
                        if (!response.status) {
                            let errorCode = checkForErrorCode(response)
                            if (flagCookieExpired(userDetail, errorCode)) {
                                setUserDetail(emptyUserDetail)
                                if (userDetail.flag_user_anonymous) {
                                    let url = window.location.pathname
                                    navigate(url, { replace: true });
                                } else {
                                    setError({
                                        ...error,
                                        flagShowError: true,
                                        message: "Session expired! Please login to continue."
                                    });
                                    setTimeout(() => {
                                        let url = encodeURIComponent(window.location.href)
                                        navigate("/login?redirect=" + url, { replace: true });
                                    }, 1500);
                                }
                            } else {
                                if (flagCreditsExhausted(errorCode)) {
                                    setFlagShowCreditsExhausted(true)
                                } else {
                                    setError({
                                        ...error,
                                        flagShowError: true,
                                        message: getErrorMessage(errorCode, isDev(userDetail))
                                    });
                                }
                            }
                            // navigate("/dashboard")
                        }
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });

                    })

                } else {
                    navigate("/dashboard")
                }
            } else {
                // debugger;
                navigate("/dashboard")
            }
        })()
    }, [docId, ActiveStep])

    const isEdiorDataAvailable = (data: any): boolean => {
        let flag = false;
        if (data) {
            for (const item of data) {
                if (item['text'] != undefined) {
                    if (item['text']?.length) {
                        flag = true;
                    }
                }
                if (item?.children?.length) {
                    flag = flag || isEdiorDataAvailable(item.children);
                }
            }
        }
        return flag;
    }

    const getValidStep = () => {
        if (currentActiveDocDetails?.template_type_name == "Single-Page") {
            return 3
        } else {
            // return 3
            // let editorDetail = currentActiveDocDetails?.document_step_data?.filter((obj) => obj.step_type_name == "Editor")[0]
            // let outlineDetail = currentActiveDocDetails?.document_step_data?.filter((obj) => obj.step_type_name == "Outline")[0]
            if (isEdiorDataAvailable(documentEditorData ? documentEditorData['root']['children'] : [])) {
                return 3
                // } else if (outlineDetail?.document_outline_data?.length && currentActiveDocDetails?.document_title != null) {
            } else if (documentOutlineData?.length && currentActiveDocDetails?.document_title != null) {
                return 2
            } else {
                return 1
            }
        }
    }

    React.useEffect(() => {
        if (ActiveStep) {
            if (currentActiveDocDetails) {
                let validStep = getValidStep()
                if (validStep >= Number(ActiveStep)) {
                    if (currentActiveStep != Number(ActiveStep)) {
                        setCurrentActiveStep(Number(ActiveStep))
                    }
                } else {
                    if (currentActiveStep != validStep) {
                        setCurrentActiveStep(Number(validStep))
                    }
                }
            }
        } else {
            // debugger;
            navigate('/dashboard')
        }
    }, [ActiveStep, currentActiveDocDetails])

    React.useEffect(() => {
        if (currentActiveDocDetails) {
            // is multi step
            if (currentActiveDocDetails?.template_type_name !== "Single-Page") {
                if (currentActiveDocDetails?.document_title) {
                    setFlagShowGenerateTitlesButton(false)
                }
                if (flagFirstRender) {
                    if (ActiveStep == "1") {
                        setFlagFirstRender(false)
                        setCurrentActiveStep(Number(ActiveStep))
                        // if the step is correct no need to navigate again
                        let validStep = getValidStep()
                        if (validStep >= Number(ActiveStep)) {
                            navigate(`/doc/${currentActiveDocDetails.document_uuid}/${ActiveStep}/${currentActiveDocDetails.template_id}`)
                        } else {
                            navigate(`/doc/${currentActiveDocDetails.document_uuid}/${validStep}/${currentActiveDocDetails.template_id}`)
                        }
                        // if (Number(ActiveStep) !== activeStepNumber) {
                        //     navigate(`/doc/${currentActiveDocDetails.document_uuid}/${activeStepNumber}/${currentActiveDocDetails.template_id}`)
                        // }
                    } else if (ActiveStep) {
                        let validStep = getValidStep()
                        if (validStep >= Number(ActiveStep)) {
                            setCurrentActiveStep(Number(ActiveStep))
                            navigate(`/doc/${currentActiveDocDetails.document_uuid}/${ActiveStep}/${currentActiveDocDetails.template_id}`)
                        } else {
                            setCurrentActiveStep(Number(validStep))
                            navigate(`/doc/${currentActiveDocDetails.document_uuid}/${validStep}/${currentActiveDocDetails.template_id}`)
                        }
                    }
                }
            } else {
                // single step
                let activeStepNumber: number = getValidStep()
                // if active step is equal no need to re navigate
                if (Number(ActiveStep) !== activeStepNumber) {
                    setCurrentActiveStep(3)
                    navigate(`/doc/${currentActiveDocDetails.document_uuid}/3/${currentActiveDocDetails.template_id}`)
                }
            }
        }
    }, [currentActiveDocDetails])


    React.useEffect(() => {
        if (currentActiveStep == 1)
            (async () => {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true });
                await GetDocumentContentTypeData()
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            })()
    }, [currentActiveStep])

    React.useEffect(() => {
        if (currentActiveDocDetails?.document_title) {
            setBlogTitle(currentActiveDocDetails?.document_title)
        } else {
            setBlogTitle("")
        }
    }, [currentActiveDocDetails])

    const getComponentToRender = () => {
        if (currentActiveDocDetails) {
            if (currentActiveDocDetails.template_type_name == "Blank" || currentActiveDocDetails.document_html_body_flag || currentActiveDocDetails.document_step_data[0].template_step_seq_num == 2
            ) {
                return <PlaygroundApp />
            } else if (currentActiveDocDetails.template_type_name == "Single-Page") {
                return <BlogEditor />
            } else {
                let validStep = getValidStep();
                if (Number(ActiveStep) < 3) {
                    return <FormLayout />
                } else {
                    if (validStep < Number(ActiveStep)) {
                        return <FormLayout />
                    } else {
                        return <BlogEditor />
                    }

                }
            }
        } else {
            return <></>
        }
    }
    const [open, setOpen] = React.useState(true);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Grid container className={globalStyles.containerflexWrapStyle}>
                {userDetail.flagUser &&
                    <Grid item
                        sx={{
                            display: { sm: "none" },
                            // width: { sm: open ? drawerWidth : 96 }, 
                            flexShrink: { sm: 0 }, position: "relative"
                        }}
                    >

                        <SideNavigationPanel
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            // menuItems={userDetail.flag_user_anonymous ? menuItemsPublic : menuItemsPrivate}
                            menuItems={sidePanelMenuItems}
                            openDesktopDrawer={open}
                            handleDrawerOpen={handleDrawerOpen}
                            handleDrawerClose={handleDrawerClose}

                        />
                    </Grid>}
                <Grid item
                    sx={{ width: "100%" }}
                // sx={{ width: { sm: `calc(100% - ${open ? drawerWidth : 96}px)` } }} 
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        className={globalStyles.headerMenuIcon}
                        sx={{ marginLeft: "0px", display: { xl: "none !important", lg: "none !important", md: "none !important", sm: 'none !important' } }}

                    >
                        <MenuIcon />
                    </IconButton>

                    <Grid container sx={{
                        justifyContent: "space-between",
                        flexWrap: "nowrap" //added this css to fix ditor design issue in mobile
                    }} direction={"column"}>
                        <Grid item
                        // sx={{ display: { xl: "none !important", lg: "none !important", md: "none !important", sm: "none !important" } }}
                        >
                            <Header flagShowLogo={true} menuItems={sidePanelMenuItems}/>
                        </Grid>
                        <Grid item
                        // className={`${globalStyles.dashboardMaxWidthStyles}`}
                        >
                            <Grid container
                                direction={"column"} //did this to fix the editor design issue
                                className={globalStyles.dashboardOutletWrapper}>
                                {getComponentToRender()}
                            </Grid>
                            {currentActiveDocDetails !== null && currentActiveDocDetails.template_type_name == "Blog-Generation" &&
                                <div className={globalStyles.mobileFooterWrapper} style={{
                                    width: "100%",
                                    // , position: "absolute", 
                                    position: "fixed",
                                    background: "#fff",
                                    bottom: 0
                                }}>
                                    <MobileFooter />
                                </div>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default DocComponent;