import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import styles from "../../assets/styles/componentStyles/outlineComponent.module.scss";
import { Autocomplete, Avatar, Box, Button, Grid, Popper, Select, SelectChangeEvent, Tab, Tabs, TextField, Typography, styled } from "@mui/material";
import BasicAccordion from "./accordionComponent";
import closeArrow from "../../assets/closeArrow.png";
import { DocContext, DocContextPayload } from "../../context/docContext";
import FormPreview from "./generateTitleGlobalFormPreview";
import TabComponent from "./tabComponent";
import OutputOptionCard from "./outputOptionCard";
import back from "../../assets/backArrow.png";
import globalStyles from "../../assets/styles/global.module.scss";
import { PersonaContext, PersonaContextPayload } from "../../context/personaContext";
import MenuItem from '@mui/material/MenuItem';
import { UserContext, UserContextPayload } from "../../context/userContext";
import personaAvatar from "../../assets/personaAvatar.svg";
import personaPremiumIcon from "../../assets/personaCrown.svg";
import { Tooltip } from "@mui/joy";






function ResponsiveOutlineComponent() {


    const StyledPopper = styled(Popper)(({ theme }) => ({
        '& .MuiListSubheader-root': {
            paddingLeft: "12px"
        },

        '& .MuiAutocomplete-option': {
            paddingLeft: "4px !important",
            paddingRight: "4px !important"
        },
    }))


    const { currentActiveDocDetails, flagOutlineCollapsed, setFlagOutlineCollapsed, flagMultiStep, flagShowAIOutput, currentActiveStep, setCurrentActiveStep, flagValUpdated, tabValuePanel, UpdateDocInfo } = useContext(DocContext) as DocContextPayload;
    const { personaList, GetPersonaList } = useContext(PersonaContext) as PersonaContextPayload;
    const { userDetail } = useContext(UserContext) as UserContextPayload;
    // const [selectedPersona, setSelectedPersona] = useState<any>({ persona_name: "None", persona_id: 0 })
    let initialPersonaVal = currentActiveDocDetails !== null && currentActiveDocDetails.document_persona_id !== null ? currentActiveDocDetails.document_persona_id : (userDetail.default_persona_id == null ? 0 : userDetail.default_persona_id)
    const [selectedPersona, setSelectedPersona] = React.useState<any>(null);
    const navigate = useNavigate();

    const initialTabs = [
        { label: 'Context', content: <FormPreview buttonText="Generate Content" isDisabled={false} flagShowGenerateTitlesButton={!flagShowAIOutput} />, tabVisibility: true, flagInitiallyActive: true },
        { label: 'AI Output', content: <OutputOptionCard />, tabVisibility: false, flagInitiallyActive: false, flagDisabled: flagValUpdated == null ? false : !flagValUpdated },
    ];

    const [singleStepTemplateTabs, setSingleStepTemplateTabs] = useState(initialTabs);

    const [toggled, setToggled] = useState(false);

    const handleCollapsedChange = () => {
        setFlagOutlineCollapsed(!flagOutlineCollapsed);
    };
    const handleToggleSidebar = (value: any) => {
        setToggled(value);
    };

    const tabs = [
        { label: 'Context', content: <FormPreview flagShowGenerateTitlesButton={false} isDisabled={false} />, tabVisibility: true, flagInitiallyActive: false },
        { label: 'Outline', content: <BasicAccordion />, tabVisibility: true, flagInitiallyActive: true },
    ];

    useEffect(() => {
        if (flagShowAIOutput) {
            const newTabs = [...singleStepTemplateTabs];
            newTabs[1].tabVisibility = true; // Show the second tab
            newTabs[0].flagInitiallyActive = false;
            newTabs[0].content = <FormPreview flagShowGenerateTitlesButton={!flagShowAIOutput} isDisabled={false} />;
            newTabs[1].flagInitiallyActive = true
            setSingleStepTemplateTabs(newTabs);
        }
    }, [flagShowAIOutput])

    useEffect(() => {
        const newTabs = [...singleStepTemplateTabs];
        newTabs[1].flagDisabled = flagValUpdated == null ? false : !flagValUpdated
        newTabs[0].flagInitiallyActive = true
        newTabs[1].flagInitiallyActive = false
        setSingleStepTemplateTabs(newTabs);
    }, [flagValUpdated])
    interface PersonaOptionType {
        persona_name: string;
        persona_id: number;
    }
    const defaultProps = {
        options: personaList !== null ? [...personaList, { persona_name: "None", persona_id: 0 }] : [],
        getOptionLabel: (option: PersonaOptionType) => option.persona_name,
    };
    const handleChange = async (event: any) => {
        // debugger
        setSelectedPersona(event);
        // console.log("evemt", event)
        // if (event == null) {
        //     setSelectedPersona(event);
        // } else {
        //     setSelectedPersona(event.persona_name);
        // }
        const payload = {
            document_uuid: currentActiveDocDetails?.document_uuid ? currentActiveDocDetails?.document_uuid : "",
            document_persona_id: event == null ? null : event.persona_id
        }
        const response = await UpdateDocInfo(payload)
    };;
    const handleUpdateDocPersona = async (event: any) => {
        const payload = {
            document_uuid: currentActiveDocDetails?.document_uuid ? currentActiveDocDetails?.document_uuid : "",
            persona_id: selectedPersona == 0 ? null : selectedPersona
        }
        const response = await UpdateDocInfo(payload)
    }
    React.useEffect(() => {
        if (personaList == null) {
            GetPersonaList()
        }
    }, [])
    useEffect(() => {
        getPersonaInitialVal()
    }, [currentActiveDocDetails])

    const getPersonaInitialVal = () => {
        if (currentActiveDocDetails) {
            if (currentActiveDocDetails.document_persona_id) {
                if (personaList) {
                    setSelectedPersona(personaList.filter((persona) => persona.persona_id == currentActiveDocDetails.document_persona_id)[0])
                    // return personaList.filter((persona) => persona.persona_id == currentActiveDocDetails.document_persona_id)
                } else {
                    setSelectedPersona(null)
                    return ""
                }
            } else {
                const defaultPersona = personaList?.find((persona) => persona.user_default_persona_data.find((personaData) => personaData.default_persona_type_name == "General"));
                const personaId = defaultPersona?.persona_id ?? null;
                if (personaId) {
                    if (personaList) {
                        setSelectedPersona(personaList.filter((persona) => persona.persona_id == personaId)[0])
                        // return personaList.filter((persona) => persona.persona_id == userDetail.default_persona_id)
                    } else {
                        setSelectedPersona(null)
                        return ""
                    }
                } else {
                    setSelectedPersona(null)
                    return ""
                }
            }
        } else {
            return ""
        }
    }
    return (
        <div>
            <Grid container direction={"column"} sx={{ position: "relative", height: "100%", flexWrap: "nowrap", padding: "1px" }}>
                <Grid item className={styles.templateNameblock}>
                    <Grid container>
                        <Grid item >
                            {/* {currentActiveStep == 2 && */}
                            <img
                                style={{ width: "26px", height: "26px", opacity: "0.5", marginRight: "5px" }}
                                className={globalStyles.clickable}
                                onClick={() => {
                                    if (currentActiveDocDetails?.template_type_name !== "Single-Page") {
                                        navigate(`/doc/${currentActiveDocDetails?.document_uuid}/${currentActiveStep - 1}/${currentActiveDocDetails?.template_id}`)
                                    } else {
                                        window.history.back()
                                        navigate(document.referrer)
                                    }
                                }} src={back}
                            />
                        </Grid>
                        <Grid item className={styles.templateTextStyle} >
                            {currentActiveDocDetails?.template_name}
                        </Grid>
                    </Grid>
                </Grid>

                {/* persona */}
                {tabValuePanel == 0 &&
                    <Grid item className={styles.personaContainer}>
                        <Grid container className={globalStyles.containerflexWrapStyle} alignItems={"center"}>
                            <Grid item className={styles.personaTextStyle}>Persona:</Grid>
                            <Grid item className={globalStyles.fullwidth}>
                                {/* <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    variant="standard"
                                    className={styles.personaDropdown}
                                    value={selectedPersona}
                                    onChange={handleChange}
                                // onBlur={handleUpdateDocPersona}
                                >
                                    <MenuItem className={styles.personaDropdownMenuItem} value={0}>
                                        None
                                    </MenuItem>
                                    {personaList?.map((persona: any) => (
                                        <MenuItem className={styles.personaDropdownMenuItem} value={persona.persona_id}>{persona.persona_name}</MenuItem>
                                    ))}
                                </Select> */}
                                <Autocomplete
                                    PopperComponent={StyledPopper}
                                    options={personaList || []}
                                    value={selectedPersona}
                                    className={styles.personaDropdown}
                                    getOptionDisabled={(option) =>
                                        option.persona_premium_flag && ((userDetail.plan_detail && userDetail.plan_detail.credit_usage_data !== null && userDetail?.plan_detail?.credit_usage_data.stripe_product_name == "Free") || userDetail.flag_user_anonymous)
                                    }
                                    // getOptionLabel={(option: any) => option.persona_name ? option.persona_name : "Select Persona"}
                                    getOptionLabel={(option) =>
                                        option.persona_name
                                            ? option.persona_display_name ? `${option.persona_name}, ${option.persona_display_name}` : option.persona_name 
                                            : ""
                                    }
                                    filterOptions={(options, { inputValue }) => {
                                        const filteredOptions = options.filter((option) => {
                                            const personaName = option.persona_name || '';
                                            const subCategoryName = option.persona_sub_category__name || '';

                                            return (
                                                personaName.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                subCategoryName.toLowerCase().includes(inputValue.toLowerCase())
                                            );
                                        });

                                        return filteredOptions;
                                    }}
                                    groupBy={(option) => option.persona_sub_category__name}
                                    renderOption={(props, option) => (
                                        <li {...props} style={{ width: "100%" }}>
                                            <Tooltip
                                                sx={{ backgroundColor: "unset !important", padding: "0 !important" }}
                                                className={styles.PersonaDescpTooltip}
                                                title={<>
                                                    <Grid container className={styles.personaMenuContainer} alignItems={"start"}>
                                                        <Grid item>
                                                            <img
                                                                src={option.persona_avatar_full_size_url ?? option.persona_avatar_url ?? personaAvatar}
                                                                className={styles.personaAvatarStyle}
                                                            />
                                                        </Grid>
                                                        <Grid item className={styles.leftBlock}>
                                                            <Grid container direction={"column"}>
                                                                <Grid item className={styles.personaNameStyle}>{option.persona_name}</Grid>
                                                                <Grid item className={styles.PersonaDisplayNameStyle}>{option.persona_display_name}</Grid>
                                                                <Grid item className={styles.personaSummaryStyle}>{option.about_persona} </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>}
                                                placement="right">
                                                <li {...props}
                                                    style={{
                                                        paddingLeft: "4px !important",
                                                        paddingRight: "4px !important",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        fontSize: "15px",
                                                        fontWeight: "500",
                                                        width: "100%",
                                                        // justifyContent: "space-between",
                                                    }}
                                                >
                                                    <div
                                                        style={{ pointerEvents: "none", display: "flex" }}>
                                                        <img style={{ width: "37px", height: "37px", borderRadius: "50%", marginRight: "10px" }}
                                                            src={option.persona_avatar_full_size_url ?? option.persona_avatar_url ?? personaAvatar}
                                                        />
                                                    </div>

                                                    <div style={{ display: "flex", gap: "15px", width: "100%" }}>

                                                        <div style={{ width: "100%", whiteSpace: "pre-wrap", pointerEvents: "none", display: "flex", }}>
                                                            {option.persona_display_name ? `${option.persona_name}, ${option.persona_display_name}` : option.persona_name}
                                                        </div>

                                                        {option.persona_premium_flag ?
                                                            (<div style={{ width: "100%", display: "flex", justifyContent: "end", pointerEvents: "none", maxWidth: "25px" }}>
                                                                <img src={personaPremiumIcon} style={{ width: "25px", height: "auto" }} />
                                                            </div>) : (
                                                                <div style={{ width: "100%", display: "flex", justifyContent: "end", pointerEvents: "none", maxWidth: "25px" }}>

                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </li>

                                            </Tooltip>
                                        </li>
                                    )}
                                    // clearIcon={selectedPersona ? <ClearIcon /> : <span />}
                                    onChange={(_, newValue) => handleChange(newValue)}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                placeholder='Select Persona'
                                                {...params}
                                                variant="standard"
                                                className={styles.personaDropdown}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <>
                                                            {selectedPersona && (
                                                                <Avatar
                                                                    // src={selectedPersona.persona_avatar_url && selectedPersona.persona_avatar_url}
                                                                    src={selectedPersona.persona_avatar_full_size_url ?? selectedPersona.persona_avatar_url ?? personaAvatar}
                                                                    // src={selectedPersona.persona_avatar_full_size_url !== null ? selectedPersona.persona_avatar_full_size_url : selectedPersona.persona_avatar_url && selectedPersona.persona_avatar_url}
                                                                    alt="Selected Persona"
                                                                    style={{ width: "30px", height: "30px", marginRight: "10px" }}
                                                                />
                                                            )}
                                                        </>
                                                    ),
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </Grid>
                        </Grid>

                    </Grid>}

                <Grid item className={globalStyles.fullHeight} sx={{
                    width: "100%", height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "100%",
                    padding: "1px !important",
                    overflow: "scroll"
                }}>
                    {/* tabs */}

                    <TabComponent tabHeaderClassname="outlineChildTabHeader" className={currentActiveDocDetails?.template_type_name !== "Single-Page" ? "outlineChildPartialHeight" : flagShowAIOutput ? "outlineChildPartialHeight" : "outlineChildFullHeight"} conditionalStyling={JSON.stringify(currentActiveDocDetails?.template_type_name !== "Single-Page" ? {} : flagShowAIOutput ? { display: "block" } : { display: "none" })} tabs={currentActiveDocDetails?.template_type_name !== "Single-Page" ? tabs : singleStepTemplateTabs}
                    />
                </Grid>
            </Grid>
        </div >
    );
}
export default ResponsiveOutlineComponent;
