import { Button, Divider, Grid, IconButton, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/CampaigTabView.module.scss'
import globalStyles from '../../../assets/styles/global.module.scss'
import AddIcon from '@mui/icons-material/Add';
import GlobalPopUp from '../../utility/globalPopUp';
import GlobalForm from '../../utility/formWiz/globalForm';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { NoteContext, NoteContextPayload } from '../../../context/noteContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import agent from '../../../services/api';
import { notesListResponseModel } from '../../../models/axiosModel';
import GlobalButton from '../../utility/globalButton/button';
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import AddNoteCategory from './addNoteCategory';
interface docPageTheme {
    [key: string]: any;
}
interface globalFormField {
    [key: string]: any;
}
interface noteDetailsDataType {
    doc_page_theme: docPageTheme[],
    global_form_field: globalFormField[]
    widget_flag_auto_save: boolean,
    widget_flag_hide_button: boolean,
    widget_form_full_width: boolean
}
interface noteDetails {
    data: noteDetailsDataType
}
interface updateCategory {
    setFlagNewCategoryAdded: any,
    flagNewCategoryAdded: any
}

const UpdateNotes = (props: updateCategory) => {

    const {
        getNoteDetails, 
        categoryList,
        noteExistingDetails
    } = useContext(NoteContext) as NoteContextPayload

    const {
        setFlagLoading,
        flagLoading,
        success,
        setSuccess,
        error,
        setError,
    } = useContext(UserContext) as UserContextPayload;

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const [flagTabs, setFlagTabs] = useState(0);
    const [submissionID, setsubmissionID] = React.useState(null);

    const [noteDataForTable, setNoteDataForTable] = useState<notesListResponseModel[]>([])
    const [flagShowCategoryForm, setFlagShowCategoryForm] = useState(false)
    
    const [renderValue, setRenderValue] = React.useState({
        status: null,
        message: "",
        submission_id: null
    })

    let options = [{
        field_option_data_id: 101,
        field_option_value: "Add New Category"
    }]


    let options2 = categoryList?.map(category => ({
        field_option_data_id: category.notes_category_id,
        field_option_value: category.notes_category_name
    }))

    options = options2 ? options.concat(options2) : options
    
    const [noteDetails, setNoteDetails] = useState<noteDetails>({
        data: {
            widget_form_full_width: true,
            global_form_field: [
                {
                    "page_field_id": 1666,
                    "label": "Note Title",
                    "placeholder": "Write Title",
                    "required": true,
                    "name": "1666_Note_Title",
                    "type": "text",
                    "payload_name": "user_notes_title",
                    "value": noteExistingDetails?.user_notes_title ? noteExistingDetails?.user_notes_title : "",
                    "size": "small",
                    "hidden": false,
                    "position": null,
                    "font_color": null,
                    "col": 12,
                    "class2": "globalTextfieldStyle"
                },
                {
                    "page_field_id": 1667,
                    "label": "Note Category",
                    "placeholder": "Select Category",
                    "required": false,
                    "name": "1667_Note_Category",
                    "type": "dropdown",
                    "payload_name": "notes_category_name",
                    "value": noteExistingDetails?.notes_category_name ? noteExistingDetails?.notes_category_name : "",
                    "size": "small",
                    "hidden": false,
                    "position": null,
                    "options": options,
                    "font_color": null,
                    "col": 12,
                },
                {
                    "page_field_id": 1668,
                    "label": "Provide the content for your note",
                    "placeholder": "Enter Content",
                    "required": true,
                    "name": "1668_Content_Note",
                    "type": "longtext",
                    "payload_name": "user_notes_content",
                    "value": noteExistingDetails?.user_notes_content ? noteExistingDetails?.user_notes_content : "",
                    "hidden": false,
                    "size": "small",
                    "position": null,
                    "font_color": null,
                    "col": 12,
                    "rows": 5,
                    "class2": "globalTextfieldStyle"
                },
            ],
            widget_flag_auto_save: false,
            widget_flag_hide_button: false,
            doc_page_theme: [
                {
                    "page_id": "682",
                    "page_theme_font_type": "Open Sans",
                    "page_theme_font_style": "normal",
                    "page_theme_font_align": "left",
                    "page_theme_font_weight": "bold",
                    "page_theme_font_size": "15",
                    "page_theme_background_color": "#FFFFFF",
                    "page_theme_heading_color": "#EFE4F1",
                    "page_theme_text_decoration": "unset",
                    "page_hide_button": false,
                    "page_auto_save": false,
                    "page_font_spacing": null,
                    "page_theme_row_spacing": "10px 0px 10px 0px",
                    "page_theme_column_spacing": 0,
                    "margin": "0px 0px 8px 0px"
                }
            ]
        }
    })
    const [initialNoteDetails, setInitialNoteDetails] = useState<noteDetails | null>(null)
    const [fieldChange, setFieldChange] = useState(false);
    useEffect(() => {
        (async () => {
            try {
                if (searchParams) {
                    let user_notes_id = searchParams.get("user_notes_id");
                    if (user_notes_id) {
                        setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
                        const response = await getNoteDetails(user_notes_id);
                        if (!response.status) {
                            navigate('/dashboard/notes');
                        }
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                    }
                    else {
                        navigate('/dashboard/notes');
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                    }
                }
            }
            catch (err) {
                console.error("An error occurred:", error);
                setError({ ...error, flagShowError: true, message: "Something went wrong, Please try again later!" })
            }
            finally {
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
            }
        })();
    }, []);

    useEffect(() => {
        if (noteExistingDetails) {
            let temp = { ...noteDetails };
            for (const field of temp.data.global_form_field) {
                const payloadName = field.payload_name;
                // @ts-ignore
                field.value = noteExistingDetails[payloadName] || "";
            }
            setNoteDetails(temp);
            let initialData = JSON.parse(JSON.stringify(temp))
            setInitialNoteDetails(initialData)
        }
    }, [noteExistingDetails])

    const getCancelButtonDisableConditions = () => {
        if (JSON.stringify(noteDetails) === JSON.stringify(initialNoteDetails)) {
            return true;
        }
        else {
            return false;
        }
    }

    const getSaveButtonDisableCondition = () => {
        let fields = noteDetails["data"]["global_form_field"]
        let compareData = (JSON.stringify(noteDetails) === JSON.stringify(initialNoteDetails));
        for (const field of fields) {
            if (compareData || (field.required && ((typeof field.value === "string" && field.value.trim().length === 0) || (Array.isArray(field.value) && field.value.length === 0))) || field.value == null) {
                return true
            }
        }
        return false
    }

    const formSubmitFunction = async (data: any) => {
        var finalData = data;
    }

    const handleCloseSnackBar = () => {
        setRenderValue({
            status: null,
            message: "",
            submission_id: null
        })
    }


    // set form field value
    const setFieldValue = (data: any) => {
        var tempdata = { ...noteDetails }
        tempdata.data.global_form_field.map((newvalue: any) => {
            if (newvalue.options) {
                newvalue.options.map((option_value: any) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
        })
        if (noteDetails.data.global_form_field[1].value === "Add New Category") {
            tempdata.data.global_form_field[1].value = "Select Category";
            setFlagShowCategoryForm(true)
        }
        
        if(noteDetails.data.global_form_field[1].value === "Add New Category" && !props.flagNewCategoryAdded){
            tempdata.data.global_form_field[1].value = "Select Category";
        }
        setNoteDetails(tempdata)
        if (JSON.stringify(noteDetails) === JSON.stringify(initialNoteDetails)) {
            setFieldChange(false)
        }
        else {
            setFieldChange(true);
        }
    }


    // Update Existing Details
    const handleUpdateNoteDetails = async () => {
        const selectedCategoryValue = noteDetails.data.global_form_field[1].value;
        const selectedCategory = noteDetails.data.global_form_field[1].options?.find((option:any) => option.field_option_value === selectedCategoryValue);
        let userNotesId = searchParams.get('user_notes_id')
        let payload = {
            user_notes_id: Number(userNotesId),
            user_notes_title: noteDetails.data.global_form_field[0].value,
            notes_category_id: selectedCategory? selectedCategory.field_option_data_id : null,           
            user_notes_content: noteDetails.data.global_form_field[2].value,
        }

        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await agent.updateNoteDetail.put(payload)
        if (response.status) {
            if (searchParams) {
                let user_notes_id = searchParams.get("user_notes_id");
                if (user_notes_id) {
                    await getNoteDetails(user_notes_id);
                }
                else {
                    navigate('/dashboard/notes');
                }
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
            setSuccess({ ...success, flagShowSuccess: true, message: "Note Updated Successfully!" })
        } else {
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
            setError({ ...error, flagShowError: true, message: response.error.message })
        }
        setFieldChange(false);
    }

    useEffect(()=>{
        let temp = {...noteDetails}

        temp.data.global_form_field[1].options =  categoryList?.map(category => ({

            field_option_data_id: category.notes_category_id,
            field_option_value: category.notes_category_name
        }))
        let additionalOption = {
            field_option_data_id:101,
            field_option_value:"Add New Category"
        }
        temp.data.global_form_field[1].options.splice(0,0,additionalOption)
        props.flagNewCategoryAdded ? temp.data.global_form_field[1].value =  temp.data.global_form_field[1].options[options.length-1].field_option_value : temp.data.global_form_field[1].value = ""
        setNoteDetails(temp)
    }, [categoryList])

    const handleCancelCampaignDetails = () => {
        setNoteDetails(JSON.parse(JSON.stringify(initialNoteDetails)))
    }


    return (
        <>
            {flagShowCategoryForm &&
                <GlobalPopUp
                    open={flagShowCategoryForm}
                    onClose={() => { setFlagShowCategoryForm(false) }}
                    handleClose={() => { setFlagShowCategoryForm(false) }}
                    flagShowCloseIcon={true}
                    borderRadius="10px"
                >
                    <AddNoteCategory setFlagShowCategoryForm={setFlagShowCategoryForm} setFlagNewCategoryAdded={props.setFlagNewCategoryAdded}/>
                </GlobalPopUp>
            }

            <Grid container>
                <Grid item xs={12} className={`${styles.cmpGlobalHeadSpacing}`}>
                    <Grid container>

                        <Grid item xs={12} className={styles.cmpGlobalHeaderGrid}>
                            <Grid container sx={{ justifyContent: { xl: "space-between", lg: "space-between", md: "space-between", sm: "space-between", xs: "space-between" } }}>
                                <Grid item xl={8} lg={9} md={12} sm={12} xs={10} sx={{ margin: { xs: "0px 0px 0px 0px", sm: "0px 0px 0px 5px" } }} >
                                    <GlobalPageHeaderText
                                        mainHeadText={"Notes"}
                                    />
                                </Grid>
                                {window.screen.width < 600 && <Grid item xs={2} className={styles.plusIconGrid}>
                                    <IconButton
                                        className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                        onClick={() => { navigate(`/dashboard/notes/create`) }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Grid>}
                                <Grid item xl={4} lg={4} md={4} sm={0} xs={12} sx={{ margin: { xs: "0px 0px 0px" } }}>
                                    {/* <GlobalSearchBar
                                        onSearch={() => { }}
                                        onCancelSearch={() => { }}
                                        classGlobal=""
                                    /> */}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={`${styles.cmpGlobalComponentBorderItem}`} >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container alignItems={"center"} className={`${styles.cmpHeadNewContentContainer}`}>
                                        <Grid item xl={10} lg={9} md={8} sm={8} xs={window.screen.width < 600 ? 12 : 8} className={`${styles.cmpHeadTextItem}`} >
                                            <Typography component={'span'} className={`${styles.wzGlobalHead}`}>
                                                {noteExistingDetails? noteExistingDetails.user_notes_title : "Title"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={2} lg={3} md={4} sm={4} xs={window.screen.width < 600 ? 4 : 4} className={styles.plusIconGrid}>
                                            {window.screen.width >= 600 && window.screen.width < 800 &&
                                                <IconButton
                                                    className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                                    onClick={() => { navigate(`/dashboard/notes/create`) }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            }
                                            {window.screen.width >= 800 &&
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            size='medium'
                                                            variant='contained'
                                                            className={`${styles.wzNewContentBtn}`}
                                                            startIcon={<AddIcon />}
                                                            onClick={() => { navigate(`/dashboard/notes/create`) }}
                                                        >
                                                            New Note
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Divider className={`${styles.cmpDivider}`} />

                                <Grid item xs={12} className={flagTabs == 0 ? `${styles.cmpTabItem}` : `${styles.cmpTabItem2}`}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            {flagTabs === 0 &&
                                                <Grid container className={`${styles.cmpTabSpacing} ${styles.cmpTabContainer}`} >
                                                    {/* {!flagLoading && */}
                                                    <Grid item xl={5} lg={7} md={8} sm={12} xs={12} >
                                                        <GlobalForm
                                                            fields={noteDetails.data.global_form_field}
                                                            submitFunction={formSubmitFunction}
                                                            checkFormSubmit={renderValue}
                                                            handleCloseSnackBar={handleCloseSnackBar}
                                                            pageThemeData={noteDetails.data.doc_page_theme[0]}
                                                            formData={noteDetails.data}
                                                            submissionID={submissionID}
                                                            setFieldValue={setFieldValue}
                                                            recaptchaFlag="true"
                                                            defaultLoader={false}
                                                            formEditable={true}
                                                        />
                                                    </Grid>

                                                    {/* } */}
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    {flagTabs === 0 && <Grid item xs={12} className={styles.cancelButtonGrid}>
                                        <Grid container className={styles.cmpSaveGrid}>
                                            <GlobalButton
                                                buttonText='Update'
                                                className="primaryButtonStyle"
                                                disabled={getSaveButtonDisableCondition()}
                                                onClick={handleUpdateNoteDetails}
                                            />
                                            <Button
                                                disabled={getCancelButtonDisableConditions()}
                                                variant='outlined'
                                                size="small"
                                                className={styles.cmpCancelButton}
                                                onClick={handleCancelCampaignDetails}
                                                sx={{ textTransform: "capitalize", opacity: getCancelButtonDisableConditions() ? "0.5" : "" }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>

                                    </Grid>}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default UpdateNotes