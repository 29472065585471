import { useContext } from "react";
import { ParaphraseContext, ParaphraseContextPayload } from "../../../../../context/paraphraseContext";
import WordChangerPopover from "../../../../utility/wordChangerPopover";
import GlobalPopover from "../../../../utility/globalPopover";

export interface ConvertTextToHTMLProps {
    output_text: string;
    id: number;
    indexID: number;
    updatedOriginalText: string;
    changeWord: (wordToReplaceWith: string, id: number, indexID: number, uniqueIdAfterReplacement: any, textToChange: string, wordToReplace: string, rewordVersioningArr: any, rewordVersionIndex: number) => void;
}


const ConvertTextToHTML = (props: ConvertTextToHTMLProps) => {

    const { rewordVersioningArr, rewordVersionIndex } = useContext(ParaphraseContext) as ParaphraseContextPayload;
    const { id, indexID, output_text, updatedOriginalText, changeWord } = props;

    // regex to separate the pattern enclosed text
    const regex = /\s*\|\|\*\s*(\{"replace":[^}]*\})\s*\*\|\|\s*/g;

    // Split the text based on the pattern
    const segments = output_text.split(regex);

    return (
        <>
            {segments.map((segment: any, index: any) => {
                // Check if the segment is a placeholder
                const isPlaceholder = /\{.*\}/.test(segment);
                let replaceInfo: any

                if (isPlaceholder) {
                    // Parse the replace information from the placeholder
                    replaceInfo = JSON.parse(segment.replace(/(\w+)(?=:)/g, '"$1"').trim());
                    const replaceText = replaceInfo.replacement;
                    const suggestionInfo = replaceInfo;

                    let color;
                    let suggestion_type
                    if (suggestionInfo.suggestion_type_id === 1 || suggestionInfo.suggestion_type_id === 2) {
                        color = '#4FD61F';
                        if (suggestionInfo.suggestion_type_id === 1) {
                            suggestion_type = "Inclusion/Exclusion Compliance"
                        } else {
                            suggestion_type = "Persona Alignment"
                        }
                    } else if (suggestionInfo.suggestion_type_id === 3 || suggestionInfo.suggestion_type_id === 4) {
                        color = '#FFA500';
                        if (suggestionInfo.suggestion_type_id === 3) {
                            suggestion_type = "Synonyms for Diversity"
                        } else {
                            suggestion_type = "Grammar Fixes"
                        }
                    } else if (suggestionInfo.suggestion_type_id === 5 || suggestionInfo.suggestion_type_id === 6) {
                        color = '#D11B1B';
                        if (suggestionInfo.suggestion_type_id === 5) {
                            suggestion_type = "Syntax Correction"
                        } else {
                            suggestion_type = "Clarity Enhancements"
                        }
                    }
                    return (
                        <>

                            <GlobalPopover
                                trigger={<span contentEditable={false} key={index} style={{ fontWeight: 600, color }}
                                >
                                    {replaceText + " "}
                                </span>}
                                // @ts-ignore
                                content={<WordChangerPopover originalText={replaceInfo.replace} wordSuggestions={replaceInfo.additional_suggestion} suggestion_type={suggestion_type} color={color} replacement={replaceInfo.replacement} changeWord={(word: string) => { changeWord(word, id, indexID, replaceInfo.unique_id, updatedOriginalText, replaceInfo.replacement, rewordVersioningArr, rewordVersionIndex) }} />}
                            />
                            <span style={{ display: "none" }}>{JSON.stringify(replaceInfo)}</span>
                        </>

                    );
                } else {
                    if (segments[index + 1]) {
                        let test = JSON.parse(segments[index + 1].replace(/(\w+)(?=:)/g, '"$1"').trim())?.replacement
                        // Text not to be replaced
                        return test ? segment.replace(test, "") : segment
                    } else {
                        return segment;

                    }
                }
            })}
        </>
    );
}
export default ConvertTextToHTML