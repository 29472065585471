import { Box, Grid, Menu, Slider, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { UserContext, UserContextPayload } from '../../context/userContext';
import { useNavigate } from 'react-router-dom';
import profileIcon from "../../assets/profileIcon.svg";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import Settings from '@mui/icons-material/Settings';
import styles from "../../assets/styles/componentStyles/sidePanelAccountDetails.module.scss";
import InfoIcon from '@mui/icons-material/Info';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import agent from '../../services/api';
import { DocContext, DocContextPayload } from '../../context/docContext';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { NotifyExtentionContext } from '../../context/notifyExtentionContext';
import GlobalButton from './globalButton/button';

export const SidepanelAccountDetails = (props: any) => {
    const navigate = useNavigate();
    const { userDetail, setFlagLoading, setUserDetail, creditUsed, setCreditUsed, flagLoading, setError, error } = useContext(UserContext) as UserContextPayload
    const { setDocList } = useContext(DocContext) as DocContextPayload;
    const { SendPostMessageToExtention } = React.useContext(
        NotifyExtentionContext
    ) as any;
    const [openMenu, setOpenMenu] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    let urlToNavigateTo = encodeURIComponent(window.location.href)
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(false);
    };
    return (
        <>
            <Menu
                anchorEl={anchorEl}
                // className={MenuPaper}
                id="account-menu"
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                className={styles.menuStyleSidePanel}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {/* {!userDetail.flag_user_anonymous &&
                    <MenuItem className={styles.menuLogout}>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                    </MenuItem>} */}
                {!userDetail.flag_user_anonymous && userDetail.flagUser &&
                    <MenuItem className={styles.menuLogout}
                        onClick={async () => {
                            setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
                            const response = await agent.LogoutAPI.post();
                            if (response.status) {
                                SendPostMessageToExtention(null)
                                // localStorage.removeItem('writeWizUserDetail');
                                setUserDetail({ ...userDetail, flagUser: false });
                                setDocList(null)
                                navigate("/login")
                                // window.location.href = "https://google.com";
                            } else {
                                setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
                            }
                            setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                        }}>
                        <ListItemIcon >
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>}
                {(userDetail.flag_user_anonymous || !userDetail.flagUser) &&
                    <MenuItem onClick={() => { navigate("/login?redirect=" + urlToNavigateTo) }} className={styles.menuLogout}>
                        <ListItemIcon>
                            <AppRegistrationIcon fontSize="small" />
                        </ListItemIcon>
                        Sign In/ Register
                    </MenuItem>}
            </Menu>
            {userDetail.flagUser && !userDetail.flag_user_anonymous ?
                <Box className={props.flagMobile ? styles.mainBlockMobile : styles.mainBlock}
                    onClick={props.flagMobile ? handleClick : undefined}
                >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img src={profileIcon} style={{ width: "23px", height: "auto" }} />
                        {!props.flagMobile &&
                            <Typography className={styles.nameStyle} sx={{ maxWidth: "125px", overflow: "hidden", textOverflow: "ellipsis" }}>
                                {userDetail.flag_user_anonymous ? "Anonymous" :
                                    `${userDetail.user_first_name == null ? userDetail.user_email
                                        :
                                        `${userDetail.user_first_name} ${userDetail.user_last_name !== null ? userDetail.user_last_name : ""}`}`
                                    // `${userDetail.user_first_name} ${userDetail.user_last_name !== null ? userDetail.user_last_name : ""}`
                                }
                            </Typography>
                        }
                    </Box>
                    {!props.flagMobile &&
                        <ArrowForwardIosIcon
                            onClick={handleClick}
                            aria-controls={openMenu ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? 'true' : undefined}
                            sx={{ cursor: "pointer", fontSize: "medium !important", color: "#7A7A7A !important" }}
                        />
                    }
                </Box>
                :
                <>
                    {props.flagMobile ?
                        <>
                            <Box className={props.flagMobile ? styles.mainBlockMobile : styles.mainBlock}
                                onClick={props.flagMobile ? handleClick : undefined}
                            >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <img src={profileIcon} style={{ width: "23px", height: "auto" }} />
                                </Box>
                            </Box></>
                        :
                        <>
                            <GlobalButton buttonText={props.flagMobile ? <AppRegistrationIcon fontSize="small" /> : "  Sign In/ Register"} className='primaryButtonStyle' flagFullWidth onClick={() => { navigate("/login?redirect=" + urlToNavigateTo) }} />
                        </>}
                </>
            }
        </>
    )
}
