import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import { CommonResponseModel, addChatbotDomainPayloadModel, chatbotThreadDataInterface, chatbotThreadListInterface, createChatbotPayloadModel, getChatbotResponseModel, updateChatbotDomainPayloadModel, updateChatbotPayloadModel } from "../models/axiosModel";
import agent from "../services/api";

const ChatbotContext = React.createContext<ChatbotContextPayload | undefined>(undefined);

export interface ChatbotContextPayload {
    CreateChatbotApi: (payload: createChatbotPayloadModel) => any;
    GetChatbotListApi: () => any;
    GetChatbotDataApi: (id: number) => Promise<CommonResponseModel<getChatbotResponseModel | null>>;
    UpdateChatbotApi: (payload: updateChatbotPayloadModel) => any;
    AddChatbotDomain: (payload: addChatbotDomainPayloadModel) => any;
    UpdateChatbotDomain: (payload: updateChatbotDomainPayloadModel) => any;
    chatBotDetails: getChatbotResponseModel | null;
    setChatBotDetails: React.Dispatch<React.SetStateAction<getChatbotResponseModel | null>>;
    chatbotThreadList: chatbotThreadListInterface[] | null;
    setChatbotThreadList: React.Dispatch<React.SetStateAction<chatbotThreadListInterface[] | null>>;
    getChatbotThreadsList: (chatbot_id: string) => Promise<CommonResponseModel<chatbotThreadListInterface[]>>;
    currentActiveChatbotThread: chatbotThreadDataInterface[] | null;
    setCurrentActiveChatbotThread: React.Dispatch<React.SetStateAction<chatbotThreadDataInterface[] | null>>;
}

const ChatbotProvider = ({ children }: ChildrenProps) => {

    const [chatBotDetails, setChatBotDetails] = useState<getChatbotResponseModel | null>(null);
    const [chatbotThreadList, setChatbotThreadList] = useState<chatbotThreadListInterface[] | null>(null)
    const [currentActiveChatbotThread, setCurrentActiveChatbotThread] = useState<chatbotThreadDataInterface[] | null>(null)
    const CreateChatbotApi = async (payload: createChatbotPayloadModel): Promise<any> => {
        const response = await agent.createChatbot.post(payload);
        return response;
    }
    const GetChatbotListApi = async (): Promise<any> => {
        const response = await agent.getChatbotList.get();
        return response;
    }
    const GetChatbotDataApi = async (id: number): Promise<CommonResponseModel<getChatbotResponseModel | null>> => {
        const response = await agent.getChatbotData.get(id);
        if (response.status) {
            setChatBotDetails(response.data)
        }
        return response;
    }
    const UpdateChatbotApi = async (payload: updateChatbotPayloadModel): Promise<any> => {
        const response = await agent.updateChatbotData.put(payload);
        return response;
    }
    const AddChatbotDomain = async (payload: addChatbotDomainPayloadModel): Promise<any> => {
        const response = await agent.addChatbotDomain.post(payload);
        return response;
    }
    const UpdateChatbotDomain = async (payload: updateChatbotDomainPayloadModel): Promise<any> => {
        const response = await agent.updateChatbotDomain.put(payload);
        return response;
    }
    const getChatbotThreadsList = async (chatbot_id: string) => {
        const response = await agent.getChatbotThreadsList.get(chatbot_id)
        if (response.status) {
            setChatbotThreadList(response.data)
        } else {
            setChatbotThreadList(null)
        }
        return response;
    }
    const payload = {
        CreateChatbotApi,
        GetChatbotDataApi,
        UpdateChatbotApi,
        GetChatbotListApi,
        AddChatbotDomain,
        UpdateChatbotDomain,
        chatBotDetails,
        setChatBotDetails,
        chatbotThreadList,
        setChatbotThreadList,
        getChatbotThreadsList,
        currentActiveChatbotThread,
        setCurrentActiveChatbotThread
    };

    return (
        <ChatbotContext.Provider value={payload}>
            {children}
        </ChatbotContext.Provider>
    );
};

export { ChatbotContext, ChatbotProvider };
