import { Autocomplete, Divider, Grid, Paper, Tab, Tabs, TextField, Tooltip, Checkbox, Skeleton, useMediaQuery, Box, IconButton, Popper, Menu, MenuItem } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/paraphrasing.module.scss";
import { Typography } from '@mui/joy';
import GlobalTextField from '../../utility/globalTextfield';
import { TemplateContext, TemplateContextPayload } from '../../../context/templateContext';
import GlobalButton from '../../utility/globalButton/button';
import agent from '../../../services/api';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { CommonResponseModel, CommonResponseSecondaryModel, ExecutePromptResponseDataModel, RegisterDataResponseModel, SendOTPResponseDataModel, executePromptResponseParaphraseObj, executePromptResponseParaphraseValueObj, outputFormatObj, rewriteToneGenericListObj, workDomainObj } from '../../../models/axiosModel';
import CopyToClipboard from "react-copy-to-clipboard";
import { DocContext, DocContextPayload } from '../../../context/docContext';
import shine from "../../../assets/shine.png"
import { PersonaContext, PersonaContextPayload } from '../../../context/personaContext';
import IconsColor from '../../utility/iconsColor';
import closeArrow from "../../../assets/closeArrow.png";
import checkedIcon from "../../../assets/checkedCircularIcon.svg";
import unCheckedIcon from "../../../assets/uncheckedCircularIcon.svg";
import reWriteIcon from '../../../assets/RewriteMobileIcon.svg'
import './paraphrasingCarousel.css'
import ReactMarkdown from "react-markdown";
import { ParaphraseContext, ParaphraseContextPayload } from '../../../context/paraphraseContext';
import BasicParaphrasingTab from './basicParaphrasing';
import AdvanceParaphrasingTab from './advanceParaphrasing';
import CustomParaphrasingTab from './customParaphrasingTab';
import PersonaParaphrasingTab from './personaParaphrasingTab';
import ParaphrasingMobileComponent from './paraphrasingMobileComponent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { emptyUserDetail, flagCookieExpired } from '../../../utils/constants';
import { checkForErrorCode, flagCreditsExhausted, flagUseGemini, flagUseSocketForParaphrase, getErrorMessage, isDev } from '../../../utils/constants';
import RoundLoader from '../../utility/globalLoader';
import SplitPane from 'react-split-pane';
import languageImg from "../../../assets/images/paraphrasing/Languages.jpg";
import toneOfVoiceImg from "../../../assets/images/paraphrasing/ToneofVoice.jpg";
import Carousel from "react-elastic-carousel";
import backArrowCarousel from "../../../assets/carouselBackArrow.png";
import { useNavigate } from 'react-router-dom';

export const resizeFunction = () => {
    document.querySelectorAll(".resizableTextArea").forEach((element) => {
        const textarea = element as HTMLTextAreaElement;
        textarea.style.height = 'unset !important';
        // textarea.style.minHeight = textarea.scrollHeight + 'px';
        textarea.style.minHeight = 'fit-content';
    });
}
const Paraphrasing = () => {
    const navigate = useNavigate()
    const { personaList } = useContext(PersonaContext) as PersonaContextPayload;
    const { UpdateDocumentEditorDetail, setDocumentEditorData, CreateDoc, webSocketObj, setWebSocketObj, setFlagOutlineLoader } = useContext(DocContext) as DocContextPayload
    const { userDetail, languageList, Register, setUserDetail, promptLogFieldGenericList, setLoadingURL, flagLoading, setLoaderFilterDetails, setCreditUsed } = useContext(UserContext) as UserContextPayload;
    const { paraphrasingVersioningArr, setParaphraseVersioningarr, currentIndex, setCurrentIndex, paraphrasingVersionIndex, setParaphrasingVersionIndex, hasParaphras, setHasParaphras, currentTab } = useContext(ParaphraseContext) as ParaphraseContextPayload;
    const [morePrompts, setMorePrompts] = useState<any>([])
    const [selectedOutputResponse, setSelectedOutputResponse] = useState<ExecutePromptResponseDataModel | null>(null)
    const { rewriteToneGenericList, templateList, routeSources, outputFormatGenericList } = React.useContext(TemplateContext) as TemplateContextPayload
    // ref for output cards
    const [editIndex, setEditIndex] = useState<number | null>(null)
    const textAreaRefs = useRef<{ [key: number]: HTMLTextAreaElement | null }>({});
    const [collapsed, setCollapsed] = useState(false);
    const [laptopScreen, setLaptopScreen] = useState(false);
    const [miniLaptop, setMiniLaptop] = useState(false);
    const [tabScreen, setTabScreen] = useState(false);

    // to set the respective heights of sections when orientation changes
    useEffect(() => {
        const handleResize = () => {
            const isLaptop = window.matchMedia('(min-width:1200px)').matches;
            const isMiniLaptop = window.matchMedia('(min-width: 1200px) and (max-width: 1620px)').matches;
            const isTabScreen = window.matchMedia('(min-width: 599px) and (max-width: 1200px)').matches;
            setLaptopScreen(isLaptop);
            setMiniLaptop(isMiniLaptop);
            setTabScreen(isTabScreen);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const getTabSectionHeight = (tabName: string) => {
        if (miniLaptop) {
            switch (tabName) {
                case "Basic":
                    return 270;
                case "Advanced":
                    return 435;
                case "Custom Prompt":
                    return 435;
                case "Persona":
                    return 350;

            }
        } else if (tabScreen) {
            switch (tabName) {
                case "Basic":
                    return 300;
                case "Advanced":
                    return 460;
                case "Custom Prompt":
                    return 465;
                case "Persona":
                    return 380;
            }
        } else {
            switch (tabName) {
                case "Basic":
                    return 230;
                case "Advanced":
                    return 400;
                case "Custom Prompt":
                    return 400;
                case "Persona":
                    return 320;

            }
        }
    }
    useEffect(() => {
        setCustomPane1Style({
            display: "block",
            maxHeight: "calc(100% - 100px)", minHeight: `calc(100% - ${getTabSectionHeight(paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab)}px)`
        })
    }, [miniLaptop, tabScreen])

    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
        function handleResize() {
            setViewportHeight(window.innerHeight);
        }

        // Add event listener when component mounts
        window.addEventListener("resize", handleResize);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [window.innerWidth]);

    // for when orientation changes

    const [customPane1Style, setCustomPane1Style] = useState<React.CSSProperties>({
        display: "block",
        maxHeight: "calc(100% - 100px)", minHeight: `calc(100% - ${getTabSectionHeight(paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab)}px)`
    })
    const [customPane2Style, setCustomPane2Style] = useState<React.CSSProperties>({
        background: "#f9f9f9",
        opacity: "1",
        // overflow: "hidden",
    })
    const handleCollapse = () => {
        if (collapsed) {
            setCustomPane1Style({ ...customPane1Style, height: "36%", transition: "height 0.5s ease" });
            setCustomPane2Style({
                ...customPane2Style, height: "84%", transition: "height 0.5s ease"
            })
        } else {
            setCustomPane1Style({ ...customPane1Style, height: "86%", maxHeight: "calc(100% - 100px)", transition: "height 0.5s ease, max-height 0.5s ease" })
            setCustomPane2Style({ ...customPane2Style, height: "36%", transition: "height 0.5s ease" })
        }
        setCollapsed(!collapsed)
    }

    const handleResize = () => {
        setCustomPane1Style({ display: "block", maxHeight: "calc(100% - 100px)", minHeight: `calc(100% - ${getTabSectionHeight(paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab)}px)` })
        setCustomPane2Style({
            // background: "#f9f9f9",
            // opacity: "1",
            // overflow: "hidden",
            minHeight: "99px"
        });
    }
    useEffect(() => {
        if (editIndex !== null && textAreaRefs.current[editIndex]) {
            const textarea = textAreaRefs.current[editIndex]!;
            textarea.focus();
            const length = textarea.value.length;
            textarea.setSelectionRange(length, length);
        }
    }, [editIndex]);


    const { setFlagLoading, setError, error, setFlagShowCreditsExhausted } = useContext(UserContext) as UserContextPayload;
    useEffect(() => {
        // Select the parent element with class "Pane horizontal Pane2"
        const parentElement = document.querySelector('.Pane.horizontal.Pane2');
        const paneOne = document.querySelector('.Pane.horizontal.Pane1')
        // Check if the parent element exists
        if (parentElement) {
            // Get the immediate child div
            parentElement.classList.add(styles.responsivePane2)
            const childDiv = parentElement.querySelector('div');

            // Add a class to the child div
            if (childDiv) {
                childDiv.classList.add(globalStyles.fullHeight);
            }
        }
        if (paneOne) {
            // Get the immediate child div
            paneOne.classList.add(styles.responsivePane1)

            const childDiv = paneOne.querySelector('div');

            // Add a class to the child div
            if (childDiv) {
                childDiv.classList.add(styles.pane1Child);
            }
        }
    }, []);

    const mobileScreen = useMediaQuery('(min-width: 320px) and (max-width: 599px)')

    let SubTabList = [

        {
            id: 1,
            tabName: "Basic"
        },
        {
            id: 2,
            tabName: "Advanced"
        },
        {
            id: 3,
            tabName: "Custom Prompt"
        },
        {
            id: 4,
            tabName: "Persona"
        }
    ]


    const length = [
        {
            name: 'Length',
            optionsList: [
                {
                    length: 'Short',
                    characterCount: '150 Characters'
                },
                {
                    length: 'Medium',
                    characterCount: '160 Chacters'
                },
                {
                    length: 'Long',
                    characterCount: '260 Characters'
                }
            ]
        }
    ]

    const staticNumList = [
        {
            name: 'Language',
            optionsList: ['1', '2', '3', '4', '5']

        }
    ]
    React.useEffect(() => {
        handleResize();
        if (rewriteToneGenericList) {
            let toneOfVoicePrompts = rewriteToneGenericList.filter((prompt: any) => (prompt.flag_custom == false && prompt.flag_narration == false))
            // setMorePrompts(tempList.standard_prompt_list?.slice(4, 10))
            setMorePrompts(toneOfVoicePrompts.sort((a: any, b: any) => a.seq_num - b.seq_num))
        }
    }, [rewriteToneGenericList])

    useEffect(() => {
        resizeFunction()
    }, [paraphrasingVersioningArr, window.innerWidth]); // Empty
    function generatePromptLogFieldData(commonKeys: any, genericList: any) {
        const prompt_log_field_data = [];

        for (const key in commonKeys) {
            const commonKeyWithoutUnderscore = key.replaceAll("_", " ").toLowerCase();
            for (const item of genericList) {
                const promptLogFieldNameWithoutUnderscore = item.prompt_log_field_name.toLowerCase();

                if (commonKeyWithoutUnderscore === promptLogFieldNameWithoutUnderscore) {
                    prompt_log_field_data.push({
                        "field_id": item.prompt_log_field_id,
                        "value": commonKeys[key]
                    });
                    break;
                }
            }
        }

        return prompt_log_field_data;
    }
    const getParaphrasePayload = () => {
        const commonKeys = {
            route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id ?? 2,
            output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
            // phrase: paraphrasingInput,
            phrase: paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput,
            language: paraphrasingVersioningArr[paraphrasingVersionIndex].selectedLanguage == "Same as Input" ? "" : paraphrasingVersioningArr[paraphrasingVersionIndex].selectedLanguage,
            num_of_output: paraphrasingVersioningArr[paraphrasingVersionIndex].selectedOutputCount,
            prompt_id: paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab == "Custom Prompt" ? Number(process.env.REACT_APP_CUSTOM_PARAPHRASE_ID) ?? 0 : Number(process.env.REACT_APP_PARAPHRASE_MD_ID) ?? 0,
            prompt_variant_id: flagUseGemini ? Number(process.env.REACT_APP_PARAPHRASE_GEMINI_VARIANT_ID) : null,
            gemini_ai_flag: flagUseGemini
            // prompt_id: paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab == "Custom Prompt" ? promptList !== null ? promptList.find((prompt: any) => prompt.prompt_name == "Custom Paraphrase")?.prompt_id : 0 : promptList !== null ? promptList.find((prompt: any) => prompt.prompt_name == "Paraphrase MD")?.prompt_id : 0
        };

        let additionalKeys;
        // if (currentSubTab === 'Basic') {
        if (paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab === "Basic") {
            additionalKeys = paraphrasingVersioningArr[paraphrasingVersionIndex].basicParaphrasingVal;
        } else if (paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab === 'Advanced') {
            // additionalKeys = advanceParaphrasingVal;
            additionalKeys = paraphrasingVersioningArr[paraphrasingVersionIndex].advanceParaphrasingVal;
        } else if (paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab === "Custom Prompt") {
            additionalKeys = paraphrasingVersioningArr[paraphrasingVersionIndex].customPromptPayload;
        } else {
            additionalKeys = paraphrasingVersioningArr[paraphrasingVersionIndex].personaPayload;
        }
        // for filtering keys that are not empty
        const filteredAdditionalKeys = Object.fromEntries(
            Object.entries(additionalKeys).filter(([key, value]) => {
                return value !== "" && (value !== null ? (Array.isArray(value) ? value.length > 0 : true) : true);
            })
        );

        const sentenceLengthMapping = {
            Short: { percentageVal: -40, val: "shorter" },
            Medium: { percentageVal: 5, val: "" },
            Long: { percentageVal: 40, val: "longer" },
        };

        // replacement for "sentence_length" key
        if (filteredAdditionalKeys.hasOwnProperty("sentence_length")) {
            const sentenceLengthValue = filteredAdditionalKeys["sentence_length"] as keyof typeof sentenceLengthMapping;

            // Calculate the new range based on the percentage and round to the nearest integer
            const percentageRange = sentenceLengthMapping[sentenceLengthValue];
            const range = Math.round(paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.length * (1 + percentageRange.percentageVal / 100));
            const sentenceLengthRange = sentenceLengthMapping[sentenceLengthValue].val;
            const getSentenceLength = () => {
                if (sentenceLengthValue == "Medium") {
                    return `${range}`
                } else {
                    return `${sentenceLengthRange}, ${range}`
                }
            }
            filteredAdditionalKeys["sentence_length"] = getSentenceLength();
        }
        let allKeys = { ...commonKeys, ...filteredAdditionalKeys }
        const prompt_log_field_data = generatePromptLogFieldData(allKeys, promptLogFieldGenericList)

        return { ...commonKeys, ...filteredAdditionalKeys, prompt_log_field_data };
    }
    const HandleWSMessage = (reponseWs: any) => {
        // @ts-ignore
        if (reponseWs.response.status) {
            if (reponseWs?.payload?.operation_id?.split("-")[0] == "prompt" && reponseWs?.payload?.operation_id?.split("-")[1] == "paraphrase") {
                if (Array.isArray(reponseWs.response.data)) {
                    // @ts-ignore
                    let temp = socketAPIBuffer.filter((api) => api.operation_id == reponseWs.operation_id && api.flagCancel)
                    if (temp.length > 0) {
                    } else {
                        let response = reponseWs.response
                        let temp = response.data.map((responseObj: any, index: number) => {
                            return {
                                id: index + 1,
                                index: 0,
                                value: [
                                    {
                                        id: index + 1,
                                        paraphrase: responseObj[response.prompt_output_key_name],
                                        checked: false,
                                        flagEditable: false,
                                        flagLoading: false,
                                        flagOpenMenu: false,
                                        anchorEl: null,
                                        showTooltip: false,
                                        showCardTooltip: false
                                    }
                                ]
                            }
                        })
                        setHasParaphras(true)
                        resizeFunction()
                        let paraphrasingVersioningLength = 0
                        setParaphraseVersioningarr((prevState: any, index: number) => {
                            const updatedArray = prevState.map((item: any, index: any) =>
                                index === paraphrasingVersionIndex
                                    ? { ...item, executePromptResponseParaphrase: temp, id: index + 1 }
                                    : item
                            );
                            paraphrasingVersioningLength = prevState.length
                            const lastObject = { ...prevState[prevState.length - 1], paraphrasingInput: reponseWs.payload.payload.phrase, executePromptResponseParaphrase: temp, id: prevState.length };
                            return [...updatedArray, lastObject];
                        });
                        const container = document.getElementById('stickyBottom')
                        if (container) {
                            setTimeout(() => {
                                container.scrollIntoView({ behavior: 'smooth' });
                            }, 400)
                        }
                        // setCurrentIndex(paraphrasingVersioningLength)
                        setCurrentIndex((prevState) => { return paraphrasingVersioningLength })
                        setParaphrasingVersionIndex((prevState) => { return paraphrasingVersioningLength })

                    }
                    // @ts-ignore
                    socketAPIBuffer = socketAPIBuffer.filter((api) => Number(api.operation_id.split("-")[2]) >= Number(reponseWs.operation_id.split("-")[2]))
                } else {
                    let errorCode = 1110
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }

                setFlagLoading((prevState) => {
                    return {
                        ...prevState,
                        flagContentGenerating: false,
                        flagRoundLoader: false
                    }
                })
            }
        } else {
            // @ts-ignore
            let temp = socketAPIBuffer.filter((api) => api.operation_id == reponseWs.operation_id && api.flagCancel)
            if (temp.length > 0) {
            } else {
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                setFlagOutlineLoader(false)
                setHasParaphras(false)
                let errorCode = checkForErrorCode(reponseWs.response)
                if (flagCookieExpired(userDetail, errorCode)) {
                    setUserDetail(emptyUserDetail)
                    if (userDetail.flag_user_anonymous) {
                        let url = window.location.pathname
                        navigate(url, { replace: true });
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: "Session expired! Please login to continue."
                        });
                        setTimeout(() => {
                            let url = encodeURIComponent(window.location.href)
                            navigate("/login?redirect=" + url, { replace: true });
                        }, 1500);
                    }
                } else {
                    if (flagCreditsExhausted(errorCode)) {
                        setFlagShowCreditsExhausted(true)
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                }
            }
        }
    }
    const handleInitializeSocket = async () => {
        try {
            let pingInterval: any
            const socketUrl: any = process.env.REACT_APP_SOCKET_URL;
            let webSocket: any;
            const createWebSocket = () => {
                webSocket = new WebSocket(socketUrl);
                setWebSocketObj(webSocket);

                webSocket.onopen = (event: any) => {
                    pingInterval = setInterval(() => {
                        if (webSocket.readyState === WebSocket.OPEN) {
                            webSocket.send("Keep Alive...");
                        }
                    }, 10000);
                };

                webSocket.onmessage = async (event: any) => {
                    const wsResponse = JSON.parse(event.data);
                    if (wsResponse) {
                        if (wsResponse?.payload?.url === "credit/get-user-credit-usage-data") {
                            const temp = {
                                remaining_credit_usage: wsResponse.response.user_monthly_credit_data.remaining_credit_usage,
                                total_credit_usage: wsResponse.response.user_monthly_credit_data.total_credit_usage,
                            };
                            setCreditUsed(temp);
                        } else if (wsResponse?.payload?.url === "ai/ask-ai") {
                            const reponseWs = JSON.parse(event.data);
                            HandleWSMessage(reponseWs);
                        } else {
                            setCreditUsed("");
                        }
                    }
                };

                webSocket.onclose = (event: any) => {
                    clearInterval(pingInterval);
                    pingInterval = null;
                    if (event.code !== 1000 && event.code !== 1008) {
                        createWebSocket();
                    }
                };
            };

            if (socketUrl) {
                createWebSocket();
            }

            return {
                status: true,
                webSocketObjTemp: webSocket
            }
        }
        catch (err) {
            console.log("error", err)
        }
    }
    const handleCreateUser = async () => {
        const payload = {
            google_recaptcha_token: null,
        };
        const response: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> =
            await Register(payload);
        if (response.status) {
            if (Array.isArray(response.data)) {
                setUserDetail({ ...response.data[0], flagUser: response.status, flagUserFromParaphrase: true })
            }
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
            setFlagLoading({ ...flagLoading, flagRoundLoader: false, flagContentGenerating: false });
        }
    };

    const handleLoaderFilterDetails = () => {
        setLoaderFilterDetails((prevState: any) => {
            const nextIndex = (prevState.index + 1) % prevState.loaderDetails.length;
            return { ...prevState, index: nextIndex };
        });
    }

    const handleParaphrase = async () => {
        // FOR INTIALIZING DATA TO LOADER
        handleLoaderFilterDetails()
        setLoadingURL(agent.executePrompt.getURL)
        setFlagLoading({ ...flagLoading, flagRoundLoader: false, flagContentGenerating: true });

        const container = document.getElementById('stickyBottom')
        if (container) {
            container.scrollIntoView({ behavior: 'smooth' });
        }
        if (userDetail.flagUser) {
        } else {
            await handleCreateUser();
        }
        if (flagUseSocketForParaphrase) {
            if (webSocketObj.readyState == 3) {
                let initializeSocket = await handleInitializeSocket();
                if (initializeSocket && initializeSocket.status && initializeSocket.webSocketObjTemp) {
                    setTimeout(async () => {
                        const payload = getParaphrasePayload()
                        let currentTime = new Date().getTime()
                        initializeSocket && initializeSocket?.webSocketObjTemp.send(JSON.stringify({
                            "url": "ai/ask-ai",
                            "operation_id": `prompt-paraphrase-${currentTime}`,
                            "payload": payload
                        }))
                        // @ts-ignore
                        socketAPIBuffer.push({ operation_id: `prompt-paraphrase-${currentTime}`, flagCancel: false })
                    }, 5000)
                }
            } else {
                const payload = getParaphrasePayload()
                let currentTime = new Date().getTime()
                webSocketObj.send(JSON.stringify({
                    "url": "ai/ask-ai",
                    "operation_id": `prompt-paraphrase-${currentTime}`,
                    "payload": payload
                }))
                // @ts-ignore
                socketAPIBuffer.push({ operation_id: `prompt-paraphrase-${currentTime}`, flagCancel: false })
            }
        } else {
            const response = await agent.executePrompt.post(getParaphrasePayload())
            if (response.status) {
                let temp = response.data.map((responseObj: any, index: any) => {
                    return {
                        id: index + 1,
                        index: 0,
                        value: [
                            {
                                id: index + 1,
                                paraphrase: responseObj[response.prompt_output_key_name],
                                checked: false,
                                flagEditable: false,
                                flagLoading: false,
                                flagOpenMenu: false,
                                anchorEl: null,
                                showTooltip: false,
                                showCardTooltip: false
                            }
                        ]
                    }
                })
                setHasParaphras(true)
                resizeFunction()

                setParaphraseVersioningarr((prevState: any) => {
                    const updatedArray = prevState.map((item: any, index: any) =>
                        index === paraphrasingVersionIndex
                            ? { ...item, executePromptResponseParaphrase: temp }
                            : item
                    );
                    const lastObject = { ...prevState[prevState.length - 1], executePromptResponseParaphrase: temp, id: prevState[prevState.length - 1].id + 1 };
                    return [...updatedArray, lastObject];
                });
                const container = document.getElementById('stickyBottom')
                if (container) {
                    setTimeout(() => {
                        container.scrollIntoView({ behavior: 'smooth' });
                    }, 400)
                }
                setCurrentIndex(paraphrasingVersioningArr.length)
                setParaphrasingVersionIndex(paraphrasingVersioningArr.length)
            } else {
                setHasParaphras(false)
                let errorCode = checkForErrorCode(response)
                if (flagCookieExpired(userDetail, errorCode)) {
                    setUserDetail(emptyUserDetail)
                    if (userDetail.flag_user_anonymous) {
                        let url = window.location.pathname
                        navigate(url, { replace: true });
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: "Session expired! Please login to continue."
                        });
                        setTimeout(() => {
                            let url = encodeURIComponent(window.location.href)
                            navigate("/login?redirect=" + url, { replace: true });
                        }, 1500);
                    }
                } else {
                    if (flagCreditsExhausted(errorCode)) {
                        setFlagShowCreditsExhausted(true)
                    } else if (response.message == "canceled") {
                    }
                    else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                }
            }
        }
    }
    // Handlers
    const handleChangeSubTab = (event: React.SyntheticEvent, newValue: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('subTab', newValue);
        const newUrl = window.location.pathname + '?' + searchParams.toString();
        window.history.replaceState({}, '', newUrl);
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    { ...item, currentSubTab: newValue } : item
            ));
        setCustomPane1Style({
            display: "block",
            maxHeight: `calc(100% - ${getTabSectionHeight(newValue)}px)`,
            //  maxHeight: "calc(100% - 100px)",
            minHeight: `calc(100% - ${getTabSectionHeight(newValue)}px)`,
            transition: "max-height 0.5s ease, min-height 0.3s ease"
        });
        // setCustomPane2Style({ ...customPane2Style, maxHeight: `$calc(${getTabSectionHeight(newValue)}px` })
    };
    const handleChangeParaphraseInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.trim().split(/\s+/).filter((st: any) => st != '').length > 150) {
            setParaphraseVersioningarr((prevState: any) =>
                prevState.map((item: any, index: any) =>
                    index == paraphrasingVersionIndex ?
                        { ...item, paraphrasingInput: event.target.value, selectedOutputCount: 1 } : item
                ))
        } else {
            setParaphraseVersioningarr((prevState: any) =>
                prevState.map((item: any, index: any) =>
                    index == paraphrasingVersionIndex ?
                        { ...item, paraphrasingInput: event.target.value } : item
                ))
        }
    }

    const handleParaphraseOutputChange = (event: any, option: any) => {
        const { value } = event.target;
        const updatedResponse = paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.map((item: any) =>
            item.id === option.id ?
                {
                    ...item,
                    value: [
                        ...item.value.map((val: any, index: any) =>
                            index === option.index ? { ...val, paraphrase: value } : val)
                    ]
                }
                : item
        );
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                item.id == paraphrasingVersioningArr[paraphrasingVersionIndex].id ?
                    { ...item, executePromptResponseParaphrase: updatedResponse } : item
            ));
    }

    const [copyText, setCopyText] = React.useState("Copy")

    const handleCopyToClipboard = (textToCopy: any) => {
        if (selectedOutputResponse) {
            try {
                navigator.clipboard.writeText(selectedOutputResponse.paraphrase);
                setCopyText("Copied!"); // Update the button text
            } catch (error) {
                setCopyText("Copy Failed"); // Handle copy failure
            }
        }
    };
    const handleClearText = () => {
        const paraphraseInput = document.getElementById('paraphraseInput');
        paraphraseInput?.focus();
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    { ...item, paraphrasingInput: "" } : item
            ))
        // handleCloseSection1Menu()
    }
    const handleTrySampleText = () => {
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    { ...item, paraphrasingInput: "Paraphraser takes your sentences and makes changes, helping you to rework and rephrase your content quickly and efficiently!" } : item
            ))
    }


    const getParaphraseDisableFlag = () => {
        if (flagLoading.flagContentGenerating) {
            return true
        } else {
            if (
                paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length == 0 ||
                paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().length < 4 ||
                paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 1000
            ) {
                return true
            } else if (paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab === "Custom Prompt") {
                //  else if (currentSubTab == "Custom Prompt") {
                if (paraphrasingVersioningArr[paraphrasingVersionIndex].customPromptPayload.user_custom_prompt.length < 4) {
                    return true
                } else {
                    return false
                }
            }
        }
    }

    const CustomPaper = (props: any) => (
        <Paper {...props} className={styles.autocompletePaper} placement="top"
        />
    );
    const handleUseAsInput = (valueToReplace: string, option: any) => {
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    {
                        ...item, paraphrasingInput: valueToReplace, executePromptResponseParaphrase: item.executePromptResponseParaphrase.map((output: any) =>
                            output.id === option.id
                                ? {
                                    ...output,
                                    value: output.value.map((val: any, index: any) =>
                                        index === option.index ? { ...val, flagOpenMenu: false } : val
                                    )
                                }
                                : output
                        )
                    } : item
            ));
    }
    const handle = (currentoption: any) => {
    }
    const handleRewriteOutputOption = async (optionToRewrite: executePromptResponseParaphraseObj) => {
        // Update the UI to indicate loading
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    {
                        ...item, executePromptResponseParaphrase: item.executePromptResponseParaphrase.map((item: any) =>
                            item.id === optionToRewrite.id
                                ? {
                                    ...item,
                                    value: item.value.map((val: any, index: any) =>
                                        index === optionToRewrite.index ? { ...val, flagLoading: true } : val
                                    )
                                }
                                : item
                        )
                    } : item
            ))
        const payload = {
            phrase: optionToRewrite.value[0].paraphrase,
            num_of_output: 1,
            prompt_id: Number(process.env.REACT_APP_PARAPHRASE_MD_ID) ?? 0,
            // prompt_id: promptList !== null ? promptList.find((prompt: any) => prompt.prompt_name == "Paraphrase MD")?.prompt_id : 0,
            route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id ?? 2,
            output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
            prompt_variant_id: flagUseGemini ? Number(process.env.REACT_APP_PARAPHRASE_GEMINI_VARIANT_ID) : null,
            gemini_ai_flag: flagUseGemini
        };

        try {
            const response = await agent.executePrompt.post(payload);

            if (response.status) {
                // Update the UI with the rewritten content
                const newVersion = {
                    id: optionToRewrite.value[(optionToRewrite.value.length - 1)].id + 1,
                    paraphrase: response.data[0][response.prompt_output_key_name],
                    checked: false,
                    flagEditable: false,
                    flagLoading: false
                }

                // conditions are to also change the executepromptResponse response in  replica and as well as the original arr
                setParaphraseVersioningarr((prevState: any) =>
                    prevState.map((item: any, index: any) => {
                        if (paraphrasingVersioningArr.length - 1 == currentIndex) {
                            // Update executePromptResponse for paraphrasingVersionIndex and paraphrasingVersionIndex - 1
                            if (index === paraphrasingVersionIndex || index === (paraphrasingVersionIndex - 1)) {
                                return {
                                    ...item,
                                    executePromptResponseParaphrase: item.executePromptResponseParaphrase.map((innerItem: any) =>
                                        innerItem.id === optionToRewrite.id ? {
                                            ...innerItem,
                                            value: [
                                                ...innerItem.value.map((val: any, innerIndex: any) =>
                                                    innerIndex === optionToRewrite.index ? { ...val, flagLoading: false } : val
                                                ),
                                                newVersion
                                            ],
                                            index: innerItem.value.length
                                        } : innerItem
                                    )
                                };
                            }
                        } else if (index === (currentIndex - 2)) {
                            // Update for currentIndex - 2
                            return {
                                ...item,
                                executePromptResponseParaphrase: item.executePromptResponseParaphrase.map((innerItem: any) =>
                                    innerItem.id === optionToRewrite.id ? {
                                        ...innerItem,
                                        value: [
                                            ...innerItem.value.map((val: any, innerIndex: any) =>
                                                innerIndex === optionToRewrite.index ? { ...val, flagLoading: false } : val
                                            ),
                                            newVersion
                                        ],
                                        index: innerItem.value.length
                                    } : innerItem
                                )
                            };
                        }
                        return item;
                    })
                );

            } else {
                setParaphraseVersioningarr((prevState: any) =>
                    prevState.map((item: any, index: any) =>
                        index == paraphrasingVersionIndex ?
                            {
                                ...item, executePromptResponseParaphrase: item.executePromptResponseParaphrase.map((item: any) =>
                                    item.id === optionToRewrite.id
                                        ? {
                                            ...item,
                                            value: item.value.map((val: any, index: any) =>
                                                index === optionToRewrite.index ? { ...val, flagLoading: false } : val
                                            )
                                        }
                                        : item
                                )
                            } : item
                    ))
                const errorCode = response.isAxiosError ? (response.response?.data?.error?.code || response?.error?.code) : response?.error?.code;
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true);
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        } catch (errorr) {
            setParaphraseVersioningarr((prevState: any) =>
                prevState.map((item: any, index: any) =>
                    index == paraphrasingVersionIndex ?
                        {
                            ...item, executePromptResponseParaphrase: item.executePromptResponseParaphrase.map((item: any) =>
                                item.id === optionToRewrite.id
                                    ? {
                                        ...item,
                                        value: item.value.map((val: any, index: any) =>
                                            index === optionToRewrite.index ? { ...val, flagLoading: false } : val
                                        )
                                    }
                                    : item
                            )
                        } : item
                ))
            setError({ ...error, flagShowError: true, message: "An error occurred. Please try again later!" });
        }
    };

    const handleMultipleRewrite = async () => {
        let executePromptResponseParaphrase2 = paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.map((item: any) => {
            let filteredValue = item.value.filter((val: any, index: any) => val.checked === true && index === item.index);

            if (filteredValue.length > 0) {
                return {
                    id: item.id,
                    index: item.index,
                    value: filteredValue
                };
            }

            return null;
        }).filter(Boolean);

        try {
            await Promise.all(executePromptResponseParaphrase2.map((option: any) => {
                if (option !== null) {
                    return handleRewriteOutputOption(option);
                }
                return null;
            }));
        } catch (errorr) {

            setError({ ...error, flagShowError: true, message: "An error occurred. Please try again later!" });
        }
    };

    const handleMultipleDelete = async () => {
        const optionsToKeep = paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.map((option: executePromptResponseParaphraseObj) => {
            const newValue = option.value.filter((option: executePromptResponseParaphraseValueObj) => !option.checked);
            return newValue.length == 0 ? null : { ...option, value: newValue, index: newValue.length - 1 };
        });
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    {
                        ...item, executePromptResponseParaphrase: optionsToKeep.filter((option: executePromptResponseParaphraseObj) => option !== null)
                    } : item
            ))
    };

    const handleChangeCheckOutputOptionCard = (event: React.ChangeEvent<HTMLInputElement>, option: executePromptResponseParaphraseObj) => {
        const { checked } = event.target;
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    {
                        ...item, executePromptResponseParaphrase: paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.map((item: any) =>
                            item.id === option.id
                                ? {
                                    ...item,
                                    value: [
                                        ...item.value.map((val: any, index: any) =>
                                            index === option.index ? { ...val, checked: checked } : val
                                        ),
                                    ],
                                }
                                : item
                        )
                    } : item
            ))
    }
    const handleOpenMenu = (event: React.ChangeEvent<HTMLInputElement>, option: executePromptResponseParaphraseObj) => {
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    {
                        ...item, executePromptResponseParaphrase: paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.map((item: any) =>
                            item.id === option.id
                                ? {
                                    ...item,
                                    value: [
                                        ...item.value.map((val: any, index: any) =>
                                            index === option.index ? { ...val, flagOpenMenu: !val.flagOpenMenu, anchorEl: event.currentTarget } : val
                                        ),
                                    ],
                                }
                                : item
                        )
                    } : item
            ))
    }
    const handleCloseMenu = (event: React.ChangeEvent<HTMLInputElement>, option: executePromptResponseParaphraseObj) => {
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    {
                        ...item, executePromptResponseParaphrase: paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.map((item: any) =>
                            item.id === option.id
                                ? {
                                    ...item,
                                    value: [
                                        ...item.value.map((val: any, index: any) =>
                                            index === option.index ? { ...val, flagOpenMenu: !val.flagOpenMenu, anchorEl: event.currentTarget } : val
                                        ),
                                    ],
                                }
                                : item
                        )
                    } : item
            ))
    }
    const handleToggleOutputEditable = (option: executePromptResponseParaphraseObj, flagEditable: boolean, index?: number) => {
        // @ts-ignore
        setEditIndex(flagEditable ? index : null);
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    {
                        ...item, executePromptResponseParaphrase: paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.map((item: any) =>
                            item.id === option.id
                                ? {
                                    ...item,
                                    value: [
                                        ...item.value.map((val: any, index: any) =>
                                            index === option.index ? { ...val, flagEditable: flagEditable } : val
                                        ),
                                    ],
                                }
                                : item
                        )
                    } : item
            ))
    }
    const handleToggleInputEdit = (flagEdit: boolean) => {
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    { ...item, flagInputEditable: flagEdit } : item
            ))
    }
    const deleteOutputOptionCard = (optionToDelete: executePromptResponseParaphraseObj) => {
        const updated = paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.map((item: any) =>
            item.id === optionToDelete.id
                ?
                optionToDelete.value.length == 1 ? null :
                    {
                        ...item,
                        index: item.value.filter((val: any, index: any) => index !== optionToDelete.index).length - 1,
                        value: item.value.filter((val: any, index: any) => index !== optionToDelete.index),
                    }
                : item
        );

        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    {
                        ...item, executePromptResponseParaphrase: updated.filter((item: any) => item !== null)
                    } : item
            ))
    };
    function contentLexicalObjFun(contentValue: string, indent: number = 0) {
        return {
            "children": [
                {
                    "detail": 0,
                    "format": 0,
                    "mode": "normal",
                    "style": "",
                    "text": contentValue,
                    "type": "text",
                    "version": 1
                }
            ],
            "direction": "ltr",
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
        }
    }
    const handleCreateDoc = async (templateId: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const defaultPersona = personaList?.find((persona) => persona.user_default_persona_data.find((personaData) => personaData.default_persona_type_name == "General"));
        const personaId = defaultPersona?.persona_id ?? null;
        const payload = {
            template_id: templateId,
            persona_id: personaId
        }
        const response = await CreateDoc(payload)
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        if (response.status) {
            if (response.data) {
                let newChild: any = [];
                if (response.data[0]) {
                    let checkedOptions = paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value)
                    if (checkedOptions.some((option: any) => option.checked)) {
                        checkedOptions.map((option: any) => option.checked == true &&
                            newChild.push(contentLexicalObjFun(option.paraphrase))
                        )
                    } else {
                        checkedOptions.map((option: any) => newChild.push(contentLexicalObjFun(option.paraphrase))
                        )
                    }
                    let lexicalDataArray: any = {
                        root: {
                            "children": [],
                            "direction": "ltr",
                            "format": "",
                            "indent": 0,
                            "type": "root",
                            "version": 1
                        }
                    }
                    lexicalDataArray['root']['children']?.push(...newChild)
                    setDocumentEditorData(lexicalDataArray)

                    let finalObj: any = {}
                    finalObj['document_uuid'] = response.data[0].document_uuid
                    finalObj['document_step_id'] = response.data[0]?.document_step_data?.filter((stepDetail) => stepDetail.step_type_name == "Editor")[0]?.document_step_id
                    finalObj['document_lexical_editor_data'] = [lexicalDataArray?.root]
                    // finalObj['document_lexical_editor_data'] = [documentEditorData?.root]
                    let response1 = await UpdateDocumentEditorDetail(finalObj)
                    // debugger
                    if (!response1.status) {
                        setError({ ...error, flagShowError: true, message: "Something went wrong. Please try again!" })
                    } else {
                        window.open(`${window.location.origin}/doc/${response.data[0].document_uuid}/3/${response.data[0].template_id}`, "_blank")
                    }


                }
            }
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
        }

    }

    const handleSaveDocument = async () => {
        let blankTemplateId = templateList !== null ? templateList[0].category_list.find((category) => category.category_name == "General")?.template_data_list.find((template) => template.template_type_name == "Blank")?.template_id : 0;
        handleCreateDoc(blankTemplateId ?? 0)
    }
    const handleMultipleUseAsInput = () => {
        const outputOptions = paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value)
        const checkedOptions = outputOptions.filter((option: any) => option.checked);

        const newLongText = checkedOptions.map((option: any) => option.paraphrase).join('\n');
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    { ...item, paraphrasingInput: newLongText } : item
            ))
    }
    const multipleCopyText = () => {
        const checkedOptions = paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value);

        const newLongText = checkedOptions
            .filter((option: any) => option.checked)
            .map((option: any) => option.paraphrase)
            .join('\n');

        return newLongText;
    };
    const handleIncOutputOptionVersion = (option: executePromptResponseParaphraseObj) => {
        const updatedResponse = paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.map((item: any) =>
            item.id === option.id ?
                {
                    ...item,
                    index: item.index + 1
                }
                : item
        );
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    { ...item, executePromptResponseParaphrase: updatedResponse } : item
            ));
    }
    const handleDecOutputOptionVersion = (option: executePromptResponseParaphraseObj) => {
        const updatedResponse = paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.map((item: any) =>
            item.id === option.id ?
                {
                    ...item,
                    index: item.index - 1
                }
                : item
        );
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == paraphrasingVersionIndex ?
                    { ...item, executePromptResponseParaphrase: updatedResponse } : item
            ));
    }

    const checkForValues = () => {
        const currentIndex = paraphrasingVersionIndex;
        const currentArray = paraphrasingVersioningArr[currentIndex]?.executePromptResponseParaphrase || [];
        return currentArray.some((valueItem: any) => valueItem.value.length > 0);
    };

    return (
        <>
            {mobileScreen ?
                <ParaphrasingMobileComponent
                    hasParaphras={hasParaphras}
                    handleClearText={handleClearText}
                    handleTrySampleText={handleTrySampleText}
                    currentTab={currentTab}
                    handleChangeSubTab={handleChangeSubTab}
                    SubTabList={SubTabList}
                    morePrompts={morePrompts}
                    length={length}
                    CustomPaper={CustomPaper}
                    mobileScreen={mobileScreen}
                    handleToggleInputEdit={handleToggleInputEdit}
                    handleChangeParaphraseInput={handleChangeParaphraseInput}
                    handleParaphraseOutputChange={handleParaphraseOutputChange}
                    handleToggleOutputEditable={handleToggleOutputEditable}
                    checkForValues={checkForValues}
                    handleDecOutputOptionVersion={handleDecOutputOptionVersion}
                    handleRewriteOutputOption={handleRewriteOutputOption}
                    copyText={copyText}
                    setCopyText={setCopyText}
                    handleCopyToClipboard={handleCopyToClipboard}
                    handleChangeCheckOutputOptionCard={handleChangeCheckOutputOptionCard}
                    handleUseAsInput={handleUseAsInput}
                    deleteOutputOptionCard={deleteOutputOptionCard}
                    handleSaveDocument={handleSaveDocument}
                    handleMultipleUseAsInput={handleMultipleUseAsInput}
                    handleIncOutputOptionVersion={handleIncOutputOptionVersion}
                    handleMultipleRewrite={handleMultipleRewrite}
                    multipleCopyText={multipleCopyText}
                    handleMultipleDelete={handleMultipleDelete}
                    getParaphraseDisableFlag={getParaphraseDisableFlag}
                    handleParaphrase={handleParaphrase}
                    handleOpenMenu={handleOpenMenu}
                    handleCloseMenu={handleCloseMenu}
                    textAreaRefs={textAreaRefs}
                    editIndex={editIndex}
                />
                :
                <>
                    <Grid item xs={12} className={`${styles.ppInputOutput} ${styles.ppIpOpMainItemWrapper}`} >
                        <Grid container justifyContent={'space-between'} className={`${styles.mainContainer} ${styles.ppIpOpMainContainerWrapper}`}>

                            {/* Input */}
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12} className={`${styles.ppInputContainer} ${styles.ppInMainItemWrapper}`}>
                                <Grid container className={`${styles.ppInMainContainerWrapper} ${styles.leftBlock}`}
                                >

                                    <Grid item xs={12} className={styles.ppInputContainerHeader}>
                                        <Grid container justifyContent={"space-between"}>
                                            <Grid item className={styles.inputTextStyle}>Input <span className={styles.characterCountText} style={{ color: "#7A7A7A" }}>100+ Languages Supported</span></Grid>


                                            <Grid item onClick={() => { paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.length > 0 ? handleClearText() : handleTrySampleText() }}
                                                sx={{ color: "#6A097D !important" }}
                                                className={`${styles.clearInputText} ${globalStyles.clickable}`}>{paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 0 ? "Clear Input" : "Try Sample Text"}</Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}
                                        className={styles.ppInputAndOptions}>
                                        <Grid container
                                            sx={{ minHeight: `calc(${viewportHeight}px - 200px)`, maxHeight: `calc(${viewportHeight}px - 200px)`, position: "relative" }}
                                        >
                                            <>
                                                {/* @ts-ignore */}
                                                <SplitPane
                                                    className={`${styles.desktopSplitPaneStyle}`}
                                                    split="horizontal"
                                                    pane2Style={customPane2Style}
                                                    pane1Style={customPane1Style}
                                                    onChange={handleResize}
                                                    resizerClassName={styles.resizerStyle}
                                                >
                                                    <div>
                                                        <Grid item xs={12} className={`${styles.ppInputFieldItem} ${globalStyles.fullHeight}`} sx={{ position: "relative" }} >
                                                            <GlobalTextField
                                                                id='paraphraseInput'
                                                                autoFocus={true}
                                                                value={paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeParaphraseInput(e)}
                                                                onBlur={() => { handleToggleInputEdit(false) }}
                                                                placeholder="To paraphrase text, enter or paste your content in the language of your choice, then press the 'Paraphrase' button."
                                                                rows={6}
                                                                multiline
                                                                className={`${styles.ppGlobalMultilineTextField}`}
                                                            />
                                                        </Grid>
                                                        <div className={collapsed ? styles.upArrowStyles : styles.downArrowStyles} style={{ transform: collapsed ? "rotate(180deg)" : "" }}>
                                                            <img
                                                                alt="drawerArrow"
                                                                style={{ transform: "rotate(90deg)", width: "25px", height: "25px" }}
                                                                src={closeArrow}
                                                                onClick={() => {
                                                                    handleCollapse()
                                                                }}
                                                            />
                                                        </div>
                                                        {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.length > 0 &&
                                                            <Grid item sx={{ position: "absolute", bottom: "0", paddingLeft: "14px !important", padding: "8px" }} className={styles.characterCountText}>Words: {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length} Characters: {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.length}
                                                                {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 900 &&
                                                                    <span style={{ color: "red", fontSize: "13px", zIndex: "1", fontWeight: "600" }}> {paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length} / 1000 words</span>}

                                                            </Grid>}
                                                    </div>

                                                    <div>
                                                        <Grid item xs={12} className={globalStyles.fullHeight}>
                                                            <Grid container sx={{ height: "100%", flexDirection: "column", flexWrap: "nowrap" }}>
                                                                {/* lower tabs */}
                                                                <Grid item xs={12}
                                                                    sx={{ padding: "0px !important" }}
                                                                    className={`${styles.ppInputFieldOptions} ${styles.ppInputFieldOptionsHeightCompact}`}
                                                                >
                                                                    <Grid container direction="column" sx={{
                                                                        height: "100%",
                                                                    }}>
                                                                        <Grid item xs={12} sx={{ height: "100%" }}>
                                                                            <Grid container direction={"column"} sx={{ flexWrap: "nowrap", maxHeight: "100%" }}>
                                                                                <Grid item xs={12} className={styles.preferencesTextStyle} >Set your output preferences</Grid>
                                                                                <Grid item className={globalStyles.fullHeight} sx={{ position: "absolute", top: "22px", width: "100%" }}>
                                                                                    <Grid container className={`${globalStyles.fullHeight} ${globalStyles.containerFlexWrap}`} direction={"column"}>
                                                                                        <Grid item className={styles.tabParent} >
                                                                                            <Tabs
                                                                                                // className={styles.ppTabs}
                                                                                                className={styles.ppTabsSecond}
                                                                                                // value={currentSubTab}
                                                                                                value={paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab}
                                                                                                onChange={handleChangeSubTab}
                                                                                                textColor="secondary"
                                                                                                indicatorColor="secondary"
                                                                                                aria-label="secondary tabs example"
                                                                                            >
                                                                                                {SubTabList.map((tabDetail, i) => (
                                                                                                    <Tab className={`${styles.tabStyle}`} key={tabDetail.id} value={tabDetail.tabName} label={tabDetail.tabName} />
                                                                                                ))}
                                                                                            </Tabs>
                                                                                        </Grid>
                                                                                        <Grid item xs={12} className={styles.inputOptions}
                                                                                            sx={{ maxHeight: "calc(100% - 65px)", overflow: "scroll" }}
                                                                                        >
                                                                                            <Grid container gap={"0.2rem"} className={styles.inputOptionsChild} >
                                                                                                {
                                                                                                    paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab == 'Basic' ? (
                                                                                                        <BasicParaphrasingTab
                                                                                                            morePrompts={morePrompts}
                                                                                                            length={length}
                                                                                                            CustomPaper={CustomPaper}
                                                                                                            mobileScreen={mobileScreen}
                                                                                                        />
                                                                                                        // BasicParaphrasing(morePrompts, length, setParaphrasingLength, selectedParaphrasingLength, basicParaphrasingVal, setBasicParaphrasingVal, CustomPaper, paraphrasingVersioningArr, setParaphraseVersioningarr, paraphrasingVersionIndex, mobileScreen)
                                                                                                    ) :
                                                                                                        paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab == 'Advanced' ?
                                                                                                            <AdvanceParaphrasingTab length={length} morePrompts={morePrompts} CustomPaper={CustomPaper} />
                                                                                                            // AdvancedTabs(length, morePrompts, setAdvanceParaphrasingVal, advanceParaphrasingVal, personaWritingSkills, formatList, depthList, rewriteToneGenericList, workDomainList, CustomPaper, paraphrasingVersioningArr, setParaphraseVersioningarr, paraphrasingVersionIndex, mobileScreen)
                                                                                                            :
                                                                                                            <>
                                                                                                                {paraphrasingVersioningArr[paraphrasingVersionIndex].currentSubTab == "Custom Prompt" ?
                                                                                                                    (<>
                                                                                                                        <CustomParaphrasingTab />
                                                                                                                    </>)
                                                                                                                    :
                                                                                                                    <PersonaParaphrasingTab length={length} />
                                                                                                                }

                                                                                                            </>
                                                                                                }
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                    </Grid>

                                                                                </Grid>

                                                                            </Grid>
                                                                        </Grid>
                                                                        {/* } */}
                                                                    </Grid>
                                                                    <Divider className={styles.dividerStyle} />
                                                                </Grid>

                                                                {/* language, no of output */}
                                                                <Grid item xs={12} className={styles.optionStatic}>
                                                                    <Grid container
                                                                        className={`${styles.paraphraseLangButtonParent} ${globalStyles.containerFlexWrap}`}
                                                                        justifyContent={'space-between'} alignItems={"center"}>

                                                                        {/* language and no of output */}
                                                                        <Grid item className={styles.languageOutputCountContainer}>
                                                                            <Grid container alignItems={"end"} justifyContent={"space-between"} className={`${globalStyles.containerflexWrapStyle} ${styles.languageOutputCountContainerChild}`}>
                                                                                {/* language */}
                                                                                <Grid item className={styles.languageContainer} style={{ textAlign: "center" }}>
                                                                                    <Grid container className={`${globalStyles.containerflexWrapStyle} ${styles.languageContainerChild}`} justifyContent={"start"} alignItems="center">
                                                                                        <Grid item sx={{ minWidth: "127px" }} className={styles.bottomTextStyle}>Output Language</Grid>
                                                                                        <Grid item className={styles.languageParent}>
                                                                                            <Autocomplete
                                                                                                clearIcon={<></>}
                                                                                                className={`${styles.languageAutocomplete} ${styles.autocompleteParaphrasing}`}
                                                                                                disablePortal
                                                                                                id="combo-box-demo"
                                                                                                options={['Same as Input', ...languageList.map((language) => language.name)]}
                                                                                                defaultValue={'Same as Input'}
                                                                                                value={paraphrasingVersioningArr[paraphrasingVersionIndex].selectedLanguage == "" ? "Same as Input" : paraphrasingVersioningArr[paraphrasingVersionIndex].selectedLanguage}
                                                                                                onChange={(e, value: any) => {
                                                                                                    setParaphraseVersioningarr((prevState: any) =>
                                                                                                        prevState.map((item: any, index: any) =>
                                                                                                            index == paraphrasingVersionIndex ?
                                                                                                                { ...item, selectedLanguage: value == "Same as Input" ? "" : value } : item
                                                                                                        ));
                                                                                                }}
                                                                                                renderInput={(params) => <TextField {...params} placeholder='Search' type="" />}
                                                                                                PaperComponent={({ children }) => (
                                                                                                    <Paper className={styles.languagePaperStyle}>{children}</Paper>
                                                                                                )}
                                                                                                PopperComponent={(props) => (
                                                                                                    <Popper {...props} placement="top" />
                                                                                                )}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                {/* number of output */}
                                                                                <Grid item className={styles.outputCountContainer}>
                                                                                    <Grid container className={`${globalStyles.containerflexWrapStyle} ${styles.noOfOutputContainerChild}`} alignItems={"center"}>
                                                                                        <Grid item>
                                                                                            <Typography className={styles.bottomTextStyle}>#Output</Typography>
                                                                                        </Grid>
                                                                                        <Grid item className={styles.noOutputParent}>
                                                                                            <Autocomplete
                                                                                                disablePortal
                                                                                                clearIcon={<></>}
                                                                                                className={styles.autocompleteParaphrasing}
                                                                                                id="combo-box-demo"
                                                                                                options={staticNumList[0].optionsList}
                                                                                                value={paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 150 ? 1 : paraphrasingVersioningArr[paraphrasingVersionIndex].selectedOutputCount}
                                                                                                disabled={paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 150}
                                                                                                onChange={(e, value: any) => {
                                                                                                    setParaphraseVersioningarr((prevState: any) =>
                                                                                                        prevState.map((item: any, index: any) =>
                                                                                                            index == paraphrasingVersionIndex ?
                                                                                                                { ...item, selectedOutputCount: value } : item
                                                                                                        ));
                                                                                                }}
                                                                                                renderInput={(params) => <TextField {...params} />}
                                                                                                PopperComponent={(props) => (
                                                                                                    <Popper {...props} placement="top" className={styles.singleStructureDropdown} />
                                                                                                )}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {/* paraphrase button */}
                                                                        <Grid item className={styles.paraphraseButtonContainer} sx={{ width: "170px" }}>
                                                                            <GlobalButton
                                                                                buttonText="Paraphrase"
                                                                                flagFullWidth
                                                                                height='40px !important'
                                                                                className="primaryButtonStyle"
                                                                                disabled={getParaphraseDisableFlag()}
                                                                                onClick={handleParaphrase}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </SplitPane>
                                            </>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <>
                                {/* Output */}
                                <Grid item
                                    xl={6} lg={6} md={12} sm={12} xs={12}
                                    className={`${styles.ppOutputContainer}`}
                                    sx={{ maxHeight: "calc(100vh - 156px)" }}
                                >
                                    <Grid container sx={{ height: "100%" }} direction={"row"} justifyContent={"start"} alignItems={"start"}>
                                        {(paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length == 0 && laptopScreen && !flagLoading.flagContentGenerating) ?

                                            <>
                                                {/* @ts-ignore */}
                                                <Carousel
                                                    className='imageCarousel'
                                                    showArrows={true}
                                                    // @ts-ignore
                                                    renderArrow={props => <img alt="" src={backArrowCarousel} className={props.type == "PREV" ? "backArrowCarouselLeft" : "backArrowCarouselRight"} style={{ opacity: props.isEdge ? "0.5" : "", zIndex: "1" }} {...props} />}
                                                >
                                                    <div style={{ objectFit: "contain", position: "relative" }}>
                                                        <img alt="" src={languageImg} style={{
                                                            width: "100%", height: "100%", boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)", borderRadius: "10px"
                                                        }} />
                                                    </div>
                                                    <div style={{ objectFit: "contain" }}>
                                                        <img alt="" src={toneOfVoiceImg} style={{ width: "100%", height: "100%", boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)", borderRadius: "10px" }} />
                                                    </div>
                                                </Carousel>
                                            </>
                                            :
                                            <>
                                                <div className={styles.rightBlock}>
                                                    <Grid item xs={12} className={`${styles.ppOutputContainerHeader} ${globalStyles.fullwidth}`}>
                                                        <Typography>Output</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{
                                                        width: "100%",
                                                        height: "calc(100% - 44px)",
                                                        position: "relative"
                                                    }} className={styles.mbOutputOutsideItem}>
                                                        {flagLoading.flagContentGenerating ?

                                                            <RoundLoader />
                                                            :
                                                            <Grid container sx={{ height: "100%" }}>
                                                                <Grid item xs={12} id="stickyBottom" sx={{ height: "100%" }} className={styles.ppOutputTextFields}>
                                                                    {paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length ?
                                                                        <Grid container direction={"column"} className={`${globalStyles.fullHeight} ${styles.outputMainItem}`} >
                                                                            <Grid item xs={12} className={styles.ppOutputField}>
                                                                                {
                                                                                    paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase?.map((option: executePromptResponseParaphraseObj, index: number) => {
                                                                                        return (
                                                                                            <>
                                                                                                {option.value.length > 0 &&
                                                                                                    <div key={option.id} className={styles.outputCardContainer} >

                                                                                                        {/* option number and words and char*/}
                                                                                                        <div className={styles.firstBlock} >
                                                                                                            <div className={styles.optionNumWrapper}>
                                                                                                                <div className={styles.indexStyle}> Option {index + 1}</div>
                                                                                                                <div className={styles.mbOptionCharText}>{option.value[option.index].paraphrase.trim().split(/\s+/).filter(st => st != '').length} W {option.value[option.index].paraphrase.length} C</div>
                                                                                                            </div>
                                                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                                        {/* pagination */}
                                                                                                                        {option.value.length > 1 &&
                                                                                                                            <>
                                                                                                                                <div>
                                                                                                                                    <IconsColor
                                                                                                                                        iconName="decIndex"
                                                                                                                                        className={option.index !== 0 ? globalStyles.clickable : ""}
                                                                                                                                        width="7"
                                                                                                                                        height="11px"
                                                                                                                                        fillColor={currentIndex !== 1 ? "#000" : "#7A7A7A"}
                                                                                                                                        onClick={() => {
                                                                                                                                            option.index !== 0 &&
                                                                                                                                                handleDecOutputOptionVersion(option)
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                                <div style={{ color: "#7A7A7A", fontSize: "14px", fontWeight: "500", margin: "0px 3px" }}>{`${option.index + 1}/${option.value.length}`}</div>


                                                                                                                                <div style={{ paddingRight: "15px" }}>
                                                                                                                                    <IconsColor
                                                                                                                                        iconName="IncIndex"
                                                                                                                                        className={option.index !== option.value.length - 1 ? globalStyles.clickable : ""}
                                                                                                                                        width="7"
                                                                                                                                        height="11px"
                                                                                                                                        fillColor={option.index !== option.value.length - 1 ? "#000" : "#7A7A7A"}
                                                                                                                                        onClick={() => { option.index !== option.value.length - 1 && handleIncOutputOptionVersion(option) }}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    {/* buttons */}
                                                                                                                    {
                                                                                                                        <div style={{
                                                                                                                            display: 'flex', alignItems: "center",
                                                                                                                            gap: "14px"
                                                                                                                        }}>
                                                                                                                            {!option.value[option.index].checked &&
                                                                                                                                <>

                                                                                                                                    <div style={{ display: "flex" }}>
                                                                                                                                        {!option.value[option.index].flagEditable &&
                                                                                                                                            <>

                                                                                                                                                <Tooltip title={"Edit"}>
                                                                                                                                                    <div
                                                                                                                                                        style={{ display: "flex", cursor: "pointer" }}
                                                                                                                                                        className={option.value[option.index].flagLoading ? globalStyles.disabledClass : ""}
                                                                                                                                                        onClick={() => {
                                                                                                                                                            if (option.value[option.index].flagLoading) {
                                                                                                                                                            } else {
                                                                                                                                                                handleToggleOutputEditable(option, true, index)
                                                                                                                                                            }
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <IconsColor
                                                                                                                                                            iconName="edit"
                                                                                                                                                            fillColor="#6A097D"
                                                                                                                                                            width="16px"
                                                                                                                                                            height="16px"
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                </Tooltip>
                                                                                                                                            </>
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div
                                                                                                                                        className={option.value[option.index].flagLoading ? `${globalStyles.disabledClass} ${styles.useasInputBtnStyle}` : `${styles.useasInputBtnStyle} ${globalStyles.clickable}`}
                                                                                                                                        onClick={() => {
                                                                                                                                            if (option.value[option.index].flagLoading) {
                                                                                                                                            } else {
                                                                                                                                                handleRewriteOutputOption(option)
                                                                                                                                            }
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <Tooltip title={"Rewrite"}>
                                                                                                                                            <Grid item>
                                                                                                                                                <IconButton
                                                                                                                                                    aria-label="rewrite"
                                                                                                                                                    size="small"
                                                                                                                                                // onClick={() => { handleRewriteOutputOption(option) }}
                                                                                                                                                >
                                                                                                                                                    <img src={reWriteIcon} alt="Rewrite" />
                                                                                                                                                </IconButton>
                                                                                                                                            </Grid>
                                                                                                                                        </Tooltip>

                                                                                                                                    </div>
                                                                                                                                    <CopyToClipboard
                                                                                                                                        text={option.value[option.index].paraphrase}>
                                                                                                                                        <Grid item>
                                                                                                                                            <Tooltip title={copyText} onMouseLeave={() => setCopyText("Copy!")} onClick={() => {
                                                                                                                                                setCopyText("Copied!");

                                                                                                                                            }}>
                                                                                                                                                <div
                                                                                                                                                    onMouseLeave={() => setCopyText("Copy!")}
                                                                                                                                                    className={option.value[option.index].flagLoading ? `${globalStyles.disabledClass}` : `${globalStyles.clickable}`}
                                                                                                                                                    onClick={() => {
                                                                                                                                                        if (option.value[option.index].flagLoading) {
                                                                                                                                                        } else {
                                                                                                                                                            handleCopyToClipboard(option.value[option.index].paraphrase);
                                                                                                                                                            setCopyText("Copied!")
                                                                                                                                                        }
                                                                                                                                                    }}
                                                                                                                                                    style={{ display: "flex", alignItems: "center" }}
                                                                                                                                                >
                                                                                                                                                    <IconsColor iconName={"Copy"} fillColor={"#6A097D"} width={"16"} height={"16"} />
                                                                                                                                                </div>
                                                                                                                                            </Tooltip>

                                                                                                                                        </Grid>

                                                                                                                                    </CopyToClipboard>


                                                                                                                                    <div key={option.id} style={{ display: "flex" }}>
                                                                                                                                        <IconButton
                                                                                                                                            aria-label="more"
                                                                                                                                            id={option.id.toString()}
                                                                                                                                            key={option.id}
                                                                                                                                            aria-haspopup="true"
                                                                                                                                            // onClick={handleClick}
                                                                                                                                            sx={{ padding: "0px" }}
                                                                                                                                            className={option.value[option.index].flagLoading ? `${globalStyles.disabledClass}` : `${globalStyles.clickable}`}
                                                                                                                                            onClick={(e: any) => {
                                                                                                                                                if (option.value[option.index].flagLoading) {
                                                                                                                                                } else {
                                                                                                                                                    handleOpenMenu(e, option)
                                                                                                                                                }
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <MoreVertIcon sx={{ color: "#6A097D", fontSize: "large" }} />
                                                                                                                                        </IconButton>
                                                                                                                                        <Menu
                                                                                                                                            id={option.id.toString()}
                                                                                                                                            MenuListProps={{
                                                                                                                                                'aria-labelledby': 'long-button',
                                                                                                                                            }}
                                                                                                                                            anchorEl={option.value[option.index].anchorEl}
                                                                                                                                            key={option.value[option.index].id}
                                                                                                                                            open={option.value[option.index].flagOpenMenu}
                                                                                                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeCheckOutputOptionCard(event, option) }}

                                                                                                                                            onClose={(e: any) => handleCloseMenu(e, option)}
                                                                                                                                            PaperProps={{
                                                                                                                                                style: {
                                                                                                                                                    // maxHeight: ITEM_HEIGHT * 4.5,
                                                                                                                                                    // width: '130px',
                                                                                                                                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                                                                                                                    padding: "0 10px"
                                                                                                                                                },
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <MenuItem onClick={() => { handleUseAsInput(option.value[option.index].paraphrase, option) }}

                                                                                                                                                style={{
                                                                                                                                                    minHeight: '20px',
                                                                                                                                                    padding: "5px 10px",
                                                                                                                                                    textAlign: "center",
                                                                                                                                                    justifyContent: "start",
                                                                                                                                                    alignItems: "center",
                                                                                                                                                    borderBottom: "1px solid #E9E9E9"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Typography component={'span'}>Use as Input</Typography>
                                                                                                                                            </MenuItem>
                                                                                                                                            <MenuItem
                                                                                                                                                onClick={() => { deleteOutputOptionCard(option) }}
                                                                                                                                                style={{
                                                                                                                                                    minHeight: '20px',
                                                                                                                                                    padding: "5px 10px",
                                                                                                                                                    textAlign: "center",
                                                                                                                                                    justifyContent: "start",
                                                                                                                                                    alignItems: "center"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <Typography component={'span'}>Delete</Typography>
                                                                                                                                            </MenuItem>
                                                                                                                                        </Menu>
                                                                                                                                    </div>
                                                                                                                                </>
                                                                                                                            }
                                                                                                                            <div style={{ display: "flex" }}>
                                                                                                                                <Checkbox
                                                                                                                                    key={option.value[option.index].id}
                                                                                                                                    value={option.value[option.index].checked}
                                                                                                                                    checked={option.value[option.index].checked}
                                                                                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeCheckOutputOptionCard(event, option) }}
                                                                                                                                    className={`${styles.checkboxStyle} ${globalStyles.clickable}`}
                                                                                                                                    checkedIcon={<img alt="" src={checkedIcon} />}
                                                                                                                                    icon={<img alt="" src={unCheckedIcon} />}
                                                                                                                                    disabled={option.value[option.index].flagLoading ? true : false}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>}
                                                                                                                </div>


                                                                                                            </div>
                                                                                                        </div>



                                                                                                        {/* textarea */}
                                                                                                        {
                                                                                                            <div  >
                                                                                                                {
                                                                                                                    option.value[option.index].flagLoading ?
                                                                                                                        <>
                                                                                                                            <div style={{ marginBottom: "16px" }}>
                                                                                                                                <Skeleton />
                                                                                                                                <Skeleton />
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            {option.value[option.index].flagEditable ?
                                                                                                                                <>
                                                                                                                                    <textarea
                                                                                                                                        ref={(el) => (textAreaRefs.current[index] = el)}
                                                                                                                                        style={{ position: "relative", height: "100% !important", maxHeight: "min-content" }}
                                                                                                                                        className={`${globalStyles.clickable} resizableTextArea`}
                                                                                                                                        value={option.value[option.index].paraphrase}
                                                                                                                                        onBlur={() => { handleToggleOutputEditable(option, false, index) }}
                                                                                                                                        onChange={(e: any) => handleParaphraseOutputChange(e, option)}
                                                                                                                                    >
                                                                                                                                    </textarea>
                                                                                                                                </>
                                                                                                                                :
                                                                                                                                <>
                                                                                                                                    <div
                                                                                                                                        className='resizableTextArea'
                                                                                                                                        style={{
                                                                                                                                            whiteSpace: "normal"
                                                                                                                                        }} onClick={() => { handleToggleOutputEditable(option, true, index) }}>
                                                                                                                                        <ReactMarkdown
                                                                                                                                            className={styles.reactMarkdownStyle}
                                                                                                                                            children={option.value[option.index].paraphrase}
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                </>
                                                                                                                            }


                                                                                                                        </>
                                                                                                                }
                                                                                                            </div>
                                                                                                        }

                                                                                                    </div>}
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Grid>
                                                                            <Box className={styles.ppOutputButtons}>
                                                                                <Grid container justifyContent={'space-between'} gap="10px">
                                                                                    <Grid item id="stickyBottom" className={styles.ppOutputButtonsContainer}>
                                                                                        <GlobalButton
                                                                                            disabled={
                                                                                                paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length == 0
                                                                                            }
                                                                                            onClick={handleSaveDocument} buttonText={"Save"} className="borderedButtonStyle" />
                                                                                        <GlobalButton

                                                                                            disabled={
                                                                                                paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                            }
                                                                                            onClick={handleMultipleUseAsInput} buttonText={"Use as Input"} className='borderedButtonStyle' />
                                                                                        <GlobalButton
                                                                                            disabled={
                                                                                                paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                            }
                                                                                            onClick={handleMultipleRewrite} buttonText={"Rewrite"} className='borderedButtonStyle' />
                                                                                    </Grid>

                                                                                    <Grid item sx={{ display: "flex" }}>
                                                                                        <Grid container className={styles.inputOptionsContainer} >
                                                                                            <Grid item>
                                                                                                <CopyToClipboard
                                                                                                    text={multipleCopyText()}
                                                                                                >
                                                                                                    <Grid item>
                                                                                                        <Tooltip title={copyText} onMouseLeave={() => setCopyText("Copy!")} onClick={() => {
                                                                                                            setCopyText("Copied!");

                                                                                                        }}>
                                                                                                            <GlobalButton
                                                                                                                onClick={() => {
                                                                                                                    handleCopyToClipboard(multipleCopyText());
                                                                                                                    setCopyText("Copied!")
                                                                                                                }}
                                                                                                                disabled={
                                                                                                                    paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                                                }
                                                                                                                onMouseLeave={() => setCopyText("Copy!")}
                                                                                                                buttonText={<IconsColor iconName="Copy" fillColor="#6A097D" width={"18"} height={"18"} />}
                                                                                                                className='borderedButtonStyle'></GlobalButton>
                                                                                                        </Tooltip>

                                                                                                    </Grid>

                                                                                                </CopyToClipboard>
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <GlobalButton
                                                                                                    disabled={
                                                                                                        paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                                    }
                                                                                                    onClick={handleMultipleDelete} buttonText={<IconsColor iconName="Delete" fillColor="#6A097D" width={"18"} height={"18"} />} className='borderedButtonStyle' />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Box>
                                                                        </Grid>
                                                                        :
                                                                        <>
                                                                            <Box sx={{ height: "100%", padding: "10px", alignItems: "center", display: "flex", width: "100%" }}>
                                                                                <Box className={globalStyles.globalTextStyleGrey} alignItems={"center"} sx={{ maxWidth: "355px" }}>
                                                                                    AI paraphrased content will show up here
                                                                                    <img alt="" src={shine} style={{ width: "20px", height: "20px" }} />
                                                                                </Box>

                                                                            </Box>
                                                                        </>
                                                                    }

                                                                </Grid>

                                                            </Grid>}
                                                    </Grid>
                                                </div>
                                            </>
                                        }

                                    </Grid>
                                </Grid>
                            </>
                        </Grid>
                    </Grid>
                </>
            }

        </>
    )
}

export default Paraphrasing
