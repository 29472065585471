import React, { useEffect, useState } from 'react'
import './fileUpload.css'
import axios from 'axios'
import LinearProgress from '@mui/material/LinearProgress';
import { Alert, Box, Typography, Snackbar } from "@mui/material";
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Close';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import PropTypes from 'prop-types';
import { isAxiosError } from 'axios';
// import "../../../../assets/styles/parentFormwizStyles.css"
var FormData = require('form-data');
function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};
const FileUpload = (props) => {
    const [files, setFiles] = useState(props.final_g_id ? props.final_g_id : []);
    const [progress, setProgress] = useState({ filename: "", percent: 0 });
    const [fileError, setFileError] = React.useState({ status: false, message: "" });
    const [showFileUploader, setShowFileUploader] = React.useState({});


    useEffect(() => {
        if (props.fileDetails && props.filesFromParent && props.filesFromParent.length) {
            if (props.fileDetails.multiple_file_allow) {
                let filesCount = props.fileDetails.multiple_file || false;
                let countFileUpload = props.filesFromParent.filter(rr => rr.page_field_id == props.fileDetails.page_field_id).length
                if (countFileUpload == props.fileDetails.multiple_file) {
                    // setShowFileUploader(false)
                    setShowFileUploader(prevState => ({
                        ...prevState,
                        [props.fileDetails.page_field_id]: false
                    }));
                } else {
                    setShowFileUploader(prevState => ({
                        ...prevState,
                        [props.fileDetails.page_field_id]: true
                    }));
                    // setShowFileUploader(true)
                }
            } else {
                if (props.filesFromParent.filter(rr => rr.page_field_id == props.fileDetails.page_field_id).length) {
                    setShowFileUploader(prevState => ({
                        ...prevState,
                        [props.fileDetails.page_field_id]: false
                    }));
                } else {
                    setShowFileUploader(prevState => ({
                        ...prevState,
                        [props.fileDetails.page_field_id]: true
                    }));
                    // setShowFileUploader(true)
                }
            }

        } else {
            setShowFileUploader(true)
        }
    }, [props.filesFromParent])

    useEffect(() => {
        if (props.fileFlag != null)
            if (props.fileFlag.status == true && props.fileFlag.submission_id == null) {
                setProgress({ filename: "", percent: 0 })
                setFiles([])
                props.saveData([])
            }
    }, [props.fileFlag])

    // useEffect(() => {
    //     // props.setFileValue(props.filesFromParent)
    // }, [props.filesFromParent])

    // const removeFile = (google_id) => {
    //     var google_s_id = {};
    //     google_s_id['file_id'] = google_id
    //     const fileSet = props.filesFromParent.filter(file => file.google_storage_id !== google_id)
    //     // FileDeleteAPI(google_s_id)
    //     var finalFiles = [...fileSet];
    //     // debugger;
    //     // setFiles(finalFiles)
    //     props.filesFromParent(finalFiles)
    //     // setProgress({ filename: "", percent: 0 })
    //     // props.saveData(finalFiles)
    //     // document.getElementById('image').value = "";
    // }

    const formatFileSize = (bytes, decimalPoint) => {
        if (bytes == 0) return '0 Bytes';
        var k = 1000,
            dm = decimalPoint || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const RemoveConfirmation = (data) => {

        setFileError({
            status: false,
            message: ""
        })
        const result = window.confirm("Are you sure you want to remove the the file?");

        if (result) {
            props.removeFile(data)
        } else {
            return
        }

    }

    const validationFileOld = async (event) => {
        if (!event.target.files || event.target.files.length === 0) return false;
        const { fileDetails, filesFromParent, fileType } = props; // Destructuring for cleaner code


        const files = Array.from(event.target.files); // Convert FileList to Array for easier manipulation

        // Check if the total number of files, including already selected ones, exceeds the limit

        if (files.length > fileDetails.multiple_file || (files.length + filesFromParent.length > fileDetails.multiple_file)) {
            setFileError({
                status: true,
                message: `You can only upload up to ${fileDetails.multiple_file} file(s)`
            });
            return false;
        }

        // Check if any file exceeds the maximum allowed size
        const oversizedFile = files.find(file => (file.size / 1048576) > fileDetails.file_size);
        if (oversizedFile) {
            setFileError({
                status: true,
                message: `Please upload a file smaller than ${fileDetails.file_size} MB`
            });
            return false;
        }

        // Check if all files have the correct file type
        const invalidFileType = files.some(file => !file.name.endsWith(fileType));
        if (invalidFileType) {
            setFileError({
                status: true,
                message: `The file type you selected is invalid. Please select this file type: ${fileType}`
            });
            return false;
        }

        // If all checks pass, reset any error and return true
        setFileError({
            status: false,
            message: ""
        });
        return true;
    };
    const validationFile = async (event) => {
        debugger;
        try {

            if (!event.target.files || event.target.files.length === 0) return false;

            const { fileDetails, filesFromParent, fileType } = props;
            let filesEvent = Array.from(event.target.files);
            let findFilesFromParent = filesFromParent.filter(rr => rr.page_field_id == fileDetails.page_field_id);
            if (!fileDetails.multiple_file_allow && (filesEvent && filesEvent.length > 1)) {
                setFileError({
                    status: true,
                    message: "Only a single file can be uploaded."
                });
                return false;
            } else if ((filesEvent.length > fileDetails.multiple_file) || (filesEvent.length + (findFilesFromParent.length || 0) > fileDetails.multiple_file)) {
                setFileError({
                    status: true,
                    message: `You can only upload up to ${fileDetails.multiple_file} file(s).`
                });
                return false;
            }

            // Check if any file exceeds the maximum allowed size
            const oversizedFile = filesEvent.find(file => (file.size / 1048576) > fileDetails.file_size);
            if (oversizedFile) {
                setFileError({
                    status: true,
                    message: `Please upload a file smaller than ${fileDetails.file_size} MB`
                });
                return false;
            }

            // Check if all files have the correct file type
            if (fileType) {
                const validFileTypes = fileType.split(", ").map(type => type.trim());
                const invalidFileType = filesEvent.some(file => {
                    const fileExtension = file.name.split('.').pop();
                    return !validFileTypes.includes(`.${fileExtension}`);
                });

                if (invalidFileType) {
                    setFileError({
                        status: true,
                        message: `Invalid file type. Allowed types are: ${fileType}`
                    });
                    return false;
                }
            }

            setFileError({
                status: false,
                message: ""
            });
            return true;

        } catch (e) {
            console.log(e)
            setFileError({
                status: true,
                message: "Something went wrong."
            });
            return false;
        }
    }

    const uploadHandler = async (event) => {
        var checkFilevalidation = await validationFile(event)
        if (!checkFilevalidation) {
            return
        }
        if (!event.target.files.length) return;

        props.fileUploadhandler(event)
        // for (var i = 0; i < event.target.files.length; i++) {
        //     let file = event.target.files[i];
        //     console.log(file);
        //     file.isUploading = true;
        //     setFiles(old => {
        //         let temp = [...old]
        //         temp.push(file)
        //         return temp
        //     })

        //     let formData = new FormData()
        //     formData.append(
        //         "file", file, file.name)

        //     let config = {
        //         onUploadProgress: (progressEvent) => {
        //             const { loaded, total } = progressEvent;
        //             var percent = Math.floor((loaded * 100) / total)
        //             setProgress({ ...progress, percent: percent, filename: file.name })
        //             console.log("progress", [progress, setProgress])
        //         }
        //     }

        //     axios.post('https://api.writewiz-staging.in/api/storage/file', formData, {

        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         },
        //         withCredentials: true,
        //         onUploadProgress: config.onUploadProgress,
        //     }
        //     )
        //         .then((res) => {



        //             file.isUploading = false;
        //             file.file_data = res.data.data;
        //             file.google_storage_id = res.data.data.google_storage_id;
        //             setFiles(old => {
        //                 let temp = [...old]
        //                 temp.map(rr => {
        //                     if (rr.lastModified == file.lastModified) {
        //                         rr.isUploading = false;
        //                         rr.file_data = res.data.data;
        //                         rr.google_storage_id = res.data.data.google_storage_id;
        //                     }
        //                 })
        //                 return temp
        //             })
        //             // setFiles([...files, file]);

        //             let images = [];

        //             for (let i = 0; i < event.target.files.length; i++) {
        //                 images.push(URL.createObjectURL(event.target.files[i]))
        //             }
        //             // setfileImg(images)
        //             props.saveData([res.data.data])

        //             //    setfileImg(URL.createObjectURL(event.target.files));
        //         })
        //         .catch((err) => {
        //             // inform the user
        //             console.error(err)
        //             removeFile(file.name)
        //         });

        // }
    }

    return (
        <>
            <Grid container>
                <Grid item className="file-card" direction="column">
                    {props.fileDetails.is_avatar ?
                        <Grid item>
                            <Grid container style={{ marginTop: "5px" }}>
                                <Grid item xs={0.7} className="avatar_file_upload">
                                    <img src={'https://api.filemaster.io/api/storage/file?file_id=' + (props.fileDetails.value.length ? props.fileDetails.value[0] : "2516") + '&flag_info=false'} style={{ height: "37px" }} />
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container className="file-inputs-avatar">
                                        <input
                                            id="image"
                                            accept='image/*'
                                            type="file"
                                            disabled={props.fileDetails.is_disabled == true || props.fileDetails.is_disabled == "true"}
                                            multiple={props.multiple_file_allow}
                                            className="file-inputs-avatar"
                                            onChange={(e) => { uploadHandler(e) }} >
                                        </input>
                                        <button className="file-inputs-avatar" style={{ color: "#4C4A4D", boxShadow: "none !important" }}>
                                            <FileUploadOutlinedIcon />
                                            <span>
                                                {props.fileDetails.placeholder}
                                            </span>
                                        </button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid container direction="column">
                            {(showFileUploader[props.fileDetails.page_field_id] || !props.fileDetails.fileNewDesign) &&
                                <Grid item className="file-inputs" >

                                    <input
                                        style={{ border: "2px solid red !important" }}
                                        id="image"
                                        accept={props.fileType}
                                        type="file"
                                        multiple
                                        className="file-inputs"
                                        disabled={props.fileDetails.is_disabled == true || props.fileDetails.is_disabled == "true"}
                                        onChange={uploadHandler} >
                                    </input>
                                    {props.fileDetails.fileNewDesign ?
                                        <>

                                            <button className={`file-inputs ${props.fileDetails.extraFileClass || ''}`} style={{ color: "#4C4A4D", display: "flex", flexDirection: "column" }} disabled={props.fileDetails.is_disabled == true || props.fileDetails.is_disabled == "true"}>
                                                <Grid container justifyContent={"center"} alignItems="center">
                                                    <Grid item style={{ marginRight: "7px", display: "flex" }}>
                                                        <FileUploadOutlinedIcon iconName="uploadToGoogleStorage" width="35px" height="35px" fillColor="#000" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Grid container direction="column" justifyContent="center" alignItems="start">
                                                            <div style={{ display: "flex" }}>
                                                                <span style={{ color: "#000", fontSize: "13px", fontWeight: "400", display: "flex" }}>Drag & Drop files here, or {" "}</span><span style={{ color: "#6A097D", fontSize: "13px", fontWeight: "400", display: "flex", paddingLeft: "4px" }}>Browse</span>
                                                            </div>
                                                            <Grid item sx={{ display: "flex" }}><span style={{ color: "#7A7A7A", fontSize: "13px", fontWeight: "400", opacity: "0.8" }}>3 MB limit for single file</span></Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </button>
                                        </>
                                        :
                                        <>
                                            <button className={`file-inputs ${props.fileDetails.extraFileClass || ''}`} style={{ color: "#4C4A4D", display: "flex", flexDirection: "column" }} disabled={props.is_disabled == true || props.fileDetails.is_disabled == "true"}>
                                                <FileUploadOutlinedIcon />
                                                {props.filePlaceholderText && props.filePlaceholderText.length && props.filePlaceholderText[0].field_option_value != "" ?
                                                    <span style={{ fontSize: "14px" }}>
                                                        {props.filePlaceholderText[0].field_option_value}
                                                    </span> :
                                                    <span style={{ fontSize: "14px" }}>Drag & Drop files here, or <span style={{ color: "#2765FF" }}>Browse</span></span>
                                                }
                                            </button>
                                        </>
                                    }
                                </Grid>
                            }

                            <>
                                {/* {props.fileFlag == true ? "" : */}
                                <>
                                    {props.filesFromParent && props.filesFromParent.map((fileArray) => {

                                        var roundedNumber = formatFileSize(fileArray.size ? fileArray.size : 0)
                                        return <>
                                            {fileArray.page_field_id == props.fileDetails.page_field_id && <Grid item sx={{ display: "flex", alignSelf: "start", width: "100%" }} className={!fileArray.google_storage_id ? "file_item" : "file_item_after_uploaded"}>
                                                <Grid Container sx={{ width: "100%" }} className={fileArray.previewImageSrc ? "fileUploadedGrid" : "file_background_design"}>
                                                    <Grid item direction="column">
                                                        <Grid container alignItems="center" className={"delete_container"}>
                                                            <Grid item xs={11} className="file_name">
                                                                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", whiteSpace: "nowrap", wordBreak: "break-all", width: "100%" }}>
                                                                    <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", whiteSpace: "nowrap", wordBreak: "break-all" }}>
                                                                        {fileArray.previewImageSrc &&
                                                                            <img className="file_type_uploaded" src={fileArray.previewImageSrc} alt="file" style={{ width: "35px", height: "35px", marginRight: "8px" }} />
                                                                        }
                                                                        <span style={{ whiteSpace: "pre-wrap" }} className="file_name_txt">{fileArray.name}  </span>
                                                                    </div>
                                                                    &nbsp;
                                                                    &nbsp;
                                                                    <div>
                                                                        <span className="file_size"> {roundedNumber}</span>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                            {(fileArray.google_storage_id && props.fileDetails.is_disabled != true) &&
                                                                <>
                                                                    <Grid item xs={1} className="delete_icon">
                                                                        <DeleteIcon className="delete_onclick" onClick={() => {
                                                                            RemoveConfirmation(fileArray.google_storage_id)
                                                                        }} />
                                                                    </Grid>
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    {!fileArray.google_storage_id && (props.progressFromParent.filename == fileArray.name) ?
                                                        <Grid item>
                                                            <Box sx={{ width: '100%' }}>
                                                                <LinearProgressWithLabel value={props.progressFromParent.percent} />
                                                            </Box>
                                                        </Grid>
                                                        : ""}
                                                </Grid>
                                            </Grid>}
                                        </>
                                        // }
                                    })}
                                </>
                                {/* } */}
                            </>
                            {fileError.status == true ?
                                <Grid item style={{ color: "red", fontSize: "14px", paddingTop: "3px" }}>
                                    {fileError.message}
                                </Grid>
                                : ""}
                        </Grid>
                    }

                </Grid>
            </Grid >
        </>
    )
}

export default FileUpload;