import { Button, Grid, IconButton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { useNavigate } from 'react-router-dom';
import { NoteContext, NoteContextPayload } from '../../../context/noteContext';
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/notes.module.scss";
import { notesListResponseModel } from '../../../models/axiosModel';
import moment from 'moment';
import GlobalPopUp from '../../utility/globalPopUp';
import DeleteDocumentDataComponent from './../deleteDocumentDataComponent';
import agent from '../../../services/api';
import GlobalSearch from '../../utility/GlobalSearch/GlobalSearch';
import AddIcon from '@mui/icons-material/Add';
import createPersonaIcon from "../../../assets/createPersona.png";
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
import { debounce } from 'lodash';
import { NotesTable } from './notesTable';
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, isDev } from '../../../utils/constants';

const Notes = () => {

    let headerList = [
        {
            name: "Notes",
            alignment: "left",
            flagIcon: true,
            valueKey: "user_notes_title",
            iconKey: "documentIcon",
        },
        {
            name: "Last Modified",
            alignment: "left",
            flagIcon: false,
            valueKey: "timestamp",
            iconKey: "lastModifiedIcon"

        },
        {
            name: "Category",
            alignment: "left",
            flagIcon: true,
            valueKey: "notes_category_name",
            iconKey: "featureIcon"
        },
        {
            name: "",
            alignment: "right",
            flagIcon: true,
            valueKey: "",
            iconKey: "editIcon"
        },
    ]

    const navigate = useNavigate()
    const { userDetail, flagLoading, setFlagLoading, setError, error, setSuccess, success, setFlagShowCreditsExhausted, setUserDetail } = useContext(UserContext) as UserContextPayload;
    const { GetNotesListApi, noteList, GetNotesCategoryListApi } = useContext(NoteContext) as NoteContextPayload;
    const [noteOriginalDetails, setNoteOriginalDetails] = useState<notesListResponseModel[] | null>();
    const [noteRender, setNoteRender] = useState<any>(null)
    const [noteDetailForTable, setNoteDetailForTable] = useState<notesListResponseModel[]>([])
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [adjustedHeaderList, setAdjustedHeaderList] = useState(headerList);
    const [adjustedNoteDetailForTable, setAdjustedNoteDetailForTable] = useState(noteDetailForTable);
    const [flagDeleteDocumentDataPopup, setFlagDeleteDocumentDataPopup] = useState(false)
    const [noteSelected, setNoteSelected] = useState<null | notesListResponseModel>(null)
    const [rowAllowEdit, setRowAllowEdit] = useState(null);
    const [flagRenderData, setFlagRenderData] = useState<Boolean | null>(null);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const isMobile = screenWidth <= 1200;

    const handleSearch = (event: any) => {
        const query = event.target.value?.toLowerCase()
        const noteTableData = noteDetailForTable?.filter((header) => {
            return (
                (header?.user_notes_title !== null && header?.user_notes_title?.toLowerCase().includes(query)) ||
                (header?.timestamp !== null && header?.timestamp?.toLowerCase().includes(query)) ||
                (header?.notes_category_name !== null && header?.notes_category_name?.toLowerCase().includes(query))
            );
        });
        setAdjustedNoteDetailForTable(noteTableData)
    };

    const debouncedSearch = debounce(handleSearch, 300)
    const handleInputChange = (event: any) => {
        debouncedSearch(event);
    };

    const handleCancelSearch = (results: string) => {
        setAdjustedNoteDetailForTable(noteDetailForTable);
    };

    useEffect(() => {
        if (userDetail.flagUser) {
            (async () => {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true });
                setFlagRenderData(false)
                // await GetNotesListApi()
                await GetNotesCategoryListApi()
                setNoteRender(false)
                let response = await GetNotesListApi()
                if (response?.status) {
                    if (response?.data?.length) {
                        setNoteOriginalDetails(response.data)
                        setNoteRender(true)
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                    } else {
                        setNoteOriginalDetails(null)
                        setNoteRender(true)
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                    }
                } else {
                    setNoteOriginalDetails(null)
                    setNoteRender(true)
                    let errorCode = checkForErrorCode(response)
                    if (flagCookieExpired(userDetail, errorCode)) {
                        setUserDetail(emptyUserDetail)
                        if (userDetail.flag_user_anonymous) {
                            let url = window.location.pathname
                            navigate(url, { replace: true });
                        } else {
                            setError({
                                ...error,
                                flagShowError: true,
                                message: "Session expired! Please login to continue."
                            });
                            setTimeout(() => {
                                let url = encodeURIComponent(window.location.href)
                                navigate("/login?redirect=" + url, { replace: true });
                            }, 1500);
                        }
                    } else {
                        if (flagCreditsExhausted(errorCode)) {
                            setFlagShowCreditsExhausted(true)
                        } else {
                            setError({
                                ...error,
                                flagShowError: true,
                                message: getErrorMessage(errorCode, isDev(userDetail))
                            });
                        }
                    }
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                }
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                setFlagRenderData(true)
            })()
        }
    }, [])

    useEffect(() => {
        let tempNoteList = noteList?.length ? [...noteList] : []
        tempNoteList?.map((docObj: notesListResponseModel) => {
            docObj['user_notes_id'] = docObj?.user_notes_id
            docObj['user_notes_title'] = docObj?.user_notes_title
            docObj['notes_category_name'] = docObj?.notes_category_name ? docObj?.notes_category_name : ""
            docObj['timestamp'] = moment(docObj.timestamp).format('DD-MM-YY HH:mm')
        })
        setNoteDetailForTable(tempNoteList)
    }, [noteList])

    useEffect(() => {
        if (isMobile) {
            // For mobile view
            const filteredHeaderList = headerList.filter(header => header.name !== "Category");
            const modifiedHeaderList = filteredHeaderList.map(header => {
                if (header.name === "Notes") {
                    return { ...header, flagIcon: false };
                }
                if (header.name === "Date and time") {
                    return { ...header, name: "Last Modified" };
                }
                return header;
            });

            setAdjustedHeaderList(modifiedHeaderList);
            noteDetailForTable.slice(0, 10).map((row: any) => {
                row[`formatted_timestamp`] = moment(row.timestamp).format('DD-MM-YY HH:mm')
            })
            setAdjustedNoteDetailForTable(noteDetailForTable);
        } else {
            // For desktop view
            setAdjustedHeaderList(headerList);
            noteDetailForTable.slice(0, 10).map((row: any) => {
                row[`formatted_timestamp`] = moment(row.timestamp).format('DD-MM-YY HH:mm')
            })
            setAdjustedNoteDetailForTable(noteDetailForTable);
        }
    }, [isMobile, noteDetailForTable]);
    


    const deleteDocumentDataPopupOpen = (data: any) => {
        setFlagDeleteDocumentDataPopup(true)
        setNoteSelected(data)
    }
    const deleteCancelDocumentDataPopupOpen = () => {
        setFlagDeleteDocumentDataPopup(false)
    }

    const rowClicked = async (e: any) => {
        navigate(`/dashboard/notes/create?user_notes_id=${e.user_notes_id}`)
    
    }

    const updateNoteDetails = (data: any) => {
        navigate(`/dashboard/notes/create?user_notes_id=${data.user_notes_id}`)
    }

    const handleDeleteDocument = async (user_notes_id: any) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await agent.removeNotes.delete(user_notes_id)
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Note Deleted Successfully!" })
            await GetNotesListApi()
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong, Please try again!" })
        }
        deleteCancelDocumentDataPopupOpen()
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    return (
        <>
            {flagDeleteDocumentDataPopup &&
                <GlobalPopUp
                    open={flagDeleteDocumentDataPopup}
                    onClose={() => { setFlagDeleteDocumentDataPopup(false) }}
                    title={""}
                    flagShowCloseIcon={true}
                    handleClose={() => { setFlagDeleteDocumentDataPopup(false) }}
                >
                    <DeleteDocumentDataComponent
                        documentSelected={noteSelected != null && noteSelected.user_notes_id}
                        deleteCancelDocumentDataPopupOpen={deleteCancelDocumentDataPopupOpen}
                        handleDeleteDocument={handleDeleteDocument}
                        deleteDescription="Are you sure you want to delete this note?" />
                </GlobalPopUp>
            }
            
            <Grid item xl={12} xs={12} className={`${globalStyles.fullwidth} ${globalStyles.dashboardChildWrapper}`} sx={{ width: "100%" }}>
                <Grid container alignItems={"center"} justifyContent={"start"} >
                    {/* title header */}

                    <Grid item xs={12} >
                        <Grid container sx={{ alignItems: "center" }}>

                            <Grid item xl={6} lg={5} md={12} sm={12} xs={12} sx={{ marginBottom: "10px" }}>
                                <GlobalPageHeaderText
                                    mainHeadText={"Notes"}
                                    mainSubText={"Create a Note that you can directly use in Extension"}
                                />
                            </Grid>

                            <Grid item xl={6} lg={7} md={12} sm={12} xs={12} sx={{ marginBottom: { xs: "10px", sm: "10px", md: "0px" } }}>
                                <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} sx={{ alignItems: "center" }}>
                                    <Grid item xl={5.5} lg={5.5} md={4.5} sm={5.5} xs={9} className={`${globalStyles.globalHeadLeftSubItem}`}>
                                        <Grid container>
                                            <Grid item className={`${globalStyles.globalSearchbarSubItem}`}>
                                                <GlobalSearch
                                                    fullWidth
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleInputChange(e) }}
                                                    size="small"
                                                    placeholder="Search"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xl={2} lg={2} md={2} sm={2} xs={2} className={`${globalStyles.globalHeadRightSubItem} `}>
                                        <Grid container sx={{ alignItems: "center" }}>
                                            {window.screen.width >= 900 &&
                                                <Grid item className={`${globalStyles.globalNewAddBtnItem} ${styles.newNoteButton}`}>
                                                    <Button
                                                        variant='outlined'
                                                        size='large'
                                                        fullWidth
                                                        startIcon={<img src={createPersonaIcon} alt="" style={{ height: "18px", width: 'auto' }} />}
                                                        className={globalStyles.addDocumentBtn}
                                                        onClick={() => { navigate('/dashboard/notes/create') }}

                                                    >
                                                        Note
                                                    </Button>
                                                </Grid>
                                            }
                                            {window.screen.width <= 899 &&
                                                <IconButton
                                                    className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                                    onClick={() => { navigate('/dashboard/notes/create') }}

                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={11} xs={12} className={`${styles.docSectionTextStyle} ${globalStyles.fullwidth}`}>
                        Recent Notes
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${styles.documentsTableItem}`}>
                        {flagRenderData == true ?
                            (
                                <NotesTable
                                    headerList={adjustedHeaderList}
                                    rowData={adjustedNoteDetailForTable}
                                    tableHeight={"81vh"}
                                    tableContainerHeight={"calc(100vh - 180px)"}
                                    tableContainerMinHeight={"auto"}
                                    onRowClick={(e: any) => { console.log("Hello") }}
                                    deleteRowData={deleteDocumentDataPopupOpen}
                                    updateNoteDetail={updateNoteDetails}
                                    // renameDocumentName={renameDocumentName}
                                    rowAllowEdit={rowAllowEdit}
                                    flagPagination
                                    rowClicked={rowClicked}
                                />
                            ) : (<></>)
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Notes;