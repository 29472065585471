import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Header from '../layout/header/header';
import SideNavigationPanel from '../layout/header/sideNavigationPanel';
import { Grid } from '@mui/material';
import { Outlet, useSearchParams } from 'react-router-dom';
import globalStyles from "../../assets/styles/global.module.scss";
import GlobalPopUp from '../utility/globalPopUp';
import GetStartedPopUpData from '../utility/getStartedPopUpData';
import { UserContext, UserContextPayload } from '../../context/userContext';
import { DocContext, DocContextPayload } from '../../context/docContext';
import IconsColor from '../utility/iconsColor';
import closeArrow from "../../assets/closeArrow.png";
import Documents from './documents';
import ChatbotList from './chatbot/chatbotList';
import Settings from './settings';
import { ParaphraseContext, ParaphraseContextPayload } from '../../context/paraphraseContext';
import { isUserRegistered } from '../../utils/constants';

interface MenuItem {
    menuName: string;
    menuKey: string;
    navigationLink: string;
    imgSrc: React.ReactElement;
    whiteImgSrc: React.ReactElement;
    coloredImgSrc: React.ReactElement;
    flagDisabled?: boolean
}
const drawerWidth = 233;


export default function Dashboard() {
    const { userDetail, setFlagLoading, OneTapLoginAuthCallback, flagLoading } = React.useContext(UserContext) as UserContextPayload;
    const { setCurrentActiveDocDetails, setTabValuePanel, setCurrentActiveDocFieldVal, setExecutePromptResponse, setFlagShowAIOutput, setDocumentEditorData } = React.useContext(DocContext) as DocContextPayload;
    const { setDrawerProps } = React.useContext(ParaphraseContext) as ParaphraseContextPayload
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [flagShowRecaptchaPopUp, setFlagShowRecaptchaPopUp] = React.useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const fatchBrowserURL = window.location.pathname

    const publicMenuItems = [
        {
            menuName: "Home",
            menuKey: "home",
            navigationLink: "/dashboard",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="home" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="home" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="home" />
        },
        {
            menuName: "Paraphraser",
            menuKey: "paraphraser",
            navigationLink: "/dashboard/paraphraser",
            flagDisabled: false,
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="paraphrasing" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="paraphrasing" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="paraphrasing" />,
        },
        {
            menuName: "Create Content",
            menuKey: "templates",
            navigationLink: "/dashboard/templates",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="templates" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="templates" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="templates" />
        },

        // {
        //     menuName: "Documents",
        //     menuKey: "documents",
        //     navigationLink: "/dashboard/documents",
        //     flagDisabled: userDetail.flagUser ? false : true,
        //     imgSrc: <IconsColor fillColor="#4C4A4D" iconName="document" />,
        //     whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="document" />,
        //     coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="document" />
        // },
        {
            menuName: "Persona",
            menuKey: "persona",
            navigationLink: "/dashboard/persona",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="persona" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="persona" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="persona" />
        },
        {
            menuName: "AI Chatbot",
            menuKey: "chatbot",
            navigationLink: "/dashboard/chatbot",
            // flagDisabled: !userDetail.flagUser ? true : (
            //     userDetail.user_role && (userDetail.user_role.user_role_name === "Developer" || userDetail.user_role.user_role_name === "Admin") ? false : (
            //         userDetail.plan_detail ?
            //             userDetail.plan_detail.credit_usage_data !== null &&
            //             (userDetail.plan_detail?.credit_usage_data.stripe_product_name === null ||
            //                 userDetail.plan_detail?.credit_usage_data.stripe_product_name === "Free")
            //             : true
            //     )
            // ),
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chatbot" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="chatbot" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="chatbot" />,
        },
        {
            menuName: "Campaign",
            menuKey: "campaign",
            flagDisabled: isUserRegistered(userDetail) ? false : true,
            navigationLink: "/dashboard/campaigns",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="campaign" />,
            whiteImgSrc: <IconsColor fillColor="#FFFFFF" iconName="campaign" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="campaign" />
        },
    ]
    const [sidePanelMenuItems, setSidePanelMenuItems] = React.useState(publicMenuItems);

    React.useEffect(() => {
        let temp: MenuItem[] = [...publicMenuItems];
        if (userDetail.flagUser) {
            const documentObj: MenuItem = {
                menuName: "Documents",
                menuKey: "documents",
                navigationLink: "/dashboard/documents",
                imgSrc: <IconsColor fillColor="#4C4A4D" iconName="document" />,
                whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="document" />,
                coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="document" />
            }
            if (!userDetail.flag_user_anonymous && userDetail.flag_email_verified) {
                if (
                    userDetail.user_role.user_role_name == "Developer" ||
                    userDetail.user_role.user_role_name == "Super Admin" ||
                    userDetail.user_role.user_role_name == "Admin"
                ) {
                    const campaignObj: MenuItem = {
                        menuName: "Campaign",
                        menuKey: "campaign",
                        flagDisabled: userDetail.plan_detail ? userDetail.plan_detail.credit_usage_data !== null && userDetail.plan_detail?.credit_usage_data.stripe_product_name == "Free" : true,
                        navigationLink: "/dashboard/campaigns",
                        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="campaign" />,
                        whiteImgSrc: <IconsColor fillColor="#FFFFFF" iconName="campaign" />,
                        coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="campaign" />
                    }
                    const notesObj: MenuItem = {
                        menuName: "Notes",
                        menuKey: "notes",
                        // flagDisabled: !userDetail.flagUser ? true : userDetail.user_role && userDetail.user_role.user_role_name == "Developer" ? false : (userDetail.plan_detail ? userDetail.plan_detail.credit_usage_data !== null && (userDetail.plan_detail?.credit_usage_data.stripe_product_name == null || userDetail.plan_detail?.credit_usage_data.stripe_product_name == "Free") : true),
                        navigationLink: "/dashboard/notes",
                        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="notes" />,
                        whiteImgSrc: <IconsColor fillColor="#FFFFFF" iconName="notes" />,
                        coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="notes" />
                    }
                    // const chatbotObj: MenuItem = {
                    //     menuName: "Chatbot",
                    //     menuKey: "chatbot",
                    //     navigationLink: "/dashboard/chatbot",
                    //     imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chatbot" />,
                    //     whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="chatbot" />,
                    //     coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="chatbot" />,
                    // };
                    temp.splice(3, 0, documentObj);
                    temp.splice(8, 0, notesObj);
                    setSidePanelMenuItems(temp);
                } else {
                    // const chatbotObj: MenuItem = {
                    //     menuName: "Chatbot",
                    //     menuKey: "chatbot",
                    //     navigationLink: "/dashboard/chatbot",
                    //     imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chatbot" />,
                    //     whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="chatbot" />,
                    //     coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="chatbot" />,
                    // };
                    const settingsObj: MenuItem = {
                        menuName: "Settings",
                        menuKey: "settings",
                        navigationLink: "/dashboard/settings-accountDetails",
                        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="settings" />,
                        whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="settings" />,
                        coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="settings" />,
                    };
                    const campaignObj: MenuItem = {
                        menuName: "Campaign",
                        menuKey: "campaign",
                        navigationLink: "/dashboard/campaigns",
                        flagDisabled: userDetail.plan_detail ? userDetail.plan_detail.credit_usage_data !== null && userDetail.plan_detail?.credit_usage_data.stripe_product_name == "Free" : true,
                        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="campaign" />,
                        whiteImgSrc: <IconsColor fillColor="#FFFFFF" iconName="campaign" />,
                        coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="campaign" />
                    };
                    const notesObj: MenuItem = {
                        menuName: "Notes",
                        menuKey: "notes",
                        // flagDisabled: !userDetail.flagUser ? true : userDetail.user_role && userDetail.user_role.user_role_name == "Developer" ? false : (userDetail.plan_detail ? userDetail.plan_detail.credit_usage_data !== null && (userDetail.plan_detail?.credit_usage_data.stripe_product_name == null || userDetail.plan_detail?.credit_usage_data.stripe_product_name == "Free") : true),
                        navigationLink: "/dashboard/notes",
                        imgSrc: <IconsColor fillColor="#4C4A4D" iconName="notes" />,
                        whiteImgSrc: <IconsColor fillColor="#FFFFFF" iconName="notes" />,
                        coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="notes" />
                    }
                    // temp.push(chatbotObj, settingsObj);
                    // temp.push(documentObj, settingsObj, campaignObj)
                    temp.splice(3, 0, documentObj);
                    temp.splice(7, 0, settingsObj);
                    temp.splice(8, 0, notesObj);
                    setSidePanelMenuItems(temp);
                }
            } else {
                // const campaignObj: MenuItem = {
                //     menuName: "Campaign",
                //     menuKey: "campaign",
                //     flagDisabled: true,
                //     navigationLink: "/dashboard/campaigns",
                //     imgSrc: <IconsColor fillColor="#4C4A4D" iconName="campaign" />,
                //     whiteImgSrc: <IconsColor fillColor="#FFFFFF" iconName="campaign" />,
                //     coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="campaign" />
                // }
                temp.splice(3, 0, documentObj);
                setSidePanelMenuItems(temp);
                // setSidePanelMenuItems(publicMenuItems);
            }
        } else {

        }
    }, [userDetail]);

    const MySvgComponent = (fillColor: any) =>
    (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="#fff" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2415 4.37121L11.8705 0.999587C11.6286 0.757712 11.2939 0.618774 10.9513 0.618774H5.84552C5.12889 0.618774 4.54614 1.20152 4.54614 1.91815V2.7844H3.67877C2.96214 2.7844 2.37939 3.36715 2.37939 4.08377V16.0819C2.37939 16.7985 2.96214 17.3813 3.67877 17.3813H12.1556C12.8723 17.3813 13.455 16.7985 13.455 16.0819V15.2117H14.323C15.0396 15.2117 15.6223 14.629 15.6223 13.9123V5.28977C15.6223 4.94271 15.4873 4.61646 15.2415 4.37121ZM13.8943 4.45502H12.073C11.9149 4.45502 11.7861 4.32621 11.7861 4.16815V2.34677L13.8943 4.45502ZM12.4425 16.0819C12.4425 16.24 12.3137 16.3688 12.1556 16.3688H3.67877C3.52071 16.3688 3.39189 16.24 3.39189 16.0819V4.08377C3.39189 3.92571 3.52071 3.7969 3.67877 3.7969H4.54614V13.9118C4.54614 14.6284 5.12889 15.2111 5.84552 15.2111H12.4425V16.0819ZM14.323 14.1992H5.84552C5.68746 14.1992 5.55864 14.0704 5.55864 13.9123V1.91815C5.55864 1.76009 5.68746 1.63127 5.84552 1.63127H10.7736V4.16815C10.7736 4.88477 11.3563 5.46752 12.073 5.46752H14.6098V13.9118C14.6098 14.0704 14.481 14.1992 14.323 14.1992Z" fill="black" />
            <path d="M13.1761 6.6814H6.99258C6.71302 6.6814 6.48633 6.90808 6.48633 7.18765C6.48633 7.46721 6.71302 7.6939 6.99258 7.6939H13.1767C13.4563 7.6939 13.683 7.46721 13.683 7.18765C13.683 6.90808 13.4557 6.6814 13.1761 6.6814Z" fill="black" />
            <path d="M6.99258 5.18791H9.47208C9.75164 5.18791 9.97833 4.96123 9.97833 4.68167C9.97833 4.4021 9.75164 4.17542 9.47208 4.17542H6.99258C6.71302 4.17542 6.48633 4.4021 6.48633 4.68167C6.48633 4.96123 6.71302 5.18791 6.99258 5.18791Z" fill="black" />
            <path d="M13.1761 9.18738H6.99258C6.71302 9.18738 6.48633 9.41407 6.48633 9.69363C6.48633 9.97319 6.71302 10.1999 6.99258 10.1999H13.1767C13.4563 10.1999 13.683 9.97319 13.683 9.69363C13.683 9.41407 13.4557 9.18738 13.1761 9.18738Z" fill="black" />
            <path d="M13.1761 11.6932H6.99258C6.71302 11.6932 6.48633 11.9199 6.48633 12.1995C6.48633 12.479 6.71302 12.7057 6.99258 12.7057H13.1767C13.4563 12.7057 13.683 12.479 13.683 12.1995C13.683 11.9199 13.4557 11.6932 13.1761 11.6932Z" fill="black" />
        </svg>
    );


    const menuItemsPrivate = [
        {
            menuName: "Home",
            menuKey: "home",
            navigationLink: "/dashboard",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="home" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="home" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="home" />
        },
        {
            menuName: "Create Content",
            menuKey: "templates",
            navigationLink: "/dashboard/templates",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="templates" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="templates" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="templates" />
        },
        {
            menuName: "Documents",
            menuKey: "documents",
            navigationLink: "/dashboard/documents",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="document" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="document" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="document" />
        },
        // {
        //     menuName: "Persona",
        //     menuKey: "persona",
        //     navigationLink: "/dashboard/persona",
        //     imgSrc: <IconsColor fillColor="#4C4A4D" iconName="persona" />,
        //     whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="persona" />,
        //     coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="persona" />
        // },
        {
            menuName: "Chatbot",
            menuKey: "chatbot",
            navigationLink: "/dashboard/chatbot",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="chatbot" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="chatbot" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="chatbot" />
        },
        // {
        //     menuName: "Campaigns",
        //     menuKey: "campgains",
        //     imgSrc: campaignIcon,
        //     whiteImgSrc: whiteCampaignIcon,
        //     navigationLink: "/dashboard/campaigns"
        // },
        // {
        //     menuName: "Brand Voice",
        //     menuKey: "brandVoice",
        //     imgSrc: brandVoiceIcon,
        //     whiteImgSrc: whiteBrandVoice,
        //     navigationLink: "/dashboard/brandVoice"
        // },
        // {
        //     menuName: "Get started",
        //     menuKey: "getstarted",
        //     whiteImgSrc: whiteGetStartedIcon,
        //     imgSrc: getStartedIcon,
        //     navigationLink: "/dashboard/getStarted"
        // },
        {
            menuName: "Settings",
            menuKey: "settings",
            navigationLink: "/dashboard/settings-accountDetails",
            imgSrc: <IconsColor fillColor="#4C4A4D" iconName="settings" />,
            whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="settings" />,
            coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="settings" />

        },
    ]
    // const menuItemsPublic = [
    //     {
    //         menuName: "Home",
    //         menuKey: "home",
    //         navigationLink: "/dashboard",
    //         imgSrc: <IconsColor fillColor="#4C4A4D" iconName="home" />,
    //         whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="home" />,
    //         coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="home" />
    //     },
    //     {
    //         menuName: "Templates",
    //         menuKey: "templates",
    //         navigationLink: "/dashboard/templates",
    //         imgSrc: <IconsColor fillColor="#4C4A4D" iconName="templates" />,
    //         whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="templates" />,
    //         coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="templates" />
    //     },

    //     {
    //         menuName: "Documents",
    //         menuKey: "documents",
    //         navigationLink: "/dashboard/documents",
    //         imgSrc: <IconsColor fillColor="#4C4A4D" iconName="document" />,
    //         whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="document" />,
    //         coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="document" />
    //     },
    //     {
    //         menuName: "Persona",
    //         menuKey: "persona",
    //         navigationLink: "/dashboard/persona",
    //         imgSrc: <IconsColor fillColor="#4C4A4D" iconName="persona" />,
    //         whiteImgSrc: <IconsColor fillColor="#ffffff" iconName="persona" />,
    //         coloredImgSrc: <IconsColor fillColor="#6A097D" iconName="persona" />
    //     },
    //     // {
    //     //     menuName: "Campaigns",
    //     //     menuKey: "campgains",
    //     //     imgSrc: campaignIcon,
    //     //     whiteImgSrc: whiteCampaignIcon,
    //     //     navigationLink: "/dashboard/campaigns"
    //     // },
    //     // {
    //     //     menuName: "Brand Voice",
    //     //     menuKey: "brandVoice",
    //     //     imgSrc: brandVoiceIcon,
    //     //     whiteImgSrc: whiteBrandVoice,
    //     //     navigationLink: "/dashboard/brandVoice"
    //     // },
    //     // {
    //     //     menuName: "Get started",
    //     //     menuKey: "getstarted",
    //     //     whiteImgSrc: whiteGetStartedIcon,
    //     //     imgSrc: getStartedIcon,
    //     //     navigationLink: "/dashboard/getStarted"
    //     // },
    // ]
    // React.useEffect(() => {
    //     localStorage.removeItem('flagRedirectedFromLogin');
    //     if (window.location.pathname.toLowerCase() == "/dashboard/paraphraser") {
    //         setFlagShowRecaptchaPopUp(false)
    //     } else {
    //         if (userDetail.flagUser == false) {
    //             setFlagShowRecaptchaPopUp(true)
    //         } else {
    //             setFlagShowRecaptchaPopUp(false)
    //         }
    //     }
    // }, [userDetail.flagUser, window.location.pathname])

    React.useEffect(() => {
        if (window.location.pathname !== "/dashboard/settings-accountDetails" && window.location.pathname !== "/dashboard/settings-plansAndBilling") {
            setTabValuePanel(0)
        }
        // emptying the context
        setCurrentActiveDocDetails(null)
        setCurrentActiveDocFieldVal(null)
        setExecutePromptResponse(null);
        setFlagShowAIOutput(false)
        setDocumentEditorData(null)
        setDrawerProps({
            openDrawer: false,
            dropdownOptions: [],
            paraphrasingPayloadKeyValue: "",
            optionKeyName: "",
            paraphrasingTabKey: null,
            optionImage: null,
            isValMultiple: false,
            dropdownName: ""
        })
        if (userDetail.flagUser) {
            (async () => {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true });
                // await GetDocList()
                // await GetTemplateList()
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            })()
        }
    }, [window.location.pathname])


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // ******************** HERE COMMENTED :  APPLIED CONDITIONS FOR USER ENTER FIRST TIME IN PARAPHRASER **********************
    // const [open, setOpen] = React.useState(fatchBrowserURL == "/dashboard/Paraphraser" ? false : true);
    let isDrawerClosed = searchParams.get('collapsed')
    const [open, setOpen] = React.useState(isDrawerClosed === 'true' ? false : isDrawerClosed === 'false' ? true : true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    // one tap login
    const handleCredentialResponse = async (response: any, er: any) => {
        let apiResponse = await OneTapLoginAuthCallback(response)
        if (apiResponse?.status) {
            window.location.reload()
        }
    };

    React.useEffect(() => {
        // Load the Google Sign-In client
        if (userDetail.flagUser) {
        } else {
            const script = document.createElement("script");
            script.src = "client.js";
            script.async = true;
            document.head.appendChild(script);

            script.onload = () => {
                window.google.accounts.id.initialize({
                    client_id:
                        "361992322546-k97tfp20831fuavdabsl5tei4gc2g0a9.apps.googleusercontent.com",
                    callback: handleCredentialResponse
                });
                window.google.accounts.id.prompt((notification: any) => {
                    if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                    }
                });
            };

            return () => {
                // Cleanup script element
                document.head.removeChild(script);
            };
        }

    }, [window.location.pathname]);
    return (
        <>
            {flagShowRecaptchaPopUp &&
                <GlobalPopUp
                    borderRadius='20px'
                    open={flagShowRecaptchaPopUp}
                    onClose={() => { setFlagShowRecaptchaPopUp(false) }}
                    // title={"Get started"}
                    flagBackdrop
                    flagShowCloseIcon={false}
                    handleClose={() => { setFlagShowRecaptchaPopUp(false) }}
                >
                    <GetStartedPopUpData />
                </GlobalPopUp>
            }

            <Grid container className={globalStyles.containerflexWrapStyle}>

                {/* *****************Temp Comment For the task of : BG image for sign up popup - show the platform  {userDetail.flagUser && */}
                <Grid item sx={{ width: { sm: open ? drawerWidth : 70 }, flexShrink: { sm: 0 }, position: "relative" }} >
                    {open ? (
                        <img src={closeArrow}
                            className={globalStyles.closeArrowStyle}
                            onClick={handleDrawerClose} />


                    ) : (
                        <img src={closeArrow}
                            className={globalStyles.openArrowStyle}
                            onClick={handleDrawerOpen} />

                    )}
                    <SideNavigationPanel
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        // menuItems={userDetail.flag_user_anonymous ? menuItemsPublic : menuItemsPrivate}
                        menuItems={sidePanelMenuItems}
                        openDesktopDrawer={open}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose}

                    />
                </Grid>
                {/* ******************************* } ****************************************************************** */}


                <Grid item sx={{ width: "100%" }} // sx={{ width: { sm: `calc(100% - ${open ? drawerWidth : 96}px)` } }} 
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        className={globalStyles.headerMenuIcon}
                        sx={{ marginLeft: "0px", display: { xl: "none !important", lg: "none !important", md: "none !important", sm: 'none !important' } }}

                    >
                        <MenuIcon />
                    </IconButton>

                    {!userDetail.flagUser &&
                        <>
                            {/* {fatchBrowserURL.toLowerCase() == "/dashboard" && <HomeComponent />} */}
                            {/* {fatchBrowserURL.toLowerCase() == "/dashboard/templates" && <Template />} */}
                            {/* {fatchBrowserURL.toLowerCase() == "/dashboard/documents" && <Documents />} */}
                            {/* {fatchBrowserURL.toLowerCase() == "/dashboard/persona" && <Persona />} */}
                            {/* {fatchBrowserURL.toLowerCase() == "/dashboard/paraphraser" && <Paraphrasing />} */}
                            {/* {fatchBrowserURL.toLowerCase() == "/dashboard/chatbot" && <ChatbotList />} */}
                            {/* {fatchBrowserURL.toLowerCase() == "/dashboard/campaigns" && <Campaigns />} */}
                            {/* {fatchBrowserURL.toLowerCase() == "/dashboard/settings-accountDetails" && <Settings />} */}
                        </>
                    }

                    <Grid container sx={{ justifyContent: "space-between" }} direction={"column"}>
                        <Grid item sx={{ display: { xl: "none !important", lg: "none !important", md: "none !important", sm: "none !important" } }}>
                            <Header flagShowLogo={true} menuItems={sidePanelMenuItems} />
                        </Grid>
                        <Grid item className={`${globalStyles.dashboardMaxWidthStyles}`}>
                            <Grid container className={globalStyles.dashboardOutletWrapper}>
                                <Outlet />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Box sx={{ display: 'flex', position: "relative", overflow: "hidden" }}>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    <SideNavigationPanel
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        menuItems={menuItems}
                    />
                </Box>
                <Box sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={globalStyles.dashboardNavigationToggleButtonStyle}
                        sx={{ mr: 2, display: { sm: 'none' } }}

                    >
                        <MenuIcon />
                    </IconButton>

                    <Grid container sx={{ justifyContent: "space-between" }} direction={"column"}>
                        <Grid item>
                            <Header flagShowLogo={false} />
                        </Grid>
                        <Grid item sx={{ height: "calc(100vh - 64px)" }}>
                            <Grid container sx={{ width: "100% !important", margin: "0px !important", flexWrap: "nowrap", height: "calc(100vh - 64px)" }}>
                                <Outlet />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box> */}
        </>
    );
}
