import { Avatar, Button, Divider, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/CampaigTabView.module.scss'
import globalStyles from '../../../assets/styles/global.module.scss'
import AddIcon from '@mui/icons-material/Add';
import { GlobalTable } from '../../utility/globalTable';
import moment from 'moment';
import GlobalPopUp from '../../utility/globalPopUp';
import CampaignDocumentDialog from './CampaignDocumentDialog';
import GlobalForm from '../../utility/formWiz/globalForm';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { CampaignContext, CampaignContextPayload } from '../../../context/campaignContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import agent from '../../../services/api';
import { getCampaignDocumentsResponseModel } from '../../../models/axiosModel';
import GlobalButton from '../../utility/globalButton/button';
import docIcon from "../../../assets/document_30px.svg";
import DeleteDocumentDataComponent from '../deleteDocumentDataComponent';
import CachedIcon from '@mui/icons-material/Cached';
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';
interface docPageTheme {
    [key: string]: any;
}
interface globalFormField {
    [key: string]: any;
}
interface campaignDetailsDataType {
    doc_page_theme: docPageTheme[],
    global_form_field: globalFormField[]
    widget_flag_auto_save: boolean,
    widget_flag_hide_button: boolean,
    widget_form_full_width: boolean
}
interface campaignDetails {
    data: campaignDetailsDataType
}

const CampaignTabView = () => {

    let headerList = [
        {
            name: "Documents",
            alignment: "left",
            flagIcon: true,
            valueKey: "document_name",
            iconKey: "documentIcon",
        },
        {
            name: "Last Modified",
            alignment: "left",
            flagIcon: false,
            valueKey: "formatted_timestamp",
            iconKey: ""
        },
        {
            name: "Type",
            alignment: "left",
            flagIcon: true,
            valueKey: "template_name",
            iconKey: "featureIcon"
        },
        {
            name: "",
            alignment: "right",
            flagIcon: true,
            valueKey: "",
            iconKey: "deleteIcon"
        },
    ]

    const {
        getCampaignDetails,
        campaignExistingDetails,
        getCampaignDocument,
        campaignDocList,
        setTemplateCampaignList
    } = useContext(CampaignContext) as CampaignContextPayload;

    const {
        setFlagLoading,
        flagLoading,
        success,
        setSuccess,
        error,
        setError,
    } = useContext(UserContext) as UserContextPayload;

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const documentCampaignId = searchParams.get("campaign_id")
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const isMobile = screenWidth <= 600;
    const dialogMinWidth = isMobile ? '300px' : 'auto';

    const [flagTabs, setFlagTabs] = useState(0);
    const [flagNewContentDialog, setFlagNewContentDialog] = useState(false)
    const [submissionID, setsubmissionID] = React.useState(null);
    // const [flagFirstRenderDone, setFlagFirstRenderDone] = useState(false)
    const [campaignHeaderList, setCampaignHeaderList] = useState(headerList)
    const [campaignDocumentDataForTable, setCampaignDocumentDataForTable] = useState<getCampaignDocumentsResponseModel[]>([])
    const [flagDeleteDocumentDialog, setFlagDeleteDocumentDialog] = useState(false)
    const [selectedDocumentData, setSelectedDocumentData] = useState<getCampaignDocumentsResponseModel | null>(null)
    const [renderValue, setRenderValue] = React.useState({
        status: null,
        message: "",
        submission_id: null
    })
    const [campaignDetails, setCampaignDetails] = useState<campaignDetails>({
        data: {
            widget_form_full_width: true,
            global_form_field: [
                {
                    "page_field_id": 1666,
                    "label": "Campaign Name",
                    "placeholder": "Campaign name",
                    "required": true,
                    "name": "1666_Campaign_Name",
                    "type": "text",
                    "value": campaignExistingDetails?.campaign_name ? campaignExistingDetails?.campaign_name : "",
                    "payload_name": "campaign_name",
                    "size": "small",
                    "hidden": false,
                    "position": null,
                    "font_color": null,
                    "col": 12,
                    "class2": "globalTextfieldStyle"
                },
                {
                    "page_field_id": 1667,
                    "label": "Overview",
                    "placeholder": "",
                    "required": true,
                    "name": "1667_Campaign_Overview",
                    "type": "longtext",
                    "minRows": "3",
                    "maxRows": "3",
                    "value": campaignExistingDetails?.campaign_overview ? campaignExistingDetails?.campaign_overview : "",
                    "payload_name": "campaign_overview",
                    "hidden": false,
                    "size": "small",
                    "position": null,
                    "font_color": null,
                    "col": 12,
                    "rows": 5,
                    "class2": "globalTextfieldStyle",

                },
                {
                    "page_field_id": 1668,
                    "label": "Goals",
                    "placeholder": "",
                    "required": false,
                    "name": "1668_Goals",
                    "type": "longtext",
                    "minRows": "3",
                    "maxRows": "3",
                    "value": campaignExistingDetails?.goals ? campaignExistingDetails?.goals : "",
                    "payload_name": "goals",
                    "hidden": false,
                    "size": "small",
                    "position": null,
                    "font_color": null,
                    "col": 12,
                    "rows": 5,
                    "class2": "globalTextfieldStyle"

                },
                {
                    "page_field_id": 1669,
                    "label": "Target Audience",
                    "placeholder": "",
                    "required": false,
                    "name": "1669_target_audience",
                    "type": "longtext",
                    "minRows": "3",
                    "maxRows": "3",
                    "value": campaignExistingDetails?.target_audience ? campaignExistingDetails?.target_audience : "",
                    "payload_name": "target_audience",
                    "hidden": false,
                    "size": "small",
                    "position": null,
                    "font_color": null,
                    "col": 12,
                    "rows": 5,
                    "class2": "globalTextfieldStyle"
                },
                {
                    "page_field_id": 1670,
                    "label": "Key Messages",
                    "placeholder": "",
                    "required": false,
                    "name": "1670_Key_Messages",
                    "value": campaignExistingDetails?.key_messages ? campaignExistingDetails?.key_messages : "",
                    "payload_name": "key_messages",
                    "type": "longtext",
                    "minRows": "3",
                    "maxRows": "3",
                    "hidden": false,
                    "size": "small",
                    "position": null,
                    "font_color": null,
                    "col": 12,
                    "rows": 5,
                    "class2": "globalTextfieldStyle"
                },
                {
                    "page_field_id": 1671,
                    "label": "Things to avoid",
                    "placeholder": "",
                    "required": false,
                    "name": "1671_Things_To_Avoid",
                    "value": campaignExistingDetails?.things_to_avoid ? campaignExistingDetails?.things_to_avoid : "",
                    "payload_name": "things_to_avoid",
                    "type": "longtext",
                    "minRows": "3",
                    "maxRows": "3",
                    "hidden": false,
                    "size": "small",
                    "position": null,
                    "font_color": null,
                    "col": 12,
                    "rows": 5,
                    "class2": "globalTextfieldStyle"
                },
                {
                    "page_field_id": 1672,
                    "label": "Channels",
                    "placeholder": "",
                    "required": false,
                    "name": "1672_Channels",
                    "type": "longtext",
                    "minRows": "3",
                    "maxRows": "3",
                    "value": campaignExistingDetails?.channels ? campaignExistingDetails?.channels : "",
                    "payload_name": "channels",
                    "hidden": false,
                    "size": "small",
                    "position": null,
                    "font_color": null,
                    "col": 12,
                    "rows": 5,
                    "class2": "globalTextfieldStyle"
                },
                {
                    "page_field_id": 1673,
                    "label": "Timeline",
                    "placeholder": "",
                    "required": false,
                    "name": "1673_Timeline",
                    "value": campaignExistingDetails?.timeline ? campaignExistingDetails?.timeline : "",
                    "payload_name": "timeline",
                    "type": "longtext",
                    "minRows": "3",
                    "maxRows": "3",
                    "hidden": false,
                    "size": "small",
                    "position": null,
                    "font_color": null,
                    "col": 12,
                    "rows": 5,
                    "class2": "globalTextfieldStyle"
                },
                {
                    "page_field_id": 1674,
                    "label": "CTA",
                    "placeholder": "",
                    "required": false,
                    "name": "1674_CTA",
                    "type": "longtext",
                    "minRows": "3",
                    "maxRows": "3",
                    "value": campaignExistingDetails?.call_to_action ? campaignExistingDetails?.call_to_action : "",
                    "payload_name": "call_to_action",
                    "hidden": false,
                    "size": "small",
                    "position": null,
                    "font_color": null,
                    "col": 12,
                    "rows": 5,
                    "class2": "globalTextfieldStyle"
                },
            ],
            widget_flag_auto_save: false,
            widget_flag_hide_button: false,
            doc_page_theme: [
                {
                    "page_id": "682",
                    "page_theme_font_type": "Open Sans",
                    "page_theme_font_style": "normal",
                    "page_theme_font_align": "left",
                    "page_theme_font_weight": "bold",
                    "page_theme_font_size": "15",
                    "page_theme_background_color": "#FFFFFF",
                    "page_theme_heading_color": "#EFE4F1",
                    "page_theme_text_decoration": "unset",
                    "page_hide_button": false,
                    "page_auto_save": false,
                    "page_font_spacing": null,
                    "page_theme_row_spacing": "10px 0px 10px 0px",
                    "page_theme_column_spacing": 0,
                    "margin": "0px 0px 8px 0px"
                }
            ]
        }
    })
    const [initialCampaignDetails, setInitialCampaignDetails] = useState<campaignDetails | null>(null)
    // const [flagFieldsChange, setFlagFieldsChange] = useState(false)
    const [fieldChange, setFieldChange] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                if (searchParams) {
                    let campaign_id = searchParams.get("campaign_id");
                    if (campaign_id) {
                        setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
                        const response = await getCampaignDetails(campaign_id);
                        if (!response.status) {
                            // setError({ ...error, flagShowError: true, message: "Campaign doesn't exist." })
                            navigate('/dashboard/campaigns');
                        }
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                    }
                    else {
                        navigate('/dashboard/campaigns');
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                    }
                }
            }
            catch (err) {
                console.error("An error occurred:", error);
                setError({ ...error, flagShowError: true, message: "Something went wrong, Please try again later!" })
            }
            finally {
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (searchParams) {
                    let campaign_id = searchParams.get("campaign_id");
                    if (campaign_id) {
                        setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
                        await getCampaignDocument(campaign_id);
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                    }
                    else {
                        navigate('/dashboard/campaigns');
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                        setError({ ...error, flagShowError: true, message: "Something went wrong" })
                    }
                }
            }
            catch (err) {
                console.error("An error occurred:", err);
                setError({ ...error, flagShowError: true, message: "Something went wrong" })
            }
            finally {
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
            }
        })();

    }, [])

    useEffect(() => {
        let tempDocList = campaignDocList?.length ? [...campaignDocList] : []
        tempDocList?.map((documents: getCampaignDocumentsResponseModel) => {
            documents['document_id'] = documents?.document_id;
            documents['document_name'] = documents?.document_name;
            documents['alignment'] = "left";
            documents['iconSrc'] = documents?.template_icon_url != null ? documents?.template_icon_url : docIcon
            documents['formatted_timestamp'] = moment(documents.updated_at_timestamp).format('DD-MM-YY HH:mm');
        })
        setCampaignDocumentDataForTable(tempDocList)

    }, [campaignDocList])

    useEffect(() => {
        if (isMobile) {
            // For mobile view
            const filteredHeaderList = headerList.filter(header => header.name !== "Type");
            const modifiedHeaderList = filteredHeaderList.map(header => {
                if (header.name === "Documents") {
                    return { ...header, flagIcon: false };
                }
                if (header.name === "Date and time") {
                    return { ...header, name: "Last Modified" };
                }
                return header;
            });
            setCampaignHeaderList(modifiedHeaderList);
            campaignDocumentDataForTable.slice(0, 10).map((row: any) => {
                row[`formatted_timestamp`] = moment(row.updated_at_timestamp).format('DD-MM-YY HH:mm')
            })
        } else {
            // For desktop view
            setCampaignHeaderList(headerList);
            campaignDocumentDataForTable.slice(0, 10).map((row: any) => {
                row[`formatted_timestamp`] = moment(row.updated_at_timestamp).format('DD-MM-YY HH:mm')
            })
        }
    }, [isMobile, campaignDocumentDataForTable]);

    useEffect(() => {
        if (campaignExistingDetails) {
            let temp = { ...campaignDetails };
            for (const field of temp.data.global_form_field) {
                const payloadName = field.payload_name;
                // @ts-ignore
                field.value = campaignExistingDetails[payloadName] || "";
            }
            setCampaignDetails(temp);
            let initialData = JSON.parse(JSON.stringify(temp))
            setInitialCampaignDetails(initialData)
        }
    }, [campaignExistingDetails])

    const getCancelButtonDisableConditions = () => {
        if (JSON.stringify(campaignDetails) === JSON.stringify(initialCampaignDetails)) {
            return true;
        }
        else {
            return false;
        }
    }

    // useEffect(() => {
    //     getCancelButtonDisableConditions();
    // }, [campaignDetails]);

    const getSaveButtonDisableCondition = () => {
        let fields = campaignDetails["data"]["global_form_field"]
        let compareData = (JSON.stringify(campaignDetails) === JSON.stringify(initialCampaignDetails));
        for (const field of fields) {
            if (compareData || (field.required && ((typeof field.value === "string" && field.value.trim().length === 0) || (Array.isArray(field.value) && field.value.length === 0))) || field.value == null) {
                return true
            }
        }
        return false
    }

    const formSubmitFunction = async (data: any) => {
        var finalData = data;
    }

    const handleCloseSnackBar = () => {
        setRenderValue({
            status: null,
            message: "",
            submission_id: null
        })
    }


    // set form field value
    const setFieldValue = (data: any) => {
        var tempdata = { ...campaignDetails }
        tempdata.data.global_form_field.map((newvalue: any) => {
            if (newvalue.options) {
                newvalue.options.map((option_value: any) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
        })
        setCampaignDetails(tempdata)
        // setFlagFirstRenderDone(true)
        if (JSON.stringify(campaignDetails) === JSON.stringify(initialCampaignDetails)) {
            setFieldChange(false)
        }
        else {
            setFieldChange(true);
        }
    }

    // Update Existing Details
    const handleUpdateCampaignDetails = async () => {
        let campaignId = searchParams.get('campaign_id')
        let payload = {
            campaign_id: Number(campaignId),
            campaign_name: campaignDetails.data.global_form_field[0].value,
            campaign_overview: campaignDetails.data.global_form_field[1].value,
            goals: campaignDetails.data.global_form_field[2].value,
            target_audience: campaignDetails.data.global_form_field[3].value,
            key_messages: campaignDetails.data.global_form_field[4].value,
            things_to_avoid: campaignDetails.data.global_form_field[5].value,
            channels: campaignDetails.data.global_form_field[6].value,
            timeline: campaignDetails.data.global_form_field[7].value,
            call_to_action: campaignDetails.data.global_form_field[8].value,
        }

        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await agent.updateCampaignDetails.put(payload)
        if (response.status) {
            if (searchParams) {
                let campaign_id = searchParams.get("campaign_id");
                if (campaign_id) {
                    await getCampaignDetails(campaign_id);
                }
                else {
                    navigate('/dashboard/campaigns');
                }
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
            setSuccess({ ...success, flagShowSuccess: true, message: response.data.message })
        }
        setFieldChange(false);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setFlagTabs(newValue);
    };

    const rowClicked = async (e: any) => {
        if (e.template_type_name == "Single-Page") {
            navigate(`/doc/${e.document_uuid}/3/${e.template_id}`)

        } else {
            navigate(`/doc/${e.document_uuid}/1/${e.template_id}`)
        }
    }

    const deleteDocumentDataPopupOpen = (event: any, data: any) => {
        // event.stopPropagation()
        setFlagDeleteDocumentDialog(true)
        setSelectedDocumentData(data)
    }

    const handleDeleteDocument = async (data: any) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await agent.deleteDocument.delete(data)
        if (response.status) {
            deleteCancelDocumentDataPopupOpen()
            if (documentCampaignId) {
                setSuccess({ ...success, flagShowSuccess: true, message: response.data.message })
                let responseOfGetDocument = await getCampaignDocument(documentCampaignId)
                if (responseOfGetDocument.status) {
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                }
                else {
                    setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                }
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
        else {
            setError({ ...error, flagShowError: true, message: "Something went wrong!" })
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    }

    const deleteCancelDocumentDataPopupOpen = () => {
        setFlagDeleteDocumentDialog(false)
    }

    const handleRefreshingDocument = async () => {
        try {
            if (documentCampaignId) {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
                await getCampaignDocument(documentCampaignId);
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
            }
            else {
                navigate('/dashboard/campaigns');
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                setError({ ...error, flagShowError: true, message: "Something went wrong" })
            }
        }
        catch (err) {
            console.error("An error occurred:", err);
            setError({ ...error, flagShowError: true, message: "Something went wrong" })
        }
        finally {
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
        }
    }

    const handleCancelCampaignDetails = () => {
        setCampaignDetails(JSON.parse(JSON.stringify(initialCampaignDetails)))
    }


    return (
        <>
            {flagDeleteDocumentDialog &&
                <GlobalPopUp
                    open={flagDeleteDocumentDialog}
                    onClose={() => { setFlagDeleteDocumentDialog(false) }}
                    title={""}
                    flagShowCloseIcon={true}
                    handleClose={() => { setFlagDeleteDocumentDialog(false) }}
                >
                    <DeleteDocumentDataComponent
                        documentSelected={selectedDocumentData ? selectedDocumentData.document_uuid : ""}
                        deleteCancelDocumentDataPopupOpen={deleteCancelDocumentDataPopupOpen}
                        handleDeleteDocument={handleDeleteDocument}
                        deleteDescription="Are you sure you want to delete this document?"
                    />
                </GlobalPopUp>
            }
            {flagNewContentDialog &&
                <GlobalPopUp
                    open={true}
                    onClose={() => { setFlagNewContentDialog(false); setTemplateCampaignList(null) }}
                    handleClose={() => { setFlagNewContentDialog(false) }}
                    maxWidth="lg"
                    PaperProps={{ style: { minWidth: dialogMinWidth } }}
                >
                    <CampaignDocumentDialog
                        setFlagNewContentDialog={setFlagNewContentDialog}
                        documentCampaignId={documentCampaignId}
                        setFlagTabs={setFlagTabs}
                        campaignDocumentDataForTable={campaignDocumentDataForTable}
                    />
                </GlobalPopUp>
            }
            <Grid container>
                <Grid item xs={12} className={`${styles.cmpGlobalHeadSpacing}`}>
                    <Grid container>

                        <Grid item xs={12} className={styles.cmpGlobalHeaderGrid}>
                            <Grid container sx={{ justifyContent: { xl: "space-between", lg: "space-between", md: "space-between", sm: "space-between", xs: "space-between" } }}>
                                <Grid item xl={8} lg={9} md={12} sm={12} xs={10} sx={{ margin: { xs: "0px 0px 0px 0px", sm: "0px 0px 0px 5px" } }} >
                                    <GlobalPageHeaderText
                                        mainHeadText={"Campaign"}
                                        mainSubText={"Create a campaign that transforms a simple brief into a full suite of essential documents seamlessly"}
                                    />
                                </Grid>
                                {window.screen.width < 600 && <Grid item xs={2} className={styles.plusIconGrid}>
                                    <IconButton
                                        className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                        onClick={() => { setFlagNewContentDialog(true) }}
                                    >
                                        <AddIcon />
                                    </IconButton>
                                </Grid>}
                                <Grid item xl={4} lg={4} md={4} sm={0} xs={12} sx={{ margin: { xs: "0px 0px 0px" } }}>
                                    {/* <GlobalSearchBar
                                        onSearch={() => { }}
                                        onCancelSearch={() => { }}
                                        classGlobal=""
                                    /> */}
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={`${styles.cmpGlobalComponentBorderItem}`} >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container alignItems={"center"} className={`${styles.cmpHeadNewContentContainer}`}>
                                        <Grid item xl={10} lg={9} md={8} sm={8} xs={window.screen.width < 600 ? 12 : 8} className={`${styles.cmpHeadTextItem}`} >
                                            <Typography component={'span'} className={`${styles.wzGlobalHead}`}>
                                                {campaignExistingDetails?.campaign_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={2} lg={3} md={4} sm={4} xs={window.screen.width < 600 ? 4 : 4} className={styles.plusIconGrid}>
                                            {window.screen.width >= 600 && window.screen.width < 800 &&
                                                <IconButton
                                                    className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                                    onClick={() => { setFlagNewContentDialog(true) }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            }
                                            {window.screen.width >= 800 &&
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            size='medium'
                                                            variant='contained'
                                                            className={`${styles.wzNewContentBtn}`}
                                                            startIcon={<AddIcon />}
                                                            onClick={() => { setFlagNewContentDialog(true) }}
                                                        >
                                                            New Content
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Divider className={`${styles.cmpDivider}`} />

                                <Grid item xs={12} className={flagTabs == 0 ? `${styles.cmpTabItem}` : `${styles.cmpTabItem2}`}>
                                    <Grid container>
                                        <Grid item xs={12} >
                                            <Tabs value={flagTabs} onChange={handleChange} className={`${styles.cmpTabText}`} indicatorColor="secondary" >
                                                <Tab
                                                    key={0}
                                                    label="Details"
                                                    className={flagTabs === 0 ? `${styles.cmpSelctedTab} ${styles.cmpDetailsTab}` : `${styles.cmpTab} ${styles.cmpDetailsTab}`}
                                                />
                                                <Tab
                                                    key={1}
                                                    label="Documents"
                                                    className={flagTabs === 1 ? `${styles.cmpSelctedTab} ${styles.cmpDocumentsTab}` : `${styles.cmpTab} ${styles.cmpDocumentsTab}`}
                                                    icon={<Avatar className={flagTabs === 0 ? styles.cmpDocumentsAvatar : styles.cmpDocumentsSelectedAvatar}>{campaignDocumentDataForTable.length > 99 ? "99+" : campaignDocumentDataForTable.length}</Avatar>}
                                                    iconPosition='end'
                                                />
                                            </Tabs>
                                            {flagTabs === 0 &&
                                                <Grid container className={`${styles.cmpTabSpacing} ${styles.cmpTabContainer}`} >
                                                    {/* {!flagLoading && */}
                                                    <Grid item xl={5} lg={7} md={8} sm={12} xs={12} >
                                                        <GlobalForm
                                                            fields={campaignDetails.data.global_form_field}
                                                            submitFunction={formSubmitFunction}
                                                            checkFormSubmit={renderValue}
                                                            handleCloseSnackBar={handleCloseSnackBar}
                                                            pageThemeData={campaignDetails.data.doc_page_theme[0]}
                                                            formData={campaignDetails.data}
                                                            submissionID={submissionID}
                                                            setFieldValue={setFieldValue}
                                                            recaptchaFlag="true"
                                                            defaultLoader={false}
                                                            formEditable={true}
                                                        />
                                                    </Grid>

                                                    {/* } */}
                                                </Grid>
                                            }
                                            {flagTabs === 1 &&
                                                <Grid container className={`${styles.cmpTabSpacing}`}>
                                                    <Grid item xs={12}>
                                                        <GlobalTable
                                                            fullwidth
                                                            tableHeight={680}
                                                            headerList={campaignHeaderList}
                                                            rowData={campaignDocumentDataForTable}
                                                            onRowClick={(e: any) => { console.log("Hello", e) }}
                                                            deleteRowData={deleteDocumentDataPopupOpen}
                                                            rowClicked={rowClicked}
                                                            flagPagination
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                        {flagTabs === 1 &&
                                            <Grid item className={styles.cmpRefreshGrid}>
                                                <IconButton
                                                    className={styles.cmpRefreshIcon}
                                                    onClick={() => { handleRefreshingDocument(); }}
                                                >
                                                    <CachedIcon />
                                                </IconButton>
                                            </Grid>
                                        }
                                    </Grid>
                                    {flagTabs === 0 && <Grid item xs={12} className={styles.cancelButtonGrid}>
                                        <Grid container className={styles.cmpSaveGrid}>
                                            <GlobalButton
                                                buttonText='Save'
                                                className="primaryButtonStyle"
                                                disabled={getSaveButtonDisableCondition()}
                                                onClick={handleUpdateCampaignDetails}
                                            />
                                            <Button
                                                disabled={getCancelButtonDisableConditions()}
                                                variant='outlined'
                                                size="small"
                                                className={styles.cmpCancelButton}
                                                onClick={handleCancelCampaignDetails}
                                                sx={{ textTransform: "capitalize", opacity: getCancelButtonDisableConditions() ? "0.5" : "" }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>

                                    </Grid>}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default CampaignTabView