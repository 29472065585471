import { SplitPane } from "react-multi-split-pane";
import "../../assets/styles/test.css";
// import Test from "../test";
import OutlineComponent from "../templateComponents/outlineComponent";
import { Button, Grid } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { DocContext, DocContextPayload } from "../../context/docContext";
import closeArrow from "../../assets/closeArrow.png";
import styles from "../../assets/styles/componentStyles/editor.module.scss";
import PlaygroundApp from "../Editor/App";
import ResponsiveBlogEditor from "./ResponsiveEditor";

export default function BlogEditor() {
  const { flagOutlineCollapsed, setFlagOutlineCollapsed, currentActiveDocDetails } = useContext(
    DocContext
  ) as DocContextPayload;
  const initialWidth = Math.max(window.innerWidth - 555, 0);
  const [defaultSize, setDefaultSize] = useState([555, initialWidth]); // Initialize with 0
  const [flagClose, setFlagClose] = useState(false)
  const { flagShowAIOutput } = useContext(DocContext) as DocContextPayload;
  const handleResize = (sizes: any) => {
    if (sizes[0] <= 300) {

      setFlagOutlineCollapsed(true);
      setDefaultSize([25, Math.max(window.innerWidth - 25, 0)]);

    } else {
      setFlagClose(false)
      setFlagOutlineCollapsed(false);
      setDefaultSize([555, sizes[1]]);
      setFlagClose(false);


    }
  };

  useEffect(() => {
    handleResize(defaultSize);
  }, []);

  useEffect(() => {
    if (flagClose) {
      (document.getElementsByClassName('Pane')[0] as HTMLElement).style.flex = '19 50 auto';
      (document.getElementsByClassName('Pane')[0] as HTMLElement).style.minWidth = '10px';
    } else {

    }
  }, [])


  function Help(sizes: number[]) {
    if (sizes[0] <= 300) {
      if (!flagClose) {
        setTimeout(() => {

          (document.getElementsByClassName('Pane')[0] as HTMLElement).style.flex = '19 50 auto';
        }, 0)
        setFlagClose(true);
      }

    } else {


    }
  }
  const handleCollapse = () => {
    if (flagOutlineCollapsed) {
      (document.getElementsByClassName('Pane')[0] as HTMLElement).style.flex = '555';
    } else {
      (document.getElementsByClassName('Pane')[0] as HTMLElement).style.flex = '19 50 auto';
    }
    setFlagOutlineCollapsed(!flagOutlineCollapsed)
  }
  return (
    <>
      {/* mobile view editor */}
      <ResponsiveBlogEditor />
      {/* desktop view editor */}
      <Grid container className={styles.editorDesktopView}>
        <SplitPane
          split="vertical"
          className={`${styles.splitpaneStyle} ${flagOutlineCollapsed ? "ABCD" : "EFGH"}`}
          onDragFinished={Help}
          onChange={handleResize}
          defaultSizes={defaultSize}
          minSize={[20, Math.max(window.innerWidth - 900, 0)]}
        >
          <div className={flagClose ? `${styles.close}` : ""}>
            <div
              style={{
                position: "absolute",
                transform: `rotate(${flagOutlineCollapsed ? "0deg" : "180deg"})`,
                zIndex: "10",
                right: "-15px",
                top: "1%",
                cursor: "pointer",
              }}
              onClick={() => {
                handleCollapse()
              }}
            >
              <img src={closeArrow} style={{ width: "30px", height: "30px" }} />
            </div>
            <OutlineComponent />
          </div>
          <div className="right panel" style={{ width: "100%" }}>
            {/* to hide the editor until content is generated */}
            {currentActiveDocDetails?.document_name !== "Untitled Document" &&
              <PlaygroundApp />}
          </div>
        </SplitPane>
      </Grid>
    </>
  );
}
