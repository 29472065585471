import * as React from "react";
import {
  Box,
  InputLabel,
  TextField,
  Grid,
} from "@mui/material";
import styles from "../../assets/styles/componentStyles/optionList.module.scss";
import globalStyles from "../../assets/styles/global.module.scss";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Skeleton from "@mui/material/Skeleton";
import Required from "../utility/required";
import { DocContext, DocContextPayload } from "../../context/docContext";
import GlobalButton from "../utility/globalButton/button";
import { ExecutePromptResponseDataModel, outputFormatObj } from "../../models/axiosModel";
import { UserContext, UserContextPayload } from "../../context/userContext";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { TemplateContext, TemplateContextPayload } from "../../context/templateContext";
import { emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, isDev } from "../../utils/constants";
import { useNavigate } from "react-router";
import { checkForErrorCode } from "../../utils/constants";
const data = [
  {
    id: 0,
    heading: "Exploring the Possibilities of AI: What the Future Holds",
  },
  {
    id: 1,
    heading: "Charting the Course of the AI Revolution",
  },
  {
    id: 2,
    heading: "Preparing for an AI-Driven Future",
  },
  {
    id: 3,
    heading: "Unlocking the Potential of Artificial Intelligence",
  },
  {
    id: 4,
    heading: "Discovering New Frontiers with Artificial Intelligence",
  },
  {
    id: 5,
    heading: "Exploring the Possibilities of AI: What the Future Holds",
  },
  {
    id: 6,
    heading: "Charting the Course of the AI Revolution",
  },
  {
    id: 7,
    heading: "Preparing for an AI-Driven Future",
  },
  {
    id: 8,
    heading: "Unlocking the Potential of Artificial Intelligence",
  },
  {
    id: 9,
    heading: "Discovering New Frontiers with Artificial Intelligence",
  },
  {
    id: 10,
    heading: "Exploring the Possibilities of AI: What the Future Holds",
  },
  {
    id: 11,
    heading: "Charting the Course of the AI Revolution",
  },
  {
    id: 12,
    heading: "Preparing for an AI-Driven Future",
  },
  {
    id: 13,
    heading: "Unlocking the Potential of Artificial Intelligence",
  },
  {
    id: 14,
    heading: "Discovering New Frontiers with Artificial Intelligence",
  },
];

interface option {
  id: number;
  heading: string;
}
export default function OptionListComponent(props: any) {
  const navigate = useNavigate()
  const { setError, error, setFlagShowCreditsExhausted, userDetail, setUserDetail } = React.useContext(UserContext) as UserContextPayload;
  const { routeSources, outputFormatGenericList } = React.useContext(TemplateContext) as TemplateContextPayload;
  const { flagGenerateButtonDisabled, blogTitle, setBlogTitle, currentActiveDocDetails, UpdateDocTitle, UpdateDocInfo, setCurrentActiveDocDetails, ExecutePrompt, setAISuggestedTitles, aiSuggestedTitles, flagTextLoader,
    setFlagTextLoader, flagDebouncePending, flagGenerateTitleButtonClicked } = React.useContext(DocContext) as DocContextPayload;
  const [selectedOption, setSelectedOption] = React.useState<ExecutePromptResponseDataModel>(
    { title: blogTitle });
  const [currentPage, setCurrentPage] = React.useState(0);
  const [temporarySelectedOptionForStyle, setTemporarySelectedOptionForStyle] =
    React.useState<ExecutePromptResponseDataModel>({ title: "-1" });
  const handleClickOption = async (option: ExecutePromptResponseDataModel) => {
    setBlogTitle(option.title)
    setSelectedOption(option);
    setTimeout(() => {
      setTemporarySelectedOptionForStyle({
        title: ""
      });

    }, 3000);
  };
  const [debounceBlogTitle] = useDebounce(selectedOption.title, 2000);

  // no need to make this call since user's credit will get cut unnecessarily
  // React.useEffect(() => {
  //   // debugger;
  //   (async () => {
  //     if (blogTitle !== "" && aiSuggestedTitles[0].title == "") {
  //       await executePromptCall()
  //     }
  //   })()

  // }, [])
  const executePromptCall = async () => {
    if (flagDebouncePending) {
      setFlagTextLoader(true)
      const payload = {
        document_uuid: currentActiveDocDetails !== null ? currentActiveDocDetails.document_uuid : "",
        prompt_id: 4,
        route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id ?? 2,
        output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
      }
      setTimeout(async () => {
        const response = await ExecutePrompt(payload);
        setFlagTextLoader(false)
        if (response.status) {
          if (response.data) {
            if (aiSuggestedTitles[0].title == "") {
              setAISuggestedTitles(response.data)
            } else {
              // setCurrentPage(currentPage + 1)
              setCurrentPage(aiSuggestedTitles.length / 5) //to go to the latest suggestions
              setAISuggestedTitles([...aiSuggestedTitles, ...response.data])
            }
          }
        } else {
          let errorCode = checkForErrorCode(response)
          if (flagCreditsExhausted(errorCode)) {
            setFlagShowCreditsExhausted(true)
          } else {
            if (flagCreditsExhausted(errorCode)) {
              setFlagShowCreditsExhausted(true)
            } else {
              setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail))
              });
            }
          }
        }
      }, 1500)

    } else {
      setFlagTextLoader(true)
      const payload = {
        document_uuid: currentActiveDocDetails !== null ? currentActiveDocDetails.document_uuid : "",
        prompt_id: 4,
        route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id ?? 2,
        output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
      }
      const response = await ExecutePrompt(payload);
      setFlagTextLoader(false)
      if (response.status) {
        if (response.data) {
          if (aiSuggestedTitles[0].title == "") {
            setAISuggestedTitles(response.data)
          } else {
            // setCurrentPage(currentPage + 1)
            setCurrentPage(aiSuggestedTitles.length / 5) //to go to the latest suggestions
            setAISuggestedTitles([...aiSuggestedTitles, ...response.data])
          }
        }
      }
      else {
        let errorCode = checkForErrorCode(response)
        if (flagCookieExpired(userDetail, errorCode)) {
          setUserDetail(emptyUserDetail)
          if (userDetail.flag_user_anonymous) {
            let url = window.location.pathname
            navigate(url, { replace: true });
          } else {
            setError({
              ...error,
              flagShowError: true,
              message: "Session expired! Please login to continue."
            });
            setTimeout(() => {
              let url = encodeURIComponent(window.location.href)
              navigate("/login?redirect=" + url, { replace: true });
            }, 1500);
          }
        } else {
          if (flagCreditsExhausted(errorCode)) {
            setFlagShowCreditsExhausted(true)
          } else {
            if (flagCreditsExhausted(errorCode)) {
              setFlagShowCreditsExhausted(true)
            } else {
              setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail))
              });
            }
          }
        }

      }
    }

  }

  React.useEffect(() => {

    if (selectedOption?.title)
      debounce()
  }, [selectedOption])

  const debounce = useDebouncedCallback(
    async () => {
      if (currentActiveDocDetails) {
        const payload = {
          document_uuid: currentActiveDocDetails.document_uuid,
          document_title: debounceBlogTitle
        }
        const response = await UpdateDocTitle(payload)
      }
    }, 2000)

  // const handleGenerateMoreTitles = async () => {
  //   // setFlagTextLoader(true)
  //   const payload = {
  //     document_uuid: currentActiveDocDetails !== null ? currentActiveDocDetails.document_uuid : "",
  //     prompt_id: 4
  //   }
  //   const response = await ExecutePrompt(payload)
  //   setFlagTextLoader(false)
  //   if (response.data) {
  //     setCurrentPage(currentPage + 1)
  //     setAISuggestedTitles([...aiSuggestedTitles, ...response.data])
  //   } else {
  //     setError({
  //       ...error,
  //       flagShowError: true,
  //       message: "Something Went wrong! Please try again later.",
  //     });
  //   }
  // };
  return (
    <Grid container direction="column" alignItems="start" sx={{ minHeight: '640px', maxHeight: "100%" }}>

      {/* blog title */}
      <Grid
        item
        className={globalStyles.gridItemStyle}
      >
        <Grid container direction="column">
          <Grid item>
            <InputLabel
              className={globalStyles.globalTextStyleBlack}>
              Blog Title<Required />
            </InputLabel>
          </Grid>

          <Grid item className={styles.blogTitleWrapper}>
            <TextField
              value={selectedOption.title}
              onChange={(e: any) => {
                setSelectedOption({ ...selectedOption, title: e.target.value });
                setBlogTitle(e.target.value)
              }}
              defaultValue={blogTitle}
              className={globalStyles.globalTextfieldStyle}
              // sx={{
              //   width: "100%",
              //   "& .MuiInputBase-input": {
              //     padding: "14.5px 14px",
              //   },
              //   "& .MuiOutlinedInput-root": {
              //     "& fieldset": {
              //       borderColor: "rgb(195 185 185 / 25%)",
              //     },
              //     "&:hover fieldset": {
              //       borderColor: "#F7E9FF",
              //     },
              //   },
              //   "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              //     borderWidth: "0px !important",
              //     borderColor: "unset !important",
              //     boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.05) !important",
              //     "&:hover fieldset": {
              //       borderColor: "red",
              //     },
              //     "& .MuiInput-underline:hover:before": {
              //       border: "none !important",
              //     },
              //   },
              //   display: "flex",
              //   justifyContent: "space-around",
              //   background: "#fff",
              //   userSelect: "none",
              // }}
              placeholder="e.g, How to improve your clickthrough rates"
            />
          </Grid>

        </Grid>
      </Grid>

      {/* options */}
      {(flagTextLoader || aiSuggestedTitles[0].title !== "") &&
        <>
          <Grid item className={styles.generatedTitlesGrid}>
            <Grid
              container
              className={styles.gereratedTitleGridContainer}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item className={styles.optionHeader}>
                AI generated blog titles
              </Grid>
              {aiSuggestedTitles !== null && aiSuggestedTitles.length > 5 && (
                <Grid item className={styles.optionHeader}>
                  <KeyboardArrowLeftIcon
                    sx={{
                      opacity: currentPage == 0 || flagTextLoader ? "0.5" : "1",
                      cursor: currentPage == 0 || flagTextLoader ? "" : "pointer",
                    }}
                    onClick={() => {
                      currentPage > 0 && !flagTextLoader && setCurrentPage(currentPage - 1);
                    }}
                  />{" "}
                  <span className={styles.paginationStyle}>{currentPage + 1}/{aiSuggestedTitles.length / 5}{" "}</span>
                  <KeyboardArrowRightIcon
                    sx={{
                      opacity: currentPage + 1 == aiSuggestedTitles.length / 5 || flagTextLoader ? "0.5" : "1",
                      cursor: currentPage + 1 == aiSuggestedTitles.length / 5 || flagTextLoader ? "" : "pointer",
                    }}
                    onClick={() => {
                      currentPage + 1 != aiSuggestedTitles.length / 5 && !flagTextLoader &&
                        setCurrentPage(currentPage + 1);
                    }}
                  />{" "}
                </Grid>
              )}
            </Grid>
          </Grid>

          {aiSuggestedTitles !== null && Array.isArray(aiSuggestedTitles) && aiSuggestedTitles.slice(currentPage * 5, currentPage * 5 + 5).map((option, index) => (
            <>
              <Grid item className={styles.generatedTitlesItemGrid}>
                <div
                  key={index}
                  onClick={() => {
                    !flagTextLoader && handleClickOption(option);
                  }}
                  aria-disabled={true}
                  // className={
                  //   temporarySelectedOptionForStyle.id == option.id
                  //     ? styles.selectedOptionStyle
                  //     : styles.optionStyle
                  // }
                  className={
                    !flagTextLoader ?
                      temporarySelectedOptionForStyle.title == option.title
                        ? `${styles.selectedOptionStyle} ${globalStyles.clickable}`
                        : `${styles.optionStyle} ${globalStyles.clickable}`
                      :
                      styles.disabledOptionStyle
                  }
                >
                  {flagTextLoader ? (
                    <>
                      <Skeleton variant="rectangular" animation="wave" />
                    </>
                  ) : (
                    option.title
                  )}
                </div>
              </Grid>
            </>
          ))}
        </>
      }

      {/* regenrate button */}
      {/* added the props.flagmobile condition for mobile component */}
      {((!flagTextLoader &&
        aiSuggestedTitles[0].title !== "") || props.flagMobile) &&
        <Grid item className={styles.regenerateTitleGrid}>
          <GlobalButton
            buttonText="Regenerate Titles"
            className="secondaryButtonStyle"
            // disabled={flagGenerateButtonDisabled}
            flagGenerate
            onClick={() => { executePromptCall() }}
          />
        </Grid>}
    </Grid>
  );
}
