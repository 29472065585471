import { Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Required from './utility/required';
import GlobalTextField from './utility/globalTextfield';
import styles from "../assets/styles/componentStyles/accountDetails.module.scss";
import { UserContext, UserContextPayload } from '../context/userContext';
import PhoneInput from 'react-phone-input-2';
import GlobalButton from './utility/globalButton/button';

interface AccountDetailsProps {
    flagRedirectedFromStripe?: boolean
    closePopUpFunc?: () => void;
}

const AccountDetails = (Props: AccountDetailsProps) => {
    const { GetCustomerDetails, setFlagLoading, flagLoading,
        customerDetail,
        UpdateCustomerDetails } = useContext(UserContext) as UserContextPayload;
    const [accountFormDetails, setAccountFormDetails] = useState(customerDetail);
    const [flagIndian, setFlagIndian] = useState(false);
    useEffect(() => {
        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true })
            await GetCustomerDetails()
            setFlagLoading({ ...flagLoading, flagRoundLoader: false })
        })()
    }, [])

    useEffect(() => {
        setAccountFormDetails(customerDetail);
    }, [customerDetail])
    const handleUpdateAccountDetails = async () => {
        const flagUpdated = await UpdateCustomerDetails(accountFormDetails);
        if (flagUpdated && Props.flagRedirectedFromStripe) {
            Props.closePopUpFunc && Props.closePopUpFunc()
        }
    };
    const handlePhoneInputChange = (event: any, data: any) => {

        if (data.countryCode == "in") {
            setAccountFormDetails({ ...accountFormDetails, stripe_customer_phone: event });
            setAccountFormDetails({ ...accountFormDetails, stripe_customer_flag_is_indian: false, stripe_customer_phone: event });
            setFlagIndian(true)
        } else {
            setAccountFormDetails({ ...accountFormDetails, stripe_customer_flag_is_indian: false, stripe_customer_phone: event });
            setFlagIndian(false)
        }
    }
    const getSaveButtonDisableCondition = () => {
        if (JSON.stringify(accountFormDetails) === JSON.stringify(customerDetail)) {
            return true
        } else {
            if (accountFormDetails.stripe_customer_first_name == "" || accountFormDetails.stripe_customer_add1 == "" || accountFormDetails.stripe_customer_email == "" || accountFormDetails.stripe_customer_phone == "" || accountFormDetails.stripe_customer_phone == null || accountFormDetails.stripe_customer_country == "" || accountFormDetails.stripe_customer_state == "" || accountFormDetails.stripe_customer_city == "" || accountFormDetails.stripe_customer_postal_code == "" || accountFormDetails.stripe_customer_postal_code == null) {
                return true
            } else {
                return false
            }
        }
    }
    const getCancelButtonDisableCondition = () => {
        if (JSON.stringify(accountFormDetails) !== JSON.stringify(customerDetail)) {
            return false
        } else {
            return true
        }
    }
    const blockInvalidChar = (e: any) =>
        [
            "+",
            "-",
            "{",
            "}",
            "[",
            "]",
            ";",
            ":",
            '"',
            "'",
            "|",
            "=",
            "_",
            "(",
            ")",
            "*",
            "&",
            "^",
            "%",
            "$",
            "#",
            "!",
            "~",
            "<",
            ">",
            "?",
            "/",
            " ",
        ].includes(e.key) && e.preventDefault();
    const handleCancel = () => {
        setAccountFormDetails(customerDetail)
        if (Props.flagRedirectedFromStripe) {
            Props.closePopUpFunc && Props.closePopUpFunc()
        }
    }
    return (
        <>
            <Grid container>
                <Grid item xs={12} className={styles.accDetailsMainItem}>
                    <Grid container >

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                    <Typography component={'span'} className={styles.accDetailsMainHead}>
                                        Personal Info
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ margin: "2px 0px" }}>
                                    <Typography component={'span'} className={styles.accDetailsSubHead}>
                                        Update your personal details.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={styles.accDetailsFormMainItem}>
                            <Grid container className={styles.accDetailsFormMainContainer}>
                                {/* FIRST NAME */}
                                <Grid item className={styles.accDetailsGlobalFieldsItem}>
                                    <Grid container>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailsGlobalFieldLabelItem}`}>
                                            <Typography component={'span'} className={styles.accDetailGlobalFieldsLabel}>
                                                First Name <Required />
                                            </Typography>
                                        </Grid>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailGlobalFieldsItem}`}>
                                            <GlobalTextField
                                                onKeyDown={blockInvalidChar}
                                                value={accountFormDetails.stripe_customer_first_name}
                                                onChange={(e) => { setAccountFormDetails({ ...accountFormDetails, stripe_customer_first_name: e.target.value }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* LAST NAME */}
                                <Grid item className={styles.accDetailsGlobalFieldsItem}>
                                    <Grid container>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailsGlobalFieldLabelItem}`}>
                                            <Typography component={'span'} className={styles.accDetailGlobalFieldsLabel}>
                                                Last Name <Required />
                                            </Typography>
                                        </Grid>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailGlobalFieldsItem}`}>
                                            <GlobalTextField
                                                onKeyDown={blockInvalidChar}
                                                value={accountFormDetails.stripe_customer_last_name}
                                                onChange={(e) => { setAccountFormDetails({ ...accountFormDetails, stripe_customer_last_name: e.target.value }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* EMAIL */}
                                <Grid item className={styles.accDetailsGlobalFieldsItem}>
                                    <Grid container>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailsGlobalFieldLabelItem}`}>
                                            <Typography component={'span'} className={styles.accDetailGlobalFieldsLabel}>
                                                Email <Required />
                                            </Typography>
                                        </Grid>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailGlobalFieldsItem}`}>
                                            <GlobalTextField
                                                value={accountFormDetails.stripe_customer_email}
                                                disabled={true}
                                                onChange={(e) => { setAccountFormDetails({ ...accountFormDetails, stripe_customer_email: e.target.value }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* CONTACT NUMBER */}
                                <Grid item className={styles.accDetailsGlobalFieldsItem}>
                                    <Grid container>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailsGlobalFieldLabelItem}`}>
                                            <Typography component={'span'} className={styles.accDetailGlobalFieldsLabel}>
                                                Phone Number <Required />
                                            </Typography>
                                        </Grid>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailGlobalFieldsItem} ${styles.phoneInputWrapper}`}>
                                            <PhoneInput country={"ae"} value={accountFormDetails.stripe_customer_phone == null ? "" : accountFormDetails.stripe_customer_phone}
                                                //  onChange={(e) => { setAccountFormDetails({ ...accountFormDetails, stripe_customer_phone: e }) }}
                                                onChange={(event, data) => { handlePhoneInputChange(event, data) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* ADDRESS-1 */}
                                <Grid item className={styles.accDetailsGlobalFieldsItem}>
                                    <Grid container>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailsGlobalFieldLabelItem}`}>
                                            <Typography component={'span'} className={styles.accDetailGlobalFieldsLabel}>
                                                Address 1 <Required />
                                            </Typography>
                                        </Grid>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailGlobalFieldsItem}`}>
                                            <GlobalTextField
                                                value={accountFormDetails.stripe_customer_add1}
                                                onChange={(e) => { setAccountFormDetails({ ...accountFormDetails, stripe_customer_add1: e.target.value }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* ADDRESS-2 */}
                                <Grid item className={styles.accDetailsGlobalFieldsItem}>
                                    <Grid container>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailsGlobalFieldLabelItem}`}>
                                            <Typography component={'span'} className={styles.accDetailGlobalFieldsLabel}>
                                                Address 2
                                            </Typography>
                                        </Grid>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailGlobalFieldsItem}`}>
                                            <GlobalTextField
                                                value={accountFormDetails.stripe_customer_add2 || ""}
                                                onChange={(e) => { setAccountFormDetails({ ...accountFormDetails, stripe_customer_add2: e.target.value }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* COUNTRY */}
                                <Grid item className={styles.accDetailsGlobalFieldsItem}>
                                    <Grid container>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailsGlobalFieldLabelItem}`}>
                                            <Typography component={'span'} className={styles.accDetailGlobalFieldsLabel}>
                                                Country <Required />
                                            </Typography>
                                        </Grid>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailGlobalFieldsItem}`}>
                                            <GlobalTextField
                                                value={accountFormDetails.stripe_customer_country}
                                                onChange={(e) => { setAccountFormDetails({ ...accountFormDetails, stripe_customer_country: e.target.value }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* STATE */}
                                <Grid item className={styles.accDetailsGlobalFieldsItem}>
                                    <Grid container>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailsGlobalFieldLabelItem}`}>
                                            <Typography component={'span'} className={styles.accDetailGlobalFieldsLabel}>
                                                State <Required />
                                            </Typography>
                                        </Grid>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailGlobalFieldsItem}`}>
                                            <GlobalTextField
                                                value={accountFormDetails.stripe_customer_state}
                                                onChange={(e) => { setAccountFormDetails({ ...accountFormDetails, stripe_customer_state: e.target.value }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* CITY */}
                                <Grid item className={styles.accDetailsGlobalFieldsItem}>
                                    <Grid container>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailsGlobalFieldLabelItem}`}>
                                            <Typography component={'span'} className={styles.accDetailGlobalFieldsLabel}>
                                                City <Required />
                                            </Typography>
                                        </Grid>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailGlobalFieldsItem}`}>
                                            <GlobalTextField
                                                value={accountFormDetails.stripe_customer_city}
                                                onChange={(e) => { setAccountFormDetails({ ...accountFormDetails, stripe_customer_city: e.target.value }) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* PINCODE */}
                                <Grid item className={styles.accDetailsGlobalFieldsItem}>
                                    <Grid container>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailsGlobalFieldLabelItem}`}>
                                            <Typography component={'span'} className={styles.accDetailGlobalFieldsLabel}>
                                                Pincode <Required />
                                            </Typography>
                                        </Grid>
                                        <Grid item className={`${styles.accDetailsFullWithStyle} ${styles.accDetailGlobalFieldsItem}`}>
                                            <GlobalTextField
                                                onKeyDown={blockInvalidChar}
                                                value={accountFormDetails.stripe_customer_postal_code}
                                                onChange={(e) => {
                                                    // Prevent non-numeric input using regex
                                                    const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                                    setAccountFormDetails({ ...accountFormDetails, stripe_customer_postal_code: numericValue });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* BUTTONS */}
                        <Grid item xs={12}>
                            <Grid container sx={{ justifyContent: "center", alignItems: "center", gap: "20px" }}>
                                <Grid item className={`${styles.accCancelBtnitem}`}>
                                    <GlobalButton flagFullWidth buttonText='Cancel' className="borderedButtonStyle" height="42px" onClick={handleCancel} disabled={getCancelButtonDisableCondition()} />
                                </Grid>
                                <Grid item className={`${styles.accSaveBtnitem}`}>
                                    <GlobalButton onClick={handleUpdateAccountDetails} disabled={getSaveButtonDisableCondition()} flagFullWidth buttonText='Save' className="primaryButtonStyle" height="42px" />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>

    )
}

export default AccountDetails;