import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection } from "lexical";
import React, { useEffect, useRef, useState } from "react";
import { getSelectedNode } from "../utils/getSelectedNode";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { DocContext, DocContextPayload } from "../../../context/docContext";
import { isEqual } from "lodash";
import { UserContext, UserContextPayload } from "../../../context/userContext";

export function MyCustomOnChangePlugin(props: any) {
    const { editorRef } = props
    const [editor] = useLexicalComposerContext();
    const [currentNode, setCurrentNode] = useState<any>();
    const [currentText, setCurrentText] = useState(null);
    const { currentActiveDocDetails, UpdateDocumentEditorDetail, documentEditorData, setDocumentEditorData, setCaretPositionCount, setFlagAutoFocus, flagAutoFocus } = React.useContext(DocContext) as DocContextPayload;
    const { setError, error } = React.useContext(UserContext) as UserContextPayload;
    const [editorCustomState, setEditorCustomState] = useState<any>();
    const [editorDebounceData] = useDebounce(editorCustomState, 2000);

    useEffect(() => {
        debounced()
    }, [editorDebounceData])

    const getEditorJson = () => {
        return editor.getEditorState().toJSON()
    }

    console.log("===>editor", getEditorJson())
    console.log("===>documentEditorData", documentEditorData)

    useEffect(() => {
        // debugger;
        if (documentEditorData) {
            editor?.update(() => {
                const updatedEditorState = editor.parseEditorState(documentEditorData)
                editor.setEditorState(updatedEditorState)
            });
        }
    }, [documentEditorData])

    const debounced = useDebouncedCallback(
        async () => {
            if (editorDebounceData) {
                let finalObj: any = {}
                finalObj['document_uuid'] = currentActiveDocDetails?.document_uuid
                finalObj['document_step_id'] = currentActiveDocDetails?.document_step_data?.filter((stepDetail) => stepDetail.step_type_name == "Editor")[0]?.document_step_id
                finalObj['document_lexical_editor_data'] = [editorDebounceData?.root]
                let response = await UpdateDocumentEditorDetail(finalObj)
                if (!response.status) {
                    setError({ ...error, flagShowError: true, message: "Something went wrong. Please try again!" })
                }
            }
        }, 1000
    );

    const RemoveBorder = (node: any) => {
        // Check if the node is an object
        if (typeof node === 'object' && node !== null) {
            // If the current node has a 'style' key, set its value to 123
            if (node.hasOwnProperty('style')) {
                if (node.style.indexOf('border') > -1) {
                    // debugger;
                    let styleArray = node.style.split(';')
                    let newArr = styleArray.filter((vv: any) => vv.indexOf('border:') == -1)
                    node.style = newArr.join(';') + ';';
                }
            }

            // Iterate through the properties of the node
            for (let key in node) {
                if (node.hasOwnProperty(key)) {
                    // Recursively set the style value for each property
                    RemoveBorder(node[key]);
                }
            }

        }
        return node
    }

    useEffect(() => {
        if (currentActiveDocDetails &&
            (currentActiveDocDetails?.template_type_name == "Single-Page" ||
                currentActiveDocDetails?.template_type_name == "Blank")) {
            if (!isEqual(documentEditorData, editorDebounceData)) {
                // debugger;
                setDocumentEditorData(editorDebounceData)
            }
        }
    }, [editorDebounceData])

    // useEffect(() => {
    //     if (editorRef.current !== null) {
    //         setCaretPositionCount(caretPosition)
    //     }
    // }, [editorRef])

    // Function to get the caret position

    const getCaretCurrentPosition = () => {
        const selection: any = window.getSelection();
        if (selection.rangeCount === 0) return -1; // No selection

        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(editorRef.current);
        preCaretRange.setEnd(range.endContainer, range.endOffset);

        const caretPosition = preCaretRange.toString().length;
        setCaretPositionCount(caretPosition)
        return caretPosition;
    };

    console.log("+++documentEditorData", documentEditorData)
    console.log("+++editorDebounceData", editorDebounceData)
    console.log(">>>>>>currentNode", currentNode)
    console.log(">>>>>>flagAutoFocus", flagAutoFocus)

    // console.log("eeee", editor.toJSON())

    // useEffect(() => {
    //     // if (!currentNode || !currentText) return;
    //     // editor?.getEditorState()?.read(() => {
    //     editor?.update(() => {
    //         // debugger;
    //         let updatedNode = getEditorJson()
    //         const updatedEditorState = editor.parseEditorState(updatedNode)
    //         editor.setEditorState(updatedEditorState)
    //     });
    //     // });
    // }, [editor]);

    function getCaretPosition(editableDiv: any) {
        const doc = document as any;
        var caretPos = 0,
            sel: any, range: any;
        if (window.getSelection) {
            sel = window.getSelection();
            if (sel.rangeCount) {
                range = sel.getRangeAt(0);
                if (range.commonAncestorContainer.parentNode == editableDiv) {
                    caretPos = range.endOffset;
                }
            }
        } else if (doc?.selection && doc.selection.createRange) {
            range = doc.selection.createRange();
            if (range.parentElement() == editableDiv) {
                var tempEl = doc.createElement("span");
                editableDiv.insertBefore(tempEl, editableDiv.firstChild);
                var tempRange = range.duplicate();
                tempRange.moveToElementText(tempEl);
                tempRange.setEndPoint("EndToEnd", range);
                caretPos = tempRange.text.length;
            }
        }
        return caretPos;
    }

    const onChangeLexicalEditor = (editorState: any) => {
        setFlagAutoFocus(true)
        //@ts-ignore
        console.log("getCaretPosition(this)", getCaretPosition(this))
        //@ts-ignore
        const caretPosition = getCaretCurrentPosition();
        console.log('Caret Position:', caretPosition);

        console.log("og editor", editor.toJSON())
        let update: any = getEditorJson()
        setEditorCustomState(update)

        // Read the contents of the EditorState here.
        editorState.read(() => {
            const selection: any = $getSelection();
            const node: any = getSelectedNode(selection);
            // let curreentNodeText = node
            setCurrentNode(node);
            setCurrentText(node?.getTextContent());
        });
    };

    return <OnChangePlugin onChange={onChangeLexicalEditor} />;
}