import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Grid, Snackbar, Alert, Box, } from "@mui/material";
import { useParams } from "react-router-dom";
import GlobalForm from "../../utility/formWiz/globalForm";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import globalStyles from "../../../assets/styles/global.module.scss";
import RoundLoader from "../../utility/globalLoader";
import GlobalRoundLoader from "../../utility/globalRoundLoader";
const TemplateFormPreview = (props) => {
    const userToken = useParams();
    const [widget_favicon_url, set_Widget_favicon_url] = useState("");
    const [widget_logo_url, set_Widget_logo_url] = useState("");
    const [submissionID, setsubmissionID] = useState(null);
    const [showForm, setShowForm] = useState(true);
    const [loaderFlag, setLoaderFlag] = useState(false);
    const [flagFirstRenderDone, setFlagFirstRenderDone] = useState(false)
    const [renderValue, setRenderValue] = useState({
        status: null,
        message: "",
        submission_id: null
    })
    const [flagPending, setFlagPending] = useState(false)
    const [googleIdFinal, setGoogleIdFinal] = useState([]);




    // const [debouncedInputValue] = useDebounce(props.formwizFieldsData, 2000);
    // setting the value in props.formwizFieldsData in 500 instead of 2000
    const [debouncedInputValue] = useDebounce(props.formwizFieldsData, 500);

    useEffect(() => {
        // setLoaderFlag(true)

        // GetWidgetDetailsAPI(
        //     userToken.token,
        //     (res) => {
        //         props.setFormwizFieldsData(res.data)
        //         if (res.data.status) {
        //             document.title = res.data.data.widget_name
        //             setShowForm(true)
        //             setLoaderFlag(false)
        //         }
        //         else {
        //             setShowForm(false)
        //             window.open('https://app.filemaster.io/login', '_self');
        //         }
        //     },
        //     (err) => {
        //         console.log("err", err);
        //     });
    }, [])

    useEffect(() => {
        let widget_favicon_url = "https://api.filemaster.io/api/storage/file?file_id=" + props.formwizFieldsData.data.widget_favicon_id +
            "&flag_info=false";
        set_Widget_favicon_url(widget_favicon_url)
        let widget_logo_url = "https://api.filemaster.io/api/storage/file?file_id=" + props.formwizFieldsData.data.widget_logo_id + "&flag_info=false";
        set_Widget_logo_url(widget_logo_url)
        if (flagFirstRenderDone)
            debounced()

    }, [props.formwizFieldsData])

    const debounced = useDebouncedCallback(
        () => {
            if (props.formwizFieldsData.data.widget_flag_auto_save)
                // here changing the 3000 timespan to 1500
                formSubmitFunction(props.formwizFieldsData)
        }, 1500);

    const handleCloseSnackBar = () => {
        setRenderValue({
            status: null,
            message: "",
            submission_id: null
        })
    }

    const setFieldValue = (data) => {
        // check here for disable condition of button
        var tempdata = { ...props.formwizFieldsData }
        tempdata.data.global_form_field.map((newvalue) => {

            if (newvalue.options) {
                newvalue.options.map((option_value) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
            if (newvalue.type == "file") {
                setGoogleIdFinal(newvalue.value)
            }
        })
        // checking for generatebuttonDisableCondition
        const globalFormFields = tempdata.data.global_form_field;
        let isAnyRequiredFieldEmpty = false;
        for (const field of globalFormFields) {
            if (field.required == true && (field.value === "" || field.value === null)) {
                isAnyRequiredFieldEmpty = true; // Found at least one empty required field
                break;
            }
        }
        // setFlagGenerateButtonDisabled(isAnyRequiredFieldEmpty);
        props.setFormwizFieldsData(tempdata)
        setFlagFirstRenderDone(true)
    }

    const formSubmitFunction = async (data) => {
        var finalData = { ...debouncedInputValue.data };

        if (data.submit_button || (props.formwizFieldsData.data.widget_flag_auto_save && !data.submit_button)) {

            delete finalData.timestamp
            delete finalData.token
            delete finalData.widget_domain
            delete finalData.widget_font_color
            delete finalData.widget_favicon_id
            delete finalData.widget_flag_auto_save
            delete finalData.widget_flag_hide_button
            delete finalData.widget_logo_align
            delete finalData.widget_logo_id
            delete finalData.widget_name
            delete finalData.widget_page
            delete finalData.doc_page_theme
            delete finalData.widget_logo_size
            delete finalData.widget_preview_color
            delete finalData.widget_sub_heading
            delete finalData.widget_type
            delete finalData.widget_title
        }
        if (data.submit_button) {
            finalData['widget_flag_submit'] = true
        }
        if (props.formwizFieldsData.data.widget_flag_auto_save && !data.submit_button) {
            finalData['widget_flag_submit'] = false
        }

    }

    var faviconId = props.formwizFieldsData.data.widget_favicon_id ? props.formwizFieldsData.data.widget_favicon_id : null;
    var logoId = props.formwizFieldsData.data.widget_logo_id ? props.formwizFieldsData.data.widget_logo_id : null;

    return (
        <>
            {/* {error.flagShowError && (
                <GlobalSnackbar
                    type="error"
                    message={
                        error.message == undefined
                            ? "Something went wrong, Please try again!"
                            : error.message
                    }
                    open={error.flagShowError}
                    handleClose={() => {
                        setError({ ...error, flagShowError: false });
                    }}
                />
            )} */}
            {loaderFlag ? <GlobalRoundLoader /> : ""}
            {showForm ?
                <>
                    <Grid item>
                        <Grid container direction="column" justifyContent={"start"} alignItems={"start"}>
                            <Grid item className={globalStyles.formWrapper} style={{ position: "relative", width: "100%", background: props.formwizFieldsData.data.widget_preview_color, borderRadius: "5px", marginTop: "37px", minHeight: "600px !important", maxHeight: "600px !important" }}>
                                {!(props.formwizFieldsData.data.global_form_field.length) &&
                                    <Grid item sx={{ width: "100%", position: "absolute", zIndex: "1", top: "50%", display: "flex", justifyContent: "center" }}>Add Fields to see Preview!</Grid>}
                                <GlobalForm
                                    customTemplate
                                    fields={props.formwizFieldsData.data.global_form_field}
                                    submitFunction={formSubmitFunction}
                                    checkFormSubmit={renderValue}
                                    handleCloseSnackBar={handleCloseSnackBar}
                                    pageThemeData={props.formwizFieldsData.data.doc_page_theme[0]}
                                    formData={props.formwizFieldsData.data}
                                    submissionID={submissionID}
                                    setFieldValue={setFieldValue}
                                    // getAIsuggestionList={(e) => { getAIsuggestionList(e) }}
                                    formEditable={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                : ""}
        </>
    );
}
export default TemplateFormPreview;