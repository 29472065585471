import { Dialog, Divider, Grid } from "@mui/material";
import GlobalButton from '../../utility/globalButton/button';
import deleteConfirm from "../../../assets/deleteConfirm.svg";
import globalStyles from "../../../assets/styles/global.module.scss";

export default function GlobalDeletePopup(props: any) {

    return (
        <div>
            <Dialog open={true}>
                <Grid container >
                    <Grid item className={globalStyles.deleteConfirmHeaderPopup}>
                        <Grid container className={globalStyles.globalDeleteLogoHeadContainer}>
                            <Grid item xs={1} className={globalStyles.deleteConfirmationLogoItem}>
                                <img src={deleteConfirm} className={globalStyles.deleteConfirmImage} />
                            </Grid>
                            <Grid item xs={9}>
                                <Grid container>
                                    <Grid item xs={12} className={globalStyles.deleteConfirmPopupTextStyle}>
                                        Delete Confirmation
                                    </Grid>
                                    <Grid item xs={12} className={globalStyles.deleteConfirmPopupSubTextStyle}>
                                        {props.deleteDescription}?
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={globalStyles.deleteConfirmButtonsPopup}>
                        <Grid container justifyContent="end">
                            <Grid item>
                                <GlobalButton buttonText='Cancel' flagFullWidth className='cancelButton'
                                    onClick={props.onClose} />
                            </Grid>
                            <Grid item style={{ marginLeft: "15px" }}>
                                <GlobalButton buttonText='Delete' flagFullWidth className='deleteButton'
                                    onClick={props.onDelete} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>

        </div>
    );
}
