import { Autocomplete, Button, Chip, Grid, TextField } from '@mui/material';
import React, { useContext } from 'react';
import styles from "../../../assets/styles/componentStyles/paraphrasing.module.scss";

import CloseIcon from '@mui/icons-material/Close';
import { Textarea } from '@mui/joy';
import Required from '../../utility/required';
import { ParaphraseContext, ParaphraseContextPayload } from '../../../context/paraphraseContext';

const CustomParaphrasingTab = () => {

    const { paraphrasingVersioningArr, setParaphraseVersioningarr, paraphrasingVersionIndex } = useContext(ParaphraseContext) as ParaphraseContextPayload;
    return (
        <>
            <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "10px" }}>
                <Grid container direction="column">
                    <Grid item className={styles.ppOptionsHeader}>How would you like this paraphrased?<Required /></Grid>
                    <Grid item>
                        <Textarea
                            className={" long_text_input formWiz-input-field"}
                            defaultValue={paraphrasingVersioningArr[paraphrasingVersionIndex].customPromptPayload.user_custom_prompt}
                            value={paraphrasingVersioningArr[paraphrasingVersionIndex].customPromptPayload.user_custom_prompt}
                            onChange={({ target: { value } }) => {
                                setParaphraseVersioningarr((prevState: any) =>
                                    prevState.map((item: any, index: any) =>
                                        index == paraphrasingVersionIndex ?
                                            { ...item, customPromptPayload: { ...item.customPromptPayload, user_custom_prompt: value } } : item
                                    ))
                            }}
                            placeholder="Specify your desired changes by considering the following details:

                                                                                                            Tone of voice (e.g., formal, casual), sentence length (e.g., short, long), context
                                                                                                            (e.g., explaining to a 5-year-old, technical overview), reading ease (e.g., simple,
                                                                                                            complex), format (e.g., bullet points, paragraph), depth (e.g., detailed, summary),
                                                                                                            writing style (e.g., conversational, academic).
                                                                                                            Example: 'Shift to a persuasive tone, ensuring that the content remains accessible
                                                                                                            by avoiding industry jargon and technical terms'.
                                                                                                            "
                            minRows={3}
                            maxRows={10}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CustomParaphrasingTab;