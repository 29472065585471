import "../assets/styles/pricing.css";
import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/system';
import PricingCard from './pricingCard';
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { CreditBanner } from "./utility/creditBanner";
import agent from "../services/api";
import { UserContext, UserContextPayload } from "../context/userContext";
import GlobalPopUp from "./utility/globalPopUp";
import AccountDetails from "./accountDetails";
import moment from "moment";
import { stripeCurrencyTypeObjModel } from "../models/axiosModel";
import globalStyles from "../assets/styles/global.module.scss";
import IconsColor from "./utility/iconsColor";
import GlobalButton from "./utility/globalButton/button";
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, isDev } from "../utils/constants";
import { useNavigate } from "react-router-dom";
const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                opacity: 1,
                border: 0
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5
            }
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff"
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600]
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3
        }
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500
        })
    }
}));
const Pricing = () => {
    const navigate = useNavigate()
    const { flagLoading, setFlagLoading, customerSubscriptionDetails, GetCustomerSubscriptionDetails, setError, error, setSuccess, success, stripeCurrencyTypeGenericList, customerDetail, setFlagShowCreditsExhausted, setUserDetail, userDetail } = useContext(UserContext) as UserContextPayload;
    const [subType, setSubType] = useState<any>("monthly");
    const [scrollEffect, setScrollEffect] = useState<any>(false);
    const [plusValue, setPlusValue] = useState<any>(0);
    const [premiumValue, setPremiumValue] = useState<any>(0);
    const [pricingDetails, setPricingDetails] = useState<any>([])
    const [pricingFeatureDetails, setPricingFeatureDetails] = useState<any>([])
    const [openAccountDetailPopup, setAccountDetailPopUp] = useState<boolean>(false)
    let defaultCurrencyTypeObj = stripeCurrencyTypeGenericList.filter((obj: stripeCurrencyTypeObjModel) => obj?.gc_stripe_currency_type_abbr == "usd")
    const [stripeCurrencyType, setStripeCurrencyType] = useState<stripeCurrencyTypeObjModel>(defaultCurrencyTypeObj[0])
    const [flagShowCurrencyTypePopup, setFlagShowCurrencyTypePopup] = useState(false)
    const [selectedPlanData, setSelectedPlanData] = useState<any>(null)
    useEffect(() => {
        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            await GetCustomerSubscriptionDetails()
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        })()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/stripe/get-stripe-pricing-detail`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                // console.log("json", jsonData);

                jsonData.data.map((response: any) => {
                    // console.log("response", response);
                    pricing(response)
                })
                pricingComparePlanDetails(jsonData)

            }
            catch (error) {
                console.log("error", error);
            }
        };

        // Call the fetchData function when the component mounts
        fetchData();
    }, []);

    const pricing = (ele: any) => {

        let montlyPlan: any;
        let yearlyPlan: any;

        const FeatureDataArr = ele.gc_stripe_product_feature_data.map((featureData: any) => {
            return featureData.stripe_product_feature_name;
        });

        const priceDataArr = ele.gc_stripe_product_price_data.map((ele: any) => {
            ele.stripe_product_price_flag_recurring_is_monthly ? montlyPlan =
                ele.gc_stripe_product_price_cutomized_data.map((priceData: any) => {
                    return {
                        value: priceData.stripe_product_price_customized_unit_amount,
                        label: priceData.stripe_product_price_customized_credit
                    }
                }) : yearlyPlan = ele.gc_stripe_product_price_cutomized_data.map((priceData: any) => {
                    return {
                        value: priceData.stripe_product_price_customized_unit_amount / 12,
                        label: priceData.stripe_product_price_customized_credit
                    }
                })
        })

        setPricingDetails((prevPricingDetails: any) => [
            ...prevPricingDetails,
            {
                plan_name: ele.stripe_product_name,
                gc_stripe_product_id: ele.gc_stripe_product_id,
                plan_desc: ele.stripe_product_description,
                feature_list: FeatureDataArr,
                monthly_plan: montlyPlan,
                yearly_plan: yearlyPlan,
                gc_stripe_product_price_data: ele.gc_stripe_product_price_data

            },
        ]);
    };

    const pricingComparePlanDetails = (jsonData: any) => {
        let featureData = jsonData.data.map((element: any) => {
            return {
                name: element.stripe_product_name,
                gc_stripe_product_id: element.gc_stripe_product_id,
                feature: element.gc_stripe_product_feature_data.map((el1: any) => {
                    return {
                        feature_name: el1.stripe_product_feature_name,
                        feature_description: el1.stripe_product_feature_description,
                    }
                })
            }
        })
        let allFeatureList = featureData.filter((plans: any) => {
            if (plans.name == "Premium") {
                return plans.feature
            }
        })
        let planAllFeatures = allFeatureList[0].feature
        // let featureObject = planAllFeatures.map((element) => {
        //     var find
        //     return {
        //         name: element.feature_name,
        //         subtitle: element.feature_description,
        //         plans: {
        //             free_plan: featureData.filter(ele => ele.name == "Free").filter(ele2 => ele2.feature_name == element.name),
        //             Starter_Plan: featureData.filter(ele => ele.name == "Starter").filter(ele2 => ele2.feature_name == element.name),
        //             Premium_Plan: featureData.filter(ele => ele.name == "Premium").filter(ele2 => ele2.feature_name == element.name),
        //         }
        //     }
        // })

        let featureObject = planAllFeatures.map((element: any) => {
            const checkFeaturePresence = (planName: any) => {
                const plan = featureData.find((ele: any) => ele.name === planName);
                const feature = plan && plan.feature.find((feat: any) => feat.feature_name === element.feature_name);
                if (feature) {
                    return feature.feature_description !== null ? feature.feature_description : true;
                }
                return false;
            };

            return {
                name: element.feature_name,
                subtitle: element.feature_description,
                plans: {
                    Free_Plan: checkFeaturePresence("Free"),
                    Starter_Plan: checkFeaturePresence("Starter"),
                    Premium_Plan: checkFeaturePresence("Premium")
                }
            }
        });

        featureObject.unshift(
            {
                name: 'Rate per month - monthly / user',
                subtitle: '',
                // subtitle: 'Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue',
                select: false,
                plans: {
                    Free_Plan: "0",
                    Starter_Plan: "$18",
                    Premium_Plan: "$37",
                }
            },
            {
                name: 'Rate per month - Annually / user',
                subtitle: '',
                // subtitle: 'Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue',
                select: false,
                plans: {
                    Free_Plan: "0",
                    Starter_Plan: "$12",
                    Premium_Plan: "$25",
                }
            },
            {
                name: 'Total Credits',
                subtitle: '',
                // subtitle: 'Lorem ipsum dolor sit amet consectetur. Id ullamcorper urna vitae augue',
                select: true,
                plans: {
                    Free_Plan: "100",
                    Starter_Plan: "1000",
                    Premium_Plan: "2000",
                }
            }
        )
        // setPricingFeatureDetails(featureObject);

        setPricingFeatureDetails(featureObject)
    }

    useEffect(() => {
        const changeStructure = () => {
            if (window.scrollY > 90) {
                setScrollEffect(true);
            } else {
                setScrollEffect(false);
            }
        };

        changeStructure();
        window.addEventListener("scroll", changeStructure);

        return () => {
            window.removeEventListener("scroll", changeStructure);
        };
    }, []);

    const handlePlusSliderChange = (newValue: any) => {
        setPlusValue(newValue);
    };

    const handlePremiumSliderChange = (newValue: any) => {
        setPremiumValue(newValue);
    };

    const calculateCredit = (value: any, planType: any) => {
        const increment = planType === 'plus' ? 100 : 200;
        return value * increment;
    };

    const getCustomerPortalLink = async () => {
        setFlagLoading((prevState) => {
            return { ...prevState, flagRoundLoader: true }
        });
        const response = await agent.getStripeCustomerPortalLink.get()
        if (response.status) {
            window.open(response.data[0].url, '_blank');
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }
    const cancelStripeSubscription = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        if (customerSubscriptionDetails && customerSubscriptionDetails.gc_stripe_customer_subscription_id) {
            const response = await agent.cancelStripeSubscription.delete(customerSubscriptionDetails?.gc_stripe_customer_subscription_id)
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            if (response.status) {
                if (response.message) {
                    // window.open(response.data.url, '_blank');
                    const formattedDate = moment(response.message.message.match(/\d{4}-\d{2}-\d{2}/)[0]).format('MMMM DD, YYYY');
                    setSuccess({ ...success, flagShowSuccess: true, message: `Subscription Canceled Successfully, but your previous payment subscription is valid till ${formattedDate}` })
                    await GetCustomerSubscriptionDetails()
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                }
            } else {
                setError({ ...error, flagShowError: true, message: response.data.message })
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }
        }
    }
    const handleUpdateCustomerSubscription = async (SelectedPlanData: any) => {
        setSelectedPlanData(SelectedPlanData)
        if ((customerDetail.stripe_customer_first_name == "" || customerDetail.stripe_customer_first_name == null) ||
            (customerDetail.stripe_customer_last_name == "" || customerDetail.stripe_customer_last_name == null) ||
            (customerDetail.stripe_customer_email == "" || customerDetail.stripe_customer_email == null) ||
            (customerDetail.stripe_customer_phone == "" || customerDetail.stripe_customer_phone == null) ||
            (customerDetail.stripe_customer_add1 == "" || customerDetail.stripe_customer_add1 == null) ||
            (customerDetail.stripe_customer_country == "" || customerDetail.stripe_customer_country == null) ||
            (customerDetail.stripe_customer_state == "" || customerDetail.stripe_customer_state == null) ||
            (customerDetail.stripe_customer_city == "" || customerDetail.stripe_customer_city == null) ||
            (customerDetail.stripe_customer_postal_code == "" || customerDetail.stripe_customer_postal_code == null)
        ) {
            setAccountDetailPopUp(true);
            setError({ ...error, flagShowError: true, message: "Before proceding with subscription, please first complete customer details!" })
        } else {
            setFlagShowCurrencyTypePopup(true)
        }
    }
    const handleAfterCurrencySelection = async () => {
        setFlagShowCurrencyTypePopup(false)
        if (selectedPlanData) {
            let temp = selectedPlanData.gc_stripe_product_price_data.find((timeFrameData: any) => timeFrameData.stripe_product_price_recurring_interval == (subType == "monthly" ? "month" : "year"))
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            if (customerSubscriptionDetails && customerSubscriptionDetails.gc_stripe_customer_subscription_id) {
                const payload = {
                    gc_stripe_product_price_id: temp.gc_stripe_product_price_id,
                    stripe_customer_subscription_num_of_user: customerSubscriptionDetails.stripe_customer_subscription_num_of_user,
                    gc_stripe_currency_type_id: stripeCurrencyType.gc_stripe_currency_type_id
                }
                const response = await agent.UpdateStripeSubscription.put(payload)
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                if (response.status) {
                    if (response.data[0].stripe_product_name == "Premium" || response.data[0].stripe_product_name == "Starter") {
                        window.open(response.url, '_blank');
                    }
                    await GetCustomerSubscriptionDetails()
                } else {
                    if (response.error.code == 9008) {
                        setAccountDetailPopUp(true)
                    }
                    setError({ ...error, flagShowError: true, message: response.error.message })
                }
            }
        }
    }

    return (
        <>
            {openAccountDetailPopup &&
                <GlobalPopUp
                    open={openAccountDetailPopup}
                    onClose={() => { setAccountDetailPopUp(false) }}
                    title={"Fill Your Account Details"}
                    flagBackdrop
                    flagShowCloseIcon={true}
                    handleClose={() => { setAccountDetailPopUp(false) }}
                    maxWidth="lg"
                >
                    <AccountDetails closePopUpFunc={() => { setAccountDetailPopUp(false) }} flagRedirectedFromStripe />
                </GlobalPopUp>
            }
            {flagShowCurrencyTypePopup &&
                <GlobalPopUp
                    open={flagShowCurrencyTypePopup}
                    onClose={() => { setFlagShowCurrencyTypePopup(false) }}
                    title={"Choose currency to pay in"}
                    // flagBackdrop
                    flagShowCloseIcon={false}
                    handleClose={() => { setFlagShowCurrencyTypePopup(false) }}
                    maxWidth="lg"
                    overflow="visible !important"
                >
                    <Grid container sx={{ padding: "30px", minWidth: "350px" }} justifyContent={"space-between"} alignItems={"start"}>
                        <Grid item sx={{ minWidth: "150px" }}>
                            <Autocomplete
                                className={globalStyles.autocompleteParaphrasing}
                                disablePortal
                                disableClearable
                                id="combo-box-demo"
                                getOptionLabel={(option: any) => option?.gc_stripe_currency_type_abbr?.toUpperCase() ?? ""}
                                options={stripeCurrencyTypeGenericList?.map((currency) => currency) ?? []}
                                value={stripeCurrencyType}
                                renderOption={(props, option, state) => (
                                    <li {...props}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginRight: "10px" }}>
                                                <IconsColor iconName={option.gc_stripe_currency_type_abbr} fillColor="#7A7A7A" width='15px' height='15px' />
                                            </div>
                                            {option.gc_stripe_currency_type_abbr.toUpperCase()}
                                        </div>
                                    </li>
                                )}
                                onChange={(event, values: any) => {
                                    setStripeCurrencyType(values);
                                }}
                                renderInput={(params) => <TextField
                                    {...params} placeholder='Select' />} />
                        </Grid>
                        <Grid item >
                            <GlobalButton
                                buttonText={"Continue"}
                                className="primaryButtonStyle"
                                onClick={handleAfterCurrencySelection}
                            />
                        </Grid>
                    </Grid>
                </GlobalPopUp>}
            <Grid container>
                <Grid item xs={12} className='pp-main-item'>
                    <Grid container>
                        {customerSubscriptionDetails !== null &&
                            <CreditBanner
                                getCustomerPortalLink={getCustomerPortalLink}
                                cancelStripeSubscription={cancelStripeSubscription}
                            />}
                        <Grid item xl={12} lg={12} md={12} sm={11} xs={12} className='pp-title-item'>
                            <Typography
                                component="span"
                                className='pp-main-title'
                            >
                                Change <Typography
                                    component="span"
                                    className='pp-main-color-title'
                                >Your Plan</Typography>
                            </Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='pp-monthly-annually-item'>
                            <Grid container className='pp-monthly-annually-container'>

                                <Box className="mask-box">
                                    <Box
                                        className="mask"
                                        // sx={{
                                        //     xl: { transform: `translateX(${subType === "monthly" ? 0 : "150px"})` },
                                        //     // xs: { transform: `translateX(${subType === "monthly" ? 0 : "115px"})` }
                                        // }}
                                        style={{
                                            transform: `translateX(${subType === "monthly" ? 0 : "150px"})`
                                        }}
                                    />
                                    <Button
                                        disableRipple
                                        variant="text"
                                        onClick={() => {
                                            setSubType("monthly")
                                        }}
                                        className={subType === 'monthly' ? 'selected-monthly-button' : ''}
                                    >
                                        Monthly
                                    </Button>
                                    <Button
                                        disableRipple
                                        variant="text"
                                        onClick={() => {
                                            setSubType("yearly")
                                        }}
                                        className={subType === 'yearly' ? 'selected-yearly-button' : ''}
                                    >
                                        Annual
                                    </Button>
                                </Box>
                                {/* <FormGroup>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography>Off</Typography>
                                        <IOSSwitch sx={{ m: 1 }} defaultChecked />
                                        <Typography>On</Typography>
                                    </Stack>
                                </FormGroup> */}


                            </Grid>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={11.5} xs={12} className='pp-plans-panel-main-item'>
                            {customerSubscriptionDetails !== null && customerSubscriptionDetails.gc_stripe_customer_subscription_transaction_data.length > 0 && customerSubscriptionDetails.gc_stripe_customer_subscription_transaction_data[0].subscription_transaction_payment_status !== "paid" &&
                                (new Date() < new Date(customerSubscriptionDetails.gc_stripe_customer_subscription_transaction_data[0].sub_transaction_checkout_session_url_expires_at_timestamp == null ? "" : customerSubscriptionDetails.gc_stripe_customer_subscription_transaction_data[0].sub_transaction_checkout_session_url_expires_at_timestamp)) &&
                                <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>Payment Status:  <a href={customerSubscriptionDetails.gc_stripe_customer_subscription_transaction_data[0].subscription_transaction_checkout_session_url == null ? "" : customerSubscriptionDetails.gc_stripe_customer_subscription_transaction_data[0].subscription_transaction_checkout_session_url} target="_blank">Pending</a></Box>}
                            <PricingCard
                                subType={subType}
                                plusValue={plusValue}
                                premiumValue={premiumValue}
                                onPlusSliderChange={handlePlusSliderChange}
                                onPremiumSliderChange={handlePremiumSliderChange}
                                calculateCredit={calculateCredit}
                                customerSubscriptionDetails={customerSubscriptionDetails}
                                pricingDetails={pricingDetails}
                                setPricingDetails={setPricingDetails}
                                handleUpdateCustomerSubscription={handleUpdateCustomerSubscription}
                                cancelStripeSubscription={cancelStripeSubscription}
                            />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Pricing