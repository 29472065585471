import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import styles from "../../../assets/styles/componentStyles/header.module.scss";
import GlobalButton from "../../utility/globalButton/button";
import { useNavigate } from "react-router-dom";
import { UserContext, UserContextPayload } from "../../../context/userContext";
import { DocContext, DocContextPayload } from "../../../context/docContext";
import agent from "../../../services/api";
import { Collapse, GlobalStyles, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Select, SelectChangeEvent, TextField, Tooltip } from "@mui/material";
import writeWizLogo from "../../../assets/writewizLogo.svg";
import globalStyles from "../../../assets/styles/global.module.scss"
import { NotifyExtentionContext } from "../../../context/notifyExtentionContext";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { Textarea } from "@mui/joy";
import { ParaphraseContext, ParaphraseContextPayload } from "../../../context/paraphraseContext";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconsColor from "../../utility/iconsColor";
import { TemplateContext, TemplateContextPayload } from "../../../context/templateContext";
import { PersonaContext, PersonaContextPayload } from "../../../context/personaContext";
import wIcon from './../../../assets/shortWritewizLogo.svg';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
interface menuItem {
  menuName: string;
  menuKey: string;
  navigationLink: string;
  imgSrc: React.ReactElement;
  whiteImgSrc: React.ReactElement;
  coloredImgSrc: React.ReactElement;
  flagDisabled?: boolean
}
interface Props {
  flagShowLogo: boolean,
  menuItems?: menuItem[]
}

export default function Header(props: Props) {

  // const ITEM_HEIGHT = 15;
  let urlToNavigateTo = encodeURIComponent(window.location.href)
  const navigate = useNavigate();
  const { userDetail, setFlagLoading, setUserDetail, creditUsed, setSuccess, success, setError, error, flagLoading } = React.useContext(
    UserContext
  ) as UserContextPayload;
  const { setParaphrasingVersionIndex, setCurrentIndex, setParaphraseVersioningarr, paraphrasingVersioningArr, paraphrasingVersionIndex, flagNeverShowup, setShowConfirmationPopup, handleGetResetBtnDisableFlag, currentTab, setCurrentTab, handleGetResetBtnRewordDisableFlag, handleResetWordChanger, rewordVersioningArr, rewordVersionIndex, setDrawerProps, popUpActionData } = React.useContext(ParaphraseContext) as ParaphraseContextPayload
  const { SendPostMessageToExtention } = React.useContext(
    NotifyExtentionContext
  ) as any;
  const { personaList } = React.useContext(PersonaContext) as PersonaContextPayload
  const { createdDoc, blogTitle, currentActiveDocDetails, UpdateDocInfo, setDocList, currentActiveStep, CreateDoc, setDocumentEditorData, UpdateDocumentEditorDetail } = React.useContext(
    DocContext
  ) as DocContextPayload;
  const { templateList } = React.useContext(TemplateContext) as TemplateContextPayload;
  const [docName, setDocName] = React.useState(currentActiveDocDetails?.document_name);
  const [pendingUpdate, setPendingUpdate] = React.useState<NodeJS.Timeout | null>(null);
  const getCurrentActiveMenuName = () => {
    if (window.location.pathname.toLowerCase() == "/dashboard/persona" || window.location.pathname.toLowerCase() == "/dashboard/viewpersona" || window.location.pathname.toLowerCase() == "/dashboard/createpersona" || window.location.pathname.toLowerCase() == "/dashboard/updatepersona") {
      return "Persona"
    } else if (window.location.pathname.toLowerCase() == "/dashboard/chatbot" || window.location.pathname.toLowerCase() == "/dashboard/createchatbot" || window.location.pathname.toLowerCase() == "/dashboard/chatbot/viewchatbot") {
      return "Chatbot"
    } else if (window.location.pathname.toLowerCase() == "/dashboard/campaigns" || window.location.pathname.toLowerCase() == "/dashboard/campaigns/create") {
      return "Campaign"
    }
    else if (window.location.pathname.toLowerCase() == "/dashboard/customtemplate" || window.location.pathname.toLowerCase() == "/dashboard/templates") {
      return "Templates"
    }
    else if (window.location.pathname.toLowerCase() == "/dashboard/documents") {
      return "Documents"
    }
    else if (window.location.pathname.toLowerCase() == "/dashboard/paraphraser") {
      return "Paraphraser"
    }
    else if (window.location.pathname.toLowerCase() == "/dashboard/settings-accountdetails" || window.location.pathname.toLowerCase() == "/dashboard/settings-plansandbilling") {
      return "Settings"
    }
    else if (window.location.pathname.toLowerCase() == "/dashboard/notes" || window.location.pathname.toLowerCase() == "/dashboard/notes/create") {
      return "Notes"
    }
    else {
      return "Home"
    }
  }
  const getCurrentActiveMenuOnclickFun = () => {
    if (window.location.pathname.toLowerCase() == "/dashboard/persona" || window.location.pathname.toLowerCase() == "/dashboard/viewPersona" || window.location.pathname.toLowerCase() == "/dashboard/createPersona" || window.location.pathname.toLowerCase() == "/dashboard/updatePersona") {
      return navigate("/dashboard/persona")
    } else if (window.location.pathname.toLowerCase() == "/dashboard/chatbot" || window.location.pathname.toLowerCase() == "/dashboard/createChatbot" || window.location.pathname.toLowerCase() == "/dashboard/chatbot/viewChatbot") {
      return navigate("/dashboard/chatbot")
    } else if (window.location.pathname.toLowerCase() == "/dashboard/campaigns" || window.location.pathname.toLowerCase() == "/dashboard/campaigns/create") {
      return navigate("/dashboard/campaigns")
    }
    else if (window.location.pathname.toLowerCase() == "/dashboard/customTemplate" || window.location.pathname.toLowerCase() == "/dashboard/templates") {
      return navigate("/dashboard/templates")
    }
    else if (window.location.pathname.toLowerCase() == "/dashboard/documents") {
      return navigate("/dashboard/documents")
    }
    else if (window.location.pathname.toLowerCase() == "/dashboard/paraphraser") {
      return navigate("/dashboard/paraphraser")
    }
    else if (window.location.pathname.toLowerCase() == "/dashboard/notes" || window.location.pathname.toLowerCase() == "/dashboard/notes/create") {
      return navigate("/dashboard/notes")
    }
    else if (window.location.pathname.toLowerCase() == "/dashboard/settings-accountDetails" || window.location.pathname.toLowerCase() == "/dashboard/settings-plansAndBilling") {
      return "Settings"
    }
    else {
      return navigate("/dashboard")
    }
  }
  React.useEffect(() => {
    setDocName(currentActiveDocDetails?.document_name)
  }, [currentActiveDocDetails])

  // updating doc name
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    setDocName(newName);

    // Schedule the update with a debounce
    if (pendingUpdate !== null) {
      clearTimeout(pendingUpdate);
    }
    const timeoutId = setTimeout(async () => {
      const payload = {
        document_uuid: currentActiveDocDetails == null ? "" : currentActiveDocDetails.document_uuid,
        document_name: newName !== '' ? newName : "Untitled Document",
      };
      const response = await UpdateDocInfo(payload);
      if (response.status) {
        // setSuccess({ ...success, flagShowSuccess: true, message: "Document Name updated successfully!" })
      } else {
        setError({ ...error, flagShowError: true, message: "Something went wrong, Please try again later!" })
      }
    }, 800); // Adjust the debounce delay as needed
    setPendingUpdate(timeoutId);
  };
  const handleKeyDown = (e: any) => {
    // Check if the pressed key is Enter and Shift is also pressed
    if (e.key === 'Enter') {
      // Prevent the default behavior (newline insertion)
      e.preventDefault();
    }
  };

  React.useEffect(() => {
    // Cleanup the pending update when the component unmounts
    return () => {
      if (pendingUpdate !== null) {
        clearTimeout(pendingUpdate);
      }
    };
  }, [pendingUpdate]);
  const handleReset = () => {
    setParaphrasingVersionIndex(0)
    setCurrentIndex(0)
    setParaphraseVersioningarr(
      [
        {
          id: 0,
          index: 0,
          currentSubTab: 'Basic',
          selectedLanguage: "Same as Input",
          selectedOutputCount: "3",
          paraphrasingInput: "",
          basicParaphrasingVal: {
            tone_of_voice: [],
            sentence_length: "Medium",
          },
          advanceParaphrasingVal: {
            tone_of_voice: [],
            narrative_style: "",
            work_domain: "",
            reading_ease: "",
            layout: "",
            sentence_length: "Medium",
            dialect: ""
          },
          personaPayload: {
            personaObj: "",
            persona_id: "",
            layout: "",
            sentence_length: "Medium",
          },
          customPromptPayload: {
            user_custom_prompt: "",
          },
          executePromptResponseParaphrase: []
        }
      ]
    )
  }

  const [openSection1Anchor, setOpenSection1Anchor] = React.useState<HTMLButtonElement | null>(null)
  const [openSection1, setOpenSection1] = React.useState<boolean>(false)
  const [headerListMenu, setHeaderListMenu] = React.useState<null | HTMLElement>(null);
  const [openList, setOpenList] = React.useState(true);

  // Boolean(openSection1Anchor);
  const open = Boolean(headerListMenu);

  const handleOpenSection1Menu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenSection1(true)
    setOpenSection1Anchor(event.currentTarget)
  }

  const handleCloseSection1Menu = () => {
    setOpenSection1(false)
    setOpenSection1Anchor(null)
  }

  const handleClickHeaderListMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHeaderListMenu(event.currentTarget);
  };

  const handleCloseHeaderListMenu = () => {
    setHeaderListMenu(null);
  };

  const handleClick = () => {
    setOpenList(!openList);
  };


  function contentLexicalObjFun(contentValue: string, indent: number = 0) {
    return {
      "children": [
        {
          "detail": 0,
          "format": 0,
          "mode": "normal",
          "style": "",
          "text": contentValue,
          "type": "text",
          "version": 1
        }
      ],
      "direction": "ltr",
      "format": "",
      "indent": 0,
      "type": "paragraph",
      "version": 1
    }
  }
  const handleCreateDoc = async (templateId: number) => {
    // setCurrentActiveDocFieldVal(null)
    // setAISuggestedTitles([
    //     { title: "" },
    //     { title: "" },
    //     { title: "" },
    //     { title: "" },
    //     { title: "" }
    // ])
    // setCurrentActiveStep(0)
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });
    const defaultPersona = personaList?.find((persona) => persona.user_default_persona_data.find((personaData) => personaData.default_persona_type_name == "General"));
    const personaId = defaultPersona?.persona_id ?? null;
    const payload = {
      template_id: templateId,
      persona_id: personaId
    }
    const response = await CreateDoc(payload)
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    if (response.status) {
      if (response.data) {
        let newChild: any = [];
        if (response.data[0]) {
          if (currentTab == "Paraphrase") {

            let checkedOptions = paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value)
            if (checkedOptions.some((option: any) => option.checked)) {

              checkedOptions.map((option: any) => option.checked == true &&
                newChild.push(contentLexicalObjFun(option.paraphrase))
              )
            } else {
              checkedOptions.map((option: any) => newChild.push(contentLexicalObjFun(option.paraphrase))
              )
            }
          } else {
            let checkedOptions = rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.map((option: any) => option.value[option.index])
            checkedOptions.map((option: any) =>
              newChild.push(contentLexicalObjFun(option.paraphrase))
            )
          }
          let lexicalDataArray: any = {
            root: {
              "children": [],
              "direction": "ltr",
              "format": "",
              "indent": 0,
              "type": "root",
              "version": 1
            }
          }
          lexicalDataArray['root']['children']?.push(...newChild)
          setDocumentEditorData(lexicalDataArray)

          let finalObj: any = {}
          finalObj['document_uuid'] = response.data[0].document_uuid
          finalObj['document_step_id'] = response.data[0]?.document_step_data?.filter((stepDetail) => stepDetail.step_type_name == "Editor")[0]?.document_step_id
          finalObj['document_lexical_editor_data'] = [lexicalDataArray?.root]
          // finalObj['document_lexical_editor_data'] = [documentEditorData?.root]
          let response1 = await UpdateDocumentEditorDetail(finalObj)
          if (!response1.status) {
            setError({ ...error, flagShowError: true, message: "Something went wrong. Please try again!" })
          } else {
            window.open(`${window.location.origin}/doc/${response.data[0].document_uuid}/3/${response.data[0].template_id}`, "_blank")
          }


        }
      }
    } else {
      setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
    }

  }
  const handleSaveDocument = async () => {
    let blankTemplateId = templateList !== null ? templateList[0].category_list.find((category: any) => category.category_name == "General")?.template_data_list.find((template) => template.template_type_name == "Blank")?.template_id : 0;
    handleCreateDoc(blankTemplateId ?? 0)
  }
  const [temp, setTemp] = React.useState(popUpActionData)
  React.useEffect(() => {
    setTemp(popUpActionData)
  }, [popUpActionData])
  return (
    <Box sx={{
      flexGrow: 1,
    }}
    >
      <AppBar
        component="nav"
        sx={{
          paddingLeft: { xs: "45px", sm: "0px" },
          position: { xs: "", sm: "static" },
          background: { xs: '#fbf4ff', sm: "#fff !important" },
          // boxShadow: "none",
          boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.25)"
        }}
      >
        <Toolbar className={styles.headerToolBar}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item className={styles.headerWrapper}>
              <Grid container sx={{ flexWrap: "nowrap" }}>
                {/* logo */}
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ color: "#6A097D" }}
                  >
                    {
                      props.flagShowLogo &&
                      <img src={writeWizLogo} style={{ width: "128px", height: "22px" }} className={`${globalStyles.clickable} ${styles.writewizLogo}`} onClick={() => { navigate("/dashboard") }} />
                    }
                  </Typography>
                </Grid>
                {/* doc name */}
                {currentActiveDocDetails !== null &&
                  <>
                    {currentActiveDocDetails.template_type_name == "Blog-Generation" ?
                      currentActiveDocDetails.document_name !== "Untitled Document" &&
                      <Grid item
                        className={styles.documentNameAreaContainer}
                      // className={styles.docNameTextfieldStyle}
                      >
                        {/* docName */}
                        <Tooltip placement="bottom-start" title="Document Name">
                          {/* <TextField
                            onChange={(e) => {
                              setDocName(e.target.value);
                            }}
                            variant="standard"
                            value={docName}
                            onBlur={() => {
                              const payload = {
                                document_uuid: currentActiveDocDetails?.document_uuid,
                                document_name: docName !== undefined ? docName : "Untitled Document"
                              }
                              UpdateDocInfo(payload)
                              console.log("onblur event called");
                            }}
                          /> */}
                          <Textarea
                            className={styles.documentNameArea}
                            // variant="standard"
                            value={docName}
                            onChange={(e: any) => { handleInputChange(e) }}
                            onKeyDown={handleKeyDown}
                          />
                        </Tooltip>
                      </Grid>
                      :
                      <Grid item className={styles.documentNameAreaContainer}>
                        {/* docName */}
                        <Tooltip placement="bottom-start" title="Document Name">
                          <Textarea
                            className={styles.documentNameArea}
                            // variant="standard"
                            value={docName}
                            onChange={(e: any) => { handleInputChange(e) }}
                            onKeyDown={handleKeyDown}
                          />
                        </Tooltip>
                      </Grid>
                    }
                  </>
                }
                {/* current active menu name */}
                {currentActiveDocDetails == null &&
                  <Grid item className={styles.documentNameAreaContainer}>
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                      <Grid item xs={6} sx={{ cursor: "pointer", display: { xs: "block", sm: "none" } }} onClick={getCurrentActiveMenuOnclickFun}>
                        {getCurrentActiveMenuName() == "Paraphraser" ?
                          <Select
                            sx={{ fontWeight: "600", fontSize: "15px" }}
                            id="demo-simple-select"
                            value={currentTab}
                            className={globalStyles.selectComponent}
                            onChange={(event: SelectChangeEvent) => {
                              setCurrentTab(event.target.value); setDrawerProps({
                                openDrawer: false,
                                dropdownOptions: [],
                                paraphrasingPayloadKeyValue: "",
                                optionKeyName: "",
                                paraphrasingTabKey: null,
                                optionImage: null,
                                isValMultiple: false,
                                dropdownName: ""
                              })
                            }}
                          >
                            <MenuItem value={'Paraphrase'}>Paraphrase</MenuItem>
                            <MenuItem value={'Word Changer'}>Word Changer</MenuItem>
                          </Select>
                          :
                          getCurrentActiveMenuName()
                        }
                      </Grid>
                      {/* paraphrase reset button */}
                      {currentTab == "Paraphrase" ?
                        getCurrentActiveMenuName() == "Paraphraser" && !handleGetResetBtnDisableFlag() &&
                        <Grid item sx={{ display: "flex" }}>
                          <Grid container >
                            {/* reset button */}
                            <Grid item sx={{ display: "flex" }}>
                              <Button
                                variant='text'
                                size='small'
                                fullWidth
                                sx={{
                                  color: "#6A097D",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  padding: "3px 5px",
                                }}
                                onClick={() => {
                                  if (userDetail.flagUser) {
                                    let currentPopupActionData = temp.find((popupActionData) => popupActionData.module_type_name.includes(currentTab));
                                    if (currentPopupActionData?.do_not_show_flag) {
                                      handleReset()
                                    } else {
                                      setShowConfirmationPopup(true)
                                    }
                                  } else {
                                    handleReset()
                                  }
                                }
                                }
                                // onClick={() => handleReset()} 
                                disabled={handleGetResetBtnDisableFlag()}
                              >Reset</Button>
                            </Grid>
                            {/* menu item */}
                            <Grid item sx={{ paddingRight: "10px" }}>
                              <GlobalButton
                                onClick={() => { handleSaveDocument(); }}
                                disabled={paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length == 0}
                                buttonText={<IconsColor fillColor="#6A097D" iconName="Save" width="15px" height="15x" />}
                                className="borderedButtonStyle"
                              />
                              {/* <IconButton
                                aria-label="more"
                                id="long-button"

                                aria-haspopup="true"
                                // onClick={handleClick}
                                sx={{ padding: "5px 16px 5px 0px", height: "100%" }}
                                onClick={(e) => {
                                  // setSelectedAgent(row)
                                  handleOpenSection1Menu(e)

                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                className={"paraphraseResetBtnItem"}
                                anchorEl={openSection1Anchor}
                                open={openSection1}
                                onClose={handleCloseSection1Menu}
                                PaperProps={{
                                  style: {
                                    // width: '20ch',
                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    padding: "0px 10px",
                                  }

                                }}

                              >
                                <MenuItem
                                  className={"paraphraseResetBtnItem"}
                                  style={{
                                    minHeight: '20px',
                                    padding: "5px 10px",
                                    textAlign: "center",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    borderBottom: "1px solid #E9E9E9"
                                  }}
                                  disabled={
                                    paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                  }
                                  onClick={() => { handleSaveDocument(); }}
                                >
                                  <Typography component={'span'} sx={{ display: "flex", alignItems: "center", }}>
                                    <IconsColor fillColor="#6A097D" iconName="Save" width="15px" height="15x" />&nbsp;
                                    Save Document</Typography>
                                </MenuItem>
                              </Menu> */}
                            </Grid>
                          </Grid>
                        </Grid>
                        :
                        getCurrentActiveMenuName() == "Paraphraser" && !handleGetResetBtnRewordDisableFlag() &&
                        <Grid item sx={{ display: "flex" }}>
                          <Grid container >
                            {/* reset button */}
                            <Grid item sx={{ display: "flex" }}>
                              <Button
                                variant='text'
                                size='small'
                                fullWidth
                                sx={{
                                  color: "#6A097D",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  padding: "3px 5px",
                                }}
                                onClick={() => {
                                  handleResetWordChanger()
                                }}
                                // onClick={() => handleReset()} 
                                disabled={handleGetResetBtnRewordDisableFlag()}
                              >Reset</Button>
                            </Grid>
                            {/* menu item */}
                            <Grid item sx={{ paddingRight: "10px" }}>
                              <GlobalButton
                                onClick={() => { handleSaveDocument(); }}
                                disabled={rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.length == 0}
                                buttonText={<IconsColor fillColor="#6A097D" iconName="Save" width="15px" height="15x" />}
                                className="borderedButtonStyle"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>

            <Grid item className={styles.creditWrapper}>
              <Grid container>
                {/* credit */}
                {!userDetail.flag_user_anonymous && creditUsed &&
                  <Grid item className={styles.creditWrapper}>
                    Credits : {creditUsed.remaining_credit_usage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/{creditUsed.total_credit_usage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Grid>
                  // <Grid item className={styles.creditWrapper}>Credits : {creditUsed.remaining_credit_usage}/{creditUsed.total_credit_usage}</Grid>}
                }

                {/* logout/signup buttons for above 650px */}
                <Grid item className={styles.headerButtonWrapperSM}>
                  {userDetail.flag_user_anonymous && (
                    <GlobalButton
                      className={"primaryButtonStyle"}
                      buttonText="Sign Up"
                      onClick={() => {
                        navigate("/login?redirect=" + urlToNavigateTo);
                      }}
                    />
                  )}


                  {/* logout button */}
                  <Grid item className={styles.exploreMenuGrid}>
                    <Button
                      id="basic-button"
                      className={open ? styles.exploreMenuButtonOpen : styles.exploreMenuButton}
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClickHeaderListMenu}
                    >
                      <Grid className={styles.moreVerticalIcon}><MoreVertIcon /></Grid>
                    </Button>

                    <Menu
                      id="basic-menu"
                      anchorEl={headerListMenu}
                      open={open}
                      className={styles.moreVerticalIconMenu}
                      onClose={handleCloseHeaderListMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >

                      <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={styles.menuItemList}
                      >

                        <ListItemButton className={`${styles.listItemButtonComponent} ${styles.emailListComponent}`}>
                          <ListItemIcon>
                            <AccountCircleOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary={userDetail.flag_user_anonymous ? "Guest User" : userDetail.user_email} />
                        </ListItemButton>

                        <ListItemButton onClick={handleClick} className={`${styles.listItemButtonComponent} ${styles.exploreMenuButtonWidth}`}>
                          <ListItemIcon>
                            <img src={wIcon} alt="W" height={"22"} width={"22"} />
                          </ListItemIcon>
                          <ListItemText primary="Explore" />
                          {openList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>

                        <Collapse in={openList} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding className={styles.listItemButtonComponent}>
                            {props.menuItems?.map((menuItem, index) => {
                              return (
                                <ListItemButton className={styles.subListMenuItem} key={index} onClick={() => navigate(menuItem.navigationLink)}>
                                  <ListItemIcon>
                                    {menuItem.imgSrc}
                                  </ListItemIcon>
                                  <ListItemText primary={menuItem.menuName} />
                                </ListItemButton>
                              )
                            })}

                          </List>
                        </Collapse>

                        {userDetail.flag_user_anonymous === false && (
                          <ListItemButton className={styles.listItemButtonComponent}
                            onClick={async () => {
                              setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
                              const response = await agent.LogoutAPI.post();
                              if (response.status) {
                                SendPostMessageToExtention(null)
                                setUserDetail({ ...userDetail, flagUser: false });
                                setDocList(null)
                                navigate("/login")
                              } else {
                                setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
                              }
                              setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                            }}
                          >
                            <ListItemIcon>
                              <PowerSettingsNewIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                          </ListItemButton>
                        )}

                      </List>
                    </Menu>
                  </Grid>
                </Grid>


                {/* logout/signup buttons for below 650px and above 599 */}
                <Grid item className={styles.headerButtonWrapperXS}>
                  {userDetail.flag_user_anonymous && (
                    <GlobalButton
                      className={"primaryButtonStyleIcon"}
                      buttonText={<AppRegistrationIcon />}
                      onClick={() => {
                        navigate("/login?redirect=" + urlToNavigateTo);
                      }}
                    />
                  )}

                  <Grid item className={styles.expandMoreGridMobile} mx={1}>
                    <Button
                      id="basic-button"
                      className={open ? styles.exploreMenuButtonOpen : styles.exploreMenuButton}
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClickHeaderListMenu}
                    >
                      <Grid className={styles.moreVerticalIcon}><MoreVertIcon /></Grid>
                    </Button>

                    <Menu
                      id="basic-menu"
                      anchorEl={headerListMenu}
                      open={open}
                      className={styles.moreVerticalIconMenu}
                      onClose={handleCloseHeaderListMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >

                      <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={styles.menuItemList}
                      >

                        <ListItemButton className={styles.listItemButtonComponent}>
                          <ListItemIcon>
                            <AccountCircleOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary={userDetail.flag_user_anonymous ? "Guest User" : userDetail.user_email} />
                        </ListItemButton>

                        <ListItemButton onClick={handleClick} className={`${styles.listItemButtonComponent} ${styles.exploreMenuButtonWidth}`}>
                          <ListItemIcon>
                            <img src={wIcon} alt="W" height={"22"} width={"22"} />
                          </ListItemIcon>
                          <ListItemText primary="Explore" />
                          {openList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>

                        <Collapse in={openList} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding className={styles.listItemButtonComponent}>
                            {props.menuItems?.map((menuItem, index) => {
                              return (
                                <ListItemButton className={styles.subListMenuItem} key={index} onClick={() => navigate(menuItem.navigationLink)}>
                                  <ListItemIcon>
                                    {menuItem.imgSrc}
                                  </ListItemIcon>
                                  <ListItemText primary={menuItem.menuName} />
                                </ListItemButton>
                              )
                            })}

                          </List>
                        </Collapse>

                        {userDetail.flag_user_anonymous === false && (
                          <ListItemButton className={styles.listItemButtonComponent}
                            onClick={async () => {
                              setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
                              const response = await agent.LogoutAPI.post();
                              if (response.status) {
                                SendPostMessageToExtention(null)
                                setUserDetail({ ...userDetail, flagUser: false });
                                setDocList(null)
                                navigate("/login")
                              } else {
                                setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
                              }
                              setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                            }}
                          >
                            <ListItemIcon>
                              <PowerSettingsNewIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                          </ListItemButton>
                        )}

                      </List>
                    </Menu>
                  </Grid>

                </Grid>

              </Grid>
            </Grid>




          </Grid>


        </Toolbar>
      </AppBar>
    </Box >
  );
}
