import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { emailRegex, SiteKey } from "../../utils/constants";
import styles from "../../assets/styles/componentStyles/login.module.scss";
import { Divider, IconButton, InputLabel, Box, Grid, Button, Typography } from "@mui/material";
import GlobalButton from "../utility/globalButton/button";
import { UserContext, UserContextPayload } from "../../context/userContext";
import globalStyles from "../../assets/styles/global.module.scss";
import GlobalTextField from "../utility/globalTextfield";
import writewizWhiteLogo from "../../assets/whiteWELogo.svg";
import googleIcon from "../../assets/google.svg";
import edit from "../../assets/edit.png";
import VerificationComponent from "../utility/verificationComponent";
import back from "../../assets/loginBackArrow.svg";
import agent from "../../services/api";
import updated from "../../assets/updated.svg";
import writewizLogo from "../../assets/writewizLogo.svg";
import 'animate.css';
import { NotifyExtentionContext } from "../../context/notifyExtentionContext";
import { useLocation } from 'react-router-dom';
import { getErrorMessage, isDev } from "../../utils/constants";
import { CommonResponseModel, CommonResponseSecondaryModel, RegisterDataResponseModel, SendOTPResponseDataModel } from "../../models/axiosModel";

const LogoComponent = () => (
  <img src={edit} alt="Logo" width="17px" height="17px" />
);
interface SuccessObj {
  flagShowSuccess: boolean;
  message: string;
}

declare global {
  interface Window {
    google: any;
  }
}


export default function Login() {
  // Inside your component
  const location = useLocation();
  let urlToNavigateTo = encodeURIComponent(window.location.href)
  const originalUrl = location.state?.from || '/'

  let navigate = useNavigate();

  const { SendLoginOTP, Login, setUserDetail, setFlagLoading, error, setError, VerifyAccount, UserMe, setCreditUsed, OneTapLoginAuthCallback, userDetail, setFlagOnboardingSkip, Register, SendAccountOTP, flagLoading, success, setSuccess } = useContext(
    UserContext
  ) as UserContextPayload;

  const { SendPostMessageToExtention } = React.useContext(
    NotifyExtentionContext
  ) as any;
  const [user, setUser] = useState({
    email: "",
    google_recaptcha_token: "",
  });

  const emptyErrorObj = {
    flagShowError: false,
    message: "",
  };
  const emptySuccessObj = {
    flagShowSuccess: false,
    message: "",
  };

  const [otp, setOtp] = useState("");
  const [flagInvalidOtp, setInvalidOtp] = useState(false);
  const [flagEmailValid, setFlagEmailValid] = useState(true);
  const [flagShowVerificationBlock, setFlagShowVerificationBlock] =
    useState(false);
  const [flagTimerActive, setFlagTimerActive] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  useEffect(() => {
    let timerId: any;
    if (flagTimerActive && seconds > 0) {
      timerId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      setFlagTimerActive(false);
    }
    return () => clearInterval(timerId);
  }, [flagTimerActive, seconds]);
  const handleSendOtp = async () => {
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
    const payload = {
      user_email: user.email,
      google_recaptcha_token: user.google_recaptcha_token,
      resend_flag: true
    };
    const response = await SendLoginOTP(payload);
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
    if (response.status) {
      if (response.data) {
        setSuccess({
          ...success,
          flagShowSuccess: response.status,
          message: getErrorMessage(response.data.code, isDev(userDetail)),
        });
        setInvalidOtp(false);
        setOtp("");
        handleResetTimer();
        handleStartTimer();
        setFlagShowVerificationBlock(true);
      }

    } else {
      recaptchaRef.current?.reset();
      setError({
        ...error,
        flagShowError: true,
        message: getErrorMessage(response?.error?.code, isDev(userDetail)),
      });
      setUser({ ...user, google_recaptcha_token: "" })
    }
    // }else{
    //   recaptchaRef.current?.reset();
    //   setError({...error,flagShowError:true,message:"Please write a valid Email Address!"})
    //   setUser({...user,google_recaptcha_token:""})
    // }
  };
  const sendAccountOtp = async () => {
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });
    const response = await SendAccountOTP();
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    if (response.status) {
      setInvalidOtp(false);
      setOtp("");
      handleResetTimer();
      handleStartTimer();
      setFlagShowVerificationBlock(true);
      if (response.data) {
        setSuccess({
          ...success,
          flagShowSuccess: response.status,
          message: getErrorMessage(response.data.code, isDev(userDetail)),
        });
      }
    } else {
      if (response.data) {
        setError({ ...error, flagShowError: true, message: getErrorMessage(response?.error?.code, isDev(userDetail)) })
      } else {
        setError({ ...error, flagShowError: true, message: getErrorMessage(response?.error?.code, isDev(userDetail)) })
      }
    }
  };
  const handleAuth = async () => {
    const payload = {
      user_email: user.email,
      google_recaptcha_token: user.google_recaptcha_token,
    }
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
    const response: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> = await Register(payload);
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
    if (response.status) {
      if (response.data && 'message' in response.data) {
        let message = response.data.message
        setSuccess((prevState) => {
          return { ...prevState, flagShowSuccess: true, message: message }
        })
        setInvalidOtp(false);
        setOtp("");
        handleResetTimer();
        handleStartTimer();
        setFlagShowVerificationBlock(true);
      }
      // // if the user is new
      // if (response?.data[0]?.user_status.user_status_name == "Registered") {
      //   if (response.data) {
      //     // setUserDetail({ ...response.data[0], flagUser: response.status });
      //     sendAccountOtp()
      //   }
      // } else {
      //   handleSendOtp()
      // }
    } else {
      recaptchaRef.current?.reset();
      setError({
        ...error,
        flagShowError: true,
        message: getErrorMessage(response?.error?.code, isDev(userDetail)),
      });
    }
  }

  const handleReSendOtp = async () => {
    // let flagvalidEmail = emailRegex.test(user.email)
    // if(flagvalidEmail){
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
    const payload = {
      user_email: user.email,
      google_recaptcha_token: user.google_recaptcha_token,
      resend_flag: true
    };
    const response = await SendLoginOTP(payload);
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
    if (response.status) {
      if (response.data) {
        setSuccess({
          ...success,
          flagShowSuccess: response.status,
          message: getErrorMessage(response.data.code, isDev(userDetail)),
        });
        setInvalidOtp(false);
        setOtp("");
        handleResetTimer();
        handleStartTimer();
        setFlagShowVerificationBlock(true);
      }
    } else {
      recaptchaRef.current?.reset();
      setError({
        ...error,
        flagShowError: true,
        message: getErrorMessage(response?.error?.code, isDev(userDetail)),
      });
      setUser({ ...user, google_recaptcha_token: "" })
    }
    // }else{
    //   recaptchaRef.current?.reset();
    //   setError({...error,flagShowError:true,message:"Please write a valid Email Address!"})
    //   setUser({...user,google_recaptcha_token:""})
    // }
  };
  const handleLogin = async (otp: any) => {
    setFlagLoading({ ...flagLoading, flagRoundLoader: true, flagContentGenerating: false });
    const payload = {
      user_email: user.email,
      authentication_code: otp,
    };
    const response = await Login(payload);
    setFlagLoading({ ...flagLoading, flagRoundLoader: false, flagContentGenerating: false });
    if (response.status) {
      if (response.data) {

        // const originalUrl = localStorage.getItem('originalUrl') || '/';
        // console.log("originalUrl-----", originalUrl)
        // // Remove the original URL from localStorage
        // localStorage.removeItem('originalUrl');

        // setUserDetail({ ...response.data[0], flagUser: response.status });
        // console.log("setting userdetail in chrome storage", response.data[0])
        // navigate(originalUrl);

        const responseOfUserMe = await UserMe();
        const searchParams = new URLSearchParams(window.location.search); let redirect = searchParams.get("redirect")
        if (redirect) {
          window.location.href = decodeURIComponent(redirect)
        } else {
          navigate("/dashboard");
        }
        // if (responseOfUserMe.data) {
        //   console.log("responseOfUserMe in main login page", responseOfUserMe);
        //   // ******************* FOR ACCESS AFTER 24 HRS CODE *****************************
        //   let userCreatedTime = userDetail.user_registered_timestamp;
        //   let creationTime = new Date(userCreatedTime as string);
        //   const timeDifference = new Date().getTime() - creationTime.getTime();
        //   const is24HoursPassed = timeDifference > 24 * 60 * 60 * 1000;
        //   // console.log("userCreatedTime", userCreatedTime);
        //   // console.log("is24HoursPassed", is24HoursPassed);
        //   if (is24HoursPassed) {
        //     setFlagOnboardingSkip(false);
        //   }
        //   else {
        //     setFlagOnboardingSkip(true);
        //   }
        //   // ******************* FOR ACCESS AFTER 24 HRS CODE *****************************
        // }

        let tempUserDetail = {
          flaguserAnonymous: response.data[0].flag_user_anonymous,
          userfirstName: response.data[0].user_first_name,
          userLastName: response.data[0].user_last_name,
          userFullName: ` ${response.data[0].user_first_name} ${response.data[0].user_last_name}`,
          userStatus: response.status
        }
        if (!response.data[0].flag_user_anonymous) {
          if (response.data[0]?.plan_detail.credit_usage_data !== null) {
            const temp = {
              remaining_credit_usage: response.data[0].plan_detail.credit_usage_data.remaining_credit_usage,
              total_credit_usage: response.data[0].plan_detail.credit_usage_data.total_credit_usage
            }
            setCreditUsed(temp);
          }
        }

        SendPostMessageToExtention(tempUserDetail)
        localStorage.setItem('writeWizUserDetail', JSON.stringify(tempUserDetail));
      }
    } else {
      setError({
        ...error,
        flagShowError: true,
        message: getErrorMessage(response?.error?.code, isDev(userDetail)),
      });
      recaptchaRef.current?.reset();
      setUser({ ...user, google_recaptcha_token: "" })
      setInvalidOtp(true);
    }
  };
  useEffect(() => {
    return () => {
      localStorage.removeItem('originalUrl');
    };
  }, []);
  const handleStartTimer = () => setFlagTimerActive(true);

  const handleStopTimer = () => setFlagTimerActive(false);

  const handleResetTimer = () => {
    setFlagTimerActive(false);
    setSeconds(60);
  };
  const handleFlagOtp = () => {
    setFlagShowVerificationBlock(!flagShowVerificationBlock);
    setOtp("");
  };
  const handleVerifyAccount = async (otp: any) => {
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });
    const payload = {

      authentication_code: otp,
    };
    const response = await VerifyAccount(payload);
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    if (response.status) {
      UserMe()
      // navigate("/home");
    } else {
      // if(response.data){}
      setError({ ...error, flagShowError: true, message: "Incorrect OTP! Please try with a valid OTP" })
      setInvalidOtp(true)
    }
  };
  const contWithGoogle = async () => {
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
    let currentUrl
    const searchParams = new URLSearchParams(window.location.search); let redirect = searchParams.get("redirect")
    if (redirect) {
      currentUrl = decodeURIComponent(redirect);
    } else {
      currentUrl = window.location.href.replace("login", "dashboard")
    }
    const response = await agent.contWithGoogle.get(currentUrl, 1);
    if (response.status) {
      if (response.data) {
        let url = response.data
        window.location.href = url;
      }
    } else {
      recaptchaRef.current?.reset();
      setError({
        ...error,
        flagShowError: true,
        message: getErrorMessage(response?.error?.code, isDev(userDetail)),
      });
    }
    setTimeout(() => {
      setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }, 500)
  };


  useEffect(() => {
    // Load the Google Sign-In client
    const script = document.createElement("script");
    script.src = "client.js";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      console.log("$$$$$$script.onload called")
      window.google.accounts.id.initialize({
        client_id:
          "361992322546-k97tfp20831fuavdabsl5tei4gc2g0a9.apps.googleusercontent.com",
        callback: handleCredentialResponse
      });
      console.log("$$$$$$script initialize")

      window.google.accounts.id.prompt((notification: any) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          console.log("try next provider if One Tap failed.");
        }
      });
    };

    return () => {
      // Cleanup script element
      document.head.removeChild(script);
    };
  }, []);

  const handleCredentialResponse = async (response: any, er: any) => {
    let apiResponse = await OneTapLoginAuthCallback(response)
    if (apiResponse?.status) {
      window.location.reload()
    }
  };

  return (

    <Grid container>
      <Grid item className={styles.rightBlockWrapper}>
        <Grid container className={styles.rightBlock} justifyContent={"center"} alignItems="center" direction="column">
          <Grid item>
            <img src={writewizWhiteLogo} />
          </Grid>
          <Grid item>
            {/* pic */}
            <img src={updated} style={{ margin: "50px 0px" }} />
          </Grid>
          <Grid item className={styles.italicText}>
            “AI Content Generator, Writing Assistant, & Chatbot”
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={styles.leftBlockWrapper} >
        <Grid container direction="column" className={styles.leftBlock}>
          <Grid item className={styles.writewizLogoWrapperSecond}>
            <img src={writewizLogo} className={styles.logoStyle} />
          </Grid>
          <Grid item sx={{ position: "position" }} className={globalStyles.authLabel}>Get started with Writewiz</Grid>
          {/* <Grid item sx={{ position: "position" }} className={globalStyles.authLabel}>Sign in/Sign up</Grid> */}
          {/* <Grid item className={globalStyles.authSublabelText}>Login your <span className={globalStyles.authSublabelProductTextStyle}>WriteWiz</span> account </Grid> */}
          {!flagShowVerificationBlock &&
            <>
              <Button onClick={contWithGoogle} className={globalStyles.continueWithGoogleButton}>
                <img src={googleIcon} />
                <div style={{ paddingLeft: "26px" }}>
                  Continue with Google
                </div>
              </Button>
              <Divider className={`${globalStyles.fullwidth} ${globalStyles.dividerStyle}`}>OR</Divider>
            </>
          }
          <Grid item>
          </Grid>

          <Box className={globalStyles.fullwidth} sx={{ mt: 1 }}>
            {flagShowVerificationBlock == false ?
              (<>
                <Grid container direction={"column"}>
                  {/* user email textfield */}
                  <InputLabel sx={{ fontWeight: "600", color: "#4C4A4D", fontSize: "15px", marginBottom: "10px" }}>Email</InputLabel>
                  <GlobalTextField
                    className={globalStyles.globalTextfield}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    autoComplete="email"
                    autoFocus
                    value={user.email}
                    onChange={(e) => {
                      setUser({ ...user, email: e.target.value });
                      setError(emptyErrorObj);
                      setFlagEmailValid(true)
                    }}
                    onBlur={() => {
                      user.email.length > 0 &&
                        setFlagEmailValid(emailRegex.test(user.email));
                    }}
                    error={!flagEmailValid} // Apply error styling if user_email is invalid
                    helperText={
                      !flagEmailValid && "Please enter a valid Email Address"
                    }
                  />
                  <Grid item>
                  </Grid>
                  {/* recaptcha block */}
                  <Grid item className={styles.recaptchaBlock}>
                    <ReCAPTCHA
                      className={styles.mainRecaptchaBlock}
                      ref={recaptchaRef}
                      sitekey={SiteKey}
                      onChange={(value: any) => {
                        setUser({ ...user, google_recaptcha_token: value });
                      }}
                    />
                  </Grid>
                  {/* cont button */}
                  <Grid item className={globalStyles.fullwidth}>
                    <GlobalButton
                      id="authButtonStyle"
                      flagFullWidth
                      buttonText="Continue"
                      disabled={
                        user.email !== "" && user.google_recaptcha_token !== "" && flagEmailValid
                          ? false
                          : true
                      }
                      className="authButtonStyle"
                      onClick={() => {
                        handleAuth();
                        // handleSendOtp();
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <Grid item className={globalStyles.authPromptTextStyleLogin}>
                  Don't have an account? <span onClick={() => {
                    navigate("/Register?redirect=" + urlToNavigateTo);
                  }} className={`${globalStyles.authPromptSubTextStyle} ${globalStyles.clickable}`}>Sign Up</span>
                </Grid> */}
              </>)
              :
              (
                <>
                  <Grid container>
                    <Grid item sx={{ marginBottom: "25px" }}>
                      <Grid container className={globalStyles.clickable} onClick={() => { setFlagShowVerificationBlock(false) }} alignItems={"center"} justifyContent={"center"} sx={{ position: "relative" }}>
                        <Grid item sx={{ position: "absolute", top: "10%", left: 0 }}><img src={back} /></Grid>
                        <Grid item><Typography sx={{ fontSize: "15px", fontWeight: "600", color: "#6A097D", marginLeft: "25px" }}>Back</Typography></Grid>
                      </Grid>
                    </Grid>
                    <Grid item className={`animate__animated animate__fadeInRight ${globalStyles.fullwidth}`}>
                      <VerificationComponent
                        value={user.email}
                        onChange={() => { setFlagShowVerificationBlock(!flagShowVerificationBlock) }}
                        disabled={flagShowVerificationBlock}
                        onClick={handleLogin}
                        handleResendOTP={handleReSendOtp}
                        handleStartTimer={handleStartTimer}
                        handleStopTimer={handleStopTimer}
                        handleLogin={handleLogin}
                        handleResetTimer={handleResetTimer}
                        seconds={seconds}
                        minutes={minutes}
                        remainingSeconds={remainingSeconds}
                        flagTimerActive={flagTimerActive}
                        InputProps={{
                          endAdornment: flagShowVerificationBlock && (
                            // <InputAdornment position="end">
                            <IconButton className={styles.editIconStyle} onClick={handleFlagOtp}>
                              {flagShowVerificationBlock ? (
                                <LogoComponent />
                              ) : (
                                ""
                              )}
                            </IconButton>
                            // </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                </>
              )
            }

            {/* <Grid container>
              <Grid item sx={{ marginTop: "10px" }}>
                <Link
                  onClick={() => {
                    navigate("/Register");
                  }}
                  className={globalStyles.clickable}
                  variant="body2"
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            {/* <Copyright sx={{ mt: 5 }} /> */}
          </Box>
          {/* <formwiz form */}
        </Grid>
      </Grid>


    </Grid>
    // <ThemeProvider theme={theme}>
    //     <Grid container component="main" sx={{ height: "100vh" }}>
    //       {success.flagShowSuccess && (
    //         <GlobalSnackbar
    //           type="success"
    //           message={success.message}
    //           open={success.flagShowSuccess}
    //           handleClose={() => {
    //             setSuccess({ ...success, flagShowSuccess: false });
    //           }}
    //         />
    //       )}
    //       {error.flagShowError && (
    //         <GlobalSnackbar
    //           type="error"
    //           message={
    //             error.message == undefined
    //               ? "Something went wrong, Please try again!"
    //               : error.message
    //           }
    //           open={error.flagShowError}
    //           handleClose={() => {
    //             setError({ ...error, flagShowError: false });
    //           }}
    //         />
    //       )}
    //       <CssBaseline />
    //       <Grid
    //         item
    //         xs={false}
    //         sm={4}
    //         md={7}
    //         sx={{
    //           backgroundImage: `url(${writeWiz})`,
    //           backgroundRepeat: "no-repeat",
    //           backgroundColor: (t) =>
    //             t.palette.mode === "light"
    //               ? t.palette.grey[50]
    //               : t.palette.grey[900],
    //           backgroundSize: "contain",
    //           backgroundPosition: "center",
    //         }}
    //       />

    //       <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
    //         <Box
    //           sx={{
    //             my: 8,
    //             mx: 4,
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "center",
    //           }}
    //         >
    //           <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
    //             <VpnKeyIcon />
    //           </Avatar>
    //           <Typography component="h1" variant="h5">
    //             Sign in
    //           </Typography>
    //           <Box sx={{ mt: 1 }}>
    //             {flagShowVerificationBlock == false ?
    //               (<>
    //                 <Grid container direction={"column"}>
    //                   {/* user email textfield */}
    //                   <Grid item>
    //                     <GlobalTextField
    //                       margin="normal"
    //                       required
    //                       fullWidth
    //                       id="email"
    //                       label="Email Address"
    //                       name="email"
    //                       autoComplete="email"
    //                       autoFocus
    //                       value={user.email}
    //                       onChange={(e) => {
    //                         setUser({ ...user, email: e.target.value });
    //                         setError(emptyErrorObj);
    //                         setFlagEmailValid(true)
    //                       }}
    //                       onBlur={() => {
    //                         setFlagEmailValid(emailRegex.test(user.email));
    //                       }}
    //                       error={!flagEmailValid} // Apply error styling if user_email is invalid
    //                       helperText={
    //                         !flagEmailValid && "Please enter a valid Email Address"
    //                       }
    //                     />
    //                   </Grid>
    //                   {/* recaptcha block */}
    //                   <Grid item sx={{ marginTop: "20px" }}>
    //                     <ReCAPTCHA
    //                       ref={recaptchaRef}
    //                       sitekey={SiteKey}
    //                       onChange={(value: any) => {
    //                         setUser({ ...user, google_recaptcha_token: value });
    //                       }}
    //                     />
    //                   </Grid>
    //                   {/* cont button */}
    //                   <Grid item sx={{ marginTop: "20px" }}>
    //                     <GlobalButton

    //                       buttonText="Continue"
    //                       disabled={
    //                         user.email !== "" && user.google_recaptcha_token !== "" && flagEmailValid
    //                           ? false
    //                           : true
    //                       }
    //                       className="primaryButtonStyle"
    //                       onClick={() => {
    //                         console.log("call login");
    //                         handleSendOtp();
    //                       }}
    //                     />
    //                   </Grid>
    //                 </Grid>
    //               </>)
    //               :
    //               (
    //                 <>
    //                   <Grid container direction="column">
    //                     <Grid item>
    //                       <InputLabel>
    //                         Verification Code (sent to {user.email})
    //                       </InputLabel>
    //                     </Grid>
    //                     {/* otp block */}
    //                     <Grid item sx={{ marginBottom: "10px" }}>

    //                       <OtpInput
    //                         // {...field}
    //                         value={otp}
    //                         // id="otp"
    //                         onChange={(value: any) => {
    //                           console.log("onSubmit  vaaa: ", value);
    //                           setOtp(value);
    //                           setInvalidOtp(false);
    //                         }}
    //                         isInputNum={true}
    //                         shouldAutoFocus={true}
    //                         numInputs={6}
    //                         inputStyle={
    //                           flagInvalidOtp
    //                             ? styles.otpInputError
    //                             : styles.inputOtpStyle
    //                         }
    //                       />
    //                     </Grid>

    //                     <Grid item>
    //                       <Grid container direction="column">
    //                         {/* login button */}
    //                         <Grid item>
    //                           <GlobalButton
    //                             buttonText="Login"
    //                             disabled={otp.length < 6 ? true : false}
    //                             className="primaryButtonStyle"
    //                             onClick={() => {
    //                               console.log("call login");
    //                               handleLogin();
    //                             }}
    //                           />
    //                         </Grid>

    //                         {/* timer or resend button */}
    //                         <Grid item sx={{ marginTop: "15px" }}>
    //                           <>
    //                             {(seconds > 0) || minutes > 0 ? (
    //                               // timer
    //                               <>
    //                                 <a
    //                                   style={{
    //                                     fontSize: "15px",
    //                                     color: "#000",
    //                                     fontWeight: "600",
    //                                   }}
    //                                 >
    //                                   {" "}
    //                                   Haven’t received code? Resend Code in{" "}
    //                                 </a>
    //                                 <a
    //                                   style={{
    //                                     fontSize: "14px",
    //                                     color: "rgba(30, 136, 229, 1)",
    //                                     marginRight: "20px",
    //                                   }}
    //                                 >
    //                                   {`${minutes}:${remainingSeconds < 10 ? "0" : ""
    //                                     }${remainingSeconds}`}
    //                                 </a>
    //                               </>
    //                             )
    //                               :
    //                               // resend button
    //                               (
    //                                 <GlobalButton
    //                                   buttonText="Resend"
    //                                   className="primaryButtonStyle"
    //                                   onClick={() => { console.log("call login"); handleSendOtp() }} />
    //                               )}
    //                           </>
    //                         </Grid>
    //                       </Grid>
    //                     </Grid>
    //                   </Grid>
    //                 </>
    //               )
    //             }

    //             <Grid container>
    //               <Grid item sx={{ marginTop: "10px" }}>
    //                 <Link
    //                   onClick={() => {
    //                     navigate("/Register");
    //                   }}
    //                   className={globalStyles.clickable}
    //                   variant="body2"
    //                 >
    //                   {"Don't have an account? Sign Up"}
    //                 </Link>
    //               </Grid>
    //             </Grid>
    //             {/* <Copyright sx={{ mt: 5 }} /> */}
    //           </Box>
    //         </Box>
    //       </Grid>
    //     </Grid>
    //   </ThemeProvider>
  );
}
