import logo from './logo.svg';
import './App.css';
import { Fragment } from 'react';
import Routes from './routes/routes';
import { UserProvider } from './context/userContext';
import { TemplateProvider } from './context/templateContext';
import { DocProvider } from './context/docContext';
import { PersonaProvider } from './context/personaContext';
import { NotifyExtentionProvider } from './context/notifyExtentionContext';
import { ChatbotProvider } from './context/chatbotContext';
import { CampaignProvider } from './context/campaignContext';
import { ParaphraseProvider } from './context/paraphraseContext';
import { GenericDataProvider } from './context/genericDataContext';
import { MetaProvider } from './context/metaContext';
import { NoteProvider } from './context/noteContext';
function App() {
  return (
    <Fragment>
      <NoteProvider>
        <MetaProvider>
          <GenericDataProvider>
            <ParaphraseProvider>
              <CampaignProvider>
                <ChatbotProvider>
                  <NotifyExtentionProvider>
                    <PersonaProvider>
                      <DocProvider>
                        <TemplateProvider>
                          <UserProvider>
                            <Routes />
                          </UserProvider>
                        </TemplateProvider>
                      </DocProvider>
                    </PersonaProvider>
                  </NotifyExtentionProvider>
                </ChatbotProvider>
              </CampaignProvider>
            </ParaphraseProvider>
          </GenericDataProvider>
        </MetaProvider>
      </NoteProvider>
    </Fragment>
  );
}

export default App;
