import { Autocomplete, Chip, Grid, TextField, Tooltip, Skeleton, Box, IconButton } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/paraphrasing.module.scss";
import { Typography } from '@mui/joy';
import GlobalTextField from '../../utility/globalTextfield';
import GlobalButton from '../../utility/globalButton/button';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { executePromptResponseParaphraseObj } from '../../../models/axiosModel';
import CopyToClipboard from "react-copy-to-clipboard";
import shine from "../../../assets/shine.png"
import { PersonaContext, PersonaContextPayload } from '../../../context/personaContext';
import personaPremiumIcon from "../../../assets/personaCrown.svg";
import personaAvatar from "../../../assets/personaAvatar.svg";
import { GlobalTooltip } from '../../utility/globalTooltip';
import IconsColor from '../../utility/iconsColor';
import closeArrow from "../../../assets/closeArrow.png";
import CloseIcon from '@mui/icons-material/Close';
import SplitPane from "react-split-pane";
import reWriteIcon from '../../../assets/RewriteMobileIcon.svg'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './paraphrasingCarousel.css'
import Carousel from "react-elastic-carousel";
import { useRef } from 'react';
import { ParaphraseContext, ParaphraseContextPayload } from '../../../context/paraphraseContext';
import CustomDrawer from './mobileDropdownDrawer';
import RoundLoader from '../../utility/globalLoader';
interface ParaphrasingMobileProps {
    handleClearText: () => void;
    handleTrySampleText: () => void;
    CustomPaper: any,
    mobileScreen: any
    handleChangeParaphraseInput: (e: any) => void
    handleParaphraseOutputChange: (e: any, flag: any) => void
    handleDecOutputOptionVersion: (option: executePromptResponseParaphraseObj) => void
    handleRewriteOutputOption: (optionToRewrite: executePromptResponseParaphraseObj) => void,
    copyText: any,
    setCopyText: any,
    handleCopyToClipboard: (textToCopy: any) => void,
    handleChangeCheckOutputOptionCard: (event: React.ChangeEvent<HTMLInputElement>, option: executePromptResponseParaphraseObj) => void,
    handleUseAsInput: (valueToReplace: string, option: any) => void,
    deleteOutputOptionCard: (optionToDelete: executePromptResponseParaphraseObj) => void,
    handleSaveDocument: () => void,
    handleIncOutputOptionVersion: (option: executePromptResponseParaphraseObj) => void,
    handleParaphrase: () => void
    handleOpenMenu: (event: React.ChangeEvent<HTMLInputElement>, option: executePromptResponseParaphraseObj) => void,
    handleCloseMenu: (event: React.ChangeEvent<HTMLInputElement>, option: executePromptResponseParaphraseObj) => void
    workDomainList: any;
    getParaphraseDisableFlag: () => boolean;
}

const WordChangerMobileComponent = (props: ParaphrasingMobileProps) => {
    const {
        handleClearText,
        handleTrySampleText,
        CustomPaper,
        mobileScreen,
        handleChangeParaphraseInput,
        handleParaphraseOutputChange,
        handleDecOutputOptionVersion,
        handleRewriteOutputOption,
        copyText,
        setCopyText,
        handleCopyToClipboard,
        handleChangeCheckOutputOptionCard,
        handleUseAsInput,
        deleteOutputOptionCard,
        handleSaveDocument,
        handleIncOutputOptionVersion,
        handleParaphrase,
        handleOpenMenu,
        handleCloseMenu,
        workDomainList,
        getParaphraseDisableFlag
    } = props;
    const { setRewordVersioningarr, rewordVersioningArr, rewordVersionIndex, rewordCurrentIndex, drawerProps, setDrawerProps, setrewordCurrentIndex } = useContext(ParaphraseContext) as ParaphraseContextPayload;
    const { flagLoading, userDetail } = useContext(UserContext) as UserContextPayload;
    // @ts-ignore
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [flagResizing, setFlagResizing] = useState(false)
    const [collapsed, setCollapsed] = useState(true)
    const { personaWritingSkills, personaList } = useContext(PersonaContext) as PersonaContextPayload;
    const [customPane1Style, setCustomPane1Style] = useState<any>({
        display: "block",
        height: `calc(100% - 95px)`
        // `${window.innerHeight - 90}px`
        , flex: "unset"
    })
    const getTabSectionHeight = (tabName: string) => {
        switch (tabName) {
            case "Basic":
                return 325;
                break;
            case "Advanced":
                return 535;
                break;
            case "Custom Prompt":
                return 570;
                break;
            case "Persona":
                return 420;
                break;

        }
    }
    const [isInputFocused, setIsInputFocused] = useState(false)
    const [carouselCurrentIndex, setCarouselCurrentIndex] = useState(0)
    useEffect(() => {
        handleClearText()
    }, [])
    const [customPane2Style, setCustomPane2Style] = useState<any>({
        background: "#f9f9f9",
        opacity: "1",
        height: `95px`, flex: "unset", overflow: "auto"
    })

    const preventDefault = (event: any) => {
        if (flagResizing) {
            event.preventDefault();
            event.stopPropagation();
        }
        // Prevent the default touchmove behavior during resizing
    };
    const handleResize = (event: number) => {
        getContainerHeight();
        setFlagResizing(true)
        setCustomPane1Style({ display: "block" })
        setCustomPane2Style({
            background: "#f9f9f9",
            opacity: "1",
            overflow: "auto",
        });
        if (event + 200 > window.innerHeight) {
            setCollapsed(true)
            setCustomPane2Style({
                ...customPane2Style, overflow: "hidden"
            });

        } else {
            setCollapsed(false)
        }
        if (event)
            (document.getElementsByClassName('Pane')[0] as HTMLElement).style.flex = 'unset';
        (document.getElementsByClassName('Pane')[0] as HTMLElement).style.minWidth = 'unset';
    }
    const handleDragStarted = () => {
        setFlagResizing(true)
        document.addEventListener('touchmove', preventDefault, { passive: false });
    };

    const handleDragFinished = () => {
        setFlagResizing(false)
        document.removeEventListener('touchmove', preventDefault);
    };

    useEffect(() => {
        // Attach the touchmove event listener after the initial render
        document.addEventListener('touchmove', preventDefault, { passive: false });

        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener('touchmove', preventDefault);
        };
    }, [flagResizing]);
    const handleCollapse = (flagCollapsed: boolean, flagCalledthroughParaphrase: boolean) => {
        let height = 0;
        if (rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.length > 0 || flagCalledthroughParaphrase) {
            var elements = document.getElementsByClassName('outputCardContainer');

            for (var i = 0; i < elements.length; i++) {
                // @ts-ignore
                height += elements[i].offsetHeight;
            }
            if (elements.length == 0) {
                height = 450
            } else {
                height = height + 70
            }
        } else {
            // @ts-ignore
            height = document.getElementById('test')?.offsetHeight + 100;
        }
        const container = document.getElementsByClassName('Pane horizontal Pane2')[0] as HTMLElement
        const outputContainer = document.getElementById('ppOutputField') as HTMLElement
        if (container) {
            container.scrollTop = 0;
        }
        if (outputContainer) {
            outputContainer.scrollTop = 0;
        }
        if (rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.length > 0) {
            if (flagCollapsed) {
                // this is opening drawer
                setCustomPane1Style({ ...customPane1Style, height: `${window.innerHeight - height}px`, flex: "unset" })
                setCustomPane2Style({ ...customPane2Style, height: `${height}px`, flex: "unset", overflow: "auto", })
            } else {
                // closing drawer
                setCustomPane1Style({ ...customPane1Style, height: `${window.innerHeight - 40}px`, flex: "unset" })
                setCustomPane2Style({ ...customPane2Style, height: `40px`, flex: "unset", overflow: "hidden" })
            }
        } else {
            if (flagCollapsed) {
                // this is opening drawer
                setCustomPane1Style({ ...customPane1Style, height: `${window.innerHeight - height}px`, flex: "unset" })
                setCustomPane2Style({ ...customPane2Style, height: `${height}px`, flex: "unset", overflow: "auto", })
            } else {
                // closing drawer
                setCustomPane1Style({ ...customPane1Style, height: `${window.innerHeight - 95}px`, flex: "unset" })
                setCustomPane2Style({ ...customPane2Style, height: `95px`, flex: "unset", overflow: "hidden" })
            }
        }
        setCollapsed(!flagCollapsed)
    }
    const changeHeightOfResizer = (tabName: string) => {
        let height = getTabSectionHeight(tabName);
        if (height) {
            setCustomPane1Style({ ...customPane1Style, height: `${window.innerHeight - height}px`, flex: "unset" })
            setCustomPane2Style({ ...customPane2Style, height: `${height}px`, flex: "unset", overflow: "auto", })
        }
    }
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [openSection1Anchor, setOpenSection1Anchor] = useState<HTMLButtonElement | null>(null)
    const [openSection1, setOpenSection1] = useState<boolean>(false)
    // Boolean(openSection1Anchor);

    const handleOpenSection1Menu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenSection1(true)
        setOpenSection1Anchor(event.currentTarget)
    }

    const handleCloseSection1Menu = () => {
        setOpenSection1(false)
        setOpenSection1Anchor(null)
    }

    const carouselRef = useRef<any>(null);

    const goToSlide = (slideIndex: number) => {
        // Assuming that 'goTo' is a method provided by the carousel library
        if (carouselRef.current) {
            carouselRef.current?.goTo(slideIndex);
        }
    };
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [searchValue, setSearchValue] = useState<any>('');
    const handleSetMobileDrawer = (dropdownOptions: any, paraphrasingPayloadKeyValue: string, optionKeyName: string, paraphrasingTabKey: string | null, isValMultiple: boolean, optionImage: string | null, dropdownName: string) => {
        setDrawerProps({
            ...drawerProps,
            openDrawer: true,
            dropdownOptions: dropdownOptions,
            paraphrasingPayloadKeyValue: paraphrasingPayloadKeyValue,
            optionKeyName: optionKeyName,
            paraphrasingTabKey: paraphrasingTabKey,
            isValMultiple: isValMultiple,
            optionImage: optionImage,
            dropdownName: dropdownName
        })
    }
    const staticNumList = [
        {
            no_of_output: "1"
        },
        {
            no_of_output: "2"
        },
        {
            no_of_output: "3"
        },
        {
            no_of_output: "4"
        }, {
            no_of_output: "5"
        }]
    const flagShowTooltip = (option: any, keyname: string) => {
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == rewordVersionIndex ?
                    {
                        ...item, executePromptResponseReword: rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.map((item: any) =>
                            item.id === option.id
                                ? {
                                    ...item,
                                    value: [
                                        ...item.value.map((val: any, index: any) =>
                                            index === option.index ? { ...val, [keyname]: true } : val
                                        ),
                                    ],
                                }
                                : item
                        )
                    } : item
            ));
        handleCopyToClipboard(option.value[option.index].paraphrase);
        setCopyText("Copied!");
        setTimeout(() => {
            setRewordVersioningarr((prevState: any) =>
                prevState.map((item: any, index: any) =>
                    index == rewordVersionIndex ?
                        {
                            ...item, executePromptResponseReword: rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.map((item: any) =>
                                item.id === option.id
                                    ? {
                                        ...item,
                                        value: [
                                            ...item.value.map((val: any, index: any) =>
                                                index === option.index ? { ...val, [keyname]: false } : val
                                            ),
                                        ],
                                    }
                                    : item
                            )
                        } : item
                ))
        }, 1000)
    }

    const handleDecVersioning = () => {
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == (rewordVersioningArr.length - 1) ?
                    prevState[(rewordCurrentIndex - 2)] : item
            ))
        setrewordCurrentIndex((prevState: any) => prevState - 1)
    }

    const handleIncVersion = () => {
        setRewordVersioningarr((prevState: any) =>
            prevState.map((item: any, index: any) =>
                index == (rewordVersioningArr.length - 1) ?
                    prevState[(rewordCurrentIndex)] : item
            ))
        setrewordCurrentIndex((prevState: any) => prevState + 1)
    }

    const handleOnChangeCarouselSlide = (currentItemObject: any, rewordCurrentIndex: number) => {
        setCarouselCurrentIndex(rewordCurrentIndex)
    };
    const getContainerHeight = () => {
        let height = 0;
        if (rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.length > 0) {
            var elements = document.getElementsByClassName('outputCardContainer');

            for (var i = 0; i < elements.length; i++) {
                // @ts-ignore
                height += elements[i].offsetHeight;
            }
        } else {
            // @ts-ignore
            height = document.getElementById('test')?.offsetHeight;
            // setCustomPane1Style({ ...customPane1Style, height: `${window.innerHeight - height}px`, flex: "unset" })
            // setCustomPane2Style({ ...customPane2Style, height: `${height}px`, flex: "unset", overflow: "auto", })
        }
    }


    return (
        <>
            <div style={{ maxHeight: window.innerHeight - 56, height: window.innerHeight - 56 }}>
                <>
                    <CustomDrawer
                        drawerOpen={drawerProps.openDrawer}
                        handleDrawerClose={() => {
                            setDrawerProps({ ...drawerProps, openDrawer: false }); setSearchValue("")
                            setTimeout(() => {
                                document.getElementById('readingEase')?.blur();
                                document.getElementById('workDomain')?.blur()
                            }, 200)
                        }}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        dropdownOptions={drawerProps.dropdownOptions}
                        paraphrasingVersioningArr={rewordVersioningArr}
                        paraphrasingVersionIndex={rewordVersionIndex}
                        optionKeyName={drawerProps.optionKeyName}
                        paraphrasingPayloadKeyValue={drawerProps.paraphrasingPayloadKeyValue}
                        isValMultiple={drawerProps.isValMultiple}
                        paraphrasingTabKey={drawerProps.paraphrasingTabKey}
                        optionImage={drawerProps.optionImage}
                        dropdownName={drawerProps.dropdownName}
                    />
                </>
                {/* @ts-ignore */}
                <SplitPane
                    className={styles.splitpaneStyle}
                    split="horizontal"
                    // defaultSize={"45%"}
                    minSize={150}
                    onChange={handleResize}
                    // maxSize={-100}
                    maxSize={window.innerHeight - 190}
                    resizerClassName={styles.paraphrasingResizer}
                    pane2Style={customPane2Style}
                    pane1Style={customPane1Style}
                    paneStyle={customPane1Style}
                    // onDragStarted={() => { setPaneMovingUpword(true) }}
                    onDragStarted={handleDragStarted}
                    onDragFinished={handleDragFinished}
                    onTouchStart={handleDragStarted}
                    onPointerDown={handleDragStarted}
                    onPointerUp={handleDragFinished}
                    onMouseDown={handleDragFinished}
                // onChange={handleResize}
                >

                    {/******* PANE 1 *******/}
                    <div style={{ height: "100%" }}>
                        {/* Input */}
                        <Box
                            // item
                            // xl={6} lg={6} md={12} sm={12} xs={12}
                            className={styles.ppInputContainer} >
                            <Grid container
                                // for mobile
                                sx={{ height: "100%" }}
                            >
                                <Grid item xs={12}
                                // sx={{ marginTop: "60px" }}
                                >
                                    <Box
                                        // for mobile
                                        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                                    >
                                        <Box >
                                            <Grid container >
                                                <Grid item xs={12} className={styles.mbppTopPartMainItem}>
                                                    <Grid container justifyContent={"space-between"}>
                                                        {rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.length > 0 ?
                                                            (
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <Grid container sx={{ justifyContent: "space-between" }}>
                                                                            <Grid item className={styles.inputTextStyle} sx={{ fontWeight: "600", display: "flex", alignItems: "center" }}>
                                                                                {carouselCurrentIndex === 0 ? (
                                                                                    <>
                                                                                        Input
                                                                                        <span style={{ fontSize: "11px", fontWeight: "500", color: "#7A7A7A", paddingLeft: "5px" }}>
                                                                                        </span>
                                                                                    </>
                                                                                ) : (
                                                                                    <>Output Preferences</>
                                                                                )}
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Grid container sx={{ gap: "6px" }}>
                                                                                    <Grid item>
                                                                                        <GlobalButton
                                                                                            buttonText="Reword"
                                                                                            flagFullWidth
                                                                                            height='35px !important'
                                                                                            className="primaryButtonStyle"
                                                                                            disabled={getParaphraseDisableFlag()}
                                                                                            onClick={async () => { handleCollapse(true, true); await handleParaphrase(); getContainerHeight(); }}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item sx={{ display: "flex" }}>
                                                                                        <IconButton
                                                                                            aria-label="more"
                                                                                            id="long-button"
                                                                                            aria-controls={open ? 'long-menu' : undefined}
                                                                                            aria-expanded={open ? 'true' : undefined}
                                                                                            aria-haspopup="true"
                                                                                            // onClick={handleClick}
                                                                                            sx={{ padding: "0px" }}
                                                                                            onClick={(e) => {
                                                                                                // setSelectedAgent(row)
                                                                                                handleOpenSection1Menu(e)

                                                                                            }}
                                                                                        >
                                                                                            <MoreVertIcon />
                                                                                        </IconButton>
                                                                                        <Menu
                                                                                            id="long-menu"
                                                                                            MenuListProps={{
                                                                                                'aria-labelledby': 'long-button',
                                                                                            }}
                                                                                            anchorEl={openSection1Anchor}
                                                                                            open={openSection1}
                                                                                            onClose={handleCloseSection1Menu}
                                                                                            PaperProps={{
                                                                                                style: {
                                                                                                    // maxHeight: ITEM_HEIGHT * 2,
                                                                                                    // width: '130px',
                                                                                                    // minWidth: "160px",
                                                                                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                                                                    padding: "0px 10px",

                                                                                                },
                                                                                            }}
                                                                                        >
                                                                                            {carouselCurrentIndex == 0 ?
                                                                                                <MenuItem
                                                                                                    onClick={() => { goToSlide(2); handleCloseSection1Menu(); }}
                                                                                                    style={{
                                                                                                        minHeight: '20px',
                                                                                                        padding: "5px 10px",
                                                                                                        textAlign: "center",
                                                                                                        justifyContent: "start",
                                                                                                        alignItems: "center",
                                                                                                        borderBottom: "1px solid #E9E9E9"
                                                                                                    }}
                                                                                                >
                                                                                                    <Typography component={'span'}>Output Preference</Typography>
                                                                                                </MenuItem>
                                                                                                :
                                                                                                <MenuItem
                                                                                                    onClick={() => { goToSlide(0); handleCloseSection1Menu(); }}
                                                                                                    style={{
                                                                                                        minHeight: '20px',
                                                                                                        padding: "5px 10px",
                                                                                                        textAlign: "center",
                                                                                                        justifyContent: "start",
                                                                                                        alignItems: "center",
                                                                                                        borderBottom: "1px solid #E9E9E9"
                                                                                                    }}
                                                                                                >
                                                                                                    <Typography component={'span'}>Input</Typography>
                                                                                                </MenuItem>
                                                                                            }
                                                                                            <MenuItem
                                                                                                onClick={() => {
                                                                                                    (rewordVersioningArr[rewordVersionIndex].rewordingInput.length > 0 ? handleClearText() : handleTrySampleText()); goToSlide(0); handleCloseSection1Menu();
                                                                                                }}
                                                                                                style={{
                                                                                                    minHeight: '20px',
                                                                                                    padding: "5px 10px",
                                                                                                    textAlign: "center",
                                                                                                    justifyContent: "start",
                                                                                                    alignItems: "center"
                                                                                                }}
                                                                                            >
                                                                                                <Typography component={'span'}>{rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 0 ? "Clear Input" : "Try Sample Text"}</Typography>
                                                                                            </MenuItem>

                                                                                        </Menu>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Grid item className={styles.inputTextStyle}>
                                                                        {carouselCurrentIndex === 0 ? (
                                                                            <>
                                                                                Input
                                                                                <span style={{ fontSize: "11px", fontWeight: "500", color: "#7A7A7A", paddingLeft: "5px" }}>
                                                                                    {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st !== '').length} W {rewordVersioningArr[rewordVersionIndex].rewordingInput.length} C
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>Output Preferences</>
                                                                        )}

                                                                    </Grid>
                                                                    {/* <Grid item className={styles.characterCountText}>Words: {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length} Characters: {rewordVersioningArr[rewordVersionIndex].rewordingInput.length}</Grid> */}
                                                                    <Grid item>
                                                                        <Grid container gap="7px">
                                                                            {isInputFocused && rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 0 &&
                                                                                <Grid item>
                                                                                    <GlobalButton
                                                                                        buttonText="Reword"
                                                                                        flagFullWidth
                                                                                        height='35px !important'
                                                                                        className="primaryButtonStyle"
                                                                                        disabled={getParaphraseDisableFlag()}
                                                                                        onClick={async () => { handleCollapse(true, true); await handleParaphrase(); getContainerHeight(); }}
                                                                                    // disabled={rewordingInput.length < 5}
                                                                                    />
                                                                                </Grid>}
                                                                            {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 0 && !isInputFocused &&
                                                                                <Grid item
                                                                                    onClick={() => {
                                                                                        const currentVersion = rewordVersioningArr[rewordVersionIndex];

                                                                                        if (currentVersion && currentVersion.rewordingInput.length > 0) {
                                                                                            setIsInputFocused(true);
                                                                                            handleClearText();

                                                                                        } else {
                                                                                            handleTrySampleText();
                                                                                        }
                                                                                    }}
                                                                                    className={`${styles.clearInputText} ${globalStyles.clickable}`}>
                                                                                    {"Clear Input"}
                                                                                </Grid>}
                                                                            {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length == 0 &&
                                                                                <Grid item
                                                                                    onClick={() => {
                                                                                        const currentVersion = rewordVersioningArr[rewordVersionIndex];

                                                                                        if (currentVersion && currentVersion.rewordingInput.length > 0) {
                                                                                            setIsInputFocused(true);
                                                                                            handleClearText();

                                                                                        } else {
                                                                                            handleTrySampleText();
                                                                                        }
                                                                                    }}
                                                                                    className={`${styles.clearInputText} ${globalStyles.clickable}`}>
                                                                                    {"Try Sample Text"}
                                                                                </Grid>}
                                                                        </Grid>
                                                                    </Grid>

                                                                </>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sx={{ padding: "0px 15px" }}>
                                                    <hr className={styles.mobileDividerStyle} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box
                                            // for mobile
                                            sx={{ height: "calc(100% - 50px)" }}
                                            className={styles.ppInputAndOptions}>
                                            <Box
                                                // for mobile
                                                sx={{ height: "100%" }}
                                            >
                                                {rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.length > 0 ? (
                                                    <>
                                                        {/* @ts-ignore */}
                                                        <Carousel
                                                            ref={carouselRef}
                                                            selectedItem={0}
                                                            showArrows={false} className='paraphrasingCarousel'
                                                            onChange={handleOnChangeCarouselSlide}
                                                        >
                                                            {/********  SECTION - 2 *********/}
                                                            <div className='styleDiv'>
                                                                <Box
                                                                    // for mobile
                                                                    sx={{ height: "100%", position: "relative" }}
                                                                    className={styles.ppInputField}
                                                                //  sx={{ position: "relative" }} 
                                                                >
                                                                    {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 900 &&
                                                                        <span
                                                                            style={{
                                                                                position: "absolute",
                                                                                bottom: "13px",
                                                                                color: "red",
                                                                                right: "17px",
                                                                                fontSize: "15px",
                                                                                zIndex: "1",
                                                                                background: "#fff"
                                                                            }}>
                                                                            {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length} / 1000 words
                                                                        </span>
                                                                    }
                                                                    {/* {rewordVersioningArr[rewordVersionIndex].flagInputEditable ? */}
                                                                    <GlobalTextField
                                                                        id="paraphraseInput"
                                                                        onFocus={(e) => { setIsInputFocused(true) }}
                                                                        value={rewordVersioningArr[rewordVersionIndex].rewordingInput}
                                                                        onBlur={() => { setTimeout(() => { setIsInputFocused(false) }, 100) }}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeParaphraseInput(e)}
                                                                        placeholder="For rewording your text, just type or paste the content here and press the 'Reword' button."
                                                                        // rows={6}
                                                                        multiline
                                                                        autoFocus={isInputFocused}
                                                                        className={`${styles.ppGlobalMultilineTextField}`}
                                                                    />
                                                                    {/* :
                                                                            <div
                                                                                onClick={() => { handleToggleInputEdit(true) }}
                                                                                style={{ padding: "14px" }}
                                                                                className={`${styles.ppGlobalMultilineTextField} ${globalStyles.clickable}`}>
                                                                                <ReactMarkdown
                                                                                    className={styles.reactMarkdownStyle}
                                                                                    // children={option.value[option.index].paraphrase} 
                                                                                    children={rewordVersioningArr[rewordVersionIndex].rewordingInput}
                                                                                />
                                                                            </div>
                                                                        } */}
                                                                </Box>
                                                                {/* <Grid item className={styles.wordCounterMobileContainer}>
                                                                    <Grid container className={styles.ppWbWordAndCharMainContainer}>
                                                                        <Grid item className={styles.wordCounterMobile}>
                                                                            {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length} Words
                                                                        </Grid>
                                                                        <Grid item className={styles.wordCounterMobile}>
                                                                            {rewordVersioningArr[rewordVersionIndex].rewordingInput.length} Characters
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid> */}
                                                            </div>

                                                            {/********  SECTION - 2 *********/}
                                                            <div className='styleDiv'>
                                                                <Grid item xs={12} sx={{ height: "100%", overflowY: "scroll", }}>
                                                                    <Grid container>
                                                                        {/* lower tabs */}
                                                                        <Grid item xs={12} className={`${styles.ppMbInputFieldOptions}`} >
                                                                            <Grid container direction="column" sx={{ flexWrap: "nowrap", maxHeight: "100%" }}>
                                                                                <Grid item className={styles.preferencesTextStyle}>Set your output preferences</Grid>
                                                                                <Grid item xs={12} className={styles.inputOptions}>
                                                                                    <Grid container gap={"0.2rem"} className={styles.inputOptionsChild}>
                                                                                        <>
                                                                                            {/* Advance tab */}

                                                                                            {/* tone of voice */}
                                                                                            <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "15px" }}>
                                                                                                <Grid container direction="column">
                                                                                                    <Grid item className={styles.ppOptionsHeader}>Persona</Grid>
                                                                                                    <Grid item>
                                                                                                        <Autocomplete
                                                                                                            options={personaList || []}
                                                                                                            getOptionLabel={(option: any) => option.persona_name ? `${option.persona_name}, ${option.persona_display_name}` : ""}
                                                                                                            filterOptions={(options, { inputValue }) => {
                                                                                                                const filteredOptions = options.filter((option) => {
                                                                                                                    const personaName = option.persona_name || '';
                                                                                                                    const subCategoryName = option.persona_sub_category__name || '';

                                                                                                                    return (
                                                                                                                        personaName.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                                                                                        subCategoryName.toLowerCase().includes(inputValue.toLowerCase())
                                                                                                                    );
                                                                                                                });

                                                                                                                return filteredOptions;
                                                                                                            }}
                                                                                                            className={styles.autocompleteParaphrasing}
                                                                                                            getOptionDisabled={(option) =>
                                                                                                                option.persona_premium_flag && ((userDetail.plan_detail && userDetail.plan_detail.credit_usage_data !== null && userDetail?.plan_detail?.credit_usage_data.stripe_product_name == "Free") || userDetail.flag_user_anonymous)
                                                                                                            }
                                                                                                            ChipProps={{ deleteIcon: <CloseIcon />, style: { maxWidth: '100%' }, }}
                                                                                                            groupBy={(option) => option.persona_sub_category__name}
                                                                                                            renderOption={(props, option) => (
                                                                                                                <li {...props}>
                                                                                                                    <GlobalTooltip
                                                                                                                        option={option}
                                                                                                                    >
                                                                                                                        <li {...props}
                                                                                                                            style={{
                                                                                                                                paddingLeft: "0px",
                                                                                                                                paddingRight: "0px",
                                                                                                                                display: "flex",
                                                                                                                                alignItems: "center",
                                                                                                                                fontSize: "15px",
                                                                                                                                fontWeight: "500",
                                                                                                                                width: "100%"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <div
                                                                                                                                style={{ pointerEvents: "none", display: "flex" }}>
                                                                                                                                <img style={{ width: "37px", height: "37px", borderRadius: "50%", marginRight: "10px" }} src={option.persona_avatar_url ? option.persona_avatar_url : personaAvatar} alt="personaAvatar" />
                                                                                                                            </div>

                                                                                                                            <div style={{ width: "100%", pointerEvents: "none", padding: "0px 5px" }}>
                                                                                                                                {option.persona_display_name ? `${option.persona_name}, ${option.persona_display_name}` : option.persona_name}
                                                                                                                            </div>

                                                                                                                            {option.persona_premium_flag &&
                                                                                                                                <div style={{ width: "100%", display: "flex", justifyContent: "end", pointerEvents: "none", maxWidth: "25px" }}>
                                                                                                                                    <img src={personaPremiumIcon} style={{ width: "25px", height: "auto" }} alt="personaPremiumIcon" />
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        </li>
                                                                                                                    </GlobalTooltip>
                                                                                                                </li>
                                                                                                            )}
                                                                                                            value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.personaObj}
                                                                                                            onChange={(_, newValue) => {
                                                                                                                setRewordVersioningarr((prevState: any) =>
                                                                                                                    prevState.map((item: any, index: any) =>
                                                                                                                        index == rewordVersionIndex ?
                                                                                                                            { ...item, wordChangerPayload: { ...item.wordChangerPayload, personaObj: newValue == null ? "" : newValue, persona_id: newValue == null ? "" : newValue.persona_id } } : item
                                                                                                                    ))
                                                                                                            }}
                                                                                                            renderInput={(params) => (
                                                                                                                <>
                                                                                                                    <TextField
                                                                                                                        placeholder='Select Persona'
                                                                                                                        {...params}

                                                                                                                    />
                                                                                                                </>
                                                                                                            )}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            {/* writing style and work domain */}
                                                                                            <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "15px" }}>
                                                                                                <Grid container sx={{ gap: "5px", justifyContent: "space-between" }}>
                                                                                                    <Grid item xs={5.85}>
                                                                                                        <Grid container direction="column">
                                                                                                            <Grid item className={styles.ppOptionsHeader}>Reading Ease</Grid>
                                                                                                            <Grid item>
                                                                                                                <Autocomplete
                                                                                                                    className={styles.autocompleteParaphrasing}
                                                                                                                    disablePortal
                                                                                                                    id="readingEase"
                                                                                                                    onOpen={() => { handleSetMobileDrawer(personaWritingSkills, "reading_ease", "persona_reading_level_name", "wordChangerPayload", false, null, "Reading ease") }}
                                                                                                                    options={personaWritingSkills !== null ? personaWritingSkills.map((writingSkill: any) => writingSkill.persona_reading_level_name) : []}
                                                                                                                    value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.reading_ease}
                                                                                                                    onChange={(event, values: any) => {
                                                                                                                        setRewordVersioningarr((prevState: any) =>
                                                                                                                            prevState.map((item: any, index: any) =>
                                                                                                                                index == rewordVersionIndex ?
                                                                                                                                    { ...item, wordChangerPayload: { ...item.wordChangerPayload, reading_ease: values } } : item
                                                                                                                            ))
                                                                                                                    }}
                                                                                                                    renderOption={(props, option, state) => (
                                                                                                                        <li {...props}>
                                                                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                                                <img src={workDomainList.find((workDomain: any) => workDomain.work_domain_name == option)?.work_domain_icon_url ?? ""} alt="icon" style={{ width: '17px', height: '17px', marginRight: '8px' }} />
                                                                                                                                {option}
                                                                                                                            </div>
                                                                                                                        </li>
                                                                                                                    )}
                                                                                                                    renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={5.85}>
                                                                                                        <Grid container direction="column">
                                                                                                            <Grid item className={styles.ppOptionsHeader}>Work Domain</Grid>
                                                                                                            <Grid item>
                                                                                                                <Autocomplete
                                                                                                                    className={styles.autocompleteParaphrasing}
                                                                                                                    disablePortal
                                                                                                                    id="workDomain"
                                                                                                                    onOpen={() => { handleSetMobileDrawer(workDomainList, "work_domain", "work_domain_name", "wordChangerPayload", false, "work_domain_icon_url", "Work Domain") }}
                                                                                                                    options={workDomainList.map((workDomain: any) => workDomain.work_domain_name)}
                                                                                                                    value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.work_domain}
                                                                                                                    onChange={(event, values: any) => {
                                                                                                                        setRewordVersioningarr((prevState: any) =>
                                                                                                                            prevState.map((item: any, index: any) =>
                                                                                                                                index == rewordVersionIndex ?
                                                                                                                                    { ...item, wordChangerPayload: { ...item.wordChangerPayload, work_domain: values } } : item
                                                                                                                            ))
                                                                                                                    }}
                                                                                                                    renderOption={(props, option, state) => (
                                                                                                                        <li {...props}>
                                                                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                                                <img src={workDomainList.find((workDomain: any) => workDomain.work_domain_name == option)?.work_domain_icon_url ?? ""} alt="icon" style={{ width: '17px', height: '17px', marginRight: '8px' }} />
                                                                                                                                {option}
                                                                                                                            </div>
                                                                                                                        </li>
                                                                                                                    )}
                                                                                                                    PaperComponent={CustomPaper}
                                                                                                                    renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            {/* reading ease and format */}
                                                                                            <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "15px" }}>
                                                                                                <Grid container sx={{ gap: "5px", justifyContent: "space-between" }}>
                                                                                                    <Grid xs={5.85} className={styles.inputOptionsCont} sx={{ mb: "15px", display: "flex" }}>
                                                                                                        <Grid container justifyContent={"space-between"} direction="column">
                                                                                                            <Grid item className={styles.ppOptionsHeader}>
                                                                                                                Words/ Phrases to Include
                                                                                                                {/* {tonOfVoice[0].name} */}
                                                                                                            </Grid>
                                                                                                            <Grid item>
                                                                                                                <Autocomplete
                                                                                                                    multiple
                                                                                                                    // className={" multiple_chips__autocomplete formWiz-input-field"}
                                                                                                                    className={styles.autocompleteParaphrasing}
                                                                                                                    disablePortal
                                                                                                                    id="combo-box-demo"
                                                                                                                    freeSolo
                                                                                                                    options={[]}
                                                                                                                    value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.words_to_include}
                                                                                                                    onChange={(event, values: any) => {
                                                                                                                        setRewordVersioningarr((prevState: any) =>
                                                                                                                            prevState.map((item: any, index: any) =>
                                                                                                                                index == rewordVersionIndex ?
                                                                                                                                    { ...item, wordChangerPayload: { ...item.wordChangerPayload, words_to_include: values } } : item
                                                                                                                            ))
                                                                                                                    }}
                                                                                                                    renderTags={(value, getTagProps) =>
                                                                                                                        value.map((option, index) => (
                                                                                                                            <Chip
                                                                                                                                label={option}
                                                                                                                                deleteIcon={<CloseIcon />}
                                                                                                                                {...getTagProps({ index })}
                                                                                                                            />
                                                                                                                        ))
                                                                                                                    }
                                                                                                                    PaperComponent={CustomPaper}
                                                                                                                    renderInput={(params: any) => <TextField {...params} placeholder='Enter Words/Phrases' />} />
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid xs={5.85} className={styles.inputOptionsCont} sx={{ mb: "15px" }}>
                                                                                                        <Grid container direction="column">
                                                                                                            <Grid item className={styles.ppOptionsHeader}>
                                                                                                                Words/ Phrases to Exclude
                                                                                                                {/* {tonOfVoice[0].name} */}
                                                                                                            </Grid>
                                                                                                            <Grid item>
                                                                                                                <Autocomplete
                                                                                                                    multiple
                                                                                                                    // className={" multiple_chips__autocomplete formWiz-input-field"}
                                                                                                                    className={styles.autocompleteParaphrasing}
                                                                                                                    disablePortal
                                                                                                                    id="combo-box-demo"
                                                                                                                    freeSolo
                                                                                                                    options={[]}
                                                                                                                    value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.words_to_exclude}
                                                                                                                    onChange={(event, values: any) => {
                                                                                                                        setRewordVersioningarr((prevState: any) =>
                                                                                                                            prevState.map((item: any, index: any) =>
                                                                                                                                index == rewordVersionIndex ?
                                                                                                                                    { ...item, wordChangerPayload: { ...item.wordChangerPayload, words_to_exclude: values } } : item
                                                                                                                            ))
                                                                                                                    }}
                                                                                                                    renderTags={(value, getTagProps) =>
                                                                                                                        value.map((option, index) => (
                                                                                                                            <Chip
                                                                                                                                label={option}
                                                                                                                                deleteIcon={<CloseIcon />}
                                                                                                                                {...getTagProps({ index })}
                                                                                                                            />
                                                                                                                        ))
                                                                                                                    }
                                                                                                                    PaperComponent={CustomPaper}
                                                                                                                    renderInput={(params: any) => <TextField {...params} placeholder='Enter Words/Phrases' />} />
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </>
                                                                                    </Grid>

                                                                                </Grid>
                                                                            </Grid>
                                                                            {/* <Divider sx={{ borderStyle: "unset !important" }} /> */}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </Carousel>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* paraphrase input */}
                                                        <Box
                                                            // for mobile
                                                            sx={{ height: "100%" }}

                                                            className={styles.ppInputField}>
                                                            {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length > 900 &&
                                                                <span
                                                                    style={{
                                                                        position: "absolute",
                                                                        bottom: "13px",
                                                                        color: "red",
                                                                        right: "17px",
                                                                        fontSize: "15px",
                                                                        zIndex: "1",
                                                                        background: "#fff"
                                                                    }}>
                                                                    {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length} / 1000 words
                                                                </span>
                                                            }
                                                            {/* {rewordVersioningArr[rewordVersionIndex].flagInputEditable ? */}
                                                            <GlobalTextField
                                                                id="paraphraseInput"
                                                                onFocus={(e) => { setIsInputFocused(true) }}
                                                                value={rewordVersioningArr[rewordVersionIndex].rewordingInput}
                                                                onBlur={() => { setTimeout(() => { setIsInputFocused(false) }, 100) }}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeParaphraseInput(e)}
                                                                placeholder="For rewording your text, just type or paste the content here and press the 'Reword' button."
                                                                // rows={6}
                                                                multiline
                                                                autoFocus={isInputFocused}
                                                                className={`${styles.ppGlobalMultilineTextField}`}
                                                            />
                                                            {/* :
                                                                    <div
                                                                        onClick={() => { handleToggleInputEdit(true) }}
                                                                        style={{ padding: "14px" }}
                                                                        className={`${styles.ppGlobalMultilineTextField} ${globalStyles.clickable}`}>
                                                                        <ReactMarkdown
                                                                            className={styles.reactMarkdownStyle}
                                                                            // children={option.value[option.index].paraphrase} 
                                                                            children={rewordVersioningArr[rewordVersionIndex].rewordingInput}
                                                                        />
                                                                    </div>
                                                                } */}
                                                        </Box>

                                                        {/* <Grid item className={styles.wordCounterMobileContainer}>
                                                            <Grid container className={styles.ppWbWordAndCharMainContainer}>
                                                                <Grid item className={styles.wordCounterMobile}>
                                                                    {rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().split(/\s+/).filter((st: any) => st != '').length} Words
                                                                </Grid>
                                                                <Grid item className={styles.wordCounterMobile}>
                                                                    {rewordVersioningArr[rewordVersionIndex].rewordingInput.length} Characters
                                                                </Grid>
                                                            </Grid>
                                                        </Grid> */}
                                                    </>
                                                )
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                    {/******* PANE 2 *******/}
                    <div style={{ height: "100%" }}>
                        <div
                            style={{
                                // transform: `rotate(${flagOutlineCollapsed ? "0deg" : "180deg"})`,
                                position: "absolute",
                                transform: "rotate(90deg)",
                                zIndex: "1",
                                right: "11px",
                                top: "-2px",
                                cursor: "pointer",
                            }}
                        >
                            <img
                                alt="drawerArrow"
                                // className={styles.child}
                                src={closeArrow} style={{ width: "25px", height: "25px", transform: collapsed ? "rotate(180deg)" : "" }}
                                onClick={() => {
                                    handleCollapse(collapsed, false)
                                }}
                            />
                        </div>

                        <div style={{ height: "100%" }}>
                            {rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.length ? (
                                <>
                                    {/* Output */}
                                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12} className={`${styles.ppOutputContainer} ${styles.mbOutputItem}`}>
                                        <Grid container sx={{ height: "100%", flexDirection: "row", justifyContent: "start", alignItems: "center" }} >
                                            <Grid item xs={12} className={styles.mbOutputHeadItem}>
                                                <Grid container justifyContent={"space-between"}>
                                                    <Grid item>

                                                        <Typography component={'span'} className={styles.mbOutputHead}>Output ({rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.length})</Typography>
                                                    </Grid>
                                                    <Grid item sx={{ paddingRight: "30px" }}>
                                                        {rewordVersioningArr.length > 2 &&
                                                            <Grid container alignItems={"center"} justifyContent={"end"} >
                                                                <IconsColor iconName="decIndex" className={rewordCurrentIndex !== 1 ? globalStyles.clickable : ""}
                                                                    width="8"
                                                                    height="13px"
                                                                    fillColor={rewordCurrentIndex !== 1 ? "#000" : "#7A7A7A"}
                                                                    onClick={() => {
                                                                        rewordCurrentIndex !== 1 &&
                                                                            handleDecVersioning()
                                                                    }}
                                                                />

                                                                <Grid item sx={{ margin: "0px 4px", }}>
                                                                    {/* {rewordVersionIndex + 1 > rewordVersioningArr.length - 1 ? rewordVersionIndex : rewordVersionIndex + 1}/{rewordVersioningArr.length - 1} */}
                                                                    <Typography component={'span'} sx={{ color: "#7A7A7A", fontSize: "16px", fontWeight: "500" }}>
                                                                        {rewordCurrentIndex}/{rewordVersioningArr.length - 1}
                                                                    </Typography>
                                                                </Grid>

                                                                <IconsColor iconName="IncIndex" className={(rewordCurrentIndex) !== (rewordVersioningArr.length - 1) ? globalStyles.clickable : ""}
                                                                    width="8"
                                                                    height="13px"
                                                                    fillColor={(rewordCurrentIndex) !== (rewordVersioningArr.length - 1) ? "#000" : "#7A7A7A"}
                                                                    onClick={() => {
                                                                        (rewordCurrentIndex) !== (rewordVersioningArr.length - 1) &&
                                                                            // setParaphrasingVersionIndex(rewordVersionIndex + 1)
                                                                            handleIncVersion()
                                                                    }}
                                                                />
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {flagLoading.flagContentGenerating ?
                                                <RoundLoader />
                                                :
                                                <Grid item xs={12} className={styles.ppOutputTextFields}>
                                                    {rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.length ?
                                                        <Grid container direction={"column"} className={`${globalStyles.fullHeight} ${styles.outputMainItem}`} >
                                                            <Grid item id="ppOutputField" xs={12} className={styles.ppOutputField}>
                                                                {
                                                                    rewordVersioningArr[rewordVersionIndex].executePromptResponseReword?.map((option: executePromptResponseParaphraseObj, index: number) => {

                                                                        return (
                                                                            <>
                                                                                {option.value.length > 0 &&
                                                                                    <div className={`${styles.outputCardContainer} outputCardContainer`} >
                                                                                        {/* option number */}
                                                                                        <div className={styles.firstBlock} >
                                                                                            <div className={styles.optionNumWrapper}>
                                                                                                <div className={styles.indexStyle}> Option {index + 1}</div>
                                                                                                {!(option.value.length > 1) &&
                                                                                                    <div className={styles.mbOptionCharText}>{option.value[option.index].paraphrase.trim().split(/\s+/).filter(st => st != '').length} W {option.value[option.index].paraphrase.length} C</div>}
                                                                                            </div>
                                                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                                        {/* pagination */}
                                                                                                        {option.value.length > 1 &&
                                                                                                            <>
                                                                                                                <div>
                                                                                                                    <IconsColor
                                                                                                                        iconName="decIndex"
                                                                                                                        className={option.index !== 0 ? globalStyles.clickable : ""}
                                                                                                                        width="7"
                                                                                                                        height="11px"
                                                                                                                        fillColor={rewordCurrentIndex !== 1 ? "#000" : "#7A7A7A"}
                                                                                                                        onClick={() => {
                                                                                                                            option.index !== 0 &&
                                                                                                                                handleDecOutputOptionVersion(option)
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div style={{ color: "#7A7A7A", fontSize: "14px", fontWeight: "500", margin: "0px 3px" }}>{`${option.index + 1}/${option.value.length}`}</div>

                                                                                                                <div style={{ paddingRight: "15px" }}>
                                                                                                                    <IconsColor
                                                                                                                        iconName="IncIndex"
                                                                                                                        className={option.index !== option.value.length - 1 ? globalStyles.clickable : ""}
                                                                                                                        width="7"
                                                                                                                        height="11px"
                                                                                                                        fillColor={option.index !== option.value.length - 1 ? "#000" : "#7A7A7A"}
                                                                                                                        onClick={() => { option.index !== option.value.length - 1 && handleIncOutputOptionVersion(option) }}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </>
                                                                                                        }
                                                                                                    </div>
                                                                                                    {/* buttons */}
                                                                                                    {
                                                                                                        <div style={{
                                                                                                            display: 'flex', alignItems: "center",
                                                                                                            gap: "12px"
                                                                                                        }}>
                                                                                                            {!option.value[option.index].checked &&
                                                                                                                <>
                                                                                                                    <div style={{ display: "flex" }}>
                                                                                                                        {!option.value[option.index].flagEditable &&
                                                                                                                            <>
                                                                                                                                {/* <Tooltip title={"Edit"}>
                                                                                                                                    <div
                                                                                                                                        className={option.value[option.index].flagLoading ? globalStyles.disabledClass : ""}
                                                                                                                                        onClick={() => {
                                                                                                                                            if (option.value[option.index].flagLoading) {
                                                                                                                                            }
                                                                                                                                        }} style={{ display: "flex", cursor: "pointer" }}>
                                                                                                                                        <IconsColor
                                                                                                                                            iconName="edit"
                                                                                                                                            fillColor="#6A097D"
                                                                                                                                            width="16px"
                                                                                                                                            height="16px"
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                </Tooltip> */}
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        onClick={() => {
                                                                                                                            if (option.value[option.index].flagLoading) {
                                                                                                                            } else {
                                                                                                                                handleRewriteOutputOption(option)
                                                                                                                            }
                                                                                                                        }}
                                                                                                                        className={option.value[option.index].flagLoading ? `${globalStyles.disabledClass} ${styles.useasInputBtnStyle}` : `${styles.useasInputBtnStyle} ${globalStyles.clickable}`}>
                                                                                                                        <Tooltip title={"Rewrite"}>
                                                                                                                            <Grid item>
                                                                                                                                <IconButton
                                                                                                                                    aria-label="rewrite"
                                                                                                                                    size="small"
                                                                                                                                // onClick={() => { handleRewriteOutputOption(option) }}
                                                                                                                                >
                                                                                                                                    <img src={reWriteIcon} alt="Rewrite" />
                                                                                                                                </IconButton>
                                                                                                                            </Grid>
                                                                                                                        </Tooltip>

                                                                                                                    </div>
                                                                                                                    {/* <div className={`${globalStyles.clickable}`}><IconsColor iconName={"download"} fillColor={"#6A097D"} /></div> */}
                                                                                                                    <CopyToClipboard
                                                                                                                        text={option.value[option.index].paraphrase}>
                                                                                                                        <Grid item>
                                                                                                                            <Tooltip
                                                                                                                                disableFocusListener
                                                                                                                                disableHoverListener
                                                                                                                                disableTouchListener
                                                                                                                                open={option.value[option.index].showTooltip}
                                                                                                                                title={copyText} onClick={() => {
                                                                                                                                    setCopyText("Copied!");
                                                                                                                                }}>
                                                                                                                                <div
                                                                                                                                    className={option.value[option.index].flagLoading ? `${globalStyles.disabledClass}` : `${globalStyles.clickable}`}
                                                                                                                                    onClick={() => {
                                                                                                                                        if (option.value[option.index].flagLoading) {
                                                                                                                                        } else {
                                                                                                                                            flagShowTooltip(option, "showTooltip")
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                    style={{ display: "flex", alignItems: "center" }}
                                                                                                                                >
                                                                                                                                    <IconsColor iconName={"Copy"} fillColor={"#6A097D"} width={"16"} height={"16"} />
                                                                                                                                </div>
                                                                                                                            </Tooltip>

                                                                                                                        </Grid>

                                                                                                                    </CopyToClipboard>



                                                                                                                    <div key={option.id} style={{ display: "flex" }}>
                                                                                                                        <IconButton
                                                                                                                            aria-label="more"
                                                                                                                            id={option.id.toString()}
                                                                                                                            key={option.id}
                                                                                                                            aria-haspopup="true"
                                                                                                                            // onClick={handleClick}
                                                                                                                            sx={{ padding: "0px" }}
                                                                                                                            className={option.value[option.index].flagLoading ? `${globalStyles.disabledClass}` : `${globalStyles.clickable}`}
                                                                                                                            onClick={(e: any) => {
                                                                                                                                if (option.value[option.index].flagLoading) {
                                                                                                                                } else {
                                                                                                                                    handleOpenMenu(e, option)
                                                                                                                                }
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <MoreVertIcon sx={{ color: "#6A097D", fontSize: "large" }} />
                                                                                                                        </IconButton>
                                                                                                                        <Menu
                                                                                                                            id={option.id.toString()}
                                                                                                                            MenuListProps={{
                                                                                                                                'aria-labelledby': 'long-button',
                                                                                                                            }}
                                                                                                                            anchorEl={option.value[option.index].anchorEl}
                                                                                                                            key={option.value[option.index].id}
                                                                                                                            open={option.value[option.index].flagOpenMenu}
                                                                                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeCheckOutputOptionCard(event, option) }}

                                                                                                                            onClose={(e: any) => handleCloseMenu(e, option)}
                                                                                                                            PaperProps={{
                                                                                                                                style: {
                                                                                                                                    // maxHeight: ITEM_HEIGHT * 4.5,
                                                                                                                                    // width: '130px',
                                                                                                                                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                                                                                                                    padding: "0 10px"
                                                                                                                                },
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <MenuItem onClick={() => { handleUseAsInput(option.value[option.index].paraphrase, option) }}

                                                                                                                                style={{
                                                                                                                                    minHeight: '20px',
                                                                                                                                    padding: "5px 10px",
                                                                                                                                    textAlign: "center",
                                                                                                                                    justifyContent: "start",
                                                                                                                                    alignItems: "center",
                                                                                                                                    borderBottom: "1px solid #E9E9E9"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <Typography component={'span'}>Use as Input</Typography>
                                                                                                                            </MenuItem>
                                                                                                                            <MenuItem
                                                                                                                                onClick={() => { deleteOutputOptionCard(option) }}
                                                                                                                                style={{
                                                                                                                                    minHeight: '20px',
                                                                                                                                    padding: "5px 10px",
                                                                                                                                    textAlign: "center",
                                                                                                                                    justifyContent: "start",
                                                                                                                                    alignItems: "center"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <Typography component={'span'}>Delete</Typography>
                                                                                                                            </MenuItem>
                                                                                                                        </Menu>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            }
                                                                                                            {/* <div style={{ display: "flex" }}>
                                                                                                                <Checkbox
                                                                                                                    key={option.value[option.index].id}
                                                                                                                    value={option.value[option.index].checked}
                                                                                                                    checked={option.value[option.index].checked}
                                                                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleChangeCheckOutputOptionCard(event, option) }}
                                                                                                                    className={`${styles.checkboxStyle} ${globalStyles.clickable}`}
                                                                                                                    checkedIcon={<img src={checkedIcon} />}
                                                                                                                    icon={<img src={unCheckedIcon} />}
                                                                                                                    disabled={option.value[option.index].flagLoading ? true : false}
                                                                                                                />
                                                                                                            </div> */}
                                                                                                        </div>}
                                                                                                </div>


                                                                                            </div>
                                                                                        </div>
                                                                                        {/* textarea */}
                                                                                        {<div>
                                                                                            {
                                                                                                option.value[option.index].flagLoading ?
                                                                                                    <>
                                                                                                        <div style={{ marginBottom: "16px" }}>
                                                                                                            <Skeleton />
                                                                                                            <Skeleton />
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {
                                                                                                            // option.value[option.index].flagEditable ?
                                                                                                            <>
                                                                                                                <div
                                                                                                                    onInput={(e: React.FormEvent<HTMLDivElement>) =>
                                                                                                                        handleParaphraseOutputChange(e, option)
                                                                                                                    }
                                                                                                                    // onChange={(e: any) => handleParaphraseOutputChange(e, option)}
                                                                                                                    className="ua-chrome ProseMirror pm-table-resizing-plugin"
                                                                                                                    aria-label="Main content area, start typing to enter text."
                                                                                                                    aria-multiline="true" role="textbox" id="ak-editor-textarea"
                                                                                                                    contentEditable="true"
                                                                                                                    data-gramm="false"
                                                                                                                    translate="no">
                                                                                                                    {/* @ts-ignore */}
                                                                                                                    {option.value[option.index].convertedToHTML}
                                                                                                                    {/* {convertTextToHTML(option.value[option.index].paraphrase)} */}
                                                                                                                </div>
                                                                                                            </>
                                                                                                            // :
                                                                                                            // <>



                                                                                                            //     <CopyToClipboard
                                                                                                            //         text={option.value[option.index].paraphrase}>
                                                                                                            //         <Grid item>
                                                                                                            //             <Tooltip
                                                                                                            //                 disableFocusListener
                                                                                                            //                 disableHoverListener
                                                                                                            //                 disableTouchListener
                                                                                                            //                 open={option.value[option.index].showCardTooltip}
                                                                                                            //                 title={copyText} onClick={() => {
                                                                                                            //                     setCopyText("Copied!");
                                                                                                            //                 }}>
                                                                                                            //                 <div
                                                                                                            //                     onClick={() => {
                                                                                                            //                         flagShowTooltip(option, "showCardTooltip")
                                                                                                            //                         // handleCopyToClipboard(option.value[option.index].paraphrase);
                                                                                                            //                         // setCopyText("Copied!")
                                                                                                            //                     }}
                                                                                                            //                     style={{ display: "flex", alignItems: "center" }}
                                                                                                            //                     className={`${globalStyles.clickable}`}
                                                                                                            //                 >
                                                                                                            //                     <div style={{ minHeight: "22px", whiteSpace: "pre-wrap" }}
                                                                                                            //                     // onClick={() => { handleToggleOutputEditable(option, true, index) }}
                                                                                                            //                     >
                                                                                                            //                         <div
                                                                                                            //                             onInput={(e: React.FormEvent<HTMLDivElement>) =>
                                                                                                            //                                 handleParaphraseOutputChange(e, option)
                                                                                                            //                             }
                                                                                                            //                             // onChange={(e: any) => handleParaphraseOutputChange(e, option)}
                                                                                                            //                             className="ua-chrome ProseMirror pm-table-resizing-plugin"
                                                                                                            //                             aria-label="Main content area, start typing to enter text."
                                                                                                            //                             aria-multiline="true" role="textbox" id="ak-editor-textarea"
                                                                                                            //                             contentEditable="true"
                                                                                                            //                             data-gramm="false"
                                                                                                            //                             translate="no">
                                                                                                            //                             {/* @ts-ignore */}
                                                                                                            //                             {option.value[option.index].convertedToHTML}
                                                                                                            //                             {/* {convertTextToHTML(option.value[option.index].paraphrase)} */}
                                                                                                            //                         </div>
                                                                                                            //                     </div>
                                                                                                            //                 </div>
                                                                                                            //             </Tooltip>

                                                                                                            //         </Grid>

                                                                                                            //     </CopyToClipboard>

                                                                                                            //     {/* <hr style={{ width: "100%" }} /> */}
                                                                                                            // </>
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                        </div>}
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                            {rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length !== 0 &&
                                                                <Box className={`${styles.ppOutputButtons} ${styles.mbppStickyFooter}`} >
                                                                    <Grid container justifyContent={'space-between'}>
                                                                        <Grid item className={styles.ppOutputButtonsContainer}>
                                                                            {
                                                                                <GlobalButton
                                                                                    disabled={
                                                                                        rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                    }
                                                                                    onClick={handleSaveDocument} buttonText={"Save"} className="borderedButtonStyle" />
                                                                            }
                                                                            <GlobalButton

                                                                                height='32px'
                                                                                disabled={
                                                                                    rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                }
                                                                                // onClick={handleUseAsInput} 
                                                                                buttonText={"Use as Input"} className='borderedButtonStyle' />
                                                                            <GlobalButton
                                                                                height='32px'
                                                                                disabled={
                                                                                    rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                }
                                                                                // onClick={handleRewriteOutputOption} 
                                                                                buttonText={"Rewrite"} className='borderedButtonStyle' />


                                                                        </Grid>

                                                                        <Grid item sx={{ display: "flex" }}>
                                                                            <Grid container alignItems={"center"} sx={{ gap: "8px", justifyContent: "space-between" }} >
                                                                                <Grid item>
                                                                                    <CopyToClipboard
                                                                                        text={""
                                                                                            // multipleCopyText()
                                                                                        }
                                                                                    >
                                                                                        <Grid item>
                                                                                            <Tooltip title={copyText} onClick={() => {
                                                                                                setCopyText("Copied!");

                                                                                            }}>
                                                                                                <GlobalButton
                                                                                                    onClick={() => {
                                                                                                        // handleCopyToClipboard(multipleCopyText());
                                                                                                        setCopyText("Copied!")
                                                                                                    }}
                                                                                                    disabled={
                                                                                                        rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                                    }
                                                                                                    buttonText={<IconsColor iconName="Copy" fillColor="#6A097D" width={"18"} height={"18"} />}
                                                                                                    className='borderedButtonStyle'></GlobalButton>
                                                                                            </Tooltip>

                                                                                        </Grid>

                                                                                    </CopyToClipboard>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <GlobalButton
                                                                                        disabled={
                                                                                            rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.flatMap((option: any) => option.value).filter((option: any) => option.checked == true).length == 0
                                                                                        }
                                                                                        // onClick={handleMultipleDelete}
                                                                                        buttonText={<IconsColor iconName="Delete" fillColor="#6A097D" width={"18"} height={"18"} />} className='borderedButtonStyle' />
                                                                                </Grid>
                                                                            </Grid>
                                                                            {/* <GlobalButton buttonText={<IconsColor iconName="download" fillColor="red" />} className='borderedButtonStyle' /> */}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>}
                                                        </Grid>
                                                        :
                                                        <>
                                                            <Box sx={{ height: "100%", padding: "10px", alignItems: "center", display: "flex" }}>
                                                                <Box className={globalStyles.globalTextStyleGrey} alignItems={"center"} sx={{ maxWidth: "355px" }}>
                                                                    AI paraphrased content will show up here
                                                                    <img src={shine} style={{ width: "20px", height: "20px" }} />
                                                                </Box>

                                                            </Box>
                                                        </>
                                                    }

                                                </Grid>}
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12} className={styles.mbOpSection2MainItem}>
                                        <Grid container className={styles.mbOpSection2MainContainer}>

                                            <Grid item xs={12}>
                                                <Grid container id="test">
                                                    <Grid item xs={12} className={`${styles.mbTabSectionMainItem}`}>
                                                        <Grid container direction={"column"} sx={{ flexWrap: "nowrap", paddingLeft: "15px" }}>
                                                            <Grid item className={styles.preferencesTextStyle}>Set your output preferences</Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {flagLoading.flagContentGenerating ?

                                                        <RoundLoader />
                                                        :
                                                        <>
                                                            {/* lower tabs */}
                                                            <Grid item xs={12} className={`${styles.ppInputFieldOptionsWordChanger}`} >
                                                                <Grid container direction="column" sx={{ flexWrap: "nowrap", maxHeight: "100%" }}>
                                                                    <Grid item xs={12} className={styles.inputOptions}>
                                                                        <Grid container gap={"0.2rem"} className={styles.inputOptionsChild}>
                                                                            <>
                                                                                {/* Advance tab */}

                                                                                {/* tone of voice */}
                                                                                <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "15px" }}>
                                                                                    <Grid container direction="column">
                                                                                        <Grid item className={styles.ppOptionsHeader}>Persona</Grid>
                                                                                        <Grid item>
                                                                                            <Autocomplete
                                                                                                options={personaList || []}
                                                                                                getOptionLabel={(option: any) => option.persona_name ? `${option.persona_name}, ${option.persona_display_name}` : ""}
                                                                                                filterOptions={(options, { inputValue }) => {
                                                                                                    const filteredOptions = options.filter((option) => {
                                                                                                        const personaName = option.persona_name || '';
                                                                                                        const subCategoryName = option.persona_sub_category__name || '';

                                                                                                        return (
                                                                                                            personaName.toLowerCase().includes(inputValue.toLowerCase()) ||
                                                                                                            subCategoryName.toLowerCase().includes(inputValue.toLowerCase())
                                                                                                        );
                                                                                                    });

                                                                                                    return filteredOptions;
                                                                                                }}
                                                                                                className={styles.autocompleteParaphrasing}
                                                                                                getOptionDisabled={(option) =>
                                                                                                    option.persona_premium_flag && ((userDetail.plan_detail && userDetail.plan_detail.credit_usage_data !== null && userDetail?.plan_detail?.credit_usage_data.stripe_product_name == "Free") || userDetail.flag_user_anonymous)
                                                                                                }
                                                                                                ChipProps={{ deleteIcon: <CloseIcon />, style: { maxWidth: '100%' }, }}
                                                                                                groupBy={(option) => option.persona_sub_category__name}
                                                                                                renderOption={(props, option) => (
                                                                                                    <li {...props}>
                                                                                                        <GlobalTooltip
                                                                                                            option={option}
                                                                                                        >
                                                                                                            <li {...props}
                                                                                                                style={{
                                                                                                                    paddingLeft: "0px",
                                                                                                                    paddingRight: "0px",
                                                                                                                    display: "flex",
                                                                                                                    alignItems: "center",
                                                                                                                    fontSize: "15px",
                                                                                                                    fontWeight: "500",
                                                                                                                    width: "100%"
                                                                                                                }}
                                                                                                            >
                                                                                                                <div
                                                                                                                    style={{ pointerEvents: "none", display: "flex" }}>
                                                                                                                    <img style={{ width: "37px", height: "37px", borderRadius: "50%", marginRight: "10px" }} src={option.persona_avatar_url ? option.persona_avatar_url : personaAvatar} alt="personaAvatar" />
                                                                                                                </div>

                                                                                                                <div style={{ width: "100%", pointerEvents: "none", padding: "0px 5px" }}>
                                                                                                                    {option.persona_display_name ? `${option.persona_name}, ${option.persona_display_name}` : option.persona_name}
                                                                                                                </div>

                                                                                                                {option.persona_premium_flag &&
                                                                                                                    <div style={{ width: "100%", display: "flex", justifyContent: "end", pointerEvents: "none", maxWidth: "25px" }}>
                                                                                                                        <img src={personaPremiumIcon} style={{ width: "25px", height: "auto" }} alt="personaPremiumIcon" />
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </li>
                                                                                                        </GlobalTooltip>
                                                                                                    </li>
                                                                                                )}
                                                                                                value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.personaObj}
                                                                                                onChange={(_, newValue) => {
                                                                                                    setRewordVersioningarr((prevState: any) =>
                                                                                                        prevState.map((item: any, index: any) =>
                                                                                                            index == rewordVersionIndex ?
                                                                                                                { ...item, personaPayload: { ...item.wordChangerPayload, personaObj: newValue == null ? "" : newValue, persona_id: newValue == null ? "" : newValue.persona_id } } : item
                                                                                                        ))
                                                                                                }}
                                                                                                renderInput={(params) => (
                                                                                                    <>
                                                                                                        <TextField
                                                                                                            placeholder='Select Persona'
                                                                                                            {...params}

                                                                                                        />
                                                                                                    </>
                                                                                                )}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                {/* writing style and work domain */}
                                                                                <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "15px" }}>
                                                                                    <Grid container sx={{ gap: "5px", justifyContent: "space-between" }}>
                                                                                        <Grid item xs={5.85}>
                                                                                            <Grid container direction="column">
                                                                                                <Grid item className={styles.ppOptionsHeader}>Reading Ease</Grid>
                                                                                                <Grid item>
                                                                                                    <Autocomplete
                                                                                                        className={styles.autocompleteParaphrasing}
                                                                                                        disablePortal
                                                                                                        id="readingEase"
                                                                                                        options={personaWritingSkills !== null ? personaWritingSkills.map((writingSkill: any) => writingSkill.persona_reading_level_name) : []}
                                                                                                        value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.reading_ease}
                                                                                                        onChange={(event, values: any) => {
                                                                                                            setRewordVersioningarr((prevState: any) =>
                                                                                                                prevState.map((item: any, index: any) =>
                                                                                                                    index == rewordVersionIndex ?
                                                                                                                        { ...item, wordChangerPayload: { ...item.wordChangerPayload, reading_ease: values } } : item
                                                                                                                ))
                                                                                                        }}
                                                                                                        renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item xs={5.85}>
                                                                                            <Grid container direction="column">
                                                                                                <Grid item className={styles.ppOptionsHeader}>Work Domain</Grid>
                                                                                                <Grid item>
                                                                                                    <Autocomplete
                                                                                                        className={styles.autocompleteParaphrasing}
                                                                                                        disablePortal
                                                                                                        id="workDomain"
                                                                                                        options={workDomainList.map((workDomain: any) => workDomain.work_domain_name)}
                                                                                                        onOpen={() => { handleSetMobileDrawer(workDomainList, "work_domain", "work_domain_name", "wordChangerPayload", false, "work_domain_icon_url", "Work Domain") }}
                                                                                                        value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.work_domain}
                                                                                                        onChange={(event, values: any) => {
                                                                                                            setRewordVersioningarr((prevState: any) =>
                                                                                                                prevState.map((item: any, index: any) =>
                                                                                                                    index == rewordVersionIndex ?
                                                                                                                        { ...item, wordChangerPayload: { ...item.wordChangerPayload, work_domain: values } } : item
                                                                                                                ))
                                                                                                        }}
                                                                                                        renderOption={(props, option, state) => (
                                                                                                            <li {...props}>
                                                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                                    <img src={workDomainList.find((workDomain: any) => workDomain.work_domain_name == option)?.work_domain_icon_url ?? ""} alt="icon" style={{ width: '17px', height: '17px', marginRight: '8px' }} />
                                                                                                                    {option}
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        )}
                                                                                                        PaperComponent={CustomPaper}
                                                                                                        renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>

                                                                                {/* reading ease and format */}
                                                                                <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "15px" }}>
                                                                                    <Grid container sx={{ gap: "5px", justifyContent: "space-between" }}>
                                                                                        <Grid xs={5.85} className={styles.inputOptionsCont} sx={{ mb: "15px", display: "flex" }}>
                                                                                            <Grid container justifyContent={"space-between"} direction="column">
                                                                                                <Grid item className={styles.ppOptionsHeader}>
                                                                                                    Words/ Phrases to Include
                                                                                                    {/* {tonOfVoice[0].name} */}
                                                                                                </Grid>
                                                                                                <Grid item>
                                                                                                    <Autocomplete
                                                                                                        multiple
                                                                                                        // className={" multiple_chips__autocomplete formWiz-input-field"}
                                                                                                        className={styles.autocompleteParaphrasing}
                                                                                                        disablePortal
                                                                                                        id="combo-box-demo"
                                                                                                        freeSolo
                                                                                                        options={[]}
                                                                                                        value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.words_to_include}
                                                                                                        onChange={(event, values: any) => {
                                                                                                            setRewordVersioningarr((prevState: any) =>
                                                                                                                prevState.map((item: any, index: any) =>
                                                                                                                    index == rewordVersionIndex ?
                                                                                                                        { ...item, wordChangerPayload: { ...item.wordChangerPayload, words_to_include: values } } : item
                                                                                                                ))
                                                                                                        }}
                                                                                                        renderTags={(value, getTagProps) =>
                                                                                                            value.map((option, index) => (
                                                                                                                <Chip
                                                                                                                    label={option}
                                                                                                                    deleteIcon={<CloseIcon />}
                                                                                                                    {...getTagProps({ index })}
                                                                                                                />
                                                                                                            ))
                                                                                                        }
                                                                                                        PaperComponent={CustomPaper}
                                                                                                        renderInput={(params: any) => <TextField {...params} placeholder='Enter Words/Phrases' />} />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid xs={5.85} className={styles.inputOptionsCont} sx={{ mb: "15px" }}>
                                                                                            <Grid container direction="column">
                                                                                                <Grid item className={styles.ppOptionsHeader}>
                                                                                                    Words/ Phrases to Exclude
                                                                                                    {/* {tonOfVoice[0].name} */}
                                                                                                </Grid>
                                                                                                <Grid item>
                                                                                                    <Autocomplete
                                                                                                        multiple
                                                                                                        // className={" multiple_chips__autocomplete formWiz-input-field"}
                                                                                                        className={styles.autocompleteParaphrasing}
                                                                                                        disablePortal
                                                                                                        id="combo-box-demo"
                                                                                                        freeSolo
                                                                                                        options={[]}
                                                                                                        value={rewordVersioningArr[rewordVersionIndex].wordChangerPayload.words_to_exclude}
                                                                                                        onChange={(event, values: any) => {
                                                                                                            setRewordVersioningarr((prevState: any) =>
                                                                                                                prevState.map((item: any, index: any) =>
                                                                                                                    index == rewordVersionIndex ?
                                                                                                                        { ...item, wordChangerPayload: { ...item.wordChangerPayload, words_to_exclude: values } } : item
                                                                                                                ))
                                                                                                        }}
                                                                                                        renderTags={(value, getTagProps) =>
                                                                                                            value.map((option, index) => (
                                                                                                                <Chip
                                                                                                                    label={option}
                                                                                                                    deleteIcon={<CloseIcon />}
                                                                                                                    {...getTagProps({ index })}
                                                                                                                />
                                                                                                            ))
                                                                                                        }
                                                                                                        PaperComponent={CustomPaper}
                                                                                                        renderInput={(params: any) => <TextField {...params} placeholder='Enter Words/Phrases' />} />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        </Grid>

                                                                    </Grid>
                                                                </Grid>
                                                                {/* <Divider sx={{ borderStyle: "unset !important" }} /> */}
                                                            </Grid>

                                                            {/* language, no of output */}
                                                        </>}
                                                </Grid>
                                            </Grid>

                                            {/* paraphrase button */}
                                            {!flagLoading.flagContentGenerating &&
                                                <Grid item className={`${styles.paraphraseButtonContainer} ${styles.mbOpSection2ParaphrserBtnMainItem}`} sx={{ width: "170px" }}>
                                                    <Grid container className={`${styles.mbOpSection2ParaphrserBtnMainContainer}`}>
                                                        <GlobalButton
                                                            buttonText="Reword"
                                                            flagFullWidth
                                                            height='40px !important'
                                                            className="primaryButtonStyle"
                                                            disabled={getParaphraseDisableFlag()}
                                                            onClick={async () => { handleCollapse(true, true); await handleParaphrase(); getContainerHeight(); }}
                                                        />
                                                    </Grid>
                                                </Grid>}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </div>
                    </div>
                </SplitPane >
            </div >
        </>
    )
}

export default WordChangerMobileComponent

