import React, { useEffect, useState } from "react";
import { DragAndDrop, Drag, Drop } from "./drag-and-drop";
import '../../assets/styles/componentStyles/outlineDndComponent.css'
import { DraggableItem } from "./drag-and-drop/DraggableItem.jsx";
import { reorder } from "./drag-and-drop/helper.js";
import { DocContext } from "../../context/docContext";
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import RoundLoader from "../utility/globalLoader";
import GlobalRoundLoader from "../utility/globalRoundLoader";
export const NestedListComponent = () => {
  const { currentActiveStep, documentOutlineData, setDocumentOutlineData, UpdateDocumentOutlineData, currentActiveDocDetails } = React.useContext(DocContext);
  const [documentOutlineDebounceData] = useDebounce(documentOutlineData, 2000);
  console.log("documentOutlineData", documentOutlineData)
  const [flagFirstRenderDone, setFlagFirstRenderDone] = useState(true)
  const [flagOutlineProccessing, setFlagOutlineProccessing] = useState(false)


  const setCorrectParentId = (tempOutlineData, parentId) => {
    for (const item of tempOutlineData) {
      item.document_outline_parent_id = parentId
      if (item.children && item.children.length > 0) {
        setCorrectParentId(item.children, item.document_outline_id);
      }
    }
    return tempOutlineData
  }
  const checkIfDragOutside = (tempOutlineData, parentId, flagArray) => {
    for (const item of tempOutlineData) {
      if (item?.document_outline_parent_id == parentId) {
        flagArray.push(true)
      } else {
        flagArray.push(false)
      }
      if (item?.children && item.children.length > 0) {
        checkIfDragOutside(item.children, item.document_outline_id, flagArray);
      }
    }
    return flagArray
  }

  const handleDragEnd = (result) => {
    // return
    const { type, source, destination, draggableId } = result;
    if (!destination) return;

    const sourceDroppableId = source.droppableId;
    const destinationCategoryId = destination.droppableId;
    // Reordering items
    if (type === "droppable-sub-item") {
      let tempOutlineData = [...documentOutlineData]
      let tempDarggedElement;
      //Remove Item
      tempOutlineData.map((levelOne) => {
        if (levelOne?.children) {
          levelOne.children?.map((levelTwo) => {
            if (levelTwo?.children) {
              levelTwo?.children?.map((levelThree) => {
                if (levelThree?.unique_id == draggableId) {
                  tempDarggedElement = levelThree;
                }
              })
              levelTwo['children'] = levelTwo?.children?.filter((sub) => sub.unique_id != draggableId)
            }
          })
        }
      })

      //Add Item
      tempOutlineData.map((levelOne) => {
        if (levelOne?.children) {
          levelOne?.children?.map((levelTwo) => {
            if (levelTwo?.children) {
              if (levelTwo?.unique_id == destination.droppableId) {
                levelTwo.children.splice(destination.index, 0, tempDarggedElement)
              }
            }
          })
        }
      })

      let flagArray = []
      let flagDragOutside = checkIfDragOutside(tempOutlineData, null, flagArray)
      if (flagDragOutside.indexOf(false) > -1) {
        return;
      } else {
        let updatedData = setCorrectParentId(tempOutlineData, null)
        setDocumentOutlineData(updatedData);
      }
    }
    if (type === "droppable-item") {
      // If drag and dropping within the same category
      if (sourceDroppableId === destinationCategoryId) {
        const updatedOrder = reorder(
          documentOutlineData.find((category) => category.unique_id == sourceDroppableId).children,
          source.index,
          destination.index
        );
        const updatedCategories = documentOutlineData.map((category) =>
          category.unique_id != sourceDroppableId
            ? category
            : { ...category, children: updatedOrder }
        );
        let flagArray = []
        let flagDragOutside = checkIfDragOutside(updatedCategories, null, flagArray)
        if (flagDragOutside.indexOf(false) > -1) {
          return;
        } else {
          let updatedData = setCorrectParentId(updatedCategories, null)
          setDocumentOutlineData(updatedData);
        }
      } else {
        const sourceOrder = documentOutlineData.find(
          (category) => category.unique_id == sourceDroppableId
        ).children;
        const destinationOrder = documentOutlineData.find(
          (category) => category.unique_id == destinationCategoryId
        ).children;

        const [removed] = sourceOrder.splice(source.index, 1);
        destinationOrder.splice(destination.index, 0, removed);

        destinationOrder[removed] = sourceOrder[removed];
        delete sourceOrder[removed];

        const updatedCategories = documentOutlineData.map((category) =>
          category.unique_id == sourceDroppableId
            ? { ...category, children: sourceOrder }
            : category.unique_id == destinationCategoryId
              ? { ...category, children: destinationOrder }
              : category
        );
        let flagArray = []
        let flagDragOutside = checkIfDragOutside(updatedCategories, null, flagArray)
        if (flagDragOutside.indexOf(false) > -1) {
          return;
        } else {
          let updatedData = setCorrectParentId(updatedCategories, null)
          setDocumentOutlineData(updatedData);
        }
      }
    }

    // Reordering documentOutlineData
    if (type === "droppable-category") {
      const updatedCategories = reorder(
        documentOutlineData,
        source.index,
        destination.index
      );
      let updatedData = setCorrectParentId(updatedCategories, null)
      setDocumentOutlineData(updatedData);
    }
  };

  useEffect(() => {
    if (flagFirstRenderDone)
      debounced()

  }, [documentOutlineData])

  function printDocumentOutlineId(data) {
    for (const item of data) {
      if (item.unique_id !== undefined && item.unique_id.toString().indexOf("udid-") > -1) {
        return true
      }

      if (item.children && item.children.length > 0) {
        var tt = printDocumentOutlineId(item.children);
        if (tt) {
          return true
        }
      }
    }
    return false
  }
  function addUniqueOutlineId(data) {
    for (const item of data) {
      item['unique_id'] = item.document_outline_id
      delete item.document_outline_level_num
      delete item.document_outline_seq_num

      if (item.children && item.children.length > 0) {
        addUniqueOutlineId(item.children);
      }
    }
    return data
  }

  const debounced = useDebouncedCallback(
    async () => {
      let finalObj = {}
      finalObj['document_uuid'] = currentActiveDocDetails.document_uuid
      finalObj['document_step_id'] = currentActiveDocDetails.document_step_data[1].document_step_id
      finalObj['document_outline_data'] = documentOutlineDebounceData
      console.log("final++++++++++", finalObj)
      let flagLoadingRequired = printDocumentOutlineId(finalObj.document_outline_data)
      if (flagLoadingRequired) {
        setFlagOutlineProccessing(true)
      }
      let response = await UpdateDocumentOutlineData(finalObj)
      if (response.status && flagLoadingRequired) {
        let responseWithId = addUniqueOutlineId(response.data[0].document_step_data[1].document_outline_data)
        setDocumentOutlineData(responseWithId)
      }
      setFlagOutlineProccessing(false)
    }, 2000);


  return (
    <>
      {flagOutlineProccessing ? <GlobalRoundLoader /> : ""}
      <DragAndDrop onDragEnd={handleDragEnd} >
        <Drop id="droppable" type="droppable-category">
          {documentOutlineData.map((levelOne, levelOneIndex) => {
            return (
              <Drag
                className="draggable"
                key={levelOne.unique_id}
                id={levelOne.unique_id.toString()}
                index={levelOneIndex}
                contentData={levelOne}
                setFlagOutlineProccessing={setFlagOutlineProccessing}
              >
                <div className="heading-container">
                  <DraggableItem contentData={levelOne} contentType={levelOne?.content_type_name} subContentType={levelOne?.sub_content_type_name} />
                  <Drop key={levelOne.unique_id} id={levelOne.unique_id.toString()} type="droppable-item">
                    {levelOne?.children && levelOne.children.map((levelTwo, levelTwoIndex) => {
                      return (
                        <Drag
                          className="draggable"
                          key={levelTwo.unique_id}
                          id={levelTwo.unique_id.toString()}
                          index={levelTwoIndex}
                          contentData={levelTwo}
                          setFlagOutlineProccessing={setFlagOutlineProccessing}
                        >
                          <div className="subheading-container">
                            <DraggableItem contentData={levelTwo} contentType={levelTwo?.content_type_name} subContentType={levelTwo?.sub_content_type_name} />
                            <Drop key={levelTwo.unique_id} id={levelTwo.unique_id.toString()} type="droppable-sub-item">
                              {levelTwo?.children && levelTwo.children.map((levelThree, levelThreeIndex) => {
                                return (
                                  <Drag
                                    className="draggable"
                                    key={levelThree.unique_id}
                                    id={levelThree.unique_id.toString()}
                                    index={levelThreeIndex}
                                    contentData={levelThree}
                                    setFlagOutlineProccessing={setFlagOutlineProccessing}
                                  >
                                    <DraggableItem contentData={levelThree} contentType={levelThree?.content_type_name} subContentType={levelThree?.sub_content_type_name} />
                                  </Drag>
                                );
                              })}
                            </Drop>
                          </div>
                        </Drag>
                      );
                    })}
                  </Drop>
                </div>
              </Drag>
            );
          })}
        </Drop>
      </DragAndDrop>
    </>
  );
};
