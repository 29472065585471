import * as React from "react";
import { Grid, Divider, Typography, Button } from "@mui/material";
import styles from "../../assets/styles/componentStyles/templateHeader.module.scss";
import globalStyles from "../../assets/styles/global.module.scss";
import GlobalButton from "../utility/globalButton/button";
import backArrow from "../../assets/backArrow.png";
import { DocContext, DocContextPayload } from "../../context/docContext";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext, UserContextPayload } from "../../context/userContext";
import { prompts } from "../../utils/constants";
import { documentStepDataModel } from "../../models/axiosModel";
import { isEqual } from "lodash";

interface pageTitleBarProps {
  title?: string;
  nextStepText?: string;
  nextStepButtonClassname?: string;
  totalSteps?: number;
}

export default function TemplateHeader(props: pageTitleBarProps) {
  const navigate = useNavigate()
  const { currentActiveStep, setCurrentActiveStep,
    steps, blogTitle, currentActiveDocDetails, webSocketObj, setFlagOutlineLoader, documentEditorData, documentOutlineData, UpdateDocInfo, flagFormDetailsChanged, } = React.useContext(
      DocContext
    ) as DocContextPayload;
  const { setFlagLoading, flagLoading } = React.useContext(UserContext) as UserContextPayload;

  const getNextButtonDisabledCondition = () => {
    if (currentActiveStep == 1) {
      if (blogTitle == "") {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  function updateResponseAsRequired(data: any) {
    for (const item of data) {
      item['unique_id'] = item.document_outline_id
      delete item.document_outline_level_num
      delete item.document_outline_seq_num

      if (item?.children && item.children.length > 0) {
        updateResponseAsRequired(item.children);
      }
    }
    return data
  }
  const isEdiorDataAvailable = (data: any): boolean => {
    let flag = false;
    if (data) {
      for (const item of data) {
        if (item['text'] != undefined) {
          if (item['text']?.length) {
            flag = true;
          }
        }
        if (item?.children?.length) {
          flag = flag || isEdiorDataAvailable(item.children);
        }
      }
    }
    return flag;
  }

  const handleNext = async () => {
    // if (currentActiveStep == 1) { //added temp for flow
    //   navigate(`/doc/${currentActiveDocDetails?.document_uuid}/3/${currentActiveDocDetails?.template_id}`)
    // } else {
    if (currentActiveStep == 1) {
      if (currentActiveDocDetails) {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        // setting the document same as document title for the first time
        if (currentActiveDocDetails.document_name == "Untitled Document") {
          const payload = {
            document_uuid: currentActiveDocDetails.document_uuid,
            document_name: blogTitle
          }
          await UpdateDocInfo(payload);
        }
        // if (documentOutlineData && documentOutlineData.length > 0 && !flagFormDetailsChanged) {
        //   navigate(`/doc/${currentActiveDocDetails.document_uuid}/2/${currentActiveDocDetails?.template_id}`)
        //   setFlagLoading({ ...flagLoading, flagRoundLoader: false});
        //   console.log("data already exist")
        // } else {
        const matchingPrompt = prompts.AISuggestions.find((prompt) => prompt.prompt_key === "1::blog_outline_section_header");
        if (matchingPrompt?.prompt_id) {
          setFlagOutlineLoader(true)
          const payload = {
            document_uuid: currentActiveDocDetails.document_uuid,
            prompt_id: matchingPrompt.prompt_id
          }

          webSocketObj.send(JSON.stringify({
            "url": "ai/ask-ai",
            "operation_id": `prompt-heading-${new Date().getTime()}`,
            "payload": {
              "document_uuid": payload.document_uuid,
              "prompt_id": payload.prompt_id
            }
          }))
          // const response = await ExecutePrompt(payload)
          // setFlagLoading(false)
          // if (response.status) {
          //   navigate(`/doc/${currentActiveDocDetails.document_uuid}/2/${currentActiveDocDetails.template_id}`)
          //   setCurrentActiveStep(2)
          //   if (response?.data?.length && response.data[0]?.document_step_data) {
          //     let updatedOutlineResponse = updateResponseAsRequired(response.data[0].document_step_data?.filter((step: documentStepDataModel) => step?.step_type_name == "Outline")[0].document_outline_data)
          //     setDocumentOutlineData(updatedOutlineResponse);
          //     setFlagOutlineLoader(false)
          //   }
        }
        // }
        // }
      }
    }
    if (currentActiveStep == 2) {
      if (currentActiveDocDetails) {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        if (isEqual(documentOutlineData, currentActiveDocDetails.document_step_data.filter((obj) => obj.step_type_name == "Outline")[0].document_outline_data) && documentEditorData && (isEdiorDataAvailable(documentEditorData ? documentEditorData['root']['children'] : []))) {
          navigate(`/doc/${currentActiveDocDetails.document_uuid}/3/${currentActiveDocDetails?.template_id}`)
          setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        } else {
          const matchingPrompt = prompts.AISuggestions.find(
            (prompt) => prompt.prompt_key === "1::blog_editor_generation"
          );
          if (matchingPrompt?.prompt_id) {
            const payload = {
              document_uuid: currentActiveDocDetails.document_uuid,
              prompt_id: matchingPrompt.prompt_id
            }

            webSocketObj.send(JSON.stringify({
              "url": "ai/ask-ai",
              "operation_id": `prompt-blog-${new Date().getTime()}`,
              "payload": {
                "document_uuid": payload.document_uuid,
                "prompt_id": payload.prompt_id
              }
            }))
          }
        }
      }
    }
    // setCurrentActiveStep(currentActiveStep + 1)
    // if (currentActiveDocDetails) {
    //   if (currentActiveDocDetails.document_name == "Untitled Document") {
    //     const payload = {
    //       document_uuid: currentActiveDocDetails.document_uuid,
    //       document_name: blogTitle
    //     }
    //     const response = await UpdateDocInfo(payload);
    //     console.log("response of update data", response)
    //     if (response.data) {
    //       setCurrentActiveDocDetails(response?.data[0])
    //     }
    //   }
    // }
    // }

  }
  const { ActiveStep } = useParams()

  const handleGoBack = () => {
    if (ActiveStep != "1") {
      setCurrentActiveStep(Number(ActiveStep) - 1)
      navigate(`/doc/${currentActiveDocDetails?.document_uuid}/${Number(ActiveStep) - 1}/${currentActiveDocDetails?.template_id}`)
    }
  }

  return (
    <Grid container className={globalStyles.containerflexWrapStyle} direction="column">
      <Grid item className={globalStyles.fullwidth}>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"space-between"}
          className={styles.titleStyleWrapper}
        >
          {/* left section */}
          <Grid
            item
            xs={8}
            className={`${styles.titleStyle} ${globalStyles.gridItemStyle}`}
          >
            {/* backarrow */}
            {currentActiveStep != 1 &&
              <Typography sx={{ display: "flex", paddingRight: "10px" }}>
                <img className={globalStyles.clickable} onClick={handleGoBack} src={backArrow} style={{ width: "26px", height: "26px" }} />
              </Typography>
            }

            {/* step label and step status */}
            {steps !== null && steps[currentActiveStep == 0 ? 0 : currentActiveStep - 1].label}{" "}
            <Typography
              className={`${styles.stepStatus} ${globalStyles.globalTextStyleGrey}`}
            >
              {currentActiveStep} of {steps !== null && steps.length}
            </Typography>

            {/* blogTitle */}
            {currentActiveStep == 1 &&
              <Typography className={globalStyles.globalTextStyleBlack} sx={{ borderLeft: "1px solid #BDBDBD", marginLeft: "15px", paddingLeft: "15px" }}>{blogTitle}</Typography>}

          </Grid>

          {/* right section */}
          <Grid item>
            <GlobalButton
              buttonText={steps !== null ? steps[currentActiveStep == 0 ? 0 : currentActiveStep - 1].nextStepText : ""}
              className={"secondaryButtonStyle"}
              flagMoveForward={steps !== null && steps[currentActiveStep == 0 ? 0 : currentActiveStep - 1].flagMoveForward}
              onClick={handleNext}
              disabled={getNextButtonDisabledCondition()}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid item>
            <Divider/>
        </Grid> */}
    </Grid>
  );
}
