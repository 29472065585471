import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { useNavigate } from 'react-router-dom';
import globalStyles from "../../../assets/styles/global.module.scss";
// import personaStyles from "../../../assets/styles/componentStyles/personaList.module.scss";
import personaStyles from "../../../assets/styles/componentStyles/personaList.module.scss";
import styles from '../../../assets/styles/componentStyles/newPersonaList.module.scss';
import brandAvatar from "../../../assets/brandAvatar.svg";
import { PersonaContext, PersonaContextPayload } from '../../../context/personaContext';
import { CommonResponseModel, CommonResponseSecondaryModel, GetPersonaListDataModel, RegisterDataResponseModel, SendOTPResponseDataModel } from '../../../models/axiosModel';
import createPersonaIcon from "../../../assets/createPersona.png";
import AddIcon from '@mui/icons-material/Add';
import { GlobalTooltip } from '../../utility/globalTooltip';
import personaAvatar from "../../../assets/personaAvatar.svg";
import { Tooltip } from '@mui/joy';
import GlobalSearch from '../../utility/GlobalSearch/GlobalSearch';
import PremiumLogo from '../../../assets/PremiumUserImage.jpg'
import noDataFound from '../../../assets/CampaignNoDataFound.png';
import GlobalPageHeaderText from '../../utility/globalPageHeaderText';

const Persona = () => {
    const navigate = useNavigate()
    const { personaList, GetPersonaList, setCurrentActivePersonaDetails } = useContext(PersonaContext) as PersonaContextPayload
    const { userDetail, setFlagLoading, setError, error, Register, setUserDetail, flagLoading } = useContext(UserContext) as UserContextPayload;
    const [personaListData, setPersonaListData] = useState<GetPersonaListDataModel[] | null>();
    const [personanRender, setPersonaRender] = useState<any>(null)
    const [screenWidth, setScreenWidth] = useState(window.screen.width);
    const isSmallScreen = screenWidth <= 1200;

    useEffect(() => {
        (async () => {
            setCurrentActivePersonaDetails(null)
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            setPersonaRender(false)
            // if (userDetail.flagUser) {
            await GetPersonaList();
            // } else {
            //     await GetSystemPersonaPublicList()
            // }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        })()
    }, [])

    useEffect(() => {
        (async () => {
            // await setPersonaList(personaList)
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            setPersonaListData(personaList)
            setPersonaRender(false)

            if (personaList && personaList?.length) {
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                setPersonaRender(true)
            }
        })()

    }, [personaList])
    const handleCreateUser = async () => {
        const payload = {
            google_recaptcha_token: null,
        };
        const response: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> =
            await Register(payload);
        if (response.status) {
            if (Array.isArray(response.data)) {
                setUserDetail({ ...response.data[0], flagUser: response.status, flagUserFromParaphrase: true })
            }
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    };
    const handleGetPersonaDetails = async (persona_id: number, flagPremium: boolean) => {
        if (userDetail.flagUser) {

        } else {
            await handleCreateUser();
        }
        navigate(`/dashboard/viewPersona?persona_id=${persona_id}`)
        // if (flagPremium) {
        //     if (userDetail.flag_user_anonymous || userDetail.plan_detail && userDetail.plan_detail.credit_usage_data.stripe_product_name == "Free") {
        //         setShowInfo({ ...showInfo, flagShowInfo: true, message: "Buy Plan to unlock!" })
        //     } else {
        //         navigate(`/dashboard/viewPersona?persona_id=${persona_id}`)
        //     }
        // } else {
        // }
    }

    const handleSearch = (event: any) => {
        const query = event.target.value?.toLowerCase()
        if (personaList != null) {
            const personaData = personaList.filter(
                (persona) => {
                    const toneOfVoiceString = persona?.persona_tone_of_voice?.toLowerCase();
                    return (
                        (persona?.persona_name !== null && persona?.persona_name?.toLowerCase().includes(query)) ||
                        (persona?.persona_display_name !== null && persona?.persona_display_name?.toLowerCase().includes(query)) ||
                        (toneOfVoiceString?.includes(query))
                    )
                }
                // searchPersona => {
                //     const lowerCaseDocumentName = searchPersona.persona_name.toLowerCase();
                //     const lowerCaseResults = event.target.value?.toLowerCase();
                //     return lowerCaseDocumentName.includes(lowerCaseResults);
                // }
            );
            setPersonaListData(personaData)
            // setPersonaListData(personaData != null ? personaData : personaList)
        }
    };

    const personaFlagValue: boolean[] | null = personaListData !== null && personaListData !== undefined ? personaListData.map((persona: GetPersonaListDataModel) => persona.persona_flag_public) : null;
    const trueCount = personaFlagValue != null ? personaFlagValue.reduce((count, value) => count + (value ? 1 : 0), 0) : 0
    const falseCount = personaFlagValue != null ? personaFlagValue.reduce((count, value) => count + (value == false ? 1 : 0), 0) : 0


    return (
        <>
            {/* {
                <GlobalPopUp
                    open={true}
                    // onClose={() => { setFlagDeleteDocumentDataPopup(false) }}
                    title={""}
                    flagShowCloseIcon={false}
                    handleClose={() => { }}
                >
                    <UpgradePersonaPopup
                        deleteDescription="Are you sure you want to delete this document?"
                        deleteCancelDocumentDataPopupOpen={handleSearch}
                        handleDeleteDocument={handleSearch} />
                </GlobalPopUp>
            } */}

            <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth}`}>
                    <Grid container sx={{ flexDirection: 'column' }} className={styles.personaMainContainer}>

                        <Grid item xs={12}>
                            <Grid container sx={{ alignItems: "center" }}>
                                <Grid item xl={6} lg={5} md={12} sm={12} xs={12} sx={{ marginBottom: "10px" }}>
                                    <GlobalPageHeaderText
                                        mainHeadText={"Persona"}
                                        mainSubText={"Customize your AI experience to your persona and writing style"}
                                    />
                                </Grid>
                                <Grid item xl={6} lg={7} md={12} sm={12} xs={12} className={styles.personaSearchMainItem} sx={{ marginBottom: { xs: "20px", sm: "20px", md: "0px" } }}>
                                    <Grid container className={`${globalStyles.globalHeadMainItemRightContainer}`} >
                                        <Grid item xl={5.5} lg={5.5} md={4.5} sm={5.5} xs={9} className={`${globalStyles.globalHeadLeftSubItem}`}>
                                            <Grid container>
                                                <Grid item className={`${globalStyles.globalSearchbarSubItem}`}>
                                                    <GlobalSearch
                                                        fullWidth
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSearch(e) }}
                                                        size="small"
                                                        placeholder="Search"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {userDetail.flagUser &&
                                            <Grid item xl={4} lg={5} md={4.5} sm={5.5} xs={2} className={`${globalStyles.globalHeadRightSubItem} `}>
                                                <Grid container sx={{ alignItems: "center" }}>
                                                    {window.screen.width >= 900 &&
                                                        <Grid item className={`${globalStyles.globalNewAddBtnItem}`}>
                                                            <Button
                                                                variant='outlined'
                                                                size='large'
                                                                fullWidth
                                                                startIcon={<img src={createPersonaIcon} alt="" style={{ height: "18px", width: 'auto' }} />}
                                                                className={globalStyles.addDocumentBtn}
                                                                onClick={() => { navigate("/dashboard/createPersona") }}
                                                            >
                                                                Create Custom Persona
                                                            </Button>
                                                        </Grid>
                                                    }
                                                    {window.screen.width <= 899 &&
                                                        <IconButton
                                                            className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                                            onClick={() => { navigate("/dashboard/createPersona") }}
                                                        >
                                                            <AddIcon />
                                                        </IconButton>
                                                    }
                                                </Grid>
                                            </Grid>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} >
                            <Grid container>
                                {personanRender == true ? personaList != null && personaList.length > 0 && personaListData != null && personaListData.length ?
                                    (
                                        <>
                                            {falseCount != 0 &&
                                                <>
                                                    <Grid item className={`${personaStyles.createdPersonaTypeText} ${styles.pcLabelItems}`}>
                                                        User Created Persona
                                                    </Grid>

                                                    {
                                                        // personaListData != null &&
                                                        personaListData.map((persona: GetPersonaListDataModel) => {
                                                            return (
                                                                <>
                                                                    {(persona.persona_flag_public == false) ?
                                                                        (isSmallScreen ?
                                                                            (
                                                                                <>
                                                                                    <Grid item className={persona.user_default_persona_data.length > 0 ? `${styles.personaDefaultStyle} ${styles.personaCardMainItem}` : styles.personaCardMainItem} onClick={() => { handleGetPersonaDetails(persona.persona_id, persona.persona_premium_flag) }}>
                                                                                        <Grid container>
                                                                                            <Grid item className={persona.persona_type_name == "Brand" ? styles.pcBrandProfileItem : styles.pcIndividualProfileItem}>
                                                                                                <Typography component={'span'} className={styles.pcUserTagNameText} sx={{ padding: "0px 0px 5px" }}>
                                                                                                    {persona.persona_premium_flag ?
                                                                                                        (
                                                                                                            <Tooltip
                                                                                                                arrow
                                                                                                                title={
                                                                                                                    <>
                                                                                                                        <Grid container direction="column">
                                                                                                                            Only available in Paid Plans
                                                                                                                        </Grid>
                                                                                                                    </>
                                                                                                                }
                                                                                                            >
                                                                                                                <Grid item className={`${persona.persona_type_name === "Brand" ? styles.personaBrandTypeLabel : styles.personaIndividualTypeLabel} ${styles.personaPremiumTypeItem}`}>
                                                                                                                    {persona.persona_type_name}
                                                                                                                </Grid>
                                                                                                            </Tooltip>

                                                                                                        ) : (
                                                                                                            <Grid item className={`${persona.persona_type_name === "Brand" ? styles.personaBrandTypeLabel : styles.personaIndividualTypeLabel} ${styles.personaTypeItem}`}>
                                                                                                                {persona.persona_type_name}
                                                                                                            </Grid>
                                                                                                        )
                                                                                                    }
                                                                                                    &nbsp;&nbsp;
                                                                                                    {persona.persona_premium_flag &&
                                                                                                        <img src={PremiumLogo} alt="" />
                                                                                                    }
                                                                                                </Typography>
                                                                                            </Grid>

                                                                                            <Grid item className={styles.pcProfileItem}>
                                                                                                <Grid container>
                                                                                                    <Grid item xs={12}>
                                                                                                        {/* USER CREATED PERSONA */}
                                                                                                        {
                                                                                                            (persona.persona_type_name == "Individual" ?
                                                                                                                <>
                                                                                                                    <img
                                                                                                                        alt=""
                                                                                                                        src={persona.persona_avatar_full_size_url ?? persona.persona_avatar_url ?? personaAvatar}
                                                                                                                        className={styles.personaAvtarProfileImage}
                                                                                                                    />
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    <img
                                                                                                                        alt=""
                                                                                                                        src={brandAvatar}
                                                                                                                        className={styles.personaAvtarProfileImage}
                                                                                                                    />
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} className={styles.pcUserNameLabelItem}>
                                                                                                        <Typography component={'span'} className={styles.pcUserNameLabel}
                                                                                                            sx={{
                                                                                                                whiteSpace: "nowrap",
                                                                                                                overflow: "hidden",
                                                                                                                textOverflow: "ellipsis",
                                                                                                            }}
                                                                                                        >
                                                                                                            {persona.persona_name}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} className={styles.pcUserPostLabelItem}>
                                                                                                        <Typography component={'span'} className={styles.pcUserPostLabel}
                                                                                                            sx={{
                                                                                                                whiteSpace: "nowrap",
                                                                                                                overflow: "hidden",
                                                                                                                textOverflow: "ellipsis",
                                                                                                            }}
                                                                                                        >
                                                                                                            {persona.persona_display_name}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    {persona?.persona_tone_of_voice != '""' &&
                                                                                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={personaStyles.personaconcise}>
                                                                                                            <Grid container className={personaStyles.personacontainerToneCard}>
                                                                                                                {JSON.parse(persona?.persona_tone_of_voice)?.map((tone: string) => (
                                                                                                                    <Grid item className={personaStyles.personaToneCard}>
                                                                                                                        {tone}
                                                                                                                    </Grid>
                                                                                                                ))}
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </>
                                                                            ) : (
                                                                                <GlobalTooltip key={persona.persona_id} option={persona} placement={"right-start"}>
                                                                                    <Grid item className={persona.user_default_persona_data.length > 0 ? `${styles.personaDefaultStyle} ${styles.personaCardMainItem}` : styles.personaCardMainItem} onClick={() => { handleGetPersonaDetails(persona.persona_id, persona.persona_premium_flag) }}>
                                                                                        <Grid container>
                                                                                            <Grid item className={persona.persona_type_name == "Brand" ? styles.pcBrandProfileItem : styles.pcIndividualProfileItem}>
                                                                                                <Typography component={'span'} className={styles.pcUserTagNameText} sx={{ padding: "0px 0px 5px" }}>
                                                                                                    {persona.persona_premium_flag ?
                                                                                                        (
                                                                                                            <Tooltip
                                                                                                                arrow
                                                                                                                title={
                                                                                                                    <>
                                                                                                                        <Grid container direction="column">
                                                                                                                            Only available in Paid Plans
                                                                                                                        </Grid>
                                                                                                                    </>
                                                                                                                }
                                                                                                            >
                                                                                                                <Grid item className={`${persona.persona_type_name === "Brand" ? styles.personaBrandTypeLabel : styles.personaIndividualTypeLabel} ${styles.personaPremiumTypeItem}`}>
                                                                                                                    {persona.persona_type_name}
                                                                                                                </Grid>
                                                                                                            </Tooltip>

                                                                                                        ) : (
                                                                                                            <Grid item className={`${persona.persona_type_name === "Brand" ? styles.personaBrandTypeLabel : styles.personaIndividualTypeLabel} ${styles.personaTypeItem}`}>
                                                                                                                {persona.persona_type_name}
                                                                                                            </Grid>
                                                                                                        )
                                                                                                    }
                                                                                                    &nbsp;&nbsp;
                                                                                                    {persona.persona_premium_flag &&
                                                                                                        <img src={PremiumLogo} alt="" />
                                                                                                    }
                                                                                                </Typography>
                                                                                            </Grid>

                                                                                            <Grid item className={styles.pcProfileItem}>
                                                                                                <Grid container>
                                                                                                    <Grid item xs={12}>
                                                                                                        {/* USER CREATED PERSONA */}
                                                                                                        {(persona.persona_type_name == "Individual") ?
                                                                                                            <>
                                                                                                                <img
                                                                                                                    alt=""
                                                                                                                    src={persona.persona_avatar_full_size_url ?? persona.persona_avatar_url ?? personaAvatar}
                                                                                                                    className={styles.personaAvtarProfileImage}
                                                                                                                />
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <img
                                                                                                                    alt=""
                                                                                                                    src={brandAvatar}
                                                                                                                    className={styles.personaAvtarProfileImage}
                                                                                                                />
                                                                                                            </>
                                                                                                        }
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} className={styles.pcUserNameLabelItem}>
                                                                                                        <Typography component={'span'} className={styles.pcUserNameLabel}
                                                                                                            sx={{
                                                                                                                whiteSpace: "nowrap",
                                                                                                                overflow: "hidden",
                                                                                                                textOverflow: "ellipsis",
                                                                                                            }}
                                                                                                        >
                                                                                                            {persona.persona_name}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} className={styles.pcUserPostLabelItem}>
                                                                                                        <Typography component={'span'} className={styles.pcUserPostLabel}
                                                                                                            sx={{
                                                                                                                whiteSpace: "nowrap",
                                                                                                                overflow: "hidden",
                                                                                                                textOverflow: "ellipsis",
                                                                                                            }}
                                                                                                        >
                                                                                                            {persona.persona_display_name}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    {persona?.persona_tone_of_voice != '""' &&
                                                                                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={personaStyles.personaconcise}>
                                                                                                            <Grid container className={personaStyles.personacontainerToneCard}>
                                                                                                                {JSON.parse(persona?.persona_tone_of_voice)?.map((tone: string) => (
                                                                                                                    <Grid item className={personaStyles.personaToneCard}>
                                                                                                                        {tone}
                                                                                                                    </Grid>
                                                                                                                ))}
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </GlobalTooltip>
                                                                            )

                                                                        ) : (
                                                                            (persona.persona_flag_public == true) ? ("") :
                                                                                (
                                                                                    <Grid item className={personaStyles.personaListNoData}>
                                                                                        No Data Found
                                                                                    </Grid>
                                                                                )
                                                                        )}
                                                                </>
                                                            )
                                                        })}
                                                </>
                                            }

                                            {trueCount != 0 &&
                                                <>
                                                    <Grid item className={`${personaStyles.createdPersonaTypeText} ${styles.pcLabelItems}`}>
                                                        System Created Persona
                                                    </Grid>

                                                    {
                                                        // personaListData != null &&
                                                        personaListData.map((persona: GetPersonaListDataModel) => {
                                                            return (
                                                                <>
                                                                    {(persona.persona_flag_public == true) ?
                                                                        (isSmallScreen ?
                                                                            (
                                                                                <>
                                                                                    <Grid item className={persona.user_default_persona_data.length > 0 ? `${styles.personaDefaultStyle} ${styles.personaCardMainItem}` : styles.personaCardMainItem} onClick={() => { handleGetPersonaDetails(persona.persona_id, persona.persona_premium_flag) }}>
                                                                                        <Grid container>
                                                                                            <Grid item className={persona.persona_type_name == "Brand" ? styles.pcBrandProfileItem : styles.pcIndividualProfileItem}>
                                                                                                <Typography component={'span'} className={styles.pcUserTagNameText} sx={{ padding: "0px 0px 5px" }}>
                                                                                                    {persona.persona_premium_flag ?
                                                                                                        (
                                                                                                            <Tooltip
                                                                                                                arrow
                                                                                                                title={
                                                                                                                    <>
                                                                                                                        <Grid container direction="column">
                                                                                                                            Only available in Paid Plans
                                                                                                                        </Grid>
                                                                                                                    </>
                                                                                                                }
                                                                                                            >
                                                                                                                <Grid item className={`${persona.persona_type_name === "Brand" ? styles.personaBrandTypeLabel : styles.personaIndividualTypeLabel} ${styles.personaPremiumTypeItem}`}>
                                                                                                                    <Typography component={'span'}>
                                                                                                                        {persona.persona_type_name}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </Tooltip>
                                                                                                        ) : (
                                                                                                            <Grid item className={`${persona.persona_type_name === "Brand" ? styles.personaBrandTypeLabel : styles.personaIndividualTypeLabel} ${styles.personaTypeItem}`}>
                                                                                                                {persona.persona_type_name}
                                                                                                            </Grid>
                                                                                                        )
                                                                                                    }
                                                                                                    &nbsp;&nbsp;
                                                                                                    {persona.persona_premium_flag &&
                                                                                                        <img src={PremiumLogo} alt="" />
                                                                                                    }

                                                                                                </Typography>
                                                                                            </Grid>

                                                                                            <Grid item className={styles.pcProfileItem}>
                                                                                                <Grid container>
                                                                                                    <Grid item xs={12}>
                                                                                                        {(persona.persona_type_name == "Individual") ?
                                                                                                            <>
                                                                                                                <img
                                                                                                                    alt=""
                                                                                                                    src={persona.persona_avatar_full_size_url ?? persona.persona_avatar_url ?? personaAvatar}
                                                                                                                    className={styles.personaAvtarProfileImage}
                                                                                                                />
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <img
                                                                                                                    alt=""
                                                                                                                    src={brandAvatar}
                                                                                                                    className={styles.personaAvtarProfileImage}
                                                                                                                />
                                                                                                            </>
                                                                                                        }
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} className={styles.pcUserNameLabelItem}>
                                                                                                        <Typography component={'span'} className={styles.pcUserNameLabel}
                                                                                                            sx={{
                                                                                                                whiteSpace: "nowrap",
                                                                                                                overflow: "hidden",
                                                                                                                textOverflow: "ellipsis",
                                                                                                            }}
                                                                                                        >
                                                                                                            {persona.persona_name}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} className={styles.pcUserPostLabelItem}>
                                                                                                        <Typography component={'span'} className={styles.pcUserPostLabel}
                                                                                                            sx={{
                                                                                                                whiteSpace: "nowrap",
                                                                                                                overflow: "hidden",
                                                                                                                textOverflow: "ellipsis",
                                                                                                            }}
                                                                                                        >
                                                                                                            {persona.persona_display_name}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    {persona?.persona_tone_of_voice != '""' &&
                                                                                                        <Grid item xs={12} className={personaStyles.personaconcise}>
                                                                                                            <Grid container className={personaStyles.personacontainerToneCard}>
                                                                                                                {JSON.parse(persona?.persona_tone_of_voice)?.map((tone: string) => (
                                                                                                                    <Grid item className={personaStyles.personaToneCard}>
                                                                                                                        {tone}
                                                                                                                    </Grid>
                                                                                                                ))}
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </>
                                                                            ) : (
                                                                                <GlobalTooltip option={persona} key={persona.persona_id} placement={isSmallScreen ? "" : "right-start"}>
                                                                                    <Grid item className={persona.user_default_persona_data.length > 0 ? `${styles.personaDefaultStyle} ${styles.personaCardMainItem}` : styles.personaCardMainItem} onClick={() => { handleGetPersonaDetails(persona.persona_id, persona.persona_premium_flag) }}>
                                                                                        <Grid container>
                                                                                            <Grid item className={persona.persona_type_name == "Brand" ? styles.pcBrandProfileItem : styles.pcIndividualProfileItem}>
                                                                                                <Typography component={'span'} className={styles.pcUserTagNameText} sx={{ padding: "0px 0px 5px" }}>
                                                                                                    {persona.persona_premium_flag ?
                                                                                                        (
                                                                                                            <Tooltip
                                                                                                                arrow
                                                                                                                title={
                                                                                                                    <>
                                                                                                                        <Grid container direction="column">
                                                                                                                            Only available in Paid Plans
                                                                                                                        </Grid>
                                                                                                                    </>
                                                                                                                }
                                                                                                            >
                                                                                                                <Grid item className={`${persona.persona_type_name === "Brand" ? styles.personaBrandTypeLabel : styles.personaIndividualTypeLabel} ${styles.personaPremiumTypeItem}`}>
                                                                                                                    <Typography component={'span'}>
                                                                                                                        {persona.persona_type_name}
                                                                                                                    </Typography>
                                                                                                                </Grid>
                                                                                                            </Tooltip>
                                                                                                        ) : (
                                                                                                            <Grid item className={`${persona.persona_type_name === "Brand" ? styles.personaBrandTypeLabel : styles.personaIndividualTypeLabel} ${styles.personaTypeItem}`}>
                                                                                                                {persona.persona_type_name}
                                                                                                            </Grid>
                                                                                                        )
                                                                                                    }
                                                                                                    &nbsp;&nbsp;
                                                                                                    {persona.persona_premium_flag &&
                                                                                                        <img src={PremiumLogo} alt="" />
                                                                                                    }

                                                                                                </Typography>
                                                                                            </Grid>

                                                                                            <Grid item className={styles.pcProfileItem}>
                                                                                                <Grid container>
                                                                                                    <Grid item xs={12}>
                                                                                                        {(persona.persona_type_name == "Individual") ?
                                                                                                            <>
                                                                                                                <img
                                                                                                                    alt=""
                                                                                                                    src={persona.persona_avatar_full_size_url ?? persona.persona_avatar_url ?? personaAvatar}
                                                                                                                    className={styles.personaAvtarProfileImage}
                                                                                                                />
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                <img
                                                                                                                    alt=""
                                                                                                                    src={brandAvatar}
                                                                                                                    className={styles.personaAvtarProfileImage}
                                                                                                                />
                                                                                                            </>
                                                                                                        }
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} className={styles.pcUserNameLabelItem}>
                                                                                                        <Typography component={'span'} className={styles.pcUserNameLabel}
                                                                                                            sx={{
                                                                                                                whiteSpace: "nowrap",
                                                                                                                overflow: "hidden",
                                                                                                                textOverflow: "ellipsis",
                                                                                                            }}
                                                                                                        >
                                                                                                            {persona.persona_name}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} className={styles.pcUserPostLabelItem}>
                                                                                                        <Typography component={'span'} className={styles.pcUserPostLabel}
                                                                                                            sx={{
                                                                                                                whiteSpace: "nowrap",
                                                                                                                overflow: "hidden",
                                                                                                                textOverflow: "ellipsis",
                                                                                                            }}
                                                                                                        >
                                                                                                            {persona.persona_display_name}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    {persona?.persona_tone_of_voice != '""' &&
                                                                                                        <Grid item xs={12} className={personaStyles.personaconcise}>
                                                                                                            <Grid container className={personaStyles.personacontainerToneCard}>
                                                                                                                {JSON.parse(persona?.persona_tone_of_voice)?.map((tone: string) => (
                                                                                                                    <Grid item className={personaStyles.personaToneCard}>
                                                                                                                        {tone}
                                                                                                                    </Grid>
                                                                                                                ))}
                                                                                                            </Grid>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </GlobalTooltip>
                                                                            )

                                                                        ) : (
                                                                            (persona.persona_flag_public == false) ? ("") :
                                                                                (
                                                                                    <Grid item className={personaStyles.personaListNoData}>
                                                                                        No Data Found
                                                                                    </Grid>
                                                                                )
                                                                        )}
                                                                </>
                                                            )
                                                        })}
                                                </>
                                            }
                                        </>
                                    ) : (
                                        <Grid item xs={12} className={styles.noDataFoundItem}>
                                            <Grid container sx={{ display: "block", textAlign: "center" }}>
                                                <Grid item>
                                                    <img src={noDataFound} alt="" />
                                                </Grid>
                                                <Grid item>
                                                    <Typography component={'span'} sx={{ fontSize: "18px", fontWeight: "500" }}>
                                                        No Data Found!
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        // <Grid item className={personaStyles.personaListNoData}>
                                        //     No Data Found
                                        // </Grid>
                                    )
                                    : (<></>)
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Persona;