import * as React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { DocContext, DocContextPayload } from "../../context/docContext";
import { TemplateContext, TemplateContextPayload } from "../../context/templateContext";
import { ExecutePromptResponseDataModel, outputFormatObj } from "../../models/axiosModel";
import { flagCreditsExhausted, getErrorMessage, isDev } from "../../utils/constants";
import { UserContext, UserContextPayload } from "../../context/userContext";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import OptionListComponent from "./optionListComponent";
import FormPreview from "./generateTitleGlobalFormPreview";
import { Divider, Grid, Stack } from "@mui/material";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GlobalButton from "../utility/globalButton/button";
const drawerBleeding = 55;

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800]
}));

const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
    borderRadius: 3,
    position: "absolute",
    top: 8,
    left: "calc(50% - 15px)"
}));

function OptionListMobileComponent(props: any) {
    const { window } = props;
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };
    const { setError, error, setFlagShowCreditsExhausted, userDetail } = React.useContext(UserContext) as UserContextPayload;
    const { routeSources, outputFormatGenericList } = React.useContext(TemplateContext) as TemplateContextPayload;
    const { flagGenerateButtonDisabled, blogTitle, setBlogTitle, currentActiveDocDetails, UpdateDocTitle, UpdateDocInfo, setCurrentActiveDocDetails, ExecutePrompt, setAISuggestedTitles, aiSuggestedTitles, flagTextLoader,
        setFlagTextLoader, flagDebouncePending, flagGenerateTitleButtonClicked } = React.useContext(DocContext) as DocContextPayload;
    const [currentPage, setCurrentPage] = React.useState(0);

    const executePromptCall = async () => {
        setOpen(false);
        if (flagDebouncePending) {
          setFlagTextLoader(true)
          const payload = {
            document_uuid: currentActiveDocDetails !== null ? currentActiveDocDetails.document_uuid : "",
            prompt_id: 4,
            route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id ?? 2,
            output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
          }
          setTimeout(async () => {
            const response = await ExecutePrompt(payload);
            setFlagTextLoader(false)
            if (response.status) {
              if (response.data) {
                if (aiSuggestedTitles[0].title == "") {
                  setAISuggestedTitles(response.data)
                } else {
                  // setCurrentPage(currentPage + 1)
                  setCurrentPage(aiSuggestedTitles.length / 5) //to go to the latest suggestions
                  setAISuggestedTitles([...aiSuggestedTitles, ...response.data])
                }
              }
            } else {
              let errorCode = response.isAxiosError ? (response.response && response.response.data ? (response?.response?.data?.error?.code || response?.data?.error?.code || response) : response) : response?.error?.code
              if (flagCreditsExhausted(errorCode)) {
                setFlagShowCreditsExhausted(true)
              } else {
                setError({
                  ...error,
                  flagShowError: true,
                  message: getErrorMessage(errorCode, isDev(userDetail))
                });
              }
            }
          }, 1500)
    
        } else {
          setFlagTextLoader(true)
          const payload = {
            document_uuid: currentActiveDocDetails !== null ? currentActiveDocDetails.document_uuid : "",
            prompt_id: 4,
            route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id ?? 2,
            output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
          }
          const response = await ExecutePrompt(payload);
          setFlagTextLoader(false)
          if (response.status) {
            if (response.data) {
              if (aiSuggestedTitles[0].title == "") {
                setAISuggestedTitles(response.data)
              } else {
                // setCurrentPage(currentPage + 1)
                setCurrentPage(aiSuggestedTitles.length / 5) //to go to the latest suggestions
                setAISuggestedTitles([...aiSuggestedTitles, ...response.data])
              }
            }
          }
          else {
            let errorCode = response.isAxiosError ? (response.response && response.response.data ? (response?.response?.data?.error?.code || response?.data?.error?.code || response) : response) : response?.error?.code
            if (flagCreditsExhausted(errorCode)) {
              setFlagShowCreditsExhausted(true)
            } else {
              setError({
                ...error,
                flagShowError: true,
                message: getErrorMessage(errorCode, isDev(userDetail))
              });
            }
          }
        }
    
      }
    // This is used only for the example
    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            {/* <CssBaseline /> */}
            <Global
                styles={{}}
            // styles={{
            //     ".MuiDrawer-root .MuiPaper-root": {
            //         height: `calc(80% - ${drawerBleeding}px)`,
            //         overflow: "visible"
            //     }
            // }}
            />
            <Box>
                <OptionListComponent />
                <Stack pb={1} style={{position:'fixed', left:"0", right:"0" ,bottom:'60px', display:'flex', alignItems:"center",justifyContent:"center"}} sx={{backgroundColor: "#FDF8FF !important"}}>
                <KeyboardArrowDownIcon onClick={toggleDrawer(true)} style={{transform: 'rotate(180deg)'}}/>
                <Typography variant="body1" style={{fontSize:"14px", fontWeight:"500"}}>Context Form</Typography>
                    {/* <GlobalButton
                        buttonText={"Context Form"}
                        sx={{backgroundColor: 'transparent !important'}}
                        className="secondaryButtonStyle"
                        ></GlobalButton> */}
                </Stack>
            </Box>
            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                // disableSwipeToOpen={false}
                disableSwipeToOpen={true}

                ModalProps={{
                    keepMounted: true
                }}
                sx={{ display: { xs: "block", md: "none" } }}
            >{open ?

                <StyledBox
                    sx={{
                        position: "absolute",
                        top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        borderBottom: "1px solid #f7e9ff",
                        visibility: "visible",
                        right: 0,
                        left: 0
                    }}
                >
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            <Typography sx={{ padding: "15px 0px", color: "text.secondary", textAlign: "center" }}>
                                <KeyboardArrowDownIcon sx={{ verticalAlign: "text-top" }} onClick={() => { toggleDrawer(false) }} /> Context form
                            </Typography>
                        </Grid>
                        <Grid item>
                            <GlobalButton
                                buttonText="Regenerate Titles"
                                className="secondaryButtonStyle"
                                flagGenerate
                                onClick={() => { executePromptCall() }}
                                // onClick={() => { console.log("kkkkkkkkkkk") }}
                            />
                        </Grid>
                    </Grid>
                </StyledBox>
                :
                <></>
                    // <StyledBox
                    //     sx={{
                    //         position: "absolute",
                    //         top: -drawerBleeding,
                    //         borderTopLeftRadius: 8,
                    //         borderTopRightRadius: 8,
                    //         backgroundColor: "#f7e9ff",
                    //         visibility: "visible",
                    //         right: 0,
                    //         left: 0
                    //     }}
                    // >
                    //     <div style={{ textAlign: "center" }}>
                    //         <KeyboardArrowUpOutlinedIcon />
                    //     </div>
                    //     <Typography sx={{ paddingBottom: "10px", color: "text.secondary", textAlign: "center" }}>
                    //         Context form
                    //     </Typography>
                    // </StyledBox>
                }
                    <Grid container justifyContent={"space-between"} alignItems={"center"} p={2}>
                        <Grid item style={{display:'flex', alignItems:"center"}}>
                            <KeyboardArrowDownIcon onClick={toggleDrawer(false)}/>
                            <Typography variant="body1" style={{fontSize:"14px", fontWeight:"500"}}>Context Form</Typography>
                        </Grid>
                        <Grid item>
                            <GlobalButton
                                buttonText="Regenerate Titles"
                                className="secondaryButtonStyle"
                                flagGenerate
                                onClick={() => { executePromptCall() }}
                            />
                        </Grid>
                    </Grid>
                    <Divider />
                    <StyledBox
                        sx={{
                            px: 2,
                            pb: 2,
                            height: "100%",
                            overflow: "auto"
                        }}
                    >
                    <FormPreview
                        buttonText="Generate Titles"
                    // flagShowGenerateTitlesButton={flagShowGenerateTitlesButton}
                    />
                </StyledBox>
            </SwipeableDrawer>
        </>
    );
}

OptionListMobileComponent.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func
};

export default OptionListMobileComponent;
