import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import {
    CommonResponseModel,
    CommonResponseSecondaryModel,
    getChatbotIntegrationDataResponseModel,
    getMetaCredentialsDataResponseModel,
} from "../models/axiosModel";
import agent from "../services/api";

const MetaContext = React.createContext<MetaContextPayload | undefined>(
    undefined
);

export interface MetaContextPayload {
    metaCredentials: null | getMetaCredentialsDataResponseModel;
    setMetaCredentials: React.Dispatch<React.SetStateAction<getMetaCredentialsDataResponseModel | null>>;
    getChatbotIntegrationData: null | getChatbotIntegrationDataResponseModel[];
    setGetChatbotIntegrationData: React.Dispatch<React.SetStateAction<getChatbotIntegrationDataResponseModel[] | null>>;
    GetMetaCredentials: () => Promise<CommonResponseSecondaryModel<getMetaCredentialsDataResponseModel>>;
    GetChatbotIntegrationData: (chatbot_id: string, chatbot_integration_id: string) => Promise<CommonResponseModel<getChatbotIntegrationDataResponseModel>>;
    showIntegrationDetails: boolean,
    setShowIntegrationDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

const MetaProvider = ({ children }: ChildrenProps) => {
    const [metaCredentials, setMetaCredentials] = useState<getMetaCredentialsDataResponseModel | null>(null);
    const [getChatbotIntegrationData, setGetChatbotIntegrationData] = useState<getChatbotIntegrationDataResponseModel[] | null>(null)
    const [showIntegrationDetails, setShowIntegrationDetails] = useState(false)

    const GetMetaCredentials = async () => {
        const response: CommonResponseSecondaryModel<getMetaCredentialsDataResponseModel> = await agent.getMetaCredentials.get();
        if (response.status && response.data) {
            setMetaCredentials(response.data)
        } else {
            setMetaCredentials(null)
        }
        return response;
    }
    const GetChatbotIntegrationData = async (chatbot_id: string, chatbot_integration_id: string) => {
        const response: CommonResponseModel<getChatbotIntegrationDataResponseModel> = await agent.getChatbotIntegrationData.get(chatbot_id, chatbot_integration_id);
        if (response.status && response.data) {
            if (response.data.length > 0) {
                setGetChatbotIntegrationData(response.data)
            } else {
                setGetChatbotIntegrationData(null)
            }
        } else {
            setGetChatbotIntegrationData(null)
        }
        return response;
    }

    const payload = {
        metaCredentials,
        setMetaCredentials,
        getChatbotIntegrationData,
        setGetChatbotIntegrationData,
        GetMetaCredentials,
        GetChatbotIntegrationData,
        showIntegrationDetails,
        setShowIntegrationDetails
    };

    return (
        <MetaContext.Provider value={payload}>{children}</MetaContext.Provider>
    );
};

export { MetaContext, MetaProvider };
