import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes as RouterRoutes, useNavigate } from "react-router-dom";
import { UserContext, UserContextPayload } from "../context/userContext";
import DocComponent from "../components/pages/document";
import HomeComponent from "../components/dashboard/home";
import Login from "../components/pages/login";
import Registration from "../components/pages/registration";
import TermsAndConditions from "../components/pages/terms&condition";
import PrivacyCookiePolicy from "../components/pages/privacy&Cookie";
import GlobalSnackbar from "../components/utility/globalSnackbar";
import Dashboard from "../components/dashboard/dashboard";
import Template from "../components/dashboard/template";
import Documents from "../components/dashboard/documents";
import Settings from "../components/dashboard/settings";
import { DocContext, DocContextPayload } from "../context/docContext";
import { documentStepDataModel, loaderFilterdetails } from "../models/axiosModel";
import Persona from "../components/dashboard/persona/personaList";
import CreatePersona from "../components/dashboard/persona/createPersona";
import ViewPersonaDetails from "../components/dashboard/persona/viewPersonaDetails";
import UpdatePersona from "../components/dashboard/persona/updatePersona";
import { NotifyExtentionContext } from "../context/notifyExtentionContext";
import { TemplateContext, TemplateContextPayload } from "../context/templateContext";
import SomethingWentWrong from "../components/pages/somethingWentWrong";
import Campaigns from "../components/dashboard/Campaigns/Campaigns";
import CampaignCreateUpdateView from "../components/dashboard/Campaigns/CampaignCreateUpdateView";
import ChatbotList from "../components/dashboard/chatbot/chatbotList";
import CreateChatbot from "../components/dashboard/chatbot/createChatbot";
import { ViewChatbot } from "../components/dashboard/chatbot/viewChatbot";
import Welcome from "../components/dashboard/welcome";
import axios from "axios";
import { resizeFunction } from "../components/dashboard/paraphraser/paraphrasing";
import Paraphraser from "../components/dashboard/paraphraser/paraphraserMainComponent";
import { ParaphraseContext, ParaphraseContextPayload } from "../context/paraphraseContext";
import { GenericDataContext, GenericDataContextPayload } from "../context/genericDataContext";
import GlobalPopUp from "../components/utility/globalPopUp";
import PlanHasExhausted from "../components/popupFiles/planHasExhausted";
import GetStartedPopUpData from "../components/utility/getStartedPopUpData";
import GlobalRoundLoader from "../components/utility/globalRoundLoader";
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, isDev } from "../utils/constants";
import CommonErrorPage from "../utils/commonErrorPage";
import CustomTemplate from "../components/dashboard/customTemplate/customTemplate";
import Notes from "../components/dashboard/notes/notes";
import CreateUpdateNote from "../components/dashboard/notes/createUpdateNote";
import ChatbotLandingPage from "../components/dashboard/chatbot/chatbotLandingPage";

const Routes = () => {
  const { UserMe, setFlagChromeDesktop, getLanguagesList, flagLoading, error, success, setError, showInfo, setShowInfo, setSuccess, setCreditUsed, userDetail, setFlagLoading, flagOnboardingSkip, setFlagOnboardingSkip, GetStripeCurrencyTypeGenericList, GetPromptLogFieldGenericList, flagShowCreditsExhausted, setFlagShowCreditsExhausted, showLoginPopup, setShowLoginPopup, setLoaderDetails, loaderDetails, setLoaderFilterDetails, getLoaderDetails, setUserDetail } = useContext(
    UserContext
  ) as UserContextPayload;
  const { GetPromptList, GetRewriteToneGenericList, GetRouteSourceList, GetOutputFormatGenericList } = useContext(TemplateContext) as TemplateContextPayload
  const { GetPopupList } = useContext(GenericDataContext) as GenericDataContextPayload;
  const { SendPostMessageToExtention } = useContext(
    NotifyExtentionContext
  ) as any;
  const { setWebSocketObj, setDocumentOutlineData, webSocketObj, setFlagOutlineLoader, setCurrentActiveStep, setDocumentEditorData, setTabValuePanel } = useContext(DocContext) as DocContextPayload;
  const { getDialectList, setFlagNeverShowup, setPopupActionData, setParaphraseVersioningarr, paraphrasingVersionIndex, setHasParaphras, setCurrentIndex, setParaphrasingVersionIndex } = useContext(ParaphraseContext) as ParaphraseContextPayload
  const paraphraseContextValue = useContext(ParaphraseContext) as ParaphraseContextPayload
  const [flagUserMeCompleted, setFlagUserMeCompleted] = useState(false);
  const navigate = useNavigate()
  const paraphraseContextRef = useRef<ParaphraseContextPayload>()
  useEffect(() => {
    paraphraseContextRef.current = paraphraseContextValue
  }, [paraphraseContextValue])
  useEffect(() => {

    const searchParams = new URLSearchParams(window.location.search);
    if (!userDetail.flagUser || userDetail.flag_user_anonymous) {
      if (searchParams.has('chatbot_id')) {
        let sampleChatbotPath = window.location.pathname + window.location.search
        let url = encodeURIComponent(sampleChatbotPath)
        navigate("/dashboard/chatbot?redirect=" + url);
      }
    } else {
      if (userDetail.plan_detail !== null && userDetail.plan_detail.credit_usage_data !== null && userDetail.plan_detail?.credit_usage_data?.stripe_product_name === "Premium" || userDetail.user_role.user_role_name === "Developer" || userDetail.user_role.user_role_name === "Admin") {
        if (searchParams.has('redirect')) {
          let url = searchParams.get('redirect') ?? window.location.pathname + window.location.search
          let urlToNavigate = decodeURIComponent(url)
          navigate(urlToNavigate);
        }
      }
    }
  }, [userDetail])
  // to reload app once tab is not active for half an hour
  useEffect(() => {
    let hiddenTime: number;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        hiddenTime = Date.now();
      } else if (document.visibilityState === 'visible') {
        const currentTime = Date.now();
        if (hiddenTime && (currentTime - hiddenTime > 1800000)) { // 600000 ms = 10 minutes
          window.location.reload(); // Reload the page
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [])

  // for userMe call
  useEffect(() => {
    (async () => {
      const response = await UserMe();
      if (response.status && response.data) {
        setFlagNeverShowup(response.data[0].do_not_show_flag)
        setPopupActionData(response.data[0].user_popup_actions)
      }
      setFlagUserMeCompleted(true);
      localStorage.setItem('testCase', 'Testing storing in local storage');

    })();
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isEdge = /Edg/.test(userAgent);
    const isMobile = /Mobi/.test(userAgent);
    if (isMobile) {
      setFlagChromeDesktop(false)
    } else {
      if (isEdge) {
        // Your Edge-specific code here
        setFlagChromeDesktop(false)
      } else if (/Chrome/.test(userAgent)) {
        setFlagChromeDesktop(true)
        // Your Chrome-specific code here
      } else {
        setFlagChromeDesktop(false)
        // Your code for other browsers here
      }
    }
  }, []); // Empty dependency array because navigator.userAgent is constant

  useEffect(() => {
    // generic calls
    GetOutputFormatGenericList()
    getLanguagesList();
    GetRewriteToneGenericList();
    getDialectList();
    GetStripeCurrencyTypeGenericList()
    GetRouteSourceList();
    GetPromptLogFieldGenericList();
    GetPopupList();
  }, [])
  useEffect(() => {
    if (userDetail.flagUser) {
      setPopupActionData(userDetail.user_popup_actions);

      (async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/get-user-additional-information-data`);
          if (response.status) {
            const userAdditionalInfo = response?.data?.data;
            // ******************* FOR ACCESS AFTER 24 HRS CODE *****************************
            let userCreatedTime = userDetail.user_registered_timestamp;
            let creationTime = new Date(userCreatedTime as string);
            const timeDifference = new Date().getTime() - creationTime.getTime();
            const is24HoursPassed = timeDifference > 24 * 60 * 60 * 1000;
            //  ******************* FOR ACCESS AFTER 24 HRS CODE *****************************
            // if (is24HoursPassed && (!userAdditionalInfo.length || userAdditionalInfo[userAdditionalInfo.length - 1].user_additional_information_data_value === "")) {
            //   setFlagOnboardingSkip(false);
            // }
            // else {
            //   setFlagOnboardingSkip(true);
            // }
          }
          GetPromptList();
          let tempUserDetail = {
            flaguserAnonymous: userDetail.flag_user_anonymous,
            userfirstName: userDetail.user_first_name,
            userLastName: userDetail.user_last_name,
            userFullName: ` ${userDetail.user_first_name} ${userDetail.user_last_name}`,
            userStatus: userDetail.flagUser
          }
          SendPostMessageToExtention(tempUserDetail)
        }
        catch (error) {
          // console.log(error);
        }
        if (userDetail.flagUserFromParaphrase) {
        } else {
          setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
        // const response = await axios.put("https://api.writewiz-staging.in/api/user/add-user-additional-information-data", { user_additional_information_data: [{user_additional_information_id: 5, user_additional_information_data_value: ""}, {user_additional_information_id: 6, user_additional_information_data_value: ""}] });
      })()
    }
  }, [userDetail]);

  // useeffect for adding variable in mouseflow
  useEffect(() => {
    if (userDetail && userDetail.flagUser && process.env["REACT_APP_ENV"] === 'production') {
      if (userDetail.flag_user_anonymous) {
        // @ts-ignore
        window._mfq.push(["setVariable", "user_assignment_id", userDetail.user_assignment_id])
      } else {
        // console.log("script running")
        // @ts-ignore
        window._mfq.push(["setVariable", "user_email", userDetail.user_email])
      }
    }
  }, [userDetail])

  let pingInterval: any;
  // @ts-ignore
  const HandleWSMessage = (reponseWs: any) => {
    // @ts-ignore
    if (reponseWs.response.status) {
      if (reponseWs?.payload?.operation_id?.split("-")[0] == "prompt" && reponseWs?.payload?.operation_id?.split("-")[1] == "outline") {
        let updatedOutlineResponse = updateResponseAsRequired(reponseWs.response.data[0].document_step_data?.filter((step: documentStepDataModel) => step?.step_type_name == "Outline")[0].document_outline_data)
        setDocumentOutlineData(updatedOutlineResponse);
        setFlagOutlineLoader(false)
      } else if (reponseWs?.payload?.operation_id?.split("-")[0] == "prompt" && reponseWs?.payload?.operation_id?.split("-")[1] == "heading") {
        setCurrentActiveStep(2)
        let updatedOutlineResponse = updateResponseAsRequired(reponseWs.response.data[0].document_step_data?.filter((step: documentStepDataModel) => step?.step_type_name == "Outline")[0].document_outline_data)
        setDocumentOutlineData(updatedOutlineResponse);
        // second step
        navigate(`/doc/${reponseWs?.payload?.payload?.document_uuid}/2/${reponseWs?.response?.data[0]?.template_id}`)
        setFlagOutlineLoader(false)
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
      } else if (reponseWs?.payload?.operation_id?.split("-")[0] == "prompt" && reponseWs?.payload?.operation_id?.split("-")[1] == "blog") {
        setCurrentActiveStep(3)
        let updatedEditorResponse = reponseWs?.response?.data[0].document_step_data?.filter((step: documentStepDataModel) => step?.step_type_name == "Editor")[0].document_content_data[0]
        if (updatedEditorResponse) {
          let finalState: any = {
            root: updatedEditorResponse
          }
          setDocumentEditorData(finalState);
        }
        // third step
        setTabValuePanel(1)
        navigate(`/doc/${reponseWs?.payload?.payload?.document_uuid}/3/${reponseWs?.response?.data[0]?.template_id}`)
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
      } else if (reponseWs?.payload?.operation_id?.split("-")[0] == "prompt" && reponseWs?.payload?.operation_id?.split("-")[1] == "paraphrase") {
        if (Array.isArray(reponseWs.response.data)) {
          // @ts-ignore
          let temp = socketAPIBuffer.filter((api) => api.operation_id == reponseWs.operation_id && api.flagCancel)
          if (temp.length > 0) {
          } else {
            if (paraphraseContextRef.current) {
              const { paraphrasingVersionIndex, setParaphraseVersioningarr, setCurrentIndex, setParaphrasingVersionIndex, setHasParaphras } = paraphraseContextRef.current;

              let response = reponseWs.response
              let temp = response.data.map((responseObj: any, index: number) => {
                return {
                  id: index + 1,
                  index: 0,
                  value: [
                    {
                      id: index + 1,
                      paraphrase: responseObj[response.prompt_output_key_name],
                      checked: false,
                      flagEditable: false,
                      flagLoading: false,
                      flagOpenMenu: false,
                      anchorEl: null,
                      showTooltip: false,
                      showCardTooltip: false
                    }
                  ]
                }
              })
              setHasParaphras(true)
              resizeFunction()
              let paraphrasingVersioningLength = 0
              setParaphraseVersioningarr((prevState: any, index: number) => {
                const updatedArray = prevState.map((item: any, index: any) =>
                  index === paraphrasingVersionIndex
                    ? { ...item, executePromptResponseParaphrase: temp, id: index + 1 }
                    : item
                );
                paraphrasingVersioningLength = prevState.length
                const lastObject = { ...prevState[prevState.length - 1], paraphrasingInput: reponseWs.payload.payload.phrase, executePromptResponseParaphrase: temp, id: prevState.length };
                return [...updatedArray, lastObject];
              });
              const container = document.getElementById('stickyBottom')
              if (container) {
                setTimeout(() => {
                  container.scrollIntoView({ behavior: 'smooth' });
                }, 400)
              }
              // setCurrentIndex(paraphrasingVersioningLength)
              setCurrentIndex((prevState) => { return paraphrasingVersioningLength })
              setParaphrasingVersionIndex((prevState) => { return paraphrasingVersioningLength })
            }
          }
          // @ts-ignore
          socketAPIBuffer = socketAPIBuffer.filter((api) => Number(api.operation_id.split("-")[2]) >= Number(reponseWs.operation_id.split("-")[2]))
        } else {
          let errorCode = 1110
          setError({
            ...error,
            flagShowError: true,
            message: getErrorMessage(errorCode, isDev(userDetail))
          });
        }

        setFlagLoading((prevState) => {
          return {
            ...prevState,
            flagContentGenerating: false,
            flagRoundLoader: false
          }
        })
      }
    } else {
      // @ts-ignore
      let temp = socketAPIBuffer.filter((api) => api.operation_id == reponseWs.operation_id && api.flagCancel)
      if (temp.length > 0) {
      } else {
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        setFlagOutlineLoader(false)
        setHasParaphras(false)
        let errorCode = checkForErrorCode(reponseWs.response)
        if (flagCookieExpired(userDetail, errorCode)) {
          setUserDetail(emptyUserDetail)
          if (userDetail.flag_user_anonymous) {
            let url = window.location.pathname
            navigate(url, { replace: true });
          } else {
            setError({
              ...error,
              flagShowError: true,
              message: "Session expired! Please login to continue."
            });
            setTimeout(() => {
              let url = encodeURIComponent(window.location.href)
              navigate("/login?redirect=" + url, { replace: true });
            }, 1500);
          }
        } else {
          if (flagCreditsExhausted(errorCode)) {
            setFlagShowCreditsExhausted(true)
          } else {
            setError({
              ...error,
              flagShowError: true,
              message: getErrorMessage(errorCode, isDev(userDetail))
            });
          }
        }
      }
    }
  }

  function updateResponseAsRequired(data: any) {
    for (const item of data) {
      item['unique_id'] = item.document_outline_id
      delete item.document_outline_level_num
      delete item.document_outline_seq_num

      if (item?.children && item.children.length > 0) {
        updateResponseAsRequired(item.children);
      }
    }
    return data
  }


  useEffect(() => {
    const socketUrl: any = process.env.REACT_APP_SOCKET_URL;
    let webSocket: any;

    const createWebSocket = () => {
      webSocket = new WebSocket(socketUrl);
      setWebSocketObj(webSocket);

      webSocket.onopen = (event: any) => {
        pingInterval = setInterval(() => {
          if (webSocket.readyState === WebSocket.OPEN) {
            webSocket.send("Keep Alive...");
          }
        }, 10000);
      };

      webSocket.onmessage = async (event: any) => {
        const wsResponse = JSON.parse(event.data);
        if (wsResponse) {
          if (wsResponse?.payload?.url === "credit/get-user-credit-usage-data") {
            const temp = {
              remaining_credit_usage: wsResponse.response.user_monthly_credit_data.remaining_credit_usage,
              total_credit_usage: wsResponse.response.user_monthly_credit_data.total_credit_usage,
            };
            setCreditUsed(temp);
          } else if (wsResponse?.payload?.url === "ai/ask-ai") {
            const reponseWs = JSON.parse(event.data);
            HandleWSMessage(reponseWs);
          } else {
            setCreditUsed("");
          }
        }
      };

      webSocket.onclose = (event: any) => {
        clearInterval(pingInterval);
        pingInterval = null;

        // Reopen the WebSocket if it wasn't a user-initiated close
        if (event.code !== 1000 && event.code !== 1008) {
          createWebSocket();
        }
      };
    };

    if (socketUrl) {
      createWebSocket();
    }

    return () => {
      if (webSocket) {
        webSocket.close();
      }
    };
  }, []);
  // seo keywords
  const fetchMetaKeywords = async () => {

    // https://api.writewiz-staging.in/api/global/seo-keywords
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/global/seo-keywords`);
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error('Failed to fetch data from the API');
      }
    } catch (error) {
      return null;
    }
  };
  const updateMetaKeywords = () => {
    fetchMetaKeywords()
      .then((data) => {
        if (data && data.status) {
          let seoData = data.data[0]
          if (window.location.pathname.indexOf('Paraphraser') > -1 || window.location.pathname.indexOf('paraphraser') > -1) {
            seoData = data.data.filter((seo: any) => seo.flag_default == false)[0];
          } else {
            seoData = data.data.filter((seo: any) => seo.flag_default == true)[0];
          }
          const keywords = seoData.keywords;
          const metaKeywordsTag = document.querySelector('meta[name="keywords"]');

          if (metaKeywordsTag) {
            metaKeywordsTag.setAttribute('content', keywords);
          }
        }
      })
      .catch((error) => {
      });
  };
  useEffect(() => {
    updateMetaKeywords()
  }, [window.location.pathname])


  useEffect(() => {
    const fetchLoaderDetails = async () => {
      // debugger
      if (loaderDetails == null) {
        try {
          const response = await getLoaderDetails();
          if (response.status && response.data) {
            let loaderResponse = response.data[0];
            setLoaderDetails(loaderResponse)
          }
        }
        catch (error) {
          console.error("Error fetching loader details:", error);
        }
      }
    };

    fetchLoaderDetails();
  }, []);

  useEffect(() => {
    if (loaderDetails !== null) {
      if (userDetail.flag_user_anonymous == true) {
        const filteredData = loaderDetails.image_group_assignment_data.filter(item => {
          return item.loader_user_type_data && item.loader_user_type_data.some(userData => {
            return userData.loader_user_type_name === "Anonymous";
          });
        });
        let object: loaderFilterdetails = { index: -1, loaderDetails: [] }
        filteredData.map((ele, i) => {
          object.loaderDetails.push({ ...ele, loaderDetailsIndex: i - 1 })
        })
        setLoaderFilterDetails(object)
      }
      else {
        if (userDetail.plan_detail?.credit_usage_data !== null && userDetail.plan_detail?.credit_usage_data.stripe_product_name == null) {
          let filteredData = []
          if ((userDetail.user_role.user_role_name == "Developer" || userDetail.user_role.user_role_name == "Super Admin" || userDetail.user_role.user_role_name == "Admin")) {
            filteredData = loaderDetails.image_group_assignment_data.filter(item =>
              item?.loader_user_type_data && item?.loader_user_type_data?.some(userData => (userData.loader_user_type_name === "Free" || userData.loader_user_type_name === "Premium"))
            );
          } else {
            filteredData = loaderDetails?.image_group_assignment_data?.filter(item => item?.loader_user_type_data?.some(userData => userData.loader_user_type_name === "Anonymous"));
          }
          let object: loaderFilterdetails = { index: -1, loaderDetails: [] }
          filteredData.map((ele, i) => {
            object.loaderDetails.push({ ...ele, loaderDetailsIndex: i - 1 })
          })
          setLoaderFilterDetails(object)
        }
        else if (userDetail.user_role.user_role_name == "Developer" || userDetail.user_role.user_role_name == "Super Admin" || userDetail.user_role.user_role_name == "Admin") {
          const filteredData = loaderDetails.image_group_assignment_data.filter(item =>
            item?.loader_user_type_data && item?.loader_user_type_data?.some(userData => (userData.loader_user_type_name === "Free" || userData.loader_user_type_name === "Premium"))
          );
          let object: loaderFilterdetails = { index: -1, loaderDetails: [] }
          filteredData.map((ele, i) => {
            object.loaderDetails.push({ ...ele, loaderDetailsIndex: i - 1 })
          })
          setLoaderFilterDetails(object)
        }
        else if (userDetail.plan_detail?.credit_usage_data !== null && userDetail.plan_detail?.credit_usage_data.stripe_product_name == "Free") {
          const freeData = loaderDetails.image_group_assignment_data.filter(item =>
            item?.loader_user_type_data && item?.loader_user_type_data?.some(userData => userData.loader_user_type_name === "Free")
          );
          let paraphraseFreeData = freeData.filter(item => item.module_type_name === "Paraphrase");
          let generalFreeData = freeData.filter(item => item.module_type_name === "General");
          let combinedFreeData = [...paraphraseFreeData, ...generalFreeData];
          let object: loaderFilterdetails = { index: -1, loaderDetails: [] }
          combinedFreeData.map((ele, i) => {
            object.loaderDetails.push({ ...ele, loaderDetailsIndex: i - 1 })
          })
          setLoaderFilterDetails(object)
        }
        else if (userDetail.plan_detail?.credit_usage_data !== null && userDetail.plan_detail?.credit_usage_data.stripe_product_name == "Premium") {
          const premiumData = loaderDetails.image_group_assignment_data.filter(item =>
            item?.loader_user_type_data && item?.loader_user_type_data?.some(userData => userData?.loader_user_type_name === "Premium")
          );
          let paraphrasePremiumData = premiumData.filter(item => item?.module_type_name === "Paraphrase");
          let generalPremiumData = premiumData.filter(item => item?.module_type_name === "General");
          let combinedPremiumData = [...paraphrasePremiumData, ...generalPremiumData]?.map((item, index) => ({ ...item, loaderDataIndex: index }));
          let object: loaderFilterdetails = { index: -1, loaderDetails: [] }
          combinedPremiumData.map((ele, i) => {
            object.loaderDetails.push({ ...ele, loaderDetailsIndex: i - 1 })
          })
          setLoaderFilterDetails(object)
        }
        else {
          const filteredData = loaderDetails?.image_group_assignment_data?.filter(item => { return item })?.map((item, index) => ({ ...item, loaderDataIndex: index }));
          let object: loaderFilterdetails = { index: -1, loaderDetails: [] }
          filteredData?.map((ele, i) => {
            object.loaderDetails.push({ ...ele, loaderDetailsIndex: i - 1 })
          })
          setLoaderFilterDetails(object)
        }
      }
    }
  }, [userDetail, loaderDetails])

  if (flagUserMeCompleted == false) {
    return <GlobalRoundLoader />;
  }
  if (userDetail.flagUser && !userDetail.flag_user_anonymous && userDetail.flag_email_verified && flagOnboardingSkip == null) {
    return <GlobalRoundLoader />
  }
  return (

    <Fragment>
      {showLoginPopup &&
        <GlobalPopUp
          open={showLoginPopup}
          onClose={() => { setShowLoginPopup(false) }}
          handleClose={() => { setShowLoginPopup(false) }}
          flagShowCloseIcon={true}
          borderRadius="10px"
        >
          <GetStartedPopUpData />
        </GlobalPopUp>
      }
      {flagShowCreditsExhausted &&
        <GlobalPopUp
          open={flagShowCreditsExhausted}
          onClose={() => { setFlagShowCreditsExhausted(false) }}
          handleClose={() => { setFlagShowCreditsExhausted(false) }}
          flagShowCloseIcon={true}
          borderRadius="10px"
          paperMaxwidth="450px !important"
        >
          <PlanHasExhausted />
        </GlobalPopUp>}
      {flagLoading.flagRoundLoader && <GlobalRoundLoader />}
      {error.flagShowError && (
        <GlobalSnackbar
          type="error"
          message={
            error.message == undefined
              ? "Something went wrong, Please try again!"
              : error.message
          }
          open={error.flagShowError}
          handleClose={() => {
            setError({ ...error, flagShowError: false });
          }}
        />
      )}
      {success.flagShowSuccess && (
        <GlobalSnackbar
          type="success"
          message={
            success.message == undefined
              ? "Success"
              : success.message
          }
          open={success.flagShowSuccess}
          handleClose={() => {
            setSuccess({ ...success, flagShowSuccess: false });
          }}
        />
      )}
      {showInfo.flagShowInfo && (
        <GlobalSnackbar
          type="info"
          message={
            showInfo.message == undefined
              ? "Success"
              : showInfo.message
          }
          open={showInfo.flagShowInfo}
          handleClose={() => {
            setShowInfo({ ...showInfo, flagShowInfo: false });
          }}
        />
      )}
      <RouterRoutes>
        <>
          <Route path="/privacy-policy" element={<PrivacyCookiePolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/test" element={<SomethingWentWrong />} />
          <Route path="/error" element={<CommonErrorPage />} />
          {!userDetail?.flagUser ? (
            <>

              {/* <Route path="/register" element={<Registration />} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />}>
                <Route index element={<HomeComponent />} />
                <Route path="/dashboard/chatbot" element={<ChatbotLandingPage />} />
                <Route path="/dashboard/templates" element={<Template />} />
                <Route path="/dashboard/paraphraser" element={<Paraphraser />} />
                <Route path="/dashboard/persona" element={<Persona />} />
                {/* <Route path="/dashboard/chatbot" element={<ChatbotList />} /> */}
                <Route path="/dashboard/createPersona" element={<CreatePersona />} />
                <Route path="/dashboard/viewPersona" element={<ViewPersonaDetails />} />
                <Route path="/dashboard/updatePersona" element={<UpdatePersona />} />
                {/* <Route path="/dashboard/campaigns" element={<Campaigns />} />
                <Route path="/dashboard/campaigns/create" element={<CampaignCreateUpdateView />} /> */}
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Route>
              <Route path="*" element={<Dashboard />}>
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Route>
            </>
          ) : (
            <>
              {userDetail.flag_user_anonymous ?
                <>
                  <Route
                    path="/doc/:docId/:ActiveStep/:templateId"
                    element={<DocComponent />}
                  />
                  <Route path="/dashboard" element={<Dashboard />}>
                    <Route index element={<HomeComponent />} />
                    <Route path="/dashboard/chatbot" element={<ChatbotLandingPage />} />
                    <Route path="/dashboard/templates" element={<Template />} />
                    <Route path="/dashboard/documents" element={<Documents />} />
                    <Route path="/dashboard/persona" element={<Persona />} />
                    {/* <Route path="/dashboard/chatbot" element={<ChatbotList />} /> */}
                    {/* {userDetail.plan_detail && userDetail.plan_detail.credit_usage_data != null && userDetail.plan_detail.credit_usage_data != null && userDetail.plan_detail.credit_usage_data.stripe_product_name !== null && userDetail.plan_detail.credit_usage_data.stripe_product_name !== "Free" &&
                      <>
                        <Route path="/dashboard/createChatbot" element={<CreateChatbot />} />
                        <Route path="/dashboard/chatbot/viewChatbot" element={<ViewChatbot />} />
                      </>
                    } */}
                    <Route path="/dashboard/campaigns" element={<Campaigns />} />
                    <Route path="/dashboard/campaigns/create" element={<CampaignCreateUpdateView />} />
                    <Route path="/dashboard/createPersona" element={<CreatePersona />} />
                    <Route path="/dashboard/viewPersona" element={<ViewPersonaDetails />} />
                    <Route path="/dashboard/updatePersona" element={<UpdatePersona />} />
                    <Route path="/dashboard/paraphraser" element={<Paraphraser />} />
                  </Route>
                  <Route path="/" element={<Navigate to="/dashboard" />}></Route>
                  <Route path="/login" element={<Login />} />
                  {/* <Route path="/register" element={<Registration />} /> */}
                  <Route path="/register" element={<Navigate to="/login" />} />
                  {/* <Route
                    path="/page-not-found"
                    element={<PageNotFound />}
                  /> */}
                  {/* <Route
                    path="*"
                    element={<Navigate to="/page-not-found" />}
                  /> */}
                  <Route path="*" element={<Navigate to="/dashboard" />}></Route>
                  <Route
                    path="/error"
                    element={<SomethingWentWrong />}
                  />


                </>
                :
                <>
                  {userDetail.flag_email_verified ?
                    <>

                      {
                        (flagOnboardingSkip == false
                          // || flagOnboardingSkip == null
                        ) ?
                          <>
                            <Route path="/welcome" element={<Welcome />} />
                            <Route path="*" element={<Navigate to="/welcome" />} />
                          </>
                          :
                          <>
                            <Route
                              path="/doc/:docId/:ActiveStep/:templateId"
                              element={<DocComponent />}
                            />
                            <Route path="/dashboard" element={<Dashboard />}>
                              <Route index element={<HomeComponent />} />
                              <Route path="/dashboard/templates" element={<Template />} />
                              {/* {userDetail.plan_detail && userDetail.plan_detail.credit_usage_data.stripe_product_name !== "Free" && */}
                              <Route path="/dashboard/customTemplate" element={<CustomTemplate />} />
                              {/* }  */}

                              <Route path="/dashboard/documents" element={<Documents />} />
                              <Route path="/dashboard/documents" element={<Documents />} />
                              {/* {!(userDetail.user_role.user_role_name == "Developer" || userDetail.user_role.user_role_name == "Super Admin" || userDetail.user_role.user_role_name == "Admin") && */}
                              <Route path="/dashboard/settings-accountDetails" element={<Settings />} />
                              <Route path="/dashboard/settings-plansAndBilling" element={<Settings />} />
                              {/* <Route path="/dashboard/chatbot" element={<ChatbotList />} /> */}
                              {/* } */}
                              <Route path="/dashboard/persona" element={<Persona />} />
                              {/* {(userDetail.user_role && (userDetail.user_role.user_role_name == "Developer" || userDetail.user_role.user_role_name == "Admin") || (userDetail.plan_detail && userDetail.plan_detail.credit_usage_data != null && userDetail.plan_detail.credit_usage_data.stripe_product_name !== null && userDetail.plan_detail.credit_usage_data.stripe_product_name !== "Free")) &&
                                <>
                                  <Route path="/dashboard/createChatbot" element={<CreateChatbot />} />
                                  <Route path="/dashboard/chatbot/viewChatbot" element={<ViewChatbot />} />
                                </>
                              } */}
                              <Route path="/dashboard/createPersona" element={<CreatePersona />} />
                              <Route path="/dashboard/viewPersona" element={<ViewPersonaDetails />} />
                              <Route path="/dashboard/updatePersona" element={<UpdatePersona />} />
                              <Route path="/dashboard/campaigns" element={<Campaigns />} />
                              <Route path="/dashboard/campaigns/create" element={<CampaignCreateUpdateView />} />
                              <Route path="/dashboard/paraphraser" element={<Paraphraser />} />
                              <Route path="/dashboard/notes" element={<Notes />} />
                              <Route path="/dashboard/chatbot" element={<ChatbotLandingPage />} />
                              <Route path="/dashboard/notes/create" element={<CreateUpdateNote />} />
                              {/* <Route path="/dashboard/paraphraser-loader" element={<ParaphraserLoader />} /> */}
                            </Route>
                            <Route path="/" element={<Navigate to="/dashboard" />}></Route>
                            <Route path="/welcome" element={<Navigate to="/dashboard" />} />

                            {/* <Route
                        path="/page-not-found"
                        element={<PageNotFound />}
                      /> */}
                            <Route
                              path="*"
                              element={<Navigate to="/dashboard" />}
                            // element={localStorage.flagRedirectedFromLogin == "true" ? <Navigate to="/dashboard" /> : <Navigate to="/page-not-found" />}
                            />
                            <Route
                              path="/error"
                              element={<SomethingWentWrong />}
                            />

                            {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
                          </>
                      }

                    </>
                    :
                    <>
                      <Route path="*" element={<Registration flagShowVerificationBlock flagSuccessfullRegistered />} />
                    </>
                  }
                </>
              }
            </>
          )

          }
        </>
      </RouterRoutes>
    </Fragment>
  );
};

export default Routes;
