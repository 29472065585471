import { Autocomplete, Avatar, Button, Chip, Grid, Popper, TextField } from '@mui/material';
import React, { useContext } from 'react';
import styles from "../../../assets/styles/componentStyles/paraphrasing.module.scss";

import CloseIcon from '@mui/icons-material/Close';
import { Textarea } from '@mui/joy';
import Required from '../../utility/required';
import { GlobalTooltip } from '../../utility/globalTooltip';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { PersonaContext, PersonaContextPayload } from '../../../context/personaContext';
import personaPremiumIcon from "../../../assets/personaCrown.svg";
import personaAvatar from "../../../assets/personaAvatar.svg";
import { ParaphraseContext, ParaphraseContextPayload } from '../../../context/paraphraseContext';


interface PersonaParaphrasingTabProps {
    length: any,
}
const PersonaParaphrasingTab = (props: PersonaParaphrasingTabProps) => {
    const {
        length,
    } = props;
    const { userDetail } = useContext(UserContext) as UserContextPayload;
    const { personaList } = useContext(PersonaContext) as PersonaContextPayload
    const { paraphrasingVersioningArr, setParaphraseVersioningarr, paraphrasingVersionIndex, setDrawerProps, drawerProps } = useContext(ParaphraseContext) as ParaphraseContextPayload;
    const handleSetMobileDrawer = (dropdownOptions: any, paraphrasingPayloadKeyValue: string, optionKeyName: string, paraphrasingTabKey: string | null, isValMultiple: boolean, optionImage: string | null, dropdownName: string) => {
        setDrawerProps({
            ...drawerProps,
            openDrawer: true,
            dropdownOptions: dropdownOptions,
            paraphrasingPayloadKeyValue: paraphrasingPayloadKeyValue,
            optionKeyName: optionKeyName,
            paraphrasingTabKey: paraphrasingTabKey,
            isValMultiple: isValMultiple,
            optionImage: optionImage,
            dropdownName: dropdownName
        })
    }
    return (
        <>
            {/* Persona tab */}

            {/* Persona */}
            <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "10px" }}>
                <Grid container direction="column">
                    <Grid item className={styles.ppOptionsHeader}>Persona</Grid>
                    <Grid item>
                        <Autocomplete
                            options={personaList || []}
                            // onOpen={() => { handleSetMobileDrawer(personaList, "tone_of_voice", "rewrite_tone_name", "advanceParaphrasingVal", true, "rewrite_tone_icon_url") }}
                            getOptionLabel={(option: any) => option.persona_name ? `${option.persona_name}, ${option.persona_display_name}` : ""}
                            filterOptions={(options, { inputValue }) => {
                                const filteredOptions = options.filter((option) => {
                                    const personaName = option.persona_name || '';
                                    const subCategoryName = option.persona_sub_category__name || '';

                                    return (
                                        personaName.toLowerCase().includes(inputValue.toLowerCase()) ||
                                        subCategoryName.toLowerCase().includes(inputValue.toLowerCase())
                                    );
                                });

                                return filteredOptions;
                            }}
                            className={styles.autocompleteParaphrasing}
                            getOptionDisabled={(option) =>
                                option.persona_premium_flag &&
                                (userDetail.flagUser ?
                                    ((userDetail.plan_detail && userDetail.plan_detail.credit_usage_data !== null && userDetail?.plan_detail?.credit_usage_data.stripe_product_name == "Free") || userDetail.flag_user_anonymous)
                                    :
                                    true)

                            }
                            ChipProps={{ deleteIcon: <CloseIcon />, style: { maxWidth: '100%' }, }}
                            groupBy={(option) => option.persona_sub_category__name}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <GlobalTooltip
                                        option={option}
                                    >
                                        <li {...props}
                                            style={{
                                                paddingLeft: "0px",
                                                paddingRight: "0px",
                                                display: "flex",
                                                alignItems: "center",
                                                fontSize: "15px",
                                                fontWeight: "500",
                                                width: "100%"
                                            }}
                                        >
                                            <div
                                                style={{ pointerEvents: "none", display: "flex" }}>
                                                <img style={{ width: "37px", height: "37px", borderRadius: "50%", marginRight: "10px" }}
                                                    //  src={option.persona_avatar_url ? option.persona_avatar_url : personaAvatar}
                                                    src={option.persona_avatar_full_size_url ?? option.persona_avatar_url ?? personaAvatar}
                                                // src={option.persona_avatar_full_size_url !== null ? option.persona_avatar_full_size_url : option.persona_avatar_url ? option.persona_avatar_url : personaAvatar}
                                                />
                                            </div>

                                            <div style={{ width: "100%", pointerEvents: "none", padding: "0px 5px" }}>
                                                {option.persona_display_name ? `${option.persona_name}, ${option.persona_display_name}` : option.persona_name}
                                            </div>

                                            {option.persona_premium_flag &&
                                                <div style={{ width: "100%", display: "flex", justifyContent: "end", pointerEvents: "none", maxWidth: "25px" }}>
                                                    <img src={personaPremiumIcon} style={{ width: "25px", height: "auto" }} />
                                                </div>
                                            }
                                        </li>
                                    </GlobalTooltip>
                                </li>
                            )}
                            value={paraphrasingVersioningArr[paraphrasingVersionIndex].personaPayload.personaObj}
                            onChange={(_, newValue) => {
                                setParaphraseVersioningarr((prevState: any) =>
                                    prevState.map((item: any, index: any) =>
                                        index == paraphrasingVersionIndex ?
                                            { ...item, personaPayload: { ...item.personaPayload, personaObj: newValue == null ? "" : newValue, persona_id: newValue == null ? "" : newValue.persona_id } } : item
                                    ))
                            }}
                            renderInput={(params) => (
                                <>
                                    <TextField
                                        placeholder='Select Persona'
                                        {...params}
                                        // variant="standard"
                                        // className={styles.personaDropdown}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <>
                                                    {paraphrasingVersioningArr[paraphrasingVersionIndex].personaPayload.personaObj && (
                                                        <Avatar
                                                            src={paraphrasingVersioningArr[paraphrasingVersionIndex].personaPayload.personaObj.persona_avatar_url ? paraphrasingVersioningArr[paraphrasingVersionIndex].personaPayload.personaObj.persona_avatar_url : personaAvatar}
                                                            alt="Selected Persona"
                                                            style={{ width: "30px", height: "30px", marginLeft: "10px" }}
                                                        />
                                                    )}
                                                </>
                                            ),
                                        }}
                                    />
                                </>
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* format and length*/}
            <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "10px" }}>
                <Grid container gap={"15px"} justifyContent={"space-between"}>
                    {/* format */}
                    <Grid item xs={12}>
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item className={styles.ppOptionsHeader}>Length</Grid>
                                <Grid item>
                                    <Autocomplete
                                        // className={" multiple_chips__autocomplete formWiz-input-field"}
                                        className={styles.autocompleteParaphrasing}
                                        disablePortal
                                        disableClearable
                                        id="length"
                                        options={length[0].optionsList?.map((listItem: any) => listItem.length)}
                                        value={paraphrasingVersioningArr[paraphrasingVersionIndex].personaPayload.sentence_length}
                                        onChange={(event, values: any) => {
                                            setParaphraseVersioningarr((prevState: any) =>
                                                prevState.map((item: any, index: any) =>
                                                    index == paraphrasingVersionIndex ?
                                                        { ...item, personaPayload: { ...item.personaPayload, sentence_length: values } } : item
                                                ))
                                        }}
                                        PopperComponent={(props) => (
                                            <Popper {...props} placement="top" className={styles.singleStructureDropdown} />
                                        )}
                                        // onChange={(event, values: any) => {
                                        //     setPersonaPayload({ ...personaPayload, sentence_length: values });
                                        // }}
                                        // value={personaPayload.sentence_length}
                                        renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </>
    )
}

export default PersonaParaphrasingTab;