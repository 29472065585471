import { SplitPane } from "react-multi-split-pane";
import "../../assets/styles/test.css";
// import Test from "../test";
import OutlineComponent from "../templateComponents/outlineComponent";
import { Button, Grid } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { DocContext, DocContextPayload } from "../../context/docContext";
import closeArrow from "../../assets/closeArrow.png";
import styles from "../../assets/styles/componentStyles/editor.module.scss";
import PlaygroundApp from "../Editor/App";
import ResponsiveOutlineComponent from "../templateComponents/responsiveOutlineComponent";

export default function ResponsiveBlogEditor() {
    const { flagOutlineCollapsed, setFlagOutlineCollapsed, currentActiveDocDetails } = useContext(
        DocContext
    ) as DocContextPayload;
    const initialWidth = Math.max(window.innerWidth - 555, 0);
    const [defaultSize, setDefaultSize] = useState([555, initialWidth]); // Initialize with 0
    const [flagClose, setFlagClose] = useState(false)
    const { flagShowAIOutput } = useContext(DocContext) as DocContextPayload;
    return (
        <Grid className={currentActiveDocDetails?.template_type_name === "Blog-Generation" ? styles.responsiveEditorBlogPost : styles.responsiveEditor} container direction="column" >
            <Grid item>
                <ResponsiveOutlineComponent />
            </Grid>
            <Grid item>
                {currentActiveDocDetails?.document_name !== "Untitled Document" &&
                    <PlaygroundApp />}
            </Grid>
        </Grid>

    );
}
