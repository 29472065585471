import React, { useState, ReactNode } from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ClickAwayListener, styled } from '@mui/material';
import globalStyles from './../../assets/styles/global.module.scss'

interface GlobalPopoverProps {
    trigger: any; // The element that triggers the tooltip
    content: ReactNode; // The content of the tooltip (can be HTML)
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        borderRadius: "5px",
        boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.12)",
        color: 'rgba(0, 0, 0, 0.87)',
        // maxWidth: "unset",
        maxWidth: "500px",
        padding: "0px !important",
        marginTop: "0px !important",
        marginRight: "20px"

        // maxWidth: 220,
    },
}));

const GlobalTooltip: React.FC<GlobalPopoverProps> = ({ trigger, content }) => {
    const [open, setOpen] = useState(false);

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };
    function isMobile() {
        const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(navigator.userAgent);
    }
      
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <span className={globalStyles.globalPopOverTooltip}>
                <HtmlTooltip
                    disableFocusListener
                    disableHoverListener={isMobile()}
                    disableTouchListener
                    title={content}
                    open={open}
                    onClose={handleTooltipClose}
                    onMouseEnter={handleTooltipOpen}
                    onClick={handleTooltipOpen}
                >
                    <span onClick={handleTooltipOpen}>{trigger}</span>
                </HtmlTooltip>
            </span>
        </ClickAwayListener>
    );
};

export default GlobalTooltip;
