import React from 'react';
import { Grid } from '@mui/material';
import globalStyles from "../../assets/styles/global.module.scss";

function TermsAndConditions() {
  return (
    <Grid container direction="column" alignItems={"center"} className={globalStyles.htmlGridContainer}>
      <Grid item className={globalStyles.htmlHeading}>Terms and Condition</Grid>
      <Grid item className={globalStyles.htmlGridItemStyles}>
        <>
          <p>Terms of Service</p>
          <p>September 27, 2023</p>
          <p>
            <br />
          </p>
          <p>AGREEMENT TO TERMS</p>
          <p>
            <br />
          </p>
          <p>These Terms of Use constitute a legally binding agreement between the user (&quot;you&quot;) and Writewiz
            (&quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;), governing your access to and utilization
            of the writewiz.ai website, along with any other related media forms, channels, mobile websites, or
            applications associated with it (collectively referred to as the &quot;Site&quot;). Our company is registered in
            India, with its registered office located at 611,612 Satyamev Eminence, Ahmedabad, Gujarat 380060. &nbsp;You agree
            that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms of Use. IF YOU DO
            NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
            DISCONTINUE USE IMMEDIATELY.</p>
          <p>
            <br />
          </p>
          <p>Any supplementary terms, conditions, or documents that may be published on the Site periodically are explicitly
            incorporated into these Terms of Use. We reserve the exclusive right, at our sole discretion, to modify or amend
            these Terms of Use as needed. We will provide notice of any changes by updating the &quot;Last updated&quot; date
            specified in these Terms of Use. By doing so, you waive the right to receive individual notifications for each
            modification. It is your responsibility to review the applicable Terms each time you utilize our Site to ensure your
            understanding of the current Terms in effect. &nbsp;You will be subject to, and will be deemed to have been made
            aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Site after the
            date such revised Terms of Use are posted.</p>
          <p>
            <br />
          </p>
          <p>The information presented on the Site is not intended for distribution to or use by any person or entity in any
            jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject
            us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to
            access the Site from other locations do so on their own initiative and are solely responsible for compliance with
            local laws, if and to the extent local laws are applicable.</p>
          <p>The Site is intended for users who are at least 13 years of age. All users who are minors in the jurisdiction in
            which they reside (generally under the age of 18) must have the permission of, and be directly supervised by, their
            parent or guardian to use the Site. If you are a minor, you must have your parent or guardian read and agree to
            these Terms of Use prior to you using the Site.</p>
          <p>
            <br />
          </p>
          <p>INTELLECTUAL PROPERTY RIGHTS</p>
          <p>Unless specifically stated otherwise, the Site is our exclusive property, and all elements comprising the Site,
            including source code, databases, functionality, software, website designs, audio, video, text, photographs, and
            graphics (referred to collectively as the &quot;Content&quot;), as well as the trademarks, service marks, and logos
            displayed on the Site (referred to as the &quot;Marks&quot;), are either owned or under our control or licensed to
            us. These materials are protected by copyright and trademark laws, along with other intellectual property rights and
            unfair competition laws of the United States, international copyright laws, and international conventions.&nbsp;The
            Content and the Marks are provided on the Site &ldquo;AS IS&rdquo; for your information and personal use only.
            Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied,
            reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted,
            distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior
            written permission.</p>
          <p>
            <br />
          </p>
          <p>Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to
            download or print a copy of any portion of the Content to which you have properly gained access solely for your
            personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and
            the Marks.</p>
          <p>
            <br />
          </p>
          <p>USER REPRESENTATIONS</p>
          <p>
            <br />
          </p>
          <p>By using the Site, you represent and warrant that: (1) all registration information you submit will be true,
            accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such
            registration information as necessary; (3) you have the legal capacity and you agree to comply with these Terms of
            Use; (4) you are not under the age of 13; (5) you are not a minor in the jurisdiction in which you reside, or if a
            minor, you have received parental permission to use the Site; (6) you will not access the Site through automated or
            non-human means, whether through a bot, script or otherwise; (7) you will not use the Site for any illegal or
            unauthorized purpose; and (8) your use of the Site will not violate any applicable law or regulation.</p>
          <p>
            <br />
          </p>
          <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend
            or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).</p>
          <p>
            <br />
          </p>
          <p>USER REGISTRATION <br />
            <br />
          </p>
          <p>You may be required to register with the Site. We reserve the right to remove, reclaim, or change a username you
            select
            if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
          </p>
          <p>
            <br />
          </p>
          <p>FEES AND PAYMENT</p>
          <p>
            <br />
          </p>
          <p>Access to certain services may require a purchase or payment of a fee. By using the Site, you acknowledge and agree
            to provide accurate, complete, and up-to-date information for all purchases and accounts.&nbsp;You further agree to
            promptly update account and payment information, including email address, payment method, and payment card
            expiration date, so that we can complete your transactions and contact you as needed. We bill you through an online
            billing account for purchases made via the Site. Sales tax will be added to the price of purchases as deemed
            required by us. We may change prices at any time.&nbsp; <br />
            <br />
          </p>
          <p>You agree to pay all charges or fees at the prices then in effect for your purchases, and you authorize us to
            charge your chosen payment provider for any such amounts upon making your purchase. If your purchase is subject to
            recurring charges, then you consent to our charging your payment method on a recurring basis without requiring your
            prior approval for each recurring charge, until you notify us of your cancellation.</p>
          <p>
            <br />
          </p>
          <p>We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received
            payment. We also reserve the right to refuse any order placed through the Site.</p>
          <p>
            <br />
          </p>
          <p>CANCELLATION <br />
            <br />
          </p>
          <p>The final decision to grant a refund for a service subscription lies solely with Writewiz.&nbsp; <br />We
            reserve the right to evaluate each refund request on a case-by-case basis and consider factors such as the nature of
            the service, usage, and timing of the request.
          </p>
          <p>
            <br />
          </p>
          <p>If you are unsatisfied with our services, please email us at info@writewiz.ai</p>
          <p>
            <br />
          </p>
          <p>MULTIPLE FREE ACCOUNTS <br />
            <br />
          </p>
          <p>The creation of multiple free accounts is strictly prohibited. Users found to have created multiple accounts will
            have all accounts terminated and may face additional actions, including but not limited to, being banned from the
            service. Any free credits obtained across all accounts will be forfeited.</p>
          <p>
            <br />
          </p>
          <p>PROHIBITED ACTIVITIES <br />
            <br />
          </p>
          <p>You may not access or use the Site for any purpose other than that for which we make the Site available. The Site
            may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved
            by us.</p>
          <p>
            <br />
          </p>
          <p>As a user of the Site, you agree not to:</p>
          <p>
            <br />
          </p>
          <ul>
            <li>
              <p>Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a
                collection, compilation, database, or directory without written permission from us.</p>
            </li>
            <li>
              <p>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information
                such as user passwords.</p>
            </li>
            <li>
              <p>Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that
                prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the
                Content contained therein.</p>
            </li>
            <li>
              <p>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</p>
            </li>
            <li>
              <p>Use any information obtained from the Site in order to harass, abuse, or harm another person.</p>
            </li>
            <li>
              <p>Make improper use of our support services or submit false reports of abuse or misconduct.</p>
            </li>
            <li>
              <p>Use the Site in a manner inconsistent with any applicable laws or regulations.</p>
            </li>
            <li>
              <p>Engage in unauthorized framing of or linking to the Site.</p>
            </li>
            <li>
              <p>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including
                excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any
                party&rsquo;s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes
                with the use, features, functions, operation, or maintenance of the Site.</p>
            </li>
            <li>
              <p>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any
                data mining, robots, or similar data gathering and extraction tools.</p>
            </li>
            <li>
              <p>Delete the copyright or other proprietary rights notice from any Content.</p>
            </li>
            <li>
              <p>Attempt to impersonate another user or person or use the username of another user.</p>
            </li>
            <li>
              <p>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
                information collection or transmission mechanism, including without limitation, clear graphics
                interchange&nbsp;formats (&ldquo;gifs&rdquo;), 1&times;1 pixels, web bugs, cookies, or other similar devices
                (sometimes referred to as &ldquo;spyware&rdquo; or &ldquo;passive collection mechanisms&rdquo; or
                &ldquo;pcms&rdquo;).</p>
            </li>
            <li>
              <p>Interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the
                Site.</p>
            </li>
            <li>
              <p>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the
                Site to you.</p>
            </li>
            <li>
              <p>Attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion
                of the Site.</p>
            </li>
            <li>
              <p>Copy or adapt the Site&rsquo;s software, including but not limited to&nbsp;Flash, PHP, HTML, JavaScript, or
                other code.</p>
            </li>
            <li>
              <p>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the
                software comprising or in any way making up a part of the Site.</p>
            </li>
            <li>
              <p>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or
                distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or
                offline reader that accesses the Site, or using or launching any unauthorized script or other software.</p>
            </li>
            <li>
              <p>Use a buying agent or purchasing agent to make purchases on the Site.</p>
            </li>
            <li>
              <p>Make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by
                electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated
                means or under false pretenses.</p>
            </li>
            <li>
              <p>Use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any
                revenue-generating endeavor or commercial enterprise. <br />
                <br />
                <br />
              </p>
            </li>
          </ul>
          <p>USER GENERATED CONTRIBUTIONS</p>
          <p>
            <br />
          </p>
          <p>The Site may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other
            functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform,
            publish, distribute, or broadcast content and materials to us or on the Site, including but not limited to text,
            writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material
            (collectively, &quot;Contributions&quot;). Contributions may be viewable by other users of the Site and through
            third-party websites. As such, any Contributions you transmit may be treated as non-confidential and
            non-proprietary. When you create or make available any Contributions, you thereby represent and warrant that:</p>
          <p>
            <br />
          </p>
          <ul>
            <li>
              <p>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or
                copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to
                the copyright, patent, trademark, trade secret, or moral rights of any third party.</p>
            </li>
            <li>
              <p>You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to
                use and to authorize us, the Site, and other users of the Site to use your Contributions in any manner
                contemplated by the Site and these Terms of Use.</p>
            </li>
            <li>
              <p>You have the written consent, release, and/or permission of each and every identifiable individual person in
                your Contributions to use the name or likeness of each and every such identifiable individual person to enable
                inclusion and use of your Contributions in any manner contemplated by the Site and these Terms of Use.</p>
            </li>
            <li>
              <p>Your Contributions are not false, inaccurate, or misleading.</p>
            </li>
            <li>
              <p>Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes,
                chain letters, spam, mass mailings, or other forms of solicitation.</p>
            </li>
            <li>
              <p>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or
                otherwise objectionable (as determined by us).</p>
            </li>
            <li>
              <p>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</p>
            </li>
            <li>
              <p>Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and
                to promote violence against a specific person or class of people.</p>
            </li>
            <li>
              <p>Your Contributions do not violate any applicable law, regulation, or rule.</p>
            </li>
            <li>
              <p>Your Contributions do not violate the privacy or publicity rights of any third party.</p>
            </li>
            <li>
              <p>Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to
                protect the health or well-being of minors.</p>
            </li>
            <li>
              <p>Your Contributions do not include any offensive comments that are connected to race, national origin, gender,
                sexual preference, or physical handicap.</p>
            </li>
            <li>
              <p>Your Contributions do not otherwise violate, or link to material that violates, any provision of these Terms of
                Use, or any applicable law or regulation.</p>
            </li>
          </ul>
          <p>
            <br />
          </p>
          <p>Any use of the Site in violation of the foregoing violates these Terms of Use and may result in, among other
            things, termination or suspension of your rights to use the Site.</p>
          <p>
            <br />
          </p>
          <p>CONTRIBUTION LICENSE</p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>By submitting your Contributions to any section of the Site or making them accessible to the Site through linking
            your account to your social networking accounts, you hereby grant us an unrestricted, unlimited, irrevocable,
            perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide license to host, use, reproduce, copy,
            disclose, sell, resell, publish, broadcast, rename, archive, store, cache, publicly perform, publicly display,
            reformat, translate, transmit, excerpt (in whole or in part), and distribute said Contributions (including, but not
            limited to, your image and voice) for any purpose, whether commercial, advertising, or otherwise. Furthermore, you
            represent and warrant that you possess the necessary rights to grant such a license, and you also grant us the
            authority to create derivative works of your Contributions, incorporate them into other works, and grant sublicenses
            of the aforementioned rights.The use and distribution may occur in any media formats and through any media channels.
          </p>
          <p>
            <br />
          </p>
          <p>This license will apply to any form, media, or technology now known or hereafter developed, and includes our use of
            your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names,
            logos, and personal and commercial images you provide. You waive all moral rights in your Contributions, and you
            warrant that moral rights have not otherwise been asserted in your Contributions.</p>
          <p>
            <br />
          </p>
          <p>We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and
            any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable
            for any statements or representations in your Contributions provided by you in any area on the Site. You are solely
            responsible for your Contributions to the Site and you expressly agree to exonerate us from any and all
            responsibility and to refrain from any legal action against us regarding your Contributions.</p>
          <p>
            <br />
          </p>
          <p>We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions;
            (2) to re-categorize any Contributions to place them in more appropriate locations on the Site; and (3) to
            pre-screen or delete any Contributions at any time and for any reason, without notice. We have no obligation to
            monitor your Contributions.</p>
          <p>
            <br />
          </p>
          <p>SOCIAL MEDIA</p>
          <p>
            <br />
          </p>
          <p>As part of the functionality of the Site, you may link your account with online accounts you have with third-party
            service providers (each such account, a &ldquo;Third-Party Account&rdquo;) by either: (1) providing your Third-Party
            Account login information through the Site; or (2) allowing us to access your Third-Party Account, as is permitted
            under the applicable terms and conditions that govern your use of each Third-Party Account. You represent and
            warrant that you are entitled to disclose your Third-Party Account login information to us and/or grant us access to
            your Third-Party Account, without breach by you of any of the terms and conditions that govern your use of the
            applicable Third-Party Account, and without obligating us to pay any fees or making us subject to any usage
            limitations imposed by the third-party service provider of the Third-Party Account. By granting us access to any
            Third-Party Accounts, you understand that (1) we may access, make available, and store (if applicable) any content
            that you have provided to and stored in your Third-Party Account (the &ldquo;Social Network Content&rdquo;) so that
            it is available on and through the Site via your account, including without limitation any friend lists and (2) we
            may submit to and receive from your Third-Party Account additional information to the extent you are notified when
            you link your account with the Third-Party Account. Depending on the Third-Party Accounts you choose and subject to
            the privacy settings that you have set in such Third-Party Accounts, personally identifiable information that you
            post to your Third- Party Accounts may be available on and through your account on the Site. Please note that if a
            Third-Party Account or associated service becomes unavailable or our access to such Third-Party Account is
            terminated by the third-party service provider, then Social Network Content may no longer be available on and
            through the Site. You will have the ability to disable the connection between your account on the Site and your
            Third-Party Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS
            ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE
            PROVIDERS. We make no effort to review any Social Network Content for any purpose, including but not limited to, for
            accuracy, legality, or non-infringement, and we are not responsible for any Social Network Content. You acknowledge
            and agree that we may access your email address book associated with a Third-Party Account and your contacts list
            stored on your mobile device or tablet computer solely for purposes of identifying and informing you of those
            contacts who have also registered to use the Site. You can&nbsp;deactivate the connection between the Site and your
            Third-Party Account by contacting us using the contact information below or through your account settings (if
            applicable). We will&nbsp;attempt to delete any information&nbsp;stored on our servers that was obtained through
            such Third-Party Account, except the username and profile picture that become associated with your account.</p>
          <p>
            <br />
          </p>
          <p>SUBMISSIONS</p>
          <p>
            <br />
          </p>
          <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information
            regarding the Site (&quot;Submissions&quot;) provided by you to us are non-confidential and shall become our sole
            property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the
            unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without
            acknowledgment or compensation to you. You hereby waive all moral rights to any such Submissions, and you hereby
            warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You
            agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any
            proprietary right in your Submissions.</p>
          <p>
            <br />
          </p>
          <p>THIRD-PARTY WEBSITES AND CONTENT</p>
          <p>
            <br />
          </p>
          <p>The Site may contain (or you may be sent via the Site) links to other websites (&quot;Third- Party Websites&quot;)
            as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications,
            software, and other content or items belonging to or originating from third parties (&quot;Third-Party
            Content&quot;). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for
            accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed
            through the Site or any Third-Party Content posted on, available through, or installed from the Site, including the
            content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the
            Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or installation of
            any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you
            decide to leave the Site and access the Third-Party Websites or to use or install any Third-Party Content, you do so
            at your own risk, and you should be aware these Terms of Use no longer govern. You should review the applicable
            terms and policies, including privacy and data gathering practices, of any website to which you navigate from the
            Site or relating to any applications you use or install from the Site. Any purchases you make through Third-Party
            Websites will be through other websites and from other companies, and we take no responsibility whatsoever in
            relation to such purchases which are exclusively between you and the applicable third party. You agree and
            acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us
            harmless from any harm caused by your purchase of such products or services. Additionally, you shall hold us
            harmless from any losses sustained by you or harm caused to you relating to or resulting in any way from any
            Third-Party Content or any contact with Third-Party Websites.</p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>SITE MANAGEMENT</p>
          <p>
            <br />
          </p>
          <p>We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2)
            take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use,
            including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and
            without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically
            feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation,
            notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size
            or are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to protect our
            rights and property and to facilitate the proper functioning of the Site.</p>
          <p>
            <br />
          </p>
          <p>PRIVACY POLICY</p>
          <p>
            <br />
          </p>
          <p>We care about data privacy and security. Please review our Privacy Policy: www.writewiz.ai/privacy/.&nbsp;By
            using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Use. Please
            be advised the Site is hosted in&nbsp;India. If you access the Site from any other region of the world with laws or
            other requirements governing personal data collection, use, or disclosure that differ from applicable laws
            in&nbsp;India, then through your continued use of the Site, you are transferring your data to&nbsp;India, and you
            agree to have your data transferred to and processed in&nbsp;India. Further, we do not knowingly accept, request, or
            solicit information from children or knowingly market to children. Therefore, in accordance with the U.S.
            Children&rsquo;s Online Privacy Protection Act, if we receive actual knowledge that anyone under the&nbsp;age of
            13&nbsp;has provided personal information to us without the requisite and verifiable parental consent, we will
            delete that information from the Site as quickly as is reasonably practical.</p>
          <p>
            <br />
          </p>
          <p>TERM AND TERMINATION</p>
          <p>
            <br />
          </p>
          <p>These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER
            PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
            DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
            REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
            TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR
            DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
            DISCRETION.</p>
          <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new
            account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on
            behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
          <p>
            <br />
          </p>
          <p>MODIFICATIONS AND INTERRUPTIONS <br />
            <br />
          </p>
          <p>We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our
            sole discretion without notice. However, we have no obligation to update any information on our Site. We also
            reserve the right to modify or discontinue all or part of the Site without notice at any time. We will not be liable
            to you or any third party for any modification, price change, suspension, or discontinuance of the Site.</p>
          <p>We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other
            problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We
            reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for
            any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or
            inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site.
            Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any
            corrections, updates, or releases in connection therewith.</p>
          <p>
            <br />
          </p>
          <p>DISPUTE RESOLUTION <br />
            <br />
          </p>
          <p>Informal Negotiations</p>
          <p>To expedite resolution and control the cost of any dispute, controversy, or claim related to these Terms of Use
            (each &quot;Dispute&quot; and collectively, the &ldquo;Disputes&rdquo;) brought by either you or us (individually, a
            &ldquo;Party&rdquo; and collectively, the &ldquo;Parties&rdquo;), the Parties agree to first attempt to negotiate
            any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before
            initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.
          </p>
          <p>
            <br />
          </p>
          <p>Restrictions</p>
          <p>The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the
            full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or
            authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c)
            there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the
            general public or any other persons.</p>
          <p>
            <br />
          </p>
          <p>Exceptions&nbsp;to Informal Negotiations and Arbitration</p>
          <p>The Parties agree that the following Disputes are not subject to the above provisions concerning informal
            negotiations and binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of,
            any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of
            theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision
            is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that
            portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of
            competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the
            personal jurisdiction of that court.</p>
          <p>
            <br />
          </p>
          <p>CORRECTIONS <br />
            <br />
          </p>
          <p>There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including
            descriptions, pricing, availability, and various other information. We reserve the right to correct any errors,
            inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.
          </p>
          <p>
            <br />
          </p>
          <p>DISCLAIMER <br />
            <br />
          </p>
          <p>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL
            BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
            CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION,&nbsp;THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.&nbsp;WE MAKE NO WARRANTIES OR
            REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE&rsquo;S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED
            TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS
            TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
            INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR
            FROM THE SITE, (5) ANY BUGS,&nbsp;VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE
            SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE
            OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
            SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
            OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN
            ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
            TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
            SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE.</p>
          <p>
            <br />
          </p>
          <p>LIMITATIONS OF LIABILITY <br />
            <br />
          </p>
          <p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR&nbsp;AGENTS&nbsp;BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
            DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST
            REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY
            CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE&nbsp;LIMITED TO THE AMOUNT PAID, IF
            ANY, BY YOU TO US DURING THE&nbsp;TWO (2) MONTH PERIOD&nbsp;PRIOR TO ANY CAUSE OF ACTION ARISING.&nbsp;CERTAIN US
            STATE LAWS AND INTERNATIONAL LAWS&nbsp;DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
            OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO
            YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
          <p>
            <br />
          </p>
          <p>INDEMNIFICATION <br />
            <br />
          </p>
          <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
            respective officers,&nbsp;agents, partners, and employees, from and against any loss, damage, liability, claim, or
            demand, including reasonable attorneys&rsquo; fees and expenses, made by any third party due to or arising out of:
            (1) your Contributions; (2) use of the Site; (3) breach of these Terms of Use; (4) any breach of your
            representations and warranties set forth in these Terms of Use; (5) your violation of the rights of a third party,
            including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the
            Site with whom you connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to
            assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to
            cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any
            such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>
          <p>
            <br />
          </p>
          <p>USER DATA</p>
          <p>We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the
            Site, as well as data relating to your use of the Site. Although we perform regular routine backups of data, you are
            solely responsible for all data that you transmit or that relates to any activity you have undertaken using the
            Site. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby
            waive any right of action against us arising from any such loss or corruption of such data.</p>
          <p>
            <br />
          </p>
          <p>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES <br />
            <br />
          </p>
          <p>Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent
            to receive electronic communications, and you agree that all agreements, notices, disclosures, and other
            communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such
            communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US
            OR VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or
            other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic
            records, or to payments or the&nbsp;granting of credits by any means other than electronic means.</p>
          <p>
            <br />
          </p>
          <p>MISCELLANEOUS</p>
          <p>These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site
            constitute the entire agreement and understanding between you and us.&nbsp;Our failure to exercise or enforce any
            right or provision of these Terms of Use shall not operate as a waiver of such right or provision.&nbsp;These Terms
            of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to
            others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by
            any cause beyond our reasonable control. If any provision or part of a provision of these Terms of Use is determined
            to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms
            of Use and does not affect the validity and enforceability of any remaining provisions.&nbsp;There is no joint
            venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Use
            or use of the Site.&nbsp;You agree that these Terms of Use will not be construed against us by virtue of having
            drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use
            and the lack of signing by the parties hereto to execute these Terms of Use.</p>
          <p>
            <br />
          </p>
          <p>CONTACT US</p>
          <p>In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site,
            please contact us at: 611/612 ,Satyamev Eminence Near Sukan Mall Science-City Road, Ahmedabad, India or email us
            at&nbsp;info@writewiz.ai.</p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
        </>
      </Grid>
    </Grid>
  );
}

export default TermsAndConditions;