import { Autocomplete, Button, Chip, Grid, Popper, TextField } from '@mui/material';
import React, { useContext } from 'react';
import styles from "../../../assets/styles/componentStyles/paraphrasing.module.scss";

import CloseIcon from '@mui/icons-material/Close';
import { ParaphraseContext, ParaphraseContextPayload } from '../../../context/paraphraseContext';
import { TemplateContext, TemplateContextPayload } from '../../../context/templateContext';
import { PersonaContext, PersonaContextPayload } from '../../../context/personaContext';
interface AdvanceParaphrasingTabProps {
    length: any,
    morePrompts: any,
    CustomPaper: any,
}
const AdvanceParaphrasingTab = (props: AdvanceParaphrasingTabProps) => {
    const {
        length,
        morePrompts,
        CustomPaper,
    } = props;
    const { paraphrasingVersioningArr, setParaphraseVersioningarr, paraphrasingVersionIndex, setDrawerProps, drawerProps, dialectList } = useContext(ParaphraseContext) as ParaphraseContextPayload;
    const { workDomainList, rewriteToneGenericList, } = useContext(TemplateContext) as TemplateContextPayload;
    const { personaWritingSkills } = useContext(PersonaContext) as PersonaContextPayload;
    const handleSetMobileDrawer = (dropdownOptions: any, paraphrasingPayloadKeyValue: string, optionKeyName: string, paraphrasingTabKey: string | null, isValMultiple: boolean, optionImage: string | null, dropdownName: string) => {
        setDrawerProps({
            ...drawerProps,
            openDrawer: true,
            dropdownOptions: dropdownOptions,
            paraphrasingPayloadKeyValue: paraphrasingPayloadKeyValue,
            optionKeyName: optionKeyName,
            paraphrasingTabKey: paraphrasingTabKey,
            isValMultiple: isValMultiple,
            optionImage: optionImage,
            dropdownName: dropdownName
        })
    }
    return (
        <>
            {/* Advance tab */}

            {/* tone of voice and dialect*/}
            <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "10px" }}>
                <Grid container sx={{ gap: "5px", justifyContent: "space-between" }}>
                    <Grid item xs={5.85}>
                        <Grid container direction="column">
                            <Grid item className={styles.ppOptionsHeader}>
                                Tone of Voice
                                {/* {tonOfVoice[0].name} */}
                            </Grid>
                            <Grid item>
                                <Autocomplete
                                    multiple
                                    clearIcon={<></>}
                                    // className={" multiple_chips__autocomplete formWiz-input-field"}
                                    onOpen={() => { handleSetMobileDrawer(morePrompts, "tone_of_voice", "rewrite_tone_name", "advanceParaphrasingVal", true, "rewrite_tone_icon_url", "Tone of Voice") }}
                                    className={styles.autocompleteParaphrasing}
                                    disablePortal
                                    id="toneOfVoice"
                                    options={morePrompts.map((prompt: any) => prompt.rewrite_tone_name)}
                                    value={paraphrasingVersioningArr[paraphrasingVersionIndex].advanceParaphrasingVal.tone_of_voice}
                                    onChange={(event, values: any) => {
                                        setParaphraseVersioningarr((prevState: any) =>
                                            prevState.map((item: any, index: any) =>
                                                index == paraphrasingVersionIndex ?
                                                    { ...item, advanceParaphrasingVal: { ...item.advanceParaphrasingVal, tone_of_voice: values }, basicParaphrasingVal: { ...item.basicParaphrasingVal, tone_of_voice: values } } : item
                                            ))
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip
                                                label={option}
                                                deleteIcon={<CloseIcon />}
                                                {...getTagProps({ index })}
                                                avatar={
                                                    <img
                                                        src={
                                                            morePrompts.find((prompt: any) => prompt.rewrite_tone_name === option)
                                                                ?.rewrite_tone_icon_url
                                                        }
                                                        alt="icon"
                                                        style={{ width: '17px', height: '17px' }} // Adjust the size as needed
                                                    />
                                                }
                                            />
                                        ))
                                    }
                                    renderOption={(props, option, state) => (
                                        <li {...props}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={morePrompts.find((prompt: any) => prompt.rewrite_tone_name == option).rewrite_tone_icon_url} alt="icon" style={{ width: '17px', height: '17px', marginRight: '8px' }} />
                                                {option}
                                            </div>
                                        </li>
                                    )}
                                    PaperComponent={CustomPaper}
                                    PopperComponent={(props) => (
                                        <Popper {...props} placement="top" />
                                    )}
                                    renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5.85}>
                        <Grid container direction="column">
                            <Grid item className={styles.ppOptionsHeader}>Dialect</Grid>
                            <Grid item>
                                <Autocomplete
                                    className={styles.autocompleteParaphrasing}
                                    disablePortal
                                    id="combo-box-demo"
                                    onOpen={() => { handleSetMobileDrawer(dialectList, "dialect", "dialect_name", "advanceParaphrasingVal", false, null, "Dialect") }}
                                    options={dialectList.map((dialect: any) => dialect.dialect_name)}
                                    value={paraphrasingVersioningArr[paraphrasingVersionIndex].advanceParaphrasingVal.dialect}
                                    onChange={(event, values: any) => {
                                        setParaphraseVersioningarr((prevState: any) =>
                                            prevState.map((item: any, index: any) =>
                                                index == paraphrasingVersionIndex ?
                                                    { ...item, advanceParaphrasingVal: { ...item.advanceParaphrasingVal, dialect: values } } : item
                                            ))
                                    }}
                                    PopperComponent={(props) => (
                                        <Popper {...props} placement="top" className={styles.singleStructureDropdown} />
                                    )}
                                    renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            {/* reading ease and format */}
            <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "10px" }}>
                <Grid container sx={{ gap: "5px", justifyContent: "space-between" }}>
                    <Grid item xs={5.85}>
                        <Grid container direction="column">
                            <Grid item className={styles.ppOptionsHeader}>Reading Ease</Grid>
                            <Grid item>
                                <Autocomplete
                                    className={styles.autocompleteParaphrasing}
                                    disablePortal
                                    id="combo-box-demo"
                                    onOpen={() => { handleSetMobileDrawer(personaWritingSkills, "reading_ease", "persona_reading_level_name", "advanceParaphrasingVal", false, null, "Reading ease") }}
                                    options={personaWritingSkills !== null ? personaWritingSkills.map((writingSkill: any) => writingSkill.persona_reading_level_name) : []}
                                    value={paraphrasingVersioningArr[paraphrasingVersionIndex].advanceParaphrasingVal.reading_ease}
                                    onChange={(event, values: any) => {
                                        setParaphraseVersioningarr((prevState: any) =>
                                            prevState.map((item: any, index: any) =>
                                                index == paraphrasingVersionIndex ?
                                                    { ...item, advanceParaphrasingVal: { ...item.advanceParaphrasingVal, reading_ease: values } } : item
                                            ))
                                    }}
                                    PopperComponent={(props) => (
                                        <Popper {...props} placement="top" className={styles.singleStructureDropdown} />
                                    )}
                                    renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item
                        xs={5.85}
                    >
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item className={styles.ppOptionsHeader}>Length</Grid>
                                <Grid item>
                                    <Autocomplete
                                        className={styles.autocompleteParaphrasing}
                                        disablePortal
                                        disableClearable
                                        id="combo-box-demo"
                                        onOpen={() => { handleSetMobileDrawer(length[0].optionsList, "sentence_length", "length", "advanceParaphrasingVal", false, null, "Length") }}
                                        options={length[0].optionsList?.map((listItem: any) => listItem.length)}
                                        value={paraphrasingVersioningArr[paraphrasingVersionIndex].advanceParaphrasingVal.sentence_length}
                                        onChange={(event, values: any) => {
                                            setParaphraseVersioningarr((prevState: any) =>
                                                prevState.map((item: any, index: any) =>
                                                    index == paraphrasingVersionIndex ?
                                                        { ...item, advanceParaphrasingVal: { ...item.advanceParaphrasingVal, sentence_length: values } } : item
                                                ))
                                        }}
                                        PopperComponent={(props) => (
                                            <Popper {...props} placement="top" />
                                        )}
                                        renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* writing style and work domain */}
            <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "10px" }}>
                <Grid container sx={{ gap: "5px", justifyContent: "space-between" }}>
                    <Grid item xs={5.85}>
                        <Grid container direction="column">
                            <Grid item className={styles.ppOptionsHeader}>Narrative Style</Grid>
                            <Grid item>
                                <Autocomplete
                                    // className={" multiple_chips__autocomplete formWiz-input-field"}
                                    onOpen={() => { handleSetMobileDrawer(rewriteToneGenericList.filter((rewriteOption: any) => rewriteOption.flag_narration == true), "narrative_style", "rewrite_tone_name", "advanceParaphrasingVal", false, null, "Narrative Style") }}
                                    className={styles.autocompleteParaphrasing}
                                    disablePortal
                                    id="length"
                                    options={rewriteToneGenericList.filter((rewriteOption: any) => rewriteOption.flag_narration == true).map((rewriteOption: any) => rewriteOption.flag_narration == true && rewriteOption.rewrite_tone_name)}
                                    value={paraphrasingVersioningArr[paraphrasingVersionIndex].advanceParaphrasingVal.narrative_style}
                                    onChange={(event, values: any) => {
                                        setParaphraseVersioningarr((prevState: any) =>
                                            prevState.map((item: any, index: any) =>
                                                index == paraphrasingVersionIndex ?
                                                    { ...item, advanceParaphrasingVal: { ...item.advanceParaphrasingVal, narrative_style: values } } : item
                                            ))
                                    }}
                                    PaperComponent={CustomPaper}
                                    renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5.85}>
                        <Grid container direction="column">
                            <Grid item className={styles.ppOptionsHeader}>Work Domain</Grid>
                            <Grid item>
                                <Autocomplete
                                    className={styles.autocompleteParaphrasing}
                                    disablePortal
                                    id="combo-box-demo"
                                    onOpen={() => { handleSetMobileDrawer(workDomainList, "work_domain", "work_domain_name", "advanceParaphrasingVal", false, "work_domain_icon_url", "Work Domain") }}
                                    options={workDomainList.map((workDomain: any) => workDomain.work_domain_name)}
                                    value={paraphrasingVersioningArr[paraphrasingVersionIndex].advanceParaphrasingVal.work_domain}
                                    onChange={(event, values: any) => {
                                        setParaphraseVersioningarr((prevState: any) =>
                                            prevState.map((item: any, index: any) =>
                                                index == paraphrasingVersionIndex ?
                                                    { ...item, advanceParaphrasingVal: { ...item.advanceParaphrasingVal, work_domain: values } } : item
                                            ))
                                    }}
                                    renderOption={(props, option, state) => (
                                        <li {...props}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={workDomainList.find((workDomain: any) => workDomain.work_domain_name == option)?.work_domain_icon_url ?? ""} alt="icon" style={{ width: '17px', height: '17px', marginRight: '8px' }} />
                                                {option}
                                            </div>
                                        </li>
                                    )}
                                    PaperComponent={CustomPaper}
                                    renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


        </>
    )
}

export default AdvanceParaphrasingTab;