import { Box, Button, CardMedia, DialogContent, Grid, Typography } from '@mui/material';
import Header from '../layout/header/header';
import { useContext, useEffect, useState } from 'react';
import { UserContext, UserContextPayload } from '../../context/userContext';
import { useNavigate } from 'react-router-dom';
import { TemplateContext, TemplateContextPayload } from '../../context/templateContext';
import { CommonResponseModel, CommonResponseSecondaryModel, DocumentListDataModel, RegisterDataResponseModel, SendOTPResponseDataModel, bannerDataResponseDataModel } from '../../models/axiosModel';
import { DocContext, DocContextPayload } from '../../context/docContext';
import GlobalPopUp from '../utility/globalPopUp';
import globalStyles from "../../assets/styles/global.module.scss";
import styles from "../../assets/styles/componentStyles/template.module.scss";
import { GlobalTable } from '../utility/globalTable';
import moment from 'moment';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteDocumentDataComponent from './deleteDocumentDataComponent';
import coloredTemplateIcon from "../../assets/coloredTemplate.svg";
import docIcon from "../../assets/document_30px.svg";
import Template from './template';
import agent from '../../services/api';
import { PersonaContext, PersonaContextPayload } from '../../context/personaContext';
import chromeIcon from '../../assets/downloadExtensionIcon.svg'
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, getStripeProduct, isDev } from '../../utils/constants';
import TrialBannerDesign from '../trialBannerDesign';

const HomeComponent = () => {

    let headerList = [
        {
            name: "Documents",
            alignment: "left",
            flagIcon: true,
            valueKey: "document_name",
            iconKey: "documentIcon",
        },
        {
            name: "Last Modified",
            alignment: "left",
            flagIcon: false,
            valueKey: "formatted_timestamp",
            iconKey: ""
        },
        {
            name: "Category",
            alignment: "left",
            flagIcon: true,
            valueKey: "template_name",
            iconKey: "featureIcon"
        },
        {
            name: "",
            alignment: "right",
            flagIcon: true,
            valueKey: "",
            iconKey: "editIcon"
        },
    ]

    const navigate = useNavigate()
    const { userDetail, flagLoading, setFlagLoading, setError, error, setSuccess, success, flagChromeDesktop, Register, setUserDetail, setFlagShowCreditsExhausted } = useContext(UserContext) as UserContextPayload;
    const { GetTemplateList, templateList } = useContext(TemplateContext) as TemplateContextPayload;
    const [adjustedHeaderList, setAdjustedHeaderList] = useState(headerList);
    const [docDetailForTable, setDocDetailForTable] = useState<DocumentListDataModel[]>([])
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [rowAllowEdit, setRowAllowEdit] = useState(null);
    const [adjustedDocDetailForTable, setAdjustedDocDetailForTable] = useState(docDetailForTable);
    const { CreateDoc, GetDocList, docList, UpdateDocInfo, setCurrentActiveDocDetails, setCurrentActiveStep, setFlagShowAIOutput, setAISuggestedTitles, setCurrentActiveDocFieldVal, setTabValuePanel } = useContext(DocContext) as DocContextPayload;
    const [documentSelected, setDocumentSelected] = useState<null | DocumentListDataModel>(null)
    const [isFeatureHidden, setIsFeatureHidden] = useState(false);
    const [flagDeleteDocumentDataPopup, setFlagDeleteDocumentDataPopup] = useState(false)
    const [flagCreateNewDocument, setFlagCreateNewDocument] = useState(false)
    const [flagRenderData, setFlagRenderData] = useState<boolean | null>()
    const { personaList } = useContext(PersonaContext) as PersonaContextPayload;
    const isMobile = screenWidth <= 1200;
    const isMobileView = screenWidth <= 600;

    // Toggles the state
    const handleFeatureToggle = () => {
        setIsFeatureHidden(!isFeatureHidden);
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {

        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            setFlagRenderData(false)
            const response: any = await agent.getBannerData.get();
            if (response.status) {
                setFlagRenderData(true)
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            } else {
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                setFlagRenderData(true)
            }
        })()
    }, [])

    useEffect(() => {
        setFlagRenderData(false)
        setTabValuePanel(0)
        setCurrentActiveDocDetails(null)
        setFlagShowAIOutput(false)
        GetTemplateList()
        if (userDetail.flagUser) {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
            (async () => {
                const response = await GetDocList()
                if (response.status) {
                    setFlagRenderData(true)
                } else {
                    let errorCode = checkForErrorCode(response);
                    if (flagCookieExpired(userDetail, errorCode)) {
                        setUserDetail(emptyUserDetail)
                        if (userDetail.flag_user_anonymous) {
                            let url = window.location.pathname
                            navigate(url, { replace: true });
                        } else {
                            setError({
                                ...error,
                                flagShowError: true,
                                message: "Session expired! Please login to continue."
                            });
                            setTimeout(() => {
                                let url = encodeURIComponent(window.location.href)
                                navigate("/login?redirect=" + url, { replace: true });
                            }, 1500);
                        }
                    } else {
                        if (flagCreditsExhausted(errorCode)) {
                            setFlagShowCreditsExhausted(true)
                        } else {
                            setError({
                                ...error,
                                flagShowError: true,
                                message: getErrorMessage(errorCode, isDev(userDetail))
                            });
                        }
                    }
                }
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            })()
        }
    }, [userDetail])

    useEffect(() => {
        let tempDocList = docList?.length ? [...docList] : []
        tempDocList?.map((docObj: DocumentListDataModel) => {
            docObj['id'] = docObj?.document_id
            docObj['value'] = docObj?.document_name
            docObj['alignment'] = "left"
            docObj['iconSrc'] = docObj?.template_icon_url != null ? docObj?.template_icon_url : docIcon
            docObj['formatted_timestamp'] = moment(docObj.updated_at_timestamp).format('DD-MM-YY HH:mm')
        })
        setDocDetailForTable(tempDocList)
    }, [docList])

    useEffect(() => {
        if (isMobile) {
            // For mobile view
            const filteredHeaderList = headerList.filter(header => header.name !== "Category");
            const modifiedHeaderList = filteredHeaderList.map(header => {
                if (header.name === "Documents") {
                    return { ...header, flagIcon: false };
                }
                if (header.name === "Date and time") {
                    return { ...header, name: "Last Modified" };
                }
                return header;
            });

            setAdjustedHeaderList(modifiedHeaderList);

            docDetailForTable.slice(0, 10).map((row: any) => {
                row[`formatted_timestamp`] = moment(row.updated_at_timestamp).format('DD-MM-YY HH:mm')
            })
            setAdjustedDocDetailForTable(docDetailForTable);
        } else {
            // For desktop view
            setAdjustedHeaderList(headerList);
            docDetailForTable.slice(0, 10).map((row: any) => {
                row[`formatted_timestamp`] = moment(row.updated_at_timestamp).format('DD-MM-YY HH:mm')
            })
            setAdjustedDocDetailForTable(docDetailForTable);
        }
    }, [isMobile, docDetailForTable]);

    const handleCreateUser = async () => {
        const payload = {
            google_recaptcha_token: null,
        };
        const response: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> =
            await Register(payload);
        if (response.status) {
            if (Array.isArray(response.data)) {
                setUserDetail({ ...response.data[0], flagUser: response.status, flagUserFromParaphrase: true })
            }
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
            setFlagLoading((prevState) => {
                return { ...prevState, flagRoundLoader: false }
            })
        }
        return response;
    };
    const handleCreateDoc = async (templateId: number) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let responseOfCreateUser: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> = { status: true }
        if (userDetail.flagUser) {
        } else {
            responseOfCreateUser = await handleCreateUser();
        }
        if (responseOfCreateUser.status) {
            setCurrentActiveDocFieldVal(null)
            setAISuggestedTitles([
                { title: "" },
                { title: "" },
                { title: "" },
                { title: "" },
                { title: "" }
            ])
            setCurrentActiveStep(0)
            const defaultPersona = personaList?.find((persona) => persona.user_default_persona_data.find((personaData) => personaData.default_persona_type_name == "General"));
            const personaId = defaultPersona?.persona_id ?? null;
            const payload = {
                template_id: templateId,
                // persona_id: userDetail.default_persona_id ? userDetail.default_persona_id : null
                persona_id: personaId
            }
            const response = await CreateDoc(payload)
            if (response.status) {
                if (response.data) {
                    if (response.data[0]) {
                        if (response.data[0].template_type_name == "Single-Page") {
                            response.data[0] !== null && navigate(`/doc/${response.data[0].document_uuid}/3/${response.data[0].template_id}`)
                        } else {
                            response.data[0] !== null && navigate(`/doc/${response.data[0].document_uuid}/1/${response.data[0].template_id}`)
                        }
                    }
                }
            } else {
                // alert("something went wrong")
                let errorCode = checkForErrorCode(response)
                if (flagCookieExpired(userDetail, errorCode)) {
                    setUserDetail(emptyUserDetail)
                    if (userDetail.flag_user_anonymous) {
                        let url = window.location.pathname
                        navigate(url, { replace: true });
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: "Session expired! Please login to continue."
                        });
                        setTimeout(() => {
                            let url = encodeURIComponent(window.location.href)
                            navigate("/login?redirect=" + url, { replace: true });
                        }, 1500);
                    }
                } else {
                    if (flagCreditsExhausted(errorCode)) {
                        setFlagShowCreditsExhausted(true)
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: getErrorMessage(errorCode, isDev(userDetail))
                        });
                    }
                }
            }
            setFlagLoading((prevState) => {
                return { ...prevState, flagRoundLoader: false }
            })
            // setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }

    }

    // const handleCreateGoogleAd = async (templateId: number) => {
    //     setFlagMultiStep(false)

    //     setFlagLoading({ ...flagLoading, flagRoundLoader: true});
    //     const payload = {
    //         template_id: templateId
    //     }
    //     const response = await CreateDoc(payload)
    //     console.log("response of createdDoc", response)
    //     setFlagLoading({ ...flagLoading, flagRoundLoader: false});
    //     if (response.status) {
    //         if (response.data) {
    //             console.log("response of created doc", response)
    //             console.log("createdDoc", createdDoc)
    //             console.log("selected template id", templateId)
    //             if (!flagMultiStep) {
    //                 setCurrentActiveStep(3) //for testing
    //                 response.data[0] !== null && navigate(`/doc/${response.data[0].document_uuid}/3/${response.data[0].template_id}`)
    //             } else {
    //                 response.data[0] !== null && navigate(`/doc/${response.data[0].document_uuid}/0/${response.data[0].template_id}`)
    //             }
    //         }
    //     } else {
    //         alert("something went wrong")
    //     }

    // }

    const deleteDocumentDataPopupOpen = (data: any) => {
        setFlagDeleteDocumentDataPopup(true)
        setDocumentSelected(data)
    }
    const deleteCancelDocumentDataPopupOpen = () => {
        setFlagDeleteDocumentDataPopup(false)
    }

    const createNewDocument = async () => {
        setFlagCreateNewDocument(true)
    }
    // const rowClicked = async (e: any) => {
    //     navigate(`/doc/${e.document_uuid}/0/${e.template_id}`)
    // }
    const rowClicked = async (e: any) => {
        if (e.template_type_name == "Single-Page") {
            navigate(`/doc/${e.document_uuid}/3/${e.template_id}`)
        } else {
            navigate(`/doc/${e.document_uuid}/3/${e.template_id}`)
        }
    }
    const renameDocumentName = async (value: any, data: any, keyPressed: string) => {

        const payload = {
            document_uuid: data.document_uuid,
            document_name: value
        }

        if (keyPressed == "escape" && docList != null) {

            let tempDocData: any = adjustedDocDetailForTable.map((ele: any) => {

                const documentName = docList.find((v: DocumentListDataModel) => ele.document_uuid == data.document_uuid);

                return {
                    ...ele,
                    document_name: documentName ? documentName.document_name : ele.document_name
                }
            })

            setAdjustedDocDetailForTable(tempDocData)
        }
        else {
            if (value.length != 0) {
                let response = await UpdateDocInfo(payload)
                let tempDocData: any = adjustedDocDetailForTable.map((ele) => {
                    if (response?.data?.length) {
                        if (ele.document_uuid == response?.data[0].document_uuid) {
                            var obj = { ...ele }
                            obj.document_name = response?.data[0].document_name
                            return obj
                        } else {
                            return ele
                        }
                    }
                })
                setAdjustedDocDetailForTable(tempDocData)
            }
            else {
                setAdjustedDocDetailForTable(adjustedDocDetailForTable)
            }
        }
        setRowAllowEdit(null)
    };
    const handleDeleteDocument = async (data: any) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });

        const response = await agent.deleteDocument.delete(data)
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Document Deleted Successfully!" })
            await GetDocList()
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong, Please try again!" })
        }
        deleteCancelDocumentDataPopupOpen()
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    const showTrialBanner = () => {
        const productName = getStripeProduct(userDetail);
        if (!(isDev(userDetail) || (productName === "Premium"))) {
            return true;
        } else {
            return false;
        }
    }
    const getFormattedDate = (date: any) => {
        const inputDate = new Date(date);
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = inputDate.toLocaleDateString('en-US', options);
        return formattedDate;
    };
    const getUserPlanDetail = (userDetail: any) => {
        if (userDetail.plan_detail.credit_usage_data.stripe_product_name === "Premium" && userDetail.plan_detail.credit_usage_data.stripe_customer_subscription_interval === "10 days") {
            return "Free Trial"
        }
        else if (userDetail.plan_detail.credit_usage_data.stripe_product_name === "Premium") {
            return "Premium"
        }
        else if (userDetail.plan_detail.credit_usage_data.stripe_product_name === "Free") {
            return "Free"
        }
        else {
            return "Nothing"
        }
    }
    const getDescription = (userDetail: any) => {
        if (!userDetail.flag_user_anonymous && userDetail.flag_email_verified) {
            let planDetail = getUserPlanDetail(userDetail)
            let valid_date = getFormattedDate(userDetail?.plan_detail?.credit_usage_data?.credit_end_date)
            switch (planDetail) {
                case "Free Trial":
                    return {
                        data: {
                            description: `Your 10-day free trial will end on ${valid_date}! Upgrade for unlimited access to premium features.`,
                            btnText: "Buy Plan"
                        }
                    }
                case "Anonymous":
                    return {
                        data: {
                            description: "Experience Writewiz with a 10-day premium trial, absolutely free! Sign up now.",
                            btnText: "Sign Up"
                        }
                    }
                // case "Premium":
                //     return{
                //         data: {
                //             description: "You're currently enjoying our premium plan. Enhance your benefits by upgrading your plan for even more perks",
                //             btnText: "Upgrade Plan"
                //         }
                //     }
                case "Free":
                    return {
                        data: {
                            description: "Currently enjoying our free plan? Elevate your experience with our best plan for premium features.",
                            btnText: "Upgrade Plan"
                        }
                    }
                default:
                    return {
                        data: {
                            description: "Experience the amazing features of Writewiz. Select the plan that suits your needs.",
                            btnText: "Choose Plan"
                        }
                    }
            }
        }
        else {
            return {
                data: {
                    description: "Experience Writewiz with a 10-day premium trial, absolutely free! Sign up now.",
                    btnText: "Sign Up"
                }
            }
        }
    }
    return (
        <>
            {flagDeleteDocumentDataPopup &&
                <GlobalPopUp
                    open={flagDeleteDocumentDataPopup}
                    onClose={() => { setFlagDeleteDocumentDataPopup(false) }}
                    title={""}
                    flagShowCloseIcon={true}
                    handleClose={() => { setFlagDeleteDocumentDataPopup(false) }}
                >
                    <DeleteDocumentDataComponent
                        documentSelected={documentSelected != null && documentSelected.document_uuid}
                        deleteDescription="Are you sure you want to delete this document?"
                        deleteCancelDocumentDataPopupOpen={deleteCancelDocumentDataPopupOpen}
                        handleDeleteDocument={handleDeleteDocument} />
                </GlobalPopUp>
            }
            {flagCreateNewDocument &&
                <GlobalPopUp
                    open={flagCreateNewDocument}
                    onClose={() => { setFlagCreateNewDocument(false) }}
                    title={""}
                    flagShowCloseIcon={false}
                    handleClose={() => { setFlagCreateNewDocument(false) }}
                    className={globalStyles.newDocumentPopup}
                >
                    <Grid item >
                        <Template />
                    </Grid>
                </GlobalPopUp>
            }
            <Grid item sx={{ width: "100%" }}>
                <Grid container direction={"column"} alignItems={"center"} justifyContent={"start"} className={globalStyles.dashboardChildWrapper}>

                    <Grid item sx={{ width: "100%", marginBottom: "20px" }}>
                        <Grid container sx={{ justifyContent: "start" }}>
                            {window.screen.width > 600 &&
                                <Grid item xs={12}>
                                    <Typography component={'span'} sx={{ fontSize: "18px", fontWeight: "600", color: "#1F384C" }}>
                                        Home
                                    </Typography>
                                </Grid>
                            }
                            {/* <Grid item xs={12} >
                                <Typography component={'span'} className={`${styles.homeWelcomeText}`}>
                                    Welcome to Writewiz
                                    {userDetail.flag_user_anonymous ? "" : `, ${userDetail.user_first_name.charAt(0).toUpperCase() + userDetail.user_first_name.slice(1)}  ${userDetail.user_last_name ? userDetail.user_last_name.charAt(0).toUpperCase() + userDetail.user_last_name.slice(1) : ""}`}
                                </Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>

                    {flagChromeDesktop &&
                        <Grid item xs={12} sx={{ width: "100%", margin: "0px 0px 15px" }}>
                            <Grid container className={styles.HomeBannerTextContainer}>
                                <Grid item sx={{ width: "100%" }} className={isMobileView ? `${styles.HomeBannerBgHomeItem}` : `${styles.HomeBannerBgDesktopItem}`} >
                                    <Grid container sx={{ padding: "25px 25px" }}>
                                        <Grid item xs={12} sx={{ margin: "0px 0px 8px" }}>
                                            <Typography component={'span'} className={`${styles.HomeBannerMainText}`}>
                                                Bring Writewiz with you everywhere
                                            </Typography>
                                        </Grid>
                                        <Grid item sx={{ margin: "0px 0px 20px", maxWidth: "100%", width: "98%" }}>
                                            <Typography component={'span'} className={`${styles.HomeBannerSubText}`} >
                                                Effortlessly craft & tailor your writing, from quick Gmail replies to detailed content creation across diverse platforms in your browser.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item>
                                                    <Button
                                                        variant='contained'
                                                        size='medium'
                                                        fullWidth
                                                        startIcon={<img src={chromeIcon}></img>}
                                                        className={`${styles.HomeBannerBtn}`}
                                                        onClick={() => { window.open('https://chrome.google.com/webstore/detail/writewiz-ai-content-creat/nikfnkpdalalkollmjgjkgmfblfmlpbd?hl=en&authuser=0 _blank') }}
                                                    >Add to Browser</Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>}

                    {showTrialBanner() &&
                        <Grid item sx={{ width: "100%", marginBottom: "20px" }}>
                            <TrialBannerDesign description={getDescription(userDetail)?.data.description} btnText={getDescription(userDetail)?.data.btnText} />
                        </Grid>
                    }

                    {/* featured list */}
                    <Grid item className={globalStyles.fullwidth}>
                        <Grid container sx={{ alignItems: "baseline", justifyContent: "space-between" }}>
                            <Grid item className={`${globalStyles.dashboardChildSectionTextStyle} ${styles.featureItem}`}>
                                Start a new document
                            </Grid>
                            {window.screen.width <= 600 &&
                                <Grid item xs={6} className={styles.hideFeatureBtnItem}>
                                    <Button
                                        variant='text'
                                        size='small'
                                        className={styles.hideFeatureUpIconBtn}
                                        sx={{ alignItems: "center" }}
                                        endIcon={isFeatureHidden ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                        onClick={handleFeatureToggle}
                                    >
                                        {isFeatureHidden ? 'Show Featured' : 'Hide Featured'}
                                    </Button>
                                </Grid>
                            }
                            {
                                (isFeatureHidden == false) ?
                                    (
                                        <>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Grid container className={`${styles.featuredTilesWrapper} ${styles.featureTemplateAlignment} ${styles.featuredGap}`}>
                                                    {/* static blank */}
                                                    {/* <Grid item className={`${globalStyles.clickable} ${styles.featuredTemplateTileStyle} ${styles.fetureTempleLength}`} onClick={(e) => { createNewDocument() }} >
                                                        <Grid container >
                                                            <Grid item className={`${styles.featuredTemplateImgItem}`} >
                                                                <img src={addIcon} className={styles.featureIconStyle} />
                                                            </Grid>
                                                            <Grid item >Blank</Grid>
                                                        </Grid>
                                                    </Grid> */}
                                                    {templateList !== null && templateList[0].featured_list.map((featuredTemplate) => (
                                                        <>
                                                            <Grid item className={`${globalStyles.clickable} ${styles.featuredTemplateTileStyle} ${styles.fetureTempleLength} ${styles.featureTemplateAlignment}`} onClick={() => { handleCreateDoc(featuredTemplate.template_id) }} >
                                                                <Grid container alignItems={"center"} className={globalStyles.featuredTempMainContainer}>
                                                                    <Grid item className={`${styles.featuredTemplateImgItem}`}>
                                                                        <img alt="featureIcon" src={featuredTemplate.template_icon_url == null ? "" : featuredTemplate.template_icon_url} className={styles.featureIconStyle} />
                                                                    </Grid>
                                                                    <Grid item>{featuredTemplate.template_name}</Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    ))
                                                    }
                                                    <Grid item className={`${globalStyles.clickable} ${styles.featuredTemplateTileStyle} ${styles.fetureTempleLength} ${styles.featuredTemplateViewAllStyle}`}
                                                        onClick={() => { navigate("/dashboard/templates") }} >
                                                        <Grid container sx={{ alignItems: "center" }} className={globalStyles.featureAllTempLabelContainer}>
                                                            <Grid item sx={{ alignItems: "center", display: "flex" }} className={`${styles.featuredTemplateImgItem}`}>
                                                                <img alt="featureIcon" src={coloredTemplateIcon} className={styles.featureIconStyle} />
                                                            </Grid>
                                                            <Grid item>View All templates</Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (null)
                            }
                        </Grid>
                    </Grid>

                    {/* doc list */}
                    <Grid item className={`${globalStyles.fullwidth} ${styles.HomeTableHeadingMainItem}`}>
                        <Grid container sx={{ alignItems: "baseline", justifyContent: "space-between" }}>
                            <Grid item xs={5.5} className={` ${styles.featureItem}`}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography className={`${styles.templatCardMainHeading}`} component={'span'}>
                                            Recent Documents
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={`${styles.homeTableSubHeading}`} component={'span'}>
                                            Continue where you left off
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5.5}
                                onClick={() => { navigate("/dashboard/documents") }}
                                className={`${globalStyles.dashboardChildSectionTextStylePrimary} ${styles.featureItem}`}
                                sx={{ textAlign: "end" }}
                            >
                                View All Documents
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ width: "100%" }}>
                        {flagRenderData == true ?
                            <>
                                <GlobalTable
                                    headerList={adjustedHeaderList}
                                    rowData={adjustedDocDetailForTable}
                                    tableHeight={flagChromeDesktop ? (showTrialBanner() ? "40vh" : "48vh") : "53vh"}
                                    tableContainerHeight={flagChromeDesktop ? (showTrialBanner()? "calc(100vh - 520px)" : "calc(100vh - 445px)") : ""}
                                    tableContainerMinHeight={"auto"}
                                    deleteRowData={deleteDocumentDataPopupOpen}
                                    rowAllowEdit={rowAllowEdit}
                                    renameDocumentName={renameDocumentName}
                                    flagPagination
                                    rowClicked={rowClicked}
                                    onRowClick={(e: any) => {
                                        console.log("Hello", e);
                                        // navigate(`/doc/${e.document_uuid}/0/${e.template_id}`)
                                    }}
                                // onClick={() => { navigate(`/doc/${doc.document_uuid}/0/${doc.template_id}`) }}
                                />
                            </> : <></>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default HomeComponent;