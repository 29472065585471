import React, { useContext, useEffect, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/campaigns.module.scss'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Typography } from '@mui/material'
import { CommonResponseModel, CommonResponseSecondaryModel, RegisterDataResponseModel, SendOTPResponseDataModel, createNotePayloadModel, getNotesCategoryResponseModel } from '../../../models/axiosModel'
import { NoteContext, NoteContextPayload } from '../../../context/noteContext'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import GlobalForm from '../../utility/formWiz/globalForm'
import GlobalPageHeaderText from '../../utility/globalPageHeaderText'
import { checkForErrorCode, flagCreditsExhausted, getErrorMessage, isDev, emptyUserDetail, flagCookieExpired } from '../../../utils/constants'
import GlobalPopUp from '../../utility/globalPopUp'
import AddNoteCategory from './addNoteCategory'


interface ChangeCategory {
    flagNewCategoryAdded: any,
    setFlagNewCategoryAdded: any
}
const CreateNote = (props: ChangeCategory) => {

    const navigate = useNavigate()
    const {
        setFlagLoading,
        success,
        setSuccess,
        error,
        setError,
        setFlagShowCreditsExhausted,
        flagLoading
    } = useContext(UserContext) as UserContextPayload;
    const { createNote, categoryList, GetNotesCategoryListApi } = useContext(NoteContext) as NoteContextPayload;
    const { userDetail, Register, setUserDetail } = useContext(UserContext) as UserContextPayload;
    const [submissionID, setsubmissionID] = React.useState(null);
    const [renderValue, setRenderValue] = React.useState({
        status: null,
        message: "",
        submission_id: null
    })
    const [flagCreateBtnDisabled, setFlagCreateBtnDisabled] = useState(true)
    const [flagShowCategoryForm, setFlagShowCategoryForm] = useState(false)

    let options = [{
        field_option_data_id: 101,
        field_option_value: "Add New Category"
    }]


    let options2 = categoryList?.map(category => ({
        field_option_data_id: category.notes_category_id,
        field_option_value: category.notes_category_name
    }))

    options = options2 ? options.concat(options2) : options

    const notesDetailsForm = [
        {
            "page_field_id": 1666,
            "label": "Note Title",
            "placeholder": "Write Title",
            "required": true,
            "name": "1666_Note_Title",
            "type": "text",
            "value": "",
            "size": "small",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "class2": "globalTextfieldStyle"
        },
        {
            "page_field_id": 1667,
            "label": "Note Category",
            "placeholder": "Select Category",
            "required": false,
            "name": "1667_Note_Category",
            "type": "dropdown",
            "value": "",
            "size": "small",
            "hidden": false,
            "position": null,
            "options": options,
            "font_color": null,
            "col": 12,
        },
        {
            "page_field_id": 1668,
            "label": "Provide the content for your note",
            "placeholder": "Enter Content",
            "required": true,
            "name": "1668_Content_Note",
            "type": "longtext",
            "value": "",
            "hidden": false,
            "size": "small",
            "position": null,
            "font_color": null,
            "col": 12,
            "rows": 5,
            "class2": "globalTextfieldStyle"
        },
    ]

    const [createNoteFormData, setCreateNoteFormData] = useState<any>({
        data: {
            global_form_field: notesDetailsForm,
            widget_flag_auto_save: false,
            widget_flag_hide_button: false,
            widget_form_full_width: true,
            doc_page_theme: [
                {
                    "page_id": "682",
                    "page_theme_font_type": "Open Sans",
                    "page_theme_font_style": "normal",
                    "page_theme_font_align": "left",
                    "page_theme_font_weight": "bold",
                    "page_theme_font_size": "15",
                    "page_theme_background_color": "#FFFFFF",
                    "page_theme_heading_color": "#EFE4F1",
                    "page_theme_text_decoration": "unset",
                    "page_hide_button": false,
                    "page_auto_save": false,
                    "page_font_spacing": null,
                    "page_theme_row_spacing": "10px 0px 10px 0px",
                    "page_theme_column_spacing": 0,
                    "margin": "0px 0px 8px 0px"
                }
            ]
        }
    })

    
    const formSubmitFunction = (data: any) => {
        var finalData = data;
        // RegisterUser(data)
    }

    const handleCloseSnackBar = () => {
        setRenderValue({
            status: null,
            message: "",
            submission_id: null
        })
    }

    useEffect(()=>{
        let temp = {...createNoteFormData}

        temp.data.global_form_field[1].options =  categoryList?.map(category => ({

            field_option_data_id: category.notes_category_id,
            field_option_value: category.notes_category_name
        }))
        let additionalOption = {
            field_option_data_id:101,
            field_option_value:"Add New Category"
        }
        temp.data.global_form_field[1].options.splice(0,0,additionalOption)
        props.flagNewCategoryAdded ? temp.data.global_form_field[1].value =  temp.data.global_form_field[1].options[options.length-1].field_option_value : temp.data.global_form_field[1].value = ""
        setCreateNoteFormData(temp)
    }, [categoryList])

    const setFieldValue = (data: any) => {
        var tempdata = { ...createNoteFormData }
        tempdata.data.global_form_field.map((newvalue: any) => {
            if (newvalue.options) {
                newvalue.options.map((option_value: any) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
        })
        if (createNoteFormData.data.global_form_field[1].value === "Add New Category") {
            tempdata.data.global_form_field[1].value = "Select Category";
            setFlagShowCategoryForm(true)
        }
        
        if(createNoteFormData.data.global_form_field[1].value === "Add New Category" && !props.flagNewCategoryAdded){
            tempdata.data.global_form_field[1].value = "Select Category";
        }
        setCreateNoteFormData(tempdata)

        if (createNoteFormData.data.global_form_field[0].value.trim() && createNoteFormData.data.global_form_field[2].value.trim()) {
            setFlagCreateBtnDisabled(false)
        }        
        else {
            setFlagCreateBtnDisabled(true)
        }
    }
    const handleCreateUser = async () => {
        const payload = {
            google_recaptcha_token: null,
        };
        const response: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> =
            await Register(payload);
        if (response.status) {
            if (Array.isArray(response.data)) {
                setUserDetail({ ...response.data[0], flagUser: response.status, flagUserFromParaphrase: true })
            }
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    };

    const handleSubmitCreatedNote = async () => {
        if (userDetail.flagUser) {
        } else {
            await handleCreateUser();
        }
        const selectedCategoryValue = createNoteFormData.data.global_form_field[1].value;
        const selectedCategory = createNoteFormData.data.global_form_field[1].options?.find((option:any) => option.field_option_value === selectedCategoryValue)
        let payload: createNotePayloadModel = {
            notes_category_id: selectedCategory? selectedCategory.field_option_data_id : null,
            user_notes_title: createNoteFormData.data.global_form_field[0].value,
            user_notes_content: createNoteFormData.data.global_form_field[2].value
        }

        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let response = await createNote(payload)

        if (response.status) {
            if (response.data) {
                let responseNoteId = response.data.user_notes_id
                navigate(`/dashboard/notes/create?user_notes_id=${responseNoteId}`)
                setCreateNoteFormData(response.data)
                setSuccess({ ...success, flagShowSuccess: true, message: "Note Created Successfully!" })
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }
        }
        else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    }
    return (
        <>
        {
            flagShowCategoryForm && 
            <GlobalPopUp
                open={flagShowCategoryForm}
                onClose={() => { setFlagShowCategoryForm(false) }}
                handleClose={() => { setFlagShowCategoryForm(false) }}
                flagShowCloseIcon={true}
                borderRadius="10px"
            >
                <AddNoteCategory setFlagShowCategoryForm={setFlagShowCategoryForm} setFlagNewCategoryAdded={props.setFlagNewCategoryAdded} />
            </GlobalPopUp>
        }
            <Grid container className={`${styles.cmpGlobalHeadSpacing}`}>
                <Grid item xs={12} className={styles.cmpGlobalHeader}>
                    <GlobalPageHeaderText
                        mainHeadText={"Note"}
                    />
                </Grid>
                <Grid item xs={12} className={`${styles.cmpCreateFormHeight} ${styles.cmpGlobalComponentBorderItem}`}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        component='span'
                                        className={styles.cmpCreateCampaignText}
                                    >
                                        Create your note
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <hr className={styles.cmpCreateDivider} />
                        </Grid>

                        <Grid item xl={6} lg={7} md={10} sm={12} xs={12}>
                            <Grid container>
                                <GlobalForm
                                    fields={createNoteFormData.data.global_form_field}
                                    submitFunction={formSubmitFunction}
                                    checkFormSubmit={renderValue}
                                    handleCloseSnackBar={handleCloseSnackBar}
                                    pageThemeData={createNoteFormData.data.doc_page_theme[0]}
                                    formData={createNoteFormData.data}
                                    submissionID={submissionID}
                                    setFieldValue={setFieldValue}
                                    recaptchaFlag="true"
                                    defaultLoader={false}
                                    formEditable={true}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={styles.cmpCreateButton}>
                            <Button
                                variant='contained'
                                size='medium'
                                className={flagCreateBtnDisabled ? `${styles.cmpGlobalContainedDisabledBtnStyles}` : `${styles.cmpGlobalContainedBtnStyles}`}
                                disabled={flagCreateBtnDisabled}
                                onClick={() => {

                                    handleSubmitCreatedNote()
                                }}
                            >
                                Create Note
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CreateNote