import { Autocomplete, Button, Checkbox, Chip, Drawer, Grid, IconButton, Popper, TextField } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from "../../../assets/styles/componentStyles/paraphrasing.module.scss";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { ParaphraseContext, ParaphraseContextPayload } from '../../../context/paraphraseContext';
import CustomDrawer from './mobileDropdownDrawer';
interface BasicParaphrasingTabProps {
    morePrompts: any;
    length: { name: string; optionsList: { length: string; characterCount: string; }[]; }[];
    CustomPaper: any;
    mobileScreen: any;
}
const BasicParaphrasingTab = (props: BasicParaphrasingTabProps) => {
    const {
        morePrompts,
        length,
        CustomPaper,
        mobileScreen
    } = props;
    const { paraphrasingVersioningArr, setParaphraseVersioningarr, paraphrasingVersionIndex, drawerProps, setDrawerProps } = useContext(ParaphraseContext) as ParaphraseContextPayload;
    const [drawerOpen, setDrawerOpen] = useState<any>(false);
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [searchValue, setSearchValue] = useState<any>('');

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleAutocompleteChange = (event: any, values: any) => {
        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: number) =>
                index === paraphrasingVersionIndex
                    ? { ...item, basicParaphrasingVal: { ...item.basicParaphrasingVal, tone_of_voice: values } }
                    : item
            )
        );
    };
    const handleCheckboxChange = (optionName: string, isChecked: boolean) => {
        const newToneOfVoice = isChecked
            ? [...paraphrasingVersioningArr[paraphrasingVersionIndex].basicParaphrasingVal.tone_of_voice, optionName]
            : paraphrasingVersioningArr[paraphrasingVersionIndex].basicParaphrasingVal.tone_of_voice.filter((tone: any) => tone !== optionName);

        setParaphraseVersioningarr((prevState: any) =>
            prevState.map((item: any, index: number) =>
                index === paraphrasingVersionIndex
                    ? { ...item, basicParaphrasingVal: { ...item.basicParaphrasingVal, tone_of_voice: newToneOfVoice }, advanceParaphrasingVal: { ...item.advanceParaphrasingVal, tone_of_voice: newToneOfVoice } }
                    : item
            )
        );
    };
    const handleSetMobileDrawer = (dropdownOptions: any, paraphrasingPayloadKeyValue: string, optionKeyName: string, paraphrasingTabKey: string | null, isValMultiple: boolean, optionImage: string | null, dropdownName: string) => {
        setDrawerProps({
            ...drawerProps,
            openDrawer: true,
            dropdownOptions: dropdownOptions,
            paraphrasingPayloadKeyValue: paraphrasingPayloadKeyValue,
            optionKeyName: optionKeyName,
            paraphrasingTabKey: paraphrasingTabKey,
            isValMultiple: isValMultiple,
            optionImage: optionImage,
            dropdownName: dropdownName
        })
    }

    return (
        <>
            <Grid item xs={12} className={styles.inputOptionsCont} sx={{ mb: "10px" }}>
                <Grid container sx={{ gap: "5px", justifyContent: "space-between" }}>
                    <Grid item
                        // xs={12}
                        xs={5.85}
                        className={styles.inputOptionsCont} >
                        <Grid container direction="column">
                            <Grid item className={styles.ppOptionsHeader}>
                                Tone of Voice
                                {/* {tonOfVoice[0].name} */}
                            </Grid>
                            <Grid item>
                                <>
                                    <Autocomplete
                                        multiple
                                        clearIcon={<></>}
                                        className={styles.autocompleteParaphrasing}
                                        // className={" multiple_chips__autocomplete formWiz-input-field"}
                                        disablePortal
                                        id="toneOfVoice"
                                        blurOnSelect={true}
                                        onOpen={() => { handleSetMobileDrawer(morePrompts, "tone_of_voice", "rewrite_tone_name", "basicParaphrasingVal", true, "rewrite_tone_icon_url", "Tone of Voice") }}
                                        options={morePrompts.map((prompt: any) => prompt.rewrite_tone_name)}
                                        value={paraphrasingVersioningArr[paraphrasingVersionIndex].basicParaphrasingVal.tone_of_voice}
                                        onChange={(event, values: any) => {
                                            setParaphraseVersioningarr((prevState: any) =>
                                                prevState.map((item: any, index: any) =>
                                                    index == paraphrasingVersionIndex ?
                                                        {
                                                            ...item,
                                                            basicParaphrasingVal: { ...item.basicParaphrasingVal, tone_of_voice: values },
                                                            advanceParaphrasingVal: { ...item.advanceParaphrasingVal, tone_of_voice: values }
                                                        } : item
                                                ))
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    label={option}
                                                    deleteIcon={<CloseIcon />}
                                                    {...getTagProps({ index })}
                                                    avatar={
                                                        <img
                                                            src={
                                                                morePrompts.find((prompt: any) => prompt.rewrite_tone_name === option)
                                                                    ?.rewrite_tone_icon_url
                                                            }
                                                            alt="icon"
                                                            style={{ width: '17px', height: '17px' }} // Adjust the size as needed
                                                        />
                                                    }
                                                />
                                            ))
                                        }
                                        renderOption={(props, option, state) => (
                                            <li {...props}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={morePrompts.find((prompt: any) => prompt.rewrite_tone_name == option).rewrite_tone_icon_url} alt="icon" style={{ width: '17px', height: '17px', marginRight: '8px' }} />
                                                    {option}
                                                </div>
                                            </li>
                                        )}
                                        PaperComponent={CustomPaper}
                                        PopperComponent={(props) => (
                                            <Popper {...props} placement="top" />
                                        )}
                                        renderInput={(params: any) => <TextField {...params} placeholder='Select' />} />
                                </>
                                {/* {mobileScreen &&
                            <>
                                <CustomDrawer
                                    drawerOpen={drawerOpen}
                                    handleDrawerClose={() => setDrawerOpen(false)}
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    dropdownOptions={morePrompts}
                                    paraphrasingVersioningArr={paraphrasingVersioningArr}
                                    paraphrasingVersionIndex={paraphrasingVersionIndex}
                                    optionKeyName='rewrite_tone_name'
                                    paraphrasingObjKeyName="basicParaphrasingVal"
                                    paraphrasingObjKeyNameTest="tone_of_voice"
                                    optionImage="rewrite_tone_icon_url"
                                    isMultiple={true}
                                />
                            </>
                        } */}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item
                        // xs={mobileScreen ? 12 : 5.5}
                        xs={5.85}
                        className={styles.inputOptionsCont}>
                        <Grid container direction="column">
                            <Grid item className={styles.ppOptionsHeader}>Length</Grid>
                            <Grid item>
                                <Autocomplete
                                    onOpen={() => { handleSetMobileDrawer(length[0].optionsList, "sentence_length", "length", "basicParaphrasingVal", false, null, "Length") }}
                                    className={styles.autocompleteParaphrasing}
                                    disablePortal
                                    disableClearable
                                    id="length"
                                    options={length[0].optionsList?.map((listItem) => listItem.length)}
                                    value={paraphrasingVersioningArr[paraphrasingVersionIndex].basicParaphrasingVal.sentence_length}
                                    onChange={(event, values: any) => {
                                        setParaphraseVersioningarr((prevState: any) =>
                                            prevState.map((item: any, index: any) =>
                                                index == paraphrasingVersionIndex ?
                                                    { ...item, basicParaphrasingVal: { ...item.basicParaphrasingVal, sentence_length: values } } : item
                                            ))
                                    }}
                                    PopperComponent={(props) => (
                                        <Popper {...props} placement="top" className={styles.singleStructureDropdown} />
                                    )}
                                    renderInput={(params) => <TextField
                                        {...params} placeholder='Select' />} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}

export default BasicParaphrasingTab;