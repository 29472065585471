import { Autocomplete, Button, Grid, IconButton, Menu, TextField, Typography } from '@mui/material';
import { UIEvent, useContext, useEffect, useState } from 'react';
import { UserContext, UserContextPayload } from '../../context/userContext';
import { useNavigate } from 'react-router-dom';
import { TemplateContext, TemplateContextPayload } from '../../context/templateContext';
import { DocContext, DocContextPayload } from '../../context/docContext';
import globalStyles from "../../assets/styles/global.module.scss";
import styles from "../../assets/styles/componentStyles/template.module.scss";
import docIcon from "../../assets/document_30px.svg";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CommonResponseModel, CommonResponseSecondaryModel, RegisterDataResponseModel, SendOTPResponseDataModel, TemplateListDataModel } from '../../models/axiosModel';
import IconsColor from '../utility/iconsColor';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import agent from '../../services/api';
import DeleteIcon from '@mui/icons-material/Delete';
import { PersonaContext, PersonaContextPayload } from '../../context/personaContext';
import GlobalSearch from '../utility/GlobalSearch/GlobalSearch';
import noDataFound from '../../assets/CampaignNoDataFound.png'
import GlobalPageHeaderText from '../utility/globalPageHeaderText';
import { checkForErrorCode, emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, isDev, isUserRegistered } from '../../utils/constants';
import GlobalButton from '../utility/globalButton/button';
import AddIcon from '@mui/icons-material/Add';


const Template = () => {
    const navigate = useNavigate()
    const { userDetail, setFlagLoading, setSuccess, success, setError, error, flagLoading, Register, setUserDetail, flagShowCreditsExhausted, setFlagShowCreditsExhausted, setShowLoginPopup } = useContext(UserContext) as UserContextPayload;
    const { GetTemplateList, templateList, setCustomTemplateTemplateFormFieldList, setCustomTemplatePromptActionData } = useContext(TemplateContext) as TemplateContextPayload;
    const { CreateDoc, setCurrentActiveDocDetails, setCurrentActiveStep, setFlagShowAIOutput, setFlagGenerateTitleButtonClicked, setDocumentEditorData, setCurrentActiveDocFieldVal, setAISuggestedTitles, setTabValuePanel } = useContext(DocContext) as DocContextPayload;
    const { personaList } = useContext(PersonaContext) as PersonaContextPayload;
    const [currentClickedCategoryId, setCurrentClickedCategoryId] = useState(-1);
    const [scrollEffect, setScrollEffect] = useState(false);
    const [isFeatureHidden, setIsFeatureHidden] = useState(false);
    const [hoverCategory, setHoverCategory] = useState<number | null>()
    const [currentClickedCategoryName, setCurrentClickedCategoryName] = useState<string | null>("All");
    const [searchTemplateResults, setSearchTemplateResults] = useState<TemplateListDataModel[] | null>();
    const [openMenu, setOpenMenu] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [customTemplateId, setCustomTemplateID] = useState<number | null>(null)
    const [flagRenderCards, setFlagRenderCards] = useState<boolean | null>(null)
    // const { open, hide, show } = useUserback();

    // Toggles the state
    const handleFeatureToggle = () => {
        setIsFeatureHidden(!isFeatureHidden);
    }

    // const handleWidget = () => {
    //     debugger
    //     alert('hello')
    //     open('general', 'screenshot')
    // }

    useEffect(() => {
        if (templateList !== null) {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            setFlagRenderCards(false)
            setSearchTemplateResults(templateList)
            setFlagRenderCards(true)
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    }, [templateList])


    useEffect(() => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        setCustomTemplateTemplateFormFieldList([])
        setCustomTemplatePromptActionData("")
        setCurrentActiveDocDetails(null)
        setTabValuePanel(0)
        setFlagShowAIOutput(false)
        GetTemplateList()
        if (userDetail.flagUser) {
            (async () => {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true });
                // await GetDocList()
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            })()
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }, [userDetail])
    const handleCreateUser = async () => {
        const payload = {
            google_recaptcha_token: null,
        };
        const response: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> =
            await Register(payload);
        if (response.status) {
            if (Array.isArray(response.data)) {
                setUserDetail({ ...response.data[0], flagUser: response.status, flagUserFromParaphrase: true })
            } else {
                setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
            }
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    };
    const handleCreateDoc = async (templateId: number) => {
        if (userDetail.flagUser) {
        } else {
            await handleCreateUser();
        }
        setAISuggestedTitles([
            { title: "" },
            { title: "" },
            { title: "" },
            { title: "" },
            { title: "" }
        ])
        setCurrentActiveDocFieldVal(null)
        setCurrentActiveDocDetails(null)
        setTabValuePanel(0)
        setDocumentEditorData(null)
        setFlagGenerateTitleButtonClicked(false)
        setCurrentActiveStep(0)
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const defaultPersona = personaList?.find((persona) => persona.user_default_persona_data.find((personaData) => personaData.default_persona_type_name == "General"));
        const personaId = defaultPersona?.persona_id ?? null;
        const payload = {
            template_id: templateId,
            // persona_id: userDetail.default_persona_id ? userDetail.default_persona_id : null
            persona_id: personaId

        }
        const response = await CreateDoc(payload)
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        if (response.status) {
            if (response.data) {
                if (response.data[0]) {
                    if (response.data[0].template_type_name == "Single-Page") {
                        response.data[0] !== null && navigate(`/doc/${response.data[0].document_uuid}/3/${response.data[0].template_id}`)
                    } else {
                        response.data[0] !== null && navigate(`/doc/${response.data[0].document_uuid}/1/${response.data[0].template_id}`)
                    }

                }
            }
        } else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
        }
    }

    const handleDeleteCustomTemplate = async (templateId: string) => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const response = await agent.deleteCustomTemplate.delete(templateId)
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Template Deleted Successfully!" })
            GetTemplateList()
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong, Please try again later!" })
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }

    // old Search Function
    // const handleSearch = (results: string) => {
    //     const newTemplateList = JSON.parse(JSON.stringify(templateList || []));
    //     newTemplateList[0].category_list = newTemplateList[0].category_list.map((category: any) => ({
    //         ...category,
    //         template_data_list: category.template_data_list.filter((template: any) => {
    //             const lowerCaseDocumentName = template.template_name.toLowerCase();
    //             const lowerCaseResults = results.toLowerCase();
    //             return lowerCaseDocumentName.includes(lowerCaseResults);
    //         }),
    //     }));
    //     setSearchTemplateResults(newTemplateList);
    // };


    const handleSearch = (event: any) => {
        const query = event.target.value?.toLowerCase()
        const newTemplateList = JSON.parse(JSON.stringify(templateList || []));

        newTemplateList.forEach((data: any) => {
            data.category_list = data.category_list?.filter((category: any) => {
                return (
                    category?.category_name?.toLowerCase().includes(query) ||
                    category?.template_data_list?.some((template: any) => {
                        return (
                            (template?.template_name !== null && template?.template_name?.toLowerCase().includes(query)) ||
                            (template?.template_description !== null && template?.template_description?.toLowerCase().includes(query))
                        );
                    })
                );
            });
        });
        setSearchTemplateResults(newTemplateList);
    };

    const scrollFun = (e: UIEvent<HTMLElement>) => {
        // @ts-ignore
        if (e.target.scrollTop > 90) {
            setScrollEffect(true)
        }
        else {
            setScrollEffect(false)
        }
    }

    const handleClick = (event: any, template_id: number) => {
        setCustomTemplateID(template_id)
        setAnchorEl(event.currentTarget);
        setOpenMenu(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCustomTemplateID(null)
        setOpenMenu(false);
    };

    const handleUpdateCustomTemplate = () => {
        if (customTemplateId) {
            navigate(`/dashboard/customTemplate?template_id=${customTemplateId}`)
        }
    }

    const getCustomTemplateDisableFlag = () => {
        if (!userDetail.flag_user_anonymous && userDetail.flag_email_verified) {
            if (
                userDetail.user_role.user_role_name == "Developer" ||
                userDetail.user_role.user_role_name == "Super Admin" ||
                userDetail.user_role.user_role_name == "Admin"
            ) {
                return false
            } else if (userDetail.plan_detail && userDetail.plan_detail.credit_usage_data !== null && (userDetail.plan_detail?.credit_usage_data.stripe_product_name !== "Free" && userDetail.plan_detail?.credit_usage_data.stripe_product_name !== null)) {
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }
    const handleCreateCustomTemplate = () => {
        if (isUserRegistered(userDetail)) {
            navigate("/dashboard/customTemplate")
        } else {
            setShowLoginPopup(true)
        }
    }

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                className={styles.menuStyleSidePanel}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <MenuItem
                    className={`${styles.menuLogout} ${globalStyles.removeGlobalCss}`}
                    onClick={handleUpdateCustomTemplate}
                >
                    <Grid container alignItems={"center"}>
                        <Grid item>
                            <ListItemIcon sx={{ display: "flex", alignContent: "center", justifyContent: "center", padding: "6px" }}>
                                <AutoFixHighIcon fontSize="small" />
                            </ListItemIcon>
                        </Grid>
                        <Grid item>
                            Edit Template
                        </Grid>
                    </Grid>

                </MenuItem>
                <MenuItem className={`${styles.menuLogout} ${globalStyles.removeGlobalCss}`}
                    onClick={() => { customTemplateId && handleDeleteCustomTemplate(customTemplateId.toString()) }}
                >
                    <Grid container alignItems={"center"}>
                        <Grid item sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
                            <ListItemIcon sx={{ display: "flex", alignContent: "center", justifyContent: "center", padding: "6px" }}>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                        </Grid>
                        <Grid item>
                            Delete Template
                        </Grid>
                    </Grid>

                </MenuItem>
            </Menu>

            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} onScroll={(e: any) => scrollFun(e)} className={`${globalStyles.fullwidth} ${styles.templateWrapper}`}>
                <Grid container direction={"column"} alignItems={"center"} justifyContent={"start"} className={globalStyles.dashboardChildWrapper}>
                    {/* title header */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateSearchbarStyle} ${styles.templateItem} ${scrollEffect ? styles.titleSticky : ""}`}>
                        <Grid container sx={{ alignItems: "center" }} >
                            <Grid item xl={6} lg={5} md={12} sm={12} xs={12} sx={{ marginBottom: "10px" }}>
                                <GlobalPageHeaderText
                                    mainHeadText={"Create Content"}
                                    mainSubText={"Use our templates or design your own for increased productivity and reduced repetition"}
                                />
                            </Grid>

                            <Grid item xl={6} lg={7} md={12} sm={12} xs={12}>
                                <Grid container gap="15px" className={`${globalStyles.globalHeadMainItemRightContainer}`} sx={{ alignItems: "center" }} >
                                    <Grid item
                                    //  xl={5.5} lg={5.5} md={4.5} sm={5.5} xs={9}
                                    // className={`${globalStyles.globalHeadLeftSubItem}`}
                                    >
                                        <Grid container>
                                            <Grid item className={`${globalStyles.globalSearchbarSubItem}`}>
                                                <GlobalSearch
                                                    fullWidth
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleSearch(e) }}
                                                    size="small"
                                                    placeholder="Search"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* commenting custom template for now */}
                                    <Grid item
                                    // xl={4} lg={5.5} md={4.5} sm={5.5} xs={2}
                                    // className={`${globalStyles.globalHeadRightSubItem} `}
                                    >
                                        <Grid container sx={{ alignItems: "center" }}>
                                            {window.screen.width >= 900 &&
                                                <Grid item className={`${globalStyles.globalNewAddBtnItem}`} >
                                                    <GlobalButton
                                                        flagFullWidth
                                                        height={'45px'}
                                                        // disabled={getCustomTemplateDisableFlag()}
                                                        // disabled={isUserRegistered(userDetail) ? false : true}
                                                        buttonText={
                                                            "Create Custom Template"
                                                        }
                                                        className='secondaryButtonStyle'
                                                        flagMoveForward={true}
                                                        onClick={() => { handleCreateCustomTemplate() }}
                                                    />
                                                </Grid>
                                            }
                                            {window.screen.width <= 899 &&
                                                <IconButton
                                                    className={`${globalStyles.cmpGlobalMobileAddIcon}`}
                                                    onClick={() => { handleCreateCustomTemplate() }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            }
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* featured list */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={globalStyles.fullwidth}>
                        <Grid container sx={{ alignItems: "baseline" }}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={6} className={`${styles.featureItem}`}>
                                <Grid container justifyContent={"space-between"}>
                                    <Grid item className={`${styles.templatCardMainHeadingItem}`}>
                                        <Typography component={'span'} className={styles.templatCardMainHeading}>
                                            Featured
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {window.screen.width <= 600 &&
                                <Grid item xs={6} className={styles.hideFeatureBtnItem}>
                                    <Button
                                        variant='text'
                                        size='small'
                                        className={styles.hideFeatureUpIconBtn}
                                        sx={{ alignItems: "center", justifyContent: "end" }}
                                        endIcon={isFeatureHidden ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                                        onClick={handleFeatureToggle}
                                    >
                                        {/* {isFeatureHidden ? 'Show Feature' : 'Hide Feature'} */}
                                    </Button>
                                </Grid>
                            }
                            {
                                (isFeatureHidden == false) ?
                                    (
                                        <>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Grid container className={`${styles.featuredTilesWrapper}  ${styles.featureTemplateAlignment}`}>
                                                    {templateList !== null && templateList[0].featured_list.map((featuredTemplate) => (
                                                        <>
                                                            <Grid item onClick={() => { handleCreateDoc(featuredTemplate.template_id) }} className={`${globalStyles.clickable} ${styles.featuredTemplateTileStyle} ${styles.fetureTempleLength} ${styles.featureTemplateAlignment}`}>
                                                                <Grid container alignItems={"center"} className={globalStyles.featuredTempMainContainer}>
                                                                    <Grid item className={`${styles.featuredTemplateImgItem}`}>
                                                                        <img alt="" src={featuredTemplate.template_icon_url == null ? "" : featuredTemplate.template_icon_url} className={styles.featureIconStyle} />
                                                                    </Grid>
                                                                    <Grid item>{featuredTemplate.template_name}</Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    ))
                                                    }
                                                </Grid>
                                            </Grid>
                                        </>
                                    ) : (null)
                            }
                        </Grid>
                    </Grid>

                    {/* category list */}
                    {window.screen.width >= 600 &&
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.categoryListWrapper} stickyCategory`}>
                            <Grid container direction="column">
                                <Grid item className={`${styles.templatCategoryMainHeadingItem}`}>
                                    <Typography component={'span'} className={styles.templatCardMainHeading}>
                                        Categories
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item onMouseEnter={() => { setHoverCategory(-1) }} onMouseLeave={() => { setHoverCategory(null) }}
                                            onClick={() => { setCurrentClickedCategoryId(-1) }} className={currentClickedCategoryId == -1 ? `${styles.selectedCategoryTileStyle} ${globalStyles.clickable}` : `${styles.categoryTileStyle} ${globalStyles.clickable}`}>
                                            <Grid container alignItems={"center"} className={globalStyles.featuredTempMainContainer}>
                                                <Grid item className={`${styles.featureIconStyle}`}>
                                                    <IconsColor fillColor={currentClickedCategoryId == -1 ? "#FFFFFF" : hoverCategory == -1 ? "#FFFFFF" : "#4C4A4D"
                                                        // "#A5A5A5"
                                                    }
                                                        iconName="AllTemplates"
                                                    />
                                                </Grid>
                                                <Grid item>All Templates</Grid>
                                            </Grid>
                                        </Grid>
                                        {templateList !== null && templateList[0].category_list.map((category) => {
                                            return (
                                                <>
                                                    {category.template_data_list.length > 0 &&
                                                        <Grid item onMouseEnter={() => { setHoverCategory(category.category_id) }} onMouseLeave={() => { setHoverCategory(null) }}
                                                            onClick={() => { setCurrentClickedCategoryId(category.category_id) }}
                                                            className={currentClickedCategoryId == category.category_id ? `${styles.selectedCategoryTileStyle} ${globalStyles.clickable}`
                                                                : `${styles.categoryTileStyle} ${globalStyles.clickable}`}>
                                                            <Grid container alignItems={"center"} className={globalStyles.featuredTempMainContainer}>
                                                                <Grid item className={`${styles.featureIconStyle}`}>
                                                                    <IconsColor fillColor={currentClickedCategoryId == category.category_id ? "#FFFFFF" : hoverCategory == category.category_id ? "#FFFFFF" : "#A5A5A5"}
                                                                        iconName={category.category_name == "Blog" ? "Blog" : category.category_name == "Video" ? "Video" : category.category_name == "Social Media" ? "Social_Media" : category.category_name == "General" ? "General" : category.category_name == "Ads" ? "Ads" : "Blog"}
                                                                    />
                                                                </Grid>
                                                                <Grid item >{category.category_name}</Grid>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {/* Blog Video Social Media */}
                    {window.screen.width < 599 &&
                        <>
                            <Grid item xs={11} sx={{ width: "100%", margin: "20px 0px 20px" }}>
                                <Grid container>
                                    <Grid item xs={12} sx={{ marginBottom: "8px" }}>
                                        <span className={styles.templatCardMainHeading}>Categories</span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            sx={{ width: "100%" }}
                                            fullWidth
                                            size='small'
                                            className={`${styles.categoryListDropDown}`}
                                            options={['All', ...(templateList !== null ? templateList[0].category_list.map((ele) => ele.category_name) : [])]}
                                            value={currentClickedCategoryName}
                                            onChange={(event, newValue) => {
                                                setCurrentClickedCategoryName(newValue);
                                                if (newValue === 'All') {
                                                    setCurrentClickedCategoryId(-1);
                                                }
                                                else if (templateList && templateList[0]) {
                                                    const selectedCategory = templateList[0].category_list.find(category => category.category_name === newValue);
                                                    if (selectedCategory) {
                                                        setCurrentClickedCategoryId(selectedCategory.category_id);
                                                    }
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth className={`${styles.categodydropdowntextfield}`} />}
                                        ></Autocomplete>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }

                    {/* template card list */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={`${globalStyles.fullwidth} ${styles.templateMainItem}`}>
                        <Grid container direction={"column"}>
                            {flagRenderCards == true && flagLoading.flagRoundLoader == false ?
                                (
                                    <>
                                        {searchTemplateResults && searchTemplateResults[0].category_list.length > 0 ?
                                            (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            {searchTemplateResults[0].category_list.filter((category) => currentClickedCategoryId === -1 || category.category_id === currentClickedCategoryId).map((category) => {
                                                                return (
                                                                    <>
                                                                        {category.template_data_list.length > 0 &&
                                                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                                                                                <Grid container direction={"column"}>
                                                                                    {/* {category.category_name == "Blank" ?
                                                                                        <>
                                                                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginBottom: "15px", color: "#000", fontSize: "15px", fontWeight: "600" }}>
                                                                                                {category.category_name}
                                                                                            </Grid>
                                                                                        </> : */}
                                                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={styles.categoryNameStyle}>
                                                                                        {category.category_name}
                                                                                    </Grid>
                                                                                    {/* } */}
                                                                                    {category.category_description !== null &&
                                                                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={styles.categoryDescriptionStyle}>
                                                                                            {category.category_description}
                                                                                        </Grid>
                                                                                    }
                                                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={styles.tempCardsMainItem}>
                                                                                        <Grid container className={`${styles.tempBlogCards}`}>
                                                                                            {category.template_data_list.map((template) => (
                                                                                                <Grid item sx={{ position: "relative" }} xl={2.8} lg={4} md={5.5} sm={12} xs={12}
                                                                                                    // onClick={() => { category.category_name == "Custom" ? console.log("custom template") : handleCreateDoc(template.template_id) }}
                                                                                                    onClick={() => { handleCreateDoc(template.template_id) }}
                                                                                                    className={`${globalStyles.fullwidth} ${styles.templateTileStyle} ${globalStyles.clickable} `}>
                                                                                                    <Grid container direction="column">
                                                                                                        <Grid item className={styles.templateIconStyle}>
                                                                                                            {template.template_icon_url != null ?
                                                                                                                <img alt="" src={template.template_icon_url} />
                                                                                                                :
                                                                                                                <img alt="" src={docIcon} />
                                                                                                            }
                                                                                                        </Grid>
                                                                                                        <Grid item className={styles.templateTitleTextstyle} >
                                                                                                            {template.template_name}
                                                                                                        </Grid>
                                                                                                        <Grid item className={`${styles.templateDescriptionTextStyle} ${styles.templateDescOverflowStyle}`}>
                                                                                                            {template.template_description}
                                                                                                        </Grid>
                                                                                                        {category.category_name == "Custom" &&
                                                                                                            <Grid item sx={{ position: "absolute", bottom: "6px", right: "6px" }}><MoreVertIcon onClick={(event) => { event.stopPropagation(); handleClick(event, template.template_id) }}
                                                                                                                aria-controls={openMenu ? 'account-menu' : undefined}
                                                                                                                aria-haspopup="true"
                                                                                                                aria-expanded={openMenu ? 'true' : undefined}
                                                                                                                sx={{ cursor: "pointer", fontSize: "medium !important", color: "#7A7A7A !important" }} />
                                                                                                            </Grid>
                                                                                                        }
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            ))}
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                    </>
                                                                );
                                                            })
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    {/* <Grid item sx={{ margin: "20px 0px", width: "100%" }}>
                                                        <Grid container>
                                                            <Grid item
                                                                xl={2.8} lg={4} md={5.5} sm={12} xs={12}
                                                                className={`${styles.templateTileStyle} ${styles.cfcMainItem}`}
                                                                onClick={handleWidget}
                                                            >
                                                                <Grid container>
                                                                    <Grid item xs={12} className={styles.templateIconStyle}>
                                                                        <img src={CanNotFindImg} alt="" />
                                                                    </Grid>
                                                                    <Grid item xs={12} className={styles.cfcItemSpacing}>
                                                                        <Typography component={'span'} className={styles.cfcMainText}>
                                                                            Can't Find a content type?
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography component={'span'} className={styles.cfcSubtext}>
                                                                            Suggest what we should add next. We'll send our AI back to school to learn it!
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> */}
                                                </>
                                            ) : (
                                                <>
                                                    <Grid item xs={12} sx={{ marginTop: "100px" }} >
                                                        <Grid container sx={{ display: "block", textAlign: "center" }}>
                                                            <Grid item>
                                                                <img src={noDataFound} alt="" />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography component={'span'} sx={{ fontSize: "15px", fontWeight: "500" }}>
                                                                    No Data Found!
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )
                                        }
                                    </>
                                ) : (<></>)
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    )
}



export default Template;