import { Grid, InputLabel, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GlobalButton from '../utility/globalButton/button'
import FormControl from '@mui/material/FormControl';
// import Select from '../Editor/ui/Select'
import MenuItem from '@mui/material/MenuItem';
import { DocContext, DocContextPayload } from '../../context/docContext';
import styles from "../../assets/styles/global.module.scss"
import { UserContext, UserContextPayload } from '../../context/userContext';
import { prompts } from '../../utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';

export const MobileFooter = () => {
    const navigate = useNavigate()
    const { currentActiveStep, setCurrentActiveStep, flagShowGenerateTitlesButton, setFlagOutlineLoader, webSocketObj, documentOutlineData, documentEditorData } = React.useContext(DocContext) as DocContextPayload;
    const [btnText, setBtnText] = useState("")
    const [disableNextBtn, setDisableNextBtn] = useState(false)
    const { currentActiveDocDetails, steps, blogTitle } = React.useContext(DocContext) as DocContextPayload;
    const { setFlagLoading, flagLoading } = React.useContext(UserContext) as UserContextPayload;
    const { docId, ActiveStep } = useParams();
    useEffect(() => {
        if (currentActiveStep == 1) {
            if (flagShowGenerateTitlesButton) {
                setBtnText("Heading")
            } else {
                setBtnText("Generate Titles")
            }
        } else if (currentActiveStep == 2) {
            setBtnText("Generate Titles")
        } else {

        }
    }, [currentActiveStep, flagShowGenerateTitlesButton])


    let stepList = [
        {
            value: 1,
            label: "Step 1: Title"
        },
        {
            value: 2,
            label: "Step 2: Outline"
        },
        {
            value: 3,
            label: "Step 3: Editor"
        },
    ]
    const getNextButtonDisabledCondition = () => {
        if (currentActiveStep == 1) {
            if (blogTitle == "") {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    const handleNext = async () => {
        if (currentActiveStep == 1) {
            if (currentActiveDocDetails) {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true });

                const matchingPrompt = prompts.AISuggestions.find((prompt) => prompt.prompt_key === "1::blog_outline_section_header");
                if (matchingPrompt?.prompt_id) {
                    setFlagOutlineLoader(true)
                    const payload = {
                        document_uuid: currentActiveDocDetails.document_uuid,
                        prompt_id: matchingPrompt.prompt_id
                    }

                    webSocketObj.send(JSON.stringify({
                        "url": "ai/ask-ai",
                        "operation_id": `prompt-heading-${new Date().getTime()}`,
                        "payload": {
                            "document_uuid": payload.document_uuid,
                            "prompt_id": payload.prompt_id
                        }
                    }))
                }
            }
        }
        if (currentActiveStep == 2) {
            if (currentActiveDocDetails) {
                setFlagLoading({ ...flagLoading, flagRoundLoader: true });

                const matchingPrompt = prompts.AISuggestions.find(
                    (prompt) => prompt.prompt_key === "1::blog_editor_generation"
                );
                if (matchingPrompt?.prompt_id) {
                    const payload = {
                        document_uuid: currentActiveDocDetails.document_uuid,
                        prompt_id: matchingPrompt.prompt_id
                    }

                    webSocketObj.send(JSON.stringify({
                        "url": "ai/ask-ai",
                        "operation_id": `prompt-blog-${new Date().getTime()}`,
                        "payload": {
                            "document_uuid": payload.document_uuid,
                            "prompt_id": payload.prompt_id
                        }
                    }))
                }
            }
        }

    }
    // const handleStepChange = (event: SelectChangeEvent<number>) => {
    //     setCurrentActiveStep(Number(event.target.value || 0)); 
    //     {currentActiveDocDetails?navigate(`/doc/${currentActiveDocDetails.document_uuid}/${currentActiveStep}/${currentActiveDocDetails.template_id}`):navigate(`/dashboard`)}
    // };
    const getValidStep = (step:any) => {
        if(currentActiveStep === 1 && step.value > currentActiveStep){
            if(documentOutlineData?.length && currentActiveDocDetails?.document_title != null && step.value===2){
                setCurrentActiveStep(currentActiveStep);
                return false;
            }else{
                if(documentEditorData){
                    setCurrentActiveStep(currentActiveStep);
                    return false;
                }
                else{
                    return true;
                }
            }
        }
        else if(currentActiveStep === 2 && step.value > currentActiveStep){
            if(documentEditorData){
                setCurrentActiveStep(currentActiveStep);
                return false;
            }
            else{
                return true;
            }
        }
        else{
            setCurrentActiveStep(currentActiveStep);
            return false;
        }
    }
    const handleStepChange = (event: SelectChangeEvent<number>) => {
        setCurrentActiveStep(Number(event.target.value || 0)); 
        {currentActiveDocDetails?navigate(`/doc/${currentActiveDocDetails.document_uuid}/1/${currentActiveDocDetails.template_id}`):navigate(`/dashboard`)}
    };
    React.useEffect(() => {
        if (currentActiveDocDetails) {
            navigate(`/doc/${currentActiveDocDetails.document_uuid}/${currentActiveStep}/${currentActiveDocDetails.template_id}`)
        } else {
            // debugger;
            navigate('/dashboard')
        }
    }, [currentActiveStep])
    return (
        <>
            <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ padding: "10px", borderTop: "0.5px solid #DADADA" }}>
                <Grid item>
                    <FormControl fullWidth>
                        <Select
                            value={currentActiveStep}
                            label=''
                            onChange={(event: SelectChangeEvent<number>) => { handleStepChange(event) }}
                            className={styles.mobileFooterSelect}
                        >
                            {stepList.map((step) => {
                                return (
                                    <MenuItem key={step.value} value={step.value} disabled={getValidStep(step)}>{step.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item>
                    <GlobalButton
                        buttonText={steps !== null ? steps[currentActiveStep == 0 ? 0 : currentActiveStep - 1].nextStepText : ""}
                        className={"secondaryButtonStyle"}
                        flagMoveForward={steps !== null && steps[currentActiveStep == 0 ? 0 : currentActiveStep - 1].flagMoveForward}
                        onClick={handleNext}
                        disabled={getNextButtonDisabledCondition()}
                    />
                </Grid>
            </Grid>
        </>
    )
}
