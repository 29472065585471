import React from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import globalStyles from '../../assets/styles/global.module.scss'


const GlobalPageHeaderText = (props: any) => {

    const mobileScreen: boolean = useMediaQuery('(min-width: 0px) and (max-width: 599px)');

    return (
        <>
            <Grid container sx={{ justifyContent: "start" }}>
                {mobileScreen ? (<></>) : (
                    <Grid item xs={12}>
                        <Typography
                            component={'span'}
                            className={globalStyles.globalHeadText}
                        >
                            {props.mainHeadText}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} sx={{ marginBottom: { xs: "10px", sm: "0px" } }}>
                    <Typography
                        component={'span'}
                        className={`${globalStyles.globalDecsText}`}
                    >
                        {props.mainSubText}
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default GlobalPageHeaderText