import React, { MutableRefObject, useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import {
    CommonResponseModel,
    CreateDocResponseModel,
    CreateDocPayloadModel,
    DocumentListDataModel,
    documentStepDataModel,
    StepModel,
    updateDocumentFormRequestPayloadModel,
    UpdateDocumentInfoRequestPayloadModel,
    ExecutePromptRequestPayloadModel,
    ExecutePromptResponseDataModel,
    DocumentContentTypeResponseDataModel,
    UpdateDocumentOutlineDataPayloadModel,
    DeleteDocumentOutlineDataParamsModel,
    UpdateDocumentTitlePayloadModel,
    UpdateDocumentEditorPayloadModel,
    ExecutePromptResponseModel,
    executePromptResponseParaphraseObj,
    popupListObj,
} from "../models/axiosModel";
import agent from "../services/api";

const GenericDataContext = React.createContext<GenericDataContextPayload | undefined>(
    undefined
);

export interface GenericDataContextPayload {
    popupList: popupListObj[];
    setPopupList: React.Dispatch<React.SetStateAction<popupListObj[]>>
    GetPopupList: () => void
}

const GenericDataProvider = ({ children }: ChildrenProps) => {

    const [popupList, setPopupList] = useState<popupListObj[]>([])
    const GetPopupList = async () => {
        const response = await agent.getPopupList.get();
        if (response.status) {
            setPopupList(response.data)
        } else {
            setPopupList([])
        }
    }


    const payload = {
        popupList,
        setPopupList,
        GetPopupList
    };
    return <GenericDataContext.Provider value={payload}>{children}</GenericDataContext.Provider>;
};

export { GenericDataContext, GenericDataProvider };
