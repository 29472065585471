import { Grid, TextareaAutosize, Typography } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import styles from "../../../assets/styles/componentStyles/customTemplate.module.scss";
import GlobalButton from '../../utility/globalButton/button';
import TemplateFormPreview from './templateFormPreview';
import DraggableListComponent from './drag&drop';
import { TemplateContext } from '../../../context/templateContext';
import agent from '../../../services/api';
import MixedModeTagify from './mixedTag';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../../../context/userContext';
import { Tooltip } from '@mui/joy';
import GlobalTextField from '../../utility/globalTextfield';
import Required from '../../utility/required';
const initialTemplateFormDetails = {
    category_id: 10,
    template_name: "",
    flag_show_field: false,
    template_icon_url: "",
    template_description: "",
    template_visibility_type_name: "Private",
    template_prompt_data: {
        prompt_action_data: "",
        prompt_num_of_output: 1
    },
    template_step_form_data: [
        {
            template_form_name: "",
            template_form_button_name: "",
            template_form_field_data: [
                {
                    template_form_field_id: "ud_12",
                    form_field_input_type_id: 0,
                    template_form_field_seq_num: 0,
                    template_form_field_property: [
                        {
                            form_field_input_type_property_id: 0,
                            template_form_field_property_value: "",
                        }
                    ]
                }
            ]
        }
    ],

}
const CustomTemplate = () => {
    const navigate = useNavigate()
    const { customTemplateTemplateFormFieldList,
        setCustomTemplateTemplateFormFieldList, customTemplatePromptActionData, setCustomTemplatePromptActionData, routeSources, outputFormatGenericList } = useContext(TemplateContext);
    const { setFlagLoading, error, setError, setSuccess, success, flagLoading } = useContext(UserContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [formwizFieldsData, setFormwizFieldsData] = useState({
        data: {
            widget_form_full_width: true,
            global_form_field: [
                {
                    page_field_id: 5454,
                    label: 'Input',
                    placeholder: 'Add Input Field',
                    required: false,
                    name: '5454_Input',
                    type: 'text',
                    value: '',
                    hidden: false,
                    position: null,
                    font_color: null,
                    col: 12
                },
            ],
            widget_flag_auto_save: false,
            widget_flag_hide_button: false,
            doc_page_theme: [
                {
                    "page_id": "682",
                    "page_theme_font_type": "Open Sans",
                    "page_theme_font_style": "normal",
                    "page_theme_font_align": "left",
                    "page_theme_font_weight": "bold",
                    "page_theme_font_size": "15",
                    "page_theme_background_color": "#FFFFFF",
                    "page_theme_heading_color": "#EFE4F1",
                    "page_theme_text_decoration": "unset",
                    "page_hide_button": false,
                    "page_auto_save": false,
                    "page_font_spacing": null,
                    "page_theme_row_spacing": 2,
                    "page_theme_column_spacing": 2
                }
            ]
        }
    });
    const [templateFormDetails, setTemplateFormDetails] = useState(initialTemplateFormDetails)
    // if any existing custom template needs to be updated
    useEffect(() => {
        (async () => {
            if (searchParams.has('template_id')) {
                let customTemplateId = searchParams.get('template_id')
                setFlagLoading({ ...flagLoading, flagRoundLoader: true });
                const response = await agent.getTemplateDetail.get(customTemplateId)
                if (response.status) {
                    let temp = { ...response.data[0] }

                    temp.template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_field_data.map((field) => (
                        field.template_form_field_property = field.template_form_field_property_data
                    ))
                    temp.template_prompt_data = response.data[0].template_prompt_data
                    setLastUpdatedTemplateFormDetails(temp)
                    setTemplateFormDetails(temp)
                    setCustomTemplateTemplateFormFieldList(temp.template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_field_data)
                    // let modifiedPromptData = response.data[0].template_prompt_data.prompt_action_data
                    let modifiedPromptData = response.data[0].template_prompt_data.prompt_variant_data.find((variant) => variant.prompt_variant_id == response.data[0].template_prompt_data.default_prompt_variant_id).prompt_action_data

                    const regex = /\{\{?["']?template_form_field_id["']?:["']?(\d+)["']?\}\}/g;
                    let match;
                    while ((match = regex.exec(modifiedPromptData)) !== null) {
                        const templateFormFieldIdToFind = parseInt(match[1]);
                        const matchingFormField = response.data[0].template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_field_data.find(field => field.template_form_field_id === templateFormFieldIdToFind);
                        const propertyValueToInsert = matchingFormField ? matchingFormField.template_form_field_property_data.find(prop => prop.form_field_input_type_property_name === "Title")?.template_form_field_property_value : "";
                        const replacement = `[[{template_form_field_id:${templateFormFieldIdToFind}, value:${propertyValueToInsert}}]]`;
                        modifiedPromptData = modifiedPromptData.replace(match[0], replacement);
                        // modifiedPromptData = modifiedPromptData.replace(`{\"template_form_field_id\":${templateFormFieldIdToFind}}`, `{\"template_form_field_id\":${templateFormFieldIdToFind}, "value": "${propertyValueToInsert}"}`);
                    }



                    // for enclosing the modifiedPromptData value in string ""
                    let requiredPromptActionData = modifiedPromptData && modifiedPromptData.replace(/(\w+):(\w+(?:\s+\w+)*)/g, '"$1":"$2"');
                    setCustomTemplatePromptActionData(requiredPromptActionData)
                    // setCustomTemplatePromptActionData(response.data[0].template_prompt_data.prompt_action_data)
                } else {
                    setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later" })
                }
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }
        })()
    }, [searchParams.get('template_id')])

    const [flagShowForm, setFlagShowForm] = useState(false)


    const [lastUpdatedTemplateFormDetails, setLastUpdatedTemplateFormDetails] = useState()
    const [executePromptResponse, setExecutePromptResponse] = useState(null);

    const updateTemplateFormFields = (selectedValues) => {
        // Filter fields that exist in masterValues
        const filteredFields = formwizFieldsData.data.global_form_field.filter(field => {
            return selectedValues.some(selectedValue => selectedValue.page_field_id === field.page_field_id);
        });

        // Update formwizFieldsData with filtered fields
        setFormwizFieldsData(prevFormFields => ({
            ...prevFormFields,
            data: {
                ...prevFormFields.data,
                global_form_field: filteredFields,
            },
        }));
    };

    const addNewField = (newValue) => {
        // Generate a new page_field_id for the new field
        const lastField = formwizFieldsData.data.global_form_field[formwizFieldsData.data.global_form_field.length - 1];
        const newPageFieldId = lastField ? lastField.page_field_id + 1 : 1;

        // Create a new field object and add it to formwizFieldsData
        const newField = {
            page_field_id: newPageFieldId,
            label: newValue.label,
            placeholder: `Enter ${newValue.label}`,
            required: false,
            name: `${newPageFieldId}_${newValue.label}`,
            type: 'text',
            value: '',
            hidden: false,
            position: null,
            font_color: null,
            col: 12,
        };

        setFormwizFieldsData(prevFormFields => ({
            ...prevFormFields,
            data: {
                ...prevFormFields.data,
                global_form_field: [...prevFormFields.data.global_form_field, newField],
            },
        }));
    };
    const [masterValues, setMasterValues] = useState([])
    const [settings, setSettings] = useState({
        pattern: /@/, // <- must define "patten" in mixed mode
        dropdown: {
            enabled: 0,
            position: "text"
        },
        whitelist: []
    })

    useEffect(() => {
        const options = customTemplateTemplateFormFieldList.map(field => ({
            template_form_field_id: field.template_form_field_id,
            value: field.template_form_field_property.find(prop => prop.form_field_input_type_property_name === 'Title').template_form_field_property_value
        }));
        setMasterValues(options)
    }, [formwizFieldsData])



    const handleMasterValuesChange = (newValue) => {
        if (newValue[newValue.length - 1].__isNew__) {
            addNewField(newValue[newValue.length - 1]);
            let lastValue = masterValues[masterValues.length - 1].page_field_id
            let updatedvalue = [...newValue];
            updatedvalue[updatedvalue.length - 1].page_field_id = lastValue + 1
            setMasterValues(updatedvalue);
        } else {
            updateTemplateFormFields(newValue)
            setMasterValues(newValue);
        }
    };

    const [whiteListOptions, setWhiteListOptions] = useState([])


    const handleTemplateDescSubmit = () => {
        setFlagShowForm(true);
    }

    const transformToFormwizFormat = (arrayToTransform) => {
        const outputArray = []
        arrayToTransform.forEach(item => {
            const pageFieldId = typeof item.template_form_field_id == "string" ? parseInt(item.template_form_field_id.replace('ud-', ''), 10) : item.template_form_field_id;
            const type = item.form_field_input_type_name;
            const labelItem = item.template_form_field_property.find(prop => prop.form_field_input_type_property_name === 'Title');
            const placeholderItem = item.template_form_field_property.find(prop => prop.form_field_input_type_property_name === 'Placeholder');
            const requiredItem = item.template_form_field_property.find(prop => prop.form_field_input_type_property_name === 'Is Required?');

            const label = labelItem ? labelItem.template_form_field_property_value : '';
            const placeholder = placeholderItem ? placeholderItem.template_form_field_property_value : '';
            const required = requiredItem ? requiredItem.template_form_field_property_value : false;

            outputArray.push({
                page_field_id: pageFieldId,
                label: label,
                placeholder: placeholder,
                required: required,
                name: `${pageFieldId}_${label.replace(/ /g, '_')}`,
                // type: 'text',
                type: type.replace(/ /gi, '').toLowerCase(),
                value: '',
                hidden: false,
                position: null,
                font_color: null,
                col: 12
            });
        });
        let tempData = { ...formwizFieldsData }
        tempData.data.global_form_field = outputArray
        setFormwizFieldsData(tempData)
    }

    useEffect(() => {
        transformToFormwizFormat(customTemplateTemplateFormFieldList)

    }, [customTemplateTemplateFormFieldList])
    //for frontened after response from backend of create template

    const formatPromptActionData = (response) => {
        let modifiedPromptData = response.data[0].template_prompt_data.prompt_variant_data[0].prompt_action_data
        // const regex = /\{\{template_form_field_id:(\d+)\}\}/g;;
        const regex = /\{\{?["']?template_form_field_id["']?:["']?(\d+)["']?\}\}/g;
        let match;
        while ((match = regex.exec(modifiedPromptData)) !== null) {
            const templateFormFieldIdToFind = parseInt(match[1]);
            const matchingFormField = response.data[0].template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_field_data.find(field => field.template_form_field_id === templateFormFieldIdToFind);
            const propertyValueToInsert = matchingFormField ? matchingFormField.template_form_field_property_data.find(prop => prop.form_field_input_type_property_name === "Title")?.template_form_field_property_value : "";
            const replacement = `[[{template_form_field_id:${templateFormFieldIdToFind}, value:${propertyValueToInsert}}]]`;
            modifiedPromptData = modifiedPromptData.replace(match[0], replacement);
            // modifiedPromptData = modifiedPromptData.replace(`{\"template_form_field_id\":${templateFormFieldIdToFind}}`, `{\"template_form_field_id\":${templateFormFieldIdToFind}, "value": "${propertyValueToInsert}"}`);
        }

        // for enclosing the modifiedPromptData value in string ""
        let requiredPromptActionData = modifiedPromptData && modifiedPromptData.replace(/(\w+):(\w+(?:\s+\w+)*)/g, '"$1":"$2"');
        setCustomTemplatePromptActionData(requiredPromptActionData)

        // Assign the modified value to customTemplatePromptActionData

        const customTemplatePromptActionData = modifiedPromptData;
        // let temp = { ...templateFormDetails };

        // temp.prompt_id = response.data[0].prompt_id
        // temp.template_id = response.data[0].template_id
        // temp.category_id = response.data[0].category_id
        // temp.template_name = response.data[0].template_name
        // temp.flag_show_field = response.data[0].flag_show_field
        // temp.template_icon_url = response.data[0].template_icon_url
        // temp.template_description = response.data[0].template_description
        // temp.template_visibility_type_name = response.data[0].template_visibility_type_name
        // temp.template_steps.filter((obj) => obj.step_type_name === "Form")[0] = response.data[0].template_steps.filter((obj) => obj.step_type_name === "Form")[0]
        // temp.template_prompt_data.prompt_action_data = response.data[0].prompt_action_data
        // temp.template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_name = response.data[0].template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_name
        // temp.template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_button_name = response.data[0].template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_button_name
        // temp.template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_field_data = response.data[0].template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_field_data.map((field) => {
        //     return {
        //         template_form_field_id: field.template_form_field_id,
        //         template_form_field_flag_ai_generation: field.template_form_field_flag_ai_generation,
        //         template_form_field_seq_num: field.template_form_field_seq_num,
        //         form_field_input_type_id: field.form_field_input_type_id,
        //         form_field_input_type_name: field.form_field_input_type_name,
        //         form_field_input_type_abbr: field.form_field_input_type_abbr,
        //         template_form_field_property: field.template_form_field_property_data,
        //     }
        // })
        let temp = { ...response.data[0] }

        temp.template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_field_data.map((field) => (
            field.template_form_field_property = field.template_form_field_property_data
        ))
        temp.template_prompt_data = response.data[0].template_prompt_data
        setTemplateFormDetails(temp)
        setCustomTemplateTemplateFormFieldList(temp.template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_field_data)
        // setCustomTemplateTemplateFormFieldList(temp.template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_field_data)
        setTemplateFormDetails(response.data[0]);
        setLastUpdatedTemplateFormDetails(response.data[0])
    }

    const handleSaveTemplate = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        if (templateFormDetails.template_id) {
            // let regex = /,"value":"([^"]+)","prefix":"@"/g
            let tempFormattedPromptActionData = customTemplatePromptActionData.replace(/\[\[/gi, '[').replace(/\]\]/gi, ']')
            // let regex = /,{"template_form_field_id":"([^"]+)"}/g
            let regex = /\[{"template_form_field_id":"([^"]+)".*?}]/g;
            let formattedPromptActionData = tempFormattedPromptActionData.replace(regex, '[{"template_form_field_id":"$1"}]');
            // let formattedPromptActionData = tempFormattedPromptActionData.replace(regex, "")
            const payload = { ...templateFormDetails }

            payload.template_prompt_data.prompt_action_data = formattedPromptActionData
            // payload.template_prompt_data.prompt_action_data = customTemplatePromptActionData
            // templateFormDetails.template_prompt_data.prompt_action_data
            if (payload.template_steps && payload.template_steps.length) {
                payload.template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_name = `${templateFormDetails.template_name} form`
                payload.template_steps.filter((obj) => obj.step_type_name === "Form")[0].template_step_form_data[0].template_form_field_data = customTemplateTemplateFormFieldList

            } else {
                payload.template_step_form_data[0].template_form_name = `${templateFormDetails.template_name} form`
                payload.template_step_form_data[0].template_form_field_data = customTemplateTemplateFormFieldList

            }
            setTimeout(async () => {
                const response = await agent.updateCustomTemplate.put(payload);
                if (response.status) {
                    setSuccess({ ...success, flagShowSuccess: true, message: "Template Updated Successfully!" })
                    formatPromptActionData(response)
                } else {
                    setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later!" })
                }
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }, 3000)
        } else {
            //for sending to backend
            let tempFormattedPromptActionData = customTemplatePromptActionData.replace(/\[\[/gi, '[').replace(/\]\]/gi, ']')
            let regex = /,"value":"([^"]+)","prefix":"@"/g
            let formattedPromptActionData = tempFormattedPromptActionData.replace(regex, "")
            const payload = { ...templateFormDetails }
            payload.template_prompt_data.prompt_action_data = formattedPromptActionData
            payload.template_step_form_data[0].template_form_name = `${templateFormDetails.template_name} form`
            payload.template_step_form_data[0].template_form_field_data = customTemplateTemplateFormFieldList
            const response = await agent.createCustomTemplate.post(payload);
            if (response.status) {

                setSuccess({ ...success, flagShowSuccess: true, message: "Template Created Successfully!" })
                navigate(`/dashboard/customTemplate?template_id=${response.data[0].template_id}`)
                // console.log("response", response);
                // formatPromptActionData(response)
            } else {
                setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later!" })
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    }
    const handleCreateNewTemplate = () => {
        setTemplateFormDetails(initialTemplateFormDetails);
        setCustomTemplatePromptActionData("");
        setCustomTemplateTemplateFormFieldList([])
    }

    const handleTryTemplate = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        const templateFormFieldData = {};
        if (formwizFieldsData && formwizFieldsData.data && Array.isArray(formwizFieldsData.data.global_form_field)) {
            formwizFieldsData.data.global_form_field.forEach(field => {
                const templateFormFieldId = `template_form_field_id--${field.page_field_id}`;
                const value = Array.isArray(field.value) ? field.value.join(',') : field.value
                templateFormFieldData[templateFormFieldId] = value;
            });
        } else {
            console.error("formwizFieldsData or global_form_field is undefined or not an array");
        }

        const executePromptPayload = {
            document_uuid: "-1",
            prompt_id: templateFormDetails.prompt_id,
            template_form_field_data: templateFormFieldData,
            route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id,
            output_format_id: outputFormatGenericList.find((outputFormat) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1
        }
        const response = await agent.executePrompt.post(executePromptPayload)
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        if (response.status) {
            setExecutePromptResponse(response)
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong. Please try again later!" })
        }
        // const pay
    }
    const getTryTemplateDisabledCondition = () => {
        if (JSON.stringify(templateFormDetails) == JSON.stringify(lastUpdatedTemplateFormDetails)) {
            for (const fieldData of formwizFieldsData.data.global_form_field) {
                const value = fieldData.value
                if (value !== "") {
                    return false
                } else {
                    return true
                }
            }
        } else {
            return true
        }
    }
    const getSaveTemplateDisableCondition = () => {
        if (customTemplateTemplateFormFieldList.length !== 0 && templateFormDetails.template_name.trim() !== "" && customTemplatePromptActionData && customTemplatePromptActionData.trim() !== "") {
            return false
        }
        return true
    }
    const getUpdateTemplateDisableCondition = () => {
        if (customTemplateTemplateFormFieldList.length !== 0 && templateFormDetails.template_name.trim() !== "" && customTemplatePromptActionData && customTemplatePromptActionData.trim() !== "") {
            // if (JSON.stringify(templateFormDetails) == JSON.stringify(lastUpdatedTemplateFormDetails)) {
            //     return true
            // }
            return false
        }
        return true
    }
    const getSaveTemplateDisabledCondition = () => {
        if (templateFormDetails.template_name == "" && customTemplatePromptActionData == "") {
            return true
        } else {
            if (JSON.stringify(templateFormDetails) == JSON.stringify(lastUpdatedTemplateFormDetails)) {
                for (const fieldData of formwizFieldsData.data.global_form_field) {
                    const value = fieldData.value
                    if (value !== "") {
                        return false
                    } else {
                        return true
                    }
                }
            } else {
                return true
            }
        }

    }
    return (
        <>
            <Grid container>
                <Grid item xs={12} className={`${styles.customTemplateWrapper}`} >
                    <Grid container>
                        <Grid item xs={12} className={`${styles.ctMainHeadItem}`}>
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography variant='span' className={styles.customTemplateTextStyle}>Custom Template</Typography>
                                </Grid>
                                <Grid item sx={{ marginTop: "5px !important" }}>
                                    <Typography
                                        variant='span'
                                        sx={{ fontSize: "15px", fontWeight: "500", color: "#7A7A7A" }}
                                    >
                                        Unleash the power of personalized content creation with our Custom Template tailored to fit your unique needs.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={`${styles.ctMainItem}`}>
                            <Grid container >

                                <Grid item xs={12}>
                                    <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                        <Grid item xl={9} lg={9} md={9} sm={7} xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} className={`${styles.ctHeadItem}`}>
                                                    <Typography
                                                        variant='span'
                                                        sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                                                    >
                                                        Create Custom Template
                                                    </Typography>
                                                </Grid>
                                                {/* <Grid item xs={12} className={`${styles.ctSubHeadItem}`}>
                                                    <Typography
                                                        variant='span'
                                                        sx={{ fontSize: "15px", fontWeight: "600", color: "#7A7A7A" }}
                                                    >
                                                        Unleash the power of personalized content creation with our Custom Template tailored to fit your unique needs.
                                                    </Typography>
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                        {window.screen.width >= 600 &&
                                            <Grid item xl={3} lg={3} md={3} sm={4} xs={5} >
                                                <Grid container className={`${styles.ctSaveBtnContainer}`}>
                                                    <Grid item>
                                                        <Grid container gap="10px">
                                                            <Grid item>
                                                                <GlobalButton
                                                                    flagFullWidth
                                                                    buttonText={templateFormDetails.template_id ? "Update Template" : "Save Template"}
                                                                    className="primaryButtonStyle"
                                                                    onClick={handleSaveTemplate}
                                                                    disabled={templateFormDetails.template_id ? getUpdateTemplateDisableCondition() : getSaveTemplateDisableCondition()}
                                                                // disabled={getSaveTemplateDisabledCondition()}
                                                                />
                                                            </Grid>
                                                            {templateFormDetails.template_id &&
                                                                <Grid item>
                                                                    <GlobalButton
                                                                        flagFullWidth
                                                                        buttonText={"Create New"}
                                                                        className="primaryButtonStyle"
                                                                        onClick={handleCreateNewTemplate}
                                                                    />
                                                                </Grid>}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className={styles.ctMainContentItem}>
                                    <Grid container className={`${styles.ctContentMainContainer}`}>
                                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12} className={styles.ctLeftMainItem}>
                                            <Grid container>

                                                <Grid item xs={12} sx={{ margin: "15px 0px 10px" }}>
                                                    <Grid container>
                                                        <Grid item xs={12} className={styles.ctFieldLabelSpacing}>
                                                            <Typography variant='span' className={styles.ctGlobalFieldLebel}>
                                                                Template Name
                                                            </Typography>
                                                            <Required />
                                                        </Grid>
                                                        <Grid item xs={12} className={styles.ctFieldLabelSpacing}>
                                                            <GlobalTextField
                                                                fullWidth
                                                                size='medium'
                                                                sx={{ borderRadius: "5px" }}
                                                                placeholder="Caption Generator"
                                                                className={`${styles.ctTemplateNameTextfield}`}
                                                                value={templateFormDetails.template_name}
                                                                onChange={(e) => {
                                                                    setTemplateFormDetails({
                                                                        ...templateFormDetails,
                                                                        template_name: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} className={styles.ctFieldsGlobalItem}>
                                                    <Grid container>
                                                        <Grid item xs={12} className={styles.ctFieldLabelSpacing}>
                                                            <Typography variant='span' className={styles.ctGlobalFieldLebel}>
                                                                Template Description
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} className={styles.ctFieldLabelSpacing}>
                                                            <TextareaAutosize
                                                                style={{
                                                                    width: "100%", borderRadius: "5px",
                                                                }}
                                                                className={styles.ctTextarea}
                                                                value={templateFormDetails.template_description}
                                                                maxRows={8}
                                                                minRows={8}
                                                                placeholder='A social media caption generator which generates compelling&#x2028;captions with hashtags relevant to the following fields:&#x2028;- Topic&#x2028;- Target Audience&#x2028;- Social Media Post Description '
                                                                onChange={(e) => {
                                                                    setTemplateFormDetails({
                                                                        ...templateFormDetails,
                                                                        template_description: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} className={`${styles.ctFieldsGlobalItem} ${styles.ctDragableItem}`}>
                                                    <Grid container>
                                                        <Grid item xs={12} className={styles.ctFieldLabelSpacing}>
                                                            <Grid container sx={{ alignItems: "center" }}>
                                                                <Typography variant="span"
                                                                    sx={{
                                                                        marginRight: '10px',
                                                                        fontSize: "15px",
                                                                        fontWeight: 600,
                                                                        color: "#1F384C",
                                                                    }}>
                                                                    Field list
                                                                </Typography>
                                                                <Tooltip title="Add custom fields here to shape your unique content.">
                                                                    <InfoOutlinedIcon sx={{ fontSize: "19px", color: "#6A097D", cursor: "pointer" }} />
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} className={styles.ctFieldLabelSpacing}>
                                                            <DraggableListComponent setCustomTemplatePromptActionData={setCustomTemplatePromptActionData} customTemplatePromptActionData={customTemplatePromptActionData} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} className={styles.ctFieldsGlobalItem}>
                                                    <Grid container>
                                                        <Grid item xs={12} className={styles.ctFieldLabelSpacing}>
                                                            <Typography
                                                                variant='span'
                                                                className={styles.ctGlobalFieldLebel}
                                                                sx={{ display: "flex", alignItems: "end" }}
                                                            >
                                                                Template Prompt
                                                                <Required />&nbsp;&nbsp;
                                                                <Tooltip title="Guide your template's output with a clear prompt.">
                                                                    <InfoOutlinedIcon sx={{ fontSize: "19px", color: "#6A097D", cursor: "pointer" }} />
                                                                </Tooltip>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} className={`${styles.ctFieldLabelSpacing} ${styles.ctTemplatePrompt}`} >
                                                            <MixedModeTagify
                                                                // key={new Date().getTime()}
                                                                value={customTemplatePromptActionData}
                                                                setValue={setCustomTemplatePromptActionData}
                                                                whiteListOptions={masterValues}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12} className={styles.ctRightMainItem}>
                                            <Grid container direction={"column"}>
                                                <Grid item xs={12} sx={{ margin: "15px 0px 10px" }}>
                                                    <Typography
                                                        variant='span'
                                                        className={styles.ctGlobalFieldLebel}
                                                    >
                                                        Preview&nbsp;&nbsp;
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <Typography
                                                        variant='span'

                                                        className={styles.previewTextWrapper}

                                                    >
                                                        Here is a preview of how the finalized template will appear.
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <TemplateFormPreview formwizFieldsData={formwizFieldsData} setFormwizFieldsData={setFormwizFieldsData} />
                                                        </Grid>
                                                        <Grid item xs={12} className={styles.tryTemplateButtonWrapper}>
                                                            <GlobalButton
                                                                flagGenerate={true}
                                                                className="secondaryButtonStyle"
                                                                disabled={getTryTemplateDisabledCondition()}
                                                                onClick={handleTryTemplate}
                                                                buttonText="Try Template"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant='span'
                                                                className={styles.ctGlobalFieldLebel}
                                                            >
                                                                Output
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} className={styles.outputContainer}>
                                                            {executePromptResponse == null ?
                                                                <>
                                                                    <Typography className={styles.TryTemplateText}>
                                                                        Click on "Try Template" to see the output.
                                                                    </Typography>
                                                                </>
                                                                :
                                                                executePromptResponse != null && executePromptResponse.data.map((executePromptRespEx) => (
                                                                    executePromptResponse.flag_show_field ? (
                                                                        Object.keys(executePromptRespEx).map((key) => (
                                                                            <div key={key}>
                                                                                <Typography>{key}</Typography>
                                                                                <Typography>{(executePromptRespEx)[key]}</Typography>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div>
                                                                            {Object.values(executePromptRespEx).map((val, key) => (
                                                                                <Typography key={key}>{val}</Typography>
                                                                            ))}
                                                                        </div>
                                                                    )
                                                                ))
                                                            }

                                                        </Grid>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                        </Grid>

                                        {window.screen.width <= 600 &&
                                            <Grid item xs={12} >
                                                <Grid container className={`${styles.ctSaveBtnContainer}`}>
                                                    <Grid item>
                                                        <GlobalButton
                                                            flagFullWidth
                                                            buttonText={templateFormDetails.template_id ? "Update Template" : "Save Template"}
                                                            className="primaryButtonStyle"
                                                            onClick={handleSaveTemplate}
                                                            disabled={templateFormDetails.template_id ? getUpdateTemplateDisableCondition() : getSaveTemplateDisableCondition()}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CustomTemplate;