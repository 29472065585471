import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, TablePagination, Menu, MenuItem, TextField, ListItemIcon } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import globalStyles from "../../../assets/styles/global.module.scss";
import deleteDoc from "../../../assets/deleteDoc.svg";
import updateNoteStyles from "../../../assets/UpdateNote.svg";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import docIcon from "../../../assets/document.svg";
import deleteIcon from "../../../assets/deleteChatbotDomain.svg";
import { HeaderListObjectModel } from '../../../models/componentModel';
// import { DocContext, DocContextPayload } from '../../../context/docContext';
import { NoteContext, NoteContextPayload } from '../../../context/noteContext';
import { notesListResponseModel } from '../../../models/axiosModel';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


interface RowAllowEditInterface {
    document_id: number
}

export const NotesTable = (props: any) => {
    const {
        rowData, headerList, tableHeight, onRowClick, deleteRowData, renameDocumentName, updateNoteDetail, flagPagination, rowClicked, tableContainerHeight, tableContainerMinHeight, selectedRow, additionalRowClass
    } = props
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rowAllowEdit, setRowAllowEdit] = useState<RowAllowEditInterface | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [currentRowIndex, setCurrentRowIndex] = useState<number | null>(null);
    // const { UpdateDocInfo } = useContext(NoteContext) as NoteContextPayload;
    const [sortByUpdatedAt, setSortByUpdatedAt] = useState<'asc' | 'desc'>('asc');
    const [rowDataState, setRowDataState] = useState<notesListResponseModel[]>([]);
    const [flagDocumentNameChanged, setFlagDocumentNameChanged] = useState(false);
    const [flagUpdateButtonClicked, setUpdateButtonClicked] = useState(false);
    const [dateHover, setDateHover] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        // rowData?.map((dateFormat: any) => {
        //     dateFormat.updated_at_timestamp = moment(dateFormat.updated_at_timestamp).format('DD-MM-YY')
        // })
        setRowDataState(rowData);
    }, [rowData])
    const menuItems = [
        {
            label: 'Update', icon: <img src={updateNoteStyles} width={"14"} />, action: (e: any) => {
                setUpdateButtonClicked(true)
                updateNoteDetail(e)
            }
        },
        { label: 'Delete', icon: <img src={deleteDoc} />, action: (e: any) => deleteRowData(e) },
    ];

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, rowIndex: number) => {
        setCurrentRowIndex(rowIndex);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setCurrentRowIndex(null);
        setAnchorEl(null);
    };

    const handleSortByUpdatedAt = () => {
        const newSortOrder = sortByUpdatedAt === 'asc' ? 'desc' : 'asc';
        setSortByUpdatedAt(newSortOrder);

        const sortedData = [...rowDataState].sort((a, b) => {
            const timestampA = moment(a.timestamp).valueOf();
            const timestampB = moment(b.timestamp).valueOf();

            if (newSortOrder === 'asc') {
                if (isNaN(timestampB)) {
                    return timestampA;
                }
                if (timestampA === timestampB) {
                    const timeA = moment(a.timestamp).format('HH:mm:ss');
                    const timeB = moment(b.timestamp).format('HH:mm:ss');
                    return timeA.localeCompare(timeB);
                }
                return timestampA - timestampB;
            } else {
                if (isNaN(timestampB)) {
                    return timestampA;
                }
                if (timestampA === timestampB) {
                    const timeA = moment(a.timestamp).format('HH:mm:ss');
                    const timeB = moment(b.timestamp).format('HH:mm:ss');
                    return timeB.localeCompare(timeA);
                }
                return timestampB - timestampA;
            }
        });
        setRowDataState(sortedData);
    };
    function formatValue(rowDetail: any, headerObj: HeaderListObjectModel) {
        const value = rowDetail[headerObj.valueKey];

        return typeof value === "string" ?
            (value.trim().length === 0 ? "-" : value) :
            Array.isArray(value) ?
                (value.length === 0 ? "-" : value) :
                value;
    }

    return (
        <>
            <TableContainer
                // className={`${globalStyles.tableContainer}`}
                sx={{ maxHeight: tableHeight, height: tableContainerHeight, minHeight: tableContainerMinHeight }}
            >
                <Table aria-label="simple table" stickyHeader className={globalStyles.WritewizGlobaltable} >
                    <TableHead>
                        <TableRow className={globalStyles.headerRow}>
                            {headerList?.map((headerCell: HeaderListObjectModel) => (
                                !headerCell.hidden &&
                                <TableCell
                                    align={headerCell.alignment}
                                    className={headerCell.iconKey === 'documentIcon' ? globalStyles.iconCell : headerCell.name === 'Last Modified' ? globalStyles.iconCellLastModifield : ''}
                                    onMouseEnter={() => { headerCell.valueKey === 'timestamp' && setDateHover(true) }}
                                    onMouseLeave={() => { headerCell.valueKey === 'timestamp' && setDateHover(false) }}>
                                    {headerCell.valueKey === 'timestamp' && dateHover ?
                                        <>
                                            {headerCell?.name}
                                            <span onClick={handleSortByUpdatedAt} style={{ cursor: "pointer", paddingLeft: "10px" }} >
                                                {sortByUpdatedAt === 'asc' ? '▲' : '▼'}
                                            </span>
                                        </>
                                        :
                                        headerCell?.name
                                    }
                                    {headerCell?.subHeaderFlag == true &&
                                        <div className={globalStyles[headerCell.subHeaderClassName]}>
                                            {headerCell?.subHeaderText}
                                        </div>
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rowDataState.length ?
                                rowDataState?.map((rowDetail: any, i: number) => {
                                    return (
                                        <TableRow onClick={() => { onRowClick(rowDetail) }} key={i} className={selectedRow === i ? `${globalStyles.bodyRow} ${globalStyles.selectedBodyRow}` : `${globalStyles.bodyRow}`}>
                                            {headerList?.map((headerObj: HeaderListObjectModel) => {
                                                return (
                                                    !headerObj.hidden &&
                                                    <TableCell
                                                        align={headerObj.alignment}
                                                        className={headerObj.iconKey == "documentIcon" ? globalStyles.documentClickCell : ""}
                                                        onClick={() => (headerObj.iconKey === "documentIcon" || headerObj.iconKey === "lastModifiedIcon" || headerObj.iconKey === "featureIcon") && flagUpdateButtonClicked == false && rowClicked(rowDetail)}>
                                                        {headerObj.iconKey == "editIcon" ?
                                                            (
                                                                <>
                                                                    <MoreVertIcon
                                                                        sx={{ cursor: "pointer", color: "#7A7A7A" }}
                                                                        onClick={(event: any) => handleMenuOpen(event, i)}
                                                                    />
                                                                    <Menu
                                                                        anchorEl={anchorEl}
                                                                        keepMounted
                                                                        open={Boolean(anchorEl) && currentRowIndex === i}
                                                                        onClose={handleMenuClose}
                                                                    >
                                                                        {menuItems.map((item, index) => (
                                                                            <MenuItem
                                                                                className={globalStyles.menuItemStyle}
                                                                                key={index}
                                                                                onClick={(e) => { handleMenuClose(); item.action(rowDetail); }}
                                                                            >
                                                                                <ListItemIcon>
                                                                                    {item.icon}
                                                                                </ListItemIcon>
                                                                                <span>
                                                                                    {item.label}
                                                                                </span>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Menu>
                                                                </>
                                                            )
                                                            :
                                                            <>
                                                                <Grid container
                                                                    className={`${globalStyles.tableRowStyle} ${globalStyles[additionalRowClass]}`}
                                                                    style={{
                                                                        justifyContent: headerObj.iconKey == "deleteIcon" ? "end" : "start",
                                                                        flexWrap: "nowrap"
                                                                    }}
                                                                >
                                                                    {headerObj.flagIcon ?
                                                                        <Grid item style={{ marginRight: "10px", alignItems: "center", display: "flex" }}  >
                                                                            {headerObj.iconKey == "deleteIcon" ?
                                                                                <img src={deleteIcon} className={globalStyles.tableCategoryImage} style={{ cursor: "pointer" }}
                                                                                    onClick={(e: any) => {
                                                                                        deleteRowData(e, rowDetail)
                                                                                    }}
                                                                                />
                                                                                :
                                                                                // headerObj.iconKey == "documentIcon" ?
                                                                                //     // <img src={docIcon} className={globalStyles.tableCategoryImage} />
                                                                                //     // :
                                                                                //     <img src={rowDetail.iconSrc} className={globalStyles.tableCategoryImage} /> : ""
                                                                                ""
                                                                            }
                                                                        </Grid>
                                                                        : ""}
                                                                    <Grid item>
                                                                        {(rowAllowEdit != null && rowAllowEdit.document_id) == rowDetail.document_id ?
                                                                            headerObj.iconKey == "documentIcon" ?
                                                                                <TextField
                                                                                    key="1"
                                                                                    defaultValue={rowDetail[headerObj.valueKey]}
                                                                                    autoFocus
                                                                                    className={globalStyles.rowTextfield}
                                                                                    onChange={((e) => {
                                                                                        setRowDataState((prevValue: notesListResponseModel[]) => {
                                                                                            const response: notesListResponseModel[] = prevValue.map((value: notesListResponseModel) => {
                                                                                                if (value.user_notes_id === rowDetail.document_uuid) {
                                                                                                    if (e.target.value.length != 0) {
                                                                                                        value.user_notes_title = e.target.value;
                                                                                                    }
                                                                                                    else {
                                                                                                        value.user_notes_title = rowDetail.document_name
                                                                                                    }
                                                                                                }
                                                                                                return value;
                                                                                            })
                                                                                            return response;
                                                                                        })
                                                                                        setFlagDocumentNameChanged(true)
                                                                                    })}
                                                                                    onKeyDown={(e: any) => {
                                                                                        if (e.key === 'Enter') {
                                                                                            e.preventDefault();
                                                                                            if (flagDocumentNameChanged && e.target.value.trim() !== '') {
                                                                                                renameDocumentName(e.target.value, rowDetail);
                                                                                                setRowAllowEdit(null);
                                                                                                setFlagDocumentNameChanged(false);
                                                                                                setUpdateButtonClicked(false)
                                                                                            } else {
                                                                                                setRowAllowEdit(null);
                                                                                                setUpdateButtonClicked(false)
                                                                                            }
                                                                                        }
                                                                                        else if (e.key === 'Escape') {
                                                                                            // e.preventDefault();
                                                                                            // e.target.value = rowDetail[headerObj.valueKey];
                                                                                            renameDocumentName(e.target.value, rowDetail, "escape");
                                                                                            setRowAllowEdit(null);
                                                                                            setUpdateButtonClicked(false);
                                                                                        }
                                                                                    }}
                                                                                    onBlur={(e) => {

                                                                                        if (flagDocumentNameChanged && e.target.value) {
                                                                                            renameDocumentName(e.target.value, rowDetail)
                                                                                            setRowAllowEdit(null)
                                                                                            setFlagDocumentNameChanged(false)
                                                                                            setUpdateButtonClicked(false)
                                                                                        }
                                                                                        else {
                                                                                            setRowAllowEdit(null)
                                                                                            setUpdateButtonClicked(false)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                :
                                                                                formatValue(rowDetail, headerObj)
                                                                            :
                                                                            formatValue(rowDetail, headerObj)
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        }
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                                        No Data Available
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>

            </TableContainer>
            {flagPagination ? "" : <TablePagination
                component="div"
                count={rowData?.length || 0}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) =>
                    setRowsPerPage(parseInt(event.target.value, 10))
                }
                labelRowsPerPage="Rows per page:"
                rowsPerPageOptions={[5, 10, 25, 50]}
            />}
        </>
    )
}
