import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import { CommonResponseModel, createNotePayloadModel, createNotesResponseModel, notesListResponseModel, updateNoteDetailResponseModel, CommonResponseSecondaryModel, getNotesCategoryResponseModel, createNoteCategoryPayloadModel, createNoteCategoryResponseModel } from "../models/axiosModel";
import agent from "../services/api";

const NoteContext = React.createContext<NoteContextPayload | undefined>(undefined);
export interface NoteContextPayload {

    GetNotesListApi: () => Promise<CommonResponseModel<notesListResponseModel>>;

    noteList: notesListResponseModel[] | null;
    setNoteList: React.Dispatch<React.SetStateAction<notesListResponseModel[] | null>>
    noteListDetails: notesListResponseModel[] | null;
    setNoteListDetails: React.Dispatch<React.SetStateAction<null | notesListResponseModel[]>>;

    createNote: (payload: createNotePayloadModel) => Promise<CommonResponseSecondaryModel<createNotesResponseModel>>;

    getNoteDetails: (user_notes_id: string) => Promise<CommonResponseModel<notesListResponseModel | null>>

    noteExistingDetails: notesListResponseModel | null;
    setNoteExistingDetails: React.Dispatch<React.SetStateAction<notesListResponseModel | null>>;
    updateNoteDetails: (payload: updateNoteDetailResponseModel) => Promise<CommonResponseModel<updateNoteDetailResponseModel>>

    GetNotesCategoryListApi: () => Promise<CommonResponseModel<getNotesCategoryResponseModel>>;
    categoryList: getNotesCategoryResponseModel[] | null;
    setCategoryList: React.Dispatch<React.SetStateAction<getNotesCategoryResponseModel[] | null>>
    noteCategoryDetails: getNotesCategoryResponseModel[] | null;
    setNoteCategoryDetails: React.Dispatch<React.SetStateAction<null | getNotesCategoryResponseModel[]>>;
    addNoteCategory: (payload: createNoteCategoryPayloadModel) => Promise<CommonResponseSecondaryModel<createNoteCategoryResponseModel>>;

    noteRender: any;

}

const NoteProvider = ({ children }: ChildrenProps) => {

    const [noteListDetails, setNoteListDetails] = useState<notesListResponseModel[] | null>(null)
    const [noteCategoryDetails, setNoteCategoryDetails] = useState<getNotesCategoryResponseModel[] | null>(null)
    const [noteExistingDetails, setNoteExistingDetails] = useState<notesListResponseModel | null>(null)
    const [noteRender, setNoteRender] = useState<any>(null)
    const [noteList, setNoteList] = useState<notesListResponseModel[] | null>(null)
    const [categoryList, setCategoryList] = useState<getNotesCategoryResponseModel[] | null>(null)

    const GetNotesListApi = async (): Promise<CommonResponseModel<notesListResponseModel>> => {
        const response = await agent.getNotesList.get();
        if (response.status) {
          setNoteList(response.data);
        } else {
          setNoteList(null);
        }
        return response;
    }

    const GetNotesCategoryListApi = async (): Promise<CommonResponseModel<getNotesCategoryResponseModel>> => {
        const response = await agent.getNotesCategoryList.get();
        if (response.status) {
          setCategoryList(response.data);
        } else {
          setCategoryList(null);
        }
        return response;
    }

    const createNote = async (payload: createNotePayloadModel): Promise<any> => {
        const response = await agent.createNoteList.post(payload)
        return response
    }

    const addNoteCategory = async (payload: createNoteCategoryPayloadModel): Promise<any> => {
        const response = await agent.createNoteCategory.post(payload)
        return response
    }

    const getNoteDetails = async (user_notes_id: string): Promise<CommonResponseModel<notesListResponseModel | null>> => {
        const response = await agent.getNotesList.get()
        if (response.status) {
            if (response.data) {
                const filteredNotes = response.data.filter((note: any) => note.user_notes_id == user_notes_id)[0];
                setNoteExistingDetails(filteredNotes);
            }
        } else {
            setNoteExistingDetails(null);
        }
        return response
    }

    const updateNoteDetails = async (payload: updateNoteDetailResponseModel): Promise<CommonResponseModel<updateNoteDetailResponseModel>> => {
        const response = await agent.updateNoteDetail.put(payload)
        if (response.status) {
            if (response.data) {
                setNoteExistingDetails(response.data[0]);
                return response;
            }
        } else {
            setNoteExistingDetails(null);
            return response;
        }
        return response
    }

    const payload = {
        GetNotesListApi,
        noteList,
        setNoteList,
        createNote,
        getNoteDetails,
        noteExistingDetails,
        setNoteExistingDetails,
        updateNoteDetails,
        noteRender,
        setNoteRender,
        noteListDetails,
        setNoteListDetails,
        GetNotesCategoryListApi,
        noteCategoryDetails,
        setNoteCategoryDetails,
        addNoteCategory,
        categoryList, 
        setCategoryList
    };

    return (
        <NoteContext.Provider value={payload}>
            {children}
        </NoteContext.Provider>
    );
};

export { NoteContext, NoteProvider };
