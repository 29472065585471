import { Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import plusIcon from "../../../assets/plusIcon.png"
import text from "../../../assets/text.png"
import list from "../../../assets/list.png"
import table from "../../../assets/table.png"
import image from "../../../assets/image.png"
import globalStyles from "../../../assets/styles/global.module.scss";
import Dropdown, { DropdownMenuItem, DropdownNestedMenuItem } from "./addOutlineDropDown/Dropdown";
import { ArrowRight } from "@mui/icons-material";
import { DocContext } from '../../../context/docContext';
import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import checkedIcon from '../../../assets/checked.png'
import uncheckedIcon from '../../../assets/uncheked.png'

export const Drag = ({ id, index, contentData, setFlagOutlineProccessing, ...props }) => {

  const { documentOutlineData, setDocumentOutlineData, documentContentTypeList, currentActiveDocDetails, UpdateDocumentOutlineData } = React.useContext(DocContext);

  const [flagAddAbove, setFlagAddAbove] = useState(false)

  const getContentTypeData = (contentTypeName, subTypeName) => {
    let response = {
      content_type_id: "",
      sub_content_type_id: ""
    }

    documentContentTypeList?.map((content) => {
      if (content.content_type_name == contentTypeName) {
        response.content_type_id = content.content_type_id
        if (content?.sub_content_type_data?.length > 1) {
          let matchedObj = content.sub_content_type_data.filter((subContent) => subContent.sub_content_type_name == subTypeName)[0]
          response["sub_content_type_id"] = matchedObj.sub_content_type_id
        } else {
          response["sub_content_type_id"] = content.sub_content_type_data[0].sub_content_type_id
        }
      }
    })
    return response
  }

  function addUniqueOutlineId(data) {
    for (const item of data) {
      item['unique_id'] = item.document_outline_id
      delete item.document_outline_level_num
      delete item.document_outline_seq_num

      if (item.children && item.children.length > 0) {
        addUniqueOutlineId(item.children);
      }
    }
    return data
  }

  const AddNewOutline = async (updatedData) => {
    let finalObj = {}
    finalObj['document_uuid'] = currentActiveDocDetails.document_uuid
    finalObj['document_step_id'] = currentActiveDocDetails.document_step_data[1].document_step_id
    finalObj['document_outline_data'] = updatedData
    console.log("final++++++++++", finalObj)
    setFlagOutlineProccessing(true)
    let response = await UpdateDocumentOutlineData(finalObj)
    if (response.status) {
      let responseWithId = addUniqueOutlineId(response.data[0].document_step_data[1].document_outline_data)
      setDocumentOutlineData(responseWithId)
    }
    setFlagOutlineProccessing(false)
    setFlagAddAbove(false)
  }

  const AddSectionHeading = (contentData) => {
    let tempOutlineData = [...documentOutlineData]
    let contentTypeData = getContentTypeData("heading", "h1");
    let targetIndex;
    let sampleObj = {
      unique_id: "udid-" + new Date().getTime(),
      content_type_id: contentTypeData.content_type_id,
      content_type_name: "heading",
      sub_content_type_id: contentTypeData.sub_content_type_id,
      sub_content_type_name: "h1",
      document_outline_value: "",
      document_outline_summary: null,
      document_outline_parent_id: null,
      children: []
    }
    tempOutlineData?.map((obj, index) => {
      if (obj.unique_id == contentData.unique_id) {
        if (flagAddAbove) {
          targetIndex = index;
        } else {
          targetIndex = index + 1;
        }
      }
    })

    tempOutlineData.splice(targetIndex, 0, sampleObj)
    AddNewOutline(tempOutlineData)
    // setDocumentOutlineData(tempOutlineData)
  }

  const AddChildInHeading = (contentData, subType) => {
    let tempOutlineData = [...documentOutlineData]
    let sampleObj = {
      unique_id: "udid-" + new Date().getTime(),
      sub_content_type_name: subType,
      content_type_name: subType == "h2" ? "sub heading" : "content brief",
      document_outline_value: "",
      document_outline_summary: "",
      document_outline_parent_id: null,
      children: []
    }
    let contentTypeData;
    if (subType == "h2") {
      contentTypeData = getContentTypeData("sub heading", subType);

    } else {
      contentTypeData = getContentTypeData("content brief", subType);
    }
    sampleObj['content_type_id'] = contentTypeData.content_type_id
    sampleObj['sub_content_type_id'] = contentTypeData.sub_content_type_id

    tempOutlineData?.map((obj, index) => {
      if (obj.unique_id == contentData.unique_id) {
        sampleObj.document_outline_parent_id = obj.document_outline_id ? obj.document_outline_id : obj.unique_id
        if (obj?.children) {
          obj['children'].splice(0, 0, sampleObj)
        } else {
          obj['children'] = sampleObj
        }
      }
    })
    AddNewOutline(tempOutlineData)

  }

  const AddSiblingSubHeading = (contentData) => {
    let tempOutlineData = [...documentOutlineData]
    let contentTypeData = getContentTypeData("sub heading", "h2");
    let sampleObj = {
      unique_id: "udid-" + new Date().getTime(),
      content_type_id: contentTypeData.content_type_id,
      content_type_name: "sub heading",
      sub_content_type_id: contentTypeData.sub_content_type_id,
      sub_content_type_name: "h2",
      document_outline_value: "",
      document_outline_summary: null,
      document_outline_parent_id: null,
      children: []
    }
    for (let i = 0; i < tempOutlineData.length; i++) {
      if (tempOutlineData[i]?.children?.length) {
        for (let j = 0; j < tempOutlineData[i].children.length; j++) {
          if (tempOutlineData[i].children[j].unique_id === contentData.unique_id) {
            if (flagAddAbove) {
              tempOutlineData[i]['children'].splice(j, 0, sampleObj)
              break;
            } else {
              tempOutlineData[i]['children'].splice(j + 1, 0, sampleObj)
              break;
            }
          }
        }
      }
    }
    AddNewOutline(tempOutlineData)
    // setDocumentOutlineData(tempOutlineData)
  }

  const AddContentBriefInChild = (contentData, subType) => {
    let tempOutlineData = [...documentOutlineData]
    let contentTypeData = getContentTypeData("content brief", subType);
    let sampleObj = {
      unique_id: "udid-" + new Date().getTime(),
      content_type_id: contentTypeData.content_type_id,
      content_type_name: "content brief",
      sub_content_type_id: contentTypeData.sub_content_type_id,
      sub_content_type_name: subType,
      document_outline_value: "",
      document_outline_summary: null,
      document_outline_parent_id: null,
    }

    tempOutlineData?.map((levelOne, i) => {
      if (levelOne?.children) {
        levelOne.children?.map((levelTwo, j) => {
          if (levelTwo.unique_id == contentData.unique_id) {
            sampleObj.document_outline_parent_id = levelTwo.document_outline_id ? levelTwo.document_outline_id : levelTwo.unique_id
            levelTwo['children'].unshift(sampleObj)
          }
        })
      }
    })
    AddNewOutline(tempOutlineData)
    // setDocumentOutlineData(tempOutlineData)
  }

  const AddContentBrief = (contentData, subType) => {
    let tempOutlineData = [...documentOutlineData]
    let contentTypeData = getContentTypeData("content brief", subType);
    let sampleObj = {
      unique_id: "udid-" + new Date().getTime(),
      content_type_id: contentTypeData.content_type_id,
      content_type_name: "content brief",
      sub_content_type_id: contentTypeData.sub_content_type_id,
      sub_content_type_name: subType,
      document_outline_value: "",
      document_outline_summary: "",
      document_outline_parent_id: null,
    }

    for (let i = 0; i < tempOutlineData.length; i++) {
      if (tempOutlineData[i].unique_id == contentData.unique_id) {
        if (flagAddAbove) {
          tempOutlineData.splice(i, 0, sampleObj)
        } else {
          tempOutlineData.splice(i + 1, 0, sampleObj)
        }
      }
      if (tempOutlineData[i]?.children) {
        for (let j = 0; j < tempOutlineData[i].children.length; j++) {
          if (tempOutlineData[i].children[j].unique_id == contentData.unique_id) {
            sampleObj.document_outline_parent_id =
              tempOutlineData[i].document_outline_id ?
                tempOutlineData[i].document_outline_id : tempOutlineData[i].unique_id

            if (flagAddAbove) {
              tempOutlineData[i]['children'].splice(j, 0, sampleObj)
            } else {
              tempOutlineData[i]['children'].splice(j + 1, 0, sampleObj)
            }
          }
          if (tempOutlineData[i]?.children[j]?.children?.length) {
            for (let k = 0; k < tempOutlineData[i].children.length; k++) {
              if (tempOutlineData[i].children[j].children[k].unique_id === contentData.unique_id) {
                sampleObj.document_outline_parent_id =
                  tempOutlineData[i].children[j].document_outline_id ?
                    tempOutlineData[i].children[j].document_outline_id : tempOutlineData[i].children[j].unique_id
                if (flagAddAbove) {
                  tempOutlineData[i]['children'][j]['children'].splice(k, 0, sampleObj)
                  break;
                } else {
                  tempOutlineData[i]['children'][j]['children'].splice(k + 1, 0, sampleObj)
                  break;
                }
              }
            }
          }
        }
      }
    }

    AddNewOutline(tempOutlineData)
    // setDocumentOutlineData(tempOutlineData)
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...props}>

            <Dropdown
              trigger={<span className={`plus_icon_visibility ${globalStyles.clickable}`}><img src={plusIcon} style={{ verticalAlign: "bottom", width: "18px", height: "18px" }} /></span>}
              menu={contentData.content_type_name == "heading"
                ?
                [
                  <DropdownMenuItem sx={{ fontSize: "13px" }} disabled>{"Add Sibling"}</DropdownMenuItem>,
                  <DropdownMenuItem onClick={() => { AddSectionHeading(contentData) }}>{"Section Heading"}</DropdownMenuItem>,
                  <DropdownMenuItem>
                    <FormControlLabel
                      label="Add above this row"
                      control={
                        <Checkbox
                          checked={flagAddAbove}
                          icon={<img src={uncheckedIcon} />}
                          checkedIcon={<img src={checkedIcon} />}
                          onChange={() => { setFlagAddAbove(!flagAddAbove) }}
                        />
                      }
                    />
                  </DropdownMenuItem>,
                  <DropdownMenuItem ><hr style={{ width: "100%", color: "#6a097d", margin: "0px" }} /></DropdownMenuItem>,
                  <DropdownMenuItem sx={{ fontSize: "13px" }} disabled>{"Add Child"}</DropdownMenuItem>,
                  <DropdownMenuItem onClick={() => { AddChildInHeading(contentData, "h2") }}>{"Sub-Heading"}</DropdownMenuItem>,
                  <DropdownNestedMenuItem
                    label="Content Brief"
                    rightIcon={<ArrowRight />}
                    menu={[
                      <DropdownMenuItem onClick={() => { AddChildInHeading(contentData, "p") }}><img src={text} style={{ width: "18px", height: "18px", marginRight: "10px" }} />{"Text"}</DropdownMenuItem>,
                      <DropdownMenuItem onClick={() => { AddChildInHeading(contentData, "ul") }}><img src={list} style={{ width: "18px", height: "18px", marginRight: "10px" }} />{"List"}</DropdownMenuItem>,
                      <DropdownMenuItem onClick={() => { AddChildInHeading(contentData, "table") }}><img src={table} style={{ width: "18px", height: "18px", marginRight: "10px" }} />{"Table"}</DropdownMenuItem>,
                      // <DropdownMenuItem><img src={image} style={{ width: "18px", height: "18px", marginRight: "10px" }} />{"Image"}</DropdownMenuItem>,
                    ]}
                  />
                ]
                :
                contentData.content_type_name == "sub heading"
                  ?
                  [
                    <DropdownMenuItem sx={{ fontSize: "13px" }} disabled>{"Add Sibling"}</DropdownMenuItem>,
                    <DropdownMenuItem onClick={() => { AddSiblingSubHeading(contentData) }}>{"Sub-Heading"}</DropdownMenuItem>,
                    <DropdownMenuItem>
                      <FormControlLabel
                        label="Add above this row"
                        control={
                          <Checkbox
                            checked={flagAddAbove}
                            icon={<img src={uncheckedIcon} />}
                            checkedIcon={<img src={checkedIcon} />}
                            onChange={() => { setFlagAddAbove(!flagAddAbove) }}
                          />
                        }
                      />
                    </DropdownMenuItem>,
                    <DropdownMenuItem ><hr style={{ width: "100%", color: "#6a097d", margin: "0px" }} /></DropdownMenuItem>,
                    <DropdownMenuItem sx={{ fontSize: "13px" }} disabled>{"Add Child"}</DropdownMenuItem>,
                    <DropdownNestedMenuItem
                      label="Content Brief"
                      rightIcon={<ArrowRight />}
                      menu={[
                        <DropdownMenuItem onClick={() => { AddContentBriefInChild(contentData, "p") }}><img src={text} style={{ width: "18px", height: "18px", marginRight: "10px" }} />{"Text"}</DropdownMenuItem>,
                        <DropdownMenuItem onClick={() => { AddContentBriefInChild(contentData, "ul") }}><img src={list} style={{ width: "18px", height: "18px", marginRight: "10px" }} />{"List"}</DropdownMenuItem>,
                        <DropdownMenuItem onClick={() => { AddContentBriefInChild(contentData, "table") }}><img src={table} style={{ width: "18px", height: "18px", marginRight: "10px" }} />{"Table"}</DropdownMenuItem>,
                        // <DropdownMenuItem><img src={image} style={{ width: "18px", height: "18px", marginRight: "10px" }} />{"Image"}</DropdownMenuItem>,
                      ]}
                    />
                  ] :
                  [
                    <DropdownMenuItem sx={{ fontSize: "14px" }} disabled>{"Add Sibling"}</DropdownMenuItem>,
                    <DropdownNestedMenuItem
                      label="Content Brief"
                      rightIcon={<ArrowRight />}
                      menu={[
                        <DropdownMenuItem onClick={() => { AddContentBrief(contentData, "p") }}><img src={text} style={{ width: "18px", height: "18px", marginRight: "10px" }} />{"Text"}</DropdownMenuItem>,
                        <DropdownMenuItem onClick={() => { AddContentBrief(contentData, "ul") }}><img src={list} style={{ width: "18px", height: "18px", marginRight: "10px" }} />{"List"}</DropdownMenuItem>,
                        <DropdownMenuItem onClick={() => { AddContentBrief(contentData, "table") }}><img src={table} style={{ width: "18px", height: "18px", marginRight: "10px" }} />{"Table"}</DropdownMenuItem>,
                        // <DropdownMenuItem onClick={() => { AddContentBrief(contentData, "Image") }}><img src={image} style={{ width: "18px", height: "18px", marginRight: "10px" }} />{"Image"}</DropdownMenuItem>,
                      ]}
                    />,
                    <DropdownMenuItem>
                      <FormControlLabel
                        label="Add above this row"
                        control={
                          <Checkbox
                            checked={flagAddAbove}
                            icon={<img src={uncheckedIcon} />}
                            checkedIcon={<img src={checkedIcon} />}
                            onChange={() => { setFlagAddAbove(!flagAddAbove) }}
                          />
                        }
                      />
                    </DropdownMenuItem>
                  ]
              }
            />
            <div className="drag-handle" {...provided.dragHandleProps}>
              <DragIndicatorIcon sx={{ color: "gray", verticalAlign: "middle" }} />
            </div>
            {props.children}
          </div>
        );
      }}
    </Draggable>
  );
};
