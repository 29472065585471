import axios, { AxiosError } from 'axios';
import { LoginPayloadModel, RegisterPayloadModel, SendLoginOTPPayloadModel, VerifyAccountOTPPayloadModel, CreateDocPayloadModel, updateDocumentFormRequestPayloadModel, UpdateDocumentInfoRequestPayloadModel, ExecutePromptRequestPayloadModel, UpdateDocumentOutlineDataPayloadModel, DeleteDocumentOutlineDataParamsModel, UpdateDocumentTitlePayloadModel, UpdateDocumentEditorPayloadModel, CustomerDetailModel, CreatePersonaPayloadModel, createCustomTemplatePayloadModel, UpdatePersonaPayloadModel, updateCustomerSubscriptionPayloadModel, createChatbotPayloadModel, updateChatbotPayloadModel, addChatbotDomainPayloadModel, updateChatbotDomainPayloadModel, createCampaignPayloadModel, updateCampaignDetailsModel, generateCampaignDocumentPayloadModel, markDefaultPersonaPayloadModel, createUserPopupActionPayload, createChatbotIntegrationPayload, createNoteCategoryPayloadModel, updateNoteCategoryDetailsModel, createNotePayloadModel, updateNoteDetailModel } from '../models/axiosModel';
import { getTime } from 'date-fns';


axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// axios.defaults.baseURL = "http://192.168.1.2:3000";
const responseBody = (response: any) => {
    if (response?.Title === undefined)
        return response.data
}

const Error = (err: AxiosError) => {
    // @ts-ignore
    if (err?.response !== null && err?.response !== undefined) {
        console.log("response.response.data.error--", err?.response?.data)
    }
    return err;
}

const requests = {
    get: async (url: string) => {
        try {

            // @ts-ignore
            const controller = new AbortController();
            // @ts-ignore
            apiBuffer.push({ name: url, controller: controller })
            const response = await axios.get(url, {
                headers: {
                    "Content-Type": "application/json"
                },
                signal: controller.signal
            });
            // @ts-ignore
            if (response?.flagStatus === false && response?.error !== "" && response?.error !== null && response?.error !== undefined) {
                alert("Something went wrong.!");
            }
            else {
                return responseBody(response);
            }
        } catch (err: any) {
            return Error(err);
        }
    },
    fileUploadPost: async (url: string, body: any) => {
        try {
            const response = await axios.post(url, body, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            return responseBody(response);
        } catch (err: any) {
            return Error(err);
        }
    },
    post: async (url: string, body: any, onAbort?: () => void) => {
        try {
            // @ts-ignore
            const controller = new AbortController();
            // @ts-ignore
            apiBuffer.push({ name: url, controller: controller })
            // @ts-ignore
            const response = await axios.post(url, body, {
                headers: {
                    "Content-Type": "application/json"
                },
                signal: controller.signal
            });
            return responseBody(response);
        } catch (err: any) {
            if (axios.isCancel(err)) {
                console.log("Request was canceled", err);
                // @ts-ignore
                apiBuffer = apiBuffer.filter((api: any) => api.name !== url)
                if (onAbort) console.log("onabort called");
            } else {
                console.log("not aborted", err)
            }
            return Error(err);
        }
    },
    put: async (url: string, body: any) => {
        try {
            const response = await axios.put(url, body, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err: any) {
            return Error(err);
        }
    },
    delete: async (url: string) => {
        try {
            const response = await axios.delete(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            return responseBody(response);
        } catch (err: any) {
            return Error(err);
        }
    }
};
const UserMeAPI = {
    get: () => requests.get("/api/user/get-user-detail"),
    getURL: "/api/user/get-user-detail"
}

const LoaderDetailsAPI = {
    get: () => requests.get(`api/popup/get-image-groups`)
}

const RegisterAPI = {
    post: (payload: RegisterPayloadModel) => requests.post(`/api/user/create-user`, payload)
}
const SendAccountOTPAPI = {
    post: () => requests.post(`/api/user/account-authentication-code`, {})
}
const VerifyAccountOTPAPI = {
    put: (payload: VerifyAccountOTPPayloadModel) => requests.put("api/user/account-authentication-code", payload)
}
const SendLoginOTPAPI = {
    post: (payload: SendLoginOTPPayloadModel) => requests.post("api/user/auth", payload)
}
const VerifyLoginOTPAPI = {
    put: (payload: LoginPayloadModel) => requests.put("api/user/auth", payload)
}
const LogoutAPI = {
    post: () => requests.post("api/user/logout-user", {})
}
const getTemplateList = {
    get: () => requests.get("api/template/get-template-list")
}
const getTemplateDetail = {
    get: (template_id: number) => requests.get(`api/template/template-detail?template_id=${template_id}`)
}
const createDoc = {
    post: (payload: CreateDocPayloadModel) => requests.post("api/document/create-document", payload)
}
const getDocDetails = {
    get: (document_uuid: String) => requests.get(`api/document/get-document-detail?document_uuid=${document_uuid}`)
}
const getDocList = {
    get: () => requests.get("api/document/get-document-list")
}
const updateDocumentFormSubmissionData = {
    put: (payload: updateDocumentFormRequestPayloadModel) => requests.put("api/document/update-document-form-detail", payload)
}
const updateDocumentInfo = {
    put: (payload: UpdateDocumentInfoRequestPayloadModel) => requests.put("api/document/update-document-info", payload)
}
const getPrompList = {
    get: () => requests.get("api/ai/get-prompt-list")
}
const executePrompt = {
    post: (payload: ExecutePromptRequestPayloadModel, currentTime?: any) => requests.post("api/ai/ask-ai", payload, currentTime),
    getURL: "api/ai/ask-ai"

}
const getDocumentContentTypeData = {
    get: () => requests.get("api/document/get-document-content-type-data")
}

const updateDocumentOutlineData = {
    put: (payload: UpdateDocumentOutlineDataPayloadModel[]) => requests.put("api/document/update-document-outline-detail", payload)
}
const deleteDocument = {
    delete: (document_uuid: string) =>
        requests.delete(`api/document/delete-document?document_uuid=${document_uuid}`)
}
const deleteDocumentOutlineData = {
    delete: (params: DeleteDocumentOutlineDataParamsModel) =>
        requests.delete("api/document/delete-document-outline?" +
            Object.keys(params).map(key => `${key}=${encodeURIComponent((params as any)[key])}`).join('&'))
}
const updateDocumentTitle = {
    put: (payload: UpdateDocumentTitlePayloadModel) => requests.put("api/document/update-document-title", payload)
}
const updateDocumentEditorDetail = {
    put: (payload: UpdateDocumentEditorPayloadModel) => requests.put("api/document/update-document-editor-detail", payload)
}
const contWithGoogle = {
    get: (currentUrl: string, gc_integration_type_id: number) => requests.get(`apiIntegration/api/oauth/outbound/auth-url?currentUrl=${currentUrl}&gc_integration_type_id=${gc_integration_type_id}`)
}
const getCustomerDetail = {
    get: () => requests.get("api/stripe/get-stripe-customer-detail")
}
const updateCustomerDetail = {
    put: (payload: CustomerDetailModel) => requests.put("api/stripe/update-stripe-customer-detail", payload)
}
const getPricingDetail = {
    get: () => requests.get("api/stripe/get-stripe-pricing-detail")

}
const getStripeCustomerSubscriptionDetail = {
    get: () => requests.get("api/stripe/get-stripe-customer-subscription-detail")
}
const getStripeCustomerPortalLink = {
    get: () => requests.get("api/stripe/get-stripe-customer-portal-link")
}
// persona
const getPersonaList = {
    get: () => requests.get("api/persona/get-persona-list")
}
const getSystemPersonaPublicList = {
    get: () => requests.get("api/persona/get-system-persona-list")
}
const getPersonaDetails = {
    get: (persona_id: number) => requests.get(`api/persona/get-persona-deatils?persona_id=${persona_id}`)
}
const createNewPersona = {
    post: (payload: CreatePersonaPayloadModel) => requests.post("api/persona/create-persona", payload)
}
const updatePersona = {
    put: (payload: UpdatePersonaPayloadModel) => requests.put("api/persona/update-persona-details", payload)
}
const markDefaultPersona = {
    put: (payload: markDefaultPersonaPayloadModel) => requests.put("api/persona/set-default-persona", payload)
}
const removeDefaultPersona = {
    delete: (default_persona_type_id: string, persona_id: string) => requests.delete(`api/persona/remove-default-persona?default_persona_type_id=${default_persona_type_id}&persona_id=${persona_id}`)
}
const getDefaultPersonaTypeList = {
    get: () => requests.get("api/persona/get-default-persona-type-list")
}
const deletePersona = {
    delete: (persona_id: string) =>
        requests.delete(`api/persona/delete-persona?persona_id=${persona_id}`)
}
const getPersonaWritingSkillsDetails = {
    get: () => requests.get("api/persona/get-writing-skills-details")
}
const getReadingSkillsDetails = {
    get: () => requests.get("api/persona/get-reading-skills-details")
}
const getSentenceLengthDetails = {
    get: () => requests.get("api/persona/get-sentence-length-details")
}
const getNarrativeTypeDetails = {
    get: () => requests.get("api/persona/get-narrative-type-details")
}
const getCommunicationTypeDetails = {
    get: () => requests.get("api/persona/get-communication-type-details")
}
const oneTapLoginAuthCallback = {
    post: (payload: any) => requests.post("api/user/auth/callback", payload)
}
const getBannerData = {
    get: () => requests.get("api/banner/get-banner")
}
const fileUpload = {
    post: (payload: any) => requests.fileUploadPost("api/storage/upload-file", payload)
}
const createCustomTemplate = {
    post: (payload: createCustomTemplatePayloadModel) => requests.post("api/template/create-custom-template", payload)
}
const updateCustomTemplate = {
    put: (payload: createCustomTemplatePayloadModel) => requests.put("api/template/update-custom-template", payload)
}
const deleteCustomTemplate = {
    delete: (template_id: string) => requests.delete(`api/template/delete-custom-template?template_id=${template_id}`)
}
// Chatbot
const createChatbot = {
    post: (payload: createChatbotPayloadModel) => requests.post("api/chatbot", payload)
}
const getChatbotList = {
    get: () => requests.get("api/chatbot/chatbot-list")
}
const getChatbotData = {
    get: (id: number) => requests.get("api/chatbot?chatbot_id=" + id)
}
const getFileDetail = {
    get: (id: number) => requests.get("api/storage/file?file_id=" + id + "&flag_info=true")
}
const updateChatbotData = {
    put: (payload: updateChatbotPayloadModel) => requests.put("api/chatbot", payload)
}
const addChatbotDomain = {
    post: (payload: addChatbotDomainPayloadModel) => requests.post("api/chatbot/chatbot-domain", payload)
}
const updateChatbotDomain = {
    put: (payload: updateChatbotDomainPayloadModel) => requests.put("api/chatbot/chatbot-domain", payload)
}
const deleteChatbotDomain = {
    delete: (chatbot_domain_id: number) => requests.delete(`api/chatbot/chatbot-domain?chatbot_domain_id=${chatbot_domain_id}`)
}
const cancelStripeSubscription = {
    delete: (gc_stripe_customer_subscription_id: number) => requests.delete(`api/stripe/cancel-stripe-subscription?gc_stripe_customer_subscription_id=${gc_stripe_customer_subscription_id}`)
}
const UpdateStripeSubscription = {
    put: (payload: updateCustomerSubscriptionPayloadModel) => requests.put(`api/stripe/create-change-stripe-plan-customer-subscription`, payload)
}

const removeChatbot = {
    delete: (chatbot_id: any) => requests.delete(`api/chatbot?chatbot_id=${chatbot_id}`)
}


// Campaign
const getCampaignList = { get: () => requests.get("api/campaign/get-campaign-list") }

const createCampaignList = {
    post: (payload: createCampaignPayloadModel) => requests.post(`api/campaign/create-campaign`, payload)
}

const getCampaignDetails = {
    get: (campaign_id: string) => requests.get(`api/campaign/get-campaign-details?campaign_id=${campaign_id}`)
}

const updateCampaignDetails = {
    put: (payload: updateCampaignDetailsModel) => requests.put(`api/campaign/update-campaign-details`, payload)
}

const removeCampaign = {
    delete: (campaign_id: any) => requests.delete(`api/campaign/remove-campaign?campaign_id=${campaign_id}`)
}

const getNotesCategoryList = { get: ()=> requests.get("api/notes/notes-category") }

const createNoteCategory = {
    post: (payload: createNoteCategoryPayloadModel) => requests.post(`api/notes/notes-category`, payload)
}

const updateNoteCategory = {
    put: (payload: updateNoteCategoryDetailsModel) => requests.put(`api/notes/notes-category`, payload)
}

const deleteNoteCategory = {
    delete: (notes_category_id: any) => requests.delete(`api/notes/notes-category?notes_category_id=${notes_category_id}`)
}

const getNotesList = { get: ()=> requests.get("api/notes/user-notes") }

const createNoteList = {
    post: (payload: createNotePayloadModel) => requests.post(`api/notes/user-notes`, payload)
}

const updateNoteDetail = {
    put: (payload: updateNoteDetailModel) => requests.put(`api/notes/user-notes`, payload)
}

const removeNotes = {
    delete: (user_notes_id: any) => requests.delete(`api/notes/user-notes?user_notes_id=${user_notes_id}`)
}

const getTemplateCampaignList = { get: () => requests.get("api/campaign/get-campaign-template-list") }

const generateCampaignDocument = {
    post: (payload: generateCampaignDocumentPayloadModel) => requests.post(`api/campaign/generate-campaign-document`, payload)
}

const getCampaignDocuemnts = { get: (campaign_id: string) => requests.get(`api/campaign/get-campaign-document?campaign_id=${campaign_id}`) }

const getRewriteToneGenericList = {
    get: () => requests.get(`api/global/get-rewrite-tone-list`)
}
const getWorkDomainList = {
    get: () => requests.get(`api/global/get-work-domain-list`)
}
const getLanguageList = {
    get: () => requests.get("api/global/languages")
}
const getRouteSourceIds = {
    get: () => requests.get("api/global/get-route-source-list")
}
const getOutputFormatGenericList = {
    get: () => requests.get("api/global/get-output-format-generic-list"),
    getURL: "api/global/get-output-format-generic-list"

}
const getStripeCurrencyTypeGenericList = {
    get: () => requests.get("api/stripe/get-stripe-currency-type-generic-list")
}
const getPromptLogFieldsGenericList = {
    get: () => requests.get("api/dev_portal/prompt-log-field")
}
const getDialectList = {
    get: () => requests.get("api/dev_portal/dialect")
}
const getPopupList = {
    get: () => requests.get("api/popup/get-module-data-list")
}
const createUserPopupAction = {
    post: (payload: createUserPopupActionPayload) => requests.post("/api/popup/change-user-popup-action", payload)
}
const resetChatbotSession = {
    get: () => requests.get("api/chatbot/reset-chat")
}
const removeFile = {
    delete: (file_id: any) => requests.delete(`api/storage/file?file_id=${file_id}`)
}
// meta
const getMetaCredentials = {
    get: () => requests.get("api/chatbot/get-meta-credential")
}
const createChatbotIntegration = {
    post: (payload: createChatbotIntegrationPayload) => requests.post("api/chatbot/integration", payload)
}
const getChatbotIntegrationData = {
    get: (chatbot_id: string, meta_integration_id: string) => requests.get(`api/chatbot/integration?chatbot_id=${chatbot_id}&meta_integration_id=${meta_integration_id}`)
}
const deleteChatbotIntegration = {
    delete: (chatbot_id: string, meta_integration_id: string) => requests.delete(`api/chatbot/integration?chatbot_id=${chatbot_id}&meta_integration_id=${meta_integration_id}`)
}
const getChatbotThreadsList = {
    get: (chatbot_id: string) => requests.get(`api/chatbot/get-chatbot-threads-list?chatbot_id=${chatbot_id}`)
}
const getChatbotThreadDetails = {
    get: (chatbot_id: string, chatbot_user_id: string) => requests.get(`api/chatbot/get-chatbot-thread-details?chatbot_id=${chatbot_id}&chatbot_user_id=${chatbot_user_id}`)
}
const agent = {
    UserMeAPI,
    RegisterAPI,
    SendAccountOTPAPI,
    VerifyAccountOTPAPI,
    SendLoginOTPAPI,
    VerifyLoginOTPAPI,
    LogoutAPI,
    getTemplateList,
    getTemplateDetail,
    createDoc,
    getDocDetails,
    getDocList,
    updateDocumentFormSubmissionData,
    updateDocumentInfo,
    getPrompList,
    executePrompt,
    getDocumentContentTypeData,
    deleteDocument,
    updateDocumentOutlineData,
    deleteDocumentOutlineData,
    updateDocumentTitle,
    updateDocumentEditorDetail,
    contWithGoogle,
    getCustomerDetail,
    updateCustomerDetail,
    getPricingDetail,
    getStripeCustomerSubscriptionDetail,
    getStripeCustomerPortalLink,
    getPersonaList,
    getPersonaDetails,
    createNewPersona,
    updatePersona,
    getDefaultPersonaTypeList,
    markDefaultPersona,
    removeDefaultPersona,
    deletePersona,
    getPersonaWritingSkillsDetails,
    getReadingSkillsDetails,
    getSentenceLengthDetails,
    getNarrativeTypeDetails,
    getCommunicationTypeDetails,
    oneTapLoginAuthCallback,
    getBannerData,
    fileUpload,
    createCustomTemplate,
    updateCustomTemplate,
    deleteCustomTemplate,
    cancelStripeSubscription,
    UpdateStripeSubscription,
    createChatbot,
    getChatbotData,
    getFileDetail,
    getChatbotList,
    updateChatbotData,
    addChatbotDomain,
    updateChatbotDomain,
    deleteChatbotDomain,
    getCampaignList,
    createCampaignList,
    getCampaignDetails,
    updateCampaignDetails,
    removeCampaign,
    getTemplateCampaignList,
    generateCampaignDocument,
    getCampaignDocuemnts,
    removeChatbot,
    getRewriteToneGenericList,
    getWorkDomainList,
    getLanguageList,
    getRouteSourceIds,
    getOutputFormatGenericList,
    getSystemPersonaPublicList,
    getStripeCurrencyTypeGenericList,
    getPromptLogFieldsGenericList,
    getDialectList,
    getPopupList,
    createUserPopupAction,
    resetChatbotSession,
    removeFile,
    getMetaCredentials,
    createChatbotIntegration,
    getChatbotIntegrationData,
    deleteChatbotIntegration,
    LoaderDetailsAPI,
    getChatbotThreadsList,
    getChatbotThreadDetails,
    getNotesCategoryList,
    createNoteCategory,
    updateNoteCategory,
    deleteNoteCategory,
    getNotesList,
    createNoteList,
    updateNoteDetail,
    removeNotes
};

export default agent;