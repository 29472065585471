import React, { MutableRefObject, useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import {
  CommonResponseModel,
  CreateDocResponseModel,
  CreateDocPayloadModel,
  DocumentListDataModel,
  documentStepDataModel,
  StepModel,
  updateDocumentFormRequestPayloadModel,
  UpdateDocumentInfoRequestPayloadModel,
  ExecutePromptRequestPayloadModel,
  ExecutePromptResponseDataModel,
  DocumentContentTypeResponseDataModel,
  UpdateDocumentOutlineDataPayloadModel,
  DeleteDocumentOutlineDataParamsModel,
  UpdateDocumentTitlePayloadModel,
  UpdateDocumentEditorPayloadModel,
  ExecutePromptResponseModel,
  executePromptResponseParaphraseObj,
} from "../models/axiosModel";
import agent from "../services/api";

const DocContext = React.createContext<DocContextPayload | undefined>(
  undefined
);

export interface DocContextPayload {
  createdDoc: CreateDocResponseModel | null;
  setCreatedDoc: React.Dispatch<React.SetStateAction<CreateDocResponseModel | null>>;
  CreateDoc: (payload: CreateDocPayloadModel) => Promise<CommonResponseModel<CreateDocResponseModel | null>>;
  GetDocDetails: (document_uuid: string) => Promise<CommonResponseModel<CreateDocResponseModel | null>>;
  currentActiveDocDetails: CreateDocResponseModel | null;
  setCurrentActiveDocDetails: React.Dispatch<React.SetStateAction<CreateDocResponseModel | null>>;
  currentActiveStep: number;
  setCurrentActiveStep: React.Dispatch<React.SetStateAction<number>>;
  steps: StepModel[] | null;
  setSteps: React.Dispatch<React.SetStateAction<StepModel[] | null>>;
  blogTitle: string;
  setBlogTitle: React.Dispatch<React.SetStateAction<string>>;
  flagShowGenerateTitlesButton: boolean | null;
  setFlagShowGenerateTitlesButton: React.Dispatch<React.SetStateAction<boolean | null>>;
  docList: DocumentListDataModel[] | null;
  setDocList: React.Dispatch<React.SetStateAction<DocumentListDataModel[] | null>>
  GetDocList: () => Promise<CommonResponseModel<DocumentListDataModel | null>>;
  UpdateDocForm: (payload: updateDocumentFormRequestPayloadModel) => void;
  UpdateDocInfo: (payload: UpdateDocumentInfoRequestPayloadModel) => Promise<CommonResponseModel<CreateDocResponseModel>>;
  ExecutePrompt: (payload: ExecutePromptRequestPayloadModel) => any;
  GetDocumentContentTypeData: () => void;
  aiSuggestedTitles: ExecutePromptResponseDataModel[];
  setAISuggestedTitles: React.Dispatch<React.SetStateAction<ExecutePromptResponseDataModel[]>>
  flagTextLoader: boolean;
  setFlagTextLoader: React.Dispatch<React.SetStateAction<boolean>>
  flagOutlineCollapsed: boolean;
  setFlagOutlineCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  flagGenerateButtonDisabled: boolean;
  setFlagGenerateButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
  flagMultiStep: boolean | null;
  setFlagMultiStep: React.Dispatch<React.SetStateAction<boolean | null>>;
  flagShowAIOutput: boolean;
  setFlagShowAIOutput: React.Dispatch<React.SetStateAction<boolean>>;
  flagOutlineLoader: boolean;
  setFlagOutlineLoader: React.Dispatch<React.SetStateAction<boolean>>;
  flagRewritePopup: boolean | null;
  setFlagRewritePopup: React.Dispatch<React.SetStateAction<boolean | null>>;
  flagGenerateTitleButtonClicked: boolean;
  setFlagGenerateTitleButtonClicked: React.Dispatch<React.SetStateAction<boolean>>;
  flagDebouncePending: boolean;
  setFlagDebouncePending: React.Dispatch<React.SetStateAction<boolean>>;
  UpdateDocTitle: (payload: UpdateDocumentTitlePayloadModel) => Promise<CommonResponseModel<CreateDocResponseModel>>;
  UpdateDocumentEditorDetail: (payload: UpdateDocumentEditorPayloadModel) => Promise<CommonResponseModel<CreateDocResponseModel>>;
  documentOutlineData: UpdateDocumentOutlineDataPayloadModel[] | null
  documentEditorData: any,
  setDocumentEditorData: React.Dispatch<React.SetStateAction<any>>,
  setDocumentOutlineData: React.Dispatch<React.SetStateAction<any>>,
  editorSelectedText: string | null;
  setEditorSelectedText: React.Dispatch<React.SetStateAction<string | null>>,
  executePromptResponse: ExecutePromptResponseModel | null;
  setExecutePromptResponse: React.Dispatch<React.SetStateAction<ExecutePromptResponseModel | null>>
  webSocketObj: any | null;
  setWebSocketObj: React.Dispatch<React.SetStateAction<any | null>>;
  flagAutoFocus: boolean,
  setFlagAutoFocus: React.Dispatch<React.SetStateAction<boolean>>
  currentActiveDocFieldVal: any,
  setCurrentActiveDocFieldVal: React.Dispatch<React.SetStateAction<any>>;
  flagValUpdated: boolean | null;
  setFlagValUpdated: React.Dispatch<React.SetStateAction<boolean | null>>
  caretPositionCount: number | null,
  setCaretPositionCount: React.Dispatch<React.SetStateAction<number | null>>
  prevCursorPos: any,
  setPrevCursorPos: React.Dispatch<React.SetStateAction<any>>
  tabValuePanel: number,
  setTabValuePanel: React.Dispatch<React.SetStateAction<number>>
  setEditorHtmlData: React.Dispatch<React.SetStateAction<string>>
  editorHtmlData: string;
  flagFormDetailsChanged: boolean;
  setFlagFormDetailsChanged: React.Dispatch<React.SetStateAction<boolean>>;
  executePromptResponseParaphrase: executePromptResponseParaphraseObj[] | [],
  setExecutePromptResponseParaphrase: React.Dispatch<React.SetStateAction<executePromptResponseParaphraseObj[] | []>>
  selectedParaphraseText: any,
  setSelectedParaphraseText: React.Dispatch<React.SetStateAction<any>>
}

const DocProvider = ({ children }: ChildrenProps) => {
  const emptyDoc = {
    document_id: null,
    document_name: "",
    parent_document_id: null,
    user_assignment_id: null,
    user_id: null,
    template_id: null,
    timestamp: "",
  };
  const initialAISuggestedTitlesArray = [
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" }
  ]
  const [createdDoc, setCreatedDoc] = useState<CreateDocResponseModel | null>(null);
  const [currentActiveDocDetails, setCurrentActiveDocDetails] = useState<CreateDocResponseModel | null>(null);
  const [steps, setSteps] = useState<StepModel[] | null>(null)
  const [currentActiveStep, setCurrentActiveStep] = useState(1)
  const [blogTitle, setBlogTitle] = useState(currentActiveDocDetails?.document_title == null ? "" : currentActiveDocDetails?.document_title)
  const [flagShowGenerateTitlesButton, setFlagShowGenerateTitlesButton] = useState<boolean | null>(null)
  const [docList, setDocList] = useState<DocumentListDataModel[] | null>(null)
  const [aiSuggestedTitles, setAISuggestedTitles] = useState<ExecutePromptResponseDataModel[]>(initialAISuggestedTitlesArray)
  const [flagTextLoader, setFlagTextLoader] = useState<boolean>(false)
  const [flagOutlineCollapsed, setFlagOutlineCollapsed] = useState(false);
  const [flagGenerateButtonDisabled, setFlagGenerateButtonDisabled] = useState(true)
  const [flagMultiStep, setFlagMultiStep] = useState<boolean | null>(null);
  const [flagShowAIOutput, setFlagShowAIOutput] = useState<boolean>(false);
  const [flagOutlineLoader, setFlagOutlineLoader] = useState<boolean>(false);
  const [flagRewritePopup, setFlagRewritePopup] = useState<boolean | null>(null);
  const [flagGenerateTitleButtonClicked, setFlagGenerateTitleButtonClicked] = useState<boolean>(false)
  const [flagDebouncePending, setFlagDebouncePending] = useState<boolean>(false)
  const [documentContentTypeList, setDocumentContentTypeList] = useState<DocumentContentTypeResponseDataModel[] | null>(null)
  const [documentOutlineData, setDocumentOutlineData] = useState<UpdateDocumentOutlineDataPayloadModel[] | null>(null)
  const [documentEditorData, setDocumentEditorData] = useState(null)
  const [editorSelectedText, setEditorSelectedText] = useState<string | null>(null);
  const [executePromptResponse, setExecutePromptResponse] = useState<ExecutePromptResponseModel | null>(null);
  const [webSocketObj, setWebSocketObj] = useState<any>(null);
  const [flagAutoFocus, setFlagAutoFocus] = useState<boolean>(false);
  const [currentActiveDocFieldVal, setCurrentActiveDocFieldVal] = useState<any>(null)
  const [flagValUpdated, setFlagValUpdated] = useState<boolean | null>(null)
  const [caretPositionCount, setCaretPositionCount] = useState<number | null>(null);
  const [prevCursorPos, setPrevCursorPos] = React.useState<any>({ x: 0, y: 0 });
  const [tabValuePanel, setTabValuePanel] = React.useState<number>(0)
  const [editorHtmlData, setEditorHtmlData] = React.useState<string>("")
  const [flagFormDetailsChanged, setFlagFormDetailsChanged] = useState<boolean>(false);
  const [executePromptResponseParaphrase, setExecutePromptResponseParaphrase] = useState<executePromptResponseParaphraseObj[] | []>([])
  const [selectedParaphraseText, setSelectedParaphraseText] = useState<any>(null);


  // for dummy api call
  // const CreateDoc = (payload: CreateDocPayloadModel): Promise<CommonResponseModel<CreateDocResponseModel>> => {
  //   return new Promise((resolve,reject)=>{
  //       setTimeout(async()=>{
  //           const response ={
  //               status:true,
  //               data:[
  //                   {
  //                       document_id: 1,
  //                       document_name: "Untitled Document",
  //                       parent_document_id: 1,
  //                       user_assignment_id: 1,
  //                       user_id: 1,
  //                       template_id: 1,
  //                       timestamp: "54545",
  //                   }
  //               ]
  //           }
  //          await setCreatedDoc({...response.data[0]})
  //           resolve(response)
  //       },2000)
  //   })
  // };
  const CreateDoc = async (payload: CreateDocPayloadModel): Promise<CommonResponseModel<CreateDocResponseModel | null>> => {
    const response = await agent.createDoc.post(payload);
    if (response.status) {
      setCreatedDoc({ ...response.data[0] });
      return response
    } else {
      setCreatedDoc(null);
      return response
    }
  };

  const getNextButtonText = (index: number) => {
    switch (index) {
      case 0: {
        return "Next: Outline"
      }
      case 1: {
        return "Generate Blog"
      }
      case 2: {
        return "Integrate"
      }
    }
  }

  function updateResponseAsRequired(data: any) {
    for (const item of data) {
      item['unique_id'] = item.document_outline_id
      delete item.document_outline_level_num
      delete item.document_outline_seq_num

      if (item?.children && item.children.length > 0) {
        updateResponseAsRequired(item.children);
      }
    }
    return data
  }
  const GetDocDetails = async (document_uuid: string): Promise<CommonResponseModel<CreateDocResponseModel | null>> => {
    const response = await agent.getDocDetails.get(document_uuid);
    if (response.status) {
      setCurrentActiveDocDetails({ ...response.data[0] });
      // response.data[0].document_step_data[1].document_outline_data?.map(())
      let updatedOutlineResponse
      let updatedEditorResponse
      if (response.data[0].template_type_name == "Blog-Generation") {
        updatedOutlineResponse = updateResponseAsRequired(response.data[0].document_step_data?.filter((step: documentStepDataModel) => step?.step_type_name == "Outline")[0].document_outline_data)
        setDocumentOutlineData(updatedOutlineResponse);
        updatedEditorResponse = response.data[0].document_step_data?.filter((step: documentStepDataModel) => step?.step_type_name == "Editor")[0].document_content_data[0]
        if (updatedEditorResponse) {
          let finalState: any = {
            root: updatedEditorResponse
          }
          setDocumentEditorData(finalState);
        }
      } else if (response.data[0].template_type_name == "Single-Page" || response.data[0].template_type_name == "Blank") {
        if (response.data[0].document_html_body_flag) {
          setEditorHtmlData(response?.data[0]?.document_html_body)
        } else {
          updatedEditorResponse = response.data[0].document_step_data?.filter((step: documentStepDataModel) => step?.step_type_name == "Editor")[0].document_content_data[0]
          if (updatedEditorResponse) {
            let finalState: any = {
              root: updatedEditorResponse
            }
            setDocumentEditorData(finalState);
          }
        }
      }
      let tempSteps = response.data[0].document_step_data.map((step: documentStepDataModel, index: number) => {
        return {
          id: index,
          label: step.template_step_name,
          description: step.template_step_description,
          nextStepText: getNextButtonText(index),
          flagMoveForward: index < response.data[0].document_step_data.length ? true : false
        }
      })
      setSteps(tempSteps)
      return response;
    } else {
      setCurrentActiveDocDetails(null);
      return response;
    }
  };

  const GetDocList = async () => {
    const response = await agent.getDocList.get();
    if (response.status) {
      setDocList(response.data);
    } else {
      setDocList(null);
    }
    return response;
  }

  const UpdateDocForm = async (payload: updateDocumentFormRequestPayloadModel) => {
    const response = await agent.updateDocumentFormSubmissionData.put(payload);
    return response;
  }

  const UpdateDocInfo = async (payload: UpdateDocumentInfoRequestPayloadModel): Promise<CommonResponseModel<CreateDocResponseModel>> => {
    const response = await agent.updateDocumentInfo.put(payload)
    return response;
  }
  const ExecutePrompt = async (payload: ExecutePromptRequestPayloadModel) => {
    const response = await agent.executePrompt.post(payload)
    return response;
  }
  const GetDocumentContentTypeData = async () => {
    const response = await agent.getDocumentContentTypeData.get()
    if (response.status) {
      setDocumentContentTypeList(response.data)
    }
  }
  const UpdateDocumentOutlineData = async (payload: UpdateDocumentOutlineDataPayloadModel[]) => {
    const response = await agent.updateDocumentOutlineData.put(payload)
    return response
  }
  const DeleteDocumentOutlineData = async (params: DeleteDocumentOutlineDataParamsModel) => {
    const response = await agent.deleteDocumentOutlineData.delete(params)
    return response;
  }
  const UpdateDocTitle = async (payload: UpdateDocumentTitlePayloadModel) => {
    const response = await agent.updateDocumentTitle.put(payload)
    return response;
  }
  const UpdateDocumentEditorDetail = async (payload: UpdateDocumentEditorPayloadModel) => {
    const response = await agent.updateDocumentEditorDetail.put(payload)
    if (response.status) {
      let updatedEditorResponse = response.data[0].document_step_data?.filter((step: documentStepDataModel) => step?.step_type_name == "Editor")[0].document_content_data[0]
      // let finalState: any = {
      //   root: updatedEditorResponse
      // }
      // setDocumentEditorData(finalState)
    }
    return response
  }
  const payload = {
    createdDoc,
    setCreatedDoc,
    CreateDoc,
    currentActiveDocDetails,
    setCurrentActiveDocDetails,
    currentActiveStep,
    setCurrentActiveStep,
    steps,
    setSteps,
    blogTitle,
    setBlogTitle,
    flagShowGenerateTitlesButton,
    setFlagShowGenerateTitlesButton,
    GetDocDetails,
    docList,
    setDocList,
    GetDocList,
    UpdateDocForm,
    UpdateDocInfo,
    ExecutePrompt,
    aiSuggestedTitles,
    setAISuggestedTitles,
    flagTextLoader,
    setFlagTextLoader,
    flagOutlineCollapsed,
    setFlagOutlineCollapsed,
    flagGenerateButtonDisabled,
    setFlagGenerateButtonDisabled,
    flagMultiStep,
    setFlagMultiStep,
    flagShowAIOutput,
    setFlagShowAIOutput,
    flagGenerateTitleButtonClicked,
    setFlagGenerateTitleButtonClicked,
    flagDebouncePending,
    setFlagDebouncePending,
    GetDocumentContentTypeData,
    documentContentTypeList,
    documentOutlineData,
    setDocumentOutlineData,
    UpdateDocumentOutlineData,
    DeleteDocumentOutlineData,
    UpdateDocTitle,
    UpdateDocumentEditorDetail,
    documentEditorData,
    setDocumentEditorData,
    setFlagOutlineLoader,
    flagOutlineLoader,
    setFlagRewritePopup,
    flagRewritePopup,
    editorSelectedText,
    setEditorSelectedText,
    executePromptResponse,
    setExecutePromptResponse,
    webSocketObj,
    setWebSocketObj,
    flagAutoFocus,
    setFlagAutoFocus,
    currentActiveDocFieldVal,
    setCurrentActiveDocFieldVal,
    flagValUpdated,
    setFlagValUpdated,
    caretPositionCount,
    setCaretPositionCount,
    prevCursorPos,
    setPrevCursorPos,
    tabValuePanel,
    setTabValuePanel,
    setEditorHtmlData,
    editorHtmlData,
    flagFormDetailsChanged,
    setFlagFormDetailsChanged,
    executePromptResponseParaphrase,
    setExecutePromptResponseParaphrase,
    selectedParaphraseText,
    setSelectedParaphraseText
  };
  return <DocContext.Provider value={payload}>{children}</DocContext.Provider>;
};

export { DocContext, DocProvider };
