import * as React from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Divider
} from "@mui/material";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { emailRegex, SiteKey, userNameRegex } from "../../utils/constants";
import { UserContext, UserContextPayload } from "../../context/userContext";
import { RegisterDataResponseModel, errorModel } from "../../models/axiosModel";
import GlobalTextField from "../utility/globalTextfield";
// import styles from "../../assets/styles/global.module.scss";
import styles from "../../assets/styles/componentStyles/registration.module.scss";
import GlobalSnackbar from "../utility/globalSnackbar";
import GlobalButton from "../utility/globalButton/button";
import writeWizLogo from "../../assets/whiteWELogo.svg"
import GlobalForm from "../utility/formWiz/globalForm";
import globalStyles from "../../assets/styles/global.module.scss";
import googleIcon from "../../assets/google.svg";
import registrationRightPanelImage from "../../assets/registration.svg";
import registered from "../../assets/registered.svg";
import VerificationComponent from "../utility/verificationComponent";
import edit from "../../assets/edit.png";
import microsoftIcon from "../../assets/microsoft.svg";
import agent from "../../services/api";
import writewizLogo from "../../assets/writewizLogo.svg";
import { env } from "process";
import updated from "../../assets/updated.svg";
import { getErrorMessage, isDev } from "../../utils/constants";
import 'animate.css';

const LogoComponent = () => (
  <img src={edit} alt="Logo" width="17px" height="17px" />
);
export default function Register(props: any) {
  let navigate = useNavigate();
  const { Register, setUserDetail, userDetail, setFlagLoading, VerifyAccount, SendAccountOTP, UserMe, setError, error, setSuccess, success, flagLoading } = useContext(
    UserContext
  ) as UserContextPayload;

  const [user, setUser] = useState({
    user_first_name: "",
    user_last_name: "",
    user_email: "",
    user_phone_number: "",
    google_recaptcha_token: "",
  });

  const emptyErrorObj = {
    flagShowError: false,
    message: "",
  };

  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const [flagEmailValid, setFlagEmailValid] = useState(true);
  const [flagFirstNameValid, setFlagFirstNameValid] = useState(true);
  const [flagLastNameValid, setFlagLastNameValid] = useState(true);
  const [registrationformData, setRegistrationFormData] = useState<any>({
    data: {
      widget_form_full_width: true,
      global_form_field: [
        {
          "page_field_id": 1666,
          "label": "First Name",
          "placeholder": "Enter First Name",
          "required": true,
          "name": "1666_First_Name ",
          "type": "text",
          "value": "",
          "hidden": false,
          "position": null,
          "font_color": null,
          "col": 5.8,
          "class2": "globalTextfieldStyle"
        },
        {
          "page_field_id": 1667,
          "label": "Last Name",
          "placeholder": "Enter Last Name",
          "required": false,
          "name": "1667_Last_Name ",
          "type": "text",
          "value": "",
          "hidden": false,
          "position": null,
          "font_color": null,
          "col": 5.8,
          "class2": "globalTextfieldStyle"
        },
        {
          "page_field_id": 1668,
          "label": "Email",
          "placeholder": "Enter Email",
          "required": true,
          "name": "1668_Email ",
          "type": "email",
          "value": "",
          "hidden": false,
          "position": null,
          "font_color": null,
          "col": 12,
          "class2": "globalTextfieldStyle"
        },
        {
          "page_field_id": 1669,
          "label": "Phone",
          "placeholder": "Enter Phone",
          "required": false,
          "name": "1669_Phone ",
          "type": "phone",
          "value": "",
          "hidden": false,
          "position": null,
          "font_color": null,
          "col": 12,
          "class2": "globalPhoneStyle"
        },
        {
          "page_field_id": 1591,
          "required": true,
          "name": "1669_recaptcha",
          "type": "recaptcha",
          "value": "",
          "hidden": false,
          "position": "center",
          "font_color": null,
          "reCAPTCHAsitekey": SiteKey,
          "col": 12,
          "label": "reCAPTCHA",
          "class1": "recaptchaBlock"
        },
        {
          "page_field_id": 1670,
          "label": "Register",
          "placeholder": null,
          "required": false,
          "name": "1670_Submit ",
          "type": "submit",
          "value": "",
          "hidden": false,
          "position": "center",
          "font_color": "#FFFFFF",
          "buttonBackgroundColor": "#6A097D",
          "col": 12,
          "flag_ai_suggestion": false,
          "class2": "globalTextfieldStyle",
          "color": "#fff",
          "buttonFontSize": "15px",
          "buttonFontWeight": "600",
          "background": "#6A097D",
          "buttonHeight": "56px",
          "marginButton": "20px 0px 0px"
        }
      ],
      widget_flag_auto_save: false,
      widget_flag_hide_button: false,
      doc_page_theme: [
        {
          "page_id": "682",
          "page_theme_font_type": "Open Sans",
          "page_theme_font_style": "normal",
          "page_theme_font_align": "left",
          "page_theme_font_weight": "bold",
          "page_theme_font_size": "15",
          "page_theme_background_color": "#FFFFFF",
          "page_theme_heading_color": "#EFE4F1",
          "page_theme_text_decoration": "unset",
          "page_hide_button": false,
          "page_auto_save": false,
          "page_font_spacing": null,
          "page_theme_row_spacing": "10px 0px 1px 0px",
          "page_theme_column_spacing": 0,
          "margin": "0px 0px 8px 0px"
        }
      ]
    }
  })
  const [flagSuccessfullRegistered, setFlagSuccessfullRegistered] = useState(false)
  const [flagShowVerificationBlock, setFlagShowVerificationBlock] = useState(false)
  const [flagTimerActive, setFlagTimerActive] = useState(false);

  const RegisterUser = async (data: any) => {
    const payload = {
      user_first_name: "",
      user_last_name: "",
      user_email: "",
      user_phone_number: "",
      google_recaptcha_token: "",
    }
    registrationformData.data.global_form_field.forEach((field: any) => {
      const fieldName = field.name;
      const value = field.value;

      if (fieldName.includes("First_Name")) {
        payload.user_first_name = value;
      } else if (fieldName.includes("Last_Name")) {
        payload.user_last_name = value;
      } else if (fieldName.includes("Email")) {
        payload.user_email = value;
      } else if (fieldName.includes("Phone")) {
        payload.user_phone_number = value;
      }
      else if (fieldName.includes("recaptcha")) {
        payload.google_recaptcha_token = value;
      }
    });
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
    const response = await Register(payload);
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
    if (response.status) {
      if (Array.isArray(response.data)) {
        setFlagSuccessfullRegistered(true)
        setUserDetail({ ...response.data[0], flagUser: response.status });
        const timerForLoader = setTimeout(() => {
          sendAccountOtp()
          setFlagShowVerificationBlock(true)
        }, 2000);
        return () => clearTimeout(timerForLoader)
      }
    } else {
      recaptchaRef.current?.reset();
      setRenderValue({ ...renderValue, status: true })
      setError({
        ...error,
        flagShowError: true,
        message: getErrorMessage(response?.error?.code, isDev(userDetail)),
      });
    }
  };

  const checkRegisterButtonDisableCondition = () => {
    if (
      user.user_first_name !== "" &&
      user.user_email !== "" &&
      flagEmailValid
    ) {
      return false;
    } else {
      return true;
    }
  };

  // const userToken = useParams();
  // const [widget_favicon_url, set_Widget_favicon_url] = useState("");
  // const [widget_logo_url, set_Widget_logo_url] = useState("");
  const [submissionID, setsubmissionID] = useState(null);
  // const [showForm, setShowForm] = useState(false);
  // const [loaderFlag, setLoaderFlag] = useState(false);
  const [flagFirstRenderDone, setFlagFirstRenderDone] = useState(false)
  const [renderValue, setRenderValue] = useState<any>({
    status: null,
    message: "",
    submission_id: null
  })
  const [seconds, setSeconds] = useState(60);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  // const [widgetData, setWidgetData] = useState({
  //     data: {
  //         global_form_field: [
  //             {
  //                 page_field_id: "",
  //                 label: "",
  //                 placeholder: "",
  //                 required: true,
  //                 name: "",
  //                 type: "",
  //                 value: "",
  //                 hidden: false,
  //                 position: null,
  //                 font_color: null,
  //             }
  //         ],
  //         widget_page: [
  //             {
  //                 page_id: '',
  //                 page_name: "",
  //                 page_field: [
  //                     {
  //                         page_field_id: '',
  //                         field_id: '',
  //                         field_type: "",
  //                         field_icon_id: '',
  //                         field_description: '',
  //                         field_option: [
  //                             {
  //                                 field_option_data_id: '',
  //                                 field_option_id: '',
  //                                 field_option_name: "",
  //                                 field_option_placeholder: "",
  //                                 field_option_description: '',
  //                                 field_option_value: "",
  //                                 field_option_required: '',
  //                                 form_option_default_value: '',
  //                                 field_option_add_field: [],
  //                                 field_data_type: {
  //                                     field_data_type_id: '',
  //                                     field_data_type_name: "",
  //                                     field_data_type_unit: ''
  //                                 }
  //                             },
  //                         ]
  //                     }]
  //             }],
  //         widget_page_theme: [
  //             {
  //                 page_id: "",
  //                 page_theme_font_type: "",
  //                 page_theme_font_style: "",
  //                 page_theme_font_align: "",
  //                 page_theme_font_weight: "",
  //                 page_theme_font_size: "",
  //                 page_theme_background_color: "",
  //                 page_theme_heading_color: "",
  //                 page_theme_text_decoration: ""
  //             }
  //         ]
  //     }
  // });
  // const [googleIdFinal, setGoogleIdFinal] = useState([]);

  // const [debouncedInputValue] = useDebounce(registrationformData, 2000);

  // useEffect(() => {
  //     setLoaderFlag(true)

  //     GetWidgetDetailsAPI(
  //         userToken.token,
  //         (res) => {
  //             setWidgetData(res.data)
  //             if (res.data.status) {
  //                 document.title = res.data.data.widget_name
  //                 setShowForm(true)
  //                 setLoaderFlag(false)
  //             }
  //             else {
  //                 setShowForm(false)
  //                 window.open('https://app.filemaster.io/login', '_self');
  //             }
  //         },
  //         (err) => {
  //             console.log("err", err);
  //         });
  // }, [])

  // useEffect(() => {

  //     let widget_favicon_url = "https://api.filemaster.io/api/storage/file?file_id=" + widgetData.data.widget_favicon_id + "&flag_info=false";
  //     set_Widget_favicon_url(widget_favicon_url)
  //     let widget_logo_url = "https://api.filemaster.io/api/storage/file?file_id=" + widgetData.data.widget_logo_id + "&flag_info=false";
  //     set_Widget_logo_url(widget_logo_url)
  //     if (flagFirstRenderDone)
  //         debounced()

  // }, [widgetData])




  const handleCloseSnackBar = () => {
    setRenderValue({
      status: null,
      message: "",
      submission_id: null
    })
  }

  const setFieldValue = (data: any) => {
    var tempdata = { ...registrationformData }
    tempdata.data.global_form_field.map((newvalue: any) => {

      if (newvalue.options) {
        newvalue.options.map((option_value: any) => {
          if (option_value.field_option_data_id == data.field_option_data_id) {
            option_value.field_option_flag = data.page_field_value
          }
        })
      }
      if (newvalue.page_field_id === data.page_field_id) {
        newvalue.value = data.page_field_value
      }
    })
    setRegistrationFormData(tempdata)
    setFlagFirstRenderDone(true)
  }

  const formSubmitFunction = (data: any) => {
    // console.log("devounce value in preview--------------", data);
    var finalData = data;
    RegisterUser(data)
    // FormSubmissionAPI(
    //     finalData,
    //     (res) => {
    //         if (res.status == 200) {
    //             if (res.data.status) {
    //                 setRenderValue({ status: true, message: res.data.data.message, submission_id: res.data.data.submission_id })
    //                 setsubmissionID(res.data.data.submission_id)
    //             }
    //         } else {
    //             setRenderValue({ status: false, message: "Something Went wrong!", submission_id: null })
    //         }
    //     },
    //     (err) => {
    //         console.log("err", err);
    //         setRenderValue({ status: false, message: "Something Went wrong!", submission_id: null })
    //     })
  }

  const handleVerifyAccount = async (otp: any) => {
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });
    const payload = {
      authentication_code: otp,
    };
    const response = await VerifyAccount(payload);
    if (response.status) {
      await UserMe()
      const searchParams = new URLSearchParams(window.location.search); let redirect = searchParams.get("redirect")
      if (redirect) {
        let redirectionURL = new URL(decodeURIComponent(redirect));
        let pathName = redirectionURL.pathname
        navigate(pathName)
      } else {
        navigate("/dashboard");
      }
    } else {
      // if(response.data){}
      setError({ ...error, flagShowError: true, message: "Incorrect OTP! Please try with a valid OTP" })
      // setInvalidOtp(true)
    }
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
  };
  const handleStartTimer = () => setFlagTimerActive(true);

  const handleStopTimer = () => setFlagTimerActive(false);

  const handleResetTimer = () => {
    setFlagTimerActive(false);
    setSeconds(60);
  };
  const handleFlagOtp = () => {
    setFlagShowVerificationBlock(!flagShowVerificationBlock);
    // setOtp("");
  };
  const sendAccountOtp = async () => {
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });
    const response = await SendAccountOTP();
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    if (response.status) {
      setFlagShowVerificationBlock(true)
      handleStopTimer();
      handleResetTimer();
      handleStartTimer();
      if (response.data) {
        setSuccess({
          ...success,
          flagShowSuccess: response.status,
          message: getErrorMessage(response.data.code, isDev(userDetail)),
        });
      }
    } else {
      if (response.data) {
        setError({ ...error, flagShowError: true, message: getErrorMessage(response?.error?.code, isDev(userDetail)) })
      } else {
        setError({ ...error, flagShowError: true, message: getErrorMessage(response?.error?.code, isDev(userDetail)) })
      }
    }
  };

  useEffect(() => {
    let timerId: any;
    if (flagTimerActive && seconds > 0) {
      timerId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      setFlagTimerActive(false);
    }
    return () => clearInterval(timerId);
  }, [flagTimerActive, seconds]);

  const contWithGoogle = async () => {
    setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
    // const
    // const currentUrl = "https://app.writewiz-staging.in/home";
    let currentUrl
    const searchParams = new URLSearchParams(window.location.search); let redirect = searchParams.get("redirect")
    if (redirect) {
      currentUrl = decodeURIComponent(redirect);
    } else {
      currentUrl = window.location.href.replace("register", "dashboard")
    }

    const response = await agent.contWithGoogle.get(currentUrl, 1);
    // setFlagLoading(false);
    if (response.status) {
      if (response.data) {
        let url = response.data
        // navigate(url, {
        //   replace: true,
        // });
        window.location.href = url;
      }
    } else {
      recaptchaRef.current?.reset();
      setError({
        ...error,
        flagShowError: true,
        message: getErrorMessage(response?.error?.code, isDev(userDetail)),
      });
    }
    console.log("response of register", response);
    setFlagLoading({ ...flagLoading, flagRoundLoader: false });;

  };
  return (
    <Grid container>
      <Grid item className={styles.rightBlockWrapper}>
        <Grid container className={styles.rightBlock} justifyContent={"center"} alignItems="center" direction="column">
          <Grid item>
            <img src={writeWizLogo} />
          </Grid>
          <Grid item>
            {/* pic */}
            <img src={updated} style={{ margin: "50px 0px" }} />
          </Grid>
          <Grid item className={styles.italicText}>
            “AI Content Generator, Writing Assistant, & Chatbot”
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={styles.leftBlockWrapper}>
        {flagSuccessfullRegistered || props.flagSuccessfullRegistered ?
          <Grid container direction="row" alignItems={"center"} justifyContent={"center"} className={styles.leftBlock}>
            {flagShowVerificationBlock || props.flagShowVerificationBlock ?
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item className={styles.verifyText}>Verify your account</Grid>
                      <Grid item className={styles.verifySubText}>We have sent a verification code on your registered email</Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={`animate__animated animate__fadeInRight`}>
                    <VerificationComponent
                      value={userDetail.user_email}
                      onChange={() => { setFlagShowVerificationBlock(!flagShowVerificationBlock) }}
                      disabled={flagShowVerificationBlock}
                      onClick={handleVerifyAccount}
                      handleResendOTP={sendAccountOtp}
                      handleStartTimer={handleStartTimer}
                      handleStopTimer={handleStopTimer}
                      handleResetTimer={handleResetTimer}
                      seconds={seconds}
                      minutes={minutes}
                      remainingSeconds={remainingSeconds}
                      flagTimerActive={flagTimerActive}
                    // InputProps={{
                    //   endAdornment: flagShowVerificationBlock && (
                    //     <InputAdornment position="end">
                    //       <IconButton onClick={handleFlagOtp}>
                    //         {flagShowVerificationBlock ? (
                    //           <LogoComponent />
                    //         ) : (
                    //           ""
                    //         )}
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              :
              <Grid container direction="column" alignItems={"center"}>
                <Grid item>
                  <img src={registered} />
                </Grid>
                <Grid item>
                  <Typography className={styles.successSubAuthText}>
                    <Grid container direction="column" className={styles.successAuthTextWrapper}>
                      <Grid item>
                        <span className={styles.successAuthText}>Congratulations!</span> <span className={styles.successAuthNameText}>{userDetail.user_first_name} {userDetail.user_last_name}</span>
                      </Grid>
                      <Grid item className={styles.successSubAuthText}>
                        your account has been created successfully!
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
              </Grid>
            }
          </Grid>
          :
          <Grid container direction="column" alignItems={"center"} justifyContent={"center"} className={styles.leftBlock}>
            <Grid item className={styles.writewizLogoWrapper}>
              <img src={writewizLogo} className={styles.logoStyle} />
            </Grid>
            <Grid item className={styles.authLabelRegistration}>Registration</Grid>
            <Grid item className={globalStyles.authSublabelText}>Create your free <span className={globalStyles.authSublabelProductTextStyle}>Writewiz</span> account </Grid>
            {!flagShowVerificationBlock &&
              <>
                <Button onClick={contWithGoogle} className={globalStyles.continueWithGoogleButton}>
                  <img src={googleIcon} />
                  <div style={{ paddingLeft: "26px" }}>
                    Continue with Google
                  </div>
                </Button>
                <Divider className={`${globalStyles.fullwidth} ${globalStyles.dividerStyle}`}>OR</Divider>
              </>
            }
            <Grid item>
              <GlobalForm
                fields={registrationformData.data.global_form_field}
                submitFunction={formSubmitFunction}
                checkFormSubmit={renderValue}
                handleCloseSnackBar={handleCloseSnackBar}
                pageThemeData={registrationformData.data.doc_page_theme[0]}
                formData={registrationformData.data}
                submissionID={submissionID}
                setFieldValue={setFieldValue}
                recaptchaFlag="true"
                defaultLoader={false}
                formEditable={true}
              />
            </Grid>
            {/* {!userDetail.flag_user_anonymous && */}
            <Grid item sx={{ paddingLeft: "4px" }} className={`${globalStyles.authPromptTextStyleRegistration} ${globalStyles.fullwidth}`}>
              Already have an account? <span onClick={() => {
                const searchParams = new URLSearchParams(window.location.search); let redirect = searchParams.get("redirect")
                if (redirect) {
                  navigate("/login?redirect=" + encodeURIComponent(redirect));
                } else {
                  navigate("/login")
                }
              }} className={`${globalStyles.authPromptSubTextStyle} ${globalStyles.clickable}`}>Log In</span>
            </Grid>
            <Grid item className={styles.termsLineItem} mt={1}>
              By creating an account, you are agreeing to our
              <Button variant="text" className={styles.termsFontPopup} onClick={() => { navigate("/terms") }}> Terms</Button> and
              <Button variant="text" className={styles.privacyFontPopup} onClick={() => { navigate("/privacy-policy") }}> Privacy Policy.</Button>
            </Grid>
            {/* } */}
            {/* <formwiz form */}
          </Grid>
        }

      </Grid>
    </Grid>

  );
}
