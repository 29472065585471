import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import writewizLogo from "../../../assets/writewizLogo.svg";
import styles from "../../../assets/styles/componentStyles/sideNavigationPanel.module.scss";
import { useNavigate } from 'react-router-dom';
import { CreditUsedComponent } from '../../utility/creditUsed';
import { Grid } from '@mui/material';
import GlobalButton from '../../utility/globalButton/button';
import { SidepanelAccountDetails } from '../../utility/sidePanelAccountDetails';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import premiumIcon from "../../../assets/personaCrown.svg";
import MiniDrawer from './test';
import globalStyles from "../../../assets/styles/global.module.scss";
const drawerWidth = 233;
const mobileDrawerWidth = 96;
interface menuItem {
    menuName: string,
    menuKey: string,
    imgSrc: any,
    whiteImgSrc: any,
    coloredImgSrc: any,
    navigationLink: string,
    flagDisabled?: boolean
}
interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    open: boolean;
    onClose: () => void;
    menuItems: menuItem[]
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
    openDesktopDrawer: boolean
}
export default function SideNavigationPanel(props: Props) {
    const { userDetail, flagChromeDesktop, setShowLoginPopup } = React.useContext(UserContext) as UserContextPayload
    const [currentActiveMenu, setCurrentActiveMenu] = React.useState("home");
    const [hoveredMenu, setHoveredMenu] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    React.useEffect(() => {
        if (window.location.pathname.toLowerCase() == "/dashboard/viewpersona" || window.location.pathname.toLowerCase() == "/dashboard/createpersona" || window.location.pathname.toLowerCase() == "/dashboard/updatepersona") {
            setCurrentActiveMenu("/dashboard/persona")
        } else if (window.location.pathname.toLowerCase() == "/dashboard/createchatbot" || window.location.pathname.toLowerCase() == "/dashboard/chatbot/viewchatbot") {
            setCurrentActiveMenu("/dashboard/chatbot")
        } else if (window.location.pathname.toLowerCase() == "/dashboard/campaigns/create") {
            setCurrentActiveMenu("/dashboard/campaigns")
        }
        else if (window.location.pathname.toLowerCase() == "/dashboard/customtemplate") {
            setCurrentActiveMenu("/dashboard/templates")
        }
        else if (window.location.pathname.toLowerCase() == "/dashboard/notes" || window.location.pathname.toLowerCase() == "/dashboard/notes/create") {
            setCurrentActiveMenu("/dashboard/notes")
        }
        else if (window.location.pathname.toLowerCase() == "/dashboard/settings-plansandbilling") {
            setCurrentActiveMenu("/dashboard/settings-accountDetails")
        }
        else if (window.location.pathname.toLowerCase() == "/dashboard/settings-accountdetails") {
            setCurrentActiveMenu("/dashboard/settings-accountDetails")
        }
        else {
            setCurrentActiveMenu(window.location.pathname)
        }
    }, [window.location.pathname])



    const drawer = (
        <>
            <Grid container direction="column" justifyContent={"space-between"} className={userDetail.flagUser && !userDetail.flag_user_anonymous ? `${styles.navigationPanelContentWrapper}` : `${styles.navigationPanelContentWrapperAnonymous}`}>
                <Grid item>
                    <Toolbar>
                        <img alt="logo" src={writewizLogo} onClick={() => { navigate('/dashboard') }} className={styles.writewizLogoStyle} />
                    </Toolbar>
                    <List sx={{ padding: '10px' }} >
                        {props.menuItems.map((menuItem, index) => {
                            return <ListItem
                                sx={{ opacity: menuItem.flagDisabled ? "0.5" : "1" }}
                                className={(currentActiveMenu.toLowerCase() == menuItem.navigationLink.toLowerCase()) ? styles.selectedListItemStyle : styles.listItemStyle}
                                onMouseEnter={() => { setHoveredMenu(menuItem.menuKey) }} onMouseLeave={() => { setHoveredMenu("") }}
                                onClick={() => {
                                    if (menuItem.flagDisabled) {
                                        setShowLoginPopup(true)
                                    } else {
                                        if (menuItem.menuName == "AI Chatbot") {
                                            navigate('/dashboard/chatbot')
                                            setCurrentActiveMenu(menuItem.navigationLink);
                                            // window.open(process.env.REACT_APP_CHATBOT_LINK)
                                            // props.onClose()
                                        } else {
                                            setCurrentActiveMenu(menuItem.navigationLink); navigate(menuItem.navigationLink)
                                            props.onClose()
                                        }
                                    }
                                }
                                }
                                key={menuItem.menuKey} disablePadding>
                                <ListItemIcon sx={{ transform: "scale(1.2)" }} className={styles.imageNavigationMenu}>
                                    {currentActiveMenu.toLowerCase() == menuItem.navigationLink.toLowerCase() ? menuItem.whiteImgSrc : hoveredMenu.toLowerCase() == menuItem.menuKey.toLowerCase() ? menuItem.coloredImgSrc : menuItem.imgSrc}
                                </ListItemIcon>
                                <ListItemText primary={menuItem.menuName} />
                                {/* {menuItem.flagDisabled &&
                                    <ListItemIcon><img src={premiumIcon} /></ListItemIcon>} */}
                            </ListItem>
                        })}
                    </List>
                </Grid>
                <Grid item>
                    <Grid container direction={"column"}>
                        {!userDetail.flag_user_anonymous &&
                            userDetail.flagUser &&
                            <Grid item className={`${styles.creditUsedComponentWrapper} ${globalStyles.fullwidth}`}>
                                <CreditUsedComponent />
                            </Grid>
                        }
                        {flagChromeDesktop &&
                            <Grid item className={styles.downloadExtensionWrapper}>
                                <GlobalButton onClick={() => { window.open("https://chrome.google.com/webstore/detail/writewiz-ai-content-creat/nikfnkpdalalkollmjgjkgmfblfmlpbd/related?hl=en", '_blank'); }} flagDownloadExtension flagFullWidth buttonText='Download Extension' className='borderedButtonStyle' />
                            </Grid>}
                        <Grid item className={styles.profileWrapper}>
                            <SidepanelAccountDetails />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
    // const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <Box sx={{ display: 'flex' }}>
            <Box
                component="nav"
                sx={{ width: { sm: open ? drawerWidth : mobileDrawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
                className={styles.sideBarIcon}
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    className={styles.sideNavigationPanelStyle}
                    // container={container}
                    variant="temporary"
                    open={props.open}
                    onClose={props.onClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <MiniDrawer
                    menuItems={props.menuItems}
                    open={props.openDesktopDrawer}
                    handleDrawerClose={props.handleDrawerClose}
                    handleDrawerOpen={props.handleDrawerOpen}
                />
                {/* <Drawer
                    className={styles.sideNavigationPanelStyle}
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer> */}
            </Box>
        </Box>
    );
}