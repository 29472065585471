import * as React from "react";

import { Button, Grid, Radio, Typography } from "@mui/material";
import styles from "../../assets/styles/componentStyles/outputOptionCard.module.scss";
import globalStyles from "../../assets/styles/global.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import Tooltip from "@mui/material/Tooltip";
import copyIcon from "../../assets/copy.svg";
import { DocContext, DocContextPayload } from "../../context/docContext";
import { ExecutePromptResponseModel, promptSuggestionListObj } from "../../models/axiosModel";
import deleteIcon from "../../assets/deleteIcon.svg";
import ReactShadowRoot from "react-shadow-root";
import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import IconsColor from "../utility/iconsColor";

export default function OutputOptionCard() {

    const { executePromptResponse, setDocumentEditorData, documentEditorData, setExecutePromptResponse, caretPositionCount } = React.useContext(DocContext) as DocContextPayload;

    const [copyText, setCopyText] = React.useState("Copy")

    const formatOptionContent = (option: any) => {
        const formattedContent = [];

        if (executePromptResponse !== null && executePromptResponse.flag_show_field) {
            for (const key in option) {
                if (option.hasOwnProperty(key)) {
                    formattedContent.push(key.split('_') // Split the string by underscores
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
                        .join(' ') + "\n" + option[key]);
                }
            }
        } else {
            formattedContent.push(...Object.values(option));
        }
        // navigator.clipboard.writeText(formattedContent.join("\n"))
        return formattedContent.join("\n");
    };

    const handleDeleteOption = (option: any, index: number) => {
        // console.log("option", option)
        // let tempOptions = outputOptions;
        // const updatedOptions = tempOptions.filter((existingOption) => existingOption.id !== option.id)
        // console.log("tempoptions", updatedOptions)
        // setOutputOptions(updatedOptions)
        let tempPromptRes = { ...executePromptResponse } as ExecutePromptResponseModel;
        let updatedData: any = tempPromptRes['data']?.filter((obj, i) => i != index)
        tempPromptRes['data'] = updatedData
        setExecutePromptResponse(tempPromptRes)

    }

    function contentLexicalObjFun(contentValue: string, indent: number = 0) {
        let updatedValue = contentValue.replace("#", " ")
        return {
            "children": [
                {
                    "detail": 0,
                    "format": 0,
                    "mode": "normal",
                    "style": "",
                    "text": updatedValue,
                    "type": "text",
                    "version": 1
                }
            ],
            "direction": "ltr",
            "format": "",
            "indent": 0,
            "type": "paragraph",
            "version": 1
        }
    }

    const getDataFromShadowRoot = (index: number) => {
        const shadowById = document.getElementById(`AIOutputShadow${index}`)
        let shadowData = shadowById?.shadowRoot
        // @ts-ignore
        let aiOutputHtml: HTMLElement = shadowData != undefined && shadowData?.querySelector('.htmlContentDiv')
        return aiOutputHtml.innerText
    }

    const AddAIOutputToEditor = (data: any, index: number) => {
        if (IsContentTypeHTML(Object.keys(data)[0])) {
            data[Object.keys(data)[0]] = getDataFromShadowRoot(index)
        }
        let newChild: any = [];
        Object.keys(data)?.map((key) => {
            if (executePromptResponse?.flag_show_field) {
                let modifiedText = key?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                newChild.push(contentLexicalObjFun(modifiedText))
                newChild.push(contentLexicalObjFun(data[key]))
            } else {
                // newChild.push(contentLexicalObjFun(key))
                newChild.push(contentLexicalObjFun(data[key]))
            }
        })
        if (documentEditorData) {
            let tempEditorData = { ...documentEditorData }
            tempEditorData['root']['children']?.push(...newChild)
            setDocumentEditorData(tempEditorData)
        } else {
            let lexicalDataArray: any = {
                root: {
                    "children": [],
                    "direction": "ltr",
                    "format": "",
                    "indent": 0,
                    "type": "root",
                    "version": 1
                }
            }
            lexicalDataArray['root']['children']?.push(...newChild)
            setDocumentEditorData(lexicalDataArray)
        }
    }

    const getDataWithKey = (option: promptSuggestionListObj) => {
        let finalString = ""
        Object.keys(option)?.map((key) => {
            finalString = " " + finalString + "" + key + ":" + option[key] + " "
        })
        // debugger;
        return finalString
    }

    const getDataWithoutKey = (option: promptSuggestionListObj) => {
        let finalString = ""
        Object.keys(option)?.map((key) => {
            finalString = " " + finalString + option[key] + " "
        })
        return finalString
    }

    const AddToEditorFunction = (data: any, newData: promptSuggestionListObj) => {
        if (caretPositionCount || caretPositionCount == 0) {
            let currentPos = 0
            for (const item of data) {
                if (item['text'] != undefined) {
                    if (item['text']?.length) {
                        if (currentPos + item['text']?.length >= caretPositionCount) {
                            let difference = caretPositionCount - currentPos
                            let previousText = item['text']?.substring(0, difference)
                            let afterText = item['text']?.substring(difference, item['text']?.length)
                            let concatedData = executePromptResponse?.flag_show_field == false ? getDataWithoutKey(newData) : getDataWithKey(newData)
                            let updatedText = previousText + concatedData + afterText
                            item['text'] = updatedText
                        } else {
                            currentPos += item['text']?.length
                        }
                    } else {
                        if (caretPositionCount == 0) {
                            if (executePromptResponse?.flag_show_field == true) {
                                let newChildren = contentLexicalObjFun(getDataWithKey(newData))
                                item['text'] = newChildren
                            } else {
                                let newChildren = contentLexicalObjFun(getDataWithoutKey(newData))
                                item['text'] = newChildren
                            }
                        } else {

                        }
                    }
                }
                if (item?.children?.length) {
                    AddToEditorFunction(item.children, newData);
                }
            }
        }
        return data
    }

    const handleCopyToClipboard = (textToCopy: any) => {
        try {
            navigator.clipboard.writeText(textToCopy);
            setCopyText("Copied!"); // Update the button text
        } catch (error) {
            console.error("Clipboard write error:", error);
            setCopyText("Copy Failed"); // Handle copy failure
        }
    };

    function makeLexicalObj(contentValue: any) {
        return {
            "root": {
                "children": contentValue,
                "direction": "ltr",
                "format": "",
                "indent": 0,
                "type": "root",
                "version": 1
            }
        }
    }

    const IsContentTypeHTML = (key: string) => {
        let tempArray: string[] = key.split("_")
        if (tempArray.indexOf("html") > -1) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            {executePromptResponse !== null && executePromptResponse.data.map((option: promptSuggestionListObj, index: number) => {
                let textToCopy = "";
                if (IsContentTypeHTML(Object.keys(option)[0])) {
                    textToCopy = getDataFromShadowRoot(index)
                } else {
                    textToCopy = formatOptionContent(option);
                }
                return (
                    <>
                        <Grid container direction={"column"} className={styles.optionCard}>
                            {/* title */}
                            <Grid item className={`${globalStyles.fullwidth} ${styles.upperBlock}`}>
                                <Grid container justifyContent={"space-between"}>
                                    <Grid item className={styles.optionTextStyle}>{`Option ${index + 1} `}</Grid>
                                    <Grid item className={globalStyles.displayFlex}>
                                        <Grid container direction={"row"}>
                                            <Grid
                                                item
                                                className={`${styles.addToEditorTextStyle} ${globalStyles.clickable}`}
                                                onClick={() => {
                                                    AddAIOutputToEditor(option, index)
                                                }}
                                            >
                                                Add to Editor
                                            </Grid>
                                            <CopyToClipboard text={textToCopy}>
                                                <Tooltip title={copyText} onMouseLeave={() => setCopyText("Copy!")} onClick={() => {
                                                    setCopyText("Copied!");
                                                }}>
                                                    <div
                                                        className={`${styles.copyTextStyle} ${globalStyles.clickable}`}
                                                        onClick={() => handleCopyToClipboard(textToCopy)}
                                                        onMouseLeave={() => setCopyText("Copy!")}
                                                    >
                                                        <IconsColor iconName={"Copy"} fillColor={"#7a7a7a"} width={"16"} height={"16"} />
                                                        {/* <img src={copyIcon} /> */}
                                                    </div>
                                                </Tooltip>
                                            </CopyToClipboard>
                                            <Grid
                                                item
                                                className={`${styles.deleteTextStyle} ${globalStyles.clickable}`}
                                                onClick={() => { handleDeleteOption(option, index) }}
                                            >
                                                <IconsColor iconName={"Delete"} fillColor={"#7a7a7a"} width={"16"} height={"16"} />
                                                {/* <img src={deleteIcon} /> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item className={globalStyles.fullwidth}>
                                <Grid container direction="column">
                                    {executePromptResponse.flag_show_field ? (
                                        Object.keys(option).map((key) => (
                                            <div id={`AIOutputShadow${index}`} key={key}>
                                                {IsContentTypeHTML(key)
                                                    ?
                                                    <Typography sx={{ fontWeight: "600", fontSize: "17px" }}>
                                                        {key.split('_').splice(-1)
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                            .join(' ')}
                                                    </Typography>
                                                    :
                                                    <Typography sx={{ fontWeight: "600", fontSize: "17px" }}>
                                                        {
                                                            key.split('_')
                                                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                                .join(' ')
                                                        }
                                                    </Typography>
                                                }
                                                {IsContentTypeHTML(key)
                                                    ?
                                                    <ReactShadowRoot>
                                                        <div className="htmlContentDiv" dangerouslySetInnerHTML={{ __html: (option as Record<string, string>)[key] }} />
                                                    </ReactShadowRoot>
                                                    :
                                                    <Typography>
                                                        {(option as Record<string, string>)[key]}
                                                    </Typography>
                                                }
                                            </div>
                                        ))
                                    ) : (

                                        <div id={`AIOutputShadow${index}`}>
                                            {Object.values(option).map((val, key) => {
                                                if (IsContentTypeHTML(Object.keys(option)[key])) {
                                                    return (
                                                        <ReactShadowRoot>
                                                            <div className="htmlContentDiv" dangerouslySetInnerHTML={{ __html: val }} />
                                                        </ReactShadowRoot>
                                                    )
                                                } else {
                                                    return (<Typography key={key}>{val}</Typography>)
                                                }

                                            })}
                                        </div>
                                    )}
                                </Grid>
                            </Grid>

                        </Grid >
                    </>
                )
            })
            }
        </>
    );
}
