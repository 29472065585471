import { Button, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import globalStyles from "../../../assets/styles/global.module.scss";
import styles from "../../../assets/styles/componentStyles/chatbot.module.scss";
import chatbotPreview from '../../../assets/Chatbot Preview.png'

const ChatbotLandingPage = () => {

    const isMobileScreen = useMediaQuery('(max-width : 600px) and (min-width : 0px)')

    return (
        <>
            <Grid container className={isMobileScreen ? '' : `${styles.dashboardChildWrapper}`}>
                <Grid item xs={12} className={styles.dashboardChildWrapper}>
                    <Grid container>
                        <Grid item xs={12} className={styles.cbLandingPageItem}>
                            <Typography component={'span'}
                                sx={{ fontSize: '30px', fontWeight: '500', color: '#6A097D' }}
                            >
                                Unleash the Power of AI with Chronox AI Chatbot
                            </Typography>
                        </Grid>

                        <Grid item xl={3} lg={7} md={7} sm={10} xs={12} className={styles.cbLandingPageItem2}>
                            <Typography component={'span'}
                                sx={{ fontSize: '18px', fontWeight: '500', color: '#000' }}
                            >
                                Enter Chronox AI Chatbot, your gateway to unleashing the unparalleled power of AI
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ textAlign: isMobileScreen ? 'start' : 'center' }}>
                            <Button
                                variant='outlined'
                                size='large'
                                className={styles.getStartedBtn}
                                onClick={() => {
                                    window.open(process.env.REACT_APP_CHATBOT_LINK, '_self')
                                }}
                            >
                                Get Started
                            </Button>
                        </Grid>

                        <Grid item xs={12} sx={{ margin: '20px auto', textAlign: 'center' }}>
                            <img src={chatbotPreview} style={{ width: isMobileScreen ? '100%' : '350px', height: 'auto' }} alt="" />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default ChatbotLandingPage