import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import styles from "../../assets/styles/componentStyles/tab.module.scss";
import FormPreview from './generateTitleGlobalFormPreview';
import BasicAccordion from './accordionComponent';
import OutputOptionCard from './outputOptionCard';
import globalStyles from "../../assets/styles/global.module.scss";
import { DocContext, DocContextPayload } from '../../context/docContext';
import GlobalButton from '../utility/globalButton/button';
import { UserContext, UserContextPayload } from '../../context/userContext';
import { useNavigate } from 'react-router-dom';
import { TemplateContext, TemplateContextPayload } from '../../context/templateContext';
import { outputFormatObj } from '../../models/axiosModel';
import { emptyUserDetail, flagCookieExpired, flagCreditsExhausted, getErrorMessage, isDev } from '../../utils/constants';
import { checkForErrorCode } from '../../utils/constants';
interface DynamicTabProps {
    tabs: { label: string; content: React.ReactNode, tabVisibility: boolean, flagInitiallyActive: boolean, flagDisabled?: boolean }[];
    defaultTab?: number;
    tabClassName?: string;
    tabPanelClassName?: string;
    conditionalStyling?: string
    className: string;
    tabHeaderClassname: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ height: "100%" }}
            // style={value == 0 ? { height: "100%" } : {}}
            {...other}
        >
            {value === index && (
                // <Box sx={{ paddingTop: "23px" }}>
                <Grid item className={value == 0 ? styles.tabFormWrapper : styles.tabAIOutputWrapper}>{children}</Grid>
                // </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


export default function TabComponent({

    tabs,
    defaultTab = 0,
    tabClassName = '',
    tabPanelClassName = '',
    tabHeaderClassname = '',
    conditionalStyling,
    className,
}: DynamicTabProps) {
    const { setFlagLoading, setFlagShowCreditsExhausted, setError, error, userDetail, flagLoading, setUserDetail } = React.useContext(UserContext) as UserContextPayload;
    const { currentActiveDocDetails,
        flagShowAIOutput,
        executePromptResponse,
        setExecutePromptResponse,
        ExecutePrompt,
        currentActiveDocFieldVal,
        tabValuePanel,
        setTabValuePanel
    } = React.useContext(DocContext) as DocContextPayload;
    const navigate = useNavigate()
    // const [value, setValue] = React.useState(defaultTab);

    useEffect(() => {
        if (window.location.pathname == "/dashboard/settings-accountDetails" || window.location.pathname == "/dashboard/settings-plansAndBilling") {
            let tempPath = window.location.pathname
            let tempPathArray = tempPath.split("/")
            if (tempPathArray[tempPathArray.length - 1] == "settings-accountDetails") {
                setTabValuePanel(0)
            } else if (tempPathArray[tempPathArray.length - 1] == "settings-plansAndBilling") {
                setTabValuePanel(1)
            }
        }
    }, [])

    const { routeSources, outputFormatGenericList } = React.useContext(TemplateContext) as TemplateContextPayload;
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {

        if (window.location.pathname == "/dashboard/settings-accountDetails" || window.location.pathname == "/dashboard/settings-plansAndBilling") {
            if (newValue == 0) {
                navigate("/dashboard/settings-accountDetails")
                setTabValuePanel(0)
            } else if (newValue == 1) {
                navigate("/dashboard/settings-plansAndBilling")
                setTabValuePanel(1)
            }
        } else {
            setTabValuePanel(newValue);
        }
    };

    const executePrompt = async () => {
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        if (currentActiveDocDetails) {
            const payload = {
                document_uuid: currentActiveDocDetails?.document_uuid,
                prompt_id: currentActiveDocDetails.prompt_id ? currentActiveDocDetails.prompt_id : 0,
                previous_response: executePromptResponse?.data,
                route_source_id: routeSources.find((route) => route.route_source_name == "Web App")?.route_source_id ?? 2,
                output_format_id: outputFormatGenericList.find((outputFormat: outputFormatObj) => outputFormat.output_format_name == "JSON")?.output_format_id ?? 1,
            }
            const response = await ExecutePrompt(payload);
            if (response.status) {
                // @ts-ignore
                setExecutePromptResponse({ ...executePromptResponse, data: [...response.data, ...executePromptResponse?.data,] })
                setTabValuePanel(1)
            } else {
                let errorCode = checkForErrorCode(response)
                if (flagCookieExpired(userDetail, errorCode)) {
                    setUserDetail(emptyUserDetail)
                    if (userDetail.flag_user_anonymous) {
                        let url = window.location.pathname
                        navigate(url, { replace: true });
                    } else {
                        setError({
                            ...error,
                            flagShowError: true,
                            message: "Session expired! Please login to continue."
                        });
                        setTimeout(() => {
                            let url = encodeURIComponent(window.location.href)
                            navigate("/login?redirect=" + url, { replace: true });
                        }, 1500);
                    }
                } else {

                    if (flagCreditsExhausted(errorCode)) {
                        setFlagShowCreditsExhausted(true)
                    } else {
                        if (flagCreditsExhausted(errorCode)) {
                            setFlagShowCreditsExhausted(true)
                        } else {
                            setError({
                                ...error,
                                flagShowError: true,
                                message: getErrorMessage(errorCode, isDev(userDetail))
                            });
                        }
                    }
                }

            }
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
    }
    const getGenerateMoreDisableCondition = () => {
        if (currentActiveDocFieldVal) {
            for (const fieldData of currentActiveDocFieldVal) {
                const isRequired = fieldData.required;
                const submissionValue = fieldData.value;

                if (isRequired === true && (submissionValue === null || submissionValue === "" || submissionValue.length < 0)) {
                    return true
                } else {
                    return false
                }
            }
        } else {
            return false
        }
    }

    // React.useEffect(() => {
    //     // Find the index of the first tab with initialActiveTab = true
    //     const initialActiveTabIndex = tabs.findIndex(tab => tab.flagInitiallyActive === true);

    //     // If initialActiveTabIndex is valid and that tab is visible, set it as the active tab
    //     if (initialActiveTabIndex !== -1 && tabs[initialActiveTabIndex].tabVisibility) {
    //         setTabValuePanel(initialActiveTabIndex);
    //     }
    // }, [tabs]);

    // useEffect(() => {
    //     let tempPath = window.location.pathname
    //     let tempPathArray = tempPath.split("/")
    //     if (tempPathArray[tempPathArray.length - 1] == "settings-accountDetails") {
    //         setTabValuePanel(0)
    //     } else if (tempPathArray[tempPathArray.length - 1] == "settings-plansAndBilling") {
    //         setTabValuePanel(1)
    //     }
    // }, [])



    return (
        // <Box className={globalStyles.fullwidth} sx={{ height: "100%" }}>
        <>
            <Tabs
                value={tabValuePanel}
                onChange={handleChange}
                aria-label="dynamic tabs example"
                className={styles[tabHeaderClassname]}
                sx={conditionalStyling ? JSON.parse(conditionalStyling) : {}}
            >
                {tabs.map((tab, index) => (
                    tab.tabVisibility &&
                    <Tab
                        key={index}
                        label={tab.label}
                        disabled={tab.flagDisabled}
                        {...a11yProps(index)}
                    />
                ))}
                {currentActiveDocDetails?.template_type_name == "Single-Page" && flagShowAIOutput &&
                    // value == 1 &&
                    <div
                        style={{ position: "absolute", right: "0" }}
                    >
                        {/* @ts-ignore */}
                        <GlobalButton
                            className='secondaryButtonStyle'
                            buttonText='Generate more'
                            flagGenerate
                            // flagMoveForward
                            disabled={getGenerateMoreDisableCondition()}
                            onClick={executePrompt}
                        ></GlobalButton>
                    </div>}
            </Tabs>

            <Grid item
                className={styles[className]}
            >
                {tabs.map((tab, index) => (
                    tab.tabVisibility &&
                    <TabPanel
                        key={index}
                        value={tabValuePanel}
                        index={index}
                    >
                        {tab.content}
                    </TabPanel>
                ))}
            </Grid>
        </>
        // </Box>
    );
}
