import React from 'react'
import { reorder } from "../drag-and-drop/helper.js";
import '../../../assets/styles/componentStyles/outlineDndComponent.css'
import reload from "../../../assets/reload.png"
import deleteIcon from "../../../assets/delete.png"
import globalStyles from "../../../assets/styles/global.module.scss";
import text from "../../../assets/text.png";
import { DocContext } from '../../../context/docContext';
import { Skeleton, TextField, Typography } from '@mui/material';
import { Textarea } from '@mui/joy';
import IconsColor from '../../utility/iconsColor.tsx';

export const DraggableItem = (props) => {
    const { contentType, subContentType, contentData } = props
    const { documentOutlineData, setDocumentOutlineData, DeleteDocumentOutlineData, currentActiveDocDetails, flagOutlineLoader } = React.useContext(DocContext);
    const [isHovered, setIsHovered] = React.useState(false);
    function ChangeContentOfOutline(tempOutlineData, newValue) {
        for (const item of tempOutlineData) {
            if (item.unique_id == contentData.unique_id) {
                if (contentData.content_type_id == 3) {
                    item.document_outline_summary = newValue
                } else {
                    item.document_outline_value = newValue
                }
            }

            if (item.children && item.children.length > 0) {
                ChangeContentOfOutline(item.children, newValue);
            }
        }
        return tempOutlineData
    }

    const UpdateOutlineState = (newValue) => {
        let tempOutlineData = [...documentOutlineData]
        let updatedOulinedData = ChangeContentOfOutline(tempOutlineData, newValue)
        setDocumentOutlineData(updatedOulinedData)
    }

    const RemoveOutlineRecData = (data, id) => {
        // for (const item of data) 
        for (let i = 0; i < data.length; i++) {

            if (data[i]?.document_outline_id == id || data[i].unique_id == id) {
                // debugger;
                //   return true
                // delete current obj
                data.splice(i, 1);
                i--;
            }

            if (data[i]?.children && data[i]?.children?.length > 0) {
                RemoveOutlineRecData(data[i].children, id);
                // let tttt = RemoveOutlineRecData(data[i].children);
                // if (!tttt)
                //     tttt = []
                // data[i].children = tttt;

            }
        }
        return data
    }

    const RemoveOutlineData = (id) => {
        let tempOtlineData = [...documentOutlineData]
        // debugger;
        tempOtlineData = RemoveOutlineRecData(tempOtlineData, id)
        setDocumentOutlineData(tempOtlineData)
    }
    const DeleteOutline = (contentData) => {
        if (!contentData?.document_outline_id) {
            RemoveOutlineData(contentData.unique_id)
        } else {
            (async () => {
                let params = {
                    document_uuid: currentActiveDocDetails.document_uuid,
                    document_step_id: currentActiveDocDetails.document_step_data[1].document_step_id,
                    document_outline_id: contentData.document_outline_id,

                }
                let response = await DeleteDocumentOutlineData(params)
                if (response.status) {
                    RemoveOutlineData(contentData.document_outline_id)
                }
            })()
        }
    }

    return (
        <>{contentType == "heading" || contentType == "sub heading" ?
            <div className="heading_box">
                <div className="item_category category_name dnd_block_container">
                    <div className="editable_area">
                        {documentOutlineData?.length && !flagOutlineLoader ?
                            <TextField
                                placeholder='e.g, How to improve your clickthrough rates'
                                className="editableNodeText"
                                fullWidth id="fullWidth"
                                onChange={(e) => { UpdateOutlineState(e.target.value) }}
                                value={contentData.document_outline_value}
                                multiline
                                maxRows={3}
                            />
                            :
                            <Skeleton variant='rectangular'/>
                        }
                    </div>
                    {documentOutlineData?.length && !flagOutlineLoader ?
                    <>
                    <div className="sectionHeadingVisibility">
                        <div className="section_type">
                            {contentType == "heading" ? "Section Heading" : "Sub-Heading"}
                        </div>
                        <div className='reloadDeleteMobile'>
                            {/* <span className={globalStyles.clickable}>
                                <img src={reload} className="icon_size" />
                            </span> */}
                            <span className={`${globalStyles.clickable} hoverDeleteIcon`} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} onClick={() => { DeleteOutline(contentData) }}>
                                {isHovered ? <IconsColor iconName="DeleteIcon" fillColor="#6A097D" width='14px' height='14px'/> : <IconsColor iconName="DeleteIcon" fillColor="#7A7A7A" width='14px' height='14px'/>}
                            </span>
                        </div>
                    </div>
                    </>
                        : ""
                    }
                </div>
                {documentOutlineData?.length && !flagOutlineLoader ?
                    <>
                        {/* <span className={globalStyles.clickable}>
                            <img src={reload} className="icon_size" />
                        </span> */}
                        <span className={`${globalStyles.clickable} deleteDesktopIcon hoverDeleteIcon`} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} onClick={() => { DeleteOutline(contentData) }}>
                            {isHovered ? <IconsColor iconName="DeleteIcon" fillColor="#6A097D" width='18px' height='18px'/> : <IconsColor iconName="DeleteIcon" fillColor="#7A7A7A" width='18px' height='18px'/>}
                        </span>
                    </>
                    :
                    ""
                }
            </div>
            :
            <div className="brief_box">
                <div className="item_category category_name dnd_block_container">
                    <div className="editable_area">
                        {documentOutlineData?.length && !flagOutlineLoader
                            ?
                            <TextField
                                placeholder='e.g, How to improve your clickthrough rates'
                                className="editableNodeTextSummary"
                                fullWidth id="fullWidth"
                                onChange={(e) => { UpdateOutlineState(e.target.value) }}
                                value={contentData.document_outline_summary}
                                multiline
                                maxRows={4}
                            // multiline
                            />
                            :
                            <Skeleton variant='rectangular'/>
                        }
                    </div>
                    {documentOutlineData?.length && !flagOutlineLoader ?
                    <>
                        <div className="section_type">
                            <div className="contentBriefDeleteSection">
                                <div className="contentBriefSection">
                                    <div>Content Brief</div>
                                    <div className="contentBriefDesktopIcon">
                                            <IconsColor iconName="TextIcon" width='18px' height='18px'/>
                                        {/* <img src={text} className="contentBriefIcon"/> */}
                                    </div>
                                    <div className="contentBriefIcon">
                                            <IconsColor iconName="TextIcon" width='14px' height='14px'/>
                                        {/* <img src={text} className="contentBriefIcon"/> */}
                                    </div>
                                </div>
                                <div className='reloadDeleteMobile'>
                                    {/* <span className={globalStyles.clickable}>
                                        <img src={reload} className="icon_size" />
                                    </span> */}
                                    <span className={`${globalStyles.clickable} hoverDeleteIcon`} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} onClick={() => { DeleteOutline(contentData) }}>
                                        {isHovered ? <IconsColor iconName="DeleteIcon" fillColor="#6A097D" width='14px' height='14px'/> : <IconsColor iconName="DeleteIcon" fillColor="#7A7A7A" width='14px' height='14px'/>}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                        : ""}
                </div>
                {documentOutlineData?.length && !flagOutlineLoader ?
                    <>
                        {/* <span className={globalStyles.clickable}>
                            <img src={reload} className="icon_size" />
                        </span> */}
                        <span className={`${globalStyles.clickable} deleteDesktopIcon hoverDeleteIcon`} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} onClick={() => { DeleteOutline(contentData) }}>
                            {isHovered ? <IconsColor iconName="DeleteIcon" fillColor="#6A097D" width='18px' height='18px'/> : <IconsColor iconName="DeleteIcon" fillColor="#7A7A7A" width='18px' height='18px'/>}
                        </span>
                    </>
                    : ""
                }
            </div>
        }</>
    )
}
