import { AutoFocusPlugin } from '../Editor/plugins/AutoFocusPlugin/LexicalAutoFocusPlugin';
import { CharacterLimitPlugin } from '@lexical/react/LexicalCharacterLimitPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
// import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import { CollaborationPlugin } from '@lexical/react/LexicalCollaborationPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HashtagPlugin } from '@lexical/react/LexicalHashtagPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import useLexicalEditable from '@lexical/react/useLexicalEditable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CAN_USE_DOM } from './shared/src/canUseDOM';

import { createWebsocketProvider } from './collaboration';
import { useSettings } from './context/SettingsContext';
import { useSharedHistoryContext } from './context/SharedHistoryContext';
import TableCellNodes from './nodes/TableCellNodes';
import ActionsPlugin from './plugins/ActionsPlugin';
import AutocompletePlugin from './plugins/AutocompletePlugin';
import AutoEmbedPlugin from './plugins/AutoEmbedPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CodeActionMenuPlugin from './plugins/CodeActionMenuPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import CollapsiblePlugin from './plugins/CollapsiblePlugin';
import CommentPlugin from './plugins/CommentPlugin';
import ComponentPickerPlugin from './plugins/ComponentPickerPlugin';
// import ContextMenuPlugin from './plugins/ContextMenuPlugin';
import DragDropPaste from './plugins/DragDropPastePlugin';
import DraggableBlockPlugin from './plugins/DraggableBlockPlugin';
// import EmojiPickerPlugin from './plugins/EmojiPickerPlugin';
import EmojisPlugin from './plugins/EmojisPlugin';
import EquationsPlugin from './plugins/EquationsPlugin';
import ExcalidrawPlugin from './plugins/ExcalidrawPlugin';
import FigmaPlugin from './plugins/FigmaPlugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import FloatingTextFormatToolbarPlugin from './plugins/FloatingTextFormatToolbarPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import InlineImagePlugin from './plugins/InlineImagePlugin';
import KeywordsPlugin from './plugins/KeywordsPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import MarkdownShortcutPlugin from './plugins/MarkdownShortcutPlugin';
import { MaxLengthPlugin } from './plugins/MaxLengthPlugin';
// import MentionsPlugin from './plugins/MentionsPlugin';
import PollPlugin from './plugins/PollPlugin';
import SpeechToTextPlugin from './plugins/SpeechToTextPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
// import TableCellActionMenuPlugin from './plugins/TableActionMenuPlugin';
import TableCellResizer from './plugins/TableCellResizer';
// import TableOfContentsPlugin from './plugins/TableOfContentsPlugin';
import { TablePlugin as NewTablePlugin } from './plugins/TablePlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
// import TreeViewPlugin from './plugins/TreeViewPlugin';
import TwitterPlugin from './plugins/TwitterPlugin';
import YouTubePlugin from './plugins/YouTubePlugin';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import ContentEditable from './ui/ContentEditable';
import Placeholder from './ui/Placeholder';
import { RestoreFromLocalStoragePlugin } from './InitialState';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $createParagraphNode, $createTextNode, $getRoot, $getSelection, EditorState, $insertNodes } from 'lexical';
import { isEqual } from 'lodash';
import { MyCustomOnChangePlugin } from './plugins/MyCustomOnChangePlugin';
import { stringify } from 'querystring';
import { useDebounce, useDebouncedCallback } from 'use-debounce';
import { DocContext, DocContextPayload } from '../../context/docContext';
import { documentStepDataModel, UpdateDocumentEditorPayloadModel } from '../../models/axiosModel';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';

import { RewritePopup } from './customComponent/rewritePopup';
const skipCollaborationInit =
    // @ts-ignore
    window.parent != null && window.parent.frames.right === window;

export default function Editor(): JSX.Element {
    const { historyState } = useSharedHistoryContext();
    const [allowOnchange, setAllowOnchange] = useState(false)
    const { currentActiveDocDetails, documentEditorData, flagRewritePopup, setFlagRewritePopup, editorHtmlData, flagAutoFocus } = React.useContext(DocContext) as DocContextPayload;
    const {
        settings: {
            isCollab,
            isAutocomplete,
            isMaxLength,
            isCharLimit,
            isCharLimitUtf8,
            isRichText,
            showTreeView,
            showTableOfContents,
            shouldUseLexicalContextMenu,
            tableCellMerge,
            tableCellBackgroundColor,
        },
    } = useSettings();

    const [previousEditorObj, setPreviousEditorObj] = useState<any>()
    const [prevPosition, setPrevPosition] = useState<any>()

    const isEditable = useLexicalEditable();
    const text = isCollab
        ? 'Enter some collaborative rich text...'
        : isRichText
            ? 'Write or paste something here'
            : 'Enter some plain text...';
    const placeholder = <Placeholder>{text}</Placeholder>;
    const [floatingAnchorElem, setFloatingAnchorElem] =
        useState<HTMLDivElement | null>(null);
    const [isSmallWidthViewport, setIsSmallWidthViewport] =
        useState<boolean>(false);

    const onRef = (_floatingAnchorElem: HTMLDivElement) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };



    const editorRef = React.useRef<HTMLInputElement | null>(null)

    const [editor] = useLexicalComposerContext()
    const [editorDebounceData] = useDebounce(editor, 1000);

    const getEditorJson = () => {
        return editor.getEditorState().toJSON()
        // return JSON.parse(JSON.stringify(editor))?.editorState
    }

    // const debounced = useDebouncedCallback(
    //     async () => {
    //         let finalObj: any = {}
    //         finalObj['document_uuid'] = currentActiveDocDetails?.document_uuid
    //         finalObj['document_step_id'] = currentActiveDocDetails?.document_step_data[1].document_step_id
    //         finalObj['document_lexical_editor_data'] = []
    //         let nn = getEditorJson()
    //         debugger;
    //     }, 2000);

    const cellEditorConfig = {
        namespace: 'Playground',
        nodes: [...TableCellNodes],
        onError: (error: Error) => {
            throw error;
        },
        theme: PlaygroundEditorTheme,
    };

    useEffect(() => {
        const updateViewPortWidth = () => {
            const isNextSmallWidthViewport =
                CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

            if (isNextSmallWidthViewport !== isSmallWidthViewport) {
                setIsSmallWidthViewport(isNextSmallWidthViewport);
            }
        };
        updateViewPortWidth();
        window.addEventListener('resize', updateViewPortWidth);

        return () => {
            window.removeEventListener('resize', updateViewPortWidth);
        };
    }, [isSmallWidthViewport]);

    // useEffect(() => {
    //     if (flagRewritePopup) {
    //         setFloatingAnchorElem(null);
    //     }
    // }, [flagRewritePopup])

    // useEffect(() => {
    //     if (currentActiveDocDetails && currentActiveDocDetails?.template_type_name == "Single-Page") {
    //         if (documentEditorData) {
    //             // debugger;
    //             editor.setEditable(true);
    //         } else {
    //             // debugger;
    //             editor.setEditable(false);
    //         }
    //     }
    // }, [documentEditorData])

    function generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    function setUUIDValue(node: any) {
        // Check if the node is an object
        if (typeof node === 'object' && node !== null) {
            // If the current node has a 'style' key, set its value to 123
            if (node.hasOwnProperty('style')) {
                if (node.style.indexOf('border') > -1) {
                    // debugger;
                    let styleArray = node.style.split(';')
                    let newArr = styleArray.filter((vv: any) => vv.indexOf('border:') == -1)
                    node.style = newArr.join(';') + ';';
                }
            }

            // Iterate through the properties of the node
            for (let key in node) {
                if (node.hasOwnProperty(key)) {
                    // Recursively set the style value for each property
                    setUUIDValue(node[key]);
                }
            }
        }
        return node;
    }

    const EditorCapturePlugin = React.forwardRef((props: any, ref: any) => {
        const [editor] = useLexicalComposerContext();
        useEffect(() => {
            ref.current = editor;
            return () => {
                ref.current = null;
            };
        }, [editor, ref]);

        return null;
    });

    useEffect(() => {
        setPreviousEditorObj(getEditorJson())
    }, [])

    useEffect(() => {
        if (currentActiveDocDetails?.document_html_body_flag) {
            if (editorHtmlData && currentActiveDocDetails?.document_step_data?.filter((step: documentStepDataModel) => step?.step_type_name == "Editor")[0]?.document_content_data?.length == 0) {
                AddHtmlToLexical(editorHtmlData)
            }
        }
    }, [editorHtmlData])


    const IsIdenticalObjects = (oldObj: object, updatedCurrentObj: object) => {
        if (isEqual(oldObj, updatedCurrentObj)) {
            return true
        } else {
            return false
        }
    }

    const ChangeBtn = () => {
        let currentEditorObj: any = editor.getEditorState().toJSON()
        // var te222 = editor.getEditorState()._nodeMap.get('4')
        currentEditorObj = setUUIDValue(currentEditorObj)
        // currentEditorObj.root.children[0].format = "asdfgh"
        // const keyNodeMap = editor._keyToDOMMap;

        var upadtededitor = editor.getEditorState()?._nodeMap
        // debugger;
        if (IsIdenticalObjects(previousEditorObj, currentEditorObj)) {
            return
        } else {
            alert("changes alert")
            //= temp.root.children[0].children[0].text + "99999"
            editor.update(() => {
                const updatedEditorState = editor.parseEditorState(currentEditorObj)
                editor.setEditorState(editor._editorState)
            })
            setPreviousEditorObj(currentEditorObj)
        }
    }

    function formatHTML(htmlContent: string) {
        return htmlContent.replace(/<\/(p|h1|h2|h3|h4|h5|h6|a|div|span|ul|ol|li)>[\s]*</gmi, function (match) {
            return match.slice(0, -1) + "\n<";
        });
    }

    const AddHtmlToLexical = (editorHtmlData: string) => {
        // editorHtmlData = editorHtmlData.replace(/>/g, ">\n")
        const htmlString = formatHTML(editorHtmlData)
        //editorHtmlData

        // `
        // <h2>Witness the Next Revolution in Tech at GITEX Dubai 2023</h2> 
        // <p>Calling all tech enthusiasts and industry leaders! We're thrilled to announce our participation in GITEX Dubai 2023, where we'll be introducing our groundbreaking new product that is set to redefine the future of technology.</p> 
        // <p>Don't miss the opportunity to get an exclusive look at our innovative product powered by cutting-edge technology. Be among the first to experience what we believe is the next big thing in tech. Join us at GITEX Dubai 2023 and be a part of this revolutionary journey.</p>`//$generateHtmlFromNodes(editor);
        editor.update(() => {
            // In the browser you can use the native DOMParser API to parse the HTML string.
            const parser = new DOMParser();
            const dom = parser.parseFromString(htmlString, "text/html");

            // Once you have the DOM instance it's easy to generate LexicalNodes.
            const nodes = $generateNodesFromDOM(editor, dom);

            // Select the root
            $getRoot().select();

            // Insert them at a selection.
            $insertNodes(nodes);
        });
    }

    return (
        <>
            {/* <button onClick={() => { addHtml() }} >Change Btn</button> */}
            {/* <button onClick={() => { IsIdenticalObjects() }} >Compare Obj</button> */}

            {isRichText && <ToolbarPlugin />}
            {flagRewritePopup && <RewritePopup />}
            <div className={`editor-container ${showTreeView ? 'tree-view' : ''} ${!isRichText ? 'plain-text' : ''}`}>

                {isMaxLength && <MaxLengthPlugin maxLength={30} />}
                <DragDropPaste />
                <AutoFocusPlugin />
                <ClearEditorPlugin />
                <ComponentPickerPlugin />
                <AutoEmbedPlugin />
                <EmojisPlugin />
                {/* <HashtagPlugin /> */}
                <KeywordsPlugin />
                <SpeechToTextPlugin />
                <AutoLinkPlugin />
                <RestoreFromLocalStoragePlugin />
                <CommentPlugin providerFactory={isCollab ? createWebsocketProvider : undefined} />
                {isRichText ? (
                    <>
                        {isCollab ? (
                            //   <CollaborationPlugin
                            //     id="main"
                            //     providerFactory={createWebsocketProvider}
                            //     shouldBootstrap={!skipCollaborationInit}
                            //   />
                            <></>
                        ) : (
                            <HistoryPlugin externalHistoryState={historyState} />
                        )}

                        {/* <OnChangePlugin onChange={onChange} /> */}
                        {/* <EditorCapturePlugin ref={editorRef} /> */}
                        <MyCustomOnChangePlugin editorRef={editorRef} />
                        <RichTextPlugin
                            contentEditable={
                                <div className="editor-scroller" ref={editorRef}>
                                    <div className="editor" ref={onRef}>
                                        <ContentEditable />
                                    </div>
                                </div>
                            }
                            placeholder={placeholder}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        {/* <MarkdownShortcutPlugin /> */}
                        <CodeHighlightPlugin />
                        <ListPlugin />
                        <CheckListPlugin />
                        <ListMaxIndentLevelPlugin maxDepth={7} />
                        {/* <TablePlugin
                        // hasCellMerge={tableCellMerge}
                        // hasCellBackgroundColor={tableCellBackgroundColor}
                        /> */}
                        <TableCellResizer />
                        <NewTablePlugin cellEditorConfig={cellEditorConfig}>
                            <AutoFocusPlugin />
                            <RichTextPlugin
                                contentEditable={
                                    <ContentEditable className="TableNode__contentEditable" />
                                }
                                placeholder={null}
                                ErrorBoundary={LexicalErrorBoundary}
                            />
                            <HistoryPlugin />
                            <ImagesPlugin captionsEnabled={false} />
                            <LinkPlugin />
                            <FloatingTextFormatToolbarPlugin />
                        </NewTablePlugin>
                        <ImagesPlugin />
                        <InlineImagePlugin />
                        <LinkPlugin />
                        <PollPlugin />
                        <TwitterPlugin />
                        <YouTubePlugin />
                        <FigmaPlugin />
                        <HorizontalRulePlugin />
                        <EquationsPlugin />
                        <ExcalidrawPlugin />
                        <TabFocusPlugin />
                        <TabIndentationPlugin />
                        <CollapsiblePlugin />

                        {floatingAnchorElem && (
                            <>
                                <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                                <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                                <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
                                <FloatingTextFormatToolbarPlugin anchorElem={floatingAnchorElem} />
                            </>
                        )}
                    </>
                ) : (
                    <>

                    </>
                )}
                {(isCharLimit || isCharLimitUtf8) && (
                    <CharacterLimitPlugin
                        charset={isCharLimit ? 'UTF-16' : 'UTF-8'}
                        maxLength={5}
                    />
                )}
                {isAutocomplete && <AutocompletePlugin />}
                <ActionsPlugin isRichText={isRichText} />
            </div>
        </>
    );
}
