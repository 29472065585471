import React, { useContext, useEffect, useState } from 'react'
import styles from '../../../assets/styles/componentStyles/campaigns.module.scss'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Typography } from '@mui/material'
import { CommonResponseModel, CommonResponseSecondaryModel, RegisterDataResponseModel, SendOTPResponseDataModel, createNoteCategoryPayloadModel } from '../../../models/axiosModel'
import { NoteContext, NoteContextPayload } from '../../../context/noteContext'
import { UserContext, UserContextPayload } from '../../../context/userContext'
import GlobalForm from '../../utility/formWiz/globalForm'
import agent from "../../../services/api"
import axios from "axios";
import { checkForErrorCode, flagCreditsExhausted, getErrorMessage, isDev, emptyUserDetail, flagCookieExpired } from '../../../utils/constants'

interface addCategory {
    setFlagShowCategoryForm: any,
    setFlagNewCategoryAdded: any,

}
const AddNoteCategory = (props: addCategory) => {

    const navigate = useNavigate()
    const {
        setFlagLoading,
        success,
        setSuccess,
        error,
        setError,
        setFlagShowCreditsExhausted,
        flagLoading
    } = useContext(UserContext) as UserContextPayload;
    const { addNoteCategory, GetNotesCategoryListApi, categoryList } = useContext(NoteContext) as NoteContextPayload;
    const { userDetail, Register, setUserDetail } = useContext(UserContext) as UserContextPayload;
    const [submissionID, setsubmissionID] = React.useState(null);
    const [files, setFiles] = useState([]);
    const [filePath, setfilePath] = useState<any>("");
    const [progress, setProgress] = useState({ filename: "", percent: 0 });
    const [renderValue, setRenderValue] = React.useState({
        status: null,
        message: "",
        submission_id: null
    })

    const [flagCreateBtnDisabled, setFlagCreateBtnDisabled] = useState(true)
    const notesDetailsForm = [
        {
            "page_field_id": 1666,
            "label": "Category Name",
            "placeholder": "Write Category Name",
            "required": true,
            "name": "1666_Category_Name",
            "type": "text",
            "value": "",
            "size": "small",
            "hidden": false,
            "position": null,
            "font_color": null,
            "col": 12,
            "class2": "globalTextfieldStyle"
        },
        {
            "page_field_id": 1668,
            "label": "Category Icon URL",
            "placeholder": "Enter Category Icon URL",
            "required": false,
            "name": "1668_Category_Icon",
            "type": "file",
            "value": [],
            "hidden": false,
            "col": 12,
            "file_size": "3",
            "multiple_file_allow": false,
            "multiple_file": 1,
            "flag_ai_suggestion": false,
            "fileType": ".png, .jpg, .jpeg",
            "class2": "globalTextfieldStyle"
        },

    ]

    const [createNoteFormData, setCreateNoteFormData] = useState<any>({
        data: {
            global_form_field: notesDetailsForm,
            widget_flag_auto_save: false,
            widget_flag_hide_button: false,
            widget_form_full_width: true,
            doc_page_theme: [
                {
                    "page_id": "682",
                    "page_theme_font_type": "Open Sans",
                    "page_theme_font_style": "normal",
                    "page_theme_font_align": "left",
                    "page_theme_font_weight": "bold",
                    "page_theme_font_size": "15",
                    "page_theme_background_color": "#FFFFFF",
                    "page_theme_heading_color": "#EFE4F1",
                    "page_theme_text_decoration": "unset",
                    "page_hide_button": false,
                    "page_auto_save": false,
                    "page_font_spacing": null,
                    "page_theme_row_spacing": "10px 0px 10px 0px",
                    "page_theme_column_spacing": 0,
                    "margin": "0px 0px 8px 0px"
                }
            ]
        }
    })

    // useEffect(() => {
    //     if (createNoteFormData.data.global_form_field[0].value.trim()) {
    //         setFlagCreateBtnDisabled(false)
    //     } else {
    //         setFlagCreateBtnDisabled(true)
    //     }

    // }, [createNoteFormData])


    const formSubmitFunction = (data: any) => {
        var finalData = data;
        // RegisterUser(data)
    }

    const handleCloseSnackBar = () => {
        setRenderValue({
            status: null,
            message: "",
            submission_id: null
        })
    }

    const setFieldValue = (data: any) => {
        var tempdata = { ...createNoteFormData }
        tempdata.data.global_form_field.map((newvalue: any) => {

            if (newvalue.options) {
                newvalue.options.map((option_value: any) => {
                    if (option_value.field_option_data_id == data.field_option_data_id) {
                        option_value.field_option_flag = data.page_field_value
                    }
                })
            }
            if (newvalue.page_field_id === data.page_field_id) {
                newvalue.value = data.page_field_value
            }
        })
        if (createNoteFormData.data.global_form_field[0].value.trim()) {
            setFlagCreateBtnDisabled(false)
        } else {
            setFlagCreateBtnDisabled(true)
        }
        setCreateNoteFormData(tempdata)
        // setFlagFirstRenderDone(true)
    }
    const handleCreateUser = async () => {
        const payload = {
            google_recaptcha_token: null,
        };
        const response: CommonResponseModel<RegisterDataResponseModel> | CommonResponseSecondaryModel<SendOTPResponseDataModel> =
            await Register(payload);
        if (response.status) {
            if (Array.isArray(response.data)) {
                setUserDetail({ ...response.data[0], flagUser: response.status, flagUserFromParaphrase: true })
            }
        } else {
            setError({ ...error, flagShowError: true, message: "Something went wrong! Please try again later." })
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    };

    const uploadFileHandler = async (event: any, id: any, removeFile: any) => {

        for (var i = 0; i < event.target.files.length; i++) {
            const file: any = event.target.files[i];
            file.isUploading = true;
            setFiles(old => {
                let temp: any = [...old]
                temp.push(file)
                return temp
            })

            let formData = new FormData()
            formData.append(
                "file", file, file.name,
            );
            formData.append("chatbot_flag", "true");
            let config = {
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent;
                    var percent = Math.floor((loaded * 100) / total)
                    setProgress({ ...progress, percent: percent, filename: file.name })
                }
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/storage/file`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true,
                onUploadProgress: config.onUploadProgress,
            }
            ).then(async (res) => {

                file.isUploading = false;
                file.file_data = res.data.data;
                file.google_storage_id = res.data.data.google_storage_id;
                await setFiles(old => {
                    let temp = [...old]
                    temp.map((rr: any) => {
                        if (rr.lastModified == file.lastModified) {
                            rr.isUploading = false;
                            rr.file_data = res.data.data;
                            rr.google_storage_id = res.data.data.google_storage_id;
                            rr.page_field_id = id
                        }
                    })
                    var tempdata = { ...createNoteFormData }
                    const googleStorageIds = temp.map((item: any) => item.google_storage_id);

                    tempdata.data.global_form_field.map((newvalue: any) => {
                        if (newvalue.page_field_id === id) {
                            // debugger
                            newvalue.value = googleStorageIds
                        }
                    })
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                    setCreateNoteFormData(tempdata)
                    return temp
                })

                let images = [];
                for (let i = 0; i < event.target.files.length; i++) {
                    images.push(URL.createObjectURL(event.target.files[i]))
                }
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            }
            ).catch((err) => {
                // inform the user
                console.error(err)
                removeFile(file.name)
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
            });
        }
    }

    const removeFile = (google_id: any, page_field_id: any) => {
        var google_s_id: any = {};
        google_s_id['file_id'] = google_id
        const fileSet: any = files.filter((file: any) => file.google_storage_id !== google_id)
        var finalFiles: any = [...fileSet];
        var tempdata = { ...createNoteFormData }
        const googleStorageIds = finalFiles.map((item: any) => item.google_storage_id);

        tempdata.data.global_form_field.map((newvalue: any) => {
            if (newvalue.page_field_id === page_field_id) {
                // debugger
                newvalue.value = googleStorageIds
            }
        })
        setCreateNoteFormData(tempdata)
        setFiles(finalFiles)
    }

    const handleSubmitCreatedNote = async () => {
        if (userDetail.flagUser) {
        } else {
            await handleCreateUser();
        }

        let payload: createNoteCategoryPayloadModel = {
            notes_category_name: createNoteFormData.data.global_form_field[0].value,
            notes_category_icon_id: createNoteFormData.data.global_form_field[1].value.length > 0 ? createNoteFormData.data.global_form_field[1].value : null
        }
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let response = await addNoteCategory(payload)

        if (response.status) {
            if (response.data) {
                let res = await GetNotesCategoryListApi()
                if(res.status && res.data) {
                    props.setFlagNewCategoryAdded(true);
                    setTimeout(() => {
                        setSuccess({ ...success, flagShowSuccess: true, message: "Category Added Successfully!" })
                        props.setFlagShowCategoryForm(false);
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                    }, 1500);
                }
            }
        }
        else {
            let errorCode = checkForErrorCode(response)
            if (flagCookieExpired(userDetail, errorCode)) {
                setUserDetail(emptyUserDetail)
                if (userDetail.flag_user_anonymous) {
                    let url = window.location.pathname
                    navigate(url, { replace: true });
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: "Session expired! Please login to continue."
                    });
                    setTimeout(() => {
                        let url = encodeURIComponent(window.location.href)
                        navigate("/login?redirect=" + url, { replace: true });
                    }, 1500);
                }
            } else {
                if (flagCreditsExhausted(errorCode)) {
                    setFlagShowCreditsExhausted(true)
                } else {
                    setError({
                        ...error,
                        flagShowError: true,
                        message: getErrorMessage(errorCode, isDev(userDetail))
                    });
                }
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    }
    return (
        <>
            <Grid container className={`${styles.cmpPopupHeadSpacing}`}>                
                <Grid item xs={12} className={`${styles.cmpCreateFormPopup} ${styles.cmpPopupBorderItem}`}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        component='span'
                                        className={styles.cmpCreateCampaignText}
                                    >
                                        Add New Category
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <hr className={styles.cmpCreateDivider} />
                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container>
                                <GlobalForm
                                    fields={createNoteFormData.data.global_form_field}
                                    submitFunction={formSubmitFunction}
                                    checkFormSubmit={renderValue}
                                    handleCloseSnackBar={handleCloseSnackBar}
                                    pageThemeData={createNoteFormData.data.doc_page_theme[0]}
                                    formData={createNoteFormData.data}
                                    submissionID={submissionID}
                                    setFieldValue={setFieldValue}
                                    recaptchaFlag="true"
                                    defaultLoader={false}
                                    formEditable={true}
                                    uploadFileHandler={(e: any, id: any, removeFile: any) => { uploadFileHandler(e, id, removeFile) }}
                                    filesInParent={files}
                                    removeFile={(e: any, page_field_id: any) => { removeFile(e, page_field_id) }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={styles.cmpPopupButton}>
                            <Button
                                variant='contained'
                                size='medium'
                                className={flagCreateBtnDisabled ? `${styles.cmpGlobalContainedDisabledBtnStyles}` : `${styles.cmpGlobalContainedBtnStyles}`}
                                disabled={flagCreateBtnDisabled}
                                onClick={() => {

                                    handleSubmitCreatedNote()
                                }}
                            >
                                Add Category
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AddNoteCategory