import { Grid, Slider, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { UserContext, UserContextPayload } from '../../context/userContext';
import upgrade from "../../assets/upgrade.svg";
import moveForward from "../../assets/rightArrow.png";
import GlobalButton from './globalButton/button';
import styled from '@emotion/styled';
import styles from "../../assets/styles/componentStyles/creditUsed.module.scss";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import globalStyles from "../../assets/styles/global.module.scss";
import { takeUserToPricingPlan } from '../../utils/constants';


export const CreditUsedComponent = (props: any) => {
    const navigate = useNavigate();
    const { creditUsed, userDetail } = useContext(UserContext) as UserContextPayload;
    const handleNavigate = () => {
        let urlToNavigateTo = takeUserToPricingPlan(userDetail);
        if (urlToNavigateTo.toNavigate) {
            navigate(urlToNavigateTo.url)
        } else {
            window.open(urlToNavigateTo.url, "_blank")
        }
    }
    return (
        <>
            <Grid item className={props.flagMobile ? styles.creditUsedContainerMobile : styles.creditUsedContainer}>
                <Grid container direction="column">
                    {/* credits left text */}
                    <Grid item className={props.flagMobile ? "" : styles.firstBlockWrapper}>
                        <Grid container justifyContent={props.flagMobile ? "center" : "space-between"}>
                            {/* left */}
                            <Grid item>
                                <Grid container >
                                    <Grid item ><img src={upgrade} /></Grid>
                                    {!props.flagMobile && <Grid item className={styles.creditUsedTextStyle}>Credits left</Grid>}
                                </Grid>
                            </Grid>
                            {/* right */}
                            {!props.flagMobile &&
                                <Grid item className={globalStyles.displayFlex}>
                                    <img onClick={() => { handleNavigate() }} src={moveForward} className={globalStyles.clickable} style={{ width: "12px", height: "auto" }} />
                                </Grid>}
                        </Grid>
                    </Grid>
                    {/* slider */}
                    {creditUsed !== undefined &&
                        <>
                            <Grid container justifyContent={props.flagMobile ? "center" : "space-between"} alignItems={"center"}>
                                <Grid item sx={{ width: props.flagMobile ? "100%" : "75%" }}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            {props.flagMobile ?
                                                <Grid container className={styles.creditUsedStatusMobileContainer} direction="column" justifyContent={"center"} alignItems={"center"}>
                                                    <Grid item>
                                                        <Typography className={styles.creditUsedStatusMobile}>
                                                            {creditUsed.remaining_credit_usage}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                :
                                                <Typography className={styles.creditUsedStatus}>
                                                    {creditUsed.remaining_credit_usage}/{creditUsed.total_credit_usage}
                                                </Typography>}
                                        </Grid>
                                        {!props.flagMobile &&
                                            <Grid item className={styles.creditUsedRailWrapper}>
                                                <LinearProgress
                                                    className={styles.CreditUsedRail}
                                                    variant="determinate"
                                                    value={(creditUsed.remaining_credit_usage / creditUsed.total_credit_usage) * 100}
                                                />
                                            </Grid>}
                                    </Grid>
                                </Grid>
                                {!props.flagMobile &&
                                    <GlobalButton onClick={() => { handleNavigate() }} flagMobile flagUpgrade buttonText={''} className='upgradeButtonStyle' />}
                            </Grid>
                        </>
                    }
                    {/* upgrade button */}
                    {props.flagMobile &&
                        <Grid item>
                            <GlobalButton onClick={() => { handleNavigate(); }} flagMobile flagFullWidth flagUpgrade buttonText={props.flagMobile ? '' : 'Upgrade'} className='upgradeButtonStyle' />
                        </Grid>}
                </Grid>
            </Grid>
        </>
    )
}
