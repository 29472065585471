import React, { MutableRefObject, useRef, useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import {
    CommonResponseModel,
    CreateDocResponseModel,
    CreateDocPayloadModel,
    DocumentListDataModel,
    documentStepDataModel,
    StepModel,
    updateDocumentFormRequestPayloadModel,
    UpdateDocumentInfoRequestPayloadModel,
    ExecutePromptRequestPayloadModel,
    ExecutePromptResponseDataModel,
    DocumentContentTypeResponseDataModel,
    UpdateDocumentOutlineDataPayloadModel,
    DeleteDocumentOutlineDataParamsModel,
    UpdateDocumentTitlePayloadModel,
    UpdateDocumentEditorPayloadModel,
    ExecutePromptResponseModel,
    executePromptResponseParaphraseObj,
    dialectObj,
    drawerProps,
    popUpActionDataModel,
} from "../models/axiosModel";
import agent from "../services/api";

const ParaphraseContext = React.createContext<ParaphraseContextPayload | undefined>(
    undefined
);

export interface ParaphraseContextPayload {
    paraphrasingVersioningArr: any,
    setParaphraseVersioningarr: React.Dispatch<React.SetStateAction<any>>
    paraphrasingVersionIndex: number,
    setParaphrasingVersionIndex: React.Dispatch<React.SetStateAction<number>>;
    currentIndex: number;
    setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
    rewordVersioningArr: any,
    setRewordVersioningarr: any,
    rewordVersionIndex: number,
    setrewordVersionIndex: React.Dispatch<React.SetStateAction<number>>,
    rewordCurrentIndex: number,
    setrewordCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
    drawerProps: drawerProps,
    setDrawerProps: React.Dispatch<React.SetStateAction<drawerProps>>;
    showConfirmationPopup: boolean,
    setShowConfirmationPopup: React.Dispatch<React.SetStateAction<boolean>>;
    flagNeverShowup: boolean;
    setFlagNeverShowup: React.Dispatch<React.SetStateAction<boolean>>;
    dialectList: dialectObj[];
    setDialectList: React.Dispatch<React.SetStateAction<dialectObj[]>>;
    getDialectList: () => void;
    handleGetResetBtnDisableFlag: () => boolean;
    currentTab: string;
    setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
    handleResetWordChanger: () => void;
    handleGetResetBtnRewordDisableFlag: () => boolean;
    popUpActionData: popUpActionDataModel[];
    setPopupActionData: React.Dispatch<React.SetStateAction<popUpActionDataModel[]>>;
    hasParaphras: boolean,
    setHasParaphras: React.Dispatch<React.SetStateAction<boolean>>;
}

const ParaphraseProvider = ({ children }: ChildrenProps) => {
    const urlParams = new URLSearchParams(window.location.search);
    const [dialectList, setDialectList] = useState<dialectObj[]>([])
    const [paraphrasingVersioningArr, setParaphraseVersioningarr] = useState<any>(
        [
            {
                id: 0,
                index: 0,
                currentSubTab: urlParams.get('subTab') || 'Basic',
                selectedLanguage: "Same as Input",
                selectedOutputCount: "3",
                paraphrasingInput: "",
                flagInputEditable: true,
                basicParaphrasingVal: {
                    tone_of_voice: [],
                    sentence_length: "Medium",
                },
                advanceParaphrasingVal: {
                    tone_of_voice: [],
                    narrative_style: "",
                    work_domain: "",
                    reading_ease: "",
                    layout: "",
                    sentence_length: "Medium",
                    dialect: ""
                },
                personaPayload: {
                    personaObj: "",
                    persona_id: "",
                    layout: "",
                    sentence_length: "Medium",
                },
                customPromptPayload: {
                    user_custom_prompt: "",
                },
                executePromptResponseParaphrase: []
            }
        ]
    )
    const [hasParaphras, setHasParaphras] = useState<any>(false);
    const [paraphrasingVersionIndex, setParaphrasingVersionIndex] = useState(0);
    const handleGetResetBtnDisableFlag = () => {
        let initialBasicParaphrasingVal = {
            tone_of_voice: [],
            sentence_length: "Medium",
        }
        let initialAdvanceParaphrasingVal = {
            tone_of_voice: [],
            narrative_style: "",
            work_domain: "",
            reading_ease: "",
            layout: "",
            sentence_length: "Medium",
            dialect: ""
        }
        let initialCustomParaphrasingVal = {
            user_custom_prompt: "",
        }
        let initialPersonaVal = {
            personaObj: "",
            persona_id: "",
            layout: "",
            sentence_length: "Medium",
        }
        if (
            (JSON.stringify(paraphrasingVersioningArr[paraphrasingVersionIndex].basicParaphrasingVal) !== JSON.stringify(initialBasicParaphrasingVal))
            || (JSON.stringify(paraphrasingVersioningArr[paraphrasingVersionIndex].advanceParaphrasingVal) !== JSON.stringify(initialAdvanceParaphrasingVal))
            || (JSON.stringify(initialCustomParaphrasingVal) !== JSON.stringify(paraphrasingVersioningArr[paraphrasingVersionIndex].customPromptPayload))
            || (JSON.stringify(paraphrasingVersioningArr[paraphrasingVersionIndex].personaPayload) !== JSON.stringify(initialPersonaVal))
            || paraphrasingVersioningArr[paraphrasingVersionIndex].executePromptResponseParaphrase.length > 0 || paraphrasingVersioningArr[paraphrasingVersionIndex].selectedLanguage !== "Same as Input" || paraphrasingVersioningArr[paraphrasingVersionIndex].selectedOutputCount !== "3" || paraphrasingVersioningArr[paraphrasingVersionIndex].paraphrasingInput.trim().length > 0) {
            return false
        } else {
            return true
        }
    }
    const handleGetResetBtnRewordDisableFlag = () => {
        let initialValue = {
            personaObj: "",
            persona_id: "",
            reading_ease: "",
            work_domain: "",
            words_to_include: [],
            words_to_exclude: []
        }
        if (
            (JSON.stringify(rewordVersioningArr[rewordVersionIndex].wordChangerPayload) !== JSON.stringify(initialValue)) ||
            rewordVersioningArr[rewordVersionIndex].executePromptResponseReword.length > 0 || rewordVersioningArr[rewordVersionIndex].rewordingInput.trim().length > 0) {
            return false
        } else {
            return true
        }
    }
    const [currentIndex, setCurrentIndex] = useState(paraphrasingVersioningArr.length - 1)
    const [rewordVersioningArr, setRewordVersioningarr] = useState<any>(
        [
            {
                id: 0,
                index: 0,
                selectedOutputCount: "3",
                rewordingInput: "",
                wordChangerPayload: {
                    personaObj: "",
                    persona_id: "",
                    reading_ease: "",
                    work_domain: "",
                    words_to_include: [],
                    words_to_exclude: []
                },
                executePromptResponseReword: []
            }
        ]
    )
    const [rewordVersionIndex, setrewordVersionIndex] = useState(0);
    const [rewordCurrentIndex, setrewordCurrentIndex] = useState(rewordVersioningArr.length - 1)
    const [currentTab, setCurrentTab] = React.useState(urlParams.get('currentTab') || "Paraphrase");
    const [drawerProps, setDrawerProps] = useState<drawerProps>({
        openDrawer: false,
        dropdownOptions: [],
        paraphrasingPayloadKeyValue: "",
        optionKeyName: "",
        paraphrasingTabKey: null,
        optionImage: null,
        isValMultiple: false,
        dropdownName: ""
    })
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const [flagNeverShowup, setFlagNeverShowup] = useState(false)
    const [popUpActionData, setPopupActionData] = useState<popUpActionDataModel[]>([])
    const getDialectList = async () => {
        const response = await agent.getDialectList.get();
        if (response.status) {
            setDialectList(response.data)
        } else {
        }
    }
    const handleResetWordChanger = () => {
        setrewordVersionIndex(0);
        setRewordVersioningarr([
            {
                id: 0,
                index: 0,
                selectedOutputCount: "3",
                rewordingInput: "",
                wordChangerPayload: {
                    personaObj: "",
                    persona_id: "",
                    reading_ease: "",
                    work_domain: "",
                    words_to_include: [],
                    words_to_exclude: []
                },
                executePromptResponseReword: []
            }
        ])
    }

    const payload = {
        paraphrasingVersioningArr,
        setParaphraseVersioningarr,
        paraphrasingVersionIndex,
        setParaphrasingVersionIndex,
        currentIndex,
        setCurrentIndex,
        rewordVersioningArr,
        setRewordVersioningarr,
        rewordVersionIndex,
        setrewordVersionIndex,
        rewordCurrentIndex,
        setrewordCurrentIndex,
        drawerProps,
        setDrawerProps,
        showConfirmationPopup,
        setShowConfirmationPopup,
        flagNeverShowup,
        setFlagNeverShowup,
        getDialectList,
        dialectList,
        setDialectList,
        handleGetResetBtnDisableFlag,
        currentTab,
        setCurrentTab,
        handleResetWordChanger,
        handleGetResetBtnRewordDisableFlag,
        popUpActionData,
        setPopupActionData,
        hasParaphras,
        setHasParaphras
    };
    return <ParaphraseContext.Provider value={payload}>{children}</ParaphraseContext.Provider>;
};

export { ParaphraseContext, ParaphraseProvider };
