import React, { useState } from "react";
import { ChildrenProps } from "../models/componentModel";
import {
    CommonResponseModel,
    CreateDocResponseModel,
    GetPersonaListDataModel,
    GetPersonaWritingSkillsModel,
    GetPersonaSentenceLengthModel,
    GetPersonaNarrativeStyleModel,
    GetPersonaCommunicationTypeModel,
    PersonaDetailModel,
    CreatePersonaPayloadModel,
    UpdatePersonaPayloadModel,
    userDefaultPersonaDataObj,
} from "../models/axiosModel";
import agent from "../services/api";

const PersonaContext = React.createContext<PersonaContextPayload | undefined>(
    undefined
);

export interface PersonaContextPayload {
    personaList: GetPersonaListDataModel[] | null;
    setPersonaList: React.Dispatch<React.SetStateAction<GetPersonaListDataModel[] | null>>;
    GetPersonaList: () => Promise<CommonResponseModel<GetPersonaListDataModel[] | null>>;
    GetSystemPersonaPublicList: () => Promise<CommonResponseModel<GetPersonaListDataModel[] | null>>;
    personaWritingSkills: GetPersonaWritingSkillsModel[] | null;
    setPersonaWritingSkills: React.Dispatch<React.SetStateAction<GetPersonaWritingSkillsModel[] | null>>;
    GetPersonaWritingSkills: () => Promise<CommonResponseModel<GetPersonaWritingSkillsModel[] | null>>;

    personaSentenceLength: GetPersonaSentenceLengthModel[] | null;
    setPersonaSentenceLength: React.Dispatch<React.SetStateAction<GetPersonaSentenceLengthModel[] | null>>;
    GetPersonaSentenceLength: () => Promise<CommonResponseModel<GetPersonaSentenceLengthModel[] | null>>;

    personaNarrativeStyle: GetPersonaNarrativeStyleModel[] | null;
    setPersonaNarrativeStyle: React.Dispatch<React.SetStateAction<GetPersonaNarrativeStyleModel[] | null>>;
    GetPersonaNarrativeStyle: () => Promise<CommonResponseModel<GetPersonaNarrativeStyleModel[] | null>>;

    personaCommunicationType: GetPersonaCommunicationTypeModel[] | null;
    setPersonaCommunicationType: React.Dispatch<React.SetStateAction<GetPersonaCommunicationTypeModel[] | null>>;
    GetPersonaCommunicationType: () => Promise<CommonResponseModel<GetPersonaCommunicationTypeModel[] | null>>;

    currentActivePersonaDetails: PersonaDetailModel | null,
    setCurrentActivePersonaDetails: React.Dispatch<React.SetStateAction<PersonaDetailModel | null>>;
    GetPersonaDetails: (persona_id: number) => Promise<CommonResponseModel<PersonaDetailModel | null>>;
    CreatePersona: (payload: CreatePersonaPayloadModel) => Promise<CommonResponseModel<PersonaDetailModel[] | null>>;
    UpdatePersona: (payload: UpdatePersonaPayloadModel) => Promise<CommonResponseModel<PersonaDetailModel[] | null>>;
    defaultPersonaTypeList: userDefaultPersonaDataObj[] | null,
    setDefaultPersonaTypeList: React.Dispatch<React.SetStateAction<userDefaultPersonaDataObj[] | null>>;
    getDefaultPersonaTypeList: () => void;
}

const PersonaProvider = ({ children }: ChildrenProps) => {

    const [personaList, setPersonaList] = useState<GetPersonaListDataModel[] | null>(null)
    const [personaWritingSkills, setPersonaWritingSkills] = useState<GetPersonaWritingSkillsModel[] | null>(null)
    const [personaSentenceLength, setPersonaSentenceLength] = useState<GetPersonaSentenceLengthModel[] | null>(null)
    const [personaNarrativeStyle, setPersonaNarrativeStyle] = useState<GetPersonaNarrativeStyleModel[] | null>(null)
    const [personaCommunicationType, setPersonaCommunicationType] = useState<GetPersonaCommunicationTypeModel[] | null>(null)
    const [currentActivePersonaDetails, setCurrentActivePersonaDetails] = useState<PersonaDetailModel | null>(null)
    const [defaultPersonaTypeList, setDefaultPersonaTypeList] = useState<userDefaultPersonaDataObj[] | null>(null)

    const GetPersonaList = async (): Promise<CommonResponseModel<GetPersonaListDataModel[] | null>> => {
        const response = await agent.getPersonaList.get();
        if (response.status) {
            setPersonaList(response.data);
            return response;
        } else {
            setPersonaList(null);
            return response;
        }
    };
    const GetSystemPersonaPublicList = async (): Promise<CommonResponseModel<GetPersonaListDataModel[] | null>> => {
        const response = await agent.getSystemPersonaPublicList.get();
        if (response.status) {
            setPersonaList(response.data);
            return response;
        } else {
            setPersonaList(null);
            return response;
        }
    };
    const GetPersonaWritingSkills = async (): Promise<CommonResponseModel<GetPersonaWritingSkillsModel[] | null>> => {
        const response = await agent.getReadingSkillsDetails.get();
        if (response.status) {
            setPersonaWritingSkills(response.data);
            return response;
        } else {
            setPersonaWritingSkills(null);
            return response;
        }
    };
    const GetPersonaSentenceLength = async (): Promise<CommonResponseModel<GetPersonaSentenceLengthModel[] | null>> => {
        const response = await agent.getSentenceLengthDetails.get();
        if (response.status) {
            setPersonaSentenceLength(response.data);
            return response;
        } else {
            setPersonaSentenceLength(null);
            return response;
        }
    };
    const GetPersonaNarrativeStyle = async (): Promise<CommonResponseModel<GetPersonaNarrativeStyleModel[] | null>> => {
        const response = await agent.getNarrativeTypeDetails.get();
        if (response.status) {
            setPersonaNarrativeStyle(response.data);
            return response;
        } else {
            setPersonaNarrativeStyle(null);
            return response;
        }
    };
    const GetPersonaCommunicationType = async (): Promise<CommonResponseModel<GetPersonaCommunicationTypeModel[] | null>> => {
        const response = await agent.getCommunicationTypeDetails.get();
        if (response.status) {
            setPersonaCommunicationType(response.data);
            return response;
        } else {
            setPersonaCommunicationType(null);
            return response;
        }
    };
    const GetPersonaDetails = async (persona_id: number): Promise<CommonResponseModel<PersonaDetailModel | null>> => {
        const response = await agent.getPersonaDetails.get(persona_id);
        if (response.status) {
            // setCurrentActivePersonaDetails(response.data[0]);
            return response;
        } else {
            setCurrentActivePersonaDetails(null);
            return response;
        }
    }
    const CreatePersona = async (payload: CreatePersonaPayloadModel): Promise<CommonResponseModel<PersonaDetailModel[] | null>> => {
        const response = await agent.createNewPersona.post(payload);
        if (response.status) {
            setCurrentActivePersonaDetails(response.data[0]);
            return response;
        } else {
            setCurrentActivePersonaDetails(null);
            return response;
        }
    }
    const UpdatePersona = async (payload: UpdatePersonaPayloadModel): Promise<CommonResponseModel<PersonaDetailModel[] | null>> => {
        const response = await agent.updatePersona.put(payload);
        if (response.status) {
            if (response.data.message) {
                return response;
            } else {
                setCurrentActivePersonaDetails(response.data);
                return response;
            }
        } else {
            setCurrentActivePersonaDetails(null);
            return response;
        }
    }
    const getDefaultPersonaTypeList = async () => {
        const response = await agent.getDefaultPersonaTypeList.get();
        if (response.status) {
            setDefaultPersonaTypeList(response.data)
        } else {
            setDefaultPersonaTypeList([])
        }
    }
    const payload = {
        personaList,
        setPersonaList,
        GetPersonaList,
        GetSystemPersonaPublicList,
        personaWritingSkills,
        setPersonaWritingSkills,
        GetPersonaWritingSkills,

        personaSentenceLength,
        setPersonaSentenceLength,
        GetPersonaSentenceLength,

        personaNarrativeStyle,
        setPersonaNarrativeStyle,
        GetPersonaNarrativeStyle,

        personaCommunicationType,
        setPersonaCommunicationType,
        GetPersonaCommunicationType,

        GetPersonaDetails,
        currentActivePersonaDetails,
        setCurrentActivePersonaDetails,
        CreatePersona,
        UpdatePersona,
        getDefaultPersonaTypeList,
        defaultPersonaTypeList,
        setDefaultPersonaTypeList
    };

    return <PersonaContext.Provider value={payload}>{children}</PersonaContext.Provider>;
};

export { PersonaContext, PersonaProvider };
