import React, { useContext, useEffect, useState } from 'react'
import { Button, CardMedia, Grid, Typography } from '@mui/material'
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from '../../../assets/styles/componentStyles/campaignsname.module.scss'
import blogIcon from '../../../assets/blogIcon.svg'
import googleAds from '../../../assets/googleAds.svg'
import facebook from '../../../assets/facebook Ads.svg'
import youtube from '../../../assets/youtube.svg'
import story from '../../../assets/video-camera-with-play-button 1.svg'
import socialPost from '../../../assets/video-camera-with-play-button 1.svg'
import seo from '../../../assets/seo.svg'
import email from '../../../assets/email.svg'
import pushNotification from '../../../assets/Push notification.svg'
import sms from '../../../assets/SMS.svg'
import slogan from '../../../assets/slogan 2.svg'
import slogansummary from '../../../assets/summary.svg'
import unSelectedCheckbox from '../../../assets/cmpCheckboxUnselected.svg'
import selectedCheckbox from '../../../assets/cmpCheckboxSelected.svg'
import { useNavigate } from 'react-router-dom';
import { CampaignContext, CampaignContextPayload } from '../../../context/campaignContext';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { generateCampaignDocumentPayloadModel } from '../../../models/axiosModel';
import GlobalButton from '../../utility/globalButton/button';
import CloseIcon from '@mui/icons-material/Close';


const CampaignDocumentDialog = (props: any) => {

    const {
        documentCampaignId,
        setFlagNewContentDialog,
        setFlagTabs,
        campaignDocumentDataForTable
    } = props

    // context
    const {
        GetTemplateCampaignList,
        templateCampaignList,
        setTemplateCampaignList,
        generateCampaignDocument,
        getCampaignDocument,
    } = useContext(CampaignContext) as CampaignContextPayload;

    const {
        userDetail,
        setFlagLoading,
        setSuccess,
        setError,
        success,
        error,
        flagLoading
    } = useContext(UserContext) as UserContextPayload;
    const [isAnyTemplateSelected, setIsAnyTemplateSelected] = useState(false);

    useEffect(() => {
        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });
            try {
                await GetTemplateCampaignList()
            }
            catch (err) {
                console.error("An error occurred:", err);
                setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                setFlagLoading({ ...flagLoading, flagRoundLoader: false })
            }
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
        })();
    }, []);

    const handleSelectTemplate = (template: any) => {
        if (templateCampaignList) {
            let tempList: any = [...templateCampaignList]
            tempList.map((obj: any) => {
                if (obj.template_id == template.template_id) {
                    if (template.flagChecked) {
                        obj.flagChecked = false
                    }
                    else {
                        obj.flagChecked = true
                    }
                }
            })
            setTemplateCampaignList(tempList)
            const anySelected = tempList.some((template: any) => template.flagChecked);
            setIsAnyTemplateSelected(anySelected);
        }
    }
    const handleGenerateDocuments = async () => {

        const selectedTemplates = templateCampaignList?.filter((template) => template.flagChecked === true).map((template) => template.template_id);
        let payload: generateCampaignDocumentPayloadModel = {
            campaign_id: Number(documentCampaignId),
            template_id: selectedTemplates || []
        }

        var oneMinDate = new Date();
        oneMinDate.setMinutes(oneMinDate.getMinutes() + 1);
        setFlagLoading({ ...flagLoading, flagRoundLoader: true });
        let response = await generateCampaignDocument(payload)
        if (response.status) {
            setSuccess({ ...success, flagShowSuccess: true, message: "Campaign documents are currently being generated. Please wait for their completion." })
            setFlagNewContentDialog(false)
            var setIntervalgetCampign = setInterval(async function () {
                if (setIntervalgetCampign && oneMinDate.getTime() < new Date().getTime()) {
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                    clearInterval(setIntervalgetCampign)
                }
                else {
                    let responseOfGetDocument = await getCampaignDocument(documentCampaignId)
                    if (responseOfGetDocument.status) {
                        if (responseOfGetDocument?.data) {
                            if ((selectedTemplates && ((campaignDocumentDataForTable.length + selectedTemplates.length) == responseOfGetDocument?.data?.length))) {
                                setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                                clearInterval(setIntervalgetCampign)
                                setFlagTabs(1)
                                setSuccess({ ...success, flagShowSuccess: true, message: "Thank you for waiting, your new campaign documents are ready!" })
                            }
                        }
                    }
                    else {
                        setError({ ...error, flagShowError: true, message: "Something went wrong, Please try again later!" })
                        setFlagLoading({ ...flagLoading, flagRoundLoader: false });
                    }
                }
            }, 10000)
        }
        else {
            setError({ ...error, flagShowError: true, message: "Something went wrong, Please try again later!" })
            setFlagLoading({ ...flagLoading, flagRoundLoader: false });
        }
    }

    return (
        <>
            <Grid container
                sx={{ padding: { xl: "0px 40px", lg: "0px 40px", md: "0px 40px", sm: "0px 40px", xs: "0px 15px" } }}
            >
                {window.screen.width <= 600 &&
                    <Grid item xs={12} sx={{ margin: "0px 0px 10px" }}>
                        <CloseIcon
                            sx={{
                                position: "absolute",
                                right: "13px",
                                top: "13px",
                                margin: "0px 0px 10px"
                            }}
                            onClick={() => { setFlagNewContentDialog(false) }}
                        />
                    </Grid>
                }

                <Grid item xs={12} className={`${styles.cmpDoumenCardHeadItem}`}>
                    <Grid container sx={{ alignItems: "center" }}>
                        <Grid item xs={8}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography component={'span'} sx={{ fontSize: "18px", fontWeight: "600", color: "#4C4A4D" }}>Select Document</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography component={'span'} sx={{ fontSize: "13px", fontWeight: "600", color: "#7A7A7A" }}>Select content to be generated for you </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container sx={{ justifyContent: "end" }} >
                                <Grid item className={`${styles.cmpDoumentBtn}`}>
                                    <GlobalButton
                                        buttonText='Generate Documents'
                                        className="primaryButtonStyle"
                                        disabled={!isAnyTemplateSelected}
                                        onClick={() => { handleGenerateDocuments() }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={`${styles.cmpDocCardNameMainItem}`}>
                    <Grid container className={`${styles.cmpDocCardMainContainer}`}>
                        {templateCampaignList !== null && templateCampaignList?.map((templates: any, index: number) => {
                            return (
                                <>
                                    <Grid item className={`${styles.cmpDocCardNameItem}  ${templates.flagChecked ? `${styles.cmpSelectedCard}` : ""}`}  >
                                        <Grid container >
                                            <Grid item xs={12} sx={{ textAlign: "end" }}>
                                                <span onClick={() => { handleSelectTemplate(templates) }} className={`${styles.cmpCardCheckbox}`} style={{ cursor: "pointer" }} >
                                                    {templates.flagChecked == false ?
                                                        (<img src={unSelectedCheckbox} style={{ width: "25px", height: "auto" }} alt="" />)
                                                        :
                                                        (<img src={selectedCheckbox} style={{ width: "28px", height: "auto" }} alt="" />)
                                                    }
                                                </span>
                                            </Grid>
                                            <Grid item xs={12} className={`${styles.cmpDocCardIconItem}`}>
                                                <CardMedia
                                                    component="img"
                                                    sx={{ width: "auto", height: "30px", objectFit: "contain" }}
                                                    image={templates?.template_icon_url}
                                                    alt="icons"
                                                />
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Typography component={'span'} sx={{ fontSize: "15px", fontWeight: "500", color: "#4C4A4D" }}>
                                                    {templates?.template_name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default CampaignDocumentDialog