import { Grid, Typography } from '@mui/material'
import globalStyles from "../../assets/styles/global.module.scss";
import styles from "../../assets/styles/componentStyles/outlineComponent.module.scss";
import personaPremiumIcon from "../../assets/personaCrown.svg";
import personaAvatar from "../../assets/personaAvatar.svg";
import personaStyles from "../../assets/styles/componentStyles/personaList.module.scss";
import React from 'react'
// import { Tooltip } from '@mui/material';
import { Tooltip } from '@mui/joy';


export const GlobalTooltip = (props: any) => {

    return (
        <>
            <Tooltip
                // open={true}
                sx={{ backgroundColor: "unset !important", padding: "0 !important" }}
                key={props.key}
                className={styles.PersonaDescpTooltip}
                title={<>
                    <Grid container className={styles.personaMenuContainer} alignItems={"start"} direction="column">
                        <Grid item>
                            <Grid container direction="row" flexWrap={"nowrap"}>
                                <Grid item className={styles.personaNameStyle}>{props.option.persona_display_name ? `${props.option.persona_name},` : props.option.persona_name}</Grid>
                                <Grid item className={styles.PersonaDisplayNameStyle}>&nbsp;{props.option.persona_display_name}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" alignItems={"center"}>
                                <Grid item>
                                    <img src={props.option.persona_avatar_url ? props.option.persona_avatar_url : personaAvatar} className={styles.personaAvatarStyle} />
                                </Grid>
                                <Grid item className={styles.leftBlock}>
                                    <Grid item className={styles.personaSummaryStyle}>{props.option.about_persona} </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={globalStyles.tooltipCardItem}>
                            {props.option.persona_tone_of_voice != '""' &&
                                <Grid container className={globalStyles.tooltipCardContainer}>
                                    {JSON.parse(props.option.persona_tone_of_voice)?.map((tone: string) => (
                                        <Grid item className={globalStyles.tooltipCard}>
                                            {tone}
                                        </Grid>
                                    ))}
                                </Grid>}
                        </Grid>
                    </Grid>
                </>}
                placement={props.placement}

            >
                {props.children}
            </Tooltip>
        </>
    )
}
