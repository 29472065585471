import { Button, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import styles from './../assets/styles/componentStyles/trialBannerDesign.module.scss'
import { UserContext, UserContextPayload } from '../context/userContext'
import { useNavigate } from 'react-router-dom'
import { takeUserToPricingPlan } from '../utils/constants'

interface data {
    description?: string,
    btnText?: string,
}

const TrialBannerDesign = (props: data) => {
    const { userDetail, setShowLoginPopup } = useContext(UserContext) as UserContextPayload;
    const navigate = useNavigate();
    const handleNavigate = () => {
        if (!userDetail.flagUser || userDetail.flag_user_anonymous) {
            setShowLoginPopup(true);
        }
        else {
            let urlToNavigateTo = takeUserToPricingPlan(userDetail);
            if (urlToNavigateTo.toNavigate) {
                navigate(urlToNavigateTo.url)
            } else {
                window.open(urlToNavigateTo.url, "_blank")
            }
        }
    }
    return (
        <>
            <Grid container className={styles.trialComponentGrid} alignItems={"center"} justifyContent={"space-between"} flexWrap={"nowrap"}>
                <Grid item xs={6} sm={7} md={8}>
                    <Typography component={'span'} className={styles.trialComponentText}>{props.description}</Typography>
                </Grid>
                <Grid item xs={6} sm={5} md={4} className={styles.selectPlanButton}>
                    <Button
                        variant='contained'
                        size='medium'
                        // fullWidth
                        className={`${styles.HomeBannerBtn}`}
                        onClick={() => { handleNavigate() }}
                    >{props.btnText}</Button>
                </Grid>
            </Grid>
        </>
    )
}

export default TrialBannerDesign
