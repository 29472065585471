import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from "../../assets/styles/componentStyles/accordion.module.scss";
import { Grid } from '@mui/material';
import { DocContext, DocContextPayload } from '../../context/docContext';

export default function BasicAccordion() {
  const { documentOutlineData } = React.useContext(DocContext) as DocContextPayload;

  return (
    <div>
      {documentOutlineData?.map((levelOne: any) => {
        return (
          <Accordion defaultExpanded className={styles.basicAccordion} >
            <AccordionSummary
              expandIcon={levelOne?.children?.length ? <ExpandMoreIcon /> : <></>}
              disabled={levelOne?.children?.length ? false : true}
              sx={{ opacity: "1 !important" }}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={styles.basicAccordionSummary}
            >
              <Typography className={styles.heading}>{levelOne?.document_outline_value}</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.basicAccordionDetails}>
              <Grid container direction="column">

                {levelOne?.children?.length ?
                  levelOne?.children?.map((levelTwo: any) => {
                    return (
                      levelTwo?.content_type_name == "sub heading"
                        ?
                        <>
                          <Grid item className={styles.subHeadingGrid}>
                            <Typography className={styles.subHeading}>
                              {levelTwo?.document_outline_value}
                            </Typography>
                          </Grid>
                          {levelTwo?.children?.length ? levelTwo?.children?.map((levelThree: any) => {
                            return (
                              <Grid item className={styles.subHeadingGrid}>
                                <Typography className={styles.keynote}>
                                  {levelThree?.document_outline_summary}
                                </Typography>
                              </Grid>
                            )
                          }) : ""
                          }
                        </>
                        :
                        <Grid item className={styles.subHeadingGrid}>
                          <Typography className={styles.keynote}>
                            {levelTwo?.document_outline_summary}
                          </Typography>
                        </Grid>

                    )
                  })
                  : ""
                }
                {/* keynotes */}
                {/* <Grid item className={styles.keynoteGrid}>
                  <Typography className={styles.keynote}>
                    Explaining the concepts of machine learning, deep learning, and
                    natural language processing (NLP)
                  </Typography>
                  <Typography className={styles.keynote}>
                    Explaining the concepts of machine learning, deep learning, and
                    natural language processing (NLP)
                  </Typography>
                </Grid> */}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  );
}
