import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import UpdateNotes from './updateNotes';
import CreateNote from './createNote';
import { UserContext, UserContextPayload } from '../../../context/userContext';
import { NoteContext, NoteContextPayload } from '../../../context/noteContext';


const CreateUpdateNote = () => {
    const {
        setFlagLoading,
        setError,
        error,
        flagLoading,
    } = useContext(UserContext) as UserContextPayload;
    const { GetNotesCategoryListApi } = useContext(NoteContext) as NoteContextPayload;
    const [flagShowField, setFlagShowField] = useState(false);
    const [flagNewCategoryAdded, setFlagNewCategoryAdded] = useState(false);

    useEffect(() => {
        (async () => {
            setFlagLoading({ ...flagLoading, flagRoundLoader: true });;
            let res = await GetNotesCategoryListApi()
            if (res.status && res.data) {
                setTimeout(() => {
                    setFlagShowField(true);
                    setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
                }, 100);
            } else {
                setError({ ...error, flagShowError: true, message: "Something went wrong!" })
                setFlagLoading({ ...flagLoading, flagRoundLoader: false });;
            }
        })()
    }, [])

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const getNotesId = queryParams.get('user_notes_id');
    const { userDetail } = useContext(UserContext) as UserContextPayload;
    
    if(flagShowField){
        if (getNotesId && userDetail.flagUser) {
            return (
                <UpdateNotes setFlagNewCategoryAdded={setFlagNewCategoryAdded} flagNewCategoryAdded={flagNewCategoryAdded} />
            );
        }
        else {
            return (
                <CreateNote setFlagNewCategoryAdded={setFlagNewCategoryAdded} flagNewCategoryAdded={flagNewCategoryAdded} />
            );
        }
    }  
}

export default CreateUpdateNote